import { Flex } from '@chakra-ui/react';
import { FilterSelect, SearchInput } from 'components';

const WalletWithdrawalTableFilter = ({
  handleType,
  defaultType,
  handleSize,
  handleStatusChange,
  // handleQueryType,
  debouncedChangeHandler,
  defaultStatus,
  defaultFilter,
  defaultSize,
}) => {
  const isDisabled = defaultFilter === null || defaultFilter === 'Filter';

  return (
    <Flex
      gridGap='10px'
      width='100%'
      flexWrap={{ base: 'wrap', md: 'initial' }}
    >
      <FilterSelect
        width='92px'
        onChange={handleType}
        defaultValue={defaultType}
      >
        <option value='fiat'>Fiat</option>
        <option value='crypto'>Crypto</option>
      </FilterSelect>

      <FilterSelect
        width='88px'
        onChange={handleSize}
        defaultValue={defaultSize}
      >
        <option value={10}>Size</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
      </FilterSelect>

      <FilterSelect
        width='109px'
        onChange={handleStatusChange}
        placeholder='Status'
        defaultValue={defaultStatus}
      >
        <option value='approved'>Approved</option>
        <option value='cancelled'>Cancelled</option>
        <option value='denied'>Denied</option>
        <option value='paid'>Paid</option>
        <option value='cleared'>Cleared</option>
        <option value='pending'>Pending</option>
        <option value='processing'>Processing</option>
      </FilterSelect>

      {/* <FilterSelect
        width='120px'
        onChange={handleQueryType}
        defaultValue={defaultFilter}
      >
        <option>Filter</option>
        <option value='amount'>Amount</option>
        <option value='userSearch'>User</option>
      </FilterSelect> */}

      <SearchInput
        placeholder='Search name, email, reference'
        minH='48px'
        // width='319px'
        maxW='420px'
        width='100%'
        onChange={debouncedChangeHandler}
        disabled={isDisabled}
      />
    </Flex>
  );
};

export default WalletWithdrawalTableFilter;
