import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  ModalBody,
  Box,
  Text,
  ModalHeader,
  Textarea,
  useToast,
  Center,
  Checkbox,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { ActionButton, Button } from 'components';
import { put } from 'utils/axiosLib';
import { fetchBanHistory, fetchSingleUser } from 'store/slices/user.slice';
import { BASE_URL } from 'constant';

const LockUserModal = ({ id, fullName, headers, lockType }) => {
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [sendNotification, setSendNotification] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    const URL = `${BASE_URL}/admin/users/${id}/${lockType}`;

    try {
      const response = await put(
        URL,
        {
          reason,
          deleteAccount,
          sendNotification,
        },
        headers
      );

      if (response.status === 200) {
        onClose();
        setLoading(false);
        dispatch(fetchSingleUser(id));
        dispatch(fetchBanHistory(id));

        setSendNotification(false);
        setDeleteAccount(false);

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'error',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <ActionButton textTransform="capitalize" onClick={onOpen}>
        {lockType}
      </ActionButton>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="16px">
          <ModalHeader padding="16px 0" fontWeight="600" textAlign="center">
            Confirm
          </ModalHeader>

          <Box bg="#F7F7FC" borderBottomRadius="16px" paddingBottom="32px">
            <ModalBody margin="16px 0">
              <Text
                textAlign="center"
                fontSize="14px"
                maxWidth="304px"
                width="100%"
                margin="0 auto"
              >
                Please confirm that you want to{' '}
                {lockType === 'lock' ? 'lock' : 'unlock'} <br />
                <Text as="span">{fullName}</Text>.
              </Text>
            </ModalBody>

            <Center flexDir="column">
              <Textarea
                placeholder="Add reason"
                onChange={(e) => setReason(e.target.value)}
                name="reason"
                isRequired
                bg="#fff"
                width="80%"
                height="120px"
                mb="32px"
                padding="13px 22px"
                _focus={{
                  border: '1px',
                  borderColor: '#6FBE45',
                  bg: '#fff',
                }}
              />

              <Box mb="3rem">
                <Flex mb="1rem" gridGap="1rem" alignItems="center">
                  <Checkbox
                    colorScheme="green"
                    onChange={() => setSendNotification(!sendNotification)}
                    size="lg"
                    borderRadius="1xl"
                  >
                    <Text fontSize="15px">Send Notification</Text>
                  </Checkbox>{' '}
                </Flex>

                {lockType === 'lock' && (
                  <Flex alignItems="center" gridGap="1rem">
                    <Checkbox
                      colorScheme="green"
                      onChange={() => setDeleteAccount(!deleteAccount)}
                      size="lg"
                      borderRadius="1xl"
                    >
                      <Text fontSize="15px">Delete Account</Text>
                    </Checkbox>
                  </Flex>
                )}
              </Box>

              <Button
                minWidth="290px"
                height="60px"
                isLoading={loading}
                disabled={lockType === 'lock' ? !(reason.length >= 10) : false}
                onClick={handleConfirm}
              >
                Submit
              </Button>

              <Text
                textDecoration="underline"
                fontWeight="500"
                fontSize="1rem"
                mt="24px"
                cursor="pointer"
                onClick={onClose}
              >
                Close
              </Text>
            </Center>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LockUserModal;
