import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ls, constant } from 'utils';
const auth = ls.get(constant.AUTH_TOKEN_KEY);

export const notesApi = createApi({
  reducerPath: 'notes',
  refetchOnMountOrArgChange: true,
  tagTypes: ['user-notes'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, _) => {
      headers.set('Authorization', auth.token);
      headers.set('Content-Type', 'application/json');
      // headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    addNotes: builder.mutation({
      query: ({ userId, note, sentiment, attachments, parentNoteId }) => ({
        url: `/admin/users/${userId}/note`,
        method: 'POST',
        body: {
          // userId,
          sentiment,
          attachments,
          note,
          noteType: 'text',
          renderer: 'slate',
          parentNoteId,
        },
      }),
      transformResponse: (res) => res,
      invalidatesTags: ['user-notes', 'thread'],
    }),

    deleteNote: builder.mutation({
      query: ({ userId, noteId }) => ({
        url: `/admin/users/${noteId}/note?type=completeDelete`,
        method: 'DELETE',
        body: {},
      }),
      transformResponse: (res) => res,
      invalidatesTags: ['user-notes', 'thread'],
    }),

    getUserNotes: builder.query({
      query: (userId) => `/admin/users/${userId}/notes`,
      transformResponse: (res) => res.data,
      providesTags: ['user-notes'],
    }),

    getUserNotesThread: builder.query({
      query: ({ id, includeContext }) =>
        `/admin/users/notes/${id}${includeContext ? `?includeContext=${includeContext}` : ''}`,
      transformResponse: (res) => res.data,
      providesTags: ['user-notes', 'thread'],
    }),
  }),
});

export const { useAddNotesMutation, useGetUserNotesQuery, useDeleteNoteMutation, useGetUserNotesThreadQuery } =
  notesApi;

export default notesApi;
