import { Box, Flex, RadioGroup, Radio } from '@chakra-ui/react';
import { PageTitle, TableBoxWrapper } from 'components';
import { useStorage } from 'hooks';
import { IntegrationsUsers, AllUsers } from 'pages';
import { useNavigate } from 'react-router-dom';
import { constant } from 'utils';

const UserWrapper = () => {
  const { setStorage, storageValue } = useStorage(constant.WIDGET_USER);
  const navigate = useNavigate();

  const handleTab = (e) => {
    setStorage(e);
    navigate('/dashboard/users');
  };

  const checkState = storageValue === null || storageValue === 'main';

  return (
    <Box>
      <Flex justifyContent='space-between'>
        <PageTitle title={checkState ? 'Users' : 'Integration'} />

        <Flex
          alignItems='center'
          gridGap='8px'
        >
          <RadioGroup
            defaultValue={checkState ? 'main' : 'bitwaya'}
            onChange={handleTab}
            colorScheme='green'
          >
            <Flex gap='24px'>
              <Radio value='main'>Main</Radio>
              <Radio value='bitwaya'>Bitwaya</Radio>
            </Flex>
          </RadioGroup>
        </Flex>
      </Flex>

      <TableBoxWrapper
        padding={{ sm: '42px 8px', tb: '46px 16px 48px' }}
        mt='29px'
      >
        {checkState ? <AllUsers /> : <IntegrationsUsers />}
      </TableBoxWrapper>
    </Box>
  );
};
export default UserWrapper;
