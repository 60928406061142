import { Text } from '@chakra-ui/react';
import { CustomCard, CustomTooltip } from './CustomTooltip';

const UserProfileTooltip = ({ fullName, userId, index, onClick }) => {
  return (
    <CustomTooltip
      content={
        <CustomCard
          fullName={fullName}
          userId={userId}
        />
      }
      index={index}
    >
      <Text
        as='span'
        onClick={onClick}
        py='4px'
        minW='140px'
        display='block'
      >
        {fullName}
      </Text>
    </CustomTooltip>
  );
};

export default UserProfileTooltip;
