import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { CommandTab } from 'components';
import { commandsShortcuts } from 'LinkArray';

const KeyShortcutsContent = () => {
  return (
    <Accordion
      allowMultiple={false}
      allowToggle
      border='0.5px solid #CACBCB'
      borderRadius='10px'
      boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
    >
      {commandsShortcuts.map(({ controls, title }, index) => {
        return (
          <AccordionItem
            border='none'
            key={index}
          >
            <AccordionButton
              as='div'
              p='20px'
              textTransform='capitalize'
              _expanded={{ bg: 'rgba(0, 0, 0, 0.04)', fontWeight: '600' }}
              transition='all 300ms ease-in-out'
            >
              <Box
                as='span'
                flex='1'
                textAlign='left'
              >
                {title}
              </Box>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel fontSize='14px'>
              {controls.map(({ command, description }, i) => {
                return (
                  <Flex
                    justifyContent='space-between'
                    mb='12px'
                    key={i}
                    padding='4px 0'
                  >
                    <CommandTab title={command} />

                    <Text>{description}</Text>
                  </Flex>
                );
              })}
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
export default KeyShortcutsContent;
