import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, HStack, Input } from '@chakra-ui/react';
import { Button } from 'components';
import { getClaimedDate } from 'store/slices/claimedRewards.slice';

const RewardsDateFilter = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    startDate: '',
    endDate: '',
  });

  const handleDateChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const applyFilters = () => {
    dispatch(getClaimedDate([state.startDate, state.endDate]));
  };

  return (
    <Box>
      <HStack mb="20px" alignItems="flex-end">
        <Input
          label="Start Date"
          name="startDate"
          type="date"
          minH="42px"
          value={state.startDate}
          onChange={handleDateChange}
        />

        <Input
          label="End Date"
          name="endDate"
          type="date"
          minH="42px"
          value={state.endDate}
          onChange={handleDateChange}
        />

        <Button
          minH="42px"
          onClick={applyFilters}
          disabled={!state.startDate || !state.endDate}
        >
          Submit
        </Button>
      </HStack>
    </Box>
  );
};

export default RewardsDateFilter;
