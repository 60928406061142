import { Center } from '@chakra-ui/react';
import { IoClose } from 'react-icons/io5';

const RoundCloseModal = ({ ...props }) => {
  return (
    <Center
      width='42px'
      height='42px'
      borderRadius='24px'
      bg='#fff'
      boxShadow='0px 20px 60px rgba(19, 10, 46, 0.25)'
      cursor='pointer'
      {...props}
    >
      <IoClose fontSize='24px' />
    </Center>
  );
};

export default RoundCloseModal;
