import { Box, Flex, Text } from '@chakra-ui/react';
import { DeleteNoteModal, SlateViewer } from 'components';
import { useLocationSearch } from 'hooks';
import { useMemo, useState } from 'react';
import { formatDateToUTC } from 'utils';

const NotesCard = ({ data, createThread, userId, isReply, includeContext }) => {
  const search = useLocationSearch('note_id');

  const noteId = useMemo(() => {
    return search.split('?note_id=')[1];
  }, [search]);

  const [hoverId, setHoverId] = useState('');

  const showBtn = (id) => {
    setHoverId(id);
  };
  return (
    <Box>
      {data?.data?.map(({ note, _id, adminEmail, createdAt, parentNoteId, originalId }) => {
        const type = parentNoteId ? true : false;
        const thread_id = parentNoteId ? parentNoteId : _id;
        const is_active = !isReply && noteId === thread_id;
        return (
          <Box
            key={_id}
            bg={is_active ? '#F7F7FD' : 'transparent'}
            boxShadow={is_active ? '0px 1px 5px rgba(19, 10, 46, 0.25)' : 'none'}
            mb='8px'
            px='8px'
            transition='all 500ms ease-in-out'
            onMouseEnter={() => showBtn(thread_id)}
            onMouseLeave={() => setHoverId('')}
            _hover={{
              bg: !isReply ? '#F7F7FD' : 'initial',
              boxShadow: !isReply ? '0px 1px 5px rgba(19, 10, 46, 0.25)' : '',
            }}
          >
            <Box
              key={_id}
              as='ul'
              borderBottom='1px solid #eee'
              transition='transform 0.45s ease-out, all 0.4s ease-in'
              py='12px'
            >
              <Box
                as='li'
                listStyleType='disc'
              >
                <Flex
                  justifyContent='space-between'
                  onClick={!isReply ? () => createThread(thread_id, type) : () => {}}
                  cursor='pointer'
                >
                  <SlateViewer
                    key={_id}
                    id={_id}
                    value={note}
                    mb='0px'
                  />
                </Flex>

                <Flex
                  justifyContent='space-between'
                  gap='24px'
                >
                  <Flex
                    width='100%'
                    onClick={!isReply ? () => createThread(thread_id, type) : () => {}}
                    cursor='pointer'
                  >
                    <Text fontSize='14px'>
                      Added by{' '}
                      <Text
                        as='span'
                        fontWeight='500'
                        color='#6FBE45'
                      >
                        {adminEmail}{' '}
                      </Text>
                      on{' '}
                      <Text
                        as='span'
                        fontWeight='500'
                        color='brand.primary'
                      >
                        {formatDateToUTC(createdAt)}
                      </Text>
                    </Text>{' '}
                  </Flex>

                  {hoverId === thread_id && (
                    <DeleteNoteModal
                      noteId={originalId ? originalId : _id}
                      userId={userId}
                    />
                  )}
                </Flex>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default NotesCard;
