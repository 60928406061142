import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  VStack,
} from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  CustomDate,
  DateFilter,
  FilterSelect,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  TableDataStatus,
} from 'components';
import { fetchAllLoans, getLoanCustomDate } from 'store/slices/bcpl.slice';
import { lastWeek, last30Days, formatDateToUTC } from 'utils';

const BcplAllTransactions = () => {
  const dispatch = useDispatch();

  const { data, status, totalCount, beforeAndAfter } = useSelector(
    (state) => state.bcpl.loans
  );

  const [txStatus, setTxStatus] = useState('');
  const [customValue, setCustomValue] = useState('');
  const [clicked, setClicked] = useState(false);

  const handleClick = (value) => {
    setCustomValue(value);
    setCurrentPage(1);

    if (value === 'weekly=true') {
      dispatch(getLoanCustomDate(`afterDate=${lastWeek}`));
    }

    if (value === 'monthly=true') {
      dispatch(getLoanCustomDate(`afterDate=${last30Days}`));
    }

    if (value === 'custom') {
      setClicked(!clicked);
    }
  };

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    isDisabled,
    pageSize,
    offset,
  } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  useEffect(() => {
    dispatch(
      fetchAllLoans({
        limit: 10,
        page: currentPage,
        loanStatus: txStatus,
        time: beforeAndAfter,
      })
    );
  }, [dispatch, currentPage, txStatus, beforeAndAfter]);

  const handleStatusChange = (e) => {
    setTxStatus(e.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      <>
        <Flex mb="1rem" justifyContent="space-between">
          <Flex alignItems={'center'} gridGap={'1rem'}>
            <Text
              fontFamily="PolySans"
              color="#797A7A"
              fontSize="1rem"
              fontWeight="400"
            >
              Filter by status
            </Text>

            <FilterSelect width="180px" onChange={handleStatusChange}>
              <option value="">All transactions</option>
              <option value="paid">Paid</option>
              <option value="running">Running</option>
              <option value="cancelled">Cancelled</option>
              <option value="denied">Denied</option>
              <option value="processing">Processing</option>
            </FilterSelect>
          </Flex>

          <VStack alignItems={'flex-end'}>
            <DateFilter
              // bg="#EFF0F7"
              active={customValue}
              handleClick={handleClick}
            />

            <Box>{clicked && <CustomDate filterType="bcpl" />}</Box>
          </VStack>
        </Flex>

        {status === 'loading' && (
          <Box margin="8px 0">
            <Loader />
          </Box>
        )}
        <ScrollRightContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th>Amount</Th>
                <Th>Email address</Th>
                <Th>Coin Type</Th>
                <Th>Coin Volume</Th>
                <Th>Dollar rate</Th>
                <Th>Date</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data &&
                data.map(
                  ({
                    _id,
                    userDetails,
                    coin,
                    coinValue,
                    currencyValue,
                    currency,
                    loanStatus,
                    rates,
                    createdAt,
                  }) => {
                    return (
                      <Tr key={_id}>
                        <Td color="brand.primary">
                          <Link to={`/dashboard/users/${userDetails.userId}`}>
                            {userDetails.username}
                          </Link>
                        </Td>

                        <Td textTransform={'uppercase'}>
                          {coinValue} {coin}
                        </Td>

                        <Td>{userDetails.email}</Td>

                        <Td textTransform={'uppercase'}>{coin}</Td>

                        <Td textTransform={'uppercase'}>
                          {currencyValue} {currency}
                        </Td>

                        <Td textTransform={'uppercase'}>
                          {rates?.rate} {rates.ticker}
                        </Td>

                        <Td>{formatDateToUTC(createdAt)}</Td>

                        <Td>
                          <TableDataStatus status={loanStatus} />
                        </Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </ScrollRightContainer>
      </>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={pageSize}
        offset={offset}
        pages={pages}
      />
    </>
  );
};

export default BcplAllTransactions;
