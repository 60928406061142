import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Flex, useDisclosure } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  FilterSelect,
  HistoryTab,
  Loader,
  PaginationWrapper,
  TableBoxWrapper,
  PartnersFilterByDateModal,
  ScrollRightContainer,
} from 'components';
import { fetchLeaderboardRanking } from 'store/slices/partners.slice';
import { FilterSVG } from 'assets';

const PartnersEarningsReferred = () => {
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    data: { data, totalCount },
    dateFilter,
    status,
    errorMessage,
  } = useSelector((state) => state.partners.leaderBoardRanking);

  const [sortBy, setSortBy] = useState('referredCount');

  const handleSortBy = (e) => {
    if (e.target.value.length >= 3) {
      setSortBy(e.target.value);
    }
  };

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);

    setTimeout(() => {
      window.scrollTo({ top: 100, behavior: 'smooth' });
    }, 500);
  };

  useEffect(() => {
    dispatch(
      fetchLeaderboardRanking({
        limit: 10,
        page: currentPage,
        sortBy: `${sortBy}:desc`,
        beforeDate: dateFilter.endDate,
        afterDate: dateFilter.startDate,
      })
    );
  }, [dispatch, currentPage, sortBy, dateFilter]);

  return (
    <Box minWidth={{ base: '100%', md: '820px' }}>
      <Flex
        justifyContent='space-between'
        alignItems={{ base: 'baseline', md: 'center' }}
      >
        <HistoryTab
          firstText='Leaderboard'
          secondText='All Partners'
          url='/dashboard/partners'
        />

        <Flex
          alignItems='center'
          gridGap='1rem'
        >
          {dateFilter.endDate && (
            <Text>
              Start Date:{' '}
              <Text
                as='span'
                fontWeight='500'
              >
                {dateFilter.startDate}
              </Text>{' '}
              | End Date:{' '}
              <Text
                as='span'
                fontWeight='500'
              >
                {dateFilter.endDate}
              </Text>
            </Text>
          )}
          <Flex
            gridGap='5px'
            alignItems='center'
            onClick={onOpen}
            cursor='pointer'
            _hover={{
              fontWeight: '600',
              color: '#797A7A',
            }}
          >
            <Text
              fontSize='14px'
              fontWeight='500'
            >
              Filter
            </Text>

            <FilterSVG />
          </Flex>
        </Flex>
      </Flex>

      <>
        <TableBoxWrapper>
          <Box>
            <FilterSelect
              width='120px'
              onChange={handleSortBy}
              placeholder='Sort By'
            >
              <option value='referredCount'>Referred users</option>
              <option value='usdEarning'>Total Earnings</option>
            </FilterSelect>
          </Box>

          <Box mt='16px'>
            <>
              {status === 'loading' && (
                <Box margin='8px 0'>
                  <Loader />
                </Box>
              )}

              {status === 'error' && <p>{errorMessage}</p>}

              {status === 'fulfilled' && (
                <ScrollRightContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Full name</Th>
                        <Th>Username</Th>
                        <Th>Referred users</Th>
                        <Th>USD Earning</Th>
                        <Th>Level of verification</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {data &&
                        data.map(({ kycLevel, firstName, lastName, username, referredCount, usdEarning }, i) => {
                          return (
                            <Tr key={i}>
                              <Td
                                color='#6FBE45'
                                fontFamily='PostGrotesk'
                                fontWeight='500'
                                textTransform='capitalize'
                              >
                                {firstName} {lastName}
                              </Td>

                              <Td>{username}</Td>

                              <Td>{referredCount}</Td>

                              <Td>{usdEarning.toFixed(2)}</Td>

                              <Td textTransform={'capitalize'}>Level {kycLevel}</Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>

                  <PaginationWrapper
                    handlePageChange={handlePageChange}
                    totalDataCount={totalCount}
                    currentPage={currentPage}
                    isDisabled={isDisabled}
                    pagesCount={pagesCount}
                    size={pageSize}
                    offset={offset}
                    pages={pages}
                  />
                </ScrollRightContainer>
              )}
            </>
          </Box>

          <PartnersFilterByDateModal
            isOpen={isOpen}
            onClose={onClose}
          />
        </TableBoxWrapper>
      </>
    </Box>
  );
};

export default PartnersEarningsReferred;
