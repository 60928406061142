import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constant, ls } from 'utils';
const auth = ls.get(constant.AUTH_TOKEN_KEY);

export const wishlistApi = createApi({
  reducerPath: 'wishlist',
  refetchOnMountOrArgChange: true,
  tagTypes: ['allwishlist', 'wishlistTag'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, _) => {
      headers.set('Authorization', auth.token);
      headers.set('Content-Type', 'application/json');
      // headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // All wishlist admin created
    getAllWishlist: builder.query({
      query: ({ page, criteria }) => `/wishlist/admin/all${criteria ? `?criteria=${criteria}` : ''}`,
      transformResponse: (res) => res.data,
      providesTags: ['allwishlist'],
    }),

    createWishlist: builder.mutation({
      query: ({ gifts, criteria, feature, tag, frequency }) => ({
        url: `/wishlist/admin/create`,
        method: 'POST',
        body: {
          gifts,
          criteria,
          feature,
          tag,
          frequency,
        },
      }),

      transformResponse: (res) => res,
      invalidatesTags: ['wishlistTag'],
    }),

    endCampaign: builder.mutation({
      query: (id) => ({
        url: `wishlist/admin/end-campaign?wishlistId=${id}`,
        method: 'PATCH',
        body: {},
      }),

      transformResponse: (res) => res,
      invalidatesTags: ['wishlistTag'],
    }),

    getAllWishlistTagEntry: builder.query({
      query: () => `/wishlist/admin/entry`,
      transformResponse: (res) => res.data,
    }),

    // All users in campaign
    getWishlistEntryTag: builder.query({
      query: ({ entry, cycle, status, _id, page }) =>
        `/wishlist/admin/entry?page=${page}&limit=10&tag=${entry}${status ? `&status=${status}` : ''}${cycle ? `&wishlistCycle=${cycle}` : ''
        }&wishlistId=${_id}`,
      transformResponse: (res) => res.data,
      providesTags: ['wishlistTag'],
    }),

    selectWinner: builder.mutation({
      query: ({ tag, cycle, userWishListId, action, criteria }) => ({
        url: `/wishlist/admin/winner`,
        method: 'POST',
        body: {
          tag,
          cycle,
          userWishListId,
          action,
          criteria,
        },
      }),

      transformResponse: (res) => res,
      invalidatesTags: ['wishlistTag'],
    }),
  }),
});

export const {
  useGetAllWishlistQuery,
  useCreateWishlistMutation,
  useGetAllWishlistTagEntryQuery,
  useGetWishlistEntryTagQuery,
  useSelectWinnerMutation,
  useEndCampaignMutation,
} = wishlistApi;

export default wishlistApi;
