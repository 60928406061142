import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Heading } from '@chakra-ui/react';
import { P2PCancelledPendingTable, P2PCompletedTable, P2PFilter, PageTab } from 'components';
import { useLocationSearch } from 'hooks';
import { P2pTabLinkArray } from 'LinkArray';
import { fetchP2PCompletedTrades, fetchP2PContractsCancelled, fetchP2PContractsPending } from 'store/slices/p2p.slice';

const P2PTradeTable = () => {
  const dispatch = useDispatch();
  const search = useLocationSearch();
  const { trade, contractsPending, contractsCancelled } = useSelector((state) => state.p2p);

  const { currency, coin, date } = useSelector((state) => state.p2p.filter);

  useEffect(() => {
    if (
      (Object.entries(trade.data).length === 0 && trade.status !== 'fulfilled') ||
      String(currency).length >= 3 ||
      String(coin).length >= 3 ||
      String(date).length >= 1
    ) {
      dispatch(
        fetchP2PCompletedTrades({
          page: 1,
          limit: 10,
          coin: coin === 'all' ? '' : coin,
          currency: currency === 'all' ? '' : currency,
          date: date.before === undefined ? `beforeDate=${date}` : `afterDate=${date.before}&beforeDate=${date.after}`,
          adId: '',
        })
      );
    }

    if (
      (contractsPending.data.trades.length === 0 && contractsPending.status !== 'fulfilled') ||
      String(currency).length >= 3 ||
      String(coin).length >= 3 ||
      String(date).length >= 1
    ) {
      dispatch(
        fetchP2PContractsPending({
          page: 1,
          limit: 10,
          coin: coin === 'all' ? '' : coin,
          currency: currency === 'all' ? '' : currency,
          date: date.before === undefined ? `beforeDate=${date}` : `afterDate=${date.before}&beforeDate=${date.after}`,
          adId: '',
        })
      );
    }

    if (
      (contractsCancelled.data.trades.length === 0 && contractsCancelled.status !== 'fulfilled') ||
      String(currency).length >= 3 ||
      String(coin).length >= 3 ||
      String(date).length >= 1
    ) {
      dispatch(
        fetchP2PContractsCancelled({
          page: 1,
          limit: 10,
          coin: coin === 'all' ? '' : coin,
          currency: currency === 'all' ? '' : currency,
          date: date.before === undefined ? `beforeDate=${date}` : `afterDate=${date.before}&beforeDate=${date.after}`,
          adId: '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search, currency, coin, date]);

  return (
    <Box
      boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
      maxWidth='785px'
      width='100%'
      bg='#FCFDFD'
      borderRadius='10px'
      height='1130px'
      padding='23px 15px'
    >
      <Heading
        mb='2rem'
        as='h2'
        fontFamily='PolySans'
        fontSize='1rem'
        fontWeight='700'
      >
        Trades
      </Heading>

      <PageTab linkArray={P2pTabLinkArray} />

      <Box mb='32px'>
        <P2PFilter />
      </Box>

      <>
        {(search === '?completed' || search === '') && <P2PCompletedTable tradeData={trade} />}

        {search === '?pending' && <P2PCancelledPendingTable contracts={contractsPending} />}

        {search === '?cancelled' && <P2PCancelledPendingTable contracts={contractsCancelled} />}
      </>
    </Box>
  );
};

export default P2PTradeTable;
