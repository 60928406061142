import { Box, Center, Text } from '@chakra-ui/react';
import { FileUploadSVG } from 'assets';
import { acceptedSheetFormat } from 'utils';

const BatchUpload = ({ onChange }) => {
  return (
    <Box
      bg='rgba(49, 183, 169, 0.1)'
      borderRadius='13px'
      padding='12px'
      width={{ sm: '100%', md: '448px' }}
    >
      <Center
        flexDir='column'
        border='1px dashed #1D776D'
        borderRadius='12px'
        padding='21px 0 11px'
      >
        <FileUploadSVG />

        <Text
          margin='5px 0 16px'
          fontSize='16px'
        >
          Chose File
        </Text>

        <Center
          color='transparent'
          as='input'
          type='file'
          onChange={onChange}
          height='18px'
          css={{
            '::-webkit-file-upload-button': {
              visibility: 'hidden',
            },
            ':before': {
              content: "'Browse'",
              display: 'inline-block',
              fontSize: '12px',
              fontWeight: '500',
              color: '#31B7A9',
              textAlign: 'center',
              textDecoration: 'underline',
              cursor: 'pointer',
              padding: '0',
            },
          }}
          accept={acceptedSheetFormat}
        />
      </Center>
    </Box>
  );
};

export default BatchUpload;
