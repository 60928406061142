import { Heading } from '@chakra-ui/react';

const PageTitle = ({ header = true, title, ...props }) => {
  return (
    <Heading
      color={header ? '#303131' : '#041815'}
      fontWeight={header ? '700' : '600'}
      fontSize='24px'
      lineHeight='29px'
      {...props}
    >
      {title}
    </Heading>
  );
};

export default PageTitle;
