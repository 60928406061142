import { Box } from '@chakra-ui/react';
import { PageTab, PageTitle } from 'components';
import { AllDisbursement, CreateDisbursements } from 'pages';
import { useLocationSearch } from 'hooks';
import { DisburseTabLinkArray } from 'LinkArray';

const Disbursements = () => {
  const search = useLocationSearch();
  return (
    <Box>
      <PageTitle title="Disbursements" />

      <Box
        mt="27px"
        bg="#FCFDFD"
        boxShadow="0px 2px 8px rgba(229, 229, 229, 0.47)"
        borderRadius="10px"
        minHeight="700px"
        padding="15px 25px"
      >
        <PageTab linkArray={DisburseTabLinkArray} />

        {search === '' && <CreateDisbursements />}

        {search === '?all' && <AllDisbursement />}
      </Box>
    </Box>
  );
};

export default Disbursements;
