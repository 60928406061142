import { useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { StepOne, StepThree, StepTwo } from './LevelThreeUploadSteps';

const LevelThreeUpload = ({ kycLevel, data, onClose, userId }) => {
  const [bankCode, setBankCode] = useState('');
  const [state, setState] = useState({
    branchName: '',
    street: '',
    houseFlat: '',
    zipCode: '',
    city: '',
    state: '',
    country: data?.country?.toLowerCase(),
    bankStatementLink: '',
    accountName: '',
    bankAccountNumber: '',
    bankId: '',
    category: 'primary',
  });

  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const steps = [
    {
      label: '',
      content: (
        <StepOne
          handleChange={handleChange}
          state={state}
          setState={setState}
          nextStep={nextStep}
          country={data?.country?.toLowerCase()}
          bankCode={bankCode}
          setBankCode={setBankCode}
        />
      ),
    },
    {
      label: '',
      content: (
        <StepTwo
          handleChange={handleChange}
          state={state}
          setState={setState}
          nextStep={nextStep}
          country={data?.country?.toLowerCase()}
        />
      ),
    },
    {
      label: '',
      content: (
        <StepThree
          handleChange={handleChange}
          state={state}
          setState={setState}
          email={data.email}
          userData={data}
          kycLevel={kycLevel}
          onClose={onClose}
          bankCode={bankCode}
          userId={userId}
        />
      ),
    },
  ];

  return (
    <Box width='100%'>
      <Text
        fontSize='24px'
        fontWeight='600'
        mb='16px'
      >
        Level Three
      </Text>

      <Steps
        activeStep={activeStep}
        mb='24px'
      >
        {steps.map(({ label, content }, index) => (
          <Step
            label={label}
            key={index}
          >
            {content}
          </Step>
        ))}
      </Steps>

      <Box mt='24px'>
        <Center
          width='100%'
          justify='flex-end'
          flexDir='column'
        >
          {activeStep >= 1 && (
            <Box
              mt='18px'
              onClick={prevStep}
              cursor='pointer'
            >
              Prev
            </Box>
          )}
        </Center>
      </Box>
    </Box>
  );
};

export default LevelThreeUpload;
