import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ls, constant, wrapBaseApi } from 'utils';

const auth = ls.get(constant.AUTH_TOKEN_KEY);

export const disbursementApi = createApi({
  reducerPath: 'disbursement',
  refetchOnMountOrArgChange: true,
  tagTypes: ['disbursement'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, _) => {
      headers.set('Authorization', `${auth.token}`);
      headers.set('Content-Type', 'application/json');
      headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllDisbursements: builder.query({
      query: ({ limit, page }) => wrapBaseApi(`/wallet/admin/disbursements?limit=${10}&page=${page}`),
      transformResponse: (res) => res.data,
      providesTags: ['disbursement'],
    }),

    createDisbursement: builder.mutation({
      query: ({ description, recipients, senderAlias, amount, unit }) => ({
        url: wrapBaseApi(`/wallet/admin/disbursements/create`),
        method: 'POST',
        body: {
          description,
          recipients,
          senderAlias,
          amount,
          unit,
        },
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['disbursement'],
    }),

    processDisbursement: builder.mutation({
      query: ({ id }) => ({
        url: wrapBaseApi(`/wallet/admin/disbursements/${id}`),
        method: 'POST',
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['disbursement'],
    }),
  }),
});

export const { useGetAllDisbursementsQuery, useCreateDisbursementMutation, useProcessDisbursementMutation } =
  disbursementApi;

export default disbursementApi;
