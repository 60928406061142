import { Avatar, Box, Flex, Heading, Text } from '@chakra-ui/react';
import { DownArrowSVG, GoalSVG, NotificationSVG, UpArrowSVG } from 'assets';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchTotalUserCount } from 'store/slices/user.slice';

const Header = () => {
  const {
    totalUserCount: { totalCount },
  } = useSelector((state) => state.allUsers);

  const { data } = useSelector((state) => state.auth.profile);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTotalUserCount());
  }, [dispatch]);

  return (
    <Box
      bg='#FFFFFF'
      borderBottom='0.5px solid #CACBCB'
      padding={{ sm: '20px 12px', lg: '20px 52px' }}
      position='sticky'
      top='0'
      width='100%'
      zIndex='9'
    >
      <Flex
        maxWidth='1140px'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        margin={{ lg: 'auto' }}
      >
        <Flex
          flexDir={{ sm: 'column', tb: 'row' }}
          gap='4px'
        >
          <Flex
            gap={{ sm: '2px', tb: '20px' }}
            alignItems='center'
          >
            <Flex
              gap='2px'
              alignItems={{ sm: 'center' }}
            >
              <GoalSVG />{' '}
              <Text
                color='brand.red'
                fontWeight='500'
                fontSize={{ sm: '14px', tb: '18px' }}
                lineHeight={{ sm: '14px', tb: '24px' }}
              >
                Goal: &nbsp;
              </Text>
            </Flex>
            <Text
              fontWeight='500'
              fontSize={{ sm: '14px', tb: '18px' }}
              lineHeight={{ sm: '14px', tb: '24px' }}
              color='brand.success'
            >
              One billion Users
            </Text>
          </Flex>

          <Flex alignItems='center'>
            <UpArrowSVG color='' /> &nbsp;
            <Text
              fontWeight='600'
              letterSpacing='-0.5px'
              fontSize={{ sm: '14px', tb: '18px' }}
              lineHeight={{ sm: '14px', tb: '24px' }}
              color='brand.success'
            >
              {(1000000000 - Number(totalCount)).toLocaleString()} users to go
            </Text>
          </Flex>
        </Flex>

        <Box
          padding='6px 10px 5px 14px'
          borderRadius='10px'
          bg='rgba(242, 244, 243, 0.5)'
        >
          <Link to='/dashboard/account'>
            <Flex alignItems='center'>
              <Box>
                <Avatar
                  size='sm'
                  name={data?.fullName}
                />
              </Box>

              <Flex
                gridGap='4px'
                alignItems='center'
                margin='0 17px 0 10px'
                display={{ sm: 'none', tb: 'flex' }}
              >
                <Heading
                  fontWeight='600'
                  fontSize='12px'
                  lineHeight='14px'
                  letterSpacing='1.23586px'
                >
                  {data?.fullName}
                </Heading>

                <DownArrowSVG />
              </Flex>

              <NotificationSVG />
            </Flex>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
