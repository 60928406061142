import { useToast, Select } from '@chakra-ui/react';
import { put } from 'utils/axiosLib';
import { fetchSingleUser } from 'store/slices/user.slice';
import { BASE_URL } from 'constant';

const ChangeKycLevel = ({ kycLevel, id, headers, dispatch }) => {
  const toast = useToast();

  const handleChange = async (e) => {
    const level = e.target.value;
    const URL = `${BASE_URL}/admin/upgrade/user/${id}`;

    try {
      const response = await put(
        URL,
        {
          level,
        },
        headers
      );

      if (response.data) {
        dispatch(fetchSingleUser(id));

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: 'bottom-right',
        title: 'error',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Select
        _hover={{
          bg: 'rgba(49, 183, 169, 0.2)',
        }}
        _focus={{
          bg: 'rgba(49, 183, 169, 0.2)',
        }}
        bg="rgba(49, 183, 169, 0.2)"
        borderRadius="5px"
        borderWidth="0"
        width="79px"
        fontSize="12px"
        lineHeight="16px"
        height="38px"
        fontWeight="400"
        defaultValue={kycLevel}
        defaultChecked={kycLevel}
        onChange={handleChange}
      >
        <option value="one">One</option>
        <option value="two">Two</option>
        <option value="three">Three</option>
      </Select>
    </>
  );
};

export default ChangeKycLevel;
