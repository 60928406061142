import { Box, Flex, Radio, RadioGroup } from '@chakra-ui/react';
import { PageTab, PageTitle, RoundBox, StaffBatchPayment, WalletDepositTable, WalletWithdrawalTable } from 'components';
import { useLocationSearch, useStorage } from 'hooks';
import IntegrationsTransfer from 'pages/Integrations/IntegrationsTransfer';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetPaidWalletTransactionsQuery, useGetPendingWithdrawalsQuery } from 'store/services/request.service';
import { constant } from 'utils';

const Wallet = () => {
  const search = useLocationSearch();
  const { data } = useGetPaidWalletTransactionsQuery();
  const { type } = useSelector((state) => state.wallet.withdrawalType);

  const { data: withdrawal } = useGetPendingWithdrawalsQuery({
    withdrawalType: type,
  });

  const WalletTabLinkArray = [
    {
      id: 1,
      search: '',
      title: (
        <>
          <Flex
            justifyContent='center'
            alignItems='center'
            gridGap='8px'
          >
            Deposit
            <RoundBox count={data?.totalCount} />
          </Flex>
        </>
      ),
      to: '/dashboard/inflow-outflow',
      otherSearchValue: '?type=crypto',
    },
    {
      id: 2,
      search: '?withdrawal',
      title: (
        <>
          <Flex
            justifyContent='center'
            alignItems='center'
            gridGap='8px'
          >
            Withdrawal
            <RoundBox count={withdrawal?.totalCount} />
          </Flex>
        </>
      ),
      to: '/dashboard/inflow-outflow?withdrawal',
    },
    {
      id: 3,
      search: '?batch-payment',
      title: 'Batch Payment',
      to: '/dashboard/inflow-outflow?batch-payment',
    },
  ];

  const navigate = useNavigate();
  const { storageValue, setStorage } = useStorage(constant.WIDGET_TRANSFER);
  const { storageValue: mainValue, setStorage: setMain } = useStorage(constant.MAIN_URL);
  const { storageValue: widgetValue, setStorage: setWidget } = useStorage(constant.WIDGET_URL);

  const { setStorage: setLastPath } = useStorage(constant.LAST_PATH);

  const currentUrl = useMemo(() => {
    setLastPath(search);
    return search;
  }, [search, setLastPath]);

  useEffect(() => {
    if (storageValue === 'main' || storageValue === null) {
      setMain(currentUrl);
    } else {
      setWidget(currentUrl);
    }
  }, [currentUrl, setMain, setWidget, storageValue]);

  const handleWidget = (value) => {
    setStorage(value);

    if (value === 'bitwaya') {
      navigate(`/dashboard/inflow-outflow${widgetValue ?? ''}`);
    } else {
      navigate(`/dashboard/inflow-outflow${mainValue ?? ''}`);
    }
  };

  const checkState = storageValue === null || storageValue === 'main';

  return (
    <Box>
      <Flex
        alignItems='center'
        justifyContent='space-between'
      >
        <PageTitle title={checkState ? 'Inflow/Outflow' : 'Integration Transfer'} />

        <Flex
          alignItems='center'
          gridGap='32px'
        >
          <Flex gridGap={{ base: '8px', md: '16px' }}>
            <RadioGroup
              defaultValue={checkState ? 'main' : 'bitwaya'}
              onChange={handleWidget}
              colorScheme='green'
            >
              <Flex gap='24px'>
                <Radio value='main'>Main</Radio>
                <Radio value='bitwaya'>Bitwaya</Radio>
              </Flex>
            </RadioGroup>
          </Flex>
        </Flex>
      </Flex>

      <Box
        mt='27px'
        bg='#FCFDFD'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
        borderRadius='10px'
        minHeight='700px'
        padding='15px 25px'
      >
        <>
          {checkState ? (
            <>
              <PageTab linkArray={WalletTabLinkArray} />

              {(search === '' || search === '?type=crypto') && <WalletDepositTable />}

              {search === '?withdrawal' && <WalletWithdrawalTable />}

              {search === '?batch-payment' && <StaffBatchPayment />}
            </>
          ) : (
            <>
              <IntegrationsTransfer />
            </>
          )}
        </>
      </Box>
    </Box>
  );
};

export default Wallet;
