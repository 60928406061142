import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal, ConfigCheckbox, Input, Select } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateMerchantMutation } from 'store/services/merchants.service';
import { fetchBanks } from 'store/slices/bank.slice';

const UpdateMerchantNameModal = ({ isOpen, onClose, data }) => {
  const toast = useToast();
  const { _id, firstName, lastName, email, phone, bankDetails, merchantAccountId } = data;

  const initialState = {
    firstName,
    lastName,
    email,
    phone,
    bankAccountName: bankDetails?.bankAccountName,
    bankAccountNumber: bankDetails?.bankAccountNumber,
    bankName: bankDetails?.bankName,
    accountType: bankDetails?.accountType,
    currency: bankDetails?.currency,
  };

  const [state, setState] = useState(initialState);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const defaultChecked = useMemo(() => {
    return Boolean(data?.assignable);
  }, [data]);

  const [checked, setChecked] = useState(defaultChecked);

  const [_updateMerchant, { isLoading }] = useUpdateMerchantMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, phone, firstName, lastName, bankAccountName, bankAccountNumber, bankName, accountType, currency } =
      state;
    const response = await _updateMerchant({
      _id,
      ...(email !== initialState.email && { email }),
      ...(phone !== initialState.phone && { phone }),
      ...(firstName !== initialState.firstName && { firstName }),
      ...(lastName !== initialState.lastName && { lastName }),
      ...(bankAccountName !== initialState.bankAccountName && { bankAccountName }),
      ...(bankAccountNumber !== initialState.bankAccountNumber && { bankAccountNumber }),
      ...(bankName !== initialState.bankName && { bankName }),
      ...(accountType !== initialState.accountType && { accountType }),
      ...(currency !== initialState.currency && { currency }),

      merchantAccountId,
      assignable: checked,
    });

    if (response.data) {
      onClose();

      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response.data.message,
        status: 'success',
        duration: 18000,
        isClosable: true,
      });
    }

    if (response.error) {
      toast({
        position: 'bottom-right',
        title: 'Oops',
        // description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  const dispatch = useDispatch();
  const userCountry = useMemo(() => {
    const countryList = {
      ngn: 'ng',
      ghs: 'gh',
      kes: 'ng',
    };

    return countryList[state.currency];
  }, [state.currency]);

  useEffect(() => {
    dispatch(fetchBanks(userCountry));
  }, [dispatch, userCountry]);

  const { data: bankData } = useSelector((state) => state.allBanks.banks);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      key={_id}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        bg='#fff'
        maxWidth='520px'
        padding={{ base: '40px 16px 20px', md: '40px 40px 20px' }}
      >
        <Text
          fontSize='24px'
          textAlign='center'
          fontWeight='600'
        >
          Update Merchant
        </Text>

        <ModalBody
          padding='0px'
          mt='20px'
        >
          <form onSubmit={handleSubmit}>
            <VStack spacing='24px'>
              <Flex gridGap='12px'>
                <Input
                  width='100%'
                  label='First Name'
                  defaultValue={firstName}
                  name='firstName'
                  onChange={handleChange}
                />

                <Input
                  width='100%'
                  defaultValue={lastName}
                  label='Last Name'
                  name='lastName'
                  onChange={handleChange}
                />
              </Flex>

              <Flex
                width='100%'
                gridGap='12px'
              >
                <Box width='100%'>
                  <Text
                    fontSize='12px'
                    mb='8px'
                  >
                    Currency
                  </Text>
                  <Select
                    minH='60px'
                    defaultValue={state?.currency}
                    bg='#F6F8F7'
                    label='Currency'
                    name='currency'
                    borderRadius='15px'
                    onChange={handleChange}
                  >
                    <option value='kes'>KES</option>
                    <option value='ngn'>NGN</option>
                    <option value='ghs'>GHS</option>
                  </Select>
                </Box>

                <Box width='100%'>
                  <Text
                    fontSize='12px'
                    mb='8px'
                  >
                    Bank Name
                  </Text>

                  <Select
                    onChange={handleChange}
                    id='bankCode'
                    minH='60px'
                    bg='#F6F8F7'
                    fontSize='14px'
                    isRequired
                    name='bankName'
                    borderRadius='15px'
                  >
                    {bankData.bankPayloads
                      ? bankData.bankPayloads.map(({ bankName }, index) => {
                          return (
                            <option
                              key={index}
                              value={bankName}
                            >
                              {bankName}
                            </option>
                          );
                        })
                      : bankData.ghanaBankPayloads.map(({ bankName }, index) => {
                          return (
                            <option
                              key={index}
                              value={bankName}
                            >
                              {bankName}
                            </option>
                          );
                        })}
                  </Select>
                </Box>
              </Flex>

              <Flex gridGap='12px'>
                <Input
                  width='100%'
                  defaultValue={state?.bankAccountName}
                  label='Bank Account Name'
                  name='bankAccountName'
                  onChange={handleChange}
                />

                <Input
                  width='100%'
                  defaultValue={state?.bankAccountNumber}
                  label='Bank Account Number'
                  name='bankAccountNumber'
                  onChange={handleChange}
                />
              </Flex>

              <Flex
                width='100%'
                gridGap='12px'
              >
                <Input
                  width='100%'
                  defaultValue={email}
                  label='Email'
                  name='email'
                  onChange={handleChange}
                />
              </Flex>

              <Flex
                width='100%'
                gridGap='12px'
              >
                <Input
                  width='100%'
                  defaultValue={phone}
                  label='Phone Number'
                  name='phone'
                  onChange={handleChange}
                />
              </Flex>

              <Flex
                alignSelf='flex-start'
                gridGap='12px'
              >
                <Text>Assignable:</Text>

                <ConfigCheckbox
                  defaultChecked={defaultChecked}
                  onChange={() => setChecked(!checked)}
                />
              </Flex>
            </VStack>

            <Center mt='42px'>
              <Button
                width='100%'
                maxWidth='220px'
                type='submit'
                isLoading={isLoading}
                disabled={isLoading}
              >
                Submit
              </Button>
            </Center>
          </form>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdateMerchantNameModal;
