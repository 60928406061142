import { useState } from 'react';
import { VStack, Text, Flex, Box, Image, Center } from '@chakra-ui/react';
import { Input, InputSelect } from 'components';
import ChallengeIconUpload from 'components/FileUpload/ChallengeIconUpload';
import PlusSVG from 'assets/svg/plus.svg';
import DeleteSVG from 'assets/svg/delete.svg';

const LevelTarget = ({ handleAddLevelTarget, index, onChange, handleDelete }) => {
  const [selectedFile, setSelectedFile] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;

    onChange(name, name === 'currency' || name === 'description' ? value : Number(value));
  };

  const handleFileChange = (file) => {
    onChange('file', file);
    setSelectedFile(file);
  };

  return (
    <VStack
      alignItems='revert-layer'
      spacing='8px'
      mb='12px'
    >
      <Flex
        justifyContent='space-between'
        alignItems='center'
      >
        <Text fontWeight='500'>{index + 1}. Level Target</Text>

        <Flex
          alignItems='center'
          gap='8px'
        >
          <Center
            cursor='pointer'
            onClick={handleAddLevelTarget}
            alignItems='center'
            boxShadow='0px 2px 8px rgb(229 229 229 / 47%)'
            bg='#6FBE45'
            borderRadius='24px'
            width='100px'
            height='48px'
            gap='8px'
            _hover={{
              transform: 'scale(1.01, 1.0)',
              transition: 'transform 0.2s ease-out',
            }}
          >
            <Image
              src={PlusSVG}
              width='24px'
              height='24px'
              alt='plus'
            />

            <Text
              fontWeight='500'
              color='#fff'
            >
              Add
            </Text>
          </Center>

          {index >= 1 && (
            <Box
              onClick={handleDelete}
              cursor='pointer'
            >
              <Image
                src={DeleteSVG}
                width='24px'
                height='24px'
              />
            </Box>
          )}
        </Flex>
      </Flex>

      <Input
        label='Level'
        name='level'
        placeholder=''
        type='number'
        labelSize='16px'
        onChange={handleChange}
        isRequired
      />

      <ChallengeIconUpload
        selectedFile={selectedFile}
        setSelectedFile={handleFileChange}
        title='Choose level badge icon'
      />

      <Input
        label='Target'
        name='target'
        placeholder=''
        type='number'
        labelSize='16px'
        step='any'
        onChange={handleChange}
        isRequired
      />

      <Input
        label='Description'
        name='description'
        placeholder='Description'
        type='text'
        labelSize='16px'
        onChange={handleChange}
        isRequired
      />
      <Flex
        width='100%'
        gap='16px'
      >
        <Box width='100%'>
          <Text
            display='block'
            mb='0.5rem'
            htmlFor='currency'
            as='label'
          >
            Currency
          </Text>

          <InputSelect
            name='currency'
            width='100%'
            fontSize='16px'
            onChange={handleChange}
            minH='60px'
          >
            <option value=''>Currency</option>
            <option value='usdt'>USDT</option>
            <option value='usdc'>USDC</option>
            <option value='pyusd'>PYUSD</option>
            <option value='cusd'>cUSD</option>
          </InputSelect>
        </Box>

        <Input
          label='Amount'
          name='amount'
          placeholder=''
          type='number'
          step='any'
          labelSize='16px'
          width='100%'
          onChange={handleChange}
          isRequired
        />
      </Flex>
    </VStack>
  );
};
export default LevelTarget;
