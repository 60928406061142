import { Box } from '@chakra-ui/react';
import { HomeChart, HomeWallet, Overview, PageTitle } from 'components';

const Home = () => {
  return (
    <Box>
      <PageTitle
        header
        title='Dashboard'
        textAlign={{ sm: 'center', md: 'initial' }}
      />

      <Overview />

      <HomeWallet />

      <HomeChart />
    </Box>
  );
};

export default Home;
