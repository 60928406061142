import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal, Input, Select } from 'components';
import { useState } from 'react';
import { useAllMerchantsQuery, useAddMerchantMutation } from 'store/services/merchants.service';

const AddMerchantModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    bankName: '',
    bankAccountNumber: '',
    bankAccountName: '',
    accountType: 'bank',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const [_addMerchant, { isLoading }] = useAddMerchantMutation();

  const { refetch } = useAllMerchantsQuery({
    page: 1,
    size: 10,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, phone, firstName, lastName, bankAccountName, bankAccountNumber, bankName, accountType } = state;

    try {
      const response = await _addMerchant({
        firstName,
        lastName,
        email,
        phone,
        bankAccountName,
        bankAccountNumber,
        bankName,
        accountType,
      }).unwrap();
      console.log(response, 'response');
      if (response.data) {
        refetch();

        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.message,
          status: 'success',
          duration: 18000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error, 'error');
      toast({
        position: 'bottom-right',
        title: 'Oops',
        // description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  console.log(state, 'THE STATE');

  return (
    <Box>
      <Button
        minH='45px'
        minW='180px'
        onClick={onOpen}
      >
        Add Merchant
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          bg='#fff'
          maxWidth='520px'
          padding={{ base: '40px 16px 20px', md: '40px 40px 20px' }}
        >
          <Text
            fontSize='24px'
            textAlign='center'
            fontWeight='600'
          >
            Add Merchant
          </Text>

          <ModalBody
            padding='0px'
            mt='20px'
          >
            <form onSubmit={handleSubmit}>
              <VStack
                spacing='24px'
                alignItems='revert-layer'
              >
                <Flex gridGap='12px'>
                  <Input
                    width='100%'
                    label='First Name'
                    name='firstName'
                    onChange={handleChange}
                    isRequired
                  />

                  <Input
                    width='100%'
                    label='Last Name'
                    name='lastName'
                    onChange={handleChange}
                    isRequired
                  />
                </Flex>

                <Flex gridGap='12px'>
                  <Input
                    width='100%'
                    label='Email'
                    name='email'
                    onChange={handleChange}
                    isRequired
                  />

                  <Input
                    width='100%'
                    label='Phone Number'
                    name='phone'
                    onChange={handleChange}
                    isRequired
                  />
                </Flex>

                <Flex gridGap='12px'>
                  <Input
                    width='100%'
                    label='Bank Account Name'
                    name='bankAccountName'
                    onChange={handleChange}
                    isRequired
                  />

                  <Input
                    width='100%'
                    label='Bank Account Number'
                    name='bankAccountNumber'
                    onChange={handleChange}
                    isRequired
                  />
                </Flex>

                <Flex gridGap='12px'>
                  <Input
                    width='100%'
                    label='Bank Name'
                    name='bankName'
                    onChange={handleChange}
                  />

                  <Box width='100%'>
                    <Text
                      fontSize='12px'
                      mb='8px'
                    >
                      Account Type
                    </Text>

                    <Select
                      id='bankCode'
                      minH='60px'
                      bg='#F6F8F7'
                      fontSize='14px'
                      isRequired
                      name='accountType'
                      borderRadius='15px'
                      onChange={handleChange}
                    >
                      <option value='bank'>Bank</option>
                      <option value='mobilemoney'>Mobile Money</option>
                    </Select>
                  </Box>
                </Flex>
              </VStack>

              <Center mt='42px'>
                <Button
                  width='100%'
                  maxWidth='220px'
                  type='submit'
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </Center>
            </form>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddMerchantModal;
