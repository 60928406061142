import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination } from '@ajna/pagination';
import { Box, Table, Tr, Td, Th, Thead, Tbody, Flex, useDisclosure, Text } from '@chakra-ui/react';
import {
  FilterSelect,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  Select,
  TableActionButton,
  TableDataStatus,
  ViewMore,
  WalletDepositModal,
} from 'components';
import { fetchDepositById } from 'store/slices/wallet.slice';
import { formatDateToUTC } from 'utils';

const FiatDepositHistory = ({ userId }) => {
  const dispatch = useDispatch();

  const { data, status } = useSelector((state) => state.wallet.singleDeposit);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset, pageSize } = usePagination({
    initialState: { currentPage: 1, pageSize: 10 },
    total: data.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [depositPaymentType, setDepositPaymentType] = useState('');
  const [txStatus, setTxStatus] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const [size, setSize] = useState(10);

  const handleStatusChange = (e) => {
    setCurrentPage(1);
    setTxStatus(e.target.value);
  };

  const handleDepositPaymentType = (e) => {
    setCurrentPage(1);
    setDepositPaymentType(e.target.value);
  };

  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const [actionValue, setActionValue] = useState('');

  const handleActions = (e, data) => {
    setActionValue(e.target.value);
    onOpen();
    setSelectedData(data);
  };

  useEffect(() => {
    dispatch(
      fetchDepositById({
        userId,
        page: currentPage,
        limit: size,
        depositPaymentType: depositPaymentType,
        status: txStatus,
      })
    );
  }, [currentPage, depositPaymentType, dispatch, size, txStatus, userId]);
  return (
    <>
      {status === 'loading' && <Loader mb='1rem' />}

      <Box>
        <Flex
          mb='24px'
          gridGap='10px'
        >
          <FilterSelect
            width='88px'
            onChange={handleSize}
          >
            <option value={10}>Size</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </FilterSelect>

          <FilterSelect
            width='109px'
            onChange={handleStatusChange}
            placeholder='Status'
          >
            <option value='approved'>Approved</option>
            <option value='cancelled'>Cancelled</option>
            <option value='denied'>Denied</option>
            <option value='paid'>Paid</option>
            <option value='cleared'>Cleared</option>
            <option value='pending'>Pending</option>
          </FilterSelect>

          <FilterSelect
            width='120px'
            onChange={handleDepositPaymentType}
            placeholder='Source'
          >
            <option value='bank'>Bank</option>
            <option value='flutterwave'>Flutterwave</option>
          </FilterSelect>
        </Flex>

        {status === 'fulfilled' && data.totalCount === 0 && (
          <Text
            fontSize='18px'
            fontWeight='500'
          >
            User has no fiat deposit
          </Text>
        )}

        {(data && data.deposits).length >= 1 && (
          <>
            <ScrollRightContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Name</Th>
                    <Th>Amount</Th>
                    <Th>Date & Time</Th>
                    <Th>Source</Th>
                    <Th>Status</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {data &&
                    data.deposits.map(
                      (
                        {
                          _id,
                          amount,
                          createdAt,
                          currency,
                          depositPaymentType,
                          userDetails,
                          reference,
                          status,
                          txRefId,
                          depositBankTo,
                          paymentUploadUrl,
                          version,
                          fee,
                          denyReason,
                        },
                        index
                      ) => {
                        const modalData = {
                          _id,
                          amount,
                          createdAt,
                          currency,
                          depositPaymentType,
                          userDetails,
                          reference,
                          status,
                          txRefId,
                          depositBankTo,
                          paymentUploadUrl,
                          version,
                          fee,
                          denyReason,
                        };
                        return (
                          <Tr key={_id}>
                            <Td>{index + 1}</Td>

                            <Td>{userDetails?.fullName}</Td>

                            <Td>
                              {currency === 'ngn' && <span>&#x20A6;</span>}
                              {currency === 'ghs' && <span>&#8373;</span>}
                              {currency === 'kes' && 'KSh'} {amount && Number(amount?.$numberDecimal).toLocaleString()}
                            </Td>

                            <Td>{formatDateToUTC(createdAt)}</Td>

                            <Td textTransform='capitalize'>{depositPaymentType}</Td>

                            <Td>
                              <TableDataStatus status={status} />
                            </Td>

                            <Td>
                              <Flex
                                gridGap='0.5rem'
                                alignItems='center'
                              >
                                <Box>
                                  {depositPaymentType === 'flutterwave' ||
                                  status === 'denied' ||
                                  status === 'approved' ||
                                  status === 'cancelled' ? (
                                    <>
                                      <ViewMore onClick={(e) => handleActions(e, modalData)}>View more</ViewMore>
                                    </>
                                  ) : (
                                    <Flex gridGap={'12px'}>
                                      <TableActionButton onClick={(e) => handleActions(e, modalData)} />
                                    </Flex>
                                  )}
                                </Box>

                                <Box>
                                  {(status === 'pending' || status === 'cancelled') && (
                                    <Select
                                      minW='100px'
                                      onChange={(e) => handleActions(e, modalData)}
                                    >
                                      <option>Process</option>
                                      <option value='paid'>Paid</option>
                                      <option value='cleared'>Cleared</option>
                                    </Select>
                                  )}
                                </Box>
                              </Flex>
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                </Tbody>
              </Table>
            </ScrollRightContainer>

            <WalletDepositModal
              isOpen={isOpen}
              onClose={onClose}
              data={selectedData}
              actionValue={actionValue}
              currentPage={currentPage}
              pageSize={pageSize}
            />

            <PaginationWrapper
              setCurrentPage={setCurrentPage}
              totalDataCount={data.totalCount}
              currentPage={currentPage}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              offset={offset}
              pages={pages}
              size={10}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default FiatDepositHistory;
