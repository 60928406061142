import { Box } from '@chakra-ui/react';
import { EarnCardWrapper, PageTitle, EarnTable } from 'components';

const Earn = () => {
  return (
    <Box>
      <PageTitle title="Earn" />

      <EarnCardWrapper />

      <EarnTable />
    </Box>
  );
};

export default Earn;
