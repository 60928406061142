import { Box, Center, Flex, Heading, Image, Text } from '@chakra-ui/react';

const OverviewCard = ({ icon, graph, count, title, png }) => {
  return (
    <Center
      maxWidth="273px"
      flexDirection="column"
      width="100%"
      padding="14px"
      borderRadius="10px"
      bg="#fff"
      boxShadow="0px 2px 8px rgb(229 229 229 / 47%)"
    >
      <Box>
        {png && <Image width="39px" src={png} alt={title} />}

        {icon && icon}
      </Box>

      <Text
        color="brand.darkGray"
        fontWeight="400"
        fontSize="14px"
        lineHeight="19px"
      >
        {title}
      </Text>

      <Heading
        margin="8px 0"
        fontSize="28px"
        fontWeight="700"
        lineHeight="34px"
      >
        {count}
      </Heading>

      <Flex gridGap="5px" alignItems="center">
        <Box>{graph}</Box>
        <Text
          fontSize="12px"
          letterSpacing="-0.5px"
          fontWeight="400"
          lineHeight="24px"
        >
          1.9% Up from yesterday
        </Text>
      </Flex>
    </Center>
  );
};

export default OverviewCard;
