import { Box, Center, Image, Text } from '@chakra-ui/react';
import { FileUploadSVG } from 'assets';
import { useEffect, useRef, useState } from 'react';

const FileUpload = ({ setSelectedFile, selectedFile, defaultPreview }) => {
  const [preview, setPreview] = useState(defaultPreview);
  const imgRef = useRef(null);

  const handleFile = (event) => {
    const fileList = event.target.files;
    if (!fileList) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(fileList[0]);
  };

  const handleClick = () => {
    imgRef?.current.click();
  };

  useEffect(() => {
    if (!selectedFile) {
      // setPreview('');
      return;
    }

    if (preview) {
      setPreview(defaultPreview);
    }

    const objectUrl = URL.createObjectURL(selectedFile);

    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, defaultPreview]);
  return (
    <Box
      bg='rgba(49, 183, 169, 0.1)'
      borderRadius='13px'
      padding='12px'
      onClick={handleClick}
      cursor='pointer'
    >
      <>
        <input
          type='file'
          hidden
          onChange={handleFile}
          ref={imgRef}
          accept='image/jpg, image/jpeg, image/png, image/webp, text/css'
        />

        {preview ? (
          <Center>
            <Image
              objectFit='contain'
              height='150px'
              src={preview}
              width='initial'
              alt={selectedFile?.name || 'upload'}
            />
          </Center>
        ) : (
          <Center
            flexDir='column'
            border='1px dashed #1D776D'
            borderRadius='12px'
            padding='21px 0 11px'
          >
            <FileUploadSVG />

            <Text
              margin='5px 0 16px'
              fontSize='16px'
            >
              Chose File
            </Text>

            <Center
              type='file'
              accept='image/jpg, image/jpeg, image/png, image/webp'
              color='transparent'
              height='18px'
              css={{
                '::-webkit-file-upload-button': {
                  visibility: 'hidden',
                },
                ':before': {
                  content: "'Browse'",
                  display: 'inline-block',
                  fontSize: '12px',
                  fontWeight: '500',
                  color: '#31B7A9',
                  textAlign: 'center',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  padding: '0',
                },
              }}
            />
          </Center>
        )}
      </>
    </Box>
  );
};

export default FileUpload;
