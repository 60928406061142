import { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseModal, Loader } from 'components';
import { fetchP2pProfile } from 'store/slices/p2p.slice';
import { convertHMS } from 'utils';

const UserDisputeDetailsModal = ({ isOpen, onClose, username }) => {
  const dispatch = useDispatch();
  const {
    data: { profile },
    status,
    errorMessage,
  } = useSelector((state) => state.p2p.p2pProfile);

  useEffect(() => {
    username.length >= 2 && dispatch(fetchP2pProfile(username));
  }, [dispatch, username]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent borderRadius="16px" maxWidth="420px" padding={'32px'}>
        <ModalHeader
          fontSize={'24px'}
          fontWeight={'600'}
          p="0"
          textAlign="center"
        >
          Details
        </ModalHeader>

        <ModalBody p="0">
          <Box mt="8px">{status === 'loading' && <Loader />}</Box>

          {status === 'error' && <p>{errorMessage}</p>}

          {status === 'fulfilled' && (
            <Box margin={'24px 0'} fontSize="14px">
              <Flex justifyContent={'space-between'} mb="8px">
                <Text fontSize="14px">Username: </Text>

                <Text fontSize="14px" fontWeight="500">
                  {username}
                </Text>
              </Flex>

              <Flex justifyContent={'space-between'} mb="8px">
                <Text fontSize="14px">Combined Disputes: </Text>

                <Text fontSize="14px" fontWeight="500">
                  {profile.combinedDisputes}
                </Text>
              </Flex>

              <Flex justifyContent={'space-between'} mb="8px">
                <Text fontSize="14px">Sell rating: </Text>

                <Text fontSize="14px" fontWeight="500">
                  {profile.sellRating.average}
                </Text>
              </Flex>

              <Flex justifyContent={'space-between'} mb="8px">
                <Text fontSize="14px">Buy rating: </Text>

                <Text fontSize="14px" fontWeight="500">
                  {profile.buyRating.average}
                </Text>
              </Flex>

              <Flex justifyContent={'space-between'} mb="8px">
                <Text fontSize="14px">Payment speed</Text>

                <Flex gridGap="16px">
                  <Box>
                    <Text fontWeight="500">Time sum</Text>
                    <Text fontSize="14px" fontWeight="500">
                      {convertHMS(profile.paymentSpeed.timeSum)}
                    </Text>
                  </Box>

                  <Box>
                    <Text fontWeight="500">Trade sum</Text>
                    <Text fontSize="14px" fontWeight="500">
                      {profile.paymentSpeed.tradeCounts}
                    </Text>
                  </Box>
                </Flex>
              </Flex>

              <Flex justifyContent={'space-between'} mb="8px">
                <Text fontSize="14px">Release speed</Text>

                <Flex gridGap="16px">
                  <Box
                  // width="69px"
                  >
                    <Text fontWeight="500">Time sum</Text>
                    <Text fontSize="14px" fontWeight="500">
                      {convertHMS(profile.releaseSpeed.timeSum)}
                    </Text>
                  </Box>

                  <Box>
                    <Text fontWeight="500">Trade sum</Text>
                    <Text fontSize="14px" fontWeight="500">
                      {profile.releaseSpeed.tradeCounts}
                    </Text>
                  </Box>
                </Flex>
              </Flex>

              <Flex justifyContent={'space-between'} mb="8px">
                <Text fontSize="14px">Suspension</Text>

                <Flex gridGap="16px">
                  <Box width="69px">
                    <Text fontWeight="500">Buys</Text>
                    <Text fontSize="14px" fontWeight="500">
                      {profile.suspensions.buys}
                    </Text>
                  </Box>

                  <Box width="69px">
                    <Text fontWeight="500">Sells</Text>
                    <Text fontSize="14px" fontWeight="500">
                      {profile.suspensions.sells}
                    </Text>
                  </Box>
                </Flex>
              </Flex>

              <Flex justifyContent={'space-between'} mb="8px">
                <Text fontSize="14px" width="200px">
                  Trade disputes
                </Text>

                <Flex gridGap="16px">
                  <Box width="69px">
                    <Text fontWeight="500">Buys</Text>
                    <Text fontSize="14px" fontWeight="500">
                      {profile.tradeDisputes.buys}
                    </Text>
                  </Box>

                  <Box width="69px">
                    <Text fontWeight="500">Sells</Text>
                    <Text fontSize="14px" fontWeight="500">
                      {profile.tradeDisputes.sells}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          )}
        </ModalBody>

        <CloseModal onClick={onClose} />
      </ModalContent>
    </Modal>
  );
};

export default UserDisputeDetailsModal;
