import { Modal, ModalContent, ModalBody, useDisclosure, Box, Flex, Text, ModalCloseButton } from '@chakra-ui/react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { NavLink, useLocation } from 'react-router-dom';
import { mobileLink } from 'utils/sidebarLink';
import Logout from './Logout';
import styles from './Sidebar.module.scss';

const MoreButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { pathname } = useLocation();

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='full'
      >
        <Box>
          <ModalContent>
            <ModalCloseButton color='#f2f4f3' />

            <ModalBody
              bg='brand.darkPrimary'
              padding='32px 16px'
            >
              <Flex
                gridGap='12px'
                as='ul'
                flexDir='column'
                className={styles.sidebar}
              >
                {mobileLink.slice(4).map(({ icon, activeIcon, id, name, path, end, subPath }) => {
                  return (
                    <Box
                      as='li'
                      key={id}
                      mb='10.5px'
                    >
                      <NavLink
                        className={({ isActive }) => {
                          return isActive || (pathname === '/dashboard/' && name === 'Home')
                            ? styles.active
                            : styles.inactive;
                        }}
                        to={path}
                        end={end}
                        onClick={onClose}
                      >
                        <Flex
                          gridGap='13px'
                          alignItems='center'
                        >
                          {pathname === path ||
                          pathname === '/dashboard/' ||
                          (subPath && pathname.split('/')[2] === name.toLowerCase())
                            ? activeIcon
                            : icon}

                          <Text
                            lineHeight='19px'
                            as='p'
                            fontWeight='300'
                            fontSize='1rem'
                            color='#F2F4F3'
                          >
                            {name}
                          </Text>
                        </Flex>
                      </NavLink>
                    </Box>
                  );
                })}
              </Flex>

              <Logout />
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>

      <HiDotsHorizontal
        color='#fff'
        fontSize='24px'
        cursor='pointer'
        onClick={onOpen}
      />
    </Box>
  );
};

export default MoreButton;
