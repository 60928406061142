import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  Center,
  useDisclosure,
  useToast,
  Stack,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { Button } from 'components';
import { useState } from 'react';
import { useSelectWinnerMutation } from 'store/services/wishlist.service';

const SelectWishlistUserModal = ({ username, userWishListId, wishlistCycle, tag, criteria }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [_selectWinner, { isLoading }] = useSelectWinnerMutation();

  // const [action, setAction] = useState(true);
  const [action, setAction] = useState('startNewCycle');

  const handleSelect = async () => {
    try {
      const response = await _selectWinner({
        cycle: wishlistCycle,
        userWishListId,
        tag,
        action,
        criteria,
      }).unwrap();

      onClose();

      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response?.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      console.log(response, 'RESPONSE');
    } catch (error) {
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.data?.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        minH='40px'
        border='1px solid #CACBCA'
        color='#0D4740'
        borderRadius='42px'
        bg='transparent'
        padding='12px'
        fontWeight='500'
        width='86px'
        minW='86px'
        fontSize='12px'
        onClick={onOpen}
      >
        Select User
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='21px'
          padding={{ base: '24px', md: '40px' }}
          maxWidth='460px'
          width='100%'
        >
          <Center
            padding='0'
            flexDir='column'
          >
            <Text
              textAlign='center'
              mb='24px'
              fontSize='18px'
            >
              Are you sure you want to select{' '}
              <Text
                as='span'
                fontWeight='600'
              >
                {username}
              </Text>
            </Text>

            {/* <Center
              gap='18px'
              mb='16px'
              width='200px'
              justifyContent='space-between'
            >
              <Text>End Campaign</Text>

              <ConfigCheckbox
                defaultChecked={endCampaign}
                onChange={(e) => setEndCampaign(!endCampaign)}
              />
            </Center> */}

            {/* <Center
              gap='18px'
              mb='32px'
              width='200px'
              justifyContent='space-between'
            >
              <Text>Action</Text>

              <ConfigCheckbox
                defaultChecked={action}
                onChange={(e) => setAction(!action)}
              />
            </Center> */}

            <RadioGroup
              onChange={setAction}
              value={action}
              mb='32px'
            >
              <Stack
                alignItems='revert-layer'
                spacing='16px'
              >
                <Radio
                  value='startNewCycle'
                  colorScheme='green'
                  fontWeight='500'
                >
                  Start New Cycle
                </Radio>
                <Radio
                  value='endCampaign'
                  colorScheme='green'
                  fontWeight='500'
                >
                  End Campaign
                </Radio>
              </Stack>
            </RadioGroup>

            <Center>
              <Button
                width='100%'
                isLoading={isLoading}
                onClick={handleSelect}
                disabled={isLoading}
                minW='180px'
                w='initial'
                minH='48px'
              >
                Yes
              </Button>
            </Center>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectWishlistUserModal;
