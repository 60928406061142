import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, FormLabel, FormControl, VStack, useToast, Center } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, FilterSelect, HistoryTab, Input } from 'components';
import { fetchRoles } from 'store/slices/roles.slice';
import { fetchSingleUser } from 'store/slices/user.slice';
import { put } from 'utils/axiosLib';
import { getHeaders } from 'utils';

const EditAdmin = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    singleUser: { data, status: userStatus },
  } = useSelector((state) => state.allUsers);

  const { roles } = useSelector((state) => state.roles);
  const [loading, setLoading] = useState(false);
  const [level, setLevel] = useState('');
  const [role, setRole] = useState('');

  const handleLevel = (event) => {
    setLevel(event.target.value);
  };

  const handleRole = (event) => {
    setRole(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await put(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/assign/role/${id}`,
        {
          group: role,
          level,
        },
        getHeaders()
      );

      if (response) {
        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        navigate('/dashboard/account');
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchRoles());

    dispatch(fetchSingleUser(id));
  }, [dispatch, id]);

  return (
    <>
      <HistoryTab
        firstText='Administrators'
        secondText='Edit Admin'
        url='/dashboard/account'
      />

      <Box
        bg='#FCFDFD'
        padding={{ base: '24px', md: '72px' }}
        borderRadius='10px'
        maxWidth='666px'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
        width='100%'
      >
        <VStack
          maxWidth='523px'
          gridGap='16px'
          width='100%'
          margin='0 auto'
          as='form'
          onSubmit={handleSubmit}
        >
          <FormControl>
            <FormLabel fontWeight={'400'}>Full name</FormLabel>
            <Input
              width='100%'
              name='fullName'
              value={userStatus === 'fulfilled' ? `${data.firstName} ${data.lastName}` : `Full name`}
              type='text'
              placeholder='Enter full name'
              padding='13px 22px'
              disabled
              _disabled={{
                color: '#A0A3BD',
                fontWeight: 500,
                cursor: 'not-allowed',
              }}
              _hover={{
                borderColor: 'none',
                border: 'none',
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight={'400'}>Email</FormLabel>
            <Input
              width='100%'
              name='email'
              value={userStatus === 'fulfilled' ? `${data.email}` : ``}
              disabled
              type='email'
              required
              placeholder='Enter email'
              padding='13px 22px'
              _focus={{
                borderColor: '#06C3448f',
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight={'400'}>Role</FormLabel>

            <FilterSelect
              placeholder='Select role'
              onChange={handleRole}
              height='60px'
              required
              backgroundColor={'#F6F8F7'}
              width='100%'
              borderRadius='15px'
              _hover={{
                backgroundColor: '#F6F8F7',
              }}
            >
              <option value='super-admin'>Super Admin</option>
              <option value='finance'>Finance</option>
              <option value='customer-care'>Customer care</option>
              <option value='staff'>Staff</option>
            </FilterSelect>
          </FormControl>

          <FormControl>
            <FormLabel fontWeight={'400'}>Level</FormLabel>

            <FilterSelect
              placeholder='Select level'
              onChange={handleLevel}
              height='60px'
              backgroundColor={'#F6F8F7'}
              required
              width='100%'
              borderRadius='15px'
              _hover={{
                backgroundColor: '#F6F8F7',
              }}
            >
              {roles.roles.map(({ level, _id }) => {
                return (
                  <option
                    value={level}
                    key={_id}
                  >
                    {level}
                  </option>
                );
              })}
            </FilterSelect>
          </FormControl>

          <Center width='100%'>
            <Button
              type='submit'
              mt='62px'
              minW={{ base: '100%', md: '399px' }}
              isLoading={loading}
              disabled={!(level.length >= 3 && role.length >= 3) || loading}
            >
              Save
            </Button>
          </Center>
        </VStack>
      </Box>
    </>
  );
};

export default EditAdmin;
