import { Box } from '@chakra-ui/react';
import { IntegrationsDeposit, IntegrationsWithdrawal, PageTab } from 'components';
import { useLocationSearch } from 'hooks';
import WidgetPay from './WidgetPay';

const IntegrationsTransfer = () => {
  const tabLink = [
    {
      id: 1,
      search: '',
      title: 'Deposit',
      to: '/dashboard/inflow-outflow',
    },
    {
      id: 2,
      search: '?withdrawal',
      title: 'Withdrawal',
      to: '/dashboard/inflow-outflow?withdrawal',
    },
    {
      id: 3,
      search: '?pay',
      title: 'Pay',
      to: '/dashboard/inflow-outflow?pay',
    },
  ];

  const search = useLocationSearch();
  return (
    <Box>
      <PageTab linkArray={tabLink} />

      {search === '' && <IntegrationsDeposit />}
      {search === '?withdrawal' && <IntegrationsWithdrawal />}
      {search === '?pay' && <WidgetPay />}
    </Box>
  );
};

export default IntegrationsTransfer;
