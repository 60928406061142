import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dehydrate,
  fetchUserProfile,
  hydrateToken,
  selectAuth,
} from 'store/slices/auth.slice';
import { constant, ls } from 'utils';

export function useAuth() {
  const dispatch = useDispatch();
  const { auth, profile } = useSelector(selectAuth);

  const login = useCallback(
    (token, exp, isSignedIn = true) => {
      dispatch(hydrateToken({ token, exp, isSignedIn }));
      dispatch(fetchUserProfile(token));
    },
    [dispatch]
  );

  const logout = useCallback(() => dispatch(dehydrate()), [dispatch]);

  const makeRedirect = (toLogin = false) => {
    const exceptions = [
      constant.PAGES.FORGOT_PASSWORD,
      constant.PAGES.RESET_CODE,
      constant.PAGES.RESET_PASSWORD,
      constant.PAGES.VERIFY_EMAIL_PASSWORD,
    ];
    const currentPagePath = window.location.pathname;

    const navigate = (path) =>
      (window.location = window.location.origin + path);
    const searchParam = new URLSearchParams(window?.location?.search ?? '');

    if (exceptions.includes(currentPagePath)) {
      return;
    }
    if (toLogin && !exceptions.includes(currentPagePath)) {
      const redirectUrl = currentPagePath;
      currentPagePath !== constant.PAGES.LOGIN &&
        currentPagePath !== '/' &&
        navigate(`${constant.PAGES.LOGIN}?redirectUrl=${redirectUrl}`);
      return;
    }
    if (
      currentPagePath &&
      !searchParam.has('redirectUrl') &&
      currentPagePath !== constant.PAGES.LOGIN
    )
      return;

    if (
      searchParam.has('redirectUrl') &&
      !currentPagePath?.includes(searchParam.get('redirectUrl'))
    ) {
      navigate(searchParam.get('redirectUrl'));
      return;
    }

    !currentPagePath?.includes(constant.PAGES.HOME) &&
      navigate(constant.PAGES.HOME);
    return;
  };

  const hydrateAuth = useCallback(() => {
    const auth = ls.get(constant.AUTH_TOKEN_KEY);
    if ((auth?.exp && Date.now() > auth?.exp) || !auth?.token) {
      // console.log("GOT HERE");
      logout();
      makeRedirect(true);
      return;
    }
    login(auth.token, auth.exp, true, auth.verification);
    makeRedirect();
  }, [login, logout]);

  useEffect(() => {
    // console.log('AUTH FROM STORE ::: ', auth);
    if (!auth?.token) hydrateAuth();
  }, [auth, hydrateAuth]);

  return {
    token: auth?.token,
    isSignedIn: auth?.isSignedIn,
    login,
    logout,
    profile,
    verification: auth?.verification,
    hydrateToken: (input) => dispatch(hydrateToken({ ...input })),
  };
}
