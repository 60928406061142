import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Center,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { CloseModal, PlaceNewLien, RunPendingLien } from 'components';
import { getHeaders } from 'utils';

const LienActionModal = ({ id }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();
  const headers = getHeaders();

  const { data } = useSelector((state) => state.allUsers.singleUser);

  const [selected, setSelected] = useState('');

  const handleOpen = (value) => {
    setSelected(value);
    onOpen();
  };

  return (
    <Box>
      <Menu>
        <MenuButton
          textAlign="center"
          borderRadius="20.4px"
          bg="transparent"
          as={Button}
          color="brand.black"
          fontWeight="500"
          width="106px"
          height="32px"
          border="1px solid #6FBE45"
          fontFamily="PolySans"
          _hover={{
            transition: 'all 300ms ease-in-out',
            bg: 'brand.primary',
            color: '#fff',
            border: '1px solid #0D4740',
          }}
          _active={{
            bg: 'brand.primary',
            color: '#fff',
            border: '1px solid #0D4740',
          }}
        >
          <Center gridGap="1rem" alignItems="center">
            <Text fontSize="11px">Lien</Text>
            <ChevronDownIcon fontSize="1rem" />
          </Center>
        </MenuButton>

        <MenuList>
          <MenuItem minH="50px" onClick={() => handleOpen('new')}>
            Place New Lien
          </MenuItem>

          <MenuItem minH="50px" onClick={() => handleOpen('run')}>
            Run Pending Lien
          </MenuItem>
        </MenuList>
      </Menu>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent pb="2rem" borderRadius="21px" maxWidth="428px">
          <ModalHeader
            padding="24px"
            fontWeight="600"
            fontSize="24px"
            textAlign="center"
          >
            {selected === 'new' ? 'Place New Lien' : 'Run Pending Lien'}
          </ModalHeader>

          <ModalBody>
            {selected === 'new' && (
              <PlaceNewLien
                data={data}
                headers={headers}
                onClose={onClose}
                toast={toast}
                dispatch={dispatch}
                id={id}
              />
            )}

            {selected === 'run' && (
              <RunPendingLien
                data={data}
                headers={headers}
                onClose={onClose}
                toast={toast}
                dispatch={dispatch}
                id={id}
              />
            )}
          </ModalBody>

          <CloseModal onClick={onClose} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LienActionModal;
