import { usePagination } from '@ajna/pagination';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Flex } from '@chakra-ui/react';
import {
  FilterSelect,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  TableDataStatus,
  WidgetPayModal,
} from 'components';
import { useCopy } from 'hooks';
import { useState } from 'react';
import { useAllTransferQuery } from 'store/services/integrations.service';
import { useGetPaymentsQuery } from 'store/services/pay.service';
import { formatDateToUTC } from 'utils';

const WidgetPay = () => {
  const [page, setPage] = useState(0);
  const size = 10;
  const [paymentStatus, setPaymentStatus] = useState('');
  const { data, isLoading } = useGetPaymentsQuery({
    page,
    limit: 10,
    paymentStatus,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  // const [modalData, setModalData] = useState({});

  // const handleModalData = (data) => {
  //   setModalData(data);
  // };
  const { hasCopied, onCopy, value } = useCopy();

  const handleStatus = (e) => {
    setPaymentStatus(e.target.value);
  };

  const { data: dData } = useAllTransferQuery({
    page,
    limit: 10,
    paymentStatus: '',
  });

  console.log(dData, 'THE DATA');

  return (
    <Box>
      <Flex>
        <FilterSelect
          width='120px'
          onChange={handleStatus}
          mb='24px'
        >
          <option value=''>All Status</option>
          <option value='confirmed'>Confirmed</option>
          <option value='made'>Made</option>
          <option value='cancelled'>Cancelled</option>
          <option value='failed'>Failed</option>
          <option value='timedout'>Timedout</option>
        </FilterSelect>
      </Flex>
      {isLoading && <Loader />}
      <ScrollRightContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>S/N</Th>
              <Th>Reference</Th>
              <Th>Amount</Th>
              <Th>Fee</Th>
              <Th>Direction</Th>
              <Th>Payment Method</Th>
              <Th>Status</Th>
              <Th>Date/Time</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data?.data?.map(
              (
                {
                  _id,
                  reference,
                  amount,
                  unit,
                  fee,
                  createdAt,
                  direction,
                  paymentStatus,
                  amountReceived,
                  paymentAccountData,
                  sender,
                  txId,
                  paymentUrl,
                  paymentMethod,
                },
                i
              ) => {
                const mData = {
                  _id,
                  reference,
                  amount,
                  unit,
                  fee,
                  createdAt,
                  direction,
                  paymentStatus,
                  amountReceived,
                  paymentAccountData,
                  sender,
                  txId,
                  paymentUrl,
                  paymentMethod,
                };
                return (
                  <Tr key={_id}>
                    <Td>{i + 1}</Td>

                    <Td onClick={() => onCopy(reference)}>
                      <Flex
                        alignItems='center'
                        gridGap='4px'
                        cursor='pointer'
                      >
                        <Text>{reference}</Text>

                        {hasCopied && value === reference ? <CheckIcon /> : <CopyIcon />}
                      </Flex>
                    </Td>

                    <Td>
                      {Number(amount).toLocaleString()}{' '}
                      <Text
                        as='span'
                        textTransform='uppercase'
                      >
                        {unit}
                      </Text>
                    </Td>

                    <Td>{fee}</Td>

                    <Td textTransform='capitalize'>{direction === 'in' ? 'Buy' : 'Sell'}</Td>

                    <Td textTransform='capitalize'>{paymentMethod}</Td>

                    <Td>
                      <TableDataStatus status={paymentStatus} />
                    </Td>

                    <Td>{formatDateToUTC(createdAt)}</Td>

                    <Td>
                      <WidgetPayModal modalData={mData} />
                    </Td>
                  </Tr>
                );
              }
            )}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={isLoading ? 0 : data?.totalCount}
        currentPage={currentPage}
        setPage={setPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={size}
        offset={offset}
        pages={pages}
      />
    </Box>
  );
};

export default WidgetPay;
