import { useMemo } from 'react';
import { useStorage } from './useStorage';
import { constant } from 'utils';

export const useSetUserStorage = (userId, fullName) => {
  const { setStorage } = useStorage(constant.SELECTED_USER_ID);
  return useMemo(() => {
    fullName && setStorage(`${userId},${fullName}`);
  }, [fullName, setStorage, userId]);
};
