import { useEffect, useRef, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import {
  BanHistoryModal,
  LockUserModal,
  P2PHealModal,
  ReviewKYCModal,
  ScrollRightContainer,
  AccountHealModal,
  LienActionModal,
  AddBankDetailsModal,
  SendNotificationsModal,
  UploadUserKYCModal,
} from 'components';
import { SquareLeftArrowSVG, SquareRightArrowSVG } from 'assets';

const UserDetailsScrollContainer = ({ data, reviewDocuments, headers, id, dispatch }) => {
  const ref = useRef(null);

  const [scrollValue, setScrollValue] = useState(440);
  const handleScroll = () => {
    if (scrollValue >= 440) {
      setScrollValue(0);
    } else {
      setScrollValue(440);
    }

    if (ref.current) {
      ref.current.scrollTo({
        left: scrollValue,
        behavior: 'smooth',
      });
    }
  };

  const trackScrollPosition = () => {
    const scrollLeft = ref.current.scrollLeft;

    if (scrollLeft >= 400) {
      setScrollValue(0);
    }

    if (scrollLeft <= 20) {
      setScrollValue(440);
    }
  };

  useEffect(() => {
    const currentRef = ref.current;
    currentRef.addEventListener('scroll', trackScrollPosition);

    return () => {
      currentRef.removeEventListener('scroll', trackScrollPosition);
    };
  }, []);

  return (
    <Flex
      alignItems='center'
      gap='4px'
    >
      <ScrollRightContainer
        maxWidth={{ base: '520px', tb: '580px', lg: '580px' }}
        propRef={ref}
        hideScrollbar
      >
        <Flex
          gridGap='10px'
          alignItems='center'
          id='container'
        >
          <LockUserModal
            fullName={data?.fullName}
            id={id}
            headers={headers}
            lockType={data?.lock === 0 ? 'lock' : 'unlock'}
          />

          <AddBankDetailsModal
            headers={headers}
            userData={data}
          />

          <ReviewKYCModal reviewDocuments={reviewDocuments} />

          <UploadUserKYCModal
            data={data}
            headers={headers}
          />

          <BanHistoryModal />

          <AccountHealModal
            dispatch={dispatch}
            id={id}
            headers={headers}
          />

          <P2PHealModal
            headers={headers}
            id={id}
          />

          <LienActionModal id={id} />

          <SendNotificationsModal
            id={id}
            headers={headers}
          />
        </Flex>
      </ScrollRightContainer>

      <Box
        cursor='pointer'
        transition='0.3s ease, transform 0.3s ease'
        onClick={handleScroll}
        bg='#fcfcfc'
        borderRadius='full'
      >
        {scrollValue === 440 ? <SquareRightArrowSVG /> : <SquareLeftArrowSVG />}
      </Box>
    </Flex>
  );
};

export default UserDetailsScrollContainer;
