import { useToast } from '@chakra-ui/react';
import { KeyShortcuts, PrivateRoute, ScrollToTop } from 'components';
import useShortcutKeys from 'hooks/useShortCut';
import { useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routePath from 'router';
import { constant, ls } from 'utils';
import { useCopy } from 'hooks';

const App = () => {
  const { hasCopied, onCopy, value } = useCopy();

  const copyAuthTokenToClipboard = useCallback(() => {
    const token = ls.get(constant.AUTH_TOKEN_KEY).token;
    onCopy(token);
  }, [onCopy]);

  const disableShortCut = () => {
    ls.set('DISABLE_CMD_KEYS', '1');
  };

  const enableShortCut = () => {
    ls.set('DISABLE_CMD_KEYS', '0');
  };

  useShortcutKeys(['/', 'd', 'd', 'd'], disableShortCut, true);
  useShortcutKeys(['/', 'e', 'e', 'e'], enableShortCut, true);

  useShortcutKeys(['/', 'c', 't', 'k'], copyAuthTokenToClipboard);

  const toast = useToast();
  useEffect(() => {
    hasCopied &&
      value &&
      toast({
        description: 'Token copied successfully',
        status: 'success',
        position: 'bottom-right',
      });
  }, [hasCopied, toast, value]);

  return (
    <>
      <BrowserRouter>
        <KeyShortcuts>
          <ScrollToTop>
            <Routes>
              {routePath.map(({ component, path, id, protected: checkProtected }) => {
                return checkProtected ? (
                  <Route
                    key={id}
                    path={path}
                    element={<PrivateRoute>{component}</PrivateRoute>}
                  />
                ) : (
                  <Route
                    key={id}
                    path={path}
                    element={component}
                  />
                );
              })}
            </Routes>
          </ScrollToTop>
        </KeyShortcuts>
      </BrowserRouter>
    </>
  );
};

export default App;
