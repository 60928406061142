import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal, ModalOverlay, ModalContent, ModalHeader, Text, Flex } from '@chakra-ui/react';
import { Button, CloseModal, Input } from 'components';
import { setLeaderBoardFilter, setRankingDateFilter } from 'store/slices/partners.slice';
import { filterCardTxDate } from 'store/slices/card.slice';
import { convertToIso } from 'utils';

const PartnersFilterByDateModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [state, setState] = useState({
    startDate: '',
    endDate: '',
  });

  const handleDateChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilter = () => {
    if (pathname === '/dashboard/partners') {
      dispatch(setLeaderBoardFilter(state));
    }

    if (pathname === '/dashboard/partners/earnings-referred') {
      dispatch(setRankingDateFilter(state));
    }

    if (pathname === '/dashboard/cards') {
      dispatch(
        filterCardTxDate(`afterDate=${convertToIso(state.startDate)}&beforeDate=${convertToIso(state.endDate)}`)
      );
    }

    onClose();

    setState({
      endDate: '',
      startDate: '',
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='21px'
        maxWidth='568px'
        padding='53px 0 48px'
      >
        <ModalHeader
          fontWeight='600'
          borderTopRadius='16px'
          fontSize='24px'
          textAlign='center'
        >
          Filter
        </ModalHeader>

        <Flex
          maxWidth='390px'
          margin='auto'
          width='100%'
          flexDir='column'
        >
          <Text
            fontSize='14px'
            lineHeight='24px'
            mb='10px'
            textAlign='center'
          >
            Filter by date
          </Text>

          <Input
            label='Start Date'
            name='startDate'
            type='date'
            minH='40px'
            placeholder='Start date'
            mb='44px'
            value={state.startDate}
            onChange={handleDateChange}
          />

          <Input
            label='End Date'
            _placeholder={'End date'}
            name='endDate'
            type='date'
            minH='40px'
            placeholder='End date'
            minW='100%'
            mb='17px'
            value={state.endDate}
            onChange={handleDateChange}
          />

          <Button
            mt='49px'
            disabled={state.endDate === '' || state.startDate === ''}
            onClick={handleFilter}
          >
            Filter
          </Button>

          <CloseModal onClick={onClose} />
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default PartnersFilterByDateModal;
