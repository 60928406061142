import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ls, constant } from 'utils';
const auth = ls.get(constant.AUTH_TOKEN_KEY);

export const gamingApi = createApi({
  reducerPath: 'gamingApi',
  refetchOnMountOrArgChange: true,
  tagTypes: ['games', 'single-game'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, _) => {
      headers.set('Authorization', auth.token);
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    allGames: builder.query({
      query: ({ status, page, limit }) => `/admin/game?page=${page}&limit=${limit}${status ? `&status=${status}` : ''}`,
      transformResponse: (res) => res.data,
      providesTags: ['games'],
    }),

    gamesId: builder.query({
      query: ({ id }) => `/admin/game/${id}`,
      transformResponse: (res) => res.data,
      providesTags: ['single-game'],
    }),

    createGame: builder.mutation({
      query: ({ name, description, logoUrl, label, category, levelTarget }) => ({
        url: `/admin/game`,
        method: 'POST',
        body: {
          name,
          logoUrl,
          description,
          label,
          category,
          levelTarget,
        },
      }),
      invalidatesTags: ['games'],
      transformResponse: (res) => res,
    }),

    updateGamgeLevel: builder.mutation({
      query: ({ logoUrl, level, target, currency, amount, description, _id }) => ({
        url: `/admin/game/level/${_id}`,
        method: 'PATCH',
        body: {
          logoUrl,
          level,
          target,
          description,
          reward: {
            currency,
            amount,
          },
        },
      }),
      invalidatesTags: ['single-game'],
      transformResponse: (res) => res,
    }),

    updateGameDetails: builder.mutation({
      query: ({ logoUrl, name, maxPoint, description, _id }) => ({
        url: `/admin/game/${_id}`,
        method: 'PATCH',
        body: {
          name,
          description,
          logoUrl,
          maxPoint,
        },
      }),
      invalidatesTags: ['single-game'],
      transformResponse: (res) => res,
    }),
  }),
});

export const {
  useAllGamesQuery,
  useCreateGameMutation,
  useGamesIdQuery,
  useUpdateGamgeLevelMutation,
  useUpdateGameDetailsMutation,
} = gamingApi;

export default gamingApi;
