import { Center, Text } from '@chakra-ui/react';

const CloseModal = ({ onClick, mt }) => {
  return (
    <Center mt={mt ? mt : '2rem'}>
      <Text
        fontWeight='500'
        color='#0D4740'
        onClick={onClick}
        textDecoration='underline'
        cursor='pointer'
        fontFamily='PostGrotesk'
      >
        Close
      </Text>
    </Center>
  );
};

export default CloseModal;
