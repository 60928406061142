import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';
import { BoxInput, HistoryTab } from 'components';
import { fetchUserStaking } from 'store/slices/staking.slice';
import { formatDateToUTC } from 'utils';

const EarnDetails = () => {
  const { id } = useParams();
  const {
    data: { stake },
  } = useSelector((state) => state.stakings.stakingDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserStaking(id));
  }, [dispatch, id]);

  return (
    <Box>
      <HistoryTab
        firstText='Earn'
        secondText='Staking details'
        url='/dashboard/earn'
      />

      <Flex
        gridGap='80px'
        justifyContent='center'
        pt='72px'
        pb='72px'
        bg='#FCFDFD'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
        borderRadius='10px'
        flexDir={{ base: 'column', tb: 'row' }}
      >
        <Box>
          <BoxInput
            label='Username'
            value={stake?.userDetails?.fullName ? stake.userDetails.fullName : 'N/A'}
            textColor='#6FBE45'
          />

          <BoxInput
            label='Coin Type'
            value={stake.unit}
            textColor='#6FBE45'
            textTransform='uppercase'
          />

          <BoxInput
            label='Percentage'
            value={stake?.product?.originalApr ? Number(stake?.product.originalApr).toFixed(8) : 'N/A'}
            textColor='#6FBE45'
          />

          <BoxInput
            label='Coin Amount'
            value={`${stake.amountAtStake?.usdValue} ${stake.unit}`}
            textColor='#6FBE45'
            textTransform='uppercase'
          />

          <BoxInput
            label='Date Created'
            value={formatDateToUTC(stake.createdAt)}
            textColor='#6FBE45'
          />
        </Box>

        <Box>
          <BoxInput
            label='Duration'
            value={`${stake.product?.tenor ? `${stake.product?.tenor} days` : 'N/A'} `}
            textColor='#6FBE45'
          />

          <BoxInput
            label='Status'
            value={stake?.status}
            textColor='#6FBE45'
            textTransform='capitalize'
          />

          <BoxInput
            label='Type of Staking'
            value={String(stake?.product?.type).toLowerCase()}
            textColor='#6FBE45'
            textTransform='capitalize'
          />

          <BoxInput
            label='Wallet ID'
            value={stake.walletId}
            textColor='#6FBE45'
          />

          <BoxInput
            label='Expires'
            value={stake?.product?.expiresOn ? formatDateToUTC(stake.product?.expiresOn) : 'N/A'}
            textColor='#6FBE45'
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default EarnDetails;
