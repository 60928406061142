import { usePagination } from '@ajna/pagination';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import {
  ActionButton,
  ExportBitwaya,
  FilterSelect,
  IntegrationUpdateStatusModal,
  PaginationWrapper,
  Refresh,
  ScrollRightContainer,
  SearchInput,
  TableDataStatus,
} from 'components';
import { useDebounce, useStorage } from 'hooks';
import { useState } from 'react';
import { useIntegrationDepositsQuery } from 'store/services/integrations.service';
import { constant, formatDateToUTC } from 'utils';

const IntegrationsDeposit = () => {
  const { setStorage, storageValue } = useStorage(constant.BITWAYA_DEPOSIT_STATUS);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [status, setStatus] = useState(storageValue ? storageValue : 'PaymentMade');

  const [queryValue, setQueryValue] = useState('');
  // const [queryType, setQueryType] = useState('');

  const { data, isLoading, refetch, isFetching } = useIntegrationDepositsQuery({
    page,
    size,
    status,
    queries: queryValue ? `search=${queryValue}` : '',
  });
  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: size,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSize = (e) => {
    setCurrentPage(1);
    setSize(e.target.value);
  };

  const handleTransferStatus = (e) => {
    const value = e.target.value;

    setCurrentPage(1);
    setPage(1);

    setStatus(value);
    setStorage(value);
  };

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [modalData, setModalData] = useState({});

  const handleUpdate = (data) => {
    onOpen();

    setModalData(data);
  };

  // const handleQueryType = (e) => {
  //   setQueryType(e.target.value);
  // };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setQueryValue(query);
      setCurrentPage(1);
    } else {
      setQueryValue('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  return (
    <Box>
      <Flex
        mb='16px'
        justifyContent='space-between'
      >
        <Flex gridGap='12px'>
          <FilterSelect
            width='88px'
            onChange={handleSize}
          >
            <option value={10}>Size</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </FilterSelect>

          <FilterSelect
            width='180px'
            onChange={handleTransferStatus}
            value={status}
          >
            <option value=''>All Status</option>
            <option value='PaymentMade'>PaymentMade</option>
            <option value='PaymentFailed'>PaymentFailed</option>
            <option value='PaymentCancelled'>PaymentCancelled</option>
            <option value='PaymentPending'>PaymentPending</option>
            <option value='PaymentIncomplete'>PaymentIncomplete</option>
          </FilterSelect>

          {/* <FilterSelect
            width='110px'
            onChange={handleQueryType}
          >
            <option>Filter</option>
            <option value='fiatAmount'>Amount</option>
            <option value='userSearch'>User</option>
            <option value='reference'>Reference</option>
          </FilterSelect> */}

          <Box
            alignSelf='center'
            width='100%'
          >
            <SearchInput
              placeholder='Search'
              minH='48px'
              width={{ base: '100%', tb: '319px' }}
              onChange={debouncedChangeHandler}
              // disabled={queryType.length <= 1}
            />
          </Box>
        </Flex>

        <Flex
          alignItems='center'
          gap='16px'
        >
          <Refresh
            onClick={refetch}
            isLoading={isFetching}
          />

          <ExportBitwaya
            queries={`search=${queryValue}`}
            transferStatus={status}
            paymentStatus={status}
            exportType='deposit'
          />
        </Flex>
      </Flex>

      <>
        <ScrollRightContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Date/Time</Th>
                <Th>Payment Status</Th>
                <Th>Email</Th>
                <Th>Fiat Amount</Th>
                <Th>Crypto Volume</Th>
                <Th>Action</Th>
                <Th>Partner ID</Th>
                <Th>Reference</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data &&
                data.data.map(
                  (
                    {
                      _id,
                      createdAt,
                      partnerId,
                      identification,
                      fiatType,
                      fiatAmount,
                      cryptoVolume,
                      cryptoType,
                      reference,
                      processingFee,
                      networkFee,
                      fee,
                      feeUnit,
                      paymentStatus,
                      userAddress,
                      paymentUrl,
                    },
                    i
                  ) => {
                    const response = {
                      _id,
                      createdAt,
                      partnerId,
                      identification,
                      fiatType,
                      fiatAmount,
                      cryptoVolume,
                      cryptoType,
                      reference,
                      processingFee,
                      networkFee,
                      fee,
                      feeUnit,
                      paymentStatus,
                      userAddress,
                      paymentUrl,
                    };

                    const netCryptoVolume = cryptoVolume - fee - processingFee - networkFee;

                    return (
                      <Tr
                        key={i}
                        onDoubleClick={() => handleUpdate(response)}
                      >
                        <Td>{formatDateToUTC(createdAt)}</Td>

                        <Td
                          onClick={() => {
                            setPage(1);
                            setStatus(paymentStatus);
                            setStorage(paymentStatus);
                          }}
                          cursor='pointer'
                        >
                          <TableDataStatus status={paymentStatus} />
                        </Td>

                        <Td>{identification?.id}</Td>

                        <Td textTransform='uppercase'>
                          {fiatType} {Number(fiatAmount).toLocaleString()}
                        </Td>

                        <Td textTransform='uppercase'>{`${cryptoVolume} | (Net: ${netCryptoVolume}) ${cryptoType}`}</Td>

                        <Td>
                          <ActionButton onClick={() => handleUpdate(response)}>Update</ActionButton>
                        </Td>

                        <Td>{partnerId}</Td>

                        <Td>{reference}</Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </ScrollRightContainer>

        {isOpen && (
          <IntegrationUpdateStatusModal
            isOpen={isOpen}
            modalData={modalData}
            onClose={onClose}
          />
        )}

        <PaginationWrapper
          setCurrentPage={setCurrentPage}
          totalDataCount={isLoading ? 0 : data?.totalCount}
          currentPage={currentPage}
          setPage={setPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          size={size}
          offset={offset}
          pages={pages}
        />
      </>
    </Box>
  );
};

export default IntegrationsDeposit;
