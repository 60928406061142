import { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  useToast,
  Center,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ActionButton, Button, CloseModal } from 'components';
import { useProcessDisbursementMutation } from 'store/services/disbursement';

const ProcessDisbursementModal = ({ id, status }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [_processDisbursement, { isLoading, error }] =
    useProcessDisbursementMutation();

  const handleSubmit = async () => {
    const response = await _processDisbursement({
      id,
    });

    if (response.data) {
      onClose();

      toast({
        title: 'Success',
        status: 'success',
        description: `${response.data.message}`,
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    error &&
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.error || error?.data?.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
  }, [error, toast]);

  const disabled =
    status === 'completed' || status === 'cancelled' ? true : false;

  return (
    <>
      <ActionButton
        border={disabled ? '1px solid #ccc' : '1px solid #6FBE45'}
        bg={disabled ? 'brand.primary' : 'transparent'}
        color={disabled ? '#fff' : '#303131'}
        disabled={disabled}
        _disabled={{
          cursor: 'not-allowed',
        }}
        onClick={onOpen}
      >
        {status.toLowerCase() === 'completed' ? 'Processed' : 'Process'}
      </ActionButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="16px" maxWidth="420px">
          <ModalHeader padding="32px 0" fontWeight="600" textAlign="center">
            Process Disbursement
          </ModalHeader>

          <ModalBody>
            <Text textAlign="center">
              Are you sure you want to process this disbursement?
            </Text>

            <Center mt="32px">
              <Button
                onClick={handleSubmit}
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
              >
                Submit
              </Button>
            </Center>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProcessDisbursementModal;
