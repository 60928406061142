import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Button, ConfigCheckbox, CountryConfigModal, Input, Loader, PageTitle } from 'components';
import { useMemo, useState } from 'react';
import { useGetCountryConfigsQuery } from 'store/services/app.service';

const CountryConfig = () => {
  const { data, isSuccess, isLoading, refetch } = useGetCountryConfigsQuery();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [properties, setProperties] = useState({});
  const [checkboxState, setCheckboxState] = useState({});
  const [modalData, setModalData] = useState({});

  const handleCheckboxChange = (header, value, key, newValue) => {
    setCheckboxState({
      ...checkboxState,
      [key]: value,
    });

    if (typeof newValue === 'undefined') {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: value,
        },
      });
    }

    if (typeof newValue !== 'undefined') {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: {
            ...(properties[header] ? { ...properties[header][key] } : null),
            [newValue]: value,
          },
        },
      });
    }
  };

  const handleInputChange = (header, value, key, secondLevel, thirdLevelValue, fourthLevelValue) => {
    if (typeof secondLevel === 'undefined') {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: value,
        },
      });
    }

    if (typeof secondLevel !== 'undefined' && thirdLevelValue === undefined) {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: {
            ...(properties[header] ? { ...properties[header][key] } : null),
            [value]: secondLevel,
          },
        },
      });
    }
  };

  const handleUpdate = () => {
    onOpen();
    setModalData(properties);
  };

  const [search, setSearch] = useState('');

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = useMemo(() =>
    data?.country &&
    Object.entries(data?.country).filter(([key, _]) => {
      return String(key).toLowerCase().includes(search.toLowerCase());
    }), [data, search])

  return (
    <Box>
      <PageTitle
        title='Country Config'
        fontSize={{ base: '18px', md: '24px' }}
      />

      {isLoading && <Loader />}

      {isSuccess && (
        <Flex
          justifyContent='space-between'
          gap='24px'
          pos='relative'
          mt='29px'
        >
          <Box width='100%'>
            <Accordion
              allowMultiple={false}
              allowToggle
              border='0.5px solid #CACBCB'
              borderRadius='10px'
              filter='drop-shadow(0px 4px 24px rgba(67, 55, 55, 0.2))'
              mb='30px'
              padding='18px 0px'
              maxWidth='720px'
            >
              {filteredData?.map(([header, headerValue], index) => {
                return (
                  <AccordionItem
                    key={index}
                    border='none'
                    padding={{ base: '0', md: '0 24px' }}
                    mb='2rem'
                  >
                    <Flex
                      alignItems='center'
                      gap='8px'
                    >
                      <AccordionButton
                        as='div'
                        p={{ base: '10px', md: '20px' }}
                        textTransform='capitalize'
                        _expanded={{ bg: 'rgba(0, 0, 0, 0.04)' }}
                        transition='all 300ms ease-in-out'
                      >
                        <Box
                          as='span'
                          flex='1'
                          fontSize='18px'
                          textAlign='left'
                          fontWeight='500'
                        >
                          {header}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <Box>
                        {Object.keys(properties).includes(header) && (
                          <Button
                            minW='100px'
                            minH='38px'
                            fontSize='11px'
                            onClick={handleUpdate}
                          >
                            Update
                          </Button>
                        )}
                      </Box>
                    </Flex>

                    <AccordionPanel
                      pb={4}
                      fontSize='14px'
                    >
                      {typeof headerValue === 'string'
                        ? headerValue
                        : Object.entries(headerValue).map(([key, value], i) => {
                          return (
                            <Flex
                              key={i}
                              width='100%'
                              justifyContent='space-between'
                              maxWidth='initial'
                              alignItems='center'
                              p='16px 0'
                              borderBottom='1px solid #EDEBF9'
                            >
                              <Text fontWeight='500'>{key}:</Text>

                              <Box>
                                {typeof value === 'string' ||
                                  typeof value === 'number' ||
                                  typeof value === 'boolean' ? (
                                  <>
                                    {(typeof value === 'number' || typeof value === 'string') && (
                                      <Input
                                        fontSize='14px'
                                        type={typeof value === 'number' ? 'number' : 'text'}
                                        width='100px'
                                        padding='18px'
                                        defaultValue={value}
                                        onChange={(e) =>
                                          handleInputChange(
                                            header,
                                            typeof value === 'number' ? Number(e.target.value) : e.target.value,
                                            key
                                          )
                                        }
                                      />
                                    )}

                                    {typeof value === 'boolean' && (
                                      <>
                                        <ConfigCheckbox
                                          onChange={(event) =>
                                            handleCheckboxChange(header, event.target.checked, key)
                                          }
                                          defaultChecked={value}
                                        />
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </Flex>
                          );
                        })}
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Box>

          <Box
            maxWidth='320px'
            width='100%'
            pos='sticky'
            ml='auto'
            height='400px'
            top='90px'
          >
            <Input
              width='100%'
              placeholder='Search country'
              onChange={handleSearch}
            />
          </Box>
        </Flex>
      )}

      {isSuccess && (
        <CountryConfigModal
          isOpen={isOpen}
          onClose={onClose}
          modalData={modalData}
          data={data?.country}
          refetch={refetch}
        />
      )}
    </Box>
  );
};

export default CountryConfig;
