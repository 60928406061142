import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders, wrapBaseApi } from 'utils';
import { get } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const initialState = {
  loans: {
    status: 'idle',
    data: [],
    totalCount: 0,
    beforeAndAfter: '',
  },

  loanProfile: {
    status: 'idle',
    data: [],
    totalCount: 0,
    username: '',
  },
};

export const fetchAllLoans = createAsyncThunk('bnpl/loans', async ({ limit, page, loanStatus, time }) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/wallet/admin/loans?limit=${limit}&page=${page}&loanStatus=${loanStatus}&${time}`),

    getHeaders()
  );
  return response.data;
});

export const fetchLoanProfiles = createAsyncThunk('bnpl/loanProfiles', async ({ userId, page, loanStatus, limit }) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/wallet/admin/loans?userId=${userId}&limit=${limit}&page=${page}&loanStatus=${loanStatus}`),

    getHeaders()
  );
  return response.data;
});

const inplSlice = createSlice({
  name: 'bcpl',

  initialState,

  reducers: {
    getLoanCustomDate(state, action) {
      state.loans.beforeAndAfter = action.payload;
    },
  },

  extraReducers: (builder) => {
    // loans
    builder.addCase(fetchAllLoans.fulfilled, (state, { payload }) => {
      state.loans.status = 'fulfilled';
      state.loans.data = payload.data.loans;
      state.loans.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchAllLoans.pending, (state) => {
      state.loans.status = 'loading';
    });

    builder.addCase(fetchAllLoans.rejected, (state) => {
      state.loans.status = 'error';
    });

    builder.addCase(fetchLoanProfiles.fulfilled, (state, { payload }) => {
      state.loanProfile.status = 'fulfilled';
      state.loanProfile.data = payload.data.loans;
      state.loanProfile.username = payload.data.loans[0].userDetails.username;
      state.loanProfile.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchLoanProfiles.pending, (state) => {
      state.loanProfile.status = 'loading';
    });

    builder.addCase(fetchLoanProfiles.rejected, (state) => {
      state.loanProfile.status = 'error';
    });
  },
});

export const {
  actions: { getLoanCustomDate },
  reducer: bnplReducer,
} = inplSlice;
