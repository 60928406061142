import { usePagination } from '@ajna/pagination';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Flex } from '@chakra-ui/react';
import { FilterSelect, Loader, PaginationWrapper, ScrollRightContainer, SearchInput } from 'components';
import { useDebounce } from 'hooks';
import { useState } from 'react';
import { useIntegrationUserQuery } from 'store/services/integrations.service';
import { formatDateToUTC } from 'utils';

const IntegrationsUsers = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchString, setSearchString] = useState('');
  const { data, isLoading } = useIntegrationUserQuery({
    page,
    size,
    search: searchString,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: size,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      console.log('a search');
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };
  const debouncedChangeHandler = useDebounce(handleSearch);

  return (
    <Box>
      <Flex
        mb='16px'
        gridGap='12px'
        alignItems='center'
      >
        <Box
          maxWidth='320px'
          width='100%'
        >
          <SearchInput onChange={debouncedChangeHandler} />
        </Box>

        <FilterSelect
          width='88px'
          onChange={handleSize}
        >
          <option value={10}>Size</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </FilterSelect>
      </Flex>
      <>
        {isLoading && <Loader />}

        <ScrollRightContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Partner ID</Th>
                <Th>KYC Verified</Th>
                <Th>Last Login</Th>
                <Th>Last IP</Th>
                <Th>Date/Time</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data &&
                data.data.map(({ _id, createdAt, partnerId, lastIp, identification, isKycVerified, lastLogIn }) => {
                  return (
                    <Tr key={_id}>
                      <Td>{identification?.id}</Td>

                      <Td>{partnerId}</Td>

                      <Td>{String(isKycVerified)}</Td>

                      <Td>{formatDateToUTC(lastLogIn)}</Td>

                      <Td>{lastIp}</Td>

                      <Td>{formatDateToUTC(createdAt)}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </ScrollRightContainer>

        <PaginationWrapper
          setCurrentPage={setCurrentPage}
          totalDataCount={isLoading ? 0 : data?.totalCount}
          currentPage={currentPage}
          setPage={setPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          size={size}
          offset={offset}
          pages={pages}
        />
      </>
    </Box>
  );
};

export default IntegrationsUsers;
