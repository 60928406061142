const showFirstThreeChar = (char, count) => {
  if (char === undefined) {
    return '';
  } else return char.substr(0, count ? count : 3);
};

const showLastThreeChar = (char, count) => {
  if (char === undefined) {
    return '';
  } else return char.substr(count ? count : -3);
};

export { showFirstThreeChar, showLastThreeChar };
