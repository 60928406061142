import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  Loader,
  P2PTableStatus,
  PaginationWrapper,
  ScrollRightContainer,
} from 'components';
import { formatDateToUTC } from 'utils';
import { fetchP2PCompletedTrades } from 'store/slices/p2p.slice';

const P2PAllCompletedTable = ({ date, currency, coin, searchString, size }) => {
  const dispatch = useDispatch();
  const { data, status, totalCount } = useSelector((state) => state.p2p.trade);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize: size },
      total: totalCount,
      limits: {
        inner: 2,
        outer: 1,
      },
    });

  useEffect(() => {
    dispatch(
      fetchP2PCompletedTrades({
        limit: size,
        page: 1,
        coin: coin === 'all' ? '' : coin,
        currency: currency === 'all' ? '' : currency,
        date:
          date.before === undefined
            ? `beforeDate=${date}`
            : `afterDate=${date.before}&beforeDate=${date.after}`,
        search: searchString,
      })
    );
  }, [coin, currency, date, searchString, dispatch, size]);

  return (
    <>
      {status === 'loading' && <Loader />}

      <ScrollRightContainer>
        <Table>
          {data.length >= 1 && (
            <>
              <Thead>
                <Tr>
                  <Th>Trade Ref. No.</Th>
                  <Th>Country</Th>
                  <Th>Amount</Th>
                  <Th>Coin traded</Th>
                  <Th>Unit Cost</Th>
                  <Th>Parties</Th>
                  <Th>Status</Th>
                  <Th>Date/Time</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data.map(
                  ({
                    _id,
                    adId,
                    country,
                    coin,
                    coinValue,
                    currencyValue,
                    createdAt,
                    paymentStatus,
                    parties,
                    unitCost,
                    destinationUnit,
                    sourceUnit,
                    updatedAt,
                  }) => {
                    const formattedCreatedAt = formatDateToUTC(createdAt);
                    const formattedUpdatedAt = formatDateToUTC(updatedAt);

                    return (
                      <Tr key={_id}>
                        <Td>{adId}</Td>

                        <Td textTransform="capitalize">{country}</Td>

                        <Td textTransform="uppercase">
                          {destinationUnit}{' '}
                          {currencyValue
                            ? `${currencyValue?.toLocaleString()}`
                            : 'N/A'}
                        </Td>

                        <Td textTransform="uppercase">
                          {coin
                            ? `${Number(coinValue).toLocaleString()}`
                            : 'N/A'}{' '}
                          {sourceUnit}
                        </Td>

                        <Td>{Number(unitCost).toLocaleString()}</Td>

                        <Td fontWeight="medium">
                          <Text>
                            <a
                              href={`/dashboard/users/${parties[0]?.userId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Text as="span">{parties[0]?.role}</Text>:{' '}
                              {parties[0]?.username}
                            </a>
                          </Text>

                          <Text fontWeight="medium">
                            <a
                              href={`/dashboard/users/${parties[1]?.userId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Text as="span">{parties[1]?.role}</Text>:{' '}
                              {parties[1]?.username}
                            </a>
                          </Text>
                        </Td>

                        <Td
                          textAlign="center"
                          fontSize="10px"
                          padding="2px 12px"
                          fontWeight="600"
                          textTransform="capitalize"
                        >
                          <P2PTableStatus status={paymentStatus} />
                        </Td>

                        <Td>
                          <Text>{formattedCreatedAt}</Text>
                          <Text>{formattedUpdatedAt}</Text>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </>
          )}
        </Table>
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={size}
      />
    </>
  );
};

export default P2PAllCompletedTable;
