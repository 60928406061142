import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ActionButton } from 'components';
import { FaPen } from 'react-icons/fa';
import { useState } from 'react';
import { useAddNotesMutation } from 'store/services/notes.service';
import { UserNotes } from 'pages';
import { clearInput } from 'components/Editor/SlateComponents';
import { useEditor } from 'hooks';
import ReplyNote from 'pages/Users/ReplyNote';
import RichEditorWithButton from 'components/Editor/RichEditorWithButton';
import { useNavigate } from 'react-router-dom';
import RoundCloseModal from 'components/CloseModal/RoundCloseModal';

const AddNotesModal = ({ userId, fullName }) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [content, setContent] = useState('');

  const [_addNote, { isLoading }] = useAddNotesMutation({});

  const { editor } = useEditor();

  const handleSubmit = async () => {
    try {
      const response = await _addNote({
        userId,
        attachments: [],
        note: content,
        sentiment: 'bad',
      }).unwrap();

      clearInput(editor);
      toast({
        title: 'Success',
        description: response?.message,
        status: 'success',
        position: 'bottom-right',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error?.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  const [noteId, setNoteId] = useState('');

  const [includeContext, setIncludeContext] = useState(false);

  const navigate = useNavigate();
  const createThread = (id, context) => {
    setNoteId(id);
    navigate(`/dashboard/users/${userId}?note_id=${id}`);
    setIncludeContext(context);
  };

  const handleClose = () => {
    onClose();
    setNoteId('');
    navigate(`/dashboard/users/${userId}`);
  };

  const handleReplyClose = () => {
    setNoteId('');
    navigate(`/dashboard/users/${userId}`);
  };

  return (
    <Box>
      <ActionButton
        onClick={onOpen}
        bg='#0D4740'
        color='#fff'
        border='none'
        minH='38px'
      >
        <Flex
          alignItems='center'
          gap='6px'
        >
          <FaPen />

          <Text>Notes</Text>
        </Flex>
      </ActionButton>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size='full'
        closeOnEsc={false}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='0'
          width='100%'
          pb='24px'
          pt='16px'
        >
          <Flex
            maxW='1240px'
            mx='auto'
            width='100%'
            gap='16px'
          >
            <Box
              width='100%'
              maxW='920px'
              mx='auto'
            >
              <Flex
                justifyContent='space-between'
                bg='#F7F7FC'
                alignItems='center'
                padding='24px 38px 24px 24px'
                borderTopRadius='16px'
              >
                <Text
                  fontWeight='600'
                  textAlign='center'
                  margin='0'
                  fontSize='18px'
                  color='#000'
                >
                  {fullName}'s' Notes
                </Text>

                <RoundCloseModal onClick={handleClose} />
              </Flex>

              <ModalBody
                border='2px solid #F7F7FC'
                borderBottomRadius='16px'
              >
                <UserNotes
                  userId={userId}
                  createThread={createThread}
                  includeContext={includeContext}
                />

                <RichEditorWithButton
                  editor={editor}
                  handleSubmit={handleSubmit}
                  isLoading={isLoading}
                  setContent={setContent}
                />
              </ModalBody>
            </Box>

            {noteId && (
              <ReplyNote
                id={noteId}
                clearInput={clearInput}
                toast={toast}
                userId={userId}
                includeContext={includeContext}
                handleClose={handleReplyClose}
              />
            )}
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddNotesModal;
