import { Navigate, useLocation } from 'react-router-dom';
import { Flex, VStack, Box } from '@chakra-ui/react';
import { BottomNavbar, Header, Sidebar } from 'components';
import { constant, ls } from 'utils';

const PrivateRoute = ({ children }) => {
  const auth = ls.get(constant.AUTH_TOKEN_KEY);
  const location = useLocation();

  return auth.isSignedIn ? (
    <Flex
      flexDir={{ sm: 'column', md: 'row' }}
      pos='relative'
    >
      <Box
        maxWidth='265px'
        width='100%'
        display={{ sm: 'none', md: 'block' }}
      >
        <Sidebar />
      </Box>

      <VStack width='100%'>
        <Header />

        <Box
          maxWidth='1250px'
          width='100%'
          padding={{ sm: '24px 12px', lg: '24px 59px' }}
          margin='auto'
        >
          {children}
        </Box>
      </VStack>

      <Box
        mt='130px'
        display={{ sm: 'block', md: 'none' }}
      >
        <BottomNavbar />
      </Box>
    </Flex>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        search: `redirectUrl=${location.pathname}`,
      }}
      state={{ from: location }}
      replace
    />
  );
};

export default PrivateRoute;
