import { useEffect, useState } from 'react';
import { Table, Tr, Td, Th, Thead, Tbody, Flex, useDisclosure, Text, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination } from '@ajna/pagination';
import {
  PaginationWrapper,
  ScrollRightContainer,
  TableActionButton,
  TableDataStatus,
  ViewMore,
  UpdateWithdrawalStatus,
  WalletWithdrawalModal,
  Refresh,
  UserTransactionModal,
  CurrencyFormat,
} from 'components';
import { constant, formatDateToUTC } from 'utils';
import { fetchAllWithdrawal, getWithdrawalType } from 'store/slices/wallet.slice';
import { useDebounce, useStorage } from 'hooks';
import WalletWithdrawalTableFilter from './WalletWithrawalTableFilter';

const WalletWithdrawalTable = () => {
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { storageValue, setStorage } = useStorage(constant.WITHDRAWAL_TYPE);
  const { storageValue: statusStorage, setStorage: setStatusStorage } = useStorage(constant.WI_STATUS);
  const { storageValue: sizeStorage, setStorage: setSizeStorage } = useStorage(constant.WI_SIZE);
  // const { storageValue: searchStorage, setStorage: setSearchStorage } = useStorage(constant.WI_SEARCH);

  const [type, setType] = useState(storageValue ? storageValue : 'fiat');

  const [queryValue, setQueryValue] = useState();
  // const [queryType, setQueryType] = useState(searchStorage ? searchStorage : '');
  const [txStatus, setTxStatus] = useState(statusStorage ? statusStorage : '');
  const [selectedData, setSelectedData] = useState({});
  const [size, setSize] = useState(sizeStorage ? sizeStorage : 10);
  const [actionValue, setActionValue] = useState('');

  // const handleQueryType = (e) => {
  //   setQueryType(e.target.value);
  //   setSearchStorage(e.target.value);
  // };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setQueryValue(query);
      setCurrentPage(1);
    } else {
      setQueryValue('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  const { data, status } = useSelector((state) => state.wallet.withdraw);

  const handleOpen = (data) => {
    onOpen();
    setSelectedData(data);
    setActionValue('');
  };

  const handleActions = (e, data) => {
    setActionValue(e.target.value);
    onOpen();
    setSelectedData(data);
  };

  const handleWidthrawalType = (e) => {
    setStorage(e.target.value);

    setType(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    setCurrentPage(1);
    setTxStatus(e.target.value);
    setStatusStorage(e.target.value);
  };

  const handleSize = (e) => {
    setSize(e.target.value);
    setSizeStorage(e.target.value);
  };

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: { currentPage: 1, pageSize: size },
    total: data.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  useEffect(() => {
    dispatch(
      fetchAllWithdrawal({
        page: currentPage,
        limit: size,
        withdrawalType: type,
        status: txStatus,
        queries: `${'search'}=${queryValue ?? ''}`,
      })
    );

    dispatch(getWithdrawalType(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageSize, currentPage, type, setCurrentPage, txStatus, size, queryValue]);

  const handleRefresh = () => {
    dispatch(
      fetchAllWithdrawal({
        page: currentPage,
        limit: size,
        withdrawalType: type,
        status: txStatus,
        queries: `${'search'}=${queryValue ?? ''}`,
      })
    );
  };

  console.log(data, 'DATA');

  return (
    <>
      <Flex
        mt='38px'
        mb='48px'
        justifyContent='space-between'
        alignItems='center'
      >
        <WalletWithdrawalTableFilter
          debouncedChangeHandler={debouncedChangeHandler}
          defaultType={type}
          handleSize={handleSize}
          handleStatusChange={handleStatusChange}
          defaultStatus={txStatus}
          handleType={handleWidthrawalType}
          defaultSize={size}
          // handleQueryType={handleQueryType}
          // defaultFilter={queryType}
        />

        <Box>
          <Refresh
            isLoading={status === 'loading'}
            onClick={handleRefresh}
          />
        </Box>
      </Flex>

      <>
        <ScrollRightContainer minHeight='30vh'>
          <Table overflow='hidden'>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Name</Th>
                <Th>Amount</Th>
                <Th>Date & Time</Th>
                <Th>Source</Th>
                <Th>Status</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data &&
                data.withdrawals.map(
                  (
                    {
                      _id,
                      user,
                      createdAt,
                      coin,
                      withdrawalType,
                      status,
                      amount,
                      bankDetails,
                      addressRef,
                      txRef,
                      destination,
                      withdrawalFee,
                      approvedBy,
                      txLink,
                      txHash,
                      canceledBy,
                      deniedBy,
                      payoutId,
                      enterpriseWithdrawalId,
                      reason,
                    },
                    index
                  ) => {
                    const modalData = {
                      _id,
                      user,
                      createdAt,
                      coin,
                      withdrawalType,
                      status,
                      amount,
                      bankDetails,
                      addressRef,
                      txRef,
                      destination,
                      withdrawalFee,
                      approvedBy,
                      txLink,
                      txHash,
                      canceledBy,
                      deniedBy,
                      payoutId,
                      enterpriseWithdrawalId,
                      reason,
                    };

                    return (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>

                        <UserTransactionModal
                          userId={user.userId}
                          fullName={user?.fullName}
                        />

                        <Td>
                          {/* {coin === 'ngn' && <span>&#x20A6;</span>}
                          {coin === 'ghs' && <span>&#8373;</span>}
                          {coin === 'kes' && 'KSh'}  */}
                          <CurrencyFormat currency={coin} />
                          &nbsp;
                          {withdrawalType === 'crypto' ? amount : Number(amount).toLocaleString()}
                          &nbsp;
                          {withdrawalType === 'crypto' && (
                            <Text
                              textTransform='uppercase'
                              as='span'
                            >
                              {coin}
                            </Text>
                          )}
                        </Td>

                        <Td>{formatDateToUTC(createdAt)}</Td>

                        <Td textTransform='capitalize'>{withdrawalType}</Td>

                        <Td textTransform='capitalize'>
                          <Flex
                            gridGap='0.5rem'
                            alignItems='center'
                          >
                            <TableDataStatus
                              status={
                                String(status).toLowerCase() === 'processing' && payoutId ? 'Auto Processing' : status
                              }
                            />
                            <>
                              {status === 'pending' && (
                                <UpdateWithdrawalStatus
                                  _id={_id}
                                  data={selectedData}
                                  txStatus={txStatus}
                                  withdrawalType={type}
                                />
                              )}
                            </>
                          </Flex>
                        </Td>

                        <Td
                          display='flex'
                          gridGap='8px'
                        >
                          {status === 'approved' ||
                          status === 'cleared' ||
                          status === 'denied' ||
                          status === 'cancelled' ? (
                            <ViewMore onClick={() => handleOpen(modalData)}>View more</ViewMore>
                          ) : (
                            <TableActionButton onClick={(e) => handleActions(e, modalData)} />
                          )}
                        </Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </ScrollRightContainer>

        <WalletWithdrawalModal
          isOpen={isOpen}
          onClose={onClose}
          data={selectedData}
          actionValue={actionValue}
          currentPage={currentPage}
          pageSize={pageSize}
          type={type}
          status={txStatus}
        />

        <PaginationWrapper
          setCurrentPage={setCurrentPage}
          totalDataCount={data.totalCount}
          currentPage={currentPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          offset={offset}
          pages={pages}
          size={size}
        />
      </>
    </>
  );
};

export default WalletWithdrawalTable;
