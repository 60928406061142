import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Button, CloseModal, FilterSelect, Input } from 'components';
import { FilterSVG } from 'assets';
import { fetchAllStakings } from 'store/slices/staking.slice';
import { convertToIso } from 'utils';

const FilterEarn = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.stakings.staking);

  const [selected, setSelected] = useState({
    filterBy: '',
  });

  const handleSelectedFilter = (e) => {
    setSelected({
      [e.target.name]: e.target.value,
    });
  };

  const [filterValue, setFilterValue] = useState({
    unit: null,
    country: null,
    status: null,
    afterDate: null,
    beforeDate: null,
  });

  const handleFilterValue = (e) => {
    setFilterValue({
      ...filterValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      fetchAllStakings({
        unit: filterValue.unit,
        status: filterValue.status,
        country: filterValue.country,
        beforeDate: filterValue.afterDate
          ? convertToIso(filterValue.afterDate)
          : undefined,
        afterDate: filterValue.beforeDate
          ? convertToIso(filterValue.beforeDate)
          : undefined,
      })
    );

    onClose();

    if (status === 'fulfilled') {
      setFilterValue({
        unit: null,
        country: null,
        status: null,
        afterDate: null,
        beforeDate: null,
      });
    }
  };

  return (
    <Box>
      <Flex
        gridGap="5px"
        alignItems="center"
        onClick={onOpen}
        cursor="pointer"
        _hover={{
          fontWeight: '600',
          color: '#797A7A',
        }}
      >
        <Text fontSize="14px" fontWeight="500">
          Filter
        </Text>

        <FilterSVG />
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent padding="16px 13px" borderRadius="10px" maxWidth="399px">
          <ModalHeader
            mb="3px"
            fontSize="24px"
            fontWeight="600"
            textAlign="center"
          >
            Filter
          </ModalHeader>

          <ModalBody>
            <Box mb="17px">
              <Text
                display="block"
                mb="0.5rem"
                fontSize="14px"
                fontWeight="400"
                htmlFor="filterBy"
                as="label"
              >
                Filter By
              </Text>

              <FilterSelect
                width="100%"
                name="filterBy"
                id="filterBy"
                lineHeight="24px"
                onChange={handleSelectedFilter}
                defaultValue={selected.filterBy}
              >
                <option>Select</option>
                <option value="coin">Coin type</option>
                <option value="status">Status of Staking</option>
                <option value="country">Country</option>
                <option value="date">Date</option>
              </FilterSelect>
            </Box>

            {/* Coin Type */}

            {selected.filterBy === 'coin' && (
              <Box mb="17px">
                <FilterSelect
                  width="100%"
                  name="unit"
                  id="unit"
                  onChange={handleFilterValue}
                  defaultValue={filterValue.unit}
                >
                  <option value="btc">BTC</option>
                  <option value="eth">ETH</option>
                  <option value="usdt">USDT</option>
                  <option value="celo">CELO</option>
                </FilterSelect>
              </Box>
            )}

            {/* Status */}

            {selected.filterBy === 'status' && (
              <Box mb="17px">
                <FilterSelect
                  width="100%"
                  name="status"
                  id="status"
                  onChange={handleFilterValue}
                  defaultValue={filterValue.status}
                  placeholder="Status"
                >
                  <option value="active">Active</option>
                  <option value="terminated">Terminated</option>
                  <option value="rolledover">Rolled over</option>
                  <option value="redeemed">Redeemed</option>
                </FilterSelect>
              </Box>
            )}

            {/* Country */}

            {selected.filterBy === 'country' && (
              <Box mb="17px">
                <FilterSelect
                  width="100%"
                  name="country"
                  id="country"
                  onChange={handleFilterValue}
                  defaultValue={filterValue.country}
                >
                  <option value="nigeria">Nigeria</option>
                  <option value="ghana">Ghana</option>
                  <option value="kenya">Kenya</option>
                </FilterSelect>
              </Box>
            )}

            {selected.filterBy === 'date' && (
              <Box mb="17px">
                <Box>
                  <Text
                    display="block"
                    mb="0.5rem"
                    fontSize="14px"
                    fontWeight="400"
                    htmlFor="beforeDate"
                    as="label"
                  >
                    From
                  </Text>

                  <Input
                    id="beforeDate"
                    name="beforeDate"
                    minH="35px"
                    type="date"
                    onChange={handleFilterValue}
                    defaultValue={filterValue.beforeDate}
                  />
                </Box>

                <Box mt="17px">
                  <Text
                    display="block"
                    mb="0.5rem"
                    fontSize="14px"
                    fontWeight="400"
                    htmlFor="afterDate"
                    as="label"
                  >
                    To
                  </Text>

                  <Input
                    id="afterDate"
                    name="afterDate"
                    minH="35px"
                    type="date"
                    onChange={handleFilterValue}
                    defaultValue={filterValue.afterDate}
                  />
                </Box>
              </Box>
            )}

            <Button
              minH="45px"
              width="100%"
              disabled={
                filterValue.unit ||
                filterValue.status ||
                filterValue.country ||
                filterValue.afterDate ||
                filterValue.beforeDate
                  ? false
                  : true
              }
              onClick={handleSubmit}
            >
              Filter
            </Button>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FilterEarn;
