import { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { HiCheck } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import {
  ApproveBcplRequestModal,
  DeclineBcplRequestModal,
  ActionButton,
} from 'components';

const ApproveDeclineBcpl = ({ data }) => {
  const [openApprove, setOpenApprove] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);

  const handleOpen = () => {
    setOpenApprove(true);
  };

  const handleDeclineOpen = () => {
    setOpenDecline(true);
  };

  const handleClose = () => {
    setOpenApprove(false);
    setOpenDecline(false);
  };

  return (
    <Flex gridGap="10px">
      <ActionButton
        bg="brand.primary"
        color="#fff"
        border="1px solid #0D4740"
        minW="100px"
        height="38px"
        onClick={handleOpen}
      >
        <Flex alignItems="center" gridGap="5px">
          <HiCheck fontSize="1rem" /> <Text fontSize="12px">Approve</Text>
        </Flex>
      </ActionButton>

      <ActionButton
        minW="100px"
        height="38px"
        onClick={handleDeclineOpen}
        border="1px solid #ED1C00"
        _hover={{
          bg: '#ED1C00',
          border: '1px solid #ED1C00',
          color: '#fff',
        }}
        _active={{
          bg: '#ED1C00',
          border: '1px solid #ED1C00',
          color: '#fff',
        }}
        _focus={{
          bg: '#ED1C00',
          border: '1px solid #ED1C00',
          color: '#fff',
        }}
      >
        <Flex alignItems="center" gridGap="5px">
          <IoMdClose fontSize="1rem" /> <Text fontSize="12px">Decline</Text>
        </Flex>
      </ActionButton>

      <ApproveBcplRequestModal
        isOpen={openApprove}
        onClose={handleClose}
        data={data}
      />

      <DeclineBcplRequestModal
        isOpen={openDecline}
        onClose={handleClose}
        data={data}
      />
    </Flex>
  );
};

export default ApproveDeclineBcpl;
