import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';
import { Loader } from 'components';
import { Link } from 'react-router-dom';

const PartnersLeaderboardUserCount = ({ data, status, errorMessage }) => {
  return (
    <Box
      bg="#F9FAF9"
      borderRadius="20px"
      padding="24px 20px"
      minHeight={'641px'}
    >
      <Box>
        <Text
          fontSize={'18px'}
          fontWeight="600"
          mb="25px"
          fontFamily="PolySans"
          textAlign="center"
        >
          Top 10 partners based on referred users
        </Text>

        {status === 'loading' && <Loader />}

        {status === 'error' && <p>{errorMessage}</p>}

        <Table>
          <Thead bg="transparent" borderBottom="0.5px solid #CACBCB">
            <Tr>
              <Th textAlign="center">Username</Th>
              <Th textAlign="center">Total no. of Users</Th>
            </Tr>
          </Thead>

          <Tbody>
            {status === 'fulfilled' &&
              data.data.map(({ referredCount, username }, i) => {
                return (
                  <Tr key={i} borderBottom="0.5px solid #CACBCB">
                    <Td textAlign="center">{username ? username : 'N/A'}</Td>

                    <Td textAlign="center">{referredCount}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>

        <Box
          color="brand.primary"
          fontSize="14px"
          fontWeight="600"
          textDecoration="underline"
          fontFamily="PolySans"
          mt="25px"
          textAlign="center"
        >
          <Link to={`/dashboard/partners/earnings-referred`}>View All</Link>
        </Box>
      </Box>
    </Box>
  );
};

export default PartnersLeaderboardUserCount;
