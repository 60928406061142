import { Box, Flex, Text } from '@chakra-ui/react';
import { Loader, SlateViewer } from 'components';
import RichEditorWithButton from 'components/Editor/RichEditorWithButton';
import { useEditor } from 'hooks';
import { useState } from 'react';
import { useAddNotesMutation, useGetUserNotesThreadQuery } from 'store/services/notes.service';
import NotesCard from './NotesCard';
import RoundCloseModal from 'components/CloseModal/RoundCloseModal';

const ReplyNote = ({ id, userId, toast, clearInput, includeContext, handleClose }) => {
  const [_addNote, { isLoading }] = useAddNotesMutation({});
  const [content, setContent] = useState('');
  const { editor } = useEditor();

  const {
    data,
    isLoading: _isLoading,
    isFetching,
  } = useGetUserNotesThreadQuery({
    id,
    includeContext,
  });

  const handleSubmit = async () => {
    try {
      const response = await _addNote({
        userId,
        attachments: [],
        note: content,
        sentiment: 'bad',
        parentNoteId: id,
      }).unwrap();

      clearInput(editor);
      toast({
        title: 'Success',
        description: response?.message,
        status: 'success',
        position: 'bottom-right',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error?.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  // console.log(data?.context, 'THE DATA');

  return (
    <Box
      maxWidth='510px'
      width='100%'
      border='2px solid #F7F7FC'
      pos='relative'
      borderRadius='16px'
      padding='16px 16px 0'
    >
      <Box
        mb='16px'
        overflowY='auto'
      >
        <Flex justifyContent='space-between'>
          <Text
            fontSize='20px'
            fontWeight='500'
          >
            Replies
          </Text>

          <RoundCloseModal
            // boxShadow='none'
            boxShadow='-0.005px 1px 5px rgba(19, 10, 46, 0.25)'
            onClick={handleClose}
            bg='#F7F7FC'
          />
        </Flex>

        {_isLoading && <Loader />}

        <Box h='68.5vh'>
          {!isFetching && data && (
            <>
              <SlateViewer
                id={id}
                value={data?.data?.note}
                mb='12px'
                fontWeight='600'
                fontSize='18px'
              />

              <NotesCard
                data={data.context}
                userId={userId}
                createThread={() => {}}
                isReply
              />
            </>
          )}
        </Box>
      </Box>

      <RichEditorWithButton
        editor={editor}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        setContent={setContent}
      />
    </Box>
  );
};

export default ReplyNote;
