import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Heading, useDisclosure } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  FilterSelect,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  SearchInput,
  Select,
  TableDataStatus,
  PhysicalCardRequestModal,
} from 'components';
import { formatDateToUTC } from 'utils';
import { useDebounce } from 'hooks';
import { fetchPhysicalCardRequest } from 'store/slices/card.slice';

const PhysicalCards = () => {
  const dispatch = useDispatch();
  const { onOpen, isOpen, onClose } = useDisclosure();

  const {
    data: { data },
    totalCount,
    status,
  } = useSelector((state) => state.cards.physicalCards);

  const [requestStatus, setRequestStatus] = useState('');
  const [searchString, setSearchString] = useState('');
  const [modalData, setModalData] = useState({});
  const [actionStatus, setActionStatus] = useState('');

  const handleRequestStatusChange = (e) => {
    setRequestStatus(e.target.value);
  };

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const handleOpen = (value, data) => {
    setActionStatus(value);

    if (value !== 'process') {
      // setOpen(true);
      onOpen();
    }
    setModalData(data);
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  useEffect(() => {
    dispatch(
      fetchPhysicalCardRequest({
        limit: 10,
        page: currentPage,
        status: requestStatus,
        search: searchString,
      })
    );
  }, [dispatch, currentPage, requestStatus, searchString]);

  return (
    <Box>
      <Flex
        mb='45px'
        gridGap='1rem'
        alignItems={{ base: 'initial', md: 'center' }}
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Heading
          as='h2'
          color='#797A7A'
          fontSize='1rem'
          fontWeight='700'
        >
          Transactions
        </Heading>

        <Flex
          gridGap='1rem'
          alignItems='center'
          width='100%'
        >
          <FilterSelect
            width='120px'
            onChange={handleRequestStatusChange}
          >
            <option value={''}>Status</option>
            <option value='approved'>Approve</option>
            <option value='declined'>Decline</option>
            <option value='pending'>Pending</option>
            <option value={'cancelled'}>Cancelled</option>
            <option value={'issued'}>Issued</option>
            <option value={'blocked'}>Blocked</option>
          </FilterSelect>

          <Box
            maxWidth='319px'
            width='100%'
          >
            <SearchInput
              onChange={debouncedChangeHandler}
              placeholder='Search for user name or email'
            />
          </Box>
        </Flex>
      </Flex>

      {status === 'loading' && (
        <Box margin='8px 0'>
          <Loader />
        </Box>
      )}

      <ScrollRightContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Card Name</Th>
              <Th>Email</Th>
              <Th>Address</Th>
              <Th>Postal Code</Th>
              <Th>Zip Code</Th>

              <Th>Status</Th>
              <Th>Fee</Th>
              <Th>Date</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.map(
                ({ _id, addressData, cardDetails, chargeWallet, createdAt, fee, preOrder, status, userDetails }) => {
                  const userData = {
                    _id,
                    addressData,
                    cardDetails,
                    chargeWallet,
                    createdAt,
                    fee,
                    preOrder,
                    status,
                    userDetails,
                  };
                  return (
                    <Tr key={_id}>
                      <Td>{userDetails?.fullName}</Td>

                      <Td>
                        {cardDetails?.firstName} {cardDetails?.lastName} {cardDetails?.otherNames}
                      </Td>

                      <Td>{userDetails.email}</Td>

                      <Td>
                        {addressData?.streetNo}, {addressData?.streetAddress} {addressData.state}, {addressData.country}
                      </Td>

                      <Td>{addressData?.postalCode}</Td>

                      <Td>{addressData?.zipCode}</Td>

                      <Td textTransform='capitalize'>
                        <TableDataStatus status={status} />
                      </Td>

                      <Td textTransform={'uppercase'}>
                        {fee}&nbsp;
                        {chargeWallet}
                      </Td>

                      <Td textTransform='uppercase'>{formatDateToUTC(createdAt)}</Td>

                      <Td>
                        <Select
                          width='100px'
                          onChange={(e) => handleOpen(e.target.value, userData)}
                          border='1px solid green'
                        >
                          <option value='process'>Process</option>
                          <option value='approved'>Approve</option>
                          <option value='declined'>Decline</option>
                          <option value='blocked'>Blocked</option>
                          {status !== 'pending' && (
                            <>
                              <option value='cancelled'>Cancelled</option>
                              <option value='issued'>Issued</option>
                            </>
                          )}
                        </Select>
                      </Td>
                    </Tr>
                  );
                }
              )}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      <PhysicalCardRequestModal
        isOpen={isOpen}
        onClose={onClose}
        data={modalData}
        actionStatus={actionStatus}
        currentPage={currentPage}
        pageSize={pageSize}
      />

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={pageSize}
        offset={offset}
        pages={pages}
      />
    </Box>
  );
};

export default PhysicalCards;
