import { useMemo } from 'react';
import { Center, Box, VStack, Text } from '@chakra-ui/react';
import { Button, Input, FilterSelect } from 'components';

const StepOne = ({ handleChange, state, nextStep, country }) => {
  const isDisabled = useMemo(() => {
    const { dateOfBirth, docType, supportingDocType, nameOnId } = state;

    return dateOfBirth.length > 3 && (docType?.length > 3 || supportingDocType?.length > 3) && nameOnId.length > 3;
  }, [state]);

  const today = new Date();
  const minDate = new Date(today.getFullYear() - 17.5, today.getMonth(), today.getDate()).toISOString().split('T')[0];

  return (
    <VStack
      minW='100%'
      width='100%'
      alignItems='initial'
      spacing='12px'
    >
      <Box>
        <FilterSelect
          label='Category'
          id='category'
          minH='58px'
          htmlFor='category'
          name='category'
          onChange={handleChange}
          defaultValue={state.category}
        >
          <option>Category</option>
          <option value='primary'>Primary</option>
          <option value='secondary'>Secondary</option>
        </FilterSelect>
      </Box>

      <Box>
        <FilterSelect
          label='ID Type'
          id={state.category === 'primary' ? 'docType' : 'supportingDocType'}
          minH='58px'
          htmlFor={state.category === 'primary' ? 'docType' : 'supportingDocType'}
          name={state.category === 'primary' ? 'docType' : 'supportingDocType'}
          onChange={handleChange}
          defaultValue={state.docType}
        >
          {state.category === 'primary' && (
            <>
              <option value='driver_licence'>Select ID Type</option>
              {country === 'nigeria' && <option value='nimc_slip'>NIMC Slip</option>}
              <option value='kra_certificate'>KRA Certificate</option>
              <option value='national_id'>National ID</option>
              <option value='voters_card'>Voter's Card</option>
              <option value='driver_licence'>Driver's License</option>
              <option value='international_passport'>International Passport</option>
            </>
          )}

          {/* secondary */}
          {state.category === 'secondary' && (
            <>
              <option value='school_id_card'>Select ID Type</option>
              <option value='tax_card'>Tax Card</option>
              <option value='work_id'>Work ID</option>
              <option value='marriage_certificate'>Marriage Certificate</option>
              <option value='birth_certificate'>Birth Certificate</option>
              <option value='court_affidavit'>Court Affidavit</option>
              <option value='bank_statement'>Bank Statement</option>
            </>
          )}
        </FilterSelect>

        <Text
          as='span'
          fontSize='14px'
        >
          {state.docType === 'international_passport' || state.docType === 'driver_licence'
            ? 'Note: ID should be at least 6 months from expiration'
            : ''}
        </Text>
      </Box>

      <Box>
        <Input
          label='Name on ID'
          name='nameOnId'
          minH='58px'
          onChange={handleChange}
          defaultValue={state.nameOnId}
        />
      </Box>

      {state.docType === 'national_id' || state.docType === 'nimc_slip' ? (
        ''
      ) : (
        <Box>
          <Input
            label='ID Card Number'
            name='idNumber'
            onChange={handleChange}
            minH='58px'
            defaultValue={state.idNumber}
          />
        </Box>
      )}

      {country === 'nigeria' && (
        <Box>
          <Input
            label='NIN (National Identity Number)'
            name='ninNumber'
            minH='58px'
            onChange={handleChange}
            defaultValue={state.ninNumber}
          />
        </Box>
      )}

      <Box>
        <Input
          label='Date of birth'
          name='dateOfBirth'
          type='date'
          onChange={handleChange}
          minH='58px'
          defaultValue={state.dateOfBirth}
          max={minDate}
        />
      </Box>

      <Center
        width='100%'
        justify='flex-end'
        flexDir='column'
        mt='32px !important'
      >
        <Button
          minH='58px'
          width='320px'
          cursor='pointer'
          onClick={nextStep}
          disabled={!isDisabled}
        >
          Save and Continue
        </Button>
      </Center>
    </VStack>
  );
};

export default StepOne;
