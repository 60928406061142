export function useInitialValue(note) {
  if (checkParseJSON(note)) {
    return JSON.parse(note);
  } else {
    return [
      {
        type: 'paragraph',
        children: [{ text: note }],
      },
    ];
  }
}

const checkParseJSON = (value) => {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};
