import { useState } from 'react';
import { CSVDownload } from 'react-csv';
import { Box } from '@chakra-ui/react';
import { Button } from 'components';
import { post } from 'utils/axiosLib';
import { getHeaders } from 'utils';
import { BASE_URL } from 'constant';

const ExportRewardCSV = ({ claimedDates }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const headers = [
    { label: 'Full name', key: 'name' },
    { label: 'Description', key: 'rewards[0].description' },
    { label: 'Amount', key: 'rewards[0].reward.value' },
    { label: 'Coin', key: 'rewards[0].reward.unit' },
    { label: 'Transaction count', key: 'transactionCounts' },
  ];

  const handleExport = async () => {
    setLoading(true);

    try {
      const response = await post(
        `${BASE_URL}/admin/claimStats`,
        {
          exportAs: 'json',
          includeTransactions: true,
          claimedDates,
        },
        getHeaders()
      );

      if (response) {
        setLoading(false);
      }

      setData(response.data.data.stat);
    } catch (error) {
      setLoading(false);
      console.log(error, 'ERROR');
    }
  };

  return (
    <Box>
      {data.length !== 0 && (
        <CSVDownload
          filename="bitmama-claimed-rewards"
          data={data}
          headers={headers}
        />
      )}

      <Button
        minH="42px"
        onClick={handleExport}
        disabled={loading}
        isLoading={loading}
      >
        Export
      </Button>
    </Box>
  );
};

export default ExportRewardCSV;
