import { Flex, useDisclosure } from '@chakra-ui/react';
import { Button, DenyDoCumentModal } from 'components';
import ApproveKYCModal from 'components/Modals/ApproveKYCModal';
import useShortcutKeys from 'hooks/useShortCut';
import { getHeaders } from 'utils';

const ApproveDeclineVerification = ({ id, status }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const headers = getHeaders();

  // launch reject doc modal
  useShortcutKeys(["/", "x"], status === 'pending' ? onOpen : undefined)

  return (
    <>
      <Flex
        gridGap='16px'
        mt='71px'
      >
        {status === 'pending' && (
          <>
            <Button
              bg='#fff'
              width='182px'
              height='58px'
              color='brand.primary'
              border='2px solid #0D4740'
              onClick={onOpen}
            >
              Reject
            </Button>

            <ApproveKYCModal id={id} />
          </>
        )}
      </Flex>

      <DenyDoCumentModal
        isOpen={isOpen}
        onClose={onClose}
        headers={headers}
        id={id}
      />
    </>
  );
};

export default ApproveDeclineVerification;
