import { Select as ChakraSelect } from '@chakra-ui/react';

const InputSelect = ({ onChange, children, ...props }) => {
  return (
    <ChakraSelect
      _hover={{
        bg: 'rgba(226, 242, 218, 0.3)',
        border: '1px solid #6FBE45',
        transition: 'border 500ms ease-in-out',
      }}
      _active={{
        bg: '#FBFBFB',
        border: '1px solid #6FBE45',
      }}
      _focus={{
        bg: '#FBFBFB',
        border: '1px solid #6FBE45',
      }}
      bg='#F6F8F7'
      borderRadius='15px'
      borderWidth='0'
      minW='79px'
      fontSize='12px'
      lineHeight='16px'
      minH='56px'
      fontWeight='400'
      onChange={onChange}
      {...props}
    >
      {children}
    </ChakraSelect>
  );
};

export default InputSelect;
