import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, useToast, Center } from '@chakra-ui/react';
import { Button, CloseModal, Input } from 'components';
import { BASE_URL } from 'constant';
import { useAuth } from 'hooks';
import { constant } from 'utils';
import { post } from 'utils/axiosLib';

const TwoFAModal = ({ token, isOpen, onClose }) => {
  const { login } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [twoFaCode, setTwoFaCode] = useState(0);

  const handleChange = (e) => {
    setTwoFaCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);

    try {
      const URL = `${BASE_URL}/auth/2fa/verifyUser`;

      const response = await post(
        URL,
        {
          twoFaCode,
        },
        {
          authorization: token,
          'Content-Type': 'application/json',
        }
      );

      if (response.data) {
        login(response.data.data.token, null, true);

        setLoading(false);

        if (location.search === '') {
          navigate(constant.PAGES.HOME, {
            replace: true,
          });
        }

        if (location.search === '?redirectUrl=/') {
          window.location = constant.PAGES.HOME;
        }

        if (location.search.includes('?redirectUrl')) {
          const path = location.search.split('=');
          navigate(path[1], {
            replace: true,
          });
        }
      }
    } catch (error) {
      console.log(error, 'RESPONSE');
      setLoading(false);

      toast({
        position: 'bottom-right',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    twoFaCode.length === 6 && handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFaCode.length]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius='16px'
        maxWidth='420px'
      >
        <ModalHeader
          padding='32px 0'
          fontWeight='600'
          textAlign='center'
        >
          Enter 2FA Code
        </ModalHeader>

        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Input
              placeholder='Your 2FA pin'
              onChange={handleChange}
              type='number'
              required
            />

            <Center mt='32px'>
              <Button
                isLoading={loading}
                disabled={loading}
                type='submit'
              >
                Submit
              </Button>
            </Center>
          </form>

          <Center
            display='block'
            mb='-16px'
            mt='32px'
            as='a'
            href='https://dashboard.bitmama.io/2fagenerate?btm'
            target='_blank'
            rel='noopener noreferrer'
            color='brand.primary'
            textAlign='center'
          >
            First time? Set up your 2FA here
          </Center>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TwoFAModal;
