import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal, Input } from 'components';
import { BASE_URL } from 'constant';
import useShortcutKeys from 'hooks/useShortCut';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSingleDocument } from 'store/slices/user.slice';
import { getHeaders } from 'utils';
import { put } from 'utils/axiosLib';

const ApproveKYCModal = ({ id }) => {
  const toast = useToast();

  const [approveLoading, setApproveLoading] = useState(false);

  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const headers = getHeaders();
  const [validUntil, setValidUntil] = useState('');

  const handleOpen = () => {
    onOpen();
  };
  useShortcutKeys(['/', 'p'], handleOpen);

  const handleApproveDocument = async () => {
    const URL = `${BASE_URL}/admin/verify/document/${id}`;
    setApproveLoading(true);

    try {
      const response = await put(
        URL,
        {
          validUntil: new Date(validUntil).toISOString(),
        },
        headers
      );

      if (response.data) {
        setApproveLoading(false);

        setTimeout(() => {
          dispatch(fetchSingleDocument({ id }));
        }, [200]);

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error, 'THE ERROR');
      setApproveLoading(false);
      toast({
        position: 'bottom-right',
        title: 'error',
        description: typeof error?.customError === 'object' ? 'Something went wrong' : error.customError,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        width='182px'
        height='58px'
        onClick={onOpen}
      >
        Approve
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          bg='#fff'
          maxWidth='450px'
          padding='20px 40px 20px'
        >
          <Text
            fontSize='24px'
            textAlign='center'
            fontWeight='600'
          >
            Approve Document
          </Text>

          <ModalBody
            padding='0px'
            mt='20px'
          >
            <Box flexDir='column'>
              <Text>
                Are you sure you want to{' '}
                <Text
                  as='span'
                  fontWeight='600'
                >
                  approve
                </Text>{' '}
                &nbsp;this document
              </Text>

              <Box margin='24px 0 32px'>
                <Input
                  label='Valid Until'
                  type='date'
                  onChange={(e) => setValidUntil(e.target.value)}
                />
              </Box>

              <Center>
                <Button
                  onClick={handleApproveDocument}
                  isLoading={approveLoading}
                  disabled={approveLoading || validUntil.length <= 1}
                  minW='220px'
                >
                  Yes
                </Button>
              </Center>
            </Box>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ApproveKYCModal;
