import { convertToIso } from './convertToIso';

function getLastWeeksDate(noOfDays) {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - noOfDays);
}

export const lastWeek = convertToIso(getLastWeeksDate(7));
export const last30Days = convertToIso(getLastWeeksDate(30));
