import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  TableDataStatus,
} from 'components';
import { fetchAllLoans } from 'store/slices/bcpl.slice';
import { formatDateToUTC } from 'utils';

const BcplDefaultedTransactions = () => {
  const dispatch = useDispatch();

  const { data, status, totalCount } = useSelector((state) => state.bcpl.loans);

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    isDisabled,
    pageSize,
    offset,
  } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  useEffect(() => {
    dispatch(
      fetchAllLoans({
        limit: 10,
        page: currentPage,
        loanStatus: 'defaulted',
      })
    );
  }, [dispatch, currentPage]);

  return (
    <Box>
      <Box mt="16px">
        <>
          {status === 'loading' && (
            <Box margin="8px 0">
              <Loader />
            </Box>
          )}

          <ScrollRightContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Username</Th>
                  <Th>Amount</Th>
                  <Th>Email address</Th>
                  <Th>Coin Type</Th>
                  <Th>Coin Volume</Th>
                  <Th>Dollar rate</Th>
                  <Th>Date</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.map(
                    ({
                      _id,
                      userDetails,
                      coin,
                      coinValue,
                      currencyValue,
                      currency,
                      loanStatus,
                      createdAt,
                    }) => {
                      return (
                        <Tr key={_id}>
                          <Td color="brand.primary">{userDetails.username}</Td>

                          <Td textTransform={'uppercase'}>
                            {coinValue} {coin}
                          </Td>

                          <Td>{userDetails.email}</Td>

                          <Td textTransform={'uppercase'}>{coin}</Td>

                          <Td textTransform={'uppercase'}>
                            {currencyValue} {currency}
                          </Td>

                          <Td>1$/1 Celo</Td>

                          <Td>{formatDateToUTC(createdAt)}</Td>

                          <Td>
                            <TableDataStatus status={loanStatus} />
                          </Td>
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table>
          </ScrollRightContainer>
        </>
        <PaginationWrapper
          setCurrentPage={setCurrentPage}
          totalDataCount={totalCount}
          currentPage={currentPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          size={pageSize}
          offset={offset}
          pages={pages}
        />
      </Box>
    </Box>
  );
};

export default BcplDefaultedTransactions;
