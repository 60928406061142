import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import { CloseModal } from 'components';

const ListingModal = ({ isOpen, onClose, data, type }) => {
  const {
    _id,
    userData,
    currency,
    coin,
    price,
    paymentOption,
    tradeOption,
    terms,
    instructions,
    location,
    adsType,
    timeout,
    currentVolume,
  } = data;

  return (
    <Modal isOpen={isOpen} onClose={onClose} key={_id}>
      <ModalOverlay />

      <ModalContent
        borderRadius="16px"
        bg="#fff"
        maxWidth="520px"
        padding="40px 40px 20px"
      >
        <Text fontSize="24px" textAlign="center" fontWeight="600">
          Listing Details
        </Text>

        <ModalBody padding="0px" mt="20px">
          <Box>
            <Box fontSize="14px" justifyContent="space-between" mb="24px">
              <Flex justifyContent="space-between" mb="24px">
                <Text textTransform="capitalize">{type}</Text>

                <Text fontWeight="500">{userData?.username}</Text>
              </Flex>

              <Flex justifyContent="space-between" mb="24px">
                <Text>Price</Text>

                <Flex gridGap="4px" fontWeight="500">
                  <Text>{price ? Number(price).toLocaleString() : 'N/A'}</Text>

                  <Text as="span" textTransform="uppercase">
                    {currency}
                  </Text>
                </Flex>
              </Flex>

              <Flex justifyContent="space-between" mb="24px">
                <Text>Type</Text>

                <Text textTransform="capitalize" fontWeight="500">
                  {adsType}
                </Text>
              </Flex>
            </Box>

            <Flex mb="24px" justifyContent="space-between">
              <Text>Payment</Text>

              <Text fontWeight="500">
                {paymentOption?.map(({ label }, index) => (
                  <span key={index}>{label}</span>
                ))}
              </Text>
            </Flex>

            <Flex justifyContent="space-between" mb="24px">
              <Text>Available</Text>

              <Text fontWeight="500" textTransform="uppercase">
                {currentVolume?.$numberDecimal} {coin}
              </Text>
            </Flex>

            <Flex justifyContent="space-between" mb="24px">
              <Text>Time limit</Text>

              <Text fontWeight="500">{timeout} min</Text>
            </Flex>

            <Flex justifyContent="space-between" mb="24px">
              <Text>Buy range</Text>

              <Text fontWeight="500">
                {tradeOption?.minimumFilling} - {tradeOption?.maximumFilling}
              </Text>
            </Flex>

            <Flex justifyContent="space-between" mb="24px">
              <Text>Location</Text>

              <Text fontWeight="500">{location ? location : 'N/A'}</Text>
            </Flex>
          </Box>

          <Flex justifyContent="space-between" mb="24px">
            <Text>Terms</Text>

            <Text fontWeight="500">{terms ? terms : 'N/A'}</Text>
          </Flex>

          <Flex justifyContent="space-between">
            <Text>Instruction</Text>

            <Text fontWeight="500">{instructions ? instructions : 'N/A'}</Text>
          </Flex>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ListingModal;
