import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Button } from 'components';

const ZipQrCode = ({ urls, disabled }) => {
  var zip = new JSZip();
  var count = 0;
  var zipFilename = 'qrCode.zip';

  var donwloadAll = () =>
    urls.forEach(function (url, index) {
      var filename = 'qrcode-' + index;
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url.dataUri, async function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count === urls.length) {
          var zipFile = await zip.generateAsync({ type: 'blob' });
          saveAs(zipFile, zipFilename);
        }
      });
    });
  return (
    <Button
      minH="60px"
      minW="370px"
      type="submit"
      bg="#6FBE45"
      onClick={donwloadAll}
      disabled={disabled}
    >
      Export the Qr Codes
    </Button>
  );
};

export default ZipQrCode;
