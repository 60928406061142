import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from 'constant';
import { constant, ls, wrapBaseApi } from 'utils';

export const userApi = createApi({
  reducerPath: 'user-details',
  refetchOnMountOrArgChange: true,
  tagTypes: ['user-details'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: (headers, _) => {
      const auth = ls.get(constant.AUTH_TOKEN_KEY);

      headers.set('Authorization', auth.token);
      headers.set('Content-Type', 'application/json');
      // headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (id) => ({
        url: wrapBaseApi(`/admin/user/${id}`),
      }),
      transformResponse: (res) => res?.data?.users,
    }),

    getUserTransactions: builder.query({
      query: ({ userId }) => ({
        url: wrapBaseApi(`${BASE_URL}/wallet/api/v2/admin/transactions/user/${userId}?limit=${3}&page=${1}`),
      }),
      transformResponse: (res) => res?.data,
    }),
  }),
});

export const { useGetUserQuery, useGetUserTransactionsQuery } = userApi;

export default userApi;
