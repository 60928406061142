import {
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal } from 'components';
import { BASE_URL } from 'constant';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCardDetails, fetchSingleCard } from 'store/slices/card.slice';

import { getHeaders, wrapBaseApi } from 'utils';
import { put } from 'utils/axiosLib';

const FreezeCardModal = ({ id, status, statusTag, btnWidth, btnHeight, userId }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const headers = getHeaders();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [freezeState, setFreezeState] = useState('freeze');

  const handleFreeze = async () => {
    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/${freezeState}-card/${id}`);
    setLoading(true);

    try {
      const response = await put(URL, {}, headers);

      if (response.data) {
        setLoading(false);

        dispatch(fetchCardDetails({ id }));

        userId &&
          dispatch(
            fetchSingleCard({
              userId,
              page: 1,
            })
          );

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response?.data?.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        onClose();
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'error',
        description: error?.data?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Flex gap='16px'>
        {status === 'active' && statusTag !== 'was-suspended' && (
          <Button
            width={btnWidth ? btnWidth : '182px'}
            height={btnHeight ? btnHeight : 'initial'}
            minW='initial'
            minH={btnHeight ? btnHeight : '48px'}
            fontWeight='500'
            fontSize='14px'
            onClick={() => {
              setFreezeState('freeze');
              onOpen();
            }}
          >
            Freeze
          </Button>
        )}

        {(status === 'frozen' || status === 'inactive') && status !== 'deleted' && statusTag !== 'was-suspended' && (
          <Button
            width={btnWidth ? btnWidth : '182px'}
            height={btnHeight ? btnHeight : 'initial'}
            minW='initial'
            minH={btnHeight ? btnHeight : '48px'}
            bg='#fff'
            border='1px solid #0D4740'
            fontWeight='500'
            fontSize='14px'
            color='#0D4740'
            onClick={() => {
              setFreezeState('unfreeze');
              onOpen();
            }}
          >
            Unfreeze
          </Button>
        )}
      </Flex>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          bg='#fff'
          maxWidth='450px'
          padding={{ base: '20px', md: '40px' }}
        >
          <Text
            fontSize='24px'
            textAlign='center'
            fontWeight='600'
            textTransform='capitalize'
          >
            {freezeState} Card
          </Text>

          <Text textAlign='center'>
            Are you sure you want to{' '}
            <Text
              as='span'
              fontWeight='600'
            >
              {freezeState}
            </Text>{' '}
            &nbsp;this card
          </Text>

          <ModalBody
            padding='0px'
            mt='48px'
          >
            <Center>
              <Button
                onClick={handleFreeze}
                isLoading={loading}
                disabled={loading}
                minW='220px'
              >
                Yes
              </Button>
            </Center>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FreezeCardModal;
