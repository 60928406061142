import { Box, Flex } from '@chakra-ui/react';
import { Button, PageTitle, WishlistCampaign, WishlistStat } from 'components';
import { Link } from 'react-router-dom';

const Wishlist = () => {
  return (
    <Box>
      <Flex
        justifyContent='space-between'
        alignItems='center'
      >
        <PageTitle title='Wishlist' />

        <Link to='/dashboard/wishlist/create'>
          <Button
            minH='41px'
            fontSize='14px'
          >
            Create Wishlist Items
          </Button>
        </Link>
      </Flex>

      <Box
        mt='27px'
        bg='#FCFDFD'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
        borderRadius='10px'
        minHeight='700px'
        padding={{ base: '15px', md: '40px' }}
      >
        <WishlistStat />

        <WishlistCampaign />
      </Box>
    </Box>
  );
};

export default Wishlist;
