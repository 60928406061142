import { useEffect, useState } from 'react';
import { Box, Center, useToast, Text } from '@chakra-ui/react';
import { Button, FileUpload } from 'components';
import { getHeaders, uploadFile } from 'utils';
import { post } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const StepThree = ({ setState, state, email, kycLevel, onClose, userData, bankCode, userId }) => {
  const { bankAccountNumber } = state;
  const toast = useToast();
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function upload() {
      if (selectedFile) {
        setLoading(true);
        const response = await uploadFile(selectedFile, selectedFile?.name);

        if (response.location) {
          setLoading(false);

          setState({
            ...state,
            bankStatementLink: response.location,
          });
        }
      }
    }
    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, setState]);

  const handleSubmit = async () => {
    setLoading(true);

    const headers = getHeaders();

    try {
      const bankDetails = await post(
        `${BASE_URL}/admin/addBankDetails`,
        {
          force: false,
          bankCode,
          bankAccountNumber,
          country: 'ng',
          userPayload: {
            firstName: userData.firstName,
            email,
            userId: userData._id,
            lastName: userData.lastName,
          },
        },
        headers
      );

      const response =
        bankDetails.data &&
        (await post(
          `${BASE_URL}/admin/uploadKYCForUser`,
          {
            userId,
            email,
            kycLevel,
            formData: {
              ...state,
              bankId: bankDetails.data.data.newBankDetail._id,
            },
          },
          headers
        ));

      if (response.data) {
        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 18000,
          isClosable: true,
        });

        onClose();
      }
    } catch (error) {
      console.log(error, 'ERROR');
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: typeof error?.customError === 'string' ? error.customError : '',
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Text mb='16px'>Upload utility bill (electricity, waste or water) or a bank statment as proof of residence</Text>

      <FileUpload
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        defaultPreview={state.bankStatementLink}
      />

      <Center
        width='100%'
        justify='flex-end'
        flexDir='column'
        mt='32px'
      >
        <Button
          minH='60px'
          width='320px'
          cursor='pointer'
          type='submit'
          onClick={handleSubmit}
          disabled={!state.bankStatementLink}
          isLoading={loading}
        >
          Submit
        </Button>
      </Center>
    </Box>
  );
};

export default StepThree;
