import {
  Box,
  Center,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal, Input } from 'components';
import { BASE_URL } from 'constant';
import { useState } from 'react';
import { getHeaders } from 'utils';
import { post } from 'utils/axiosLib';

const ProcessAllWithdrawalModal = ({ localStorageData, onProcessAll }) => {
  const filteredData = localStorageData?.filter(
    ({ status }) => status?.toLowerCase() !== 'processed'
  );

  const recipients = filteredData?.map(
    ({ address, amount, email, username, network }) => ({
      walletAddress: address?.toLowerCase() === 'n/a' ? undefined : address,
      volume: amount,
      email: email?.toLowerCase() === 'n/a' ? undefined : email,
      username: username?.toLowerCase() === 'n/a' ? undefined : username,
      network: network?.toLowerCase() === 'n/a' ? undefined : network,
    })
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const toast = useToast();

  const [allOrNone, setAllOrNone] = useState(true);
  const [externalAddress, setExternalAddress] = useState(true);

  const handleAllOrNone = () => {
    setAllOrNone(!allOrNone);
  };

  const handleExternalAddress = () => {
    setExternalAddress(!externalAddress);
  };

  const [state, setState] = useState({
    pin: '',
    description: '',
    unit: '',
    senderAlias: '',
  });

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const URL = `${BASE_URL}/wallet/admin/transfer/batch`;

    try {
      const response = await post(
        URL,
        {
          unit: state.unit,
          senderAlias: state.senderAlias,
          exposeDetails: true,
          pin: state.pin,
          allOrNone,
          description: state.description,
          includesExternalAddress: externalAddress,
          recipients,
        },
        getHeaders()
      );

      if (response.data) {
        setLoading(false);

        onProcessAll(response.data.data.recipients);
        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: `${response.data.message}, ${response.data.data.summary}`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: String(error?.error || error),
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        bg="rgba(49, 183, 169, 0.2)"
        fontWeight="500"
        color="brand.black"
        fontSize="12px"
        minH="38px"
        borderRadius="5px"
        onClick={onOpen}
      >
        Process All Withdrawals
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pt="24px" pb="24px" borderRadius="21px" maxWidth="430px">
          <ModalHeader fontSize="24px" textAlign="center">
            Process All Withdrawals
          </ModalHeader>

          <ModalCloseButton _focus={{ border: 'none' }} />

          <ModalBody pb="2rem">
            <form onSubmit={handleSubmit}>
              <VStack gridGap="8px" m="20px 0 40px">
                <Flex gridGap="8px">
                  <Checkbox
                    onChange={handleAllOrNone}
                    value={allOrNone}
                    defaultChecked={allOrNone}
                    colorScheme="green"
                  />

                  <Text fontSize="14px" lineHeight="18px">
                    If all the transactions cannot be processed, none will be
                    processed
                  </Text>
                </Flex>

                <Flex gridGap="8px">
                  <Checkbox
                    onChange={handleExternalAddress}
                    name="includesExternalAddress"
                    value={externalAddress}
                    defaultChecked={externalAddress}
                    colorScheme="green"
                  />

                  <Text fontSize="14px" lineHeight="18px">
                    Allow transfer to external address, and take fee from the
                    specified amount
                  </Text>
                </Flex>

                <Box width="100%">
                  <Text display="block" mb="0.5rem" >
                  </Text>

                  <Input
                    label="Customize Sender (Optional)"
                    name="senderAlias"
                    width="100%"
                    htmlFor="senderAlias"
                    placeholder="e.g. Bitmama Inc"
                    type="text"
                    onChange={handleChange}
                    isRequired={false}
                  />
                </Box>
                <Box width="100%">
                  <Text display="block" mb="0.5rem" >
                  </Text>

                  <Input
                    label="Transfer Unit"
                    name="unit"
                    width="100%"
                    htmlFor="unit"
                    placeholder="e.g. NGN or USDT"
                    type="text"
                    onChange={handleChange}
                    isRequired
                  />
                </Box>

                <Box width="100%">
                  <Text
                    display="block"
                    mb="0.5rem"
                    htmlFor="description"
                    as="label"
                  >
                    Description
                  </Text>

                  <Textarea
                    placeholder="Description"
                    onChange={handleChange}
                    name="description"
                    id="description"
                    width="100%"
                    height="80px"
                    padding="13px 22px"
                    _focus={{
                      borderColor: '#06C3448f',
                    }}
                  />
                </Box>

                <Input
                  label="Pin"
                  name="pin"
                  width="100%"
                  htmlFor="pin"
                  type="password"
                  onChange={handleChange}
                  isRequired
                />
              </VStack>

              <Center>
                <Button
                  type="submit"
                  minH="52px"
                  minW="365px"
                  // disabled={loading}
                  disabled={
                    loading ||
                    !(state.pin.length === 6) ||
                    !(state.description.length > 2)
                  }
                  isLoading={loading}
                >
                  Submit
                </Button>
              </Center>
            </form>
          </ModalBody>

          <CloseModal onClick={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProcessAllWithdrawalModal;
