import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  Loader,
  PaginationWrapper,
  HistoryTab,
  TableBoxWrapper,
  ScrollRightContainer,
  SearchInput,
} from 'components';
import { Link } from 'react-router-dom';
import { fetchP2PContractsDispute } from 'store/slices/p2p.slice';
import { formatDateToUTC } from 'utils';
import { useDebounce } from 'hooks';

const P2PAllDispute = () => {
  const dispatch = useDispatch();
  const {
    contractsDispute: { data, status },
  } = useSelector((state) => state.p2p);
  const [queryValue, setQueryValue] = useState('');

  console.log(data, 'daaaataaa');

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: {
        currentPage: 1,
        pageSize: 10,
      },
      total: data.totalCount,
      limits: {
        inner: 2,
        outer: 1,
      },
    });

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setQueryValue(query);
      setCurrentPage(1);
    } else {
      setQueryValue('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  useEffect(() => {
    dispatch(
      fetchP2PContractsDispute({
        limit: 10,
        page: currentPage,
        search: queryValue,
      })
    );
  }, [dispatch, currentPage, queryValue]);

  return (
    <Box>
      <HistoryTab
        firstText="P2P"
        secondText="All Disputes"
        url="/dashboard/p2p"
      />

      <TableBoxWrapper>
        <Box mb="24px">
          <SearchInput
            placeholder="Search username"
            minH="48px"
            width="319px"
            onChange={debouncedChangeHandler}
          />
        </Box>

        <Box>
          {status === 'loading' && <Loader />}

          {status === 'fulfilled' && (
            <>
              <ScrollRightContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Trade Ref. No.</Th>

                      <Th>Remark</Th>
                      <Th>Parties</Th>
                      <Th>Amount</Th>
                      <Th>Unit Cost</Th>
                      <Th>Trade Type</Th>
                      <Th>Created At</Th>
                      <Th>Timeline</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {data &&
                      data?.trades.map(
                        ({
                          _id,
                          tradeRef,
                          tradeType,
                          createdAt,
                          counterParties,
                          tradeRate,
                          currency,
                          remark,
                        }) => {
                          return (
                            <Tr key={_id}>
                              <Td color="brand.primary">
                                <Link to={`/dashboard/p2p/dispute/${tradeRef}`}>
                                  {tradeRef}
                                </Link>
                              </Td>

                              <Td>{remark}</Td>

                              <Td>
                                <Text fontWeight="medium">
                                  <a
                                    href={`/dashboard/users/${counterParties[0].user.userId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Text as="span">
                                      {counterParties[0].perspective}
                                    </Text>
                                    : {counterParties[0].user.username}
                                  </a>
                                </Text>

                                <Text fontWeight="medium">
                                  <a
                                    href={`/dashboard/users/${counterParties[1].user.userId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Text as="span">
                                      {counterParties[1].perspective}
                                    </Text>
                                    : {counterParties[1].user.username}
                                  </a>
                                </Text>
                              </Td>

                              <Td>
                                <Text>
                                  To Pay:{' '}
                                  <Text as="span" textTransform="uppercase">
                                    {counterParties[0].unitToPay}
                                  </Text>{' '}
                                  {Number(
                                    counterParties[0].amountToPay
                                  ).toLocaleString()}
                                </Text>

                                <Text>
                                  To Receive:{' '}
                                  {Number(
                                    counterParties[1].amountToPay
                                  ).toLocaleString()}
                                  <Text as="span" textTransform="uppercase">
                                    {' '}
                                    {counterParties[0].unitToReceive}
                                  </Text>
                                </Text>
                              </Td>

                              <Td>
                                <Text textTransform="uppercase" as="span">
                                  {currency}
                                </Text>
                                {Number(tradeRate.unitCost).toLocaleString()}
                              </Td>
                              <Td>{tradeType}</Td>

                              <Td>{formatDateToUTC(createdAt)}</Td>

                              <Td>
                                <Text>
                                  Due At:{' '}
                                  {formatDateToUTC(
                                    counterParties[0].timeline.dueAt
                                  )}
                                </Text>

                                <Text>
                                  Duration:{' '}
                                  {counterParties[0].timeline.duration}{' '}
                                  {counterParties[0].timeline.durationUnit}
                                </Text>
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </Table>
              </ScrollRightContainer>

              <PaginationWrapper
                setCurrentPage={setCurrentPage}
                totalDataCount={data.totalCount}
                currentPage={currentPage}
                isDisabled={isDisabled}
                pagesCount={pagesCount}
                offset={offset}
                pages={pages}
                size={10}
              />
            </>
          )}
        </Box>
      </TableBoxWrapper>
    </Box>
  );
};

export default P2PAllDispute;
