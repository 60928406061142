import { HStack, Text } from '@chakra-ui/react';
import {
  Pagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator,
} from '@ajna/pagination';
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { clamp } from 'utils';

const PaginationWrapper = ({
  setCurrentPage,
  totalDataCount,
  currentPage,
  isDisabled,
  pagesCount,
  offset,
  pages,
  size,
  scrollTop,
  setPage,
}) => {
  const handlePageChange = (nextPage) => {
    setTimeout(() => {
      window.scrollTo({ top: scrollTop ? scrollTop : 10, behavior: 'smooth' });
    }, 500);

    setCurrentPage(nextPage);
    setPage && setPage(nextPage);
  };

  return (
    <HStack
      bg='transparent'
      mt='45px'
      pl={{ base: '0', tb: '1.75rem' }}
      justifyContent='space-between'
      borderRadius='10px'
      flexDir={{ sm: 'column', tb: 'row' }}
    >
      <Text
        color='#0D4740'
        fontSize='14px'
        fontWeight='500'
        lineHeight='22px'
        letterSpacing='-0.5px'
        opacity='0.8'
      >
        Showing {offset + 1} to {clamp(offset + Number(size), Number(size), totalDataCount)} results of {totalDataCount}
      </Text>
      <HStack>
        <Pagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          isDisabled={isDisabled}
          onPageChange={handlePageChange}
        >
          <PaginationContainer
            align='center'
            justify='space-between'
            p={4}
            w='full'
          >
            <PaginationPrevious
              mr={{ sm: '4px', tb: '8px' }}
              boxSize={{ sm: '100%', tb: '32px' }}
              _active={{ bg: 'transparent' }}
              _hover={{
                bg: 'transparent',
              }}
              bg='transparent'
            >
              <ChevronLeftIcon />
            </PaginationPrevious>

            <PaginationPageGroup
              isInline
              align='center'
              separator={
                <PaginationSeparator
                  border='1px solid #0D4740'
                  bg='transparent'
                  fontSize='12px'
                  boxSize='32px'
                  borderRadius='10px'
                  jumpSize={4}
                  _active={{ bg: 'transparent' }}
                  _hover={{
                    bg: 'transparent',
                  }}
                  _focus={{
                    borderColor: 'transparent',
                  }}
                />
              }
            >
              {pages.map((page) => (
                <PaginationPage
                  boxSize='32px'
                  bg='transparent'
                  border='1px solid #0D4740'
                  key={`pagination_page_${page}`}
                  page={page}
                  fontSize='12px'
                  fontWeight='500'
                  borderRadius='10px'
                  _active={{ bg: 'transparent' }}
                  _hover={{
                    bg: 'transparent',
                  }}
                  _focus={{
                    borderColor: 'transparent',
                  }}
                  _current={{
                    borderColor: 'transparent',
                    bg: '#0D4740',
                    fontSize: '12px',
                    boxSize: '32px',
                    fontWeight: '600',
                    color: '#fff',
                    borderRadius: '10px',

                    _hover: {
                      borderColor: '#0D4740',
                      bg: 'transparent',
                      color: '#303131',
                    },
                  }}
                />
              ))}
            </PaginationPageGroup>

            <PaginationNext
              ml={{ sm: '4px', tb: '8px' }}
              boxSize={{ sm: '100%', tb: '32px' }}
              _active={{ bg: 'transparent' }}
              _hover={{
                bg: 'transparent',
              }}
              bg='transparent'
            >
              <ChevronRightIcon />
            </PaginationNext>
          </PaginationContainer>
        </Pagination>
      </HStack>
    </HStack>
  );
};

export default PaginationWrapper;
