import { forwardRef, useState } from 'react';
import { Text, Box, Flex, Avatar, Tag, VStack, Accordion } from '@chakra-ui/react';
import { useGetUserQuery, useGetUserTransactionsQuery } from 'store/services/user.service';
import { Loader } from 'components';
import { useGetUserNotesQuery } from 'store/services/notes.service';
import { SiOpslevel } from 'react-icons/si';
import { MdOutlineLocationOn } from 'react-icons/md';
import { FaFemale, FaMale } from 'react-icons/fa';
import { FcCalendar } from 'react-icons/fc';
import { formatDay } from 'utils/formatDateToUTC';
import NoteTooltipData from './NoteTooltipData';
import TransactionTooltipData from './TransactionTooltipData';
import { Link } from 'react-router-dom';

export const CustomCard = forwardRef(({ fullName, userId, index }, ref) => {
  const { data, isLoading } = useGetUserQuery(userId, {
    skip: userId ? false : true,
  });

  const { data: notesData } = useGetUserNotesQuery(userId, {
    skip: userId ? false : true,
  });

  const { data: userTransactions } = useGetUserTransactionsQuery(
    { userId },
    {
      skip: userId ? false : true,
    }
  );

  return (
    <Box
      borderRadius='8px'
      ref={ref}
      py='12px'
      bg='#f9faf9'
      minH='140px'
    >
      {isLoading && (
        <Box px='12px'>
          <Loader size='12px' />
        </Box>
      )}

      {data && (
        <Box>
          <Flex
            px='12px'
            gap='12px'
            py='12px'
          >
            <Avatar
              size='xl'
              name={fullName}
              border='2px solid #EDEBEB'
            />

            <VStack
              alignItems='revert-layer'
              spacing='8px'
            >
              <Text
                textTransform='capitalize'
                fontWeight='500'
                fontSize='16px'
                textAlign='left'
                color='black'
              >
                {fullName}
              </Text>

              <Box fontSize='14px'>
                <Link to={`/dashboard/users/${userId}`}>
                  <Tag
                    bg='#7e7e7e'
                    color='#fff'
                    fontWeight='500'
                    textTransform='lowercase'
                  >
                    {data?.username}
                  </Tag>
                </Link>
              </Box>

              <Flex
                alignItems='center'
                gap='8px'
              >
                <SiOpslevel
                  color='#131010'
                  size='18px'
                />

                <Text>Kyc Level {data?.kycLevel ? data.kycLevel : 'N/A'}</Text>
              </Flex>

              <Flex gap='12px'>
                <Flex
                  alignItems='center'
                  gap='0px'
                >
                  {data?.gender === 'male' ? (
                    <FaMale
                      color='#131010'
                      size='18px'
                    />
                  ) : (
                    <FaFemale
                      color='#131010'
                      size='18px'
                    />
                  )}
                  <Text>{data?.gender}</Text>
                </Flex>

                <Flex
                  alignItems='center'
                  gap='4px'
                >
                  <MdOutlineLocationOn
                    color='#131010'
                    size='18px'
                  />

                  <Text>{data?.country}</Text>
                </Flex>
              </Flex>

              <Flex
                alignItems='center'
                gap='8px'
              >
                <FcCalendar size='18px' />

                <Text>Joined: {formatDay(data?.createdAt)}</Text>
              </Flex>
            </VStack>
          </Flex>

          <Box
            borderBottom={notesData?.totalCount >= 1 ? '1.5px solid #EDEBEB' : 'none'}
            fontSize='12px'
          />
        </Box>
      )}

      <Accordion
        allowMultiple={false}
        filter='drop-shadow(0px 4px 24px rgba(67, 55, 55, 0.2))'
        mb='12px'
        padding='4px 0px'
        width='100%'
        allowToggle
      >
        {notesData && notesData?.totalCount >= 1 && (
          <NoteTooltipData
            notesData={notesData}
            userId={userId}
          />
        )}

        {userTransactions && userTransactions?.totalCount >= 1 && (
          <TransactionTooltipData
            data={userTransactions}
            userId={userId}
          />
        )}
      </Accordion>
    </Box>
  );
});

export const CustomTooltip = ({ content, index, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      style={{ position: 'relative' }}
    >
      {children}

      {isOpen && (
        <Box
          position='absolute'
          bg='#EDEBEB'
          p='8px'
          bottom={index > 4 ? '1%' : 'initial'}
          top={index < 4 ? '1%' : 'initial'}
          transform={index <= 4 ? 'translate3d(105px, 5px, 0px)' : 'translate3d(110px, -5px, 0px)'}
          opacity='1'
          minW='430px'
          height='auto'
          minH='140px'
          color='#121212'
          borderRadius='8px'
          boxShadow='0px 2px 8px rgba(229, 229, 229, 0.5)'
          zIndex={999999}
        >
          {content}
        </Box>
      )}
    </Box>
  );
};

// const SpanText = ({ title, description }) => {
//   return (
//     <Text>
//       <Text
//         as='span'
//         fontWeight='500'
//       >
//         {title}
//       </Text>
//       : {String(description)}
//     </Text>
//   );
// };
