import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import isHotkey from 'is-hotkey';
import { Editable, Slate } from 'slate-react';
import { Leaf, Element } from 'components';
import { BlockButton, HOTKEYS, MarkButton, Toolbar, toggleMark } from './SlateComponents';

const RichEditor = ({ setContent, children, editor }) => {
  const initialValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];

  const renderElement = useCallback(
    (props) => (
      <Element
        isDelete
        {...props}
      />
    ),
    []
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <Box pos='relative'>
      <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={(value) => {
          const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
          if (isAstChange) {
            const content = JSON.stringify(value);
            setContent(content);
          }
        }}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          color='green'
          style={{
            padding: '8px 52px 8px 16px',
            width: '100%',
            borderRadius: '8px',
            backgroundColor: '#F7F7FC',
            marginBottom: '8px',
          }}
          className='editable'
          spellCheck
          autoFocus
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />

        <Toolbar>
          <MarkButton
            format='bold'
            icon='format_bold'
          />
          <MarkButton
            format='italic'
            icon='format_italic'
          />
          <MarkButton
            format='underline'
            icon='format_underlined'
          />
          <MarkButton
            format='code'
            icon='code'
          />
          <BlockButton
            format='heading-one'
            icon='looks_one'
          />
          <BlockButton
            format='heading-two'
            icon='looks_two'
          />
          <BlockButton
            format='block-quote'
            icon='format_quote'
          />
          <BlockButton
            format='numbered-list'
            icon='format_list_numbered'
          />
          <BlockButton
            format='bulleted-list'
            icon='format_list_bulleted'
          />
          <BlockButton
            format='left'
            icon='format_align_left'
          />
          <BlockButton
            format='center'
            icon='format_align_center'
          />
          <BlockButton
            format='right'
            icon='format_align_right'
          />
          <BlockButton
            format='justify'
            icon='format_align_justify'
          />
        </Toolbar>
      </Slate>

      {children}
    </Box>
  );
};

export default RichEditor;
