import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import {
  ApproveDeclineVerification,
  BoxInput,
  Button,
  FilterSelect,
  FlagKYC,
  HistoryTab,
  KycLevelThree,
  KycLevelTwo,
  Loader,
  ResolveBVN,
  ReviewKYC,
} from 'components';
import { useSetUserStorage } from 'hooks';
import useLocalStorageState from 'hooks/useLocalStorageState';
import useShortcutKeys from 'hooks/useShortCut';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateKycMutation } from 'store/services/request.service';
import { fetchSingleDocument, fetchUserDocuments } from 'store/slices/user.slice';
import { formatDateToUTC } from 'utils';

const VerificationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sortBy] = useLocalStorageState('VERIF_DOC_LIST_SORT_BY', 'createdAt:asc');
  const [otherQuery] = useLocalStorageState(`VERIF_DOC_LIST_OTHER_QUERY`, '');
  const [searchString] = useLocalStorageState(`VERIF_DOC_LIST_QUERY`, '');
  const [tab] = useLocalStorageState(`VERIF_DOC_LIST_TAB`, 'pending');

  const [index, setIndex] = useState(1);
  const dispatch = useDispatch();
  const {
    data: { document },
    status,
  } = useSelector((state) => state.allUsers.singleDocument);

  const { status: allStatusDocuments } = useSelector((state) => state.allUsers.document);

  const { documents, totalCount } = useSelector((state) => state.allUsers.documentsID.data);

  useEffect(() => {
    dispatch(fetchSingleDocument({ id }));

    dispatch(
      fetchUserDocuments({
        status: tab,
        limit: 20,
        sortBy,
        search: searchString,
        otherQuery,
      })
    );
  }, [dispatch, id, otherQuery, searchString, sortBy, tab]);

  const handleVerificationID = () => {
    setIndex(index + 1);

    if (index !== totalCount) {
      navigate(`/dashboard/verification/${documents[index]._id}`);
    }
  };

  const nextShortCut = useShortcutKeys(['/', 'ArrowRight', 'ArrowRight'], undefined);
  useShortcutKeys(['/', 'Backspace'], () => window.history.back());

  useEffect(() => {
    nextShortCut.setShortcutCallback(() => {
      if (documents[index]) handleVerificationID();
      else {
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, documents, nextShortCut]);

  const toast = useToast();
  const [_updateKYC, { isLoading }] = useUpdateKycMutation();

  const handleCategory = async (e) => {
    const response = await _updateKYC({
      category: e.target.value,
      documentId: id,
    });

    if (response?.data) {
      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response?.data?.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }

    if (response?.error) {
      toast({
        position: 'bottom-right',
        title: 'Error',
        description: response?.error?.data?.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useSetUserStorage(id, status === 'fulfilled' && document?.userDetail?.fullName);

  return (
    <>
      <Flex
        alignItems='center'
        gridGap='10px'
        justifyContent='space-between'
      >
        <HistoryTab
          firstText='Account verification'
          secondText='Request'
          url='/dashboard/verification?pending'
        />

        {documents.length !== 0 && (
          <Button
            disabled={index === totalCount || allStatusDocuments === 'loading'}
            onClick={handleVerificationID}
            minW={{ base: '120px', md: '200px' }}
            minH='54px'
            fontSize={{ base: '14px', md: '16px' }}
          >
            Next Request
          </Button>
        )}
      </Flex>

      <Box
        bg='#fff'
        padding={{ base: '24px 16px', md: '44px 32px' }}
        border='1px solid #EDEBF9'
        borderRadius='8px'
      >
        {status === 'loading' && <Loader />}

        {status === 'fulfilled' && (
          <Flex
            gridGap='0'
            flexDir='column'
          >
            <Box>
              {(document?.kycLevel === 'two' || document?.kycLevel === 'one' || document?.kycLevel === 'two_three') && (
                <KycLevelTwo
                  document={document}
                  id={id}
                />
              )}

              {document?.kycLevel === 'three' && <KycLevelThree document={document} />}

              {document.country && (
                <Box maxWidth='374px'>
                  <BoxInput
                    label='Country'
                    value={document.country}
                  />
                </Box>
              )}

              <Box
                mt={{ base: '24px', md: '0' }}
                maxWidth='374px'
              >
                {document.status === 'pending' && (
                  <>
                    <BoxInput
                      value={
                        <>
                          <FilterSelect
                            onChange={handleCategory}
                            defaultValue={document?.category}
                          >
                            <option value='primary'>Primary</option>
                            <option value='secondary'>Secondary</option>
                          </FilterSelect>
                        </>
                      }
                      textTransform='capitalize'
                      label='Category'
                      height='72px'
                    >
                      <Flex
                        gridGap='12px'
                        alignItems='center'
                      >
                        {isLoading && <Loader />}
                      </Flex>
                    </BoxInput>
                  </>
                )}

                {document.status === 'approved' && (
                  <>
                    <BoxInput
                      label='Valid Until'
                      value={document?.validUntil ? formatDateToUTC(document?.validUntil) : 'Not Set'}
                    />

                    <Text
                      fontWeight='600'
                      margin='26px 0 10px'
                    >
                      Verified By:
                    </Text>

                    <BoxInput
                      label='Email address'
                      value={document?.verifiedBy?.email}
                    />

                    <BoxInput
                      label='Email address'
                      value={document?.verifiedBy?.fullName}
                    />
                  </>
                )}

                {document.status === 'expired' && (
                  <>
                    <BoxInput
                      label='Valid Until'
                      value={formatDateToUTC(document?.validUntil)}
                    />

                    <Text
                      fontWeight='600'
                      margin='26px 0 10px'
                    >
                      Review By:
                    </Text>

                    <BoxInput
                      label='Email address'
                      value={document?.reviewedBy?.email}
                    />

                    <BoxInput
                      label='Email address'
                      value={document?.reviewedBy?.fullName}
                    />
                  </>
                )}

                {document?.bvn && <ResolveBVN bvn={document.bvn} />}
              </Box>
            </Box>
          </Flex>
        )}

        <Box>
          {status === 'fulfilled' && (
            <>
              {document.status === 'approved' && (
                <Flex gridGap='1rem'>
                  <ReviewKYC id={id} />

                  <FlagKYC
                    id={id}
                    document={document}
                  />
                </Flex>
              )}

              {
                <ApproveDeclineVerification
                  status={document?.status}
                  id={document?._id}
                />
              }
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default VerificationDetails;
