import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'constant';
import { getHeaders, wrapBaseApi } from 'utils';
import { get } from 'utils/axiosLib';

const initialState = {
  staking: {
    status: 'idle',
    data: [],
    totalCount: 0,
    errorMessage: '',
  },

  stats: {
    status: 'idle',
    data: {
      atStake: {
        usdt: 0,
        btc: 0,
        eth: 0,
        teth: 0,
        tbtc: 0,
      },
      redeemed: {
        usdt: 0,
        btc: 0,
        eth: 0,
        teth: 0,
        tbtc: 0,
      },
    },
  },

  stakingDetails: {
    data: {
      stake: {},
    },
    status: 'idle',
  },
};

export const fetchAllStakings = createAsyncThunk(
  'staing/all',
  async ({ page, limit, search, unit, status, country, afterDate, beforeDate }) => {
    let query = wrapBaseApi(
      `${BASE_URL}/wallet/admin/stakings?limit=${limit || 10}&page=${
        page || 1
      }&latestChain=true&actionType=staking_in,staking_out`
    );

    if (search) query += `&search=${search}`;

    if (unit) query += `&unit=${unit}`;
    if (status) query += `&status=${status}`;
    if (country) query += `&country=${country}`;
    if (beforeDate) query += `&beforeDate=${beforeDate}`;
    if (afterDate) query += `&afterDate=${afterDate}`;
    const response = await get(query, getHeaders());
    return response.data;
  }
);

export const fetchStakingStats = createAsyncThunk('staing/stats', async () => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/wallet/admin/stakings/stat`),

    getHeaders()
  );
  return response.data;
});

export const fetchUserStaking = createAsyncThunk('staing/userStaking', async (id) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/wallet/admin/stakings/${id}`),

    getHeaders()
  );
  return response.data;
});

const stakingSlice = createSlice({
  name: 'staking',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    // Staking
    builder.addCase(fetchAllStakings.fulfilled, (state, { payload }) => {
      state.staking.status = 'fulfilled';
      state.staking.data = payload.data;
      state.staking.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchAllStakings.pending, (state) => {
      state.staking.status = 'loading';
    });

    builder.addCase(fetchAllStakings.rejected, (state, { payload }) => {
      state.staking.status = 'error';
      state.staking.errorMessage = payload;
    });

    // Staking stats
    builder.addCase(fetchStakingStats.fulfilled, (state, { payload }) => {
      state.stats.status = 'fulfilled';
      state.stats.data = payload.data;
    });

    builder.addCase(fetchStakingStats.pending, (state) => {
      state.stats.status = 'loading';
    });

    builder.addCase(fetchStakingStats.rejected, (state) => {
      state.stats.status = 'error';
    });

    // Staking details
    builder.addCase(fetchUserStaking.fulfilled, (state, { payload }) => {
      state.stakingDetails.status = 'fulfilled';
      state.stakingDetails.data = payload.data;
    });

    builder.addCase(fetchUserStaking.pending, (state) => {
      state.stakingDetails.status = 'loading';
    });

    builder.addCase(fetchUserStaking.rejected, (state) => {
      state.stakingDetails.status = 'error';
    });
  },
});

export const { reducer: stakingReducer } = stakingSlice;
