import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Center,
  useToast,
  Flex,
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import { Button, CloseModal, ConfigCheckbox, Input, Select, TableDataStatus } from 'components';
import { useUpdateTransferStatusMutation } from 'store/services/integrations.service';
import { useCopy } from 'hooks';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { formatDateToUTC, showFirstThreeChar, showLastThreeChar } from 'utils';

const UpdateTransferStatusModal = ({ modalData, isOpen, onClose }) => {
  const toast = useToast();
  const {
    _id,
    partnerId,
    userAddress,
    fiatAmount,
    processingFee,
    networkFee,
    fiatType,
    cryptoType,
    cryptoVolume,
    fee,
    reference,
    createdAt,
    transferStatus: tStatus,
    currentTransactionType,
    fiatAccountData,
    addressData,
    txHash,
    txLink,
  } = modalData;

  const [_updateTransferStatus, { isLoading }] = useUpdateTransferStatusMutation();
  const [sendWebhook, setSendwebhook] = useState(true);
  const [transferStatus, setTransferStatus] = useState('TransferStarted');
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const { hasCopied, onCopy, value } = useCopy();

  const handleUpdate = async () => {
    try {
      const response = await _updateTransferStatus({
        _id,
        transferStatus,
        sendWebhook,
        txHash: transferStatus === 'TransferComplete' ? inputValue : undefined,
        reason:
          transferStatus === 'TransferAmlFailed' ||
          transferStatus === 'TransferFailed' ||
          transferStatus === 'TransferCancelled'
            ? inputValue
            : undefined,
      }).unwrap();

      if (response.data) {
        setSendwebhook(true);
        toast({
          title: 'Success',
          status: 'success',
          description: response?.message,
          isClosable: true,
          duration: '4000',
          position: 'bottom-right',
        });
        onClose();
        setTransferStatus('TransferStarted');
      }
    } catch (error) {
      toast({
        title: 'Error',
        status: 'error',
        description: error?.data?.error,
        isClosable: true,
        duration: '4000',
        position: 'bottom-right',
      });
    }
  };

  const netCryptoVolume = cryptoVolume - fee - processingFee - networkFee;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        key={_id}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          bg='#fff'
          maxWidth='480px'
          padding='20px'
        >
          <Text
            fontSize='24px'
            textAlign='center'
            fontWeight='600'
            mb='16px'
          >
            Update Transfer Status
          </Text>

          <ModalBody padding='0px'>
            <BoxDetails
              description={partnerId}
              title='Partner ID'
            />

            <BoxDetails
              description={`${String(fiatType).toUpperCase()} ${Number(fiatAmount).toLocaleString()}`}
              title='Fiat Amount'
            />

            <BoxDetails
              description={`${cryptoVolume} | (Net: ${netCryptoVolume}) ${cryptoType}`}
              title='Crypto Volume'
            />

            <BoxDetails
              description={`N.F ${networkFee} | P.F ${processingFee} ${cryptoType}`}
              title='Crypto Fee'
            />

            <BoxDetails
              description={reference}
              title='Reference'
            />

            {currentTransactionType === 'cryptowithdrawal' && (
              <Flex
                mb='16px'
                justifyContent='space-between'
                width='100%'
              >
                <Text>Address</Text>

                <Text
                  fontWeight='500'
                  cursor='pointer'
                  onClick={() => onCopy(userAddress)}
                >
                  {`${showFirstThreeChar(userAddress, 6)}... ${showLastThreeChar(userAddress, -12)}`}{' '}
                  {hasCopied && value === userAddress ? <CheckIcon /> : <CopyIcon />}
                </Text>
              </Flex>
            )}

            {currentTransactionType === 'fiatwithdrawal' && addressData?.address && (
              <Flex
                mb='16px'
                justifyContent='space-between'
                width='100%'
              >
                <Text>Address</Text>

                <Text
                  fontWeight='500'
                  cursor='pointer'
                  onClick={() => onCopy(addressData?.address)}
                >
                  {`${showFirstThreeChar(addressData?.address, 6)}... ${showLastThreeChar(addressData?.address, -12)}`}{' '}
                  {hasCopied && value === addressData?.address ? <CheckIcon /> : <CopyIcon />}
                </Text>
              </Flex>
            )}

            <BoxDetails
              description={<TableDataStatus status={tStatus} />}
              title='Transfer Status'
              mb={currentTransactionType === 'fiatwithdrawal' ? '4px' : '16px'}
            />

            {currentTransactionType === 'fiatwithdrawal' && (
              <Box mb='12px'>
                <Accordion
                  allowToggle
                  borderRadius='0px'
                  padding='8px 0'
                >
                  <AccordionItem border='none'>
                    <AccordionButton
                      as='div'
                      p='8px 0 4px'
                      _expanded={{ bg: 'initial' }}
                      transition='all 300ms ease-in-out'
                      cursor='pointer'
                    >
                      <Box
                        flex='1'
                        textAlign='left'
                      >
                        Fiat Account Info
                      </Box>

                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel
                      fontSize='14px'
                      fontWeight='500'
                    >
                      <BoxDetails
                        title='Account Name'
                        description={fiatAccountData?.accountName}
                        mb='4px'
                      />

                      <Flex
                        mb='4px'
                        justifyContent='space-between'
                        width='100%'
                      >
                        <Text>Account Number</Text>

                        <Text
                          fontWeight='500'
                          cursor='pointer'
                          onClick={() => onCopy(fiatAccountData?.accountNumber)}
                        >
                          {fiatAccountData?.accountNumber} &nbsp;
                          {hasCopied && value === fiatAccountData?.accountNumber ? <CheckIcon /> : <CopyIcon />}
                        </Text>
                      </Flex>

                      <BoxDetails
                        title='Bank Name'
                        description={fiatAccountData?.networkOrBankCode}
                        mb='4px'
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            )}

            {/* {txHash && (
              <BoxDetails
                description={`${showFirstThreeChar(txHash, 6)}...${showLastThreeChar(txHash, -6)}`}
                title='Transaction Hash'
                mb='16px'
              />
            )} */}

            {txHash && (
              <Flex
                mb='16px'
                justifyContent='space-between'
                width='100%'
              >
                <Text>Transaction Hash</Text>

                <Flex
                  gap='4px'
                  alignItems='center'
                  onClick={() => onCopy(txHash)}
                  cursor='pointer'
                >
                  <Text
                    fontWeight='500'
                    cursor='pointer'
                  >
                    {`${showFirstThreeChar(txHash, 5)}... ${showLastThreeChar(txHash, -5)}`}{' '}
                  </Text>

                  {hasCopied && value === txHash ? <CheckIcon /> : <CopyIcon />}
                </Flex>
              </Flex>
            )}

            {txLink && (
              <Flex
                mb='16px'
                justifyContent='space-between'
                width='100%'
              >
                <Text>Transaction Link</Text>

                <a
                  href={txLink}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Text
                    fontWeight='500'
                    cursor='pointer'
                  >
                    {`${showFirstThreeChar(txLink, 5)}... ${showLastThreeChar(txLink, -5)}`}{' '}
                  </Text>
                </a>
              </Flex>
            )}

            <BoxDetails
              description={formatDateToUTC(createdAt)}
              title='Date/Time'
            />

            <Flex
              gap='16px'
              alignItems='center'
              m='32px 0'
            >
              <ConfigCheckbox
                defaultChecked={sendWebhook}
                onChange={() => setSendwebhook(!sendWebhook)}
              />

              <Text>Send Webhook</Text>
            </Flex>

            <Box mb='16px'>
              <Select
                minW='320px'
                onChange={(e) => setTransferStatus(e.target.value)}
                minH='52px'
              >
                <option value='TransferStarted'>TransferStarted</option>
                <option value='TransferPending'>TransferPending</option>
                <option value='TransferFiatFundsDebited'>TransferFiatFundsDebited</option>
                <option value='TransferSendingCryptoFunds'>TransferSendingCryptoFunds</option>
                <option value='TransferReadyForUserToSendCryptoFunds'>TransferReadyForUserToSendCryptoFunds</option>
                <option value='TransferReceivedCryptoFunds'>TransferReceivedCryptoFunds</option>
                <option value='TransferCancelled'>TransferCancelled</option>
                <option value='TransferComplete'>TransferComplete</option>
                <option value='TransferAmlFailed'>TransferAmlFailed</option>
                <option value='TransferFailed'>TransferFailed</option>
              </Select>
            </Box>

            <Box mb='32px'>
              {transferStatus === 'TransferComplete' ||
              transferStatus === 'TransferFailed' ||
              transferStatus === 'TransferAmlFailed' ||
              transferStatus === 'TransferCancelled' ? (
                <Input
                  minW='320px'
                  fontSize='16px'
                  label={transferStatus === 'TransferComplete' ? 'TxHash' : 'Reason'}
                  name={transferStatus === 'TransferComplete' ? 'TxHash' : 'Reason'}
                  onChange={handleChange}
                />
              ) : (
                <></>
              )}
            </Box>

            <Center>
              <Button
                onClick={handleUpdate}
                isLoading={isLoading}
                disabled={isLoading}
                minW='220px'
              >
                Submit
              </Button>
            </Center>

            <CloseModal
              mt='1rem'
              onClick={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateTransferStatusModal;

export const BoxDetails = ({ title, description, ...props }) => {
  return (
    <Flex
      mb='16px'
      justifyContent='space-between'
      width='100%'
      {...props}
    >
      <Text>{title}</Text>

      <Text fontWeight='500'>{description}</Text>
    </Flex>
  );
};
