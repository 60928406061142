import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';

const PartnersWalletCard = ({ coin, amount, coinSrc, align, padding }) => {
  return (
    <Box
      bg="#E2F2DA"
      borderRadius="20px"
      padding={padding ? padding : '21px'}
      marginBottom="20px"
      textAlign={align ? align : 'center'}
      maxWidth="468px"
      width="100%"
    >
      <Flex
        mb="0px"
        alignItems="center"
        justifyContent={align ? align : 'center'}
      >
        <Center
          mb="6px"
          boxShadow="0px 0.79px 3.15px rgba(229, 229, 229, 0.47)"
          borderRadius="10px"
          width="39px"
          height="35px"
          bg="#fff"
        >
          <Image src={coinSrc} width="24px" height="24px" alt="coin" />
        </Center>

        <Text fontWeight={'600'} ml="8px">
          <Text as="span" textTransform={'uppercase'}>
            {coin}
          </Text>{' '}
          Wallet
        </Text>
      </Flex>

      <Box mt="10px">
        <Text
          lineHeight={'24px'}
          fontSize="20px"
          fontWeight={'600'}
          textTransform="uppercase"
        >
          {amount} {coin}
        </Text>
      </Box>
    </Box>
  );
};

export default PartnersWalletCard;
