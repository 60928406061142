import { Box, Flex } from '@chakra-ui/react';
import { GamingTabArray } from 'LinkArray/tabArray';
import { PageTab, PageTitle, TableBoxWrapper, GamingData, Button } from 'components';
import { useLocationSearch } from 'hooks';
import { Link } from 'react-router-dom';

const Gaming = () => {
  const search = useLocationSearch();

  return (
    <Box>
      <Flex
        alignItems='center'
        justifyContent='space-between'
        mb='20px'
      >
        <PageTitle title='Rewards' />

        <Link to='/dashboard/gaming/create-game'>
          <Button
            maxW='156px'
            minH='48px'
            fontSize='14px'
          >
            Create Game
          </Button>
        </Link>
      </Flex>

      <TableBoxWrapper padding='24px'>
        <PageTab
          linkArray={GamingTabArray}
          mb='0'
        />

        <PageTitle
          title=''
          fontSize='18px'
          my='42px'
          header
        />

        {search === '' && <GamingData />}

        {search === '?inactive' && (
          <GamingData
            status='inactive'
            isCompleted
          />
        )}
      </TableBoxWrapper>
    </Box>
  );
};

export default Gaming;
