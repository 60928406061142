import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal } from 'components';
import { useUpdateCountryConfigMutation } from 'store/services/app.service';
import isObject from 'lodash/isObject';

const CountryConfigModal = ({ modalData = {}, data, isOpen, onClose, refectch }) => {
  const header = modalData && Object.keys(modalData);

  const previousData =
    data &&
    Object.fromEntries(
      Object.entries(data).filter(([key, _]) => key.toLocaleLowerCase() === header[0]?.toLocaleLowerCase())
    );

  const [_updateCountryConfig, { isLoading }] = useUpdateCountryConfigMutation();

  const toast = useToast();

  const handleUpdate = async () => {
    const updatedData = { ...previousData[header[0]] };

    Object.entries(modalData[header[0]]).forEach(([key, value]) => {
      if (Object.keys(updatedData).includes(key)) {
        if (isObject(value) && isObject(updatedData[key])) {
          updatedData[key] = { ...updatedData[key], ...value };
        } else {
          updatedData[key] = value;
        }
      }
    });

    try {
      const response = await _updateCountryConfig({
        data: updatedData,
        country: header[0],
      }).unwrap();

      if (response?.data) {
        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response?.message,
          status: 'success',
          duration: 18000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius='16px'
          maxWidth='450px'
        >
          <ModalHeader
            bg='#F7F7FC'
            padding='24px'
            fontWeight='600'
            borderTopRadius='16px'
            textAlign='center'
          >
            Update Config
          </ModalHeader>

          <ModalBody>
            <Text
              textAlign='center'
              mb='24px'
            >
              Are you sure want to update{' '}
              <Text
                as='span'
                fontWeight='bold'
                textTransform='capitalize'
              >
                {header && header[0]?.toLocaleLowerCase()}
              </Text>{' '}
              config
            </Text>

            {modalData &&
              Object.entries(modalData).map(([_, value]) => {
                return Object.entries(value).map(([key, value], index) => {
                  return (
                    <Flex
                      key={index}
                      width='100%'
                      justifyContent='space-between'
                      mb='16px'
                    >
                      <Box
                        textTransform='capitalize'
                        fontWeight='500'
                        maxWidth='250px'
                      >
                        {key}
                      </Box>

                      <Box>
                        {typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' ? (
                          <>{String(value)}</>
                        ) : (
                          Object.entries(value).map(([subHeader, subValue], i) => {
                            return (
                              <Flex
                                justifyContent='space-between'
                                minW='90px'
                                key={i}
                              >
                                <Box
                                  textTransform='uppercase'
                                  fontWeight='500'
                                  gap='24px'
                                >
                                  {subHeader}:
                                </Box>

                                <Flex gap='5px'>
                                  {(typeof subValue === 'string' ||
                                    typeof subValue === 'number' ||
                                    typeof subValue === 'boolean') &&
                                    String(subValue)}

                                  {typeof subValue === 'object' &&
                                    Object.entries(subValue).map(([a, b], _id) => {
                                      return (
                                        <Box key={_id}>
                                          {a}:{' '}
                                          <>
                                            {typeof b === 'object' ? (
                                              <>
                                                <br />
                                                {Object.entries(b).map(([newKey, newValue]) => {
                                                  return (
                                                    <Text key={_id}>
                                                      {newKey}:{newValue}
                                                    </Text>
                                                  );
                                                })}
                                              </>
                                            ) : (
                                              { b }
                                            )}
                                          </>
                                        </Box>
                                      );
                                    })}
                                </Flex>
                              </Flex>
                            );
                          })
                        )}
                      </Box>
                    </Flex>
                  );
                });
              })}

            <Center mt='32px'>
              <Button
                onClick={handleUpdate}
                isLoading={isLoading}
                disabled={isLoading}
                minW='200px'
              >
                Apply Update
              </Button>
            </Center>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CountryConfigModal;
