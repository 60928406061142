const CurrencyLogo = ({ currency }) => {
  const currencyValue = () => {
    switch (currency) {
      case 'ngn':
        return '₦';

      case 'ghs':
        return 'GH₵';

      case 'kes':
        return 'Ksh';

      default:
        return currency;
    }
  };

  return <>{currencyValue()}</>;
};

export default CurrencyLogo;
