import { Text, Flex, Box, Center, Image, Heading } from '@chakra-ui/react';

const HomeWalletCard = ({
  bgImage,
  currencyName,
  amount,
  currencyIcon,
  pl,
}) => {
  return (
    <Box
      bgImage={`url(${bgImage})`}
      width="100%"
      maxWidth="344px"
      height="176px"
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
    >
      <Center
        height="inherit"
        flexDir="column"
        alignItems="left"
        justifyContent="center"
        pl={pl ? pl : '39px'}
      >
        <Flex alignItems="center">
          <Box>
            <Image src={currencyIcon} alt="naira" width="18px" height="18px" />
          </Box>

          <Text
            color="#041815"
            fontWeight="500"
            fontSize="14px"
            lineHeight="19px"
          >
            {currencyName}
          </Text>
        </Flex>

        <Heading fontSize="24px" fontWeight="700" lineHeight="32px">
          {amount}
        </Heading>
      </Center>
    </Box>
  );
};

export default HomeWalletCard;
