import { extendTheme, theme as base, withDefaultVariant } from '@chakra-ui/react';
import { StepsTheme } from 'chakra-ui-steps';

const selectStyles = {
  variants: {
    filled: {
      field: {
        borderWidth: '1px',
        color: '#303131',
        _placeholder: {
          color: '#303131',
        },

        _focus: {
          borderWidth: '0',
          backgroundColor: '#F2F4F3',
        },
      },
    },
  },
};

const CustomSteps = {
  ...StepsTheme,
  baseStyle: (props) => ({
    ...StepsTheme.baseStyle(props),
    stepIconContainer: {
      ...StepsTheme.baseStyle(props).stepIconContainer,
      _activeStep: {
        // ...StepsTheme.baseStyle(props).stepIconContainer._activeStep,
        // bg: '0d4740',
      },
    },
  }),
};

const theme = extendTheme(
  {
    fonts: {
      heading: `'PolySans', ${base.fonts?.heading}`,
      body: `'PostGrotesk', ${base.fonts?.body}`,
    },

    colors: {
      brand: {
        primary: '#0D4740',
        darkPrimary: '#072420',
        yellowShade: '#E8C229',
        success: '#6FBE45',
        lemonShade: '#CFE9C1',
        gray: '#F2F4F3',
        darkGray: '#797A7A',
        lightGray: '#FCFDFD',
        black: '#303131',
        warning: '#F4A51D',
        error: '#ED1C00',
        proccessing: '#31B7A9',
      },
    },

    styles: {
      global: {
        'html, body': {
          background: '#FBFBFB',
          color: '#303131',
          scrollBehavior: 'smooth',
        },
        a: {
          textDecoration: 'none',
        },
        'a:hover': {
          textDecoration: 'none !important',
        },
        ul: {
          listStyle: 'none',
          margin: 0,
          padding: 0,
        },

        table: {
          fontSize: '12px',
          lineHeight: '16px',
          whiteSpace: 'nowrap',
          // position: 'relative',
          // minHeight: '520px',

          thead: {
            backgroundColor: '#f9faf9',
            borderBottom: '0.5px solid #cacbcb',

            th: {
              textTransform: 'capitalize !important',
              color: '#0d4740 !important',
            },
          },

          tbody: {
            color: '#303131',

            td: {
              border: '0px !important',
            },
          },
        },
      },
    },

    components: {
      Select: { ...selectStyles },
      Steps: CustomSteps,
      // CustomSteps,

      // This is not working
      Checkbox: {
        baseStyle: {
          icon: {
            color: '#fff',
          },
          control: {
            border: '1px',
            borderColor: '#6FBE45',
            borderRadius: 'base',
            _disabled: {
              borderColor: '#6FBE45',
              bg: '#6FBE45',
            },
          },
          label: {
            color: '#303131',
          },
          defaultProps: {
            size: null,
            variant: null,
          },
        },
      },
    },

    breakpoints: {
      sm: '320px',
      tb: '768px',
      md: '1180px',
      lg: '1300px',
      xl: '2000px',
    },
  },
  withDefaultVariant({
    variant: 'filled',
    components: ['Select', 'Textarea'],
  })
);

export default theme;
