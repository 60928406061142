import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'constant';
import { getHeaders, wrapBaseApi } from 'utils';
import { get, post } from 'utils/axiosLib';

const initialState = {
  partner: {
    data: {
      totalCount: 0,
      earnings: [],
    },
    status: 'idle',
    errorMessage: '',
  },

  leaderboard: {
    data: {
      totalCount: 0,
      data: [],
    },
    status: 'idle',
    errorMessage: '',
    dateFilter: '',
    leaderBoardId: '',
  },

  partnerByUserId: {
    data: {
      data: [],
    },
    status: 'idle',
    errorMessage: '',
  },

  earningSummary: {
    data: {
      data: [],
    },
    status: 'idle',
    errorMessage: '',
  },

  leaderBoardCount: {
    data: {
      data: [],
    },
    status: 'idle',
    errorMessage: '',
  },

  leaderBoardRanking: {
    data: {
      data: [],
    },
    referredUsersRank: {
      data: [],
    },
    totalEarnings: {
      data: [],
    },
    dateFilter: '',
    afterDate: '',
    status: 'idle',
    errorMessage: '',
  },

  leaderBoardRanking2: {
    data: {
      data: [],
    },
    status: 'idle',
    errorMessage: '',
  },
};

export const fetchAffiliates = createAsyncThunk('partner/partner', async ({ limit, page }, thunkAPI) => {
  const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/earnings/affiliates?limit=${limit}&page=${page}`);

  try {
    const response = await get(URL, getHeaders());
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchParntersByUserId = createAsyncThunk('partner/leaderBoardById', async ({ userId }, thunkAPI) => {
  const URL = `${BASE_URL}/admin/affiliates/leaderboard`;

  try {
    const response = await post(
      URL,
      {
        userId,
      },
      getHeaders()
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchLeaderboard = createAsyncThunk(
  'partner/leaderboard',
  async ({ limit, page, beforeDate, afterDate }, thunkAPI) => {
    const URL = `${BASE_URL}/admin/affiliates/leaderboard?limit=${limit}&page=${page}`;

    try {
      const response = await post(
        URL,
        {
          dateQuery: `beforeDate=${beforeDate}&afterDate=${afterDate}`,
        },
        getHeaders()
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchLeaderboardRanking = createAsyncThunk(
  'partner/leaderboardRanking',
  async ({ limit, page, sortBy, beforeDate, afterDate, timestamp }, thunkAPI) => {
    const URL = `${BASE_URL}/admin/affiliates/leaderboard-ranking`;

    try {
      const response = await post(
        URL,
        {
          limit,
          page,
          sortBy,
          beforeDate,
          afterDate,
          timestamp,
        },
        getHeaders()
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchLeaderboardRanking2 = createAsyncThunk(
  'partner/leaderboardRanking2',
  async ({ beforeDate, afterDate, timestamp }, thunkAPI) => {
    const URL = `${BASE_URL}/admin/affiliates/leaderboard-ranking`;

    try {
      const response = await post(
        URL,
        {
          limit: 10,
          page: 1,
          sortBy: 'usdEarning:desc',
          beforeDate,
          afterDate,
          timestamp,
        },
        getHeaders()
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchEarningSummary = createAsyncThunk('partner/earningSummary', async (userId, thunkAPI) => {
  const URL = `${BASE_URL}/admin/affiliates/${userId}/earning-summary`;

  try {
    const response = await get(URL, getHeaders());
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchLeaderboardCount = createAsyncThunk('partner/leaderBoardCount', async (_, thunkAPI) => {
  const URL = `${BASE_URL}/admin/affiliates/leaderboardByCount`;

  try {
    const response = await get(URL, getHeaders());
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const partnerSlice = createSlice({
  name: 'partner',

  initialState,

  reducers: {
    setLeaderBoardId: (state, action) => {
      state.leaderboard.leaderBoardId = action.payload;
    },

    setRankingDateFilter: (state, action) => {
      state.leaderBoardRanking.dateFilter = action.payload;
    },

    setLeaderBoardFilter: (state, action) => {
      state.leaderboard.dateFilter = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAffiliates.pending, (state) => {
      state.partner.status = 'loading';
    });

    builder.addCase(fetchAffiliates.fulfilled, (state, { payload }) => {
      state.partner.data = payload.data;
      state.partner.data.totalCount = payload.data.totalCount;
      state.partner.status = 'fulfilled';
    });

    builder.addCase(fetchAffiliates.rejected, (state, action) => {
      state.partner.status = 'error';
      state.partner.errorMessage = action.error.message;
    });

    // leaderboard
    builder.addCase(fetchLeaderboard.pending, (state) => {
      state.leaderboard.status = 'loading';
    });

    builder.addCase(fetchLeaderboard.fulfilled, (state, { payload }) => {
      state.leaderboard.data = payload.data;
      state.leaderboard.data.totalCount = payload.data.totalCount;
      state.leaderboard.status = 'fulfilled';
    });

    builder.addCase(fetchLeaderboard.rejected, (state, action) => {
      state.leaderboard.status = 'error';
      state.leaderboard.errorMessage = action.error.message;
    });

    // by User id
    builder.addCase(fetchParntersByUserId.pending, (state) => {
      state.partnerByUserId.status = 'loading';
    });

    builder.addCase(fetchParntersByUserId.fulfilled, (state, { payload }) => {
      state.partnerByUserId.data = payload.data;
      state.partnerByUserId.status = 'fulfilled';
    });

    builder.addCase(fetchParntersByUserId.rejected, (state, action) => {
      state.partnerByUserId.status = 'error';
      state.partnerByUserId.errorMessage = action.error.message;
    });

    // Earning Summary
    builder.addCase(fetchEarningSummary.pending, (state) => {
      state.earningSummary.status = 'loading';
    });

    builder.addCase(fetchEarningSummary.fulfilled, (state, { payload }) => {
      state.earningSummary.data = payload.data;
      state.earningSummary.status = 'fulfilled';
    });

    builder.addCase(fetchEarningSummary.rejected, (state, action) => {
      state.earningSummary.status = 'error';
      state.earningSummary.errorMessage = action.error.message;
    });

    // Leaderboard Count
    builder.addCase(fetchLeaderboardCount.pending, (state) => {
      state.leaderBoardCount.status = 'loading';
    });

    builder.addCase(fetchLeaderboardCount.fulfilled, (state, { payload }) => {
      state.leaderBoardCount.data = payload.data;
      state.leaderBoardCount.status = 'fulfilled';
    });

    builder.addCase(fetchLeaderboardCount.rejected, (state, action) => {
      state.leaderBoardCount.status = 'error';
      state.leaderBoardCount.errorMessage = action.error.message;
    });

    // Leaderboard Ranking
    builder.addCase(fetchLeaderboardRanking.pending, (state) => {
      state.leaderBoardRanking.status = 'loading';
    });

    builder.addCase(fetchLeaderboardRanking.fulfilled, (state, { payload }) => {
      state.leaderBoardRanking.status = 'fulfilled';
      state.leaderBoardRanking.data = payload.data;
    });

    builder.addCase(fetchLeaderboardRanking.rejected, (state, action) => {
      state.leaderBoardRanking.status = 'error';
      state.leaderBoardRanking.errorMessage = action.error.message;
    });

    // Leaderboard Ranking (This code should be formatted)
    builder.addCase(fetchLeaderboardRanking2.pending, (state) => {
      state.leaderBoardRanking2.status = 'loading';
    });

    builder.addCase(fetchLeaderboardRanking2.fulfilled, (state, { payload }) => {
      state.leaderBoardRanking2.status = 'fulfilled';
      state.leaderBoardRanking2.data = payload.data;
    });

    builder.addCase(fetchLeaderboardRanking2.rejected, (state, action) => {
      state.leaderBoardRanking2.status = 'error';
      state.leaderBoardRanking2.errorMessage = action.error.message;
    });
  },
});

export const {
  actions: { setLeaderBoardId, setRankingDateFilter, setLeaderBoardFilter },
  reducer: PartnerReducer,
} = partnerSlice;
