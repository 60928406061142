import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { FilterSVG } from 'assets';
import {
  PageTitle,
  SearchInput,
  TableBoxWrapper,
  PartnerStats,
  PartnersCommission,
  PartnersTable,
  PartnersFilterByDateModal,
} from 'components';

const Partners = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <PageTitle
        title='Partners'
        mb='24px'
      />

      <TableBoxWrapper bg='#FCFDFD'>
        <Flex
          gridGap='16px'
          flexDir={{ base: 'column-reverse', tb: 'row' }}
        >
          <Box>
            <SearchInput
              width='319px'
              placeholder='Search for user id, name or email'
            />
          </Box>

          <Flex
            gridGap='5px'
            alignItems='center'
            cursor='pointer'
            onClick={onOpen}
          >
            <Text
              fontSize='14px'
              fontWeight='500'
            >
              Filters
            </Text>

            <FilterSVG />
          </Flex>
        </Flex>

        <PartnerStats />

        <PartnersCommission />

        <Box>
          <PartnersTable />
        </Box>
      </TableBoxWrapper>

      <PartnersFilterByDateModal
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default Partners;
