import { format, parseISO } from 'date-fns';

export function formatDateToUTC(createdAt) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleTimeString('en-GB', {
    year: 'numeric',
    day: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });

  return result;
}

export function formatTimeToUTC(createdAt) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return result;
}

export function formatDayOnylToUTC(createdAt) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleTimeString('en-GB', {
    year: 'numeric',
    day: '2-digit',
    month: '2-digit',
  });

  return result;
}

export function convertHMS(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
}

export function formatDay(createdAt) {
  const formatted = format(parseISO(createdAt), 'eee. dd MMM, yyyy');

  return formatted;
}
