import { Box, Flex, Text } from '@chakra-ui/react';
import { LogoutSVG } from 'assets';
import { useAuth } from 'hooks';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
    navigate('/login', {
      replace: true,
    });
  };
  return (
    <Box
      cursor='pointer'
      mt='51px'
      padding={{ base: '0 8px', md: '18px 59px' }}
      onClick={handleLogout}
    >
      <Flex
        gridGap='13px'
        alignItems='center'
      >
        <LogoutSVG />

        <Text
          fontFamily='PostGrotesk'
          fontWeight='400'
          as='p'
          fontSize='1rem'
          color='#F2F4F3'
        >
          Log Out
        </Text>
      </Flex>
    </Box>
  );
};
export default Logout;
