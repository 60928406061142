import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Center, Box, useToast, Textarea } from '@chakra-ui/react';
import { Button, CloseModal, Input } from 'components';
import { useState } from 'react';
import { useUpdateIntegrationKycMutation } from 'store/services/integrations.service';

const IntegrationsProcessKYCModal = ({ modalData, kycStatus, isOpen, onClose }) => {
  const toast = useToast();
  const { _id, kycSchema } = modalData;

  const [reason, setReason] = useState('');
  const [expiresOn, setExpiresOn] = useState('');

  const status = {
    rejected: 'reject',
    approved: 'approve',
    expired: 'expire',
    pending: 'pending',
  };

  const [_updateKyc, { isLoading }] = useUpdateIntegrationKycMutation();

  const handleUpdate = async () => {
    try {
      const response = await _updateKyc({
        _id,
        kycSchema,
        kycStatus,
        reason: kycStatus === 'rejected' ? reason : undefined,
        expiresOn: kycStatus === 'approved' ? expiresOn : undefined,
      });

      if (response.data) {
        setReason('');
        setExpiresOn('');
        toast({
          title: 'Success',
          status: 'success',
          description: response?.data?.message,
          isClosable: true,
          duration: '4000',
          position: 'bottom-right',
        });
        onClose();
      }

      if (response.error) {
        toast({
          title: 'Error',
          status: 'error',
          description: response?.error?.data?.error,
          isClosable: true,
          duration: '4000',
          position: 'bottom-right',
        });
      }
    } catch (error) {}
  };

  const isDisabled =
    isLoading ||
    (kycStatus === 'rejected' && reason.length <= 2) ||
    (kycStatus === 'approved' && expiresOn.length <= 2);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        key={_id}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          bg='#fff'
          maxWidth='450px'
          padding='20px 40px 20px'
        >
          <Text
            fontSize='24px'
            textAlign='center'
            fontWeight='600'
          >
            Update KYC
          </Text>

          <ModalBody
            padding='0px'
            mt='20px'
          >
            <Box flexDir='column'>
              <Text>
                Are you sure you want to{' '}
                <Text
                  as='span'
                  fontWeight='600'
                >
                  {status[kycStatus]}
                </Text>{' '}
                &nbsp;this document
              </Text>

              <Box margin='24px 0 32px'>
                {kycStatus === 'rejected' && (
                  <Textarea
                    label='Reason'
                    placeholder='reason'
                    name='reason'
                    onChange={(e) => setReason(e.target.value)}
                    fontSize='15px'
                    bg='#f2f2f2'
                    isRequired
                    width='100%'
                    height='120px'
                    padding='14px'
                    borderWidth='1px'
                    _hover={{
                      bg: '#f2f2f2',
                      borderColor: '#6FBE45',
                    }}
                    _focus={{
                      borderColor: '#6FBE45',
                    }}
                  />
                )}
              </Box>

              <Box margin='24px 0 32px'>
                {kycStatus === 'approved' && (
                  <>
                    <Text
                      fontSize='14px'
                      mb='8px'
                    >
                      Valid Unitl
                    </Text>
                    <Input
                      type='date'
                      onChange={(e) => setExpiresOn(e.target.value)}
                    />
                  </>
                )}
              </Box>

              <Center>
                <Button
                  onClick={handleUpdate}
                  isLoading={isLoading}
                  disabled={isDisabled}
                  minW='220px'
                >
                  Submit
                </Button>
              </Center>
            </Box>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default IntegrationsProcessKYCModal;
