import { Box, Flex } from '@chakra-ui/react';
import { FilterSelect } from 'components';
import { supportedCoins } from 'constant';
import { useDispatch } from 'react-redux';
import { getSelectedCoin, getSelectedCurrency, getSelectedDate } from 'store/slices/p2p.slice';
import { last30Days, lastWeek } from 'utils';

const P2PFilter = () => {
  const dispatch = useDispatch();

  const handleCurrency = (e) => {
    dispatch(getSelectedCurrency(e.target.value));
  };

  const handleCoin = (e) => {
    dispatch(getSelectedCoin(e.target.value));
  };

  const handleDate = (e) => {
    if (e.target.value !== 'custom') {
      dispatch(getSelectedDate(e.target.value));
    }
  };

  return (
    <Flex
      gridGap='9px'
      flexWrap='wrap'
      // flexDir={{ sm: 'column', md: 'row' }}
    >
      <Box>
        <FilterSelect
          width='133px'
          onChange={handleCurrency}
        >
          <option value='all'>All Currency</option>
          <option value='ngn'>NGN</option>
          <option value='ghs'>GHS</option>
          <option value='kes'>KES</option>
        </FilterSelect>
      </Box>

      <Box>
        <FilterSelect
          width='114px'
          onChange={handleCoin}
        >
          <option value='all'>All Coins</option>

          {supportedCoins.map(({ coin, id }) => (
            <option
              key={id}
              value={coin}
            >
              {String(coin).toUpperCase()}
            </option>
          ))}
        </FilterSelect>
      </Box>

      <Box>
        <FilterSelect
          width='130px'
          onChange={handleDate}
        >
          <option value={''}>Date</option>
          <option value={lastWeek}>Last 7 days</option>
          <option value={last30Days}>Last 30 days</option>
        </FilterSelect>
      </Box>
    </Flex>
  );
};

export default P2PFilter;
