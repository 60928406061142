import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Flex, Box } from '@chakra-ui/react';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { formatDateToUTC } from 'utils';
import { CloseModal } from 'components';
import { useCopy } from 'hooks';

const WalletCryptoModal = ({ isOpen, onClose, data }) => {
  const { _id, userId, createdAt, addressRef, description, type, unit, value, balanceAfter, balanceBefore } = data;

  const { hasCopied, onCopy } = useCopy();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      key={_id}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        bg='#fff'
        maxWidth='568px'
        padding={{ base: '40px 16px 0', md: '40px 40px 0' }}
      >
        <Text
          fontSize='24px'
          textAlign='center'
          fontWeight='600'
        >
          Transaction Details
        </Text>

        <ModalBody
          padding='0px'
          margin='40px 0'
          fontSize='14px'
        >
          <Box>
            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>User ID</Text>

              <Text fontWeight='500'>
                <a
                  href={`/dashboard/users/${userId}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {userId}
                </a>
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Amount</Text>

              <Text
                fontWeight='500'
                textTransform='uppercase'
              >
                {value?.$numberDecimal?.toLocaleString()} {unit}
              </Text>
            </Flex>

            <Flex
              mb='24px'
              justifyContent='space-between'
            >
              <Text>Description</Text>

              <Text
                fontWeight='500'
                textTransform='lowercase'
              >
                {description}
              </Text>
            </Flex>

            <Flex
              mb='24px'
              justifyContent='space-between'
            >
              <Text>Address Referrence</Text>

              <Text
                fontWeight='500'
                textTransform='lowercase'
                cursor='pointer'
                onClick={() => onCopy(addressRef)}
              >
                {addressRef} {hasCopied ? <CheckIcon /> : <CopyIcon cursor='pointer' />}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Status</Text>

              <Text
                fontWeight='500'
                textTransform='capitalize'
              >
                {type}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Balance Before</Text>

              <Text
                fontWeight='500'
                textTransform='uppercase'
              >
                {balanceBefore} {unit}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Balance After</Text>

              <Text
                textTransform='uppercase'
                fontWeight='500'
              >
                {balanceAfter} {unit}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Time and Date</Text>

              <Text fontWeight='500'>{formatDateToUTC(createdAt)}</Text>
            </Flex>
          </Box>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WalletCryptoModal;
