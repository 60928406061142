import { Box, Text, Flex, Center, Image } from '@chakra-ui/react';
import { gamingDefaultImg } from 'constant/supportedCoins';

const GamingCard = ({ onClick, level, reward, logoUrl, target, description }) => {
  return (
    <Box
      boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
      borderRadius='10px'
      bg='#fff'
      padding='16px'
      maxW='340px'
      width='100%'
      onClick={onClick}
      transition='transform 0.45s ease-out, all 0.4s ease-in'
      border='1px solid #CACBCA'
      cursor='pointer'
      _hover={{
        boxShadow: '2px 2px 16px 1px rgba(50,54,57,0.15)',
        bg: 'rgba(229, 229, 229, 0.47)',
      }}
    >
      <Text
        mb='8px'
        fontWeight='500'
        fontSize='14px'
      >
        Level {level}
      </Text>

      <Flex gap='8px'>
        <Box
          width='100%'
          maxW='80px'
        >
          <Center
            borderRadius='8px'
            border='1px solid #E6E7E6'
            bg='#F4F6F5'
            width='80px'
            height='80px'
          >
            <Image
              src={logoUrl?.includes('demo') || !logoUrl ? gamingDefaultImg : logoUrl}
              width='80px'
              height='80px'
              alt='item'
              objectFit='contain'
            />
          </Center>
        </Box>

        <Box
          maxW='244px'
          width='100%'
        >
          <Text
            fontSize='14px'
            fontWeight='600'
          >
            {reward.amount}{' '}
            <Text
              as='span'
              textTransform='uppercase'
            >
              {reward.currency}
            </Text>{' '}
          </Text>

          <Text>
            Target:{' '}
            <Text
              as='span'
              fontWeight='500'
            >
              {target}
            </Text>
          </Text>

          <Text
            fontSize='12px'
            lineHeight='15.96px'
          >
            {description}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
export default GamingCard;
