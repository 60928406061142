import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'constant';
import { getHeaders, wrapBaseApi } from 'utils';
import { get } from 'utils/axiosLib';

export const fetchP2PCompletedTrades = createAsyncThunk(
  'p2p/trades',
  async ({ page, limit, coin, currency, date, search }) => {
    let query = wrapBaseApi(`${BASE_URL}/p2p/admin/trades/completed?page=${page}&limit=${limit}&sortBy=createdAt:desc`);

    if (page) query += `&page=${page}`;
    if (coin) query += `&coin=${coin}`;
    if (currency) query += `&currency=${currency}`;
    if (String(date).length > 3) query += `&${date}`;
    if (search) query += `&search=${search}`;

    const response = await get(query, getHeaders());
    return response.data;
  }
);

export const fetchTradeHistory = createAsyncThunk('p2p/tradeHistory', async (id) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/p2p/admin/trades/completed?sortBy=createdAt:desc?userId=${id}`),
    getHeaders()
  );
  return response.data;
});

export const fetchP2PListing = createAsyncThunk('p2p/listing', async ({ page, limit, search }) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/p2p/admin/listings?page=${page}&limit=${limit}&search=${search}`),
    getHeaders()
  );
  return response.data;
});

export const fetchP2PBuyListing = createAsyncThunk('p2p/buyListing', async ({ page, limit, search }) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/p2p/admin/listings?adsType=buying&page=${page}&limit=${limit}&user=${search}`),
    getHeaders()
  );
  return response.data;
});

export const fetchP2PSellListing = createAsyncThunk('p2p/sellListing', async ({ page, limit, search }) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/p2p/admin/listings?adsType=selling&page=${page}&limit=${limit}&user=${search}`),
    getHeaders()
  );
  return response.data;
});

export const fetchP2PContractsPending = createAsyncThunk(
  'p2p/contractsPending',
  async ({ page, limit, coin, currency, date, search }) => {
    let query = wrapBaseApi(`${BASE_URL}/p2p/admin/trades/contracts?status=pending&limit=${limit}`);

    if (page) query += `&page=${page}`;
    if (coin) query += `&coin=${coin}`;
    if (currency) query += `&currency=${currency}`;
    if (String(date).length > 3) query += `&${date}`;
    if (search) query += `&search=${search}`;

    const response = await get(query, getHeaders());
    return response.data;
  }
);

export const fetchP2PContractsCancelled = createAsyncThunk(
  'p2p/contractsCancelled',
  async ({ page, limit, coin, currency, date, search }) => {
    let query = wrapBaseApi(`${BASE_URL}/p2p/admin/trades/contracts?status=cancelled&limit=${limit}`);

    if (page) query += `&page=${page}`;
    if (coin) query += `&coin=${coin}`;
    if (currency) query += `&currency=${currency}`;
    if (String(date).length > 3) query += `&${date}`;
    if (search) query += `&search=${search}`;

    const response = await get(query, getHeaders());
    return response.data;
  }
);

export const fetchP2PContractsDispute = createAsyncThunk('p2p/contractsDispute', async ({ limit, page, search }) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/p2p/admin/trades/contracts?status=indispute&page=${page}&limit=${limit}&search=${search}`),
    getHeaders()
  );
  return response.data;
});

export const fetchP2PContractsDisputeByID = createAsyncThunk('p2p/contractsDisputeById', async ({ id }) => {
  const response = await get(wrapBaseApi(`${BASE_URL}/p2p/flexilisting/contracts/${id}`), getHeaders());
  return response.data;
});

export const fetchP2PStat = createAsyncThunk('p2p/stat', async () => {
  const response = await get(wrapBaseApi(`${BASE_URL}/p2p/admin/trades/stats`), getHeaders());
  return response.data;
});

export const fetchP2PChatById = createAsyncThunk('p2p/chat', async (id) => {
  const response = await get(wrapBaseApi(`${BASE_URL}/p2p/admin/chat/${id}`), getHeaders());
  return response.data;
});

export const fetchP2PAllChats = createAsyncThunk('p2p/allChats', async () => {
  const response = await get(wrapBaseApi(`${BASE_URL}/p2p/admin/chats`), getHeaders());
  return response.data;
});

export const fetchContractById = createAsyncThunk('p2p/contractById', async (id) => {
  const response = await get(wrapBaseApi(`${BASE_URL}/p2p/admin/trades/contract/${id}`), getHeaders());
  return response.data;
});

export const fetchP2pProfile = createAsyncThunk('p2p/p2pProfile', async (username, { rejectWithValue }) => {
  try {
    const response = await get(wrapBaseApi(`${BASE_URL}/p2p/profile/${username}`), getHeaders());
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const initialState = {
  trade: {
    status: 'idle',
    totalCount: 0,
    data: [],
  },

  listing: {
    status: 'idle',
    totalCount: 0,
    data: {},
    buyListing: [],
    sellListing: [],
  },

  buyListing: {
    status: 'idle',
    totalCount: 0,
    data: {
      trades: [],
    },
  },

  sellListing: {
    status: 'idle',
    totalCount: 0,
    data: {
      trades: [],
    },
  },

  contractsPending: {
    status: 'idle',
    data: {
      trades: [],
    },
    totalCount: 0,
  },

  contractsDispute: {
    status: 'idle',
    data: {
      trades: [],
      totalCount: 0,
    },
  },

  contractsDisputeById: {
    status: 'idle',
    data: {
      counterParties: [],
    },
  },

  contractsCancelled: {
    status: 'idle',
    data: {
      trades: [],
    },
    totalCount: 0,
  },

  tradeHistory: {
    status: 'idle',
    data: {
      trades: [],
    },
  },

  stat: {
    status: 'idle',
    data: {
      buys: 0,
      sells: 0,
    },
  },

  chatById: {
    status: 'idle',
    data: {
      chats: [],
      allowedParties: [],
    },
  },

  tradeRef: {
    status: 'idle',
    data: {},
  },

  p2pProfile: {
    status: 'idle',
    data: {
      profile: {},
    },
    errorMessage: '',
  },

  filter: {
    currency: '',
    coin: '',
    date: '',
  },
};

const p2pSlice = createSlice({
  name: 'p2p',

  initialState,

  reducers: {
    filterTradeByStatus: (state, { payload }) => {
      state.trade.data = state.trade.data.filter(({ paymentStatus }) => {
        return paymentStatus === payload;
      });
    },

    getSelectedCurrency: (state, { payload }) => {
      state.filter.currency = payload;
    },

    getSelectedCoin: (state, { payload }) => {
      state.filter.coin = payload;
    },

    getSelectedDate: (state, { payload }) => {
      state.filter.date = payload;
    },
  },

  extraReducers: (builder) => {
    // Trades
    builder.addCase(fetchP2PCompletedTrades.fulfilled, (state, { payload }) => {
      state.trade.status = 'fulfilled';
      state.trade.data = payload.data.trades;
      state.trade.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchP2PCompletedTrades.pending, (state) => {
      state.trade.status = 'loading';
    });

    builder.addCase(fetchP2PCompletedTrades.rejected, (state) => {
      state.trade.status = 'error';
    });

    // Contracts Pending
    builder.addCase(fetchP2PContractsPending.fulfilled, (state, { payload }) => {
      state.contractsPending.status = 'fulfilled';
      state.contractsPending.data = payload.data;
      state.contractsPending.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchP2PContractsPending.pending, (state) => {
      state.contractsPending.status = 'loading';
    });

    builder.addCase(fetchP2PContractsPending.rejected, (state) => {
      state.contractsPending.status = 'error';
    });

    // Contracts Cancelled
    builder.addCase(fetchP2PContractsCancelled.fulfilled, (state, { payload }) => {
      state.contractsCancelled.status = 'fulfilled';
      state.contractsCancelled.data = payload.data;
      state.contractsCancelled.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchP2PContractsCancelled.pending, (state) => {
      state.contractsCancelled.status = 'loading';
    });

    builder.addCase(fetchP2PContractsCancelled.rejected, (state) => {
      state.contractsCancelled.status = 'error';
    });

    // Contracts dispute
    builder.addCase(fetchP2PContractsDispute.fulfilled, (state, { payload }) => {
      state.contractsDispute.status = 'fulfilled';

      state.contractsDispute.data = payload.data;
    });

    builder.addCase(fetchP2PContractsDispute.pending, (state) => {
      state.contractsDispute.status = 'loading';
    });

    builder.addCase(fetchP2PContractsDispute.rejected, (state) => {
      state.contractsDispute.status = 'error';
    });

    // Contracts dispute by ID
    builder.addCase(fetchP2PContractsDisputeByID.fulfilled, (state, { payload }) => {
      state.contractsDisputeById.status = 'fulfilled';
      state.contractsDisputeById.data = payload.data;
    });

    builder.addCase(fetchP2PContractsDisputeByID.pending, (state) => {
      state.contractsDisputeById.status = 'loading';
    });

    builder.addCase(fetchP2PContractsDisputeByID.rejected, (state) => {
      state.contractsDisputeById.status = 'error';
    });

    // Buy Listing
    builder.addCase(fetchP2PBuyListing.fulfilled, (state, { payload }) => {
      state.buyListing.status = 'fulfilled';
      state.buyListing.data = payload.data;
      state.buyListing.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchP2PBuyListing.pending, (state) => {
      state.buyListing.status = 'loading';
    });

    builder.addCase(fetchP2PBuyListing.rejected, (state) => {
      state.buyListing.status = 'error';
    });

    // Sell listing
    builder.addCase(fetchP2PSellListing.fulfilled, (state, { payload }) => {
      state.sellListing.status = 'fulfilled';
      state.sellListing.data = payload.data;
      state.sellListing.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchP2PSellListing.pending, (state) => {
      state.sellListing.status = 'loading';
    });

    builder.addCase(fetchP2PSellListing.rejected, (state) => {
      state.sellListing.status = 'error';
    });

    // TradeHistory
    builder.addCase(fetchTradeHistory.fulfilled, (state, { payload }) => {
      state.tradeHistory.status = 'fulfilled';
      state.tradeHistory.data = payload.data;
    });

    builder.addCase(fetchTradeHistory.pending, (state) => {
      state.tradeHistory.status = 'loading';
    });

    builder.addCase(fetchTradeHistory.rejected, (state) => {
      state.tradeHistory.status = 'error';
    });

    // Stat
    builder.addCase(fetchP2PStat.fulfilled, (state, { payload }) => {
      state.stat.status = 'fulfilled';
      state.stat.data = payload.data;
    });

    builder.addCase(fetchP2PStat.pending, (state) => {
      state.stat.status = 'loading';
    });

    builder.addCase(fetchP2PStat.rejected, (state) => {
      state.stat.status = 'error';
    });

    // Trade Ref
    builder.addCase(fetchP2PChatById.fulfilled, (state, { payload }) => {
      state.chatById.status = 'fulfilled';
      state.chatById.data = payload.data;
    });

    builder.addCase(fetchP2PChatById.pending, (state) => {
      state.chatById.status = 'loading';
    });

    builder.addCase(fetchP2PChatById.rejected, (state) => {
      state.chatById.status = 'error';
    });

    // Contracts by ID (Trade Reference)
    builder.addCase(fetchContractById.fulfilled, (state, { payload }) => {
      state.tradeRef.status = 'fulfilled';
      state.tradeRef.data = payload.data;
    });

    builder.addCase(fetchContractById.pending, (state) => {
      state.tradeRef.status = 'loading';
    });

    builder.addCase(fetchContractById.rejected, (state) => {
      state.tradeRef.status = 'error';
    });

    // P2P Profile
    builder.addCase(fetchP2pProfile.pending, (state) => {
      state.p2pProfile.status = 'loading';
      state.p2pProfile.errorMessage = '';
    });

    builder.addCase(fetchP2pProfile.fulfilled, (state, { payload }) => {
      state.p2pProfile.status = 'fulfilled';
      state.p2pProfile.data = payload.data;
    });

    builder.addCase(fetchP2pProfile.rejected, (state, { payload }) => {
      state.p2pProfile.status = 'error';
      state.p2pProfile.errorMessage = payload.error;
    });
  },
});

export const { actions, reducer: P2PReducer } = p2pSlice;
export const { filterTradeByStatus, getSelectedCurrency, getSelectedCoin, getSelectedDate } = p2pSlice.actions;
