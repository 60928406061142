import { useCallback, useEffect, useRef } from "react";
import { ls } from 'utils';
import { useRedebounce } from "./useRedebounce";

const useShortcutKeys = (shortcutSequences = [], cb, superKey) => {
    const callback = useRef(cb || (() => ({})));
    const pressedSequences = useRef([]);
    const redebounce = useRedebounce();
    let disabledCmd = useRef(null);

    const isDisabled = useCallback(() => {
        if (superKey === true) return false;
        if (disabledCmd.current === null) {
            disabledCmd.current = ls.get("DISABLE_CMD_KEYS") === "1";
        }
        return !!disabledCmd.current;
    }, [superKey])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (!isDisabled()) {
                const { key } = event;
                const newPressedSequences = pressedSequences.current.concat(key);

                const matchedSequence = shortcutSequences.join("-") === newPressedSequences.join("-");

                const clearSequence = (delay = 2000) => {
                    redebounce(() => {
                        pressedSequences.current = [];
                    }, "reset-shortcut-sequence", delay)();
                }

                if (matchedSequence) {
                    if (typeof callback.current === "function") {
                        callback.current();
                    }

                    pressedSequences.current = [];
                } else {
                    const matchStart = newPressedSequences[0] === shortcutSequences[0];
                    const matchingLength = shortcutSequences.length > newPressedSequences.length;
                    const isNextChar = shortcutSequences.join("-").startsWith(newPressedSequences.join("-"));

                    if (matchStart && matchingLength && isNextChar) {
                        pressedSequences.current = newPressedSequences;
                        // Clear the stored sequence after 3 seconds
                        clearSequence(3000);
                    } else {
                        clearSequence(0.001);
                    }
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [cb, redebounce, shortcutSequences, pressedSequences, isDisabled]);

    const setShortcutCallback = (newCallback) => {
        callback.current = newCallback;
    };

    return { setShortcutCallback };
};

export default useShortcutKeys;
