import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Box,
  VStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Button, Input, InputSelect, ChallengeIconUpload } from 'components';
import { useState } from 'react';
import { useUpdateGamgeLevelMutation } from 'store/services/gaming.service';
import { uploadFile } from 'utils';

const UpdateLevelModal = ({ isOpen, onClose, modalData, id }) => {
  const toast = useToast();
  const { logoUrl, target, reward, description } = modalData;

  const [state, setState] = useState({
    level: modalData.level,
    target: modalData.target,
    currency: reward.currency,
    amount: reward.amount,
    description: modalData.description,
  });

  const [selectedFile, setSelectedFile] = useState();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const [logoLoading, setLogoLoading] = useState(false);
  const [_updateLevel, { isLoading }] = useUpdateGamgeLevelMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    selectedFile ? setLogoLoading(true) : setLogoLoading(false);

    try {
      const _logoUrl = selectedFile ? (await uploadFile(selectedFile, selectedFile.name)).location : logoUrl;

      const response = await _updateLevel({
        _id: id,
        logoUrl: _logoUrl,
        level: Number(state.level),
        target: state.target,
        currency: state.currency,
        amount: state.amount,
        description: state.description,
      }).unwrap();

      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response?.message,
        status: 'success',
        duration: 7000,
        isClosable: true,
      });

      onClose();
      setLogoLoading(false);
    } catch (error) {
      setLogoLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.data?.error,
        status: 'error',
        duration: 7000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        maxWidth='520px'
        padding='32px 0'
      >
        <ModalHeader
          fontWeight='600'
          textAlign='center'
          padding='0'
        >
          Update Level {modalData?.level}
        </ModalHeader>

        <ModalBody bg='#fff'>
          <form onSubmit={handleSubmit}>
            <VStack
              spacing='8px'
              alignItems='revert-layer'
              mb='24px'
            >
              <Input
                label='Description'
                name='description'
                placeholder='Description'
                labelSize='16px'
                type='text'
                defaultValue={description}
                onChange={handleChange}
                isRequired
              />

              <Input
                label='Target'
                name='target'
                placeholder='Target'
                labelSize='16px'
                type='number'
                step='any'
                defaultValue={target}
                onChange={handleChange}
                isRequired
              />

              <Input
                label='Amount'
                name='amount'
                placeholder='amount'
                labelSize='16px'
                step='any'
                type='number'
                defaultValue={reward?.amount}
                onChange={handleChange}
                isRequired
              />

              <Box width='100%'>
                <Text
                  display='block'
                  mb='0.5rem'
                  htmlFor='currency'
                  as='label'
                >
                  Currency
                </Text>
                <InputSelect
                  name='currency'
                  width='100%'
                  fontSize='16px'
                  minH='60px'
                  defaultValue={reward?.currency}
                  onChange={handleChange}
                >
                  <option value=''>Currency</option>
                  <option value='usdt'>USDT</option>
                  <option value='usdc'>USDC</option>
                  <option value='pyusd'>PYUSD</option>
                  <option value='cusd'>cUSD</option>
                </InputSelect>
              </Box>

              <ChallengeIconUpload
                defaultPreview={logoUrl}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
              />
            </VStack>

            <Button
              type='submit'
              isLoading={isLoading || logoLoading}
              disabled={isLoading || logoLoading}
              width='100%'
              minH='60px'
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdateLevelModal;
