import { useState } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Loader, ScrollRightContainer, ViewPartnerWalletModal } from 'components';

const PartnersLeaderboardTotalEarnings = ({ data, status, errorMessage }) => {
  const [id, setId] = useState();
  console.log(id, 'ID');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = (userId) => {
    onOpen();
    setId(userId);
  };

  return (
    <Box
      bg='#F9FAF9'
      borderRadius='20px'
      padding='24px 20px'
      minHeight={'641px'}
    >
      <Box>
        <Text
          fontSize={'18px'}
          fontWeight='600'
          mb='25px'
          fontFamily='PolySans'
          textAlign='center'
        >
          Top 10 partners based on Total Earnings
        </Text>

        {status === 'loading' && <Loader />}

        {status === 'error' && <p>{errorMessage}</p>}

        <ScrollRightContainer>
          <Table>
            <Thead
              bg='transparent'
              borderBottom='0.5px solid #CACBCB'
            >
              <Tr>
                <Th textAlign='center'>Username</Th>
                <Th textAlign='center'>Wallet</Th>
                <Th textAlign='center'>Transaction Value</Th>
              </Tr>
            </Thead>

            <Tbody>
              {status === 'fulfilled' &&
                data.data.map(({ usdEarning, username, userId }, i) => {
                  return (
                    <Tr
                      key={i}
                      borderBottom='0.5px solid #CACBCB'
                    >
                      <Td textAlign='center'>{username ? username : 'N/A'}</Td>

                      <Td
                        color='#6FBE45'
                        textDecor='underline'
                        cursor={'pointer'}
                        fontWeight='500'
                        onClick={() => handleOpen(userId)}
                        textAlign='center'
                      >
                        View wallet
                      </Td>

                      <Td textAlign='center'> {usdEarning.toFixed(2)}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </ScrollRightContainer>
      </Box>

      <Box
        color='brand.primary'
        fontSize='14px'
        fontWeight='600'
        textDecoration='underline'
        fontFamily='PolySans'
        mt='25px'
        textAlign='center'
      >
        <Link to={`/dashboard/partners/earnings-referred`}>View All</Link>
      </Box>

      <ViewPartnerWalletModal
        isOpen={isOpen}
        onClose={onClose}
        userId={id}
      />
    </Box>
  );
};

export default PartnersLeaderboardTotalEarnings;
