import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import useShortcutKeys from 'hooks/useShortCut';
import { useNavigate } from 'react-router-dom';
import { CloseModal, KeyShortcutsContent } from 'components';
import { ls } from 'utils';

const KeyShortcuts = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showPallette = () => {
    onOpen();
  };

  useShortcutKeys(['/', '/', '/'], showPallette);

  const navigate = useNavigate();
  const handleGoForward = () => {
    navigate(1);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const clearLs = () => {
    ls.clear();
    navigate('/login', { replace: true });
  };

  const appConfigPage = () => {
    // navigate('/dashboard/settings?config');;
    window.location = '/dashboard/settings?config';
  };

  useShortcutKeys(['/', 'ArrowLeft'], handleGoBack);
  useShortcutKeys(['/', 'ArrowRight'], handleGoForward);
  useShortcutKeys(['/', 'c', 'l', 's'], clearLs);
  useShortcutKeys(['/', 'a', 'p', 'c'], appConfigPage);

  return (
    <>
      {children}
      <>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />

          <ModalContent
            borderRadius='16px'
            padding='20px'
            maxWidth='480px'
          >
            <ModalHeader
              p='0'
              textAlign='center'
            >
              Shortcuts
            </ModalHeader>
            <ModalBody
              padding='0px'
              mt='20px'
            >
              <KeyShortcutsContent />
            </ModalBody>

            <CloseModal onClick={onClose} />
          </ModalContent>
        </Modal>
      </>
    </>
  );
};

export default KeyShortcuts;
