import { useEffect, useState } from 'react';
import { uploadFile } from 'utils';
import { Center, Box, Text, useToast } from '@chakra-ui/react';
import { Button, FileUpload } from 'components';

const StepTwo = ({ setState, state, nextStep }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  useEffect(() => {
    async function upload() {
      if (selectedFile) {
        setLoading(true);
        try {
          const response = await uploadFile(selectedFile, selectedFile?.name);

          if (response.location) {
            setLoading(false);
            setState({
              ...state,
              selfieLink: response.location,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error, 'ERROR ERRROR');

          toast({
            title: 'Oops',
            description: 'Something went wrong, please click the image to re-upload document',
            duration: '3000',
            isClosable: true,
            position: 'bottom-right',
          });
        }
      }
    }
    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, setState]);

  return (
    <Box>
      <Text mb='16px'>
        Place your ID card on a plain sheet of paper and write this at the top of the paper with the accurate
        information:
      </Text>

      <FileUpload
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        defaultPreview={state.selfieLink}
      />

      <Center
        width='100%'
        justify='flex-end'
        flexDir='column'
        mt='32px'
      >
        <Button
          isLoading={loading}
          minH='58px'
          width='320px'
          cursor='pointer'
          onClick={nextStep}
          disabled={!state.selfieLink}
        >
          Save and Continue
        </Button>
      </Center>
    </Box>
  );
};

export default StepTwo;
