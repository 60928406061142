import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders, wrapBaseApi } from 'utils';
import { get } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const initialState = {
  wallet: {
    status: 'idle',
    data: {},
  },

  singleUserWallet: {
    status: 'idle',
    data: {
      wallet: [],
    },
  },

  deposit: {
    status: 'idle',
    data: {
      deposits: [],
    },
    totalCount: 0,
  },

  singleDeposit: {
    status: 'idle',
    data: {
      deposits: [],
      totalCount: 0,
    },
  },

  withdraw: {
    status: 'idle',
    data: {
      withdrawals: [],
      totalCount: 0,
    },
  },

  singleWithdraw: {
    status: 'idle',
    data: {
      withdrawals: [],
      totalCount: 0,
    },
  },

  cryptoDeposit: {
    status: 'idle',
    data: {
      transactions: [],
    },
    totalCount: 0,
  },

  singleCryptoDeposit: {
    status: 'idle',
    data: {
      transactions: [],
      totalCount: 0,
    },
  },

  userTranasction: {
    status: 'idle',
    data: {
      totalCount: 0,
      transactions: [],
    },
  },

  withdrawalType: {
    type: 'fiat',
  },
};

export const fetchAllWallet = createAsyncThunk('wallet/allWallet', async (token, _) => {
  const response = await get(wrapBaseApi(`${BASE_URL}/wallet/admin/bitmama/wallet`), getHeaders(token));
  return response.data;
});

export const fetchUserWallet = createAsyncThunk('wallet/singleUser', async (id, _) => {
  const response = await get(wrapBaseApi(`${BASE_URL}/wallet/admin/wallets/user/${id}`), getHeaders());
  return response.data;
});

export const fetchWalletDeposit = createAsyncThunk(
  'wallet/deposit',
  async ({ page, limit, status, depositPaymentType, queries }) => {
    let query = wrapBaseApi(`${BASE_URL}/wallet/admin/deposit?limit=${limit || 10}&sortBy=createdAt:desc`);
    if (status) query += `&status=${status}`;
    if (page) query += `&page=${page}`;
    if (depositPaymentType) query += `&depositPaymentType=${depositPaymentType}`;
    if (queries) query += `&${queries}`;

    const response = await get(query, getHeaders());
    return response.data;
  }
);

export const fetchDepositById = createAsyncThunk(
  'wallet/depositById',
  async ({ page, limit, status, depositPaymentType, userId }) => {
    const response = await get(
      wrapBaseApi(
        `${BASE_URL}/wallet/admin/deposit?userId=${userId}&limit=${limit}&page=${page}&depositPaymentType=${depositPaymentType}&status=${status}&sortBy=createdAt:desc`
      ),
      getHeaders()
    );
    return response.data;
  }
);

export const fetchCryptoWalletDeposit = createAsyncThunk('wallet/cryptoDeposit', async ({ page, limit, unit }) => {
  let query = wrapBaseApi(
    `${BASE_URL}/wallet/api/v2/admin/transactions/all?subjectOfTransaction=external_credit&limit=${
      limit || 10
    }&sortBy=createdAt:desc&populateUser=true`
  );

  if (page) query += `&page=${page}`;

  if (unit) query += `&unit=${unit}`;

  const response = await get(query, getHeaders());
  return response.data;
});

export const fetchCryptoWalletDepositByUserId = createAsyncThunk(
  'wallet/cryptoDepositById',
  async ({ page, limit, unit, userId }) => {
    let query = wrapBaseApi(
      `${BASE_URL}/wallet/api/v2/admin/transactions/all?subjectOfTransaction=external_credit&limit=${
        limit || 10
      }&page=${page}&unit=${unit}&userId=${userId}&sortBy=createdAt:desc`
    );
    const response = await get(query, getHeaders());
    return response.data;
  }
);

export const fetchAllWithdrawal = createAsyncThunk(
  'wallet/withdraw',
  async ({ page, limit, withdrawalType, status, id, queries }) => {
    let URL = wrapBaseApi(`${BASE_URL}/wallet/admin/withdrawal/all?limit=${limit || 10}&sortBy=createdAt:desc`);

    if (id) URL += `&_id=${id}`;
    if (page) URL += `&page=${page}`;
    if (withdrawalType) URL += `&withdrawalType=${withdrawalType}`;
    if (status) URL += `&status=${status}`;
    if (queries) URL += `&${queries}`;

    const response = await get(URL, getHeaders());
    return response.data;
  }
);

export const fetchSingleWithdrawalData = createAsyncThunk(
  'wallet/singleWithdrawalData',
  async ({ page, limit, withdrawalType, status, id, queries, userId }) => {
    let URL = wrapBaseApi(
      `${BASE_URL}/wallet/admin/withdrawal/all?limit=${limit || 10}&sortBy=createdAt:desc&userId=${userId}`
    );

    if (id) URL += `&_id=${id}`;
    if (page) URL += `&page=${page}`;
    if (withdrawalType) URL += `&withdrawalType=${withdrawalType}`;
    if (status) URL += `&status=${status}`;
    if (queries) URL += `&${queries}`;

    const response = await get(URL, getHeaders());
    return response.data;
  }
);

export const fetchUserTransaction = createAsyncThunk(
  'wallet/userTransaction',
  async ({ userId, limit, page, unit, type, customType }) => {
    let URL = wrapBaseApi(`${BASE_URL}/wallet/api/v2/admin/transactions/user/${userId}?limit=${limit}&page=${page}`);

    if (unit) URL += `&unit=${unit}`;
    if (type) URL += `&type=${type}`;
    if (customType) URL += `&customType=${customType}`;

    const response = await get(URL, getHeaders());
    return response.data;
  }
);

const walletSlice = createSlice({
  name: 'wallet',

  initialState,

  reducers: {
    getWithdrawalType: (state, { payload }) => {
      state.withdrawalType.type = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAllWallet.fulfilled, (state, { payload }) => {
      state.wallet.status = 'fulfilled';
      state.wallet.data = payload.data;
    });

    builder.addCase(fetchAllWallet.pending, (state) => {
      state.wallet.status = 'loading';
    });

    builder.addCase(fetchAllWallet.rejected, (state) => {
      state.wallet.status = 'error';
    });

    // Single Wallet
    builder.addCase(fetchUserWallet.fulfilled, (state, { payload }) => {
      state.singleUserWallet.status = 'fulfilled';
      state.singleUserWallet.data = payload.data;
    });

    builder.addCase(fetchUserWallet.pending, (state) => {
      state.singleUserWallet.status = 'loading';
    });

    builder.addCase(fetchUserWallet.rejected, (state) => {
      state.singleUserWallet.status = 'error';
    });

    // Wallet Deposit
    builder.addCase(fetchWalletDeposit.fulfilled, (state, { payload }) => {
      state.deposit.status = 'fulfilled';
      state.deposit.data = payload.data;
      state.deposit.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchWalletDeposit.pending, (state) => {
      state.deposit.status = 'loading';
    });

    builder.addCase(fetchWalletDeposit.rejected, (state) => {
      state.deposit.status = 'error';
    });

    // Deposit by ID
    builder.addCase(fetchDepositById.fulfilled, (state, { payload }) => {
      state.singleDeposit.status = 'fulfilled';
      state.singleDeposit.data = payload.data;
    });

    builder.addCase(fetchDepositById.pending, (state) => {
      state.singleDeposit.status = 'loading';
    });

    builder.addCase(fetchDepositById.rejected, (state) => {
      state.singleDeposit.status = 'error';
    });

    // All withdrawals
    builder.addCase(fetchAllWithdrawal.fulfilled, (state, { payload }) => {
      state.withdraw.status = 'fulfilled';
      state.withdraw.data = payload.data;
    });

    builder.addCase(fetchAllWithdrawal.pending, (state) => {
      state.withdraw.status = 'loading';
    });

    builder.addCase(fetchAllWithdrawal.rejected, (state) => {
      state.withdraw.status = 'error';
    });

    // Single withdrawals
    builder.addCase(fetchSingleWithdrawalData.fulfilled, (state, { payload }) => {
      state.singleWithdraw.status = 'fulfilled';
      state.singleWithdraw.data = payload.data;
    });

    builder.addCase(fetchSingleWithdrawalData.pending, (state) => {
      state.singleWithdraw.status = 'loading';
    });

    builder.addCase(fetchSingleWithdrawalData.rejected, (state) => {
      state.singleWithdraw.status = 'error';
    });

    // Crypto Deposit
    builder.addCase(fetchCryptoWalletDeposit.fulfilled, (state, { payload }) => {
      state.cryptoDeposit.status = 'fulfilled';
      state.cryptoDeposit.data = payload.data;
      state.cryptoDeposit.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchCryptoWalletDeposit.pending, (state) => {
      state.cryptoDeposit.status = 'loading';
    });

    builder.addCase(fetchCryptoWalletDeposit.rejected, (state) => {
      state.cryptoDeposit.status = 'error';
    });

    // Crypto Deposit By userid
    builder.addCase(fetchCryptoWalletDepositByUserId.fulfilled, (state, { payload }) => {
      state.singleCryptoDeposit.status = 'fulfilled';
      state.singleCryptoDeposit.data = payload.data;
    });

    builder.addCase(fetchCryptoWalletDepositByUserId.pending, (state) => {
      state.singleCryptoDeposit.status = 'loading';
    });

    builder.addCase(fetchCryptoWalletDepositByUserId.rejected, (state) => {
      state.singleCryptoDeposit.status = 'error';
    });

    // User Transaction
    builder.addCase(fetchUserTransaction.fulfilled, (state, { payload }) => {
      state.userTranasction.status = 'fulfilled';
      state.userTranasction.data = payload.data;
    });

    builder.addCase(fetchUserTransaction.pending, (state) => {
      state.userTranasction.status = 'loading';
    });

    builder.addCase(fetchUserTransaction.rejected, (state) => {
      state.userTranasction.status = 'error';
    });
  },
});

export const { actions, reducer: WalletReducer } = walletSlice;
export const { getWithdrawalType } = actions;
