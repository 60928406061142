import { Center, Image, Text } from '@chakra-ui/react';

const EarnCard = ({ icon, count, coin }) => {
  return (
    <Center
      flexDir="column"
      maxWidth="223px"
      width="100%"
      boxShadow="0px 2px 8px rgba(229, 229, 229, 0.47)"
      borderRadius="10px"
      padding="31px 0 26px"
    >
      <Center
        boxShadow="0px 0.79px 3.15px rgba(229, 229, 229, 0.47)"
        borderRadius="10px"
        width="39px"
        height="35px"
      >
        <Image src={icon} alt="coin" width="24px" height="24px" />
      </Center>

      <Center flexDir="column">
        <Text margin="9px 0" color="#797A7A" fontSize="14px">
          Total amount of Coins
        </Text>

        <Text fontWeight="700" fontSize="16px" textTransform="uppercase">
          {count} {coin}
        </Text>
      </Center>
    </Center>
  );
};

export default EarnCard;
