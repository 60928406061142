import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  VStack,
  Text,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { Button, Input, ChallengeIconUpload } from 'components';
import { useState } from 'react';
import { useUpdateGameDetailsMutation } from 'store/services/gaming.service';
import { uploadFile } from 'utils';

const UpdateGameDetails = ({ name, description, logoUrl, maxPoint, id }) => {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const toast = useToast();

  const [state, setState] = useState({
    name,
    logoUrl,
    maxPoint,
    description,
  });

  const [selectedFile, setSelectedFile] = useState();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const [logoLoading, setLogoLoading] = useState(false);
  const [_updateGame, { isLoading }] = useUpdateGameDetailsMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { description, maxPoint, name } = state;
    selectedFile ? setLogoLoading(true) : setLogoLoading(false);

    try {
      const _logoUrl = selectedFile ? (await uploadFile(selectedFile, selectedFile.name)).location : logoUrl;

      const response = await _updateGame({
        _id: id,
        logoUrl: _logoUrl,
        maxPoint,
        name,
        description,
      }).unwrap();

      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response?.message,
        status: 'success',
        duration: 7000,
        isClosable: true,
      });

      onClose();
      setLogoLoading(false);
    } catch (error) {
      setLogoLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.data?.error,
        status: 'error',
        duration: 7000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        minH='48px'
        bg='#fff'
        border='1px solid #0D4740'
        color='#0D4740'
        onClick={onOpen}
      >
        Edit Game
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='520px'
          padding='32px 0'
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
            padding='0'
          >
            Edit{' '}
            <Text
              as='span'
              textTransform='capitalize'
            >
              {name}
            </Text>
          </ModalHeader>

          <ModalBody bg='#fff'>
            <form onSubmit={handleSubmit}>
              <VStack
                spacing='8px'
                alignItems='revert-layer'
                mb='24px'
              >
                <Input
                  label='Title'
                  name='name'
                  placeholder='Description'
                  labelSize='16px'
                  type='text'
                  defaultValue={name}
                  onChange={handleChange}
                  isRequired
                />

                <Input
                  label='Description'
                  name='description'
                  placeholder='Description'
                  labelSize='16px'
                  type='text'
                  defaultValue={description}
                  onChange={handleChange}
                  isRequired
                />

                <Input
                  label='Max Point'
                  name='maxPoint'
                  placeholder='maxPoint'
                  labelSize='16px'
                  step='any'
                  type='number'
                  defaultValue={maxPoint}
                  onChange={handleChange}
                  isRequired
                />

                <ChallengeIconUpload
                  defaultPreview={logoUrl}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                />
              </VStack>

              <Button
                type='submit'
                isLoading={isLoading || logoLoading}
                disabled={isLoading || logoLoading}
                width='100%'
                minH='60px'
              >
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateGameDetails;
