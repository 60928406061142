import { Box, Flex, Heading } from '@chakra-ui/react';
import { PartnersCommissionCard } from 'components';
import BTCPNG from 'assets/png/btc.png';
import ETHPNG from 'assets/png/eth.png';
import XLMPNG from 'assets/png/xlm.png';
import XRPPNG from 'assets/png/xrp.png';
import CELOPNG from 'assets/png/celo.png';

const PartnersCommission = () => {
  return (
    <Box
      boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
      bg='#fff'
      borderRadius='20px'
      mt='28px'
      padding='22px'
    >
      <Heading
        fontFamily='PolySans'
        fontWeight='600'
        fontSize='18px'
        mb='18px'
      >
        Total individual Crypto value credited on trade commissions
      </Heading>

      <Flex
        gridGap='6px'
        flexWrap='wrap'
      >
        <PartnersCommissionCard
          icon={BTCPNG}
          count={'0.000345'}
          coin='BTC'
        />
        <PartnersCommissionCard
          icon={ETHPNG}
          count={'4.83'}
          coin='ETH'
        />
        <PartnersCommissionCard
          icon={XLMPNG}
          count={'0.000345'}
          coin='XLM'
        />
        <PartnersCommissionCard
          icon={XRPPNG}
          count={'0.000345'}
          coin='XRP'
        />
        <PartnersCommissionCard
          icon={CELOPNG}
          count={'0.000345'}
          coin='CELO'
        />
      </Flex>
    </Box>
  );
};

export default PartnersCommission;
