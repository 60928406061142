import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  VStack,
  useToast,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import { Button, CloseModal, Input } from 'components';
import { put } from 'utils/axiosLib';
import { fetchAllLoans } from 'store/slices/bcpl.slice';
import { useDispatch } from 'react-redux';
import { getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';

const ApproveBcplRequestModal = ({ isOpen, onClose, data }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { _id, currencyValue } = data;

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(currencyValue);

  const headers = getHeaders();

  const approveLoanRequest = async (e) => {
    e.preventDefault();

    setLoading(true);

    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/loans/process-loan/${_id}`);

    try {
      const response = await put(
        URL,
        {
          status: 'approve',
          amount: amount && Number(amount),
          force: false,
        },
        headers
      );

      if (response.data) {
        dispatch(
          fetchAllLoans({
            limit: 10,
            page: 1,
            loanStatus: 'pending',
          })
        );

        onClose();

        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      key={_id}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        maxWidth='399px'
        padding={'32px 39px'}
      >
        <Text
          fontSize='24px'
          textAlign='center'
          fontWeight='600'
        >
          Approve Loan
        </Text>

        <ModalBody p='0'>
          <Text
            color='#4E4B66'
            fontSize={'14px'}
            fontWeight={'400'}
            margin={'13px 0 28px'}
            textAlign='center'
          >
            Are you sure you want to approve this loan?
          </Text>

          <VStack
            as='form'
            onSubmit={approveLoanRequest}
            alignItems='left'
          >
            <FormControl>
              <FormLabel>Edit amount</FormLabel>
              <Input
                width='100%'
                onChange={(e) => setAmount(e.target.value)}
                name='amount'
                type='number'
                placeholder='Enter amount'
                mb='32px'
                value={amount}
                padding='13px 22px'
                _focus={{
                  borderColor: '#06C3448f',
                }}
              />
            </FormControl>

            <Flex
              justifyContent={'center'}
              flexDir='column'
            >
              <Button
                width='158px'
                margin='auto'
                type='submit'
                _hover={{
                  boxShadow: 'none',
                }}
                disabled={loading}
                isLoading={loading}
              >
                Approve
              </Button>

              <CloseModal onClick={onClose} />
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ApproveBcplRequestModal;
