import { Box, Tr, Th, Td, Table, Thead, Tbody, Text } from '@chakra-ui/react';
import { ScrollRightContainer, TableDataStatus } from 'components';
import AccordionTab from 'components/Tabs/AccordionTab';
import { Link } from 'react-router-dom';
import { formatDateToUTC, formatValuePerUnit } from 'utils';

const TransactionTooltipData = ({ data, userId }) => {
  return (
    <AccordionTab title='Transactions'>
      <Box
        px='8px'
        py='8px'
        bg='#fff'
      >
        <ScrollRightContainer maxWidth='400px'>
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Type</Th>
                <Th>Amount</Th>
                <Th>Balance Before</Th>
                <Th>Balance After</Th>
                <Th>Description</Th>
              </Tr>
            </Thead>

            <Tbody fontSize={{ base: '12px', md: '12px  ' }}>
              {data.transactions.map(
                ({ _id, unit, operation, balanceBefore, balanceAfter, createdAt, description, value: txValue }, i) => {
                  return (
                    <Tr key={_id}>
                      <Td>{formatDateToUTC(createdAt)}</Td>

                      <Td>
                        <TableDataStatus
                          status={String(operation).toLowerCase()}
                          textTransform='capitalize'
                        />
                      </Td>

                      <Td>
                        {txValue?.$numberDecimal ? formatValuePerUnit(Number(txValue?.$numberDecimal), unit) : 'N/A'}{' '}
                        {String(unit).toUpperCase()}
                      </Td>

                      <Td>
                        {balanceBefore ? formatValuePerUnit(balanceBefore, unit) : 'N/A'} {String(unit).toUpperCase()}
                      </Td>

                      <Td>
                        {balanceAfter ? formatValuePerUnit(balanceAfter, unit) : 'N/A'} {String(unit).toUpperCase()}
                      </Td>

                      <Td>{description}</Td>
                    </Tr>
                  );
                }
              )}
            </Tbody>
          </Table>
        </ScrollRightContainer>

        {data.totalCount > 3 && (
          <Text
            textAlign='center'
            textDecor='underline'
            mt='8px'
            _hover={{ fontWeight: '500' }}
          >
            <Link to={`/dashboard/users/${userId}?transactions`}>See All</Link>
          </Text>
        )}
      </Box>
    </AccordionTab>
  );
};

export default TransactionTooltipData;
