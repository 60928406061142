import { Box } from '@chakra-ui/react';
import { acceptedSheetFormat } from 'utils';

const ReUploadBatchFile = ({ onChange }) => {
  return (
    <Box>
      <Box
        as="input"
        maxWidth="108px"
        width="100%"
        type="file"
        onChange={onChange}
        accept={acceptedSheetFormat}
        color="transparent"
        css={{
          '::-webkit-file-upload-button': {
            visibility: 'hidden',
          },
          ':before': {
            content: "'Re-upload'",
            display: 'inline-block',
            fontSize: '14px',
            fontWeight: '700',
            color: '#0D4740',
            textAlign: 'center',
            textDecoration: 'underline',
            padding: '10px 19px',
            cursor: 'pointer',
            border: '1px solid #0D4740',
            borderRadius: '10px',
          },
        }}
      />
    </Box>
  );
};

export default ReUploadBatchFile;
