import { Box } from '@chakra-ui/react';

const CommandTab = ({ title }) => {
  return (
    <Box
      boxShadow='inset 0 -4px 0 #303131'
      bg='#41434A'
      color='#fff'
      padding='4px 6px'
      fontWeight='600'
      borderRadius='4px'
      textTransform='lowercase'
    >
      / &nbsp; {title}
    </Box>
  );
};

export default CommandTab;
