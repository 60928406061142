import {
  Modal,
  ModalOverlay,
  ModalContent,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ModalBody,
  Box,
  ModalHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { ActionButton, Loader, ScrollRightContainer } from 'components';
import { formatDateToUTC } from 'utils';

const BanHistoryModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    data: { data },
    status,
    errorMessage,
  } = useSelector((state) => state.allUsers.banHistory);

  return (
    <Box>
      <ActionButton onClick={onOpen}>Ban History</ActionButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius='16px'
          maxWidth='720px'
        >
          <ModalHeader
            padding='32px 0'
            fontWeight='600'
            textAlign='center'
          >
            Ban History
          </ModalHeader>

          {status === 'error' && <Text>{errorMessage}</Text>}

          {status === 'loading' && <Loader />}

          {status === 'fulfilled' && (
            <Box
              bg='transparent'
              borderBottomRadius='16px'
              paddingBottom='32px'
              // width="720px"
            >
              <ScrollRightContainer>
                <ModalBody width='720px'>
                  <Table size='sm'>
                    <Thead>
                      <Tr>
                        <Th>Action</Th>
                        <Th>Admin Email</Th>
                        <Th>Reason</Th>
                        <Th>Date/Time</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {data.history.map(({ action, adminEmail, createdAt, reason, _id }) => {
                        return (
                          <Tr key={_id}>
                            <Td textTransform='capitalize'>{action}</Td>
                            <Td textTransform='lowercase'>{adminEmail}</Td>
                            <Td>{reason}</Td>
                            <Td>{formatDateToUTC(createdAt)}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </ModalBody>
              </ScrollRightContainer>
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BanHistoryModal;
