import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Table, Thead, Tbody, Tr, Th, Td, useDisclosure } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import { Loader, ViewMore, PaginationWrapper, ScrollRightContainer, WalletCryptModal } from 'components';
import { fetchCryptoWalletDeposit } from 'store/slices/wallet.slice';
import { formatDateToUTC } from 'utils';

const WalletCryptoDepositTable = ({ size, coinType }) => {
  const dispatch = useDispatch();
  const { data, status, totalCount } = useSelector((state) => state.wallet.cryptoDeposit);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: { currentPage: 1, pageSize: 10 },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedData, setSelectedData] = useState({});
  const [actionValue, setActionValue] = useState('');

  const handleOpen = (data) => {
    onOpen();
    setSelectedData(data);
    setActionValue('');
  };

  useEffect(() => {
    dispatch(
      fetchCryptoWalletDeposit({
        page: currentPage,
        limit: size,
        unit: coinType,
      })
    );
  }, [coinType, currentPage, dispatch, size]);

  const gotoUserdetails = (id) => {
    window.location.href = `/dashboard/users/${id}`;
  };

  return (
    <Box>
      {status !== 'fulfilled' && (
        <Box margin='8px 0'>
          <Loader />
        </Box>
      )}

      <ScrollRightContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Name</Th>
              <Th>Amount</Th>
              <Th>Date & Time</Th>
              <Th>Description</Th>
              <Th>Type</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.transactions.map(
                (
                  {
                    _id,
                    userId,
                    userData,
                    createdAt,
                    addressRef,
                    description,
                    type,
                    unit,
                    value,
                    balanceAfter,
                    balanceBefore,
                  },
                  index
                ) => {
                  const modalData = {
                    _id,
                    userId,
                    createdAt,
                    addressRef,
                    description,
                    type,
                    unit,
                    value,
                    balanceAfter,
                    balanceBefore,
                  };

                  const fullName = `${userData?.firstName} ${userData?.lastName}`;
                  return (
                    <Tr key={_id}>
                      <Td>{index + 1}</Td>

                      <Td
                        cursor='pointer'
                        onClick={() => gotoUserdetails(userId)}
                      >
                        {fullName}
                      </Td>

                      <Td textTransform='uppercase'>
                        {value?.$numberDecimal.toLocaleString()} {unit}
                      </Td>

                      <Td>{formatDateToUTC(createdAt)}</Td>

                      <Td textTransform='capitalize'>{description}</Td>

                      <Td textTransform='capitalize'>{type}</Td>

                      <Td>
                        <ViewMore onClick={() => handleOpen(modalData)}>View more</ViewMore>
                      </Td>
                    </Tr>
                  );
                }
              )}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={size}
      />

      <WalletCryptModal
        isOpen={isOpen}
        onClose={onClose}
        data={selectedData}
        actionValue={actionValue}
        currentPage={currentPage}
      />
    </Box>
  );
};

export default WalletCryptoDepositTable;
