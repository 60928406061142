import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ActionButton, Button } from 'components';
import { useMemo } from 'react';
import { useProcessWidgetPayoutMutation } from 'store/services/integrations.service';

const ProcessAllPayout = ({ data }) => {
  const toast = useToast();

  const [processWidgetPayout, { isLoading }] = useProcessWidgetPayoutMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const transferId = useMemo(() => {
    const result = data
      ?.filter(
        ({ direction, transferStatus, payoutStarted, payoutId }) =>
          direction === 'out' && transferStatus === 'TransferReceivedCryptoFunds' && !payoutStarted && !payoutId
      )
      ?.map(({ _id }) => _id);

    return result;
  }, [data]);

  const processPayout = async (id) => {
    try {
      const response = await processWidgetPayout({
        id,
      }).unwrap();

      if (response.data) {
        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: 'Process payout',
          status: 'success',
          duration: 18000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: 'bottom-right',
        title: 'Error',
        description: error?.data?.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async () => {
    await Promise.all(transferId.map((id) => processPayout(id)));
  };

  return (
    <>
      {transferId?.length >= 1 && <ActionButton onClick={onOpen}>Process All Payout</ActionButton>}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='450px'
          padding='8px 0 32px'
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
          >
            Process All Payout
          </ModalHeader>

          <ModalBody bg='#fff'>
            <Box textAlign='center'>Are you sure you want to process all transactions</Box>

            <Center mt='42px'>
              <Button
                onClick={handleSubmit}
                minH='55px'
                isLoading={isLoading}
                disabled={isLoading}
              >
                Yes
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProcessAllPayout;
