import { Box } from '@chakra-ui/react';

const TableBoxWrapper = ({ children, ...props }) => {
  return (
    <Box
      borderRadius='10px'
      padding='16px'
      bg='#FCFDFD'
      boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
      minH='951px'
      {...props}
    >
      {children}
    </Box>
  );
};

export default TableBoxWrapper;
