import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Box,
  useToast,
  Center,
  Flex,
  Checkbox,
} from '@chakra-ui/react';
import { ActionButton, Button, CloseModal, Input, Select } from 'components';
import { BASE_URL } from 'constant';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanks } from 'store/slices/bank.slice';
import { post } from 'utils/axiosLib';

const AddBankDetailsModal = ({ userData, headers }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = useSelector((state) => state.allBanks.banks);
  const [loading, setLoading] = useState(false);
  const [force, setForce] = useState(false);

  const [state, setState] = useState({
    bankCode: '',
    bankAccountNumber: '',
    firstName: '',
    lastName: '',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const userCountry = useMemo(() => {
    const countryList = {
      nigeria: 'ng',
      ghana: 'gh',
      // kenya: 'ke',
    };
    return countryList[userData?.country?.toLowerCase()];
  }, [userData]);

  const handleBankDetails = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = `${BASE_URL}/admin/addBankDetails`;

    const { bankAccountNumber, bankCode, firstName, lastName } = state;

    try {
      const response = await post(
        URL,
        {
          force,
          bankCode,
          bankAccountNumber,
          country: 'ng',
          userPayload: {
            firstName,
            email: userData.email,
            userId: userData.id,
            lastName,
          },
        },
        headers
      );

      if (response.data) {
        setLoading(false);

        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: Object.values(error?.error).length === 0 ? 'Error' : error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchBanks(userCountry));
  }, [dispatch, userCountry]);

  return (
    <Box>
      <ActionButton onClick={onOpen}>Add Bank</ActionButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          bg='#fff'
          maxWidth='468px'
          padding='20px 20px'
        >
          <ModalHeader
            padding='24px 30px'
            fontWeight='600'
            borderTopRadius='16px'
            textAlign='center'
          >
            Add Bank
          </ModalHeader>

          <form onSubmit={handleBankDetails}>
            <Box mb='16px'>
              <Input
                label='First Name'
                name='firstName'
                placeholder='First name'
                onChange={handleChange}
                isRequired
              />
            </Box>

            <Box mb='16px'>
              <Input
                onChange={handleChange}
                label='Last Name'
                name='lastName'
                placeholder='Last name'
                isRequired
              />
            </Box>

            <Box mb='16px'>
              <Box
                as='label'
                display='block'
                fontSize='12px'
                fontWeight='400'
                mb='10px'
                color='#303131'
                htmlFor='bankCode'
              >
                Select Bank
              </Box>

              <Select
                onChange={handleChange}
                id='bankCode'
                minH='60px'
                bg='#F6F8F7'
                fontSize='14px'
                isRequired
                name='bankCode'
              >
                {data.bankPayloads
                  ? data.bankPayloads.map(({ bankName, bankCode }, index) => {
                      return (
                        <option
                          key={index}
                          value={bankCode}
                        >
                          {bankName}
                        </option>
                      );
                    })
                  : data.ghanaBankPayloads.map(({ bankName, bankCode }, index) => {
                      return (
                        <option
                          key={index}
                          value={bankCode}
                        >
                          {bankName}
                        </option>
                      );
                    })}
                {/* {data.bankPayloads.map(({ bankName, bankCode }, index) => {
                  return (
                    <option
                      key={index}
                      value={bankCode}
                    >
                      {bankName}
                    </option>
                  );
                })} */}
              </Select>
            </Box>

            <Box mb='16px'>
              <Input
                onChange={handleChange}
                label='Account Number'
                name='bankAccountNumber'
                placeholder='Bank account number'
                isRequired
              />
            </Box>

            <Flex
              mb='32px'
              gridGap='1rem'
            >
              <Checkbox
                colorScheme='green'
                onChange={() => setForce(!force)}
                name='force'
                value={force}
                checked={force}
                defaultChecked={force}
              >
                Force
              </Checkbox>
            </Flex>

            <Center mt='32x'>
              <Button
                minWidth='200px'
                type='submit'
                disabled={loading}
                isLoading={loading}
              >
                Submit
              </Button>
            </Center>
          </form>

          <CloseModal onClick={onClose} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddBankDetailsModal;
