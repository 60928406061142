import { Text } from '@chakra-ui/react';

const CurrencyFormat = ({ currency }) => {
  const currencyValue = () => {
    switch (currency) {
      case 'ngn':
        return <>&#x20A6;</>;

      case 'ghs':
        return <>&#8373;</>;

      case 'kes':
        return 'KSh';

      case 'xaf':
        return 'XAF';

      case 'xof':
        return 'XOF';

      case 'cdf':
        return 'CDF';

      default:
        break;
    }
  };

  return <Text as='span'>{currencyValue()}</Text>;
};

export default CurrencyFormat;
