import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { BackArrowSVG } from 'assets';
import { Link } from 'react-router-dom';

const HistoryTab = ({ firstText, secondText, url, ...props }) => {
  return (
    <Box
      mb='51px'
      {...props}
    >
      <Link
        to={url}
        style={{ width: '100px', display: 'block' }}
      >
        <Flex
          alignItems='center'
          gridGap='7px'
          cursor='pointer'
        >
          <BackArrowSVG />
          <Text
            color='#041815'
            textDecoration='underline'
            fontSize='14px'
            fontFamily='PolySans'
            lineHeight='17px'
          >
            Back
          </Text>
        </Flex>
      </Link>

      <Heading
        color='#041815'
        fontSize={{ base: '18px', md: '24px' }}
        fontWeight='700'
        lineHeight={{ base: '18px', md: '24px' }}
        margin='28px 0 15px'
      >
        {firstText}
      </Heading>

      <Heading
        color='#041815'
        fontSize={{ base: '18px', md: '24px' }}
        fontWeight='500'
        lineHeight={{ base: '18px', md: '24px' }}
      >
        {secondText}
      </Heading>
    </Box>
  );
};

export default HistoryTab;
