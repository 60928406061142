import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'constant';
import { getHeaders, wrapBaseApi } from 'utils';
import { get } from 'utils/axiosLib';

const initialState = {
  liens: {
    status: 'idle',
    data: [],
    totalCount: 0,
  },
};

export const fetchLiens = createAsyncThunk('liens/all', async ({ page, limit, id }) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/wallet/admin/liens?page=${page}&limit=${limit}&userId=${id}`),
    getHeaders()
  );
  return response.data;
});

const liensSlice = createSlice({
  name: 'liens',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchLiens.pending, (state) => {
      state.liens.status = 'loading';
    });

    builder.addCase(fetchLiens.fulfilled, (state, { payload }) => {
      state.liens.status = 'fulfilled';
      state.liens.data = payload.data.data;
      state.liens.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchLiens.rejected, (state) => {
      state.liens.status = 'error';
    });
  },
});

export const { actions, reducer: liensReducer } = liensSlice;
