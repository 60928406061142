import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { BoxInput, FilterSelect, Loader, SimilarityScore } from 'components';
import { useUpdateKycMutation } from 'store/services/request.service';
import { calculateAge, formatDateToUTC } from 'utils';

const KycLevelTwo = ({ document, id }) => {
  const toast = useToast();
  const [_updateKYC, { isLoading }] = useUpdateKycMutation();

  const handleDocType = async (e) => {
    const response = await _updateKYC({
      docType: e.target.value,
      documentId: id,
    });

    if (response?.data) {
      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response?.data?.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }

    if (response?.error) {
      toast({
        position: 'bottom-right',
        title: 'Error',
        description: response?.error?.data?.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <Flex
      gridGap={{ base: '16px', md: '130px' }}
      flexDir={{ sm: 'column', md: 'row' }}
    >
      <Box>
        <BoxInput
          label='Name'
          value={document?.userDetail.fullName}
          textTransform='capitalize'
          link={`/dashboard/users/${document?.userDetail?.userId}`}
        />

        <BoxInput
          label='Email address'
          value={document?.userDetail?.email}
          textTransform='lowercase'
        />

        <BoxInput
          label='Date/time of verification'
          value={formatDateToUTC(document?.createdAt)}
        />

        <BoxInput
          label='Level of verification'
          value={document?.kycLevel}
          textTransform='capitalize'
        />

        <BoxInput
          label='Status'
          textTransform='capitalize'
          value={document?.status}
        />

        {document?.status === 'denied' && (
          <>
            <BoxInput
              label='Denied By'
              textTransform='capitalize'
              value={document?.deniedBy?.fullName}
            />

            <BoxInput
              label='Reason'
              value={document?.reason}
            />
          </>
        )}

        <BoxInput
          label='ID Type'
          height='72px'
          value={
            <>
              <FilterSelect
                defaultValue={document?.docType}
                onChange={handleDocType}
              >
                <option value='voters_card'>Voter's Card</option>
                <option value='nimc_slip'>NIMC Slip</option>
                <option value='kra_certificate'>KRA Certificate</option>
                <option value='driver_licence'>Driver Licence</option>
                <option value='national_id'>National ID</option>
                <option value='international_passport'>International Passport</option>
                <option value='school_id_card'>School ID Card</option>
                <option value='tax_card'>Tax Card</option>
                <option value='work_id'>Work ID</option>
                <option value='marriage_certificate'>Marriage Certificate</option>
                <option value='birth_certificate'>Birth Certificate</option>
                <option value='court_affidavit'>Court Affidavit</option>
                <option value='bank_statement'>Bank Statement</option>
              </FilterSelect>
            </>
          }
        >
          {isLoading && <Loader />}
        </BoxInput>

        <BoxInput
          label='Name of ID'
          value={document?.nameOnId}
          textTransform='capitalize'
        />

        <BoxInput
          label='ID card number'
          value={document?.idNumber}
        />

        <BoxInput
          label='NIN number'
          value={document?.ninNumber}
        />

        <BoxInput
          label='Date of birth'
          value={document?.dateOfBirth}
        />

        <BoxInput
          label='Age'
          value={calculateAge(new Date(document?.dateOfBirth))}
        />
      </Box>

      <Box>
        <Text fontWeight='600'>Upload</Text>

        <Flex
          gridGap='1rem'
          mb='1rem'
        >
          <Text>Document:</Text>

          <Text
            color='brand.primary'
            as='a'
            href={document?.docLink ? document.docLink : document.supportingDocLink}
            target='_blank'
          >
            Click to view
          </Text>
        </Flex>

        <Flex
          gridGap='1rem'
          mb='1rem'
        >
          <Text>Selfie:</Text>

          <Text
            color='brand.primary'
            as='a'
            href={document?.selfieLink}
            target='_blank'
          >
            Click to view
          </Text>
        </Flex>

        {document?.backDocLink && (
          <Flex
            gridGap='1rem'
            mb='1rem'
          >
            <Text>Document Back Side:</Text>

            <Text
              color='brand.primary'
              as='a'
              href={document?.backDocLink}
              target='_blank'
            >
              Click to view
            </Text>
          </Flex>
        )}

        <Box>{document?.userDetail && <SimilarityScore userDetail={document.userDetail} />}</Box>
      </Box>
    </Flex>
  );
};

export default KycLevelTwo;
