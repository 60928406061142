import { ls, constant } from 'utils';

const autoSignOut = (status, error) => {
  if (
    (status === 403 &&
      error &&
      String(error).toLowerCase().includes('unauthorized')) ||
    (error && String(error).toLowerCase().includes('token invalid'))
  ) {
    ls.clear();
    // localStorage.clear();

    const redirectUrl = window.location.pathname;

    if (redirectUrl && redirectUrl !== constant.PAGES.LOGIN) {
      window.location.replace(
        `${constant.PAGES.LOGIN}?redirectUrl=${redirectUrl}`
      );
    } else {
      window.location.replace(constant.PAGES.LOGIN);
    }
  }
};

export default autoSignOut;
