import { Box, Flex, Text } from '@chakra-ui/react';
import { BoxInput } from 'components';
import { formatDateToUTC } from 'utils';

const KycLevelThree = ({ document }) => {
  return (
    <>
      <Flex
        gridGap={{ base: '16px', md: '130px' }}
        flexDir={{ sm: 'column', md: 'row' }}
      >
        <Box>
          <BoxInput
            label='Name'
            value={document?.userDetail.fullName}
            textTransform='capitalize'
            link={`/dashboard/users/${document?.userDetail?.userId}`}
          />

          <BoxInput
            label='Email address'
            value={document?.userDetail.email}
          />

          <BoxInput
            label='Date/time of verification'
            value={formatDateToUTC(document?.createdAt)}
          />

          <BoxInput
            label='Level of verification'
            textTransform='capitalize'
            value={document?.kycLevel}
          />

          <BoxInput
            label='Status'
            textTransform='capitalize'
            value={document?.status}
          />

          {document?.status === 'denied' && (
            <>
              <BoxInput
                label='Denied By'
                textTransform='capitalize'
                value={document?.deniedBy?.fullName}
              />

              <BoxInput
                label='Reason'
                value={document?.reason}
              />
            </>
          )}

          <Text
            fontWeight='600'
            margin='26px 0 10px'
          >
            Bank verification
          </Text>

          <BoxInput
            label='Bank'
            value={document?.bankId?.bankName ? document?.bankId?.bankName : 'N/A'}
          />

          <BoxInput
            label='Branch'
            value={document?.branchName ? document?.branchName : 'N/A'}
          />

          <BoxInput
            label='Account name'
            value={document?.bankId?.bankAccountName ? document?.bankId?.bankAccountName : 'N/A'}
          />

          <BoxInput
            label='Account number'
            value={document?.bankId?.bankAccountNumber ? document?.bankId?.bankAccountNumber : 'N/A'}
          />
        </Box>

        <Box>
          <Text
            fontWeight='600'
            mb='19px'
            mt='0'
          >
            Address verification
          </Text>

          <BoxInput
            label='Street and number'
            value={`${document?.homeAddress ? `${document?.homeAddress},` : ''} ${document?.street}`}
          />

          <BoxInput
            label='Zip code'
            value={`${document?.zipCode}`}
          />

          <BoxInput
            label='Town/City'
            value={`${document?.city}`}
            textTransform='capitalize'
          />

          <BoxInput
            label='State/District'
            value={`${document?.state}`}
            textTransform='capitalize'
          />

          <Box mt='1rem'>
            <Text fontWeight='semibold'>Upload</Text>

            <Flex
              gridGap='1rem'
              mb='1rem'
            >
              <Text>Proof of Residency</Text>

              <Text
                as='a'
                href={document?.proofOfResidencyLink}
                target='_blank'
              >
                Click to view
              </Text>
            </Flex>

            <Flex gridGap='1rem'>
              <Text>Bank statement</Text>

              <Text
                as='a'
                href={document?.bankStatementLink}
                target='_blank'
              >
                Click to view
              </Text>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default KycLevelThree;
