import { useRef, useState } from 'react';
import { Box, Center, Flex, Heading, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Recaptcha from 'react-google-recaptcha';
import { Button, Input, AuthImage, TwoFAModal, OTPModal } from 'components';
import { useAuth } from 'hooks';
import { post } from 'utils/axiosLib';
import { constant, validateEmail } from 'utils';
import { BitcoinAuthSVG, BitmamaAdminLogoSVG, RightArrowSVG } from 'assets';
import { BASE_URL } from 'constant';

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: _isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure();

  const [twoFaToken, setTwoFaToken] = useState('');

  const location = useLocation();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const recaptchaInstanceRef = useRef();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const [humanKey, setHumanKey] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (navigator.onLine === true) {
        const captcha = await recaptchaInstanceRef.current?.executeAsync();
        setHumanKey(captcha);

        const { email, password } = state;

        const reqData = {
          email,
          password,
          humanKey: captcha,
        };

        const URL = `${BASE_URL}/v2/admin/auth/signin`;

        const response = await post(URL, { ...reqData, humanKey: captcha });

        if (response.data.data?.label === 'REQUIRES_2FA_CODE') {
          setTwoFaToken(response.data.data.token);

          recaptchaInstanceRef.current?.reset();

          setLoading(false);

          onOpen();
        }

        if (response.data.data?.label === 'REQUIRES_OTP_CODE') {
          recaptchaInstanceRef.current?.reset();
          setLoading(false);

          _onOpen();
        }

        if (
          response.data &&
          response.data.data.label !== 'REQUIRES_2FA_CODE' &&
          response.data.data.label !== 'REQUIRES_OTP_CODE'
        ) {
          setLoading(false);

          const { data } = response.data;

          login(data.token, data.exp, true);

          if (location.search === '') {
            window.location.replace(constant.PAGES.HOME);
          }

          if (location.search === '?redirectUrl=/') {
            window.location = constant.PAGES.HOME;
          }

          if (location.search.includes('?redirectUrl')) {
            const path = location.search.split('=');

            window.location.replace(path[1]);
          }
        }
      } else {
        toast({
          position: 'bottom-right',
          title: 'Network Error!!',
          description: 'Please check your network connection',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      recaptchaInstanceRef.current?.reset();

      if (error?.error === ('email is not verified' || 'phone is not verified')) {
        login(error.data.token);

        setTimeout(() => {
          navigate(constant.PAGES.VERIFY_EMAIL_PASSWORD, {
            replace: true,
          });
        }, 500);
      }

      if (error?.error.toLowerCase() === 'provide the otp sent to your email') {
        _onOpen();
      }

      toast({
        position: 'bottom-right',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      mt={{ sm: '-2rem' }}
      gridGap='2rem'
      height='100vh'
    >
      <AuthImage />

      <Box
        maxWidth='523px'
        margin='0 auto'
        width='100%'
        pt={{ base: '120px', md: '185px' }}
        px={{ base: '16px', md: '0' }}
      >
        <Box>
          <BitmamaAdminLogoSVG />
        </Box>

        <Flex flexDirection='column'>
          <Flex
            gridGap='2px'
            margin='50px 0 20px'
            alignItems='center'
          >
            <Heading
              fontWeight='600'
              fontSize={{ base: '1.5rem', md: '2rem' }}
            >
              Glad to have you back
            </Heading>

            <Box>
              <BitcoinAuthSVG />
            </Box>
          </Flex>

          <Text
            mb={{ base: '32px', md: '66px' }}
            letterSpacing='-0.5px'
            color='#797A7A'
            fontSize='18px'
          >
            Fill in your details to Log in
          </Text>

          <form onSubmit={handleSubmit}>
            <VStack
              rowGap='20px'
              alignItems='initial'
            >
              <Input
                label='Email Address'
                name='email'
                placeholder='Enter Email'
                onChange={handleChange}
              />

              <Input
                label='Password'
                name='password'
                placeholder='Enter Password'
                type='password'
                autoComplete='off'
                onChange={handleChange}
              />
            </VStack>

            <Flex
              justifyContent='right'
              margin='11px 0 58px'
            >
              <Text
                textDecoration='underline'
                fontWeight='500'
                color='brand.darkGray'
                fontSize='14px'
              >
                <Link to='/forgot-password'>Forgot Password</Link>
              </Text>
            </Flex>

            <Center>
              <Button
                isLoading={loading}
                disabled={loading || !validateEmail(state.email) || !(state.password.length >= 3)}
                maxW='399px'
                width='100%'
                type='submit'
                fontWeight='700'
                lineHeight='19px'
              >
                <Flex gridGap='10px'>
                  Login <RightArrowSVG />
                </Flex>
              </Button>
            </Center>
          </form>
        </Flex>

        {twoFaToken && (
          <TwoFAModal
            isOpen={isOpen}
            onClose={onClose}
            token={twoFaToken}
          />
        )}

        {_isOpen && (
          <OTPModal
            isOpen={_isOpen}
            onClose={_onClose}
            email={state.email}
            password={state.password}
            humanKey={humanKey}
            onOpen={onOpen}
            recaptchaInstanceRef={recaptchaInstanceRef}
            setTwoFaToken={setTwoFaToken}
          />
        )}

        <Recaptcha
          ref={recaptchaInstanceRef}
          sitekey={process.env.REACT_APP_RECAPTCHA}
          size='invisible'
        />
      </Box>
    </Flex>
  );
};

export default Login;
