import { usePagination } from '@ajna/pagination';
import { Box, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import {
  Loader,
  PaginationWrapper,
  ProcessDisbursementModal,
  ScrollRightContainer,
  TableDataStatus,
} from 'components';
import { useState } from 'react';
import { useGetAllDisbursementsQuery } from 'store/services/disbursement';
import { formatDateToUTC } from 'utils';

const AllDisbursement = () => {
  const [page, setPage] = useState(1);

  const { data, isLoading } = useGetAllDisbursementsQuery({
    page,
  });

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    isDisabled,
    pageSize,
    offset,
  } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  return (
    <Box>
      <ScrollRightContainer>
        {isLoading && <Loader />}

        <Table>
          <Thead>
            <Tr>
              <Th>S/N</Th>
              <Th>Description</Th>
              <Th>Sender Alias</Th>
              <Th>Flow State</Th>
              <Th>Amount</Th>
              <Th>Created By</Th>
              <Th>Date/Time</Th>
              <Th>Recipients</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.data.map(
                (
                  {
                    _id,
                    createdAt,
                    description,
                    flowState,
                    senderAlias,
                    recipients,
                    userDetails,
                    amount,
                    unit,
                  },
                  index
                ) => {
                  return (
                    <Tr key={_id} borderBottom="1px solid #efefef">
                      <Td verticalAlign="top">{index + 1}</Td>

                      <Td verticalAlign="top">{description}</Td>

                      <Td verticalAlign="top">{senderAlias}</Td>

                      <Td verticalAlign="top">
                        <TableDataStatus status={flowState} withIcon />
                      </Td>

                      <Td verticalAlign="top" textTransform="uppercase">
                        {amount} {unit}
                      </Td>

                      <Td verticalAlign="top" textTransform="capitalize">
                        {userDetails?.fullName}
                      </Td>

                      <Td verticalAlign="top">{formatDateToUTC(createdAt)}</Td>

                      <Td pt="8px">
                        <Thead borderBottom="none">
                          <Tr>
                            <Th
                              padding="0"
                              bgColor="transparent"
                              borderBottom="none"
                              fontWeight="500"
                            >
                              Username/Email
                            </Th>

                            <Th
                              fontWeight="500"
                              bgColor="transparent"
                              borderBottom="none"
                            >
                              Amount
                            </Th>

                            <Th
                              fontWeight="500"
                              bgColor="transparent"
                              borderBottom="none"
                            >
                              Description
                            </Th>

                            <Th
                              fontWeight="500"
                              bgColor="transparent"
                              borderBottom="none"
                            >
                              Network
                            </Th>
                          </Tr>
                        </Thead>

                        {recipients.map(
                          ({ _id, id, unit, network, amount, description }) => {
                            return (
                              <Tr key={_id} padding="0">
                                <Td padding="0">{id}</Td>

                                <Td>
                                  {amount} {unit}{' '}
                                </Td>

                                <Td>{description}</Td>

                                <Td>{network}</Td>
                              </Tr>
                            );
                          }
                        )}
                      </Td>

                      <Td verticalAlign="top">
                        <ProcessDisbursementModal status={flowState} id={_id} />
                      </Td>
                    </Tr>
                  );
                }
              )}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={isLoading ? 0 : data?.totalCount}
        currentPage={currentPage}
        setPage={setPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={pageSize}
        offset={offset}
        pages={pages}
      />
    </Box>
  );
};

export default AllDisbursement;
