import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Tooltip, Flex, useDisclosure, Center } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  CicoTransferModal,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  CICOTransferUpdate,
  Refresh,
  FilterSelect,
  ProcessPayout,
  SearchInput,
} from 'components';
import { useGetTransferQuery } from 'store/services/cico.service';
import { formatDateToUTC, ls, constant, chunkValue, transferStatusColor } from 'utils';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { useCopy, useDebounce, useStorage } from 'hooks';

const CicoTransfer = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setStorage, storageValue } = useStorage(constant.CICO_TRANSFER);

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedData, setSelectedData] = useState({});

  const [transferStatus, setTransferStatus] = useState(storageValue ? storageValue : '');
  const [queryValue, setQueryValue] = useState('');
  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setQueryValue(query);
      setPage(1);
    } else {
      setQueryValue('');
      setPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  const handleSize = (e) => {
    setPage(1);
    setSize(e.target.value);
  };

  const { data, isLoading, isFetching, isSuccess, isError, error, refetch } = useGetTransferQuery({
    page: page,
    limit: size,
    transferStatus,
    queries: queryValue ? `search=${queryValue}` : '',
  });

  const handleTransferStatus = (e) => {
    const value = e.target.value;
    setTransferStatus(value);
    setStorage(value);
  };

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: size,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  useEffect(() => {
    if (isError && error.data.error === 'SessionExpired') {
      ls.remove(constant.AUTH_CICO_TOKEN_KEY);
      navigate('/dashboard/cico', {
        replace: true,
      });
    }
  }, [error, isError, navigate]);

  const { hasCopied, onCopy, value } = useCopy();

  const handleOpen = (modalData) => {
    onOpen();
    setSelectedData(modalData);
  };

  console.log(data, 'DATA');

  return (
    <Box pos='relative'>
      <Flex
        justifyContent='space-between'
        mb='24px'
        alignItems='center'
      >
        <Flex gap='16px'>
          <FilterSelect
            width='88px'
            onChange={handleSize}
          >
            <option value={10}>Size</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FilterSelect>

          <FilterSelect
            width='200px'
            onChange={handleTransferStatus}
            value={transferStatus}
          >
            <option value=''>Transfer Status</option>
            <option value='TransferReceivedCryptoFunds'>TransferReceivedCryptoFunds</option>
            <option value='TransferReceivedFiatFunds'>TransferReceivedFiatFunds</option>
            <option value='TransferStarted'>TransferStarted</option>
            <option value='TransferWaitingForUserAction'>TransferWaitingForUserAction</option>
            <option value='TransferSendingCryptoFunds'>TransferSendingCryptoFunds</option>
            <option value='TransferPending'>TransferPending</option>
            <option value='TransferComplete'>TransferComplete</option>
          </FilterSelect>

          <Box
            alignSelf='center'
            width='100%'
          >
            <SearchInput
              placeholder='Search'
              minH='48px'
              width={{ base: '100%', tb: '319px' }}
              onChange={debouncedChangeHandler}
              // disabled={queryType.length <= 1}
            />
          </Box>
        </Flex>

        <Box
          width='40px'
          alignSelf='center'
          onClick={refetch}
        >
          {isFetching ? (
            <Center height='40px'>
              <Loader />{' '}
            </Center>
          ) : (
            <Refresh />
          )}
        </Box>
      </Flex>

      <ScrollRightContainer>
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Partner</Th>
                <Th>Coin</Th>
                <Th>Address</Th>
                <Th>Fee</Th>
                <Th>Fiat Account</Th>
                <Th>Fiat Amount</Th>
                <Th>Transfer Status</Th>
                <Th>Date/Time</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {isSuccess &&
                data.data.map(
                  ({
                    _id,
                    addressData,
                    createdAt,
                    cryptoType,
                    cryptoVolume,
                    fee,
                    feeUnit,
                    fiatAccountId,
                    fiatAmount,
                    fiatType,
                    transferStatus,
                    fiatAccountData,
                    direction,
                    client,
                    clientId,
                    enterpriseWithdrawalId,
                    payoutId,
                  }) => {
                    const modalData = {
                      _id,
                      fiatAccountData,
                      transferStatus,
                      direction,
                      cryptoVolume,
                      cryptoType,
                      fiatAmount,
                      fiatType,
                      enterpriseWithdrawalId,
                      payoutId,
                    };
                    return (
                      <Tr key={_id}>
                        <Td
                          cursor='pointer'
                          onClick={() => handleOpen(modalData)}
                        >
                          {chunkValue(_id)}
                        </Td>

                        <Td>
                          {client} <br />
                          <Flex
                            alignItems='center'
                            gap='16px'
                          >
                            <div>{clientId && chunkValue(clientId)} </div>
                            <div>
                              {value === clientId && hasCopied ? (
                                <CheckIcon />
                              ) : (
                                <CopyIcon
                                  cursor='pointer'
                                  onClick={() => onCopy(clientId)}
                                />
                              )}{' '}
                            </div>
                          </Flex>
                        </Td>

                        <Td textTransform='uppercase'>
                          {Number(cryptoVolume).toFixed('2')} {cryptoType}
                        </Td>

                        <Td>
                          {addressData?.address ? (
                            <Tooltip
                              maxW='initial'
                              label={addressData.address}
                              closeDelay='100'
                              bg='brand.primary'
                              padding='12px 8px'
                              borderRadius='8px'
                              color='#fff'
                            >
                              <Flex
                                alignItems='center'
                                gap='16px'
                                width='100%'
                              >
                                {addressData?.address && chunkValue(addressData.address)}

                                {value === addressData.address && hasCopied ? (
                                  <CheckIcon />
                                ) : (
                                  <CopyIcon
                                    cursor='pointer'
                                    onClick={() => onCopy(addressData.address)}
                                  />
                                )}
                              </Flex>
                            </Tooltip>
                          ) : (
                            'N/A'
                          )}
                        </Td>

                        <Td textTransform='uppercase'>
                          {fee} {feeUnit}
                        </Td>

                        <Td>{fiatAccountId}</Td>

                        <Td textTransform='uppercase'>
                          {Number(fiatAmount).toLocaleString()} {fiatType}
                        </Td>

                        <Td
                          fontWeight='500'
                          color={transferStatusColor(transferStatus)}
                        >
                          {transferStatus}
                        </Td>

                        <Td>{formatDateToUTC(createdAt)}</Td>

                        <Td>
                          <Flex gap='16px'>
                            <CICOTransferUpdate
                              _id={_id}
                              direction={direction}
                            />

                            {transferStatus === 'TransferReceivedCryptoFunds' && (
                              <ProcessPayout
                                _id={_id}
                                modalData={modalData}
                              />
                            )}
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </Box>
      </ScrollRightContainer>

      <CicoTransferModal
        isOpen={isOpen}
        onClose={onClose}
        modalData={selectedData}
      />

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        setPage={setPage}
        totalDataCount={isLoading ? 0 : data?.totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={size}
        offset={offset}
        pages={pages}
      />
    </Box>
  );
};

export default CicoTransfer;
