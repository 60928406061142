import {
  Box,
  Center,
  Flex,
  Heading,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
  Menu,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';

const P2PCardListing = ({ icon, type, count }) => {
  return (
    <Box
      bg="#fff"
      boxShadow="0px 2px 8px rgba(229, 229, 229, 0.47)"
      borderRadius="10px"
      width="272px"
      padding="12px 0 14.5px"
    >
      <Center flexDir="column">
        <Center
          boxShadow="0px 0.786517px 3.14607px rgba(229, 229, 229, 0.47)"
          borderRadius="10px"
          width="39px"
          height="35px"
        >
          {icon}
        </Center>

        <Text color="brand.darkGray" fontSize="14px">
          Total{' '}
          <Text as="span" textTransform="capitalize">
            {type}{' '}
          </Text>{' '}
          Listing
        </Text>

        <Heading margin="9px 0" as="h2" fontWeight="700" fontSize="28px">
          {count}
        </Heading>

        <Box
          color="#6FBE45"
          fontSize="12px"
          letterSpacing="-0.5px"
          fontWeight="500"
          textDecoration="underline"
          mb="12px"
        >
          <Link to={`/dashboard/p2p/${type}-listing`}>View All</Link>
        </Box>

        <Box>
          <Menu>
            <MenuButton
              padding="9px 16px"
              color="#4E4B66"
              fontSize="14px"
              bg="#fff"
              as="button"
            >
              <Flex gridGap="7px" justifyContent="center" alignItems="center">
                <Text color="#797A7A" fontSize="12px">
                  1hrs
                </Text>

                <ChevronDownIcon color="#797A7A" fontWeight="700" boxSize="5" />
              </Flex>
            </MenuButton>

            <MenuList
              padding="27px 12px"
              fontSize="14px"
              border="1px solid #F9FAF9"
              bg="#fff"
              minWidth="200px"
              gridGap="2rem"
              color="brand.black"
            >
              <MenuItem
                mb="15px"
                _focus={{
                  color: '#303131',
                  bg: '#fff',
                }}
                _hover={{
                  color: '#797A7A',
                  bg: '#fff',
                }}
              >
                7 days
              </MenuItem>

              <MenuItem
                _focus={{
                  color: '#303131',
                  bg: '#fff',
                }}
                _hover={{
                  color: '#797A7A',
                  bg: '#fff',
                }}
              >
                Two weeks
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Center>
    </Box>
  );
};

export default P2PCardListing;
