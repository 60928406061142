import { useEffect } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { EarnCard, FilterSelect } from 'components';
import BTCPNG from 'assets/png/btc.png';
import ETHPNG from 'assets/png/eth.png';
import USDTPNG from 'assets/png/usdt.png';
import { fetchStakingStats } from 'store/slices/staking.slice';

const EarnCardWrapper = () => {
  const {
    data: { atStake, redeemed },
  } = useSelector((state) => state.stakings.stats);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStakingStats());
  }, [dispatch]);

  return (
    <Box
      maxWidth='757px'
      width='100%'
      mt='28px'
    >
      <Box
        bg='#FFF'
        borderRadius='20px'
        mb='28px'
        padding='22px'
      >
        <Flex
          gridGap='18px'
          alignItems='center'
          mb='18px'
          justifyContent={{ base: 'space-between', tb: 'initial' }}
        >
          <Text
            fontWeight='600'
            fontSize='18px'
            fontFamily='PolySans'
          >
            Users Staked Coins
          </Text>

          <FilterSelect>
            <option value=''>All Countries</option>
            <option value='ngn'>Nigeria</option>
            <option value='ghs'>Ghana</option>
            <option value='ken'>Kenya</option>
          </FilterSelect>
        </Flex>

        <Flex
          gridGap='22px'
          flexWrap='wrap'
          justifyContent={{ base: 'center', md: 'initial' }}
        >
          <EarnCard
            icon={BTCPNG}
            count={atStake.tbtc ? Number(atStake.tbtc).toFixed(5) : Number(atStake?.btc).toFixed(5)}
            coin='btc'
          />

          <EarnCard
            icon={ETHPNG}
            coin='eth'
            count={atStake.teth ? Number(atStake.teth).toFixed(5) : Number(atStake?.eth).toFixed(5)}
          />

          <EarnCard
            icon={USDTPNG}
            coin='usdt'
            count={Number(atStake?.usdt).toLocaleString()}
          />
        </Flex>
      </Box>

      <Box
        bg='#FFF'
        borderRadius='20px'
        mb='33px'
        padding='22px'
      >
        <Flex
          gridGap='18px'
          alignItems='center'
          mb='18px'
        >
          <Text
            fontWeight='600'
            fontSize='18px'
            fontFamily='PolySans'
          >
            Bitmama Earned Coins
          </Text>
        </Flex>

        <Flex
          gridGap='22px'
          flexWrap='wrap'
          justifyContent={{ base: 'center', md: 'initial' }}
        >
          <EarnCard
            icon={BTCPNG}
            coin='btc'
            count={redeemed.tbtc ? redeemed?.tbtc : redeemed?.btc}
          />
          <EarnCard
            icon={ETHPNG}
            coin='eth'
            count={redeemed.teth ? Number(redeemed.teth).toFixed(5) : redeemed?.eth}
          />
          <EarnCard
            icon={USDTPNG}
            coin='usdt'
            count={redeemed.usdt ? Number(redeemed.usdt).toLocaleString() : ''}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default EarnCardWrapper;
