import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
  Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const TableActionButton = ({ onClick }) => {
  return (
    <Box pos="relative">
      <Menu>
        <MenuButton
          maxWidth="100px"
          width="100px"
          as={Button}
          borderRadius="5px"
          fontSize="12px"
          bg="rgba(49, 183, 169, 0.2)"
          color="#fff"
          _hover={{
            bg: 'rgba(49, 183, 169, 0.2)',
          }}
          _focus={{
            bg: ' rgba(49, 183, 169, 0.2)',
          }}
          _active={{
            bg: 'rgba(49, 183, 169, 0.2)',
          }}
        >
          <Flex
            gridGap="2px"
            justifyContent="center"
            color="brand.black"
            fontWeight="400"
          >
            Actions <ChevronDownIcon />
          </Flex>
        </MenuButton>

        <MenuList
          minWidth="120px"
          width="160px"
          borderRadius="6px"
          padding="8px"
          boxShadow="0px 20px 60px rgba(19, 10, 46, 0.25)"
          onClick={onClick}
        >
          <MenuItem
            color="#19181A"
            fontSize="14px"
            fontWeight="500"
            lineHeight="22px"
            value="approve"
          >
            Approve
          </MenuItem>

          <MenuItem
            color="#19181A"
            fontSize="14px"
            fontWeight="500"
            lineHeight="22px"
            value="deny"
          >
            Deny
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default TableActionButton;
