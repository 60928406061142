import { Box } from '@chakra-ui/react';

const ScrollRightContainer = ({ children, minHeight, propRef, hideScrollbar = false, ...props }) => {
  return (
    <Box
      maxWidth={{ sm: '700px', md: '950px', lg: '1250px' }}
      width='100%'
      overflowX='scroll'
      minHeight={minHeight ? minHeight : 'auto'}
      css={{
        '&::-webkit-scrollbar': {
          width: '2px',
          height: '2px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: hideScrollbar ? 'none' : '#CACBCB',
          borderRadius: '16px',
        },
      }}
      ref={propRef}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ScrollRightContainer;
