import { useRef, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Text, Flex, useToast, Center } from '@chakra-ui/react';
import pusherJs from 'pusher-js';
import { Button, Input } from 'components';
import { formatDateToUTC, getHeaders, unique, wrapBaseApi } from 'utils';
import { post } from 'utils/axiosLib';
import { useGetTradeChatsQuery, useLeaveTradeChatMutation } from 'store/services/p2pApi';
import { BASE_URL } from 'constant';

const DisputeConversation = ({ tradeRef, contractState }) => {
  const toast = useToast();

  const { token } = useSelector((state) => state.auth.auth);
  const tradeChat = useRef();
  const currentParties = useRef([]);
  const [chats, setChats] = useState([]);

  const [_leaveTradeChat] = useLeaveTradeChatMutation();

  const initialState = {
    chats: [],
    allowedParties: [],
    currentParties: [],
  };

  const { data: chatData = { ...initialState }, isSuccess, isError, error, refetch } = useGetTradeChatsQuery(tradeRef);

  const { data } = useSelector((state) => state.auth.profile);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);

    const headers = getHeaders();

    try {
      const URL = wrapBaseApi(`${BASE_URL}/p2p/chats/send`);

      const response = await post(
        URL,
        {
          channelName: tradeRef,
          message,
        },
        headers
      );

      if (response.data) {
        setLoading(false);
        setMessage('');

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Error',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const requestAccessToChat = async (e) => {
    e.preventDefault();
    setLoading(true);

    const headers = {
      authorization: token,
      'Content-Type': 'application/json',
    };

    try {
      const URL = wrapBaseApi(`${BASE_URL}/p2p/admin/chat/requestAdminAccessToChat`);

      const response = await post(
        URL,
        {
          channelName: tradeRef,
        },
        headers
      );

      if (response.data) {
        setLoading(false);
        setMessage('');

        refetch();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Error',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const filterAllowedParties = chatData?.allowedParties?.filter(({ username }) => {
    return String(username).toLowerCase() === String(data.username).toLowerCase();
  });

  const initPusherAuth = async () => {
    if (!tradeChat.current) {
      const pusher = new pusherJs(process.env.REACT_APP_PUSHER_KEY, {
        authEndpoint: wrapBaseApi(`${BASE_URL}/p2p/chats/auth`),
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        auth: {
          params: { tradeRef },
          headers: { authorization: token },
        },
      });
      tradeChat.current = pusher.subscribe(tradeRef);
      tradeChat.current?.members?.each(function (member) {
        var userInfo = member.info;
        const allButThisParty = currentParties.current.filter((u) => u.username === userInfo.username);
        currentParties.current = [userInfo].concat(allButThisParty);
      });

      tradeChat.current.bind('chat-update', (data) => {
        setChats((prev) => unique([...prev, data], 'chatId'));
      });
    }
  };

  const isChatsOver = useMemo(() => {
    let check = false;
    const chatsLen = chats?.length;
    // if (!sessionActive) return true;
    if (chatsLen) {
      if (chats[chatsLen - 1].command === 'closechat') check = true;
    }
    return check;
    // eslint-disable-next-line
  }, [chats, chats?.length]);

  initPusherAuth();

  useEffect(() => {
    return () => {
      _leaveTradeChat({ channelName: tradeRef }).catch(() => {});
      tradeChat.current && tradeChat.current.unsubscribe(tradeRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box postion='relative'>
      <Box
        maxWidth='420px'
        position='sticky'
        top='25px'
      >
        {isError && (
          <Box
            padding='24px 40px'
            bg='#fff'
          >
            <Text>{error ? error.error : ''}</Text>
          </Box>
        )}

        {isSuccess && (
          <Box
            bg='#fff'
            borderBottomRadius='16px'
            borderTopRadius='9px'
          >
            <Box
              height='520px'
              overflowY='scroll'
            >
              <Flex
                bg='#F1F1F1'
                borderTopRadius='16px'
                padding='24px 40px'
                position='sticky'
                width='420px'
                top='0'
                zIndex='2'
              >
                {chatData?.allowedParties.map(({ _id, username }, i) => {
                  return (
                    <Text key={_id}>
                      {username}
                      {i === 1 ? '' : ','} &nbsp;
                    </Text>
                  );
                })}
              </Flex>

              <Box
                padding='40px 40px 20px'
                h='100%'
              >
                <Box>
                  {chatData?.chats
                    .concat(chats)
                    .filter((res) => res.pin)
                    .map(({ message, chatId }) => {
                      return (
                        <Box
                          key={chatId}
                          bg='#FADBA0'
                          p='14px'
                          borderRadius='8px'
                          position='sticky'
                          top='10px'
                          mb='10px'
                          h='fit-content'
                        >
                          {message}
                        </Box>
                      );
                    })}
                </Box>

                {chatData?.chats
                  .concat(chats)
                  .filter((res) => !res.pin)
                  .map(({ message, messageTime, format, username, chatId }) => {
                    return (
                      <Flex
                        flexDir='column'
                        gap='4px'
                        key={chatId}
                        alignItems={username === data.username ? 'flex-end' : 'flex-start'}
                        mb='8px'
                      >
                        <Box
                          bg={username === data.username ? '#F5FEFF' : '#F6FEDE'}
                          padding='16px'
                          borderRadius='16px 0 16px 16px'
                          alignSelf={format === 'inline' && 'center'}
                        >
                          <Text
                            fontWeight='500'
                            mb='8px'
                          >
                            {username}
                          </Text>
                          <Text
                            fontSize='14px'
                            fontWeight='400'
                            lineHeight='16px'
                          >
                            {String(message).includes('https') ? (
                              <a
                                href={message}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {message}
                              </a>
                            ) : (
                              message
                            )}
                          </Text>
                        </Box>

                        <Text
                          as='span'
                          fontSize='10px'
                          alignSelf={format === 'inline' && 'center'}
                        >
                          {formatDateToUTC(messageTime)}
                        </Text>
                      </Flex>
                    );
                  })}
              </Box>
            </Box>

            {(contractState !== 'closed' || contractState !== 'cancelled') && (
              <Box
                bg='#fff'
                padding='16px 8px'
                borderBottomRadius='16px'
                border='1px solid #D9DBE9'
                shadow='0px, -2px rgba(0, 0, 0, 0.05)'
                as='form'
                onSubmit={handleSendMessage}
                mt='1rem'
              >
                {filterAllowedParties.length >= 1 && (
                  <>
                    <Flex
                      gridGap='8px'
                      justifyContent='space-between'
                    >
                      {isChatsOver === false && (
                        <>
                          <Input
                            width='290px'
                            plaeholder='write a message'
                            minH='48px'
                            borderRadius='16px'
                            required
                            onChange={(e) => setMessage(e.target.value)}
                            fontSize='14px'
                            value={message}
                            padding='12px 14px'
                            placeholder='Write a message...'
                          />

                          <Flex
                            alignItems='center'
                            gridGap='8px'
                            justifyContent='flex-end'
                          >
                            <Box
                              as='button'
                              bg='#0D4740'
                              borderRadius='16px'
                              fontWeight='500'
                              color='#fff'
                              padding='11px 24px'
                              type='submit'
                              _disabled={{
                                cursor: 'not-allowed',
                              }}
                              disabled={loading ? true : false}
                            >
                              {loading ? 'Sending' : 'Send'}
                            </Box>
                          </Flex>
                        </>
                      )}
                    </Flex>
                  </>
                )}

                {filterAllowedParties.length === 0 && (
                  <Center>
                    <Button
                      _disabled={{
                        cursor: 'not-allowed',
                      }}
                      minW='200px'
                      onClick={requestAccessToChat}
                      isLoading={loading}
                      disabled={loading ? true : false}
                    >
                      Request Access
                    </Button>
                  </Center>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DisputeConversation;
