import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constant, ls, wrapBaseApi } from 'utils';

export const merchantsApi = createApi({
  reducerPath: 'merchants',
  refetchOnMountOrArgChange: true,
  tagTypes: ['allMerchants'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, _) => {
      const auth = ls.get(constant.AUTH_TOKEN_KEY);

      headers.set('Authorization', auth.token);
      headers.set('Content-Type', 'application/json');
      // headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    allMerchants: builder.query({
      query: ({ page, size }) => wrapBaseApi(`/wallet/admin/merchant-banks?page=${page}&limit=${size}`),
      transformResponse: (res) => res.data,
      providesTags: ['allMerchants'],
    }),

    merchantTransactions: builder.query({
      query: ({ id, page, size }) =>
        wrapBaseApi(`/wallet/admin/merchantTx?merchantId=${id}&page=${page}&limit=${size}`),
      transformResponse: (res) => res.data,
      providesTags: ['allMerchants'],
    }),

    updateMerchantName: builder.mutation({
      query: ({ _id, firstName, lastName }) => ({
        url: wrapBaseApi(`/wallet/admin/merchant-banks/${_id}/update-first-last-name`),
        method: 'PUT',
        body: {
          firstName,
          lastName,
        },
      }),
      transformResponse: (res) => res,
      invalidatesTags: ['allMerchants'],
    }),

    addMerchant: builder.mutation({
      query: ({ firstName, lastName, email, phone, bankAccountName, bankAccountNumber, bankName, accountType }) => ({
        url: wrapBaseApi(`/wallet/admin/merchant-banks`),
        method: 'POST',
        body: {
          firstName,
          lastName,
          email,
          phone,
          bankAccountName,
          bankAccountNumber,
          bankName,
          accountType,
        },
      }),
      transformResponse: (res) => res,
      invalidatesTags: ['allMerchants'],
    }),

    updateMerchant: builder.mutation({
      query: ({
        _id,
        merchantAccountId,
        firstName,
        lastName,
        phone,
        bankAccountName,
        bankAccountNumber,
        bankName,
        accountType,
        currency,
        email,
        assignable,
      }) => ({
        url: wrapBaseApi(`/wallet/admin/bank-merchant/${_id}`),
        method: 'PUT',
        body: {
          update: {
            ...(email && { email }),
            ...(firstName && { firstName }),
            ...(lastName && { lastName }),
            ...(phone && { phone }),
            ...(bankAccountName && { bankAccountName }),
            ...(bankAccountNumber && { bankAccountNumber }),
            ...(bankName && { bankName }),
            ...(accountType && { accountType }),
            ...(currency && { currency }),
          },
          merchantAccountId,
          assignable,
        },
      }),
      transformResponse: (res) => res,
      invalidatesTags: ['allMerchants'],
    }),
  }),
});

export const {
  useAllMerchantsQuery,
  useUpdateMerchantNameMutation,
  useUpdateMerchantMutation,
  useMerchantTransactionsQuery,
  useAddMerchantMutation,
} = merchantsApi;

export default merchantsApi;
