import { Flex, Link } from '@chakra-ui/react';
import { useLocationSearch } from 'hooks';
import { NavLink } from 'react-router-dom';
import styles from './tab.module.scss';

const PageTab = ({ linkArray, count, ...props }) => {
  const searchValue = useLocationSearch();

  return (
    <Flex
      mb='36px'
      className={styles.tab}
      {...props}
    >
      {linkArray.map(({ id, search, title, to, otherSearchValue, isDisabled }) => {
        return (
          <Link
            key={id}
            as={NavLink}
            to={to}
            className={searchValue === search || searchValue === otherSearchValue ? styles.active : ''}
            style={{ pointerEvents: isDisabled ? 'none' : 'initial' }}
          >
            {title} {count ? count : ''}
          </Link>
        );
      })}
    </Flex>
  );
};

export default PageTab;
