import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'utils/axiosLib';
import { getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';

const initialState = {
  allCards: {
    status: 'idle',
    beforeAndAfter: '',
    data: {
      cards: [],
    },
    totalCount: 0,
  },

  cardTransactions: {
    status: 'idle',
    data: {
      transactions: [],
    },
    totalCount: 0,
    beforeAndAfter: '',
  },

  totalUsersWithCard: {
    status: 'idle',
    data: {
      totalUsers: 0,
    },
  },

  singleCard: {
    status: 'idle',
    data: {
      card: [],
    },
    totalCount: 0,
  },

  cardDetails: {
    status: 'idle',
    data: {},
  },

  physicalCards: {
    status: 'idle',
    data: {
      data: [],
    },
    totalCount: 0,
  },

  singleCardTransaction: {
    status: 'idle',
    data: {
      transactions: [],
    },
  },
};

export const fetchAllCards = createAsyncThunk('cards/stats', async ({ limit, page, search, filter }) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/wallet/admin/cards?limit=${limit}&page=${page}&search=${search}&${filter}`),
    getHeaders()
  );
  return response.data;
});

export const fetchCardTransactions = createAsyncThunk(
  'wallet/withdraw',
  async ({ limit, page, search, txType, dateFilter, otherQuery, failedTx }) => {
    let URL = wrapBaseApi(`${BASE_URL}/wallet/admin/cards-transactions?limit=${limit || 10}`);

    if (page) URL += `&page=${page}`;
    if (search) URL += `&search=${search}`;
    if (txType) URL += `&type=${txType}`;
    if (typeof failedTx === 'boolean') URL += `&failed=${failedTx}`;
    if (dateFilter) URL += `&${dateFilter}`;
    if (otherQuery) URL += `&${otherQuery}`;

    const response = await get(URL, getHeaders());
    return response.data;
  }
);

export const fetchTotalUsersWithCard = createAsyncThunk('cards/totalUsersWithCard', async () => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/wallet/admin/cards/aggregate?field=userDetails.email`),
    getHeaders()
  );
  return response.data;
});

export const fetchSingleCard = createAsyncThunk('cards/singleCard', async ({ userId, page }) => {
  const response = await get(
    wrapBaseApi(`${BASE_URL}/wallet/admin/virtual-cards?userId=${userId}&page=${page}&limit=10`),
    getHeaders()
  );
  return response.data;
});

export const fetchSingleCardTransaction = createAsyncThunk('card/singleCardTransaction', async ({ id }) => {
  const response = await get(wrapBaseApi(`${BASE_URL}/wallet/admin/virtual-cards/tx?cardId=${id}`), getHeaders());
  return response.data;
});

export const fetchCardDetails = createAsyncThunk('card/cardDetails', async ({ id }) => {
  const response = await get(wrapBaseApi(`${BASE_URL}/wallet/admin/cards/${id}`), getHeaders());
  return response.data;
});

export const fetchPhysicalCardRequest = createAsyncThunk(
  'cards/cardRequest',
  async ({ page, limit, status, search }) => {
    const response = await get(
      wrapBaseApi(
        `${BASE_URL}/wallet/admin/cards/p/requests?limit=${limit}&page=${page}&status=${status}&search=${search}`
      ),
      getHeaders()
    );
    return response.data;
  }
);

const cardSlice = createSlice({
  name: 'cards',

  initialState,

  reducers: {
    getCardDateFilter(state, action) {
      state.allCards.beforeAndAfter = action.payload;
    },

    filterCardTxDate(state, action) {
      state.cardTransactions.beforeAndAfter = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAllCards.fulfilled, (state, { payload }) => {
      state.allCards.status = 'fulfilled';
      state.allCards.data = payload.data;
      state.allCards.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchAllCards.pending, (state) => {
      state.allCards.status = 'loading';
    });

    builder.addCase(fetchAllCards.rejected, (state) => {
      state.allCards.status = 'error';
    });

    // card transactons
    builder.addCase(fetchCardTransactions.fulfilled, (state, { payload }) => {
      state.cardTransactions.status = 'fulfilled';
      state.cardTransactions.data = payload.data;
      state.cardTransactions.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchCardTransactions.pending, (state) => {
      state.cardTransactions.status = 'loading';
    });

    builder.addCase(fetchCardTransactions.rejected, (state) => {
      state.cardTransactions.status = 'error';
    });

    // Total users with cards
    builder.addCase(fetchTotalUsersWithCard.fulfilled, (state, { payload }) => {
      state.totalUsersWithCard.status = 'fulfilled';
      state.totalUsersWithCard.data = payload.data;
    });

    builder.addCase(fetchTotalUsersWithCard.pending, (state) => {
      state.totalUsersWithCard.status = 'loading';
    });

    builder.addCase(fetchTotalUsersWithCard.rejected, (state) => {
      state.totalUsersWithCard.status = 'error';
    });

    // Total users with cards
    builder.addCase(fetchSingleCard.fulfilled, (state, { payload }) => {
      state.singleCard.status = 'fulfilled';
      state.singleCard.data = payload.data;
      state.singleCard.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchSingleCard.pending, (state) => {
      state.singleCard.status = 'loading';
    });

    builder.addCase(fetchSingleCard.rejected, (state) => {
      state.singleCard.status = 'error';
    });

    // Physical Card Request
    builder.addCase(fetchPhysicalCardRequest.fulfilled, (state, { payload }) => {
      state.physicalCards.status = 'fulfilled';
      state.physicalCards.data = payload.data;
      state.physicalCards.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchPhysicalCardRequest.pending, (state) => {
      state.physicalCards.status = 'loading';
    });

    builder.addCase(fetchPhysicalCardRequest.rejected, (state) => {
      state.physicalCards.status = 'error';
    });

    // Single Card Transactions
    builder.addCase(fetchSingleCardTransaction.fulfilled, (state, { payload }) => {
      state.singleCardTransaction.status = 'fulfilled';
      state.singleCardTransaction.data = payload.data;
    });

    builder.addCase(fetchSingleCardTransaction.pending, (state) => {
      state.singleCardTransaction.status = 'loading';
    });

    builder.addCase(fetchSingleCardTransaction.rejected, (state) => {
      state.singleCardTransaction.status = 'error';
    });

    // Single Card Transactions
    builder.addCase(fetchCardDetails.fulfilled, (state, { payload }) => {
      state.cardDetails.status = 'fulfilled';
      state.cardDetails.data = payload.data;
    });

    builder.addCase(fetchCardDetails.pending, (state) => {
      state.cardDetails.status = 'loading';
    });

    builder.addCase(fetchCardDetails.rejected, (state) => {
      state.cardDetails.status = 'error';
    });
  },
});

export const {
  actions: { getCardDateFilter, filterCardTxDate },
  reducer: CardsReducer,
} = cardSlice;
