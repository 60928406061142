import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders, wrapBaseApi } from 'utils';
import { get } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const initialState = {
  buySell: {
    status: 'idle',
    data: {
      data: [],
      totalCount: 0,
    },
    errorMessage: '',
  },
};

export const fetchBuySell = createAsyncThunk('ledger/buySell', async ({ page, limit, status }, thunkAPI) => {
  const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/getBuySellLedger?limit=${limit}&page=${page}&status=${status}`);

  try {
    const response = await get(URL, getHeaders());
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const buySellSlice = createSlice({
  name: 'buySell',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchBuySell.fulfilled, (state, { payload }) => {
      state.buySell.status = 'fulfilled';
      state.buySell.data = payload.data;
    });

    builder.addCase(fetchBuySell.pending, (state) => {
      state.buySell.status = 'loading';
    });

    builder.addCase(fetchBuySell.rejected, (state, action) => {
      state.buySell.status = 'error';
      state.buySell.errorMessage = action.error.message;
    });
  },
});

export const { reducer: BuySellReducer } = buySellSlice;
