import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { Loader, PageTitle, RateModal, ScrollRightContainer } from 'components';
import { fetchRates } from 'store/slices/rate.slice';

const Rate = () => {
  const { data, status, errorMessage } = useSelector((state) => state.rates.rate);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRates());
  }, [dispatch]);

  return (
    <ScrollRightContainer>
      <Box
        maxWidth='920px'
        margin='auto'
      >
        <PageTitle title='Rate' />

        <Box mt='1rem'>
          {status === 'loading' && <Loader />}

          {status === 'error' && <Text>{errorMessage}</Text>}

          <Table>
            <Thead>
              <Tr>
                <Th>S/N</Th>
                <Th>Currency Pair</Th>
                <Th>Buy Rate</Th>
                <Th>Sell Rate</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {status === 'fulfilled' &&
                data.map(({ pair, rate, _id }, index) => {
                  const modalData = {
                    pair,
                    rate,
                  };
                  return (
                    <Tr key={_id}>
                      <Td>{index + 1}</Td>
                      <Td
                        fontWeight='500'
                        textTransform='uppercase'
                      >
                        {pair}
                      </Td>
                      <Td>{rate?.buy?.$numberDecimal ? rate?.buy?.$numberDecimal : rate?.buy}</Td>
                      <Td>{rate?.sell?.$numberDecimal ? rate?.buy?.$numberDecimal : rate?.buy}</Td>
                      <Td>
                        <RateModal
                          modalData={modalData}
                          dispatch={dispatch}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </ScrollRightContainer>
  );
};

export default Rate;
