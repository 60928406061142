import axios from 'axios';
import { autoSignOut } from 'utils';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // console.log('ERROR', error);
    const errorData = {
      ...error?.response?.data,
      customError: error?.response?.data?.error,
    };

    autoSignOut(errorData?.status, errorData?.customError);

    return Promise.reject(errorData);
  }
);

const includeCredentials = false;

const get = async (endpoint, headers = {}) => {
  try {
    const req = await axios.get(endpoint, { headers });
    return Promise.resolve({ status: req.status, data: req.data });
  } catch (err) {
    return Promise.reject(err);
  }
};

const post = async (endpoint, data = {}, headers = {}) => {
  try {
    const req = await axios.post(endpoint, data, {
      headers,
      ...(includeCredentials && {
        withCredentials: true,
        credentials: 'include',
      }),
    });
    return Promise.resolve({ status: req.status, data: req.data });
  } catch (err) {
    return Promise.reject(err);
  }
};

const put = async (endpoint, data = {}, headers = {}) => {
  try {
    const req = await axios.put(endpoint, data, {
      headers,
      ...(includeCredentials && {
        withCredentials: true,
        credentials: 'include',
      }),
    });
    return Promise.resolve({ status: req.status, data: req.data });
  } catch (err) {
    return Promise.reject(err);
  }
};

const del = async (endpoint, headers = {}) => {
  try {
    const req = await axios.delete(endpoint, {
      headers,
      ...(includeCredentials && {
        withCredentials: true,
        credentials: 'include',
      }),
    });
    return Promise.resolve({ status: req.status, data: req.data });
  } catch (err) {
    return Promise.reject(err);
  }
};

const patch = async (endpoint, data = {}, headers = {}) => {
  try {
    const req = await axios.patch(endpoint, data, {
      headers,
      ...(includeCredentials && {
        withCredentials: true,
        credentials: 'include',
      }),
    });
    return Promise.resolve({ status: req.status, data: req.data });
  } catch (err) {
    return Promise.reject(err);
  }
};

export { get, post, put, del, patch };
