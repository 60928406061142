import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Flex, useDisclosure, Box } from '@chakra-ui/react';
import { CloseModal, TableDataStatus, ViewMore } from 'components';
import { formatDateToUTC } from 'utils';

const IntegrationKYCModal = ({ modalData }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { _id, firstName, lastName, selfieDocument, documentType, documentStatus, documentUrl, expiresOn, reason } =
    modalData;

  return (
    <>
      <ViewMore onClick={onOpen}>View More</ViewMore>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        key={_id}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          bg='#fff'
          maxWidth='520px'
          padding='20px 40px 20px'
        >
          <Text
            fontSize='24px'
            textAlign='center'
            fontWeight='600'
          >
            KYC Details
          </Text>

          <ModalBody
            padding='0px'
            mt='20px'
          >
            <Box
              pb='8px'
              borderBottom='1px solid #efefef'
            >
              <Card
                title='Full Name'
                description={`${firstName} ${lastName}`}
              />

              <Card
                title='Document Status'
                description={documentStatus}
              />
              {documentStatus === 'rejected' && (
                <Card
                  title='Reason'
                  description={reason}
                />
              )}

              <Card
                title='Document Type'
                description={documentType}
              />

              <Card
                title='Expires On'
                description={`${formatDateToUTC(expiresOn)}`}
              />

              <Card
                title='Document URL'
                description={documentUrl}
                url
              />

              <Card
                title='Selfie Document'
                description={`${selfieDocument}`}
                url
              />
            </Box>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default IntegrationKYCModal;

const Card = ({ title, description, url }) => {
  return (
    <Flex
      justifyContent='space-between'
      mb='24px'
      alignItems='center'
    >
      <Text>{title}</Text>

      {url ? (
        <Box
          as='a'
          href={description}
          target='_blank'
          rel='noopener noreferrer'
          color='blue.600'
        >
          {String(description).substring(0, 38)}
        </Box>
      ) : (
        <Text
          fontWeight='500'
          textTransform='capitalize'
        >
          <TableDataStatus status={description} />
        </Text>
      )}
    </Flex>
  );
};
