import { useEffect, useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { read, utils } from 'xlsx';
import {
  ScrollRightContainer,
  DeleteBatchPaymentRecord,
  PageTitle,
  TableDataStatus,
  ReUploadBatchFile,
  BatchUpload,
  CreateDisbursementModal,
} from 'components';

const CreateDisbursements = () => {
  const [refresh, setRefresh] = useState(0);
  const [userChoice, setUserChoice] = useState(false);
  const disburseKey = 'disbursements';
  const disburseHead = 'disburseHead';
  const getStorage = localStorage.getItem(disburseKey);
  const getColumn = localStorage.getItem(disburseHead);

  const [localStorageData, setLocalStorageData] = useState(
    JSON.parse(getStorage)
  );

  const [columnData, setColumnData] = useState(JSON.parse(getColumn));

  function handleFile(e) {
    const checkUserChoice = window.confirm(
      'Are you sure you want to upload a new sheet? If Yes, you will lose previous data'
    );

    if (checkUserChoice) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data);

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const jsonData = utils.sheet_to_json(worksheet, {
          header: 1,
          defval: 'N/A',
        });

        const records = [];

        const headers = jsonData[0];

        jsonData.forEach((data, index) => {
          if (index !== 0) {
            const recordObject = {
              status: '',
            };

            headers.forEach((d, i) => {
              recordObject[d.toLowerCase()] = data[i] || '';
            });

            records.push(recordObject);
          }
        });

        setLocalStorageData(
          localStorage.setItem(disburseKey, JSON.stringify(records))
        );

        setColumnData(
          localStorage.setItem(disburseHead, JSON.stringify(headers))
        );
      };

      reader?.readAsArrayBuffer(file);
      setRefresh(refresh + 1);
    }
  }

  const handleDelete = (index) => {
    const filter = localStorageData.filter((_, i) => index !== i);

    localStorage.setItem(disburseKey, JSON.stringify(filter));

    setLocalStorageData(JSON.parse(getStorage));
  };

  const checkPendingWithdrawals = localStorageData?.filter(
    ({ status }) => status.toLowerCase() === 'pending'
  );

  useEffect(() => {
    setLocalStorageData(JSON.parse(getStorage));
    setColumnData(JSON.parse(getColumn));
    setUserChoice(userChoice);
    setRefresh(refresh + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getColumn, getStorage, userChoice]);

  return (
    <>
      {((localStorageData && localStorageData.length === 0) ||
        localStorageData === null) && (
        <>
          <PageTitle fontSize="16px" title="Disbursement" />

          <Box mt="43px">
            <BatchUpload onChange={handleFile} />
          </Box>
        </>
      )}

      {localStorageData && localStorageData.length >= 1 && (
        <>
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <Flex gridGap="14px" alignItems="center">
                {checkPendingWithdrawals?.length >= 1 && (
                  <CreateDisbursementModal
                    localStorageData={localStorageData}
                  />
                )}
              </Flex>
            </Box>

            <Box>
              <ReUploadBatchFile onChange={handleFile} />
            </Box>
          </Flex>

          <ScrollRightContainer>
            <Table mt="2rem">
              <Thead>
                <Tr>
                  <Th>S/N</Th>
                  {columnData &&
                    columnData?.map((response, index) => {
                      return <Th key={index}>{response}</Th>;
                    })}
                  {columnData && <Th>Action</Th>}
                </Tr>
              </Thead>

              <Tbody>
                {localStorageData &&
                  localStorageData?.map((res, i) => {
                    return (
                      <Tr key={Math.random() * 10000000}>
                        <Td>{i + 1}</Td>
                        {columnData?.map((column, _i) => {
                          const col = column.toLowerCase();

                          return (
                            <Td key={Math.random() * 10000000}>
                              {res[col] === 'Pending' ||
                              res[col] === 'processed' ? (
                                <TableDataStatus
                                  status={String(res['status']).toLowerCase()}
                                />
                              ) : (
                                <>{res[col]}</>
                              )}
                            </Td>
                          );
                        })}

                        <Td key={Math.random() * 10000000}>
                          <Flex alignItems="center" gridGap="8px">
                            <DeleteBatchPaymentRecord
                              handleDelete={() => handleDelete(i)}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </ScrollRightContainer>
        </>
      )}
    </>
  );
};

export default CreateDisbursements;
