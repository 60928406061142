import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { usePagination } from '@ajna/pagination';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Flex } from '@chakra-ui/react';
import { Loader, PaginationWrapper, ScrollRightContainer, ViewMore } from 'components';
import { formatDateToUTC } from 'utils';
import { EyeSVG } from 'assets';
import { fetchLeaderboard } from 'store/slices/partners.slice';

const PartnersData = () => {
  const dispatch = useDispatch();

  const { data, status, errorMessage, dateFilter } = useSelector((state) => state.partners.leaderboard);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: data.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);

    setTimeout(() => {
      window.scrollTo({ top: 100, behavior: 'smooth' });
    }, 500);
  };

  useEffect(() => {
    dispatch(
      fetchLeaderboard({
        limit: 10,
        page: currentPage,
        beforeDate: dateFilter.endDate ? dateFilter.endDate : '',
        afterDate: dateFilter.startDate ? dateFilter.startDate : '',
      })
    );
  }, [dispatch, currentPage, dateFilter]);

  return (
    <Box
      width='100%'
      overflowX='scroll'
    >
      <>
        {status === 'loading' && (
          <Box margin='8px 0'>
            <Loader />
          </Box>
        )}

        {status === 'error' && <p>{errorMessage}</p>}

        {status === 'fulfilled' && (
          <ScrollRightContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Full name</Th>
                  <Th>Email address</Th>
                  <Th>Date registration</Th>
                  <Th>Level of verification</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.data.map(({ kycLevel, firstName, lastName, registeredOn, userId, username }) => {
                    return (
                      <Tr key={userId}>
                        <Td
                          color='#6FBE45'
                          fontWeight='500'
                          textTransform='capitalize'
                        >
                          <Link to={`/dashboard/partners/${userId}`}>
                            {firstName} {lastName}
                          </Link>
                        </Td>

                        <Td>{username}</Td>

                        <Td>{formatDateToUTC(registeredOn).split(',')[0]}</Td>

                        <Td textTransform={'capitalize'}>Level {kycLevel}</Td>

                        <Td>
                          <Link to={`/dashboard/partners/${userId}`}>
                            <ViewMore _hover={{ bg: 'transparent' }}>
                              <Flex
                                gridGap='8px'
                                justifyContent='center'
                                alignItems='center'
                              >
                                <Text
                                  fontWeight='600'
                                  fontSize='10px'
                                >
                                  View
                                </Text>
                                <EyeSVG />
                              </Flex>
                            </ViewMore>
                          </Link>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>

            <PaginationWrapper
              handlePageChange={handlePageChange}
              totalDataCount={data.totalCount}
              currentPage={currentPage}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              size={pageSize}
              offset={offset}
              pages={pages}
            />
          </ScrollRightContainer>
        )}
      </>
    </Box>
  );
};

export default PartnersData;
