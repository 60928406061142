import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  useDisclosure,
  Text,
  Checkbox,
  HStack,
  CheckboxGroup,
} from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  AccordionWrapper,
  ExportCardTransactions,
  FilterSelect,
  Loader,
  PaginationWrapper,
  PartnersFilterByDateModal,
  ScrollRightContainer,
  SearchInput,
  TableDataStatus,
  UserTransactionModal,
  ViewMore,
} from 'components';
import { constant, formatDateToUTC } from 'utils';
import { useDebounce, useStorage } from 'hooks';
import { fetchCardTransactions } from 'store/slices/card.slice';
import { FilterSVG } from 'assets';
import { useNavigate } from 'react-router-dom';

const CardTransactions = () => {
  const dispatch = useDispatch();
  const { data, status, totalCount, beforeAndAfter } = useSelector((state) => state.cards.cardTransactions);

  const [size, setSize] = useState(10);
  const { storageValue: storageType, setStorage: setStorageType } = useStorage(constant.CARD_TX_TYPE);
  const [txType, setTxType] = useState(JSON.parse(storageType) ?? []);

  const [searchString, setSearchString] = useState('');
  const { storageValue: failedCheck, setStorage: setFailedCheck } = useStorage(constant.CARD_FAILED);

  const [failedTx, setFailedTx] = useState(failedCheck ?? undefined);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: size,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const handleTransactionType = (value) => {
    setStorageType(JSON.stringify(value));
    setTxType(value.join(','));
    setCurrentPage(1);
  };

  const handleFailedTx = () => {
    setCurrentPage(1);

    setFailedTx((prevState) => {
      console.log(prevState, 'PREVIOUS CHECK');
      if (prevState === undefined || prevState === 'false') {
        setFailedCheck(true);
        return true;
      } else {
        setFailedCheck(!prevState);
        return !prevState;
      }
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  useEffect(() => {
    dispatch(
      fetchCardTransactions({
        failedTx,
        txType,
        limit: size,
        page: currentPage,
        search: searchString,
        dateFilter: beforeAndAfter,
      })
    );
  }, [dispatch, currentPage, size, searchString, txType, beforeAndAfter, failedTx]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  return (
    <Box>
      <Flex justifyContent='space-between'>
        <Flex
          gridGap='1rem'
          alignItems={{ base: 'flex-start', md: 'center' }}
          flexDir={{ base: 'column', md: 'row' }}
          width='100%'
        >
          <Heading
            as='h2'
            color='#797A7A'
            fontSize='1rem'
            fontWeight='700'
          >
            Transactions
          </Heading>

          <Flex
            gridGap='1rem'
            width='100%'
            alignItems='center'
          >
            <FilterSelect
              width='90px'
              onChange={handleSize}
            >
              <option value={10}>Size</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </FilterSelect>

            <Box
              maxWidth='319px'
              width='100%'
            >
              <SearchInput
                onChange={debouncedChangeHandler}
                placeholder='Search for user name or email'
                width='100%'
              />
            </Box>
          </Flex>
        </Flex>

        <Flex
          gap='18px'
          alignItems='center'
        >
          <Flex
            gridGap='5px'
            alignItems='center'
            onClick={onOpen}
            cursor='pointer'
            _hover={{
              fontWeight: '600',
              color: '#797A7A',
            }}
          >
            <Text
              fontSize='14px'
              fontWeight='500'
            >
              Date
            </Text>

            <FilterSVG />
          </Flex>

          <ExportCardTransactions
            txType={txType}
            failedTx={failedTx}
            search={searchString}
            dateFilter={beforeAndAfter}
          />
        </Flex>
      </Flex>

      <Flex
        gap='1rem'
        mb='24px'
      >
        <AccordionWrapper
          title='Transaction Type'
          maxWidth='400px'
          width='100%'
        >
          <HStack gridGap='24px'>
            <CheckboxGroup
              colorScheme='green'
              onChange={handleTransactionType}
              defaultValue={txType}
            >
              <Checkbox value='credit'>Credit</Checkbox>
              <Checkbox value='debit'>Debit</Checkbox>
              <Checkbox value='refund'>Refund</Checkbox>
            </CheckboxGroup>

            <Checkbox
              onChange={handleFailedTx}
              colorScheme='green'
              isChecked={failedTx === 'false' || failedTx === undefined || failedTx === false ? false : true}
            >
              Failed
            </Checkbox>
          </HStack>
        </AccordionWrapper>
      </Flex>

      {status === 'loading' && (
        <Box margin='8px 0'>
          <Loader />
        </Box>
      )}

      <ScrollRightContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Amount</Th>
              <Th>Description</Th>
              <Th>Transaction Type</Th>
              <Th>Date</Th>
              <Th>Card ID</Th>
              <Th>Balance Before</Th>
              <Th>Balance After</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.transactions.map(
                ({
                  _id,
                  currency,
                  createdAt,
                  balanceAfter,
                  balanceBefore,
                  type,
                  value,
                  userDetails,
                  cardType,
                  cardId,
                  description,
                }) => {
                  return (
                    <Tr
                      key={_id}
                      onClick={() => navigate(`/dashboard/cards/${cardId}`)}
                      cursor='pointer'
                    >
                      <Td>
                        <UserTransactionModal
                          userId={userDetails ? userDetails.userId : _id}
                          fullName={userDetails ? userDetails.fullName : 'N/A'}
                        />
                      </Td>

                      <Td textTransform='capitalize'>
                        {Number(value?.$numberDecimal).toLocaleString()} {String(currency).toUpperCase()}
                      </Td>

                      <Td>{description}</Td>

                      <Td textTransform='uppercase'>
                        <TableDataStatus
                          textTransform='uppercase'
                          withIcon={false}
                          status={type}
                        />
                      </Td>

                      <Td>{formatDateToUTC(createdAt)}</Td>

                      <Td>{cardId}</Td>

                      <Td textTransform='capitalize'>
                        {balanceBefore ? Number(balanceBefore).toLocaleString() : 'N/A'}
                      </Td>

                      <Td textTransform='capitalize'>{Number(balanceAfter).toLocaleString()}</Td>

                      <Td>
                        <ViewMore _hover={{ bg: 'transparent' }}>View</ViewMore>
                      </Td>
                    </Tr>
                  );
                }
              )}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      {isOpen && (
        <PartnersFilterByDateModal
          isOpen={isOpen}
          onClose={onClose}
        />
      )}

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={size}
        offset={offset}
        pages={pages}
      />
    </Box>
  );
};

export default CardTransactions;
