import { Box, Flex, Image, Text } from '@chakra-ui/react';

import ChartSVG from 'assets/svg/home-big-chart.svg';
import SmallChartSVG from 'assets/svg/home-small-chart.svg';

const HomeChart = () => {
  return (
    <Box
      mt='46px'
      // display={{ sm: 'none', md: 'block' }}
    >
      <Text
        mb='15px'
        fontFamily='PolySans'
        fontSize='18px'
        fontWeight='600'
        textAlign={{ sm: 'center', md: 'initial' }}
      >
        Number of Transactions
      </Text>

      <Flex
        gridGap='15px'
        flexWrap={{ sm: 'wrap', md: 'initial' }}
        justifyContent={{ sm: 'center', md: 'initial' }}
      >
        <Box>
          <Image
            src={ChartSVG}
            alt='default chart'
          />
        </Box>

        <Box>
          <Image
            src={SmallChartSVG}
            alt='default chart'
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default HomeChart;
