import { Box, Table, Tr, Td, Th, Thead, Tbody, Text, Flex } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  FilterSelect,
  Loader,
  PageTitle,
  PaginationWrapper,
  ScrollRightContainer,
  SearchInput,
  TableDataStatus,
  UpdateRequestModal,
} from 'components';

import { formatDateToUTC } from 'utils';
import { useGetUserRequestsQuery } from 'store/services/request.service';
import { useState } from 'react';
import { useDebounce } from 'hooks';

// Name change request
const AllRequest = () => {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');
  const [searchString, setSearchString] = useState('');

  const { data, isLoading } = useGetUserRequestsQuery({
    page,
    status,
    search: searchString,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset, pageSize } = usePagination({
    initialState: { currentPage: 1, pageSize: 10 },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleStatus = (e) => {
    setCurrentPage(1);
    setStatus(e.target.value);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  return (
    <>
      <PageTitle title='All Request' />

      <Box mt='29px'>
        <Flex
          mb='24px'
          gridGap='16px'
          alignItems={{ base: 'flex-start', md: 'center' }}
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Box
            width='100%'
            maxWidth={{ base: '352px' }}
          >
            <SearchInput
              placeholder='Search by username'
              minH='56px'
              width={{ base: '100%', md: '352px' }}
              bg='#F9FAF9'
              onChange={debouncedChangeHandler}
            />
          </Box>

          <FilterSelect onChange={handleStatus}>
            <option value=''>All</option>
            <option value='denied'>Denied</option>
            <option value='approved'>Approved</option>
            <option value='pending'>Pending</option>
            <option value='cancelled'>Cancelled</option>
          </FilterSelect>
        </Flex>

        <ScrollRightContainer>
          {isLoading && <Loader />}
          <Table>
            <Thead>
              <Tr>
                <Th>Full Name (Current) </Th>
                <Th>New Request</Th>
                <Th>Email</Th>
                <Th>Status</Th>
                <Th>Reason</Th>
                <Th>Date/Time</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data &&
                data.documents.map(({ _id, reason, status, data: request, prevData, createdAt, userDetail }, index) => {
                  const prevName = `${prevData?.firstName} ${prevData?.lastName}`;
                  const newName = `${request?.firstName} ${request?.lastName}`;

                  return (
                    <Tr key={index}>
                      <Td textTransform='capitalize'>
                        <a
                          href={`/dashboard/users/${userDetail.userId}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {prevName}
                        </a>
                      </Td>

                      <Td textTransform='capitalize'>{newName}</Td>

                      <Td textTransform='lowercase'>{userDetail.email}</Td>

                      <Td textTransform='lowercase'>
                        <TableDataStatus status={status} />
                      </Td>

                      <Td>
                        <Text>{reason}</Text>
                      </Td>

                      <Td textTransform='lowercase'>{formatDateToUTC(createdAt)}</Td>

                      <Td>
                        <UpdateRequestModal
                          requestId={_id}
                          key={_id}
                          userId={userDetail.userId}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </ScrollRightContainer>

        <PaginationWrapper
          setCurrentPage={setCurrentPage}
          totalDataCount={data?.totalCount ? data.totalCount : 0}
          currentPage={currentPage}
          setPage={setPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          offset={offset}
          pages={pages}
          size={pageSize}
        />
      </Box>
    </>
  );
};

export default AllRequest;
