import { useCallback, useEffect, useRef } from 'react';

export function useRedebounce() {
  const debouncer = useRef({});

  const redebounce = useCallback((cb, functionName, delay) => {
    let timeout = debouncer.current[functionName];
    return function () {
      if (timeout) {
        clearTimeout(timeout);
        debouncer.current[functionName] = undefined;
      }
      debouncer.current[functionName] = setTimeout(cb, delay);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dc = debouncer.current;

    return () => {
      const debouncing = Object.keys(dc);
      debouncing.forEach((d) => {
        const timeout = dc[d];
        if (timeout) {
          clearTimeout(timeout);
          dc[d] = undefined;
        }
      });
    };
  }, []);

  return redebounce;
}
