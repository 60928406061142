import { Flex } from '@chakra-ui/react';
import { GreenboxClaimedSVG, GreenboxCreatedSVG, GreenboxSignupsSVG } from 'assets';
import { Loader, StatCard } from 'components';
import { useGreenboxQuery } from 'store/services/greenbox.service';

const GreenboxStat = () => {
  const { data, isLoading } = useGreenboxQuery({
    page: 1,
    limit: 10,
    actionType: '',
    search: '',
    status: '',
  });

  return (
    <Flex
      gridGap='12px'
      mt='18px'
      flexWrap='wrap'
      justifyContent={{ base: 'center', md: 'initial' }}
    >
      <StatCard
        icon={<GreenboxClaimedSVG />}
        count={isLoading ? <Loader /> : data?.totalCount}
        title='Total Greenboxes created'
      />

      <StatCard
        icon={<GreenboxCreatedSVG />}
        count='N/A'
        title='Total Greenboxes claimed'
      />

      <StatCard
        icon={<GreenboxSignupsSVG />}
        count='N/A'
        title='New sign-ups from Greenbox link'
      />
    </Flex>
  );
};

export default GreenboxStat;
