import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { secondsToMilliseconds } from 'date-fns';
import { constant, getHeaders, ls } from 'utils';
import { get } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const initialState = {
  auth: {
    token: null,
    isSignedIn: false,
    exp: null, // token expiration in seconds.
  },

  profile: {
    status: 'idle',
    data: {},
  },
};

export const fetchUserProfile = createAsyncThunk(
  'auth/currentUser',
  async (token, _) => {
    const response = await get(
      `${BASE_URL}/auth/currentUser`,
      getHeaders(token)
    );
    return response.data;
  }
);

const authSlice = createSlice({
  name: 'auth',

  initialState,

  reducers: {
    hydrateToken: (state, action) => {
      const auth = {
        token: action?.payload?.token,
        exp: action?.payload?.exp ?? null,
        isSignedIn: action?.payload?.isSignedIn ?? true,
      };
      state.auth = { ...auth };

      ls.set(constant.AUTH_TOKEN_KEY, auth);
    },

    dehydrate: () => {
      ls.remove(constant.AUTH_TOKEN_KEY);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
      state.profile.status = 'success';
      state.profile.data = payload.data;

      state.auth = {
        ...state?.auth,
        exp: secondsToMilliseconds(payload?.data?.exp),
        isSignedIn: payload?.data?.loginStatus === 'complete' ? true : false,
      };
      ls.set(constant.AUTH_TOKEN_KEY, state.auth);
    });

    builder.addCase(fetchUserProfile.pending, (state) => {
      state.profile.status = 'loading';
    });

    builder.addCase(fetchUserProfile.rejected, (state) => {
      state.profile.status = 'error';
    });
  },
});

export const selectAuth = (state) => state.auth;

export const { actions, reducer: AuthReducer } = authSlice;
export const { hydrateToken, dehydrate } = actions;
