import { Flex, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { Button } from 'components';
import { CICO_URL } from 'constant';
import { useState } from 'react';
import { constant, ls } from 'utils';

const ApproveDeclineCicoKYC = ({ id, refetch }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleKycStatus = async (kycStatus) => {
    setLoading(true);
    try {
      const URL = `${CICO_URL}/kyc/${id}/updateKycStatus`;

      const response = await axios.patch(
        URL,
        {
          kycStatus,
          sendWebhook: true,
          kycSchema: 'PersonalDataAndDocuments',
        },
        {
          headers: {
            authorization: `Bearer ${ls.get(constant.AUTH_CICO_TOKEN_KEY)}`,
          },
        }
      );

      if (response.data) {
        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: 'KYC Status updated',
          status: 'success',
          duration: 18000,
          isClosable: true,
        });
        refetch();
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Error',
        description: error?.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex gridGap="1rem" mt="2rem">
      <Button
        bg="#ED2E7E"
        minH="55px"
        minW="120px"
        onClick={() => handleKycStatus('expired')}
        disabled={loading}
        _hover={{
          border: '0px',
        }}
      >
        Expire
      </Button>

      <Button
        bg="#ED1C00"
        minH="55px"
        minW="120px"
        onClick={() => handleKycStatus('rejected')}
        disabled={loading}
        _hover={{
          border: '0px',
        }}
      >
        Reject
      </Button>

      <Button
        minH="55px"
        minW="120px"
        disabled={loading}
        onClick={() => handleKycStatus('approved')}
      >
        Approve
      </Button>
    </Flex>
  );
};

export default ApproveDeclineCicoKYC;
