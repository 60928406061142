import { usePagination } from '@ajna/pagination';
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td, Flex, Image } from '@chakra-ui/react';
import { Loader, PaginationWrapper, ScrollRightContainer } from 'components';
import { gamingDefaultImg } from 'constant/supportedCoins';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAllGamesQuery } from 'store/services/gaming.service';
import { formatDateToUTC } from 'utils';

const GamingData = ({ isCompleted = false, status }) => {
  const limit = 15;
  const [page, setPage] = useState(1);
  const { data, isLoading } = useAllGamesQuery({
    status,
    page,
    limit,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: limit,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  return (
    <>
      <ScrollRightContainer>
        {isLoading && <Loader />}
        <Table>
          <Thead
            bg='#F8F9F9'
            height='70px'
          >
            <Tr>
              <Th>Game Title</Th>
              <Th>Category</Th>
              <Th>Description</Th>

              <Th>Level</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data?.data?.map(({ _id, category, name, description, levelTarget, createdAt, logoUrl }, index) => {
              return (
                <Tr key={_id}>
                  <Td>
                    <Flex
                      gap='8px'
                      alignItems='center'
                    >
                      <Box width='48px'>
                        <Image
                          // src={logoUrl.includes('demo') ? logoUrl : logoUrl}
                          src={logoUrl ? logoUrl : gamingDefaultImg}
                          width='48px'
                          height='48px'
                        />
                      </Box>

                      <Box>
                        <Text
                          lineHeight='18.62px'
                          fontSize='14px'
                          textTransform='capitalize'
                        >
                          {name}
                        </Text>

                        <Text fontSize='12px'>Created: {formatDateToUTC(createdAt)}</Text>
                      </Box>
                    </Flex>
                  </Td>

                  <Td textTransform='capitalize'>{category}</Td>

                  <Td>
                    <Text maxW=''>{description}</Text>
                  </Td>

                  <Td
                    fontWeight='500'
                    fontSize='12px'
                  >
                    {levelTarget.map(({ level, reward, _id }) => {
                      return (
                        <Box key={_id}>
                          <Box>
                            Level {level} = {reward?.amount}{' '}
                            <Text
                              as='span'
                              textTransform='uppercase'
                            >
                              {reward?.currency}
                            </Text>
                          </Box>{' '}
                        </Box>
                      );
                    })}
                  </Td>

                  <Td>
                    <Flex gap='8px'>
                      <Link to={`/dashboard/gaming/${_id}`}>
                        <Text
                          color='#0D4740'
                          fontSize='12px'
                          textDecor='underline'
                          fontWeight='700'
                        >
                          View
                        </Text>
                      </Link>

                      {isCompleted && (
                        <Text
                          color='#0D4740'
                          fontSize='12px'
                          textDecor='underline'
                          fontWeight='700'
                          cursor='pointer'
                        >
                          Restart Game
                        </Text>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={isLoading ? 0 : data?.totalCount}
        currentPage={currentPage}
        setPage={setPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={pageSize}
        offset={offset}
        pages={pages}
      />
    </>
  );
};

export default GamingData;
