import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Text, useDisclosure } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  Button,
  Loader,
  PaginationWrapper,
  RemoveAdminModal,
  ScrollRightContainer,
  PageTitle,
  TableBoxWrapper,
  SearchInput,
} from 'components';
import { fetchUsers } from 'store/slices/user.slice';
import { formatDateToUTC } from 'utils';
import { AddAdminSVG, EditSVG, RemoveSVG } from 'assets';
import { useDebounce } from 'hooks';

/**
  All Admin page
**/
const Account = () => {
  const dispatch = useDispatch();

  const {
    user: { data, totalCount, status },
  } = useSelector((state) => state.allUsers);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchString, setSearchString] = useState('');
  const [modalData, setModalData] = useState({
    _id: '',
    fullName: '',
    username: '',
  });

  const handleOpen = (modalData) => {
    onOpen();
    setModalData(modalData);
  };

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  useEffect(() => {
    dispatch(
      fetchUsers({
        limit: 10,
        page: currentPage,
        time: `isAdmin=${true}`,
        search: searchString,
      })
    );
  }, [dispatch, currentPage, searchString]);

  return (
    <>
      <PageTitle title='Administrators' />

      <TableBoxWrapper mt='27px'>
        <Box mb='44px'>
          <Flex
            gridGap={'24px'}
            alignItems={{ sm: 'flex-start', md: 'center' }}
            flexDir={{ sm: 'column', md: 'row' }}
          >
            <Link to='/dashboard/account/add-admin'>
              <Button
                width='145px'
                bg='#6FBE45'
                minH='45px'
              >
                <Flex
                  gap='5px'
                  alignItems='center'
                >
                  <AddAdminSVG />

                  <Text fontSize='12px'>Add Admin</Text>
                </Flex>
              </Button>
            </Link>

            <Box width='100%'>
              <SearchInput
                bg='#F9FAF9'
                width={{ base: '100%', md: '232px' }}
                onChange={debouncedChangeHandler}
                placeholder='Search'
              />
            </Box>
          </Flex>
        </Box>

        <Box>
          <Box>
            <>
              {status !== 'fulfilled' && (
                <Box margin='8px 0'>
                  <Loader />
                </Box>
              )}

              <ScrollRightContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Full name</Th>
                      <Th>Username</Th>
                      <Th>Email address</Th>
                      <Th>Date added</Th>
                      <Th>Role</Th>
                      <Th>Level</Th>
                      <Th textAlign='center'>Action</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {data &&
                      data.map(({ _id, firstName, lastName, username, email, role, createdAt }) => {
                        const data = {
                          _id: _id,
                          fullName: `${firstName} ${lastName}`,
                          username,
                        };

                        return (
                          <Tr key={_id}>
                            <Td>
                              {firstName} {lastName}
                            </Td>

                            <Td>{username}</Td>

                            <Td>{email}</Td>

                            <Td>{formatDateToUTC(createdAt).split(',')[0]}</Td>

                            <Td textTransform={'capitalize'}>{role?.group}</Td>

                            <Td>{role?.level}</Td>

                            <Td>
                              <Flex
                                gap='20px'
                                justifyContent='center'
                              >
                                <Box>
                                  <Link to={`/dashboard/account/edit-admin/${_id}`}>
                                    <Flex gap='5px'>
                                      <EditSVG />

                                      <Text>Edit</Text>
                                    </Flex>
                                  </Link>
                                </Box>

                                <Box
                                  onClick={() => handleOpen(data)}
                                  cursor='pointer'
                                >
                                  <Flex gap='4px'>
                                    <RemoveSVG />

                                    <Text>Remove</Text>
                                  </Flex>
                                </Box>
                              </Flex>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </ScrollRightContainer>

              <PaginationWrapper
                setCurrentPage={setCurrentPage}
                totalDataCount={totalCount}
                currentPage={currentPage}
                isDisabled={isDisabled}
                pagesCount={pagesCount}
                size={pageSize}
                offset={offset}
                pages={pages}
              />
            </>
          </Box>
        </Box>
      </TableBoxWrapper>

      {modalData._id && (
        <RemoveAdminModal
          isOpen={isOpen}
          onClose={onClose}
          data={modalData}
        />
      )}
    </>
  );
};

export default Account;
