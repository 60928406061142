import { Box, Flex, Text } from '@chakra-ui/react';
import { OverviewCard } from 'components';
import CryptoWalletPNG from 'assets/png/crypto-wallet.png';

import {
  FiatWalletSVG,
  TotalCryptoGraphSVG,
  TotalFiatGraphSVG,
  TotalTradesGraphSVG,
  TotalTradesSVG,
  TotalUsersGraphSVG,
  TotalUsersSVG,
} from 'assets';
import { useSelector } from 'react-redux';

const Overview = () => {
  const {
    totalUserCount: { totalCount },
  } = useSelector((state) => state.allUsers);
  return (
    <Box mt='33px'>
      <Text
        mb='15px'
        fontFamily='PolySans'
        fontSize='18px'
        fontWeight='600'
        textAlign={{ sm: 'center', md: 'initial' }}
      >
        Overview
      </Text>

      <Flex
        gridGap='12px'
        flexWrap={{ sm: 'wrap', md: 'initial' }}
        justifyContent={{ sm: 'center', md: 'initial' }}
      >
        <OverviewCard
          icon={<TotalUsersSVG />}
          count={totalCount}
          graph={<TotalUsersGraphSVG />}
          title='Total Users'
        />

        <OverviewCard
          icon={<TotalTradesSVG />}
          count='5,300'
          graph={<TotalTradesGraphSVG />}
          title='Total Trades'
        />

        <OverviewCard
          png={CryptoWalletPNG}
          count='1,436'
          graph={<TotalCryptoGraphSVG />}
          title='Crypto Wallets'
        />

        <OverviewCard
          icon={<FiatWalletSVG />}
          count='1,300'
          graph={<TotalFiatGraphSVG />}
          title='Fiat Wallets'
        />
      </Flex>
    </Box>
  );
};

export default Overview;
