import { nanoid } from '@reduxjs/toolkit';
import {
  AccountSVG,
  BcplSVG,
  BuySellSVG,
  CardSVG,
  CicoSVG,
  DataAirtimeSVG,
  EarnSVG,
  HomeSVG,
  P2pSVG,
  PartnersSVG,
  QrcodeSVG,
  UsersSVG,
  VerifiationSVG,
  WalletSVG,
  AccountActiveSVG,
  BcplActiveSVG,
  BuySellActiveSVG,
  CicoActiveSVG,
  HomeActiveSVG,
  P2pActiveSVG,
  PartnersActiveSVG,
  QrcodeActiveSVG,
  UsersActiveSVG,
  VerifiationActiveSVG,
  WalletActiveSVG,
  DataAirtimeActiveSVG,
  CardsActiveSVG,
  EarnActiveSVG,
  GreenboxSVG,
  GreenboxActiveSVG,
  WishlistSVG,
  WishlistActiveSVG,
} from 'assets';
import { BsTools } from 'react-icons/bs';

import { constant, ls } from 'utils';
const cicoAuth = ls.get(constant.AUTH_CICO_TOKEN_KEY);

export const sidebarLink = [
  {
    path: '/dashboard',
    icon: <HomeSVG />,
    activeIcon: <HomeActiveSVG />,
    id: nanoid(),
    name: 'Home',
    end: true,
  },
  {
    path: '/dashboard/users',
    icon: <UsersSVG />,
    activeIcon: <UsersActiveSVG />,
    id: nanoid(),
    name: 'Users',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/inflow-outflow',
    // path: '/dashboard/inflow-outflow',
    icon: <WalletSVG />,
    activeIcon: <WalletActiveSVG />,
    id: nanoid(),
    name: 'Inflow/Outflow',
    end: false,
  },
  {
    path: '/dashboard/buy-sell',
    icon: <BuySellSVG />,
    activeIcon: <BuySellActiveSVG />,
    id: nanoid(),
    name: 'Buy/Sell',
    end: false,
  },
  {
    path: '/dashboard/p2p',
    icon: <P2pSVG />,
    activeIcon: <P2pActiveSVG />,
    id: nanoid(),
    name: 'P2P',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/verification?pending',
    icon: <VerifiationSVG />,
    activeIcon: <VerifiationActiveSVG />,
    id: nanoid(),
    name: 'Verification',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/data-airtime',
    icon: <DataAirtimeSVG />,
    activeIcon: <DataAirtimeActiveSVG />,
    id: nanoid(),
    name: 'Data/Airtime',
    end: false,
  },
  {
    path: '/dashboard/cards',
    icon: <CardSVG />,
    activeIcon: <CardsActiveSVG />,
    id: nanoid(),
    name: 'Cards',
    end: false,
  },
  {
    path: '/dashboard/earn',
    icon: <EarnSVG />,
    activeIcon: <EarnActiveSVG />,
    id: nanoid(),
    name: 'Earn',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/bcpl',
    icon: <BcplSVG />,
    activeIcon: <BcplActiveSVG />,
    id: nanoid(),
    name: 'BCPL',
    end: false,
  },
  {
    path: '/dashboard/partners',
    icon: <PartnersSVG />,
    activeIcon: <PartnersActiveSVG />,
    id: nanoid(),
    name: 'Partners',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/greenbox',
    icon: <GreenboxActiveSVG />,
    activeIcon: <GreenboxSVG />,
    id: nanoid(),
    name: 'Greenbox',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/qrcode',
    icon: <QrcodeSVG />,
    activeIcon: <QrcodeActiveSVG />,
    id: nanoid(),
    name: 'QR Code',
    end: false,
  },
  {
    path: '/dashboard/account',
    icon: <AccountSVG />,
    activeIcon: <AccountActiveSVG />,
    id: nanoid(),
    name: 'Account',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/gaming',
    icon: <WishlistSVG />,
    activeIcon: <WishlistActiveSVG />,
    id: nanoid(),
    name: 'Gaming',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/disbursements',
    icon: <WalletSVG />,
    activeIcon: <WalletActiveSVG />,
    id: nanoid(),
    name: 'Disbursement',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/settings',
    icon: <AccountSVG />,
    activeIcon: <AccountActiveSVG />,
    id: nanoid(),
    name: 'Settings',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/wishlist',
    icon: <WishlistSVG />,
    activeIcon: <WishlistActiveSVG />,
    id: nanoid(),
    name: 'Wishlist',
    end: false,
    subPath: true,
  },
  // {
  //   path: '/dashboard/integrations',
  //   icon: (
  //     <MdIntegrationInstructions
  //       fontSize='24px'
  //       color='#fff'
  //     />
  //   ),
  //   activeIcon: (
  //     <MdIntegrationInstructions
  //       fontSize='24px'
  //       color='#0D4740'
  //     />
  //   ),
  //   id: nanoid(),
  //   name: 'Integrations',
  //   end: false,
  //   subPath: true,
  // },
  {
    path: cicoAuth ? '/dashboard/cico?kyc' : '/dashboard/cico',
    icon: <CicoSVG />,
    activeIcon: <CicoActiveSVG />,
    id: nanoid(),
    name: 'CICO',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/tools',
    icon: (
      <BsTools
        color='#fff'
        fontSize='20px'
      />
    ),
    activeIcon: (
      <BsTools
        color='#0D4740'
        fontSize='20px'
      />
    ),
    id: nanoid(),
    name: 'Tools',
    end: false,
    subPath: true,
  },
];

export const mobileLink = [
  {
    path: '/dashboard',
    icon: <HomeSVG />,
    activeIcon: <HomeActiveSVG />,
    id: nanoid(),
    name: 'Home',
    end: true,
  },
  {
    path: '/dashboard/users',
    icon: <UsersSVG />,
    activeIcon: <UsersActiveSVG />,
    id: nanoid(),
    name: 'Users',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/inflow-outflow',
    icon: <WalletSVG />,
    activeIcon: <WalletActiveSVG />,
    id: nanoid(),
    name: 'Wallet',
    end: false,
  },
  {
    path: '/dashboard/p2p',
    icon: <P2pSVG />,
    activeIcon: <P2pActiveSVG />,
    id: nanoid(),
    name: 'P2P',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/verification?pending',
    icon: <VerifiationSVG />,
    activeIcon: <VerifiationActiveSVG />,
    id: nanoid(),
    name: 'Verification',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/data-airtime',
    icon: <DataAirtimeSVG />,
    activeIcon: <DataAirtimeActiveSVG />,
    id: nanoid(),
    name: 'Data/Airtime',
    end: false,
  },
  {
    path: '/dashboard/cards',
    icon: <CardSVG />,
    activeIcon: <CardsActiveSVG />,
    id: nanoid(),
    name: 'Cards',
    end: false,
  },
  {
    path: '/dashboard/earn',
    icon: <EarnSVG />,
    activeIcon: <EarnActiveSVG />,
    id: nanoid(),
    name: 'Earn',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/partners',
    icon: <PartnersSVG />,
    activeIcon: <PartnersActiveSVG />,
    id: nanoid(),
    name: 'Partners',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/greenbox',
    icon: <GreenboxActiveSVG />,
    activeIcon: <GreenboxSVG />,
    id: nanoid(),
    name: 'Greenbox',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/account',
    icon: <AccountSVG />,
    activeIcon: <AccountActiveSVG />,
    id: nanoid(),
    name: 'Account',
    end: false,
    subPath: true,
  },
  {
    path: '/dashboard/settings',
    icon: <AccountSVG />,
    activeIcon: <AccountActiveSVG />,
    id: nanoid(),
    name: 'Settings',
    end: false,
    subPath: true,
  },

  {
    path: cicoAuth ? '/dashboard/cico?kyc' : '/dashboard/cico',
    icon: <CicoSVG />,
    activeIcon: <CicoActiveSVG />,
    id: nanoid(),
    name: 'CICO',
    end: false,
    subPath: true,
  },
];
