import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Flex } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import { useGetAllKycQuery } from 'store/services/cico.service';
import { constant, formatDateToUTC, ls } from 'utils';
import {
  FilterSelect,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  SearchInput,
  TableDataStatus,
  ViewMore,
} from 'components';
import { useDebounce } from 'hooks';

const CicoKYC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  // const [level, setLevel] = useState('');
  const [status, setStatus] = useState('');

  const [searchString, setSearchString] = useState('');

  const { data, isLoading, isSuccess, isError, error } = useGetAllKycQuery({
    page: page,
    status,
    search: searchString,
    // level,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  useEffect(() => {
    if (isError && error.data.error === 'SessionExpired') {
      ls.remove(constant.AUTH_CICO_TOKEN_KEY);
      navigate('/dashboard/cico', {
        replace: true,
      });
    }
  }, [error, isError, navigate]);

  // const handleLevel = (e) => {
  //   setLevel(e.target.value);
  // };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      console.log('a search');
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  return (
    <Box>
      <Flex
        alignItems='center'
        gridGap='16px'
        mb='24px'
      >
        <Box
          maxWidth='420px'
          width='100%'
        >
          <SearchInput
            onChange={debouncedChangeHandler}
            placeholder='Search '
          />
        </Box>

        {/* <FilterSelect
          width='120px'
          onChange={handleLevel}
        >
          <option>Level</option>
          <option value='one'>One</option>
          <option value='two'>Two</option>
          <option value='three'>Three</option>
        </FilterSelect> */}

        <FilterSelect
          width='120px'
          onChange={handleStatus}
        >
          <option value=''>Status</option>
          <option value='approved'>Approved</option>
          <option value='pending'>Pending</option>
          <option value='expired'>Expired</option>
        </FilterSelect>
      </Flex>

      {isLoading && <Loader />}

      {isSuccess && (
        <>
          <ScrollRightContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Fullname</Th>
                  <Th>Phone number</Th>
                  <Th>Country</Th>
                  <Th>Address</Th>
                  <Th>Client</Th>
                  <Th>Status</Th>
                  <Th>Level</Th>
                  <Th>Date</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.data.map(
                    ({
                      _id,
                      firstName,
                      lastName,
                      middleName,
                      country,
                      createdAt,
                      phoneNumber,
                      documentStatus,
                      addressData,
                      coverage,
                      client,
                    }) => {
                      const fullName = `${firstName} ${middleName ? middleName : ''} ${lastName}`;

                      return (
                        <Tr key={_id}>
                          <Td textTransform='capitalize'>{firstName ? fullName : 'N/A'}</Td>

                          <Td>{phoneNumber ? phoneNumber : 'N/A'}</Td>

                          <Td textTransform='uppercase'>{country}</Td>

                          <Td>{addressData ? `${addressData?.address1}, ${addressData?.city}` : 'N/A'}</Td>

                          <Td textTransform=''>{client}</Td>

                          <Td>
                            <TableDataStatus status={documentStatus} />
                          </Td>

                          <Td textTransform='capitalize'>{coverage[0].level}</Td>

                          <Td>{formatDateToUTC(createdAt).split(',')[0]}</Td>

                          <Td>
                            <Link to={`/dashboard/cico/kyc/${_id}`}>
                              <ViewMore>View more</ViewMore>
                            </Link>
                          </Td>
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table>
          </ScrollRightContainer>

          <PaginationWrapper
            setCurrentPage={setCurrentPage}
            totalDataCount={isLoading ? 0 : data?.totalCount}
            currentPage={currentPage}
            setPage={setPage}
            isDisabled={isDisabled}
            pagesCount={pagesCount}
            size={pageSize}
            offset={offset}
            pages={pages}
          />
        </>
      )}
    </Box>
  );
};

export default CicoKYC;
