import { FiatDepositHistory, CryptoDepositHistory } from 'components';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

const UserDeposit = ({ userId }) => {
  return (
    <Tabs isFitted>
      <TabList border="none" maxWidth="395px">
        <Tab
          fontWeight="500"
          _selected={{
            borderBottom: '0.5px solid rgb(202, 203, 203)',
            bg: 'rgba(224, 240, 216, 0.5)',
          }}
        >
          Fiat
        </Tab>
        <Tab
          fontWeight="500"
          _selected={{
            borderBottom: '0.5px solid rgb(202, 203, 203)',
            bg: 'rgba(224, 240, 216, 0.5)',
          }}
        >
          Crypto
        </Tab>
      </TabList>

      <TabPanels mt="1rem">
        <TabPanel>
          <FiatDepositHistory userId={userId} />
        </TabPanel>

        <TabPanel>
          <CryptoDepositHistory userId={userId} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default UserDeposit;
