import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, Box, Text, Flex } from '@chakra-ui/react';
import { CloseModal } from 'components';
import { formatDateToUTC } from 'utils';

const GreenboxDetailsModal = ({ modalData, isOpen, onClose }) => {
  const { userDetails, product, unit } = modalData;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='680px'
          padding={{ base: '32px 8px', md: '32px 90px' }}
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
            fontFamily='PolySans'
          >
            Greenbox Details
          </ModalHeader>

          <ModalBody bg='#fff'>
            <BoxInput
              label='Greenbox Name'
              value={product?.title}
            />

            <BoxInput
              label='Greenbox Sender'
              value={userDetails?.username}
              link={`/dashboard/users/${userDetails?.userId}`}
            />

            <Flex gridGap='24px'>
              <BoxInput
                label='Gift Amount Per User'
                value={product?.amtPerUser}
              />
              <BoxInput
                label='Coin Type'
                value={unit}
                textTransform='uppercase'
              />
            </Flex>

            <Flex gridGap='24px'>
              <BoxInput
                label='Recipients'
                value={product?.totalRecipients}
              />
              <BoxInput
                label='Distribution'
                value={product?.distributionType}
                textTransform='capitalize'
              />
            </Flex>

            <Flex gridGap='24px'>
              <BoxInput
                label='Start Date'
                value={formatDateToUTC(product?.startDate)}
              />
              <BoxInput
                label='Expiration Date'
                value={formatDateToUTC(product?.expirationDate)}
              />
            </Flex>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GreenboxDetailsModal;

const BoxInput = ({ label, value, textTransform, link }) => {
  return (
    <Box
      mb='12px'
      width='100%'
      borderBottom='0.596281px solid rgba(202, 203, 203, 0.57)'
      filter='drop-shadow(0px 5.96281px 35.7769px rgba(67, 55, 55, 0.12))'
      padding={{ base: '0', md: '14px' }}
    >
      <Text
        fontSize='15px'
        color='#797A7A'
        fontWeight='400'
        mb='5px'
      >
        {label}
      </Text>

      {link ? (
        <a
          href={link}
          target='_blank'
          rel='noreferrer'
        >
          <Text
            fontWeight='500'
            fontSize='20px'
            textTransform={textTransform ? textTransform : 'initial'}
          >
            {value}
          </Text>
        </a>
      ) : (
        <Text
          fontWeight='500'
          fontSize='20px'
          textTransform={textTransform ? textTransform : 'initial'}
        >
          {value}
        </Text>
      )}
    </Box>
  );
};
