import { useEffect, useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { read, utils } from 'xlsx';
import {
  ScrollRightContainer,
  ExportStaffWithdrawal,
  DeleteBatchPaymentRecord,
  ProcessStaffWithdrawalModal,
  ProcessAllWithdrawalModal,
  PageTitle,
  TableDataStatus,
  ReUploadBatchFile,
  Refresh,
} from 'components';
import BatchUpload from './BatchUpload';

const StaffBatchPayment = () => {
  const [refresh, setRefresh] = useState(0);
  const [userChoice, setUserChoice] = useState(false);
  const getStorage = localStorage.getItem('batch');
  const getColumn = localStorage.getItem('batchColumn');

  const [localStorageData, setLocalStorageData] = useState(JSON.parse(getStorage));

  const [columnData, setColumnData] = useState(JSON.parse(getColumn));
  const [setup, setSetup] = useState({
    pin: '',
    statement: '',
  });

  function handleFile(e) {
    const checkUserChoice = window.confirm(
      'Are you sure you want to upload a new sheet? If Yes, you will lose previous data'
    );

    if (checkUserChoice) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data);

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const jsonData = utils.sheet_to_json(worksheet, {
          header: 1,
          defval: 'N/A',
        });

        const records = [];

        const headers = jsonData[0];

        jsonData.forEach((data, index) => {
          if (index !== 0) {
            const recordObject = {
              status: '',
            };

            headers.forEach((d, i) => {
              recordObject[d.toLowerCase()] = data[i] || '';
            });

            records.push(recordObject);
          }
        });

        setLocalStorageData(localStorage.setItem('batch', JSON.stringify(records)));

        setColumnData(localStorage.setItem('batchColumn', JSON.stringify(headers)));
      };

      reader?.readAsArrayBuffer(file);
      setRefresh(refresh + 1);
    }
  }

  const onProcess = (i) => {
    localStorageData[i].status = 'processed';

    localStorage.setItem('batch', JSON.stringify(localStorageData));
    setLocalStorageData(localStorageData);
    setRefresh(refresh + 1);
  };

  const onProcessAll = (res) => {
    const filterSuccessfulTransaction = localStorageData.filter(({ address, username, email }) => {
      return res.some((f) => {
        return f.identifier === address || f.identifier === username || f.identifier === email;
      });
    });

    filterSuccessfulTransaction.forEach((_, index) => {
      return (localStorageData[index].status = 'processed');
    });

    setTimeout(() => {
      localStorage.setItem('batch', JSON.stringify(localStorageData));
      setLocalStorageData(localStorageData);
      setRefresh(refresh + 1);
    }, res.length * 100);
  };

  const handleDelete = (index) => {
    const filter = localStorageData.filter((_, i) => index !== i);

    localStorage.setItem('batch', JSON.stringify(filter));

    setLocalStorageData(JSON.parse(getStorage));
  };

  const checkPendingWithdrawals = localStorageData?.filter(({ status }) => status.toLowerCase() === 'pending');

  useEffect(() => {
    setLocalStorageData(JSON.parse(getStorage));
    setColumnData(JSON.parse(getColumn));
    setUserChoice(userChoice);
  }, [getColumn, getStorage, userChoice]);

  const [isLoading, setIsLoading] = useState(false);
  const handleRefresh = () => {
    setLocalStorageData(JSON.parse(getStorage));
    setColumnData(JSON.parse(getColumn));
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(2000);
    };
  };

  return (
    <>
      {((localStorageData && localStorageData.length === 0) || localStorageData === null) && (
        <>
          <PageTitle
            fontSize='16px'
            title='Batch Payment'
            header={false}
          />

          <Box mt='43px'>
            <BatchUpload onChange={handleFile} />
          </Box>
        </>
      )}

      {localStorageData && (
        <>
          <Flex
            alignItems='center'
            justifyContent='space-between'
          >
            <Box>
              <Flex
                gridGap='14px'
                alignItems={{ base: 'flex-start', md: 'center' }}
                flexDir={{ sm: 'column', md: 'row' }}
              >
                <Flex
                  alignItems='center'
                  justifyContent='space-between'
                  width='100%'
                  mb={{ sm: '14px', md: '0' }}
                >
                  <PageTitle
                    fontSize='16px'
                    title='Batch Payment'
                    header={false}
                  />

                  <Flex
                    gridGap='8px'
                    display={{ sm: 'flex', md: 'none' }}
                  >
                    <Box>
                      <ReUploadBatchFile onChange={handleFile} />
                    </Box>

                    <Box>
                      <Refresh
                        isLoading={isLoading}
                        onClick={handleRefresh}
                      />
                    </Box>
                  </Flex>
                </Flex>

                <Flex gridGap='14px'>
                  {checkPendingWithdrawals?.length >= 1 && (
                    <ProcessAllWithdrawalModal
                      onProcessAll={onProcessAll}
                      localStorageData={localStorageData}
                    />
                  )}

                  <ExportStaffWithdrawal localStorageData={localStorageData} />
                </Flex>
              </Flex>
            </Box>

            <Flex
              gridGap='8px'
              display={{ sm: 'none', md: 'flex' }}
            >
              <Box>
                <ReUploadBatchFile onChange={handleFile} />
              </Box>

              <Box>
                <Refresh
                  isLoading={isLoading}
                  onClick={handleRefresh}
                />
              </Box>
            </Flex>
          </Flex>

          <ScrollRightContainer>
            <Table mt='2rem'>
              <Thead>
                <Tr>
                  {columnData &&
                    columnData?.map((response, index) => {
                      return <Th key={index}>{response}</Th>;
                    })}
                  {columnData && <Th>Action</Th>}
                </Tr>
              </Thead>

              <Tbody>
                {localStorageData &&
                  localStorageData?.map((res, i) => {
                    return (
                      <Tr key={Math.random() * 10000000}>
                        {columnData?.map((column, _i) => {
                          const col = column.toLowerCase();

                          return (
                            <Td key={Math.random() * 10000000}>
                              {res[col] === 'Pending' || res[col] === 'processed' ? (
                                <TableDataStatus status={String(res['status']).toLowerCase()} />
                              ) : (
                                <>{res[col]}</>
                              )}
                            </Td>
                          );
                        })}

                        <Td key={Math.random() * 10000000}>
                          <Flex
                            alignItems='center'
                            gridGap='8px'
                          >
                            <ProcessStaffWithdrawalModal
                              onProcess={() => onProcess(i)}
                              data={res}
                              setup={setup}
                              setSetup={setSetup}
                            />

                            <DeleteBatchPaymentRecord handleDelete={() => handleDelete(i)} />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </ScrollRightContainer>
        </>
      )}
    </>
  );
};

export default StaffBatchPayment;
