import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  VStack,
  useToast,
  Textarea,
  Select,
} from '@chakra-ui/react';

import { Button, CloseModal } from 'components';
import { put } from 'utils/axiosLib';
import { fetchSingleDocument } from 'store/slices/user.slice';
import { denyDocumentReason } from 'utils';
import { BASE_URL } from 'constant';

const DenyDoCumentModal = ({ isOpen, onClose, id, headers }) => {
  const [loading, setLoading] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [reason, setReason] = useState(selectValue);
  const dispatch = useDispatch();
  const toast = useToast();

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const handleSelectValue = (e) => {
    setSelectValue(e.target.value);
    setReason(e.target.value);
  };

  const handleDenyDocument = async () => {
    setLoading(true);
    const URL = `${BASE_URL}/admin/deny/document/${id}`;

    try {
      const response = await put(
        URL,
        {
          reason,
        },
        headers
      );

      if (response.status === 204) {
        setLoading(false);
        onClose();
        dispatch(fetchSingleDocument({ id }));
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'error',
        description: error.error.message ? error.error.message : error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        bg='#fff'
        maxWidth='420px'
        padding='40px 40px 20px'
      >
        <Text
          fontSize='24px'
          textAlign='center'
          fontWeight='600'
        >
          Reason
        </Text>

        <ModalBody
          padding='0px'
          fontSize='14px'
        >
          <VStack>
            <Box margin='1rem 0'>
              <Select
                width='100%'
                onChange={handleSelectValue}
              >
                {denyDocumentReason?.map(({ title, description }, index) => {
                  return (
                    <option
                      key={index}
                      value={`${description}`}
                    >
                      {title}
                    </option>
                  );
                })}
              </Select>

              <Textarea
                placeholder='Reason'
                onChange={handleChange}
                value={reason}
                name='reason'
                isRequired
                width='100%'
                height='150px'
                m='32px 0 0px'
                padding='13px 22px'
                _focus={{
                  borderColor: '#06C3448f',
                }}
              />
            </Box>

            <Button
              mt='2rem'
              disabled={reason.length <= 2}
              isLoading={loading}
              onClick={handleDenyDocument}
            >
              Yes
            </Button>
          </VStack>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DenyDoCumentModal;
