import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VStack, Box, Text, Flex, Image, useToast } from '@chakra-ui/react';
import { Button, Input, InputSelect, LevelTarget, TableBoxWrapper, ChallengeIconUpload, HistoryTab } from 'components';
import { badgesIcon } from 'constant/supportedCoins';
import { useCreateGameMutation } from 'store/services/gaming.service';
import { uploadFile } from 'utils';

const CreateGame = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState();
  const [state, setState] = useState({
    name: '',
    description: '',
    category: 'badge',
    label: '',
    maxPoint: 0,
  });

  const [selected, setSelected] = useState({
    icon: '',
    label: '',
    title: '',
  });

  const [levelTarget, setLevelTargets] = useState([
    {
      level: 0,
      target: 0,
      description: '',
      file: null,
      reward: {
        currency: '',
        amount: 0,
      },
    },
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleLevelTargetChange = (index, name, value) => {
    const newLevelTargets = [...levelTarget];
    if (name === 'currency' || name === 'amount') {
      newLevelTargets[index] = {
        ...newLevelTargets[index],
        reward: {
          ...newLevelTargets[index].reward,
          [name]: value,
        },
      };
    } else {
      newLevelTargets[index] = {
        ...newLevelTargets[index],
        [name]: value,
      };
    }
    setLevelTargets(newLevelTargets);
  };

  const handleAddLevelTarget = () => {
    setLevelTargets([
      ...levelTarget,
      {
        level: 0,
        target: 0,
        reward: {
          currency: 'usdt',
          amount: 0,
        },
      },
    ]);
  };

  const handleDelete = (index) => {
    const updatedTarget = levelTarget.filter((_, _index) => index !== _index);

    setLevelTargets(updatedTarget);
  };

  const [logoLoading, setLogoLoading] = useState(false);
  const [createGame, { isLoading }] = useCreateGameMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLogoLoading(true);

    const _logoUrl = selectedFile ? (await uploadFile(selectedFile, selectedFile?.name)).location : selected.icon;

    const { category, description, name, maxPoint } = state;

    const updateTarget = [...levelTarget];
    for (let i = 0; i < updateTarget.length; i++) {
      if (updateTarget[i].file) {
        const levelLogo = await uploadFile(updateTarget[i].file);
        updateTarget[i] = {
          ...updateTarget[i],
          logoUrl: levelLogo.location,
        };
      }
    }

    try {
      const response = await createGame({
        name,
        description,
        category,
        levelTarget: updateTarget,
        maxPoint: Number(maxPoint),
        logoUrl: _logoUrl,
        label: selected.label,
      }).unwrap();

      setLogoLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response?.message,
        status: 'success',
        duration: 7000,
        isClosable: true,
      });
      navigate('/dashboard/gaming');
    } catch (error) {
      setLogoLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.data?.error,
        status: 'error',
        duration: 7000,
        isClosable: true,
      });
    }
  };

  const handleBadge = (badgeDetails) => {
    setSelected(badgeDetails);

    setSelectedFile(undefined);
  };

  return (
    <>
      <HistoryTab
        firstText='Create Game'
        secondText=''
        url='/dashboard/gaming'
        mb='16px'
      />

      <TableBoxWrapper
        borderRadius='10px'
        padding='24px 24px 64px'
        bg='#FCFDFD'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
      >
        <form onSubmit={handleSubmit}>
          <VStack
            alignItems='revert-layer'
            spacing='16px'
          >
            <Input
              label='Title'
              name='name'
              placeholder='Name'
              labelSize='16px'
              onChange={handleChange}
              maxW='590px'
              isRequired
            />

            <Input
              label='Description/Instruction'
              name='description'
              placeholder='Description'
              labelSize='16px'
              maxW='590px'
              onChange={handleChange}
              isRequired
            />

            <Box>
              <Text
                fontSize='16px'
                fontWeight='400'
                mb='10px'
                color='#303131'
              >
                Select Badge
              </Text>

              <Flex
                columnGap='24px'
                rowGap='16px'
                flexWrap='wrap'
              >
                {badgesIcon.map((badge) => {
                  const { icon, label, title } = badge;
                  return (
                    <Box
                      key={label}
                      onClick={() => handleBadge(badge)}
                      borderRadius='10px'
                      bg={selected.label === label ? 'rgba(229, 229, 229, 0.47)' : '#fff'}
                      boxShadow={selected ? '2px 2px 16px 1px rgba(50,54,57,0.15)' : 'none'}
                      border='1px solid #CACBCA'
                      width='250px'
                      transition='transform 0.45s ease-out, all 0.4s ease-in'
                      _hover={{
                        boxShadow: '2px 2px 16px 1px rgba(50,54,57,0.15)',
                        bg: 'rgba(229, 229, 229, 0.47)',
                      }}
                      padding='14px'
                      cursor='pointer'
                    >
                      <Flex
                        alignItems='center'
                        flexDir='column'
                      >
                        <Image
                          src={icon}
                          width={90}
                          height={90}
                          alt='label'
                        />

                        <Box>
                          <Text
                            fontSize='14px'
                            fontWeight='600'
                          >
                            {title}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  );
                })}
              </Flex>
            </Box>

            <Box maxW='590px'>
              <ChallengeIconUpload
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                defaultPreview={selected.icon}
              />
            </Box>

            <Input
              label='Maximum Point'
              name='maxPoint'
              placeholder='Maximum Point'
              step='any'
              labelSize='16px'
              maxW='590px'
              type='number'
              onChange={handleChange}
              isRequired
            />

            <Box
              maxW='590px'
              width='100%'
            >
              {levelTarget?.map(({ level, reward, target }, index) => (
                <LevelTarget
                  index={index}
                  key={index}
                  level={level}
                  target={target}
                  currency={reward.currency}
                  amount={reward.amount}
                  onChange={(name, value) => handleLevelTargetChange(index, name, value)}
                  handleAddLevelTarget={handleAddLevelTarget}
                  handleDelete={() => handleDelete(index)}
                />
              ))}
            </Box>

            <Box maxW='590px'>
              <Text
                display='block'
                mb='0.5rem'
                htmlFor='category'
                as='label'
              >
                Category
              </Text>

              <InputSelect
                minH='60px'
                fontSize='16px'
                name='category'
                id='category'
                onChange={handleChange}
                isRequired
              >
                <option value='badge'>Badges</option>
                <option value='reward'>Rewards</option>
                <option value='challenge'>Challenge</option>
              </InputSelect>
            </Box>
          </VStack>

          <Box
            mt='48px'
            maxW='590px'
          >
            <Button
              type='submit'
              width='100%'
              minH='60px'
              isLoading={isLoading || logoLoading}
              disabled={isLoading || logoLoading}
            >
              Create Game
            </Button>
          </Box>
        </form>
      </TableBoxWrapper>
    </>
  );
};
export default CreateGame;
