import { useState } from 'react';
import { Box, useToast, Stack, Grid, HStack, Flex } from '@chakra-ui/react';
import { Input, Button, FilterSelect, PageTitle, ZipQrCode } from 'components';
import { post } from 'utils/axiosLib';
import { getHeaders } from 'utils';
import { BASE_URL, supportedCoins } from 'constant';

const GenerateQrCode = () => {
  const [state, setState] = useState({
    count: 1,
    validity: 6000,
    description: '',
    reward: 0,
    unit: '',
    includeQr: false,
    unique: true,
    capacity: 1,
  });

  const [responseData, setResponseData] = useState({
    data: {
      qrCodes: [],
    },
  });

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const toast = useToast();

  const headers = getHeaders();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const {
      count,
      validity,
      description,
      reward,
      unit,
      includeQr,
      unique,
      capacity,
    } = state;
    e.preventDefault();
    setLoading(true);

    try {
      const URL = `${BASE_URL}/admin/generateReward`;

      const response = await post(
        URL,
        {
          count: Number(count),
          capacity: Number(capacity),
          reward: Number(reward),
          validity: Number(validity),
          description,
          unit,
          includeQr,
          unique,
        },

        headers
      );

      if (response.data) {
        setLoading(false);
        setDisabled(false);

        setResponseData(response.data);

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Error',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <Box padding="20px 40px">
      <PageTitle header={false} title="Generate Qr Code" />

      <Box
        as="form"
        onSubmit={handleSubmit}
        mt="2rem"
        maxWidth="780px"
        width="100%"
      >
        <Stack gridGap="1.5rem" mb="5rem">
          <HStack>
            <Input
              width="100%"
              name="count"
              type="number"
              isRequired
              placeholder="Count"
              onChange={handleChange}
            />

            <Input
              width="100%"
              name="reward"
              type="number"
              isRequired
              placeholder="Reward"
              onChange={handleChange}
            />
          </HStack>

          <HStack>
            <Input
              width="100%"
              name="capacity"
              type="number"
              isRequired
              placeholder="Capcity"
              onChange={handleChange}
            />

            <Input
              width="100%"
              name="validity"
              type="number"
              isRequired
              placeholder="Validity"
              onChange={handleChange}
            />
          </HStack>

          <HStack>
            <Flex gridGap="10px">
              <FilterSelect
                name="unit"
                placeholder="Unit"
                onChange={handleChange}
                height="56px"
                width="253px"
                textTransform="uppercase"
                _placeholder={{
                  textTransform: 'capitalize',
                }}
              >
                {supportedCoins.map(({ coin, id }) => (
                  <option key={id} value={coin}>
                    {String(coin).toUpperCase()}
                  </option>
                ))}
              </FilterSelect>

              <FilterSelect
                name="includeQr"
                height="56px"
                placeholder="Include Qr Code"
                onChange={handleChange}
                width="253px"
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </FilterSelect>

              <FilterSelect
                name="unique"
                height="56px"
                placeholder="Unique"
                onChange={handleChange}
                width="253px"
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </FilterSelect>
            </Flex>
          </HStack>

          <Input
            width="100%"
            name="description"
            type="text"
            isRequired
            placeholder="Description"
            onChange={handleChange}
          />
        </Stack>

        <Flex gridGap="39px">
          <Button
            minH="60px"
            minW="370px"
            type="submit"
            isLoading={loading}
            disabled={loading}
          >
            Generate Qr Code
          </Button>

          <ZipQrCode urls={responseData.data.qrCodes} disabled={disabled} />
        </Flex>
      </Box>

      <Grid gridTemplateColumns="repeat(4, 1fr)" gridGap="2rem" mt="3rem">
        {responseData.data.qrCodes.length >= 1 &&
          responseData &&
          responseData.data.qrCodes.slice(0, 4).map(({ code, dataUri }) => {
            return (
              <Box key={code} gridTemplateColumns="1fr 1fr 1fr 1fr">
                <img src={dataUri} alt="qrcode" />
              </Box>
            );
          })}
      </Grid>
    </Box>
  );
};

export default GenerateQrCode;
