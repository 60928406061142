import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import {
  HistoryTab,
  TradeConversation,
  P2PTradeReference,
  PageTab,
} from 'components';
import { fetchContractById } from 'store/slices/p2p.slice';
import { useLocationSearch } from 'hooks';

const P2PTradeDetails = () => {
  const { id } = useParams();
  const search = useLocationSearch();

  const linkArray = [
    {
      id: 1,
      search: '?trade',
      otherSearchValue: '',
      title: 'Trade',
      to: `/dashboard/p2p/trades/${id}`,
    },
    {
      id: 2,
      search: '?conversation',
      title: 'Conversation',
      to: `/dashboard/p2p/trades/${id}?conversation`,
    },
  ];

  const dispatch = useDispatch();

  const { tradeRef } = useSelector((state) => state.p2p);

  const { data } = tradeRef;

  useEffect(() => {
    dispatch(fetchContractById(id));
  }, [dispatch, id]);

  return (
    <Box>
      <HistoryTab
        firstText="P2P/Trades"
        secondText="Trade Details"
        url="/dashboard/p2p/trades"
      />

      <Box
        bg="#FCFDFD"
        boxShadow="0px 2px 8px rgba(229, 229, 229, 0.47)"
        borderRadius="10px"
        maxWidth="750px"
        width="100%"
        minH="700px"
        padding="28px 30px"
      >
        <PageTab linkArray={linkArray} />

        <Box>
          {search === '' && <P2PTradeReference tradeRef={tradeRef} />}

          {search === '?conversation' && (
            <TradeConversation
              tradeRefId={data?.tradeContract && data.tradeContract.tradeRef}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default P2PTradeDetails;
