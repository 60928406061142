import { Box, Flex, Text } from '@chakra-ui/react';
import { GreenboxClaimedSVG, GreenboxCreatedSVG } from 'assets';
import { StatCard } from 'components';
import { useGetAllWishlistQuery } from 'store/services/wishlist.service';

const WishlistStat = () => {
  const { data: oldUsers } = useGetAllWishlistQuery({
    criteria: 'oldUsers',
  });

  const { data: newUsers } = useGetAllWishlistQuery({
    criteria: 'newUsers',
  });

  return (
    <Box mb='40px'>
      <Text
        mb='18.5px'
        fontWeight='600'
        fontSize='18px'
      >
        Stat
      </Text>

      <Flex gap='24px'>
        <StatCard
          icon={<GreenboxCreatedSVG />}
          count={`${oldUsers?.totalCount ?? 0} Items`}
          title='Old Users Wishlist'
        />

        <StatCard
          icon={<GreenboxClaimedSVG />}
          title='New Users Wishlist'
          count={`${newUsers?.totalCount ?? 0} Items`}
        />
      </Flex>
    </Box>
  );
};

export default WishlistStat;
