import { Select as ChakraSelect, Box, Text } from '@chakra-ui/react';

const FilterSelect = ({ onChange, defaultValue, htmlFor, children, label, ...props }) => {
  return (
    <Box>
      {label && (
        <Text
          display='block'
          mb='0.5rem'
          fontSize='14px'
          fontWeight='400'
          htmlFor={htmlFor}
          as='label'
        >
          {label}
        </Text>
      )}

      <ChakraSelect
        defaultValue={defaultValue}
        id={htmlFor}
        _hover={{
          bg: '#F2F4F3',
        }}
        _focus={{
          bg: '#F2F4F3',
          borderWidth: '1.5px',
          borderColor: '#6FBE45',
        }}
        bg='#F2F4F3'
        borderRadius='15px'
        borderWidth='1.5px'
        minW='79px'
        fontSize='14px'
        minH='48px'
        fontWeight='400'
        onChange={onChange}
        _active={{
          borderWidth: '1px',
        }}
        // _FOCU={{
        //   borderWidth: '1px',
        // }}
        {...props}
      >
        {children}
      </ChakraSelect>
    </Box>
  );
};

export default FilterSelect;
