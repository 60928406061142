import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import { BoxInput } from 'components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resolveBvn } from 'store/slices/user.slice';

const ResolveBVN = ({ bvn }) => {
  const { status, errorMessage, data } = useSelector((state) => state.allUsers.resolveBvn);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resolveBvn(bvn));
  }, [dispatch, bvn]);

  return (
    <Box>
      <Text mb='12px'>BVN</Text>

      <Accordion
        allowToggle
        borderBottom='0.5px solid #CACBCB'
        borderRadius='8px'
        bg='#FCFDFD'
      >
        <AccordionItem border='none'>
          <AccordionButton
            as='div'
            p='16px'
            _expanded={{ bg: 'rgba(0, 0, 0, 0.04)' }}
            transition='all 300ms ease-in-out'
            cursor='pointer'
          >
            <Box
              flex='1'
              textAlign='left'
              fontWeight='600'
            >
              {bvn}
            </Box>

            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel
            fontSize='14px'
            fontWeight='500'
          >
            {status === 'error' && <>{errorMessage}</>}

            {status === 'fulfilled' && (
              <Box padding='14px 0'>
                <BoxInput
                  label='First Name'
                  value={data?.firstName}
                />

                <BoxInput
                  label='Middle Name'
                  value={data?.middleName}
                />

                <BoxInput
                  label='Last Name'
                  value={data?.lastName}
                />

                <BoxInput
                  label='Gender'
                  value={data?.gender}
                />
                <BoxInput
                  label='Date of Birth'
                  value={data?.dob}
                />

                <BoxInput
                  label='Phone Number'
                  value={data?.phone}
                />

                <BoxInput
                  label='Address'
                  value={data?.address}
                />

                <BoxInput
                  label='State of Origin'
                  value={data?.stateOfOrigin}
                />
                <BoxInput
                  label='State of Residence'
                  value={data?.stateOfResidence}
                />

                <BoxInput
                  label='LGA Origin'
                  value={data?.lgaOfOrigin}
                />
                <BoxInput
                  label='LGA of Residence'
                  value={data?.lgaOfResidence}
                />

                <BoxInput
                  label='Nationality'
                  value={data?.nationality}
                />

                <Box>
                  {data?.photo?.map((res, i) => {
                    return (
                      <Flex
                        key={i}
                        alignItems='center'
                        justifyContent='space-between'
                        mb='24px'
                      >
                        <Box>
                          Photo-
                          <Text
                            textTransform='uppercase'
                            as='span'
                          >
                            {res.image_type}
                          </Text>{' '}
                        </Box>

                        <Image
                          width='200px'
                          height='200px'
                          borderRadius='full'
                          src={`data:image/jpeg;base64,${res.url}`}
                        />
                      </Flex>
                    );
                  })}
                </Box>
              </Box>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default ResolveBVN;
