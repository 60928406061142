import { Box, Center, Flex, Image, Text, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { HistoryTab, Loader, PageTitle, TableBoxWrapper } from 'components';
import { useGamesIdQuery } from 'store/services/gaming.service';
import { useParams } from 'react-router-dom';
import { gamingDefaultImg } from 'constant/supportedCoins';
import UpdateLevelModal from 'components/Modals/UpdateLevelModal';
import GamingCard from './GamingCard';
import UpdateGameDetails from 'components/Modals/UpdateGameDetails';

const GamingDetails = () => {
  const { id } = useParams();
  const { data, isSuccess, isLoading } = useGamesIdQuery(
    {
      id,
    },
    {
      skip: id ? false : true,
    }
  );

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [modalData, setModalData] = useState({});

  const handleOpen = (_modalData) => {
    setModalData(_modalData);
    onOpen();
  };

  return (
    <Box>
      <HistoryTab
        firstText=''
        secondText=''
        url='/dashboard/gaming'
        mb='24px'
      />
      <Flex
        justifyContent='space-between'
        alignItems='center'
        mb='24px'
      >
        <PageTitle title={isSuccess ? data?.name : ''} />

        {isSuccess && (
          <UpdateGameDetails
            id={id}
            name={data?.name}
            description={data?.description}
            logoUrl={data?.logoUrl ? data?.logoUrl : gamingDefaultImg}
            maxPoint={data?.maxPoint}
          />
        )}
      </Flex>

      <TableBoxWrapper padding='40px 24px'>
        {isLoading && <Loader />}

        {isSuccess && (
          <Box>
            <Center
              borderRadius='8px'
              border='1px solid #E6E7E6'
              bg='#F4F6F5'
              width='160px'
              height='160px'
            >
              <Image
                src={data?.logoUrl ? data?.logoUrl : gamingDefaultImg}
                width='124px'
                height='124px'
                alt='item'
              />
            </Center>

            <Flex
              justifyContent='space-between'
              my='12px'
            >
              <Box maxW='503px'>
                <Flex gap='8px'>
                  <Text
                    fontSize='16px'
                    mb='4px'
                  >
                    Description
                  </Text>

                  <Text
                    fontSize='18px'
                    lineHeight='23.94px'
                    fontWeight='500'
                  >
                    {data?.description}
                  </Text>
                </Flex>

                <Flex gap='8px'>
                  <Text
                    fontSize='16px'
                    mb='4px'
                  >
                    Maximum Point:
                  </Text>

                  <Text
                    fontSize='18px'
                    fontWeight='500'
                  >
                    {data?.maxPoint}
                  </Text>
                </Flex>
              </Box>
            </Flex>

            <Flex
              gap='16px'
              flexWrap='wrap'
              mb='24px'
            >
              {data?.levelTarget.map(({ _id, reward, logoUrl, level, target, description }) => {
                const modalData = { _id, reward, logoUrl, level, target, description };
                return (
                  <GamingCard
                    key={_id}
                    description={description}
                    level={level}
                    logoUrl={logoUrl}
                    onClick={() => handleOpen(modalData)}
                    reward={reward}
                    target={target}
                  />
                );
              })}
            </Flex>
          </Box>
        )}
      </TableBoxWrapper>

      {isOpen && (
        <UpdateLevelModal
          isOpen={isOpen}
          modalData={modalData}
          onClose={onClose}
          onOpen={onOpen}
          id={id}
        />
      )}
    </Box>
  );
};

export default GamingDetails;
