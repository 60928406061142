import { useState } from 'react';
import {
  Box,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  Flex,
  Checkbox,
  Center,
} from '@chakra-ui/react';
import { ActionButton, Button, CloseModal } from 'components';
import { fetchSingleUser } from 'store/slices/user.slice';
import { put } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const AccountHealModal = ({ id, dispatch, headers }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [state, setState] = useState({
    twoFA: false,
    resetPassword: false,
  });

  const handleReset = () => {
    setState({
      ...state,
      resetPassword: !state.resetPassword,
    });
  };

  const handleTwoFa = () => {
    setState({
      ...state,
      twoFA: !state.twoFA,
    });
  };

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});

  const handleAccountHeal = async () => {
    setLoading(true);
    onOpen();
    const URL = `${BASE_URL}/admin/users/${id}/heal`;

    try {
      const response = await put(URL, state, headers);

      if (response.data) {
        setResult(response.data.data.checks);
        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        dispatch(fetchSingleUser(id));
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Success',
        description: error.error?.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <ActionButton onClick={onOpen}>Account Heal</ActionButton>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="16px" maxWidth="420px">
          <ModalHeader
            bg="#F7F7FC"
            padding="24px"
            fontWeight="600"
            borderTopRadius="16px"
            textAlign="center"
          >
            Account Heal
          </ModalHeader>

          <ModalBody>
            <Box mb="14px">
              <Flex gridGap="8px" mb="14px">
                <Checkbox isDisabled defaultChecked />

                <Text fontSize="14px" lineHeight="18px">
                  General heal
                </Text>
              </Flex>

              <Flex gridGap="8px" mb="14px">
                <Checkbox
                  onChange={handleReset}
                  value={state.resetPassword}
                  colorScheme="green"
                />

                <Text fontSize="14px" lineHeight="18px">
                  Reset Password
                </Text>
              </Flex>

              <Flex gridGap="8px">
                <Checkbox
                  onChange={handleTwoFa}
                  value={state.twoFA}
                  colorScheme="green"
                />

                <Text fontSize="14px" lineHeight="18px">
                  2FA Reset
                </Text>
              </Flex>
            </Box>

            <Grid gridTemplateColumns="1fr 1fr" margin="2rem 0">
              <Box>
                <Text as="ul" textTransform="capitalize">
                  {Object.keys(result).map((res, index) => (
                    <Text key={index} as="li">
                      {res}:
                    </Text>
                  ))}
                </Text>
              </Box>

              <Box>
                <Text as="ul">
                  {Object.values(result).map((res, index) => (
                    <Text key={index} as="li">
                      {res?.msg ? res.msg : res}
                    </Text>
                  ))}
                </Text>
              </Box>
            </Grid>

            <Center>
              <Button
                isLoading={loading}
                disabled={loading}
                minH="52px"
                onClick={handleAccountHeal}
              >
                Submit
              </Button>
            </Center>

            <CloseModal mt="1rem" onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AccountHealModal;
