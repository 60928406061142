import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, useDisclosure, Box } from '@chakra-ui/react';
import { ActionButton, FilterSelect, LevelThreeUpload, LevelTwoThreeUpload, LevelTwoUpload } from 'components';
import { useParams } from 'react-router-dom';

const UploadUserKYCModal = ({ data, headers }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();

  const [idType, setIdType] = useState('two');
  const handleSelect = (e) => {
    setIdType(e.target.value);
  };

  return (
    <Box>
      <ActionButton onClick={onOpen}>Upload KYC</ActionButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          borderRadius='16px'
          maxWidth='520px'
          h='800px'
          overflowY='scroll'
        >
          <ModalHeader
            bg='#F7F7FC'
            fontWeight='600'
            borderTopRadius='16px'
            textAlign='center'
            fontFamily='PolySans'
            mb='16px'
          >
            Upload KYC
          </ModalHeader>

          <Box
            padding='0 24px 16px'
            overflowX='scroll'
            height='780px'
          >
            <Box mb='24px'>
              <FilterSelect
                minH='58px'
                width='180px'
                onChange={handleSelect}
                defaultValue={idType}
              >
                <option value='two'>Select KYC</option>
                <option value='two'>Level Two</option>
                <option value='three'>Level Three</option>
                <option value='two_three'>Level Two and Three</option>
              </FilterSelect>
            </Box>

            {idType === 'two' && (
              <LevelTwoUpload
                headers={headers}
                data={data}
                kycLevel={idType}
                onClose={onClose}
                userId={id}
              />
            )}

            {idType === 'three' && (
              <LevelThreeUpload
                headers={headers}
                data={data}
                kycLevel={idType}
                onClose={onClose}
                userId={id}
              />
            )}

            {idType === 'two_three' && (
              <LevelTwoThreeUpload
                headers={headers}
                data={data}
                kycLevel={idType}
                onClose={onClose}
                userId={id}
              />
            )}
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UploadUserKYCModal;
