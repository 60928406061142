import { Box } from '@chakra-ui/react';
import { PageTab, PageTitle, TableBoxWrapper } from 'components';
import { useLocationSearch } from 'hooks';
import { AirtimeDataTabLinkArray } from 'LinkArray';
import { AirtimeTable, DataTable } from 'pages';

const DataAirtime = () => {
  const search = useLocationSearch();
  return (
    <Box>
      <PageTitle title="Data and Airtime" mb="52px" />

      <TableBoxWrapper>
        <PageTab linkArray={AirtimeDataTabLinkArray} />

        <Box>
          {search === '' && <DataTable />}
          {search === '?airtime' && <AirtimeTable />}
        </Box>
      </TableBoxWrapper>
    </Box>
  );
};

export default DataAirtime;
