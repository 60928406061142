import { useMemo, useState } from 'react';
import { Box, Table, Tr, Td, Th, Thead, Tbody, Flex } from '@chakra-ui/react';
import {
  FilterSelect,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  SelectWishlistUserModal,
  TableDataStatus,
} from 'components';
import { useLocationSearch } from 'hooks';
import { useGetWishlistEntryTagQuery } from 'store/services/wishlist.service';
// import { FilterSVG } from 'assets';
import { usePagination } from '@ajna/pagination';
import { formatDateToUTC } from 'utils';

const BeforeCreation = () => {
  const search = useLocationSearch();

  const queries = useMemo(() => new URLSearchParams(search), [search]);
  const [status, setStatus] = useState('');

  const cycle = queries.get('cycle') ?? '';
  const entry = queries.get('entry');
  const _id = queries.get('id');
  const criteria = queries.get('criteria');

  const [currentCycle, setCurrenCyle] = useState();

  const [page, setPage] = useState(1);
  const { data, isLoading, isSuccess } = useGetWishlistEntryTagQuery({
    page,
    entry,
    status,
    _id,
    cycle: currentCycle,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  return (
    <>
      <Box>
        <Flex
          gap='16px'
          mb='40px'
          alignItems='center'
        >
          <FilterSelect
            width='128px'
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value=''>Status</option>
            <option value='pending'>Pending</option>
            <option value='expired'>Expired</option>
            <option value='fufilled'>Fulfilled</option>
          </FilterSelect>

          <FilterSelect
            width='128px'
            onChange={(e) => setCurrenCyle(e.target.value)}
          >
            <option value=''>Cycle</option>
            <option value={cycle}>Current Cycle</option>
          </FilterSelect>

          {/* <Box
            maxW='355px'
            width='100%'
          >
            <SearchInput placeholder='Search by username or wishlist item' />
          </Box> */}

          {/* <Flex
            gridGap='5px'
            alignItems='center'
            cursor='pointer'
            _hover={{
              fontWeight: '600',
              color: '#797A7A',
            }}
          >
            <Text
              fontSize='14px'
              fontWeight='500'
            >
              Filter Current Cycle
            </Text>

            <FilterSVG />
          </Flex> */}
        </Flex>
        {isLoading && <Loader />}

        {isSuccess && (
          <>
            <ScrollRightContainer>
              <Table overflow='auto'>
                <Thead>
                  <Tr>
                    <Th>Username</Th>
                    <Th>Email Address</Th>
                    <Th>Wishlist Item</Th>
                    <Th>Transaction Volume</Th>
                    <Th>Status</Th>
                    <Th>Action</Th>
                    <Th>Date/Time</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {data?.entry?.map(
                    ({ _id, user, wishlistCycle, createdAt, userId, status, gift, tag, transactionVolume }) => {
                      return (
                        <Tr key={_id}>
                          <Td
                            color='#6FBE45'
                            textDecor='underline'
                          >
                            <a
                              href={`/dashboard/users/${userId}`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {user?.username}
                            </a>
                          </Td>

                          <Td>{user?.email}</Td>

                          <Td>{gift}</Td>

                          <Td>{transactionVolume ? `$ ${transactionVolume}` : ''}</Td>

                          <Td>
                            <TableDataStatus status={status} />
                          </Td>

                          <Td>
                            <SelectWishlistUserModal
                              userWishListId={_id}
                              username={user?.username}
                              wishlistCycle={wishlistCycle}
                              tag={tag}
                              criteria={criteria}
                            />
                          </Td>

                          <Td>{formatDateToUTC(createdAt)}</Td>
                        </Tr>
                      );
                    }
                  )}
                </Tbody>
              </Table>
            </ScrollRightContainer>

            <PaginationWrapper
              setCurrentPage={setCurrentPage}
              totalDataCount={isLoading ? 0 : data?.totalCount}
              currentPage={currentPage}
              setPage={setPage}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              size={pageSize}
              offset={offset}
              pages={pages}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default BeforeCreation;
