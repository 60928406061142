import { useState } from 'react';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { TradeRefBox, Loader, Button, ReleaseRevertTrade } from 'components';
import { formatDateToUTC } from 'utils';

const P2PTradeReference = ({ tradeRef }) => {
  const {
    data: { tradeContract },
    status,
  } = tradeRef;

  const [operation, setOperation] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  // console.log(data.counterParties, 'contract');

  const handleOpen = (value) => {
    setOperation(value);
    onOpen();
  };

  return (
    <Box>
      <Text fontSize="14px" fontWeight="600" mb="24px">
        Trade Reference
      </Text>

      <Box fontSize="14px">
        {status === 'loading' && <Loader />}

        {status === 'fulfilled' && (
          <>
            <TradeRefBox
              title="Country"
              descTextTransform="uppercase"
              description={tradeContract.currency}
            />

            <TradeRefBox
              title="Price"
              descTextTransform="uppercase"
              description={`${tradeContract.amount.toLocaleString()} ${
                tradeContract.currency
              }`}
            />

            <TradeRefBox
              title="Coin amount"
              descTextTransform="uppercase"
              description={`${tradeContract.volume} ${tradeContract.coin}`}
            />

            <TradeRefBox
              title="Fiat amount"
              description={
                <>
                  {tradeContract.currency === 'ngn' && <span>&#8358;</span>}
                  {tradeContract.amount.toLocaleString()}
                </>
              }
            />

            <TradeRefBox
              title="Type"
              descTextTransform="capitalize"
              description={tradeContract.adsType}
            />

            <TradeRefBox
              title="Taker:"
              threeGrid
              description={tradeContract.counterParties
                .filter(({ role }) => {
                  return role === 'trader';
                })
                .map(({ user }) => {
                  return user.username;
                })}
            >
              <Text color="brand.primary">Blacklist</Text>
            </TradeRefBox>

            <TradeRefBox
              threeGrid
              title="Maker:"
              description={tradeContract.counterParties
                .filter(({ role }) => {
                  return role === 'tradeowner';
                })
                .map(({ user }) => {
                  return user.username;
                })}
            >
              <Text color="brand.primary">Blacklist</Text>
            </TradeRefBox>

            <TradeRefBox
              descTextTransform="capitalize"
              title="Status:"
              color={
                tradeContract.contractState === 'open'
                  ? 'brand.warning'
                  : 'brand.error'
              }
              description={tradeContract.contractState}
            />

            <TradeRefBox
              title="Rating:"
              description={
                tradeContract.ratings.length !== 0
                  ? tradeContract.ratings.map(({ userId }) => userId.ratings)
                  : 'N/A'
              }
            />

            <TradeRefBox
              title="Status update:"
              description={tradeContract.counterParties
                .filter(({ role }) => {
                  return role === 'trader';
                })
                .map(({ timeline }) => {
                  const d = formatDateToUTC(timeline.createdAt);
                  return timeline ? `Initiated on ${d}` : 'N/A';
                })}
            />

            <TradeRefBox
              title="Transaction Start date:"
              description={tradeContract.counterParties
                .filter(({ role }) => {
                  return role === 'tradeowner';
                })
                .map(({ timeline }) => {
                  const d = formatDateToUTC(timeline.createdAt);
                  return timeline ? `${d}` : 'N/A';
                })}
            />

            <TradeRefBox
              title="Paid:"
              description={tradeContract.counterParties
                .filter(({ role }) => {
                  return role === 'trader';
                })
                .map(({ paidAt }) => {
                  return paidAt ? formatDateToUTC(paidAt) : 'N/A';
                })}
            />

            <TradeRefBox
              title="Release:"
              description={tradeContract.counterParties
                .filter(({ role }) => {
                  return role === 'tradeowner';
                })
                .map(({ releasedAt }) => {
                  return releasedAt ? releasedAt : 'N/A';
                })}
            />

            {tradeContract.contractState === 'indispute' && (
              <Flex gridGap="1rem" mt="74px">
                <Button
                  bg="#fff"
                  color="brand.primary"
                  border="2px solid #0D4740"
                  minW="200px"
                  onClick={() => handleOpen('RELEASETRADE')}
                >
                  Release
                </Button>

                <Button minW="200px" onClick={() => handleOpen('REVERT')}>
                  Revert
                </Button>
              </Flex>
            )}
          </>
        )}
      </Box>

      {status === 'fulfilled' && (
        <ReleaseRevertTrade
          operation={operation}
          isOpen={isOpen}
          onClose={onClose}
          frozenRef={tradeContract.tradeRef.split('-')[1]}
          _id={tradeContract.tradeRef.split('-')[0]}
        />
      )}
    </Box>
  );
};

export default P2PTradeReference;
