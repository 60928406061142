import { Box, Flex, Tag, Text } from '@chakra-ui/react';
import { FreezeCardModal, HistoryTab, Loader } from 'components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCardDetails } from 'store/slices/card.slice';
import { formatDateToUTC } from 'utils';

const CardDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { data, status } = useSelector((state) => state.cards.cardDetails);

  useEffect(() => {
    dispatch(fetchCardDetails({ id }));
  }, [dispatch, id]);

  const navigate = useNavigate();
  const gotoUserdetails = (id) => {
    navigate(`/dashboard/users/${id}`);
  };
  return (
    <Box>
      <HistoryTab
        firstText=''
        secondText='Card Details'
        url='/dashboard/cards'
      />

      <Box
        padding={{ base: '32px 16pxpx', tb: '32px 24px' }}
        bg='#FCFDFD'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
        borderRadius='10px'
        width='100%'
        minH='60vh'
      >
        {status === 'loading' && <Loader />}
        <Box maxW='520px'>
          {status === 'fulfilled' && (
            <>
              <BoxCard
                title='Fullname'
                value={`${data?.userDetails?.fullName}`}
                cursor='pointer'
                onClick={() => gotoUserdetails(data?.userDetails?.userId)}
              />

              <BoxCard
                title='Email'
                value={`${data?.userDetails?.email}`}
                onClick={() => gotoUserdetails(data?.userDetails?.userId)}
                cursor='pointer'
              />

              <BoxCard
                title='Date Created'
                value={formatDateToUTC(data?.createdAt)}
              />

              <BoxCard
                title='Card Version'
                value={data?.cardVersion}
              />

              <BoxCard
                title='Brand'
                value={data?.brand}
              />

              <BoxCard
                title='Card Number'
                value={data?.cardNumber}
              />
              <BoxCard
                title='Expiry'
                value={data?.expiry}
              />

              <BoxCard
                title='Balance'
                value={`${Number(data?.balance?.$numberDecimal)?.toLocaleString()}`}
              />

              <BoxCard
                title='Last Deposit'
                value={Number(data?.lastDeposit?.$numberDecimal).toLocaleString()}
              />

              <BoxCard
                title='Last Withdrawal'
                value={Number(data?.lastWithdrawal?.$numberDecimal).toLocaleString()}
              />

              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Status</Text>

                <>
                  {data?.statusTag === 'was-suspended' ? (
                    <Tag
                      fontWeight='500'
                      p='4px 12px'
                      color='brand.error'
                      border='1px solid #ED1C00'
                      bg='#fff'
                    >
                      Provider Terminated
                    </Tag>
                  ) : data?.status === 'frozen' ? (
                    <Tag
                      p='4px 12px'
                      fontWeight='500'
                      color='#303131'
                      border='1px solid #CFE9C1'
                      bg='#CFE9C1'
                    >
                      Frozen
                    </Tag>
                  ) : (
                    <Tag
                      p='4px 12px'
                      fontWeight='500'
                      color='#fff'
                      border='1px solid #31B7A9'
                      bg='#31B7A9'
                      textTransform='capitalize'
                    >
                      {data?.status}
                    </Tag>
                  )}
                </>
              </Flex>

              {data?.frozenAt && data?.status !== 'active' && (
                <BoxCard
                  title='Frozen At'
                  value={formatDateToUTC(data?.frozenAt)}
                />
              )}

              <Box mt='32px'>
                <FreezeCardModal
                  id={id}
                  status={data?.status}
                  statusTag={data?.statusTag}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CardDetails;

const BoxCard = ({ title, value, ...props }) => {
  return (
    <Flex
      justifyContent='space-between'
      mb='24px'
    >
      <Text>{title}</Text>

      <Text
        fontWeight='500'
        {...props}
      >
        {value}
      </Text>
    </Flex>
  );
};
