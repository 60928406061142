import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  Loader,
  P2PTableStatus,
  PaginationWrapper,
  ScrollRightContainer,
} from 'components';
import { formatDateToUTC } from 'utils';
import { fetchP2PContractsCancelled } from 'store/slices/p2p.slice';

const P2PAllCancelledTable = ({ date, currency, coin, searchString, size }) => {
  const dispatch = useDispatch();
  const { data, status, totalCount } = useSelector(
    (state) => state.p2p.contractsCancelled
  );

  const navigate = useNavigate();

  const handleNavigate = (id) => {
    return navigate(`/dashboard/p2p/trades/${id}`);
  };

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize: size },
      total: totalCount,
      limits: {
        inner: 2,
        outer: 1,
      },
    });

  useEffect(() => {
    dispatch(
      fetchP2PContractsCancelled({
        limit: size,
        page: currentPage,
        coin: coin === 'all' ? '' : coin,
        currency: currency === 'all' ? '' : currency,
        date:
          date.before === undefined
            ? `beforeDate=${date}`
            : `afterDate=${date.before}&beforeDate=${date.after}`,
        search: searchString,
      })
    );
  }, [coin, currency, date, searchString, dispatch, size, currentPage]);

  return (
    <>
      {status === 'loading' && <Loader />}

      <ScrollRightContainer>
        <Table>
          {data.trades.length >= 1 && (
            <>
              <Thead>
                <Tr>
                  <Th>Trade Ref. No.</Th>
                  <Th>Amount</Th>
                  <Th>Parties</Th>
                  <Th>Trade type</Th>
                  <Th>Coin traded</Th>
                  <Th>Status</Th>
                  <Th>Date/Time</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data.trades.map(
                  (
                    {
                      _id,
                      tradeRef,
                      tradeType,
                      coin,

                      createdAt,
                      contractState,

                      counterParties,
                    },
                    i
                  ) => {
                    const formattedCreatedAt = formatDateToUTC(createdAt);
                    return (
                      <Tr
                        key={i}
                        cursor="pointer"
                        onClick={() => handleNavigate(_id)}
                      >
                        <Td>{tradeRef}</Td>

                        <Td>
                          <Text>
                            To Pay:{' '}
                            <Text as="span" textTransform="uppercase">
                              {counterParties[0].unitToPay}
                            </Text>{' '}
                            {Number(
                              counterParties[0].amountToPay
                            ).toLocaleString()}
                          </Text>

                          <Text>
                            To Receive:{' '}
                            {Number(
                              counterParties[1].amountToPay
                            ).toLocaleString()}
                            <Text as="span" textTransform="uppercase">
                              {' '}
                              {counterParties[0].unitToReceive}
                            </Text>
                          </Text>
                        </Td>

                        <Td>
                          <Text fontWeight="medium">
                            <a
                              href={`/dashboard/users/${counterParties[0].user.userId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Text as="span">
                                {counterParties[0].perspective}
                              </Text>
                              : {counterParties[0].user.username}
                            </a>
                          </Text>

                          <Text fontWeight="medium">
                            <a
                              href={`/dashboard/users/${counterParties[1].user.userId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Text as="span">
                                {counterParties[1].perspective}
                              </Text>
                              : {counterParties[1].user.username}
                            </a>
                          </Text>
                        </Td>

                        <Td textTransform="uppercase">{tradeType}</Td>

                        <Td textTransform="uppercase">{coin ? coin : 'N/A'}</Td>

                        <Td>
                          <P2PTableStatus status={contractState} />
                        </Td>

                        <Td>
                          <Text>{formattedCreatedAt}</Text>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </>
          )}
        </Table>
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={size}
      />
    </>
  );
};

export default P2PAllCancelledTable;
