import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';
import {
  P2PFilter,
  PageTab,
  HistoryTab,
  TableBoxWrapper,
  P2PAllCompletedTable,
  SearchInput,
  FilterSelect,
  P2PAllCancelledTable,
  P2PAllPendingTable,
  SearchFilterFormat,
} from 'components';
import { useDebounce, useLocationSearch } from 'hooks';
import { P2pTradeTabLinkArray } from 'LinkArray';

const P2PAllTrades = () => {
  const { currency, coin, date } = useSelector((state) => state.p2p.filter);
  const [size, setSize] = useState(10);
  const search = useLocationSearch();
  const [searchString, setSearchString] = useState('');

  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
    } else {
      setSearchString('');
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  return (
    <Box>
      <HistoryTab
        firstText='Trades'
        secondText='P2P'
        url='/dashboard/p2p'
      />

      <TableBoxWrapper>
        <>
          <PageTab linkArray={P2pTradeTabLinkArray} />

          <Flex
            gridGap='9px'
            mb='32px'
            alignItems='center'
            // flexWrap={{ base: 'wrap', md: 'initial' }}
            flexDir={{ sm: 'column', md: 'row' }}
          >
            <Flex
              maxWidth='350px'
              width='100%'
              gridGap='8px'
              alignItems='center'
              mr='16px'
            >
              <SearchInput onChange={debouncedChangeHandler} />

              <Box display={{ base: 'none', tb: 'block' }}>
                <SearchFilterFormat />
              </Box>
            </Flex>

            <Flex gridGap='9px'>
              <P2PFilter />

              <FilterSelect
                width='80px'
                onChange={handleSize}
              >
                <option value={10}>Size</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </FilterSelect>
            </Flex>
          </Flex>

          <>
            {(search === '?completed' || search === '') && (
              <P2PAllCompletedTable
                date={date}
                currency={currency}
                coin={coin}
                searchString={searchString}
                size={size}
              />
            )}

            {search === '?pending' && (
              <P2PAllPendingTable
                date={date}
                currency={currency}
                coin={coin}
                searchString={searchString}
                size={size}
              />
            )}

            {search === '?cancelled' && (
              <P2PAllCancelledTable
                date={date}
                currency={currency}
                coin={coin}
                searchString={searchString}
                size={size}
              />
            )}
          </>
        </>
      </TableBoxWrapper>
    </Box>
  );
};

export default P2PAllTrades;
