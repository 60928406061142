import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePagination } from '@ajna/pagination';
import { Box, Flex, Text, Table, Th, Tr, Td, Thead, Tbody } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  FilterEarn,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  SearchInput,
  TableBoxWrapper,
  ViewMore,
} from 'components';
import { EyeSVG } from 'assets';
import { fetchAllStakings } from 'store/slices/staking.slice';
import { formatDateToUTC } from 'utils';
import { useDebounce } from 'hooks';

const EarnTable = () => {
  const dispatch = useDispatch();

  const { data, status, errorMessage, totalCount } = useSelector((state) => state.stakings.staking);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: { currentPage: 1, pageSize: 10 },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const [searchString, setSearchString] = useState('');

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  useEffect(() => {
    dispatch(
      fetchAllStakings({
        limit: 10,
        page: currentPage,
        search: searchString,
      })
    );
  }, [dispatch, currentPage, searchString]);

  return (
    <TableBoxWrapper>
      <Flex
        justifyContent={{ base: 'space-between', tb: 'initial' }}
        alignItems={{ base: 'initial', md: 'center' }}
        gridGap='10px'
        flexWrap='wrap'
      >
        <Text
          fontWeight='600'
          fontSize='1rem'
          lineHeight='28px'
          color='#797A7A'
          // width='100%'
        >
          List of Stakings
        </Text>

        <Flex
          gridGap='10px'
          alignItems={{ base: 'initial', tb: 'center' }}
          flexDir={{ base: 'column-reverse', tb: 'row' }}
        >
          <SearchInput
            width='319px'
            onChange={debouncedChangeHandler}
            placeholder='Search by username, reference'
          />

          <FilterEarn />
        </Flex>
      </Flex>

      <Box
        borderRadius='8px'
        padding='20px 22px'
        mt='14px'
      >
        {status === 'loading' && <Loader />}

        <ScrollRightContainer maxWidth={{ sm: '700px', md: '875', lg: '1250px' }}>
          <Table>
            <Thead>
              <Tr>
                <Th>Full name</Th>
                <Th>Coin</Th>
                <Th>Amount (USD)</Th>
                <Th>Percentage (APR)</Th>
                <Th>Status</Th>
                <Th>Product ID</Th>
                <Th>Product Type</Th>
                <Th>Duration (Days)</Th>
                <Th>Auto Renew</Th>
                <Th>Date of stake</Th>
                <Th>Expires</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            {status === 'error' && <Text>{errorMessage}</Text>}

            <Tbody>
              {data.records &&
                data.records.map(
                  ({ _id, unit, userDetails, amountAtStake, amountRedeemed, status, product, createdAt }) => {
                    return (
                      <Tr key={_id}>
                        <Td
                          color='#6FBE45'
                          textTransform={'capitalize'}
                        >
                          <Link to={`/dashboard/earn/${_id}`}>
                            {userDetails?.fullName ? userDetails.fullName : 'N/A'}
                          </Link>
                        </Td>

                        <Td textTransform='uppercase'>{unit}</Td>

                        <Td>
                          <Text>Staked: {amountAtStake.usdValue}</Text>

                          <Text>Redeemed: {amountRedeemed.usdValue}</Text>
                        </Td>

                        <Td>{product?.originalApr ? `${Number(product.originalApr).toFixed(4)}` : 'N/A'}</Td>

                        <Td textTransform='capitalize'>{status}</Td>

                        <Td textTransform='capitalize'>{product?.orderId ? product.orderId : 'N/A'}</Td>

                        <Td>{product?.type}</Td>

                        <Td>{product?.tenor}</Td>

                        <Td textTransform='capitalize'>{product?.autoRenew ? String(product?.autoRenew) : 'N/A'}</Td>

                        <Td>{formatDateToUTC(createdAt).split(',')[0]}</Td>

                        <Td>
                          {product?.expiresOn === undefined ? 'N/A' : formatDateToUTC(product?.expiresOn).split(',')[0]}
                        </Td>

                        <Td>
                          <Link to={`/dashboard/earn/${_id}`}>
                            <ViewMore _hover={{ bg: 'transparent' }}>
                              <Flex
                                gridGap='8px'
                                justifyContent='center'
                                alignItems='center'
                              >
                                <Text
                                  fontWeight='600'
                                  fontSize='10px'
                                >
                                  View
                                </Text>
                                <EyeSVG />
                              </Flex>
                            </ViewMore>
                          </Link>
                        </Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </ScrollRightContainer>

        <PaginationWrapper
          scrollTop={550}
          setCurrentPage={setCurrentPage}
          totalDataCount={totalCount}
          currentPage={currentPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          offset={offset}
          pages={pages}
          size={10}
        />
      </Box>
    </TableBoxWrapper>
  );
};

export default EarnTable;
