import { Box, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';

const AccordionWrapper = ({ title, children, ...props }) => {
  return (
    <Accordion
      allowToggle
      borderRadius='0px'
      padding='8px 0'
      {...props}
    >
      <AccordionItem border='none'>
        <AccordionButton
          as='div'
          p='8px'
          _expanded={{ bg: '#F2F4F3' }}
          transition='all 300ms ease-in-out'
          cursor='pointer'
          borderRadius='5px'
          minH='42px'
        >
          <Box
            flex='1'
            textAlign='left'
            fontWeight='500'
          >
            {title}
          </Box>

          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel
          fontSize='14px'
          fontWeight='500'
        >
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionWrapper;
