import {
  HStack,
  PinInput as ChakraPinInput,
  PinInputField,
} from '@chakra-ui/react';

const PinInput = ({ limit, size, otp, type, onChange, onComplete }) => {
  return (
    <HStack justifyContent="space-evenly">
      <ChakraPinInput
        otp={otp}
        type={type}
        size={size ?? 'lg'}
        onChange={onChange}
        autoFocus={true}
        placeholder=""
        onComplete={onComplete}
      >
        {Array(limit ?? 5)
          .fill(0)
          .map((_, i) => (
            <PinInputField
              key={`pin-input-${i}`}
              borderBottom="1px dashed #797A7A"
              borderTop="0"
              borderLeft="0"
              borderRight="0"
              borderRadius="0"
              required
              minWidth="75px"
              height="initial"
              _focus={{
                borderBottom: '2px dashed #797A7A',
                borderLeft: '0',
                borderRight: '0',
                borderTop: '0',
                boxShadow: 'none',
              }}
              _active={{
                borderBottom: '2px dashed #797A7A',
                borderLeft: '0',
                borderRight: '0',
                borderTop: '0',
              }}
            />
          ))}
      </ChakraPinInput>
    </HStack>
  );
};

export default PinInput;
