import { useState, useEffect } from 'react';
import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
  Text,
} from '@chakra-ui/react';
import { Button, CloseModal, FilterSelect, Input } from 'components';
import { useCreateDisbursementMutation } from 'store/services/disbursement';
import { supportedCoins } from 'constant';

const CreateDisbursementModal = ({ localStorageData }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [state, setState] = useState({
    senderAlias: '',
    description: '',
    amount: undefined,
    unit: undefined,
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const filteredData = localStorageData?.filter(
    ({ status }) => status.toLowerCase() !== 'processed'
  );

  const formatValue = (value) => {
    return String(value).toLowerCase() === 'n/a' ? undefined : value;
  };

  // get the key of the object that is not undefined
  const idType = (obj) => {
    return Object.keys(obj).find((key) => obj[key] !== undefined);
  };

  const recipients = filteredData?.map(
    ({
      phone,
      userid,
      email,
      username,
      amount,
      unit,
      network,
      description,
    }) => ({
      email: email?.toLowerCase() === 'n/a' ? undefined : email,
      username: username?.toLowerCase() === 'n/a' ? undefined : username,
      userid: userid?.toLowerCase() === 'n/a' ? undefined : userid,
      phone: String(phone)?.toLowerCase() === 'n/a' ? undefined : String(phone),

      network: network?.toLowerCase() === 'n/a' ? 'tron' : network,
      amount: String(amount)?.toLowerCase() === 'n/a' ? undefined : amount,
      unit,
      description,

      id:
        formatValue(email) ||
        formatValue(username) ||
        formatValue(userid) ||
        formatValue(phone),

      idType: idType({
        email: formatValue(email),
        username: formatValue(username),
        userid: formatValue(userid),
        phone: formatValue(phone),
      }),
    })
  );

  const [_createDisbursement, { isLoading, error }] =
    useCreateDisbursementMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { unit, amount, description, senderAlias } = state;

    const response = await _createDisbursement({
      description,
      senderAlias,
      amount,
      unit,
      recipients,
    });

    if (response.data) {
      onClose();

      toast({
        title: 'Success',
        status: 'success',
        description: `${response.data.message}`,
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    error &&
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.error || error?.data?.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
  }, [error, toast]);

  return (
    <>
      <Button
        bg="rgba(49, 183, 169, 0.2)"
        fontWeight="500"
        color="brand.black"
        fontSize="12px"
        minH="38px"
        borderRadius="5px"
        onClick={onOpen}
      >
        Create Disbursements
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pt="24px" pb="24px" borderRadius="21px" maxWidth="430px">
          <ModalHeader fontSize="24px" textAlign="center">
            Create Disbursements
          </ModalHeader>

          <ModalBody pb="2rem">
            <form onSubmit={handleSubmit}>
              <VStack gridGap="8px" m="20px 0 40px">
                <Box width="100%">
                  <Text
                    display="block"
                    mb="0.5rem"
                    htmlFor="description"
                    as="label"
                  >
                    Description
                  </Text>

                  <Textarea
                    placeholder="Description"
                    onChange={handleChange}
                    name="description"
                    id="description"
                    width="100%"
                    height="80px"
                    padding="13px 22px"
                    _focus={{
                      borderColor: '#06C3448f',
                    }}
                  />
                </Box>

                <Box width="100%">
                  <Text display="block" mb="0.5rem" htmlFor="amount" as="label">
                    Amount
                  </Text>

                  <Input
                    placeholder="Amount"
                    onChange={handleChange}
                    name="amount"
                    id="amount"
                    width="100%"
                  />
                </Box>

                <Box width="100%">
                  <Text display="block" mb="0.5rem" htmlFor="unit" as="label">
                    Unit
                  </Text>

                  <FilterSelect
                    onChange={handleChange}
                    id="unit"
                    name="unit"
                    minH="60px"
                    textTransform="uppercase"
                  >
                    <option>Select Unit</option>
                    {supportedCoins.map(({ coin, id }) => {
                      return (
                        <option key={id} value={coin}>
                          {coin}
                        </option>
                      );
                    })}
                  </FilterSelect>
                </Box>

                <Box width="100%">
                  <Text
                    display="block"
                    mb="0.5rem"
                    htmlFor="senderAlias"
                    as="label"
                  >
                    Sender Alias
                  </Text>

                  <Input
                    placeholder="Sender Alias"
                    onChange={handleChange}
                    name="senderAlias"
                    id="senderAlias"
                    width="100%"
                    // height="80px"
                    // padding="13px 22px"
                  />
                </Box>
              </VStack>

              <Center>
                <Button
                  type="submit"
                  minH="52px"
                  minW="365px"
                  disabled={isLoading || !(state.description.length > 2)}
                  isLoading={isLoading}
                >
                  Submit
                </Button>
              </Center>
            </form>
          </ModalBody>

          <CloseModal onClick={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateDisbursementModal;
