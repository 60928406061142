import { Button } from 'components';
import { utils, writeFile } from 'xlsx';

const ExportStaffWithdrawal = ({ localStorageData }) => {
  const handleExport = () => {
    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(localStorageData);

    utils.book_append_sheet(workbook, worksheet, 'Staff Withdrawals');

    writeFile(workbook, 'staff_processed_withdrawals.xlsx');
  };
  return (
    <Button
      onClick={handleExport}
      bg="transparent"
      color="brand.black"
      borderRadius="20.4px"
      fontSize="14px"
      minH="38px"
      fontWeight="600"
      border="1px solid #6FBE45"
      fontFamily="PolySans"
      _hover={{
        transition: 'all 300ms ease-in-out',
        bg: 'brand.primary',
        color: '#fff',
        border: '1px solid #0D4740',
      }}
    >
      Export Withdrawals
    </Button>
  );
};

export default ExportStaffWithdrawal;
