import { Box, Tooltip } from '@chakra-ui/react';
import { GrTooltip } from 'react-icons/gr';

const SearchFilterFormat = () => {
  return (
    <Box>
      <Tooltip
        label={`
        /user:dewaleolaoye - will get trades of user dewaleolaoye \n
        /user:rasheed@bitmama.io - will get trades of user rasheed@bitmama.io \n
        /buyer:conquext - will get buy trades of user conquext \n
        /buyer:conquext - will get buy trades of user conquext \n
        /seller:conquext - will get sell trades of user conquext \n
        /tradeRef:12435454656565656-rx5453t - will get  trade with the given trade ref \n
        /ref:rx5453t - will get  trade with the given trade ref \n
        /id:12435454656565656 - will get all  trades with the given trade id \n
        `}
        bg='brand.primary'
        color='#fff'
        borderRadius='8px'
        placement='right-end'
        closeDelay={2000}
        padding='8px 12px'
        fontSize='12px'
        width='100%'
        whiteSpace='pre-line'
      >
        <Box>
          <GrTooltip
            fontSize='1.25rem'
            color='#4E4B66'
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default SearchFilterFormat;
