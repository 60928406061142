import { useState } from 'react';
import { Text, useToast, Center } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Button } from 'components';
import { put } from 'utils/axiosLib';
import { fetchWalletDeposit } from 'store/slices/wallet.slice';
import { getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';

const ProcessDeposit = ({ newStatus, onClose, _id }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const headers = getHeaders();

  const processTransaction = async (e) => {
    e.preventDefault();
    setLoading(true);
    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/deposit/${_id}/update-status`);

    try {
      const response = await put(
        URL,
        {
          newStatus,
        },
        headers
      );

      if (response.data) {
        setLoading(false);

        onClose();

        dispatch(
          fetchWalletDeposit({
            limit: 10,
          })
        );

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Center
        flexDir='column'
        gridGap='1rem'
      >
        <Text
          fontSize='16px'
          fontWeight='500'
          textAlign='center'
        >
          Are you sure you want to process{' '}
          <Text
            as='span'
            fontWeight='500'
          >
            ({newStatus})
          </Text>{' '}
          this transaction
        </Text>

        <Button
          isLoading={loading}
          disabled={loading}
          onClick={processTransaction}
          minHeight='58px'
        >
          Yes
        </Button>
      </Center>
    </>
  );
};

export default ProcessDeposit;
