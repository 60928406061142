import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { Button, FileUpload } from 'components';
import { useCopy } from 'hooks';
import { useState } from 'react';
import { uploadFile } from 'utils';

const ToolsFileUpload = () => {
  const toast = useToast();
  const { hasCopied, onCopy, value } = useCopy();

  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState('');

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const _fileName = selectedFile?.name.split('.')[0];
      const response = await uploadFile(selectedFile, _fileName);

      if (response.location) {
        setFileUrl(response.location);
        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: 'Successfully upload file',
          status: 'success',
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Success',
        description: 'Something went wrong',
        status: 'success',

        isClosable: true,
      });
    }
  };
  return (
    <Box>
      <Box mb='24px'>
        <FileUpload
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </Box>

      <Button
        type='submit'
        onClick={handleSubmit}
        width='100%'
        disabled={!selectedFile || loading}
        isLoading={loading}
      >
        Submit
      </Button>

      {fileUrl && (
        <Flex
          alignItems='center'
          // gap='16px'
          px='4px'
          mt='24px'
          justifyContent='space-between'
        >
          <Box
            textDecor='underline'
            color='#31B7A9'
            fontWeight='500'
          >
            <a
              href={fileUrl}
              target='_blank'
              rel='noreferrer'
            >
              {fileUrl}
            </a>
          </Box>

          <Box
            onClick={() => onCopy(fileUrl)}
            cursor='pointer'
          >
            {value === fileUrl && hasCopied ? <CheckIcon /> : <CopyIcon cursor='pointer' />}{' '}
          </Box>
        </Flex>
      )}
    </Box>
  );
};
export default ToolsFileUpload;
