import { Box, Flex } from '@chakra-ui/react';
import {
  CICOAuth,
  PageTab,
  PageTitle,
  TableBoxWrapper,
  CicoKYC,
  CICOTransfer,
  GenerateTransferReport,
} from 'components';
import { useLocationSearch } from 'hooks';
import { CICOTabLinkArray } from 'LinkArray';
import { constant, ls } from 'utils';

const CICO = () => {
  const search = useLocationSearch();
  const cicoAuth = ls.get(constant.AUTH_CICO_TOKEN_KEY);

  const filterTab = CICOTabLinkArray.filter(({ title }) => {
    return title !== 'Auth';
  });

  return (
    <>
      <Box>
        <Box>
          <PageTitle title='Cash In Cash Out' />
        </Box>

        <TableBoxWrapper
          minHeight='760px'
          mt='27px'
        >
          <Flex justifyContent='space-between'>
            <Box width='100%'>
              <PageTab linkArray={String(cicoAuth).length <= 5 ? CICOTabLinkArray : filterTab} />
            </Box>

            {search === '?transfer' && <GenerateTransferReport />}
          </Flex>

          {cicoAuth ? '' : <>{search === '' && <CICOAuth />}</>}
          {search === '?kyc' && <CicoKYC />}
          {search === '?transfer' && <CICOTransfer />}
        </TableBoxWrapper>
      </Box>
    </>
  );
};

export default CICO;
