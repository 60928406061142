import { configureStore } from '@reduxjs/toolkit';
import { CardsReducer } from './slices/card.slice';
import { AuthReducer } from './slices/auth.slice';
import { BillsReducer } from './slices/bills.slice';
import { bnplReducer } from './slices/bcpl.slice';
import { BuySellReducer } from './slices/buySell.slice';
import { ReferralReducer } from './slices/referral.slice';
import { UserReducer } from './slices/user.slice';
import { WalletReducer } from './slices/wallet.slice';
import { rewardsReducer } from './slices/claimedRewards.slice';
import { P2PReducer } from './slices/p2p.slice';
import { stakingReducer } from './slices/staking.slice';
import { PartnerReducer } from './slices/partners.slice';
import { RoleReducer } from './slices/roles.slice';
import { liensReducer } from './slices/liens.slice';
import { rateReducer } from './slices/rate.slice';

import p2pApi from './services/p2pApi';
import cicoApi from './services/cico.service';
import { banksReducer } from './slices/bank.slice';
import testConnection from './services/testConnection';
import disbursementApi from './services/disbursement';
import requestApi from './services/request.service';
import { greenboxReducer } from './slices/greenbox.slice';
import greenboxApi from './services/greenbox.service';
import appConfigApi from './services/app.service';
import integrationsApi from './services/integrations.service';
import merchantsApi from './services/merchants.service';
import payApi from './services/pay.service';
import wishlistApi from './services/wishlist.service';
import notesApi from './services/notes.service';
import userApi from './services/user.service';
import gamingApi from './services/gaming.service';

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    allUsers: UserReducer,
    wallet: WalletReducer,
    bcpl: bnplReducer,
    referrals: ReferralReducer,
    buySell: BuySellReducer,
    bills: BillsReducer,
    cards: CardsReducer,
    claimedRewards: rewardsReducer,
    p2p: P2PReducer,
    stakings: stakingReducer,
    partners: PartnerReducer,
    roles: RoleReducer,
    liens: liensReducer,
    allBanks: banksReducer,
    rates: rateReducer,
    greenbox: greenboxReducer,
    [p2pApi.reducerPath]: p2pApi.reducer,
    [cicoApi.reducerPath]: cicoApi.reducer,
    [testConnection.reducerPath]: testConnection.reducer,
    [disbursementApi.reducerPath]: disbursementApi.reducer,
    [requestApi.reducerPath]: requestApi.reducer,
    [greenboxApi.reducerPath]: greenboxApi.reducer,
    [appConfigApi.reducerPath]: appConfigApi.reducer,
    [integrationsApi.reducerPath]: integrationsApi.reducer,
    [merchantsApi.reducerPath]: merchantsApi.reducer,
    [payApi.reducerPath]: payApi.reducer,
    [wishlistApi.reducerPath]: wishlistApi.reducer,
    [notesApi.reducerPath]: notesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [gamingApi.reducerPath]: gamingApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      p2pApi.middleware,
      cicoApi.middleware,
      testConnection.middleware,
      disbursementApi.middleware,
      requestApi.middleware,
      greenboxApi.middleware,
      appConfigApi.middleware,
      integrationsApi.middleware,
      merchantsApi.middleware,
      payApi.middleware,
      wishlistApi.middleware,
      notesApi.middleware,
      userApi.middleware,
      gamingApi.middleware
    ),
});

export default store;
