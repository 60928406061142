import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { TableDataStatus, ViewMore } from 'components';
import { formatDateToUTC } from 'utils';

const VerificationTable = ({ data }) => {
  const navigate = useNavigate();

  const handleShowDetailsPage = (id, newPage) => {
    if (newPage) {
      const win = window.open(`/dashboard/verification/${id}`, '_blank');
      win.focus();
    } else navigate(`/dashboard/verification/${id}`);
  };

  return (
    <Box>
      <Flex
        alignItems='center'
        gridGap='1rem'
        mb='6px'
      ></Flex>

      <Box>
        <>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Date/time of verification</Th>
                <Th>Status</Th>
                <Th>Level of verification</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data &&
                data.documents.map(({ _id, createdAt, userDetail, kycLevel, status }) => {
                  return (
                    <Tr
                      key={_id}
                      // onDoubleClick={() => handleShowDetailsPage(_id, true)}
                    >
                      <Td textTransform='capitalize'>
                        <a
                          href={`/dashboard/verification/${_id}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {userDetail.fullName}
                        </a>
                      </Td>

                      <Td textTransform='lowercase'>{userDetail.email}</Td>

                      <Td>{formatDateToUTC(createdAt)}</Td>

                      <Td>
                        <TableDataStatus status={status} />
                      </Td>

                      <Td textTransform='capitalize'>
                        {kycLevel === 'one' && 'Level One'}
                        {kycLevel === 'two' && 'Level Two'}
                        {kycLevel === 'three' && 'Level Three'}
                        {kycLevel === 'two_three' && 'Level Two-Three'}
                        {kycLevel === undefined && 'N/A'}
                      </Td>

                      <Td>
                        <ViewMore
                          onClick={() =>
                            // handleShowDetailsPage(userDetail.userId)
                            handleShowDetailsPage(_id)
                          }
                        >
                          View more
                        </ViewMore>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </>
      </Box>
    </Box>
  );
};

export default VerificationTable;
