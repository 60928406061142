import { useEffect, useState } from "react";
import { ls } from 'utils';

const getInitialValue = (key, defaultValue) => {
    try {
        const storedValue = ls.get(key);
        return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
    } catch {
        return defaultValue;
    }
};

const useLocalStorageState = (key, defaultValue) => {
    const initialValue = getInitialValue(key, defaultValue);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        ls.set(key, JSON.stringify(value));
        setValue(value);
    }, [key, value]);

    return [value, setValue];
};

export default useLocalStorageState;
