import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  useDisclosure,
  Text,
  Flex,
} from '@chakra-ui/react';
import { CloseModal, Loader, ViewMore } from 'components';
import { fetchSingleCardTransaction } from 'store/slices/card.slice';
import { formatDateToUTC } from 'utils';

const CardTransactionModal = ({ cardId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, status } = useSelector((state) => state.cards.singleCardTransaction);

  const dispatch = useDispatch();
  useEffect(() => {
    isOpen &&
      dispatch(
        fetchSingleCardTransaction({
          id: cardId,
        })
      );
  }, [cardId, dispatch, isOpen]);

  return (
    <Box>
      <ViewMore onClick={onOpen}>View TX</ViewMore>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='21px'
          padding='24px 0'
          bg='#fff'
          maxWidth='520px'
        >
          <ModalHeader textAlign='center'>Card Transaction</ModalHeader>

          <ModalBody>
            {status === 'loading' && <Loader />}

            {status === 'fulfilled' && data.transactions.length === 0 && (
              <Box padding='1rem 0'>
                <Text
                  textAlign='center'
                  fontWeight='500'
                >
                  No Transaction
                </Text>
              </Box>
            )}

            <Box>
              {data &&
                data.transactions.map(({ description, currency, signedValue, transactionRef, createdAt }, i) => {
                  return (
                    <Flex
                      borderRadius='10px'
                      boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
                      padding='18px 24px'
                      mb='8px'
                      justifyContent='space-between'
                      alignItems='center'
                      key={i}
                    >
                      <Box>
                        <Text fontWeight='500'>{description}</Text>
                        <Text
                          fontWeight='400'
                          fontSize='13px'
                        >
                          {transactionRef}
                        </Text>
                      </Box>

                      <Box>
                        <Text textTransform='uppercase'>
                          {Number(signedValue?.$numberDecimal).toLocaleString()} {currency}
                        </Text>

                        <Text fontSize='13px'>{formatDateToUTC(createdAt).split(',')[0]}</Text>
                      </Box>
                    </Flex>
                  );
                })}
            </Box>
          </ModalBody>

          <CloseModal onClick={onClose} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CardTransactionModal;
