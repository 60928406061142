import { Box, Text } from '@chakra-ui/react';

const FilterTab = ({ text, onClick, active, bg }) => (
  <Box
    padding={{ sm: '12px 9px', tb: '9px 21px' }}
    color='#4E4B66'
    borderRadius={{ sm: '12px', tb: '22px' }}
    bg={bg ? bg : '#F2F4F3'}
    cursor={'pointer'}
    border={active ? '1px solid #03A438' : '1px solid transparent'}
    _hover={{
      border: '1px solid #03A438',
    }}
    onClick={onClick}
    opacity='0.5'
  >
    <Text
      fontWeight='500'
      color='brand.black'
      fontSize={{ sm: '14px', tb: '16px' }}
    >
      {text}
    </Text>
  </Box>
);

export default FilterTab;
