import { nanoid } from '@reduxjs/toolkit';

export const supportedCoins = [
  {
    coin: 'btc',
    id: nanoid(),
  },
  {
    coin: 'eth',
    id: nanoid(),
  },

  {
    coin: 'celo',
    id: nanoid(),
  },
  {
    coin: 'xrp',
    id: nanoid(),
  },
  {
    coin: 'xlm',
    id: nanoid(),
  },
  {
    coin: 'usdt',
    id: nanoid(),
  },
  {
    coin: 'usdt-tron',
    id: nanoid(),
  },
  {
    coin: 'cusd',
    id: nanoid(),
  },
  {
    coin: 'usdc',
    id: nanoid(),
  },
  {
    coin: 'matic',
    id: nanoid(),
  },
];

export const gamingDefaultImg =
  'https://res.cloudinary.com/bitmama/image/upload/v1712043324/bitmama_game_badges/airtime_ambassador_badge/level_1_afz8fc.svg';

export const badgesIcon = [
  {
    label: 'buy_trading_titan_badge',
    title: 'Buy Trading Titan Badge',
    icon: gamingDefaultImg,
  },
  {
    label: 'sell_trading_titan_badge',
    title: 'Sell Trading Titan Badge',
    icon: gamingDefaultImg,
  },
  {
    label: 'digital_shopper_badge',
    title: 'Digital Shopper Badge',
    icon: gamingDefaultImg,
  },
  {
    label: 'swap_master_badge',
    title: 'Swap Master Badge',
    icon: gamingDefaultImg,
  },
  {
    label: 'fiat_funding_pro_badge',
    title: 'Fiat Funding Pro Badge',
    icon: gamingDefaultImg,
  },
  {
    label: 'airtime_ambassador_badge',
    title: 'Airtime Ambassador Badge',
    icon: 'https://res.cloudinary.com/bitmama/image/upload/v1712043324/bitmama_game_badges/airtime_ambassador_badge/level_1_afz8fc.svg',
  },
  {
    label: 'data_maven_badge',
    title: 'Data Maven Badge',
    icon: 'https://res.cloudinary.com/bitmama/image/upload/v1712044040/bitmama_game_badges/data_maven_badge/level_1_ho6mxp.svg',
  },

  {
    label: 'verified_bitstar_badge',
    title: 'Verified Bitstar Badge',
    icon: 'https://res.cloudinary.com/bitmama/image/upload/v1712042328/bitmama_game_badges/verified_bitstar_badge/level_3_aq98zq.svg',
  },
  {
    label: 'referral_badge',
    title: 'Referral Badge',
    icon: 'https://res.cloudinary.com/bitmama/image/upload/v1712051759/bitmama_game_badges/referral_badge/level_1_ulw1v1.svg',
  },
  {
    label: 'new_bitstar_badge',
    title: 'New Bitstar Badge',
    icon: 'https://res.cloudinary.com/bitmama/image/upload/v1712040790/bitmama_game_badges/the_new_bitstar_badge/level_1_ncsodi.svg',
  },
  {
    label: 'early_adopter_badge',
    title: 'Early Adopter Badge',
    icon: 'https://res.cloudinary.com/bitmama/image/upload/v1712041839/bitmama_game_badges/early_adopter_badge/level_1_yilrdp.svg',
  },
  {
    label: 'six_month_loyalty_badge',
    title: 'Six Month Loyalty Badge',
    icon: 'https://res.cloudinary.com/bitmama/image/upload/v1712042352/bitmama_game_badges/6_months_loyalty_badge/level_1_eaz5qw.svg',
  },
  {
    label: 'twelve_month_loyalty_badge',
    title: 'Twelve Month Loyalty Badge',
    icon: 'https://res.cloudinary.com/bitmama/image/upload/v1712042367/bitmama_game_badges/12_months_loyalty_badge/level_2_lkwu22.svg',
  },
];
