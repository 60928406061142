import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { CloseModal } from 'components';
import { formatDateToUTC } from 'utils';

const RewardsModal = ({ isOpen, onClose, data }) => {
  const { name, rewards } = data;

  return (
    <Modal isOpen={isOpen} onClose={onClose} key={'_id'}>
      <ModalOverlay />

      <ModalContent borderRadius="16px" maxWidth="720px" padding={'32px 20px'}>
        <Text fontSize="24px" textAlign="center" fontWeight="600">
          Details
        </Text>

        <ModalBody p="0">
          <Flex m="16px 0" justifyContent="space-between">
            <Text>Full Name</Text>

            <Text
              fontWeight="medium"
              textTransform="capitalize"
              maxWidth="400px"
            >
              {name}
            </Text>
          </Flex>

          <Table>
            <Thead>
              <Tr>
                <Th>S/N</Th>
                <Th>Description</Th>
                <Th>Amount</Th>
                <Th>Claimed</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>

            <Tbody>
              {rewards.map((res, index) => {
                const { description, claimedAt, claimed, reward } = res;

                return (
                  <Tr key={index}>
                    <Td>{index + 1}.</Td>
                    <Td>{description}</Td>
                    <Td textTransform={'uppercase'}>
                      {reward.value} {reward?.coin}
                    </Td>
                    <Td textTransform={'capitalize'}>{claimed.toString()}</Td>
                    <Td>{formatDateToUTC(claimedAt)}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RewardsModal;
