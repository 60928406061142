import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Box,
  useToast,
  Center,
  Checkbox,
  Flex,
} from '@chakra-ui/react';
import { ActionButton, Button, CloseModal, Input } from 'components';
import { BASE_URL } from 'constant';
import { post } from 'utils/axiosLib';

const SendNotificationsModal = ({ headers, id }) => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [sendPush, setSendPush] = useState(false);

  const [state, setState] = useState({
    userId: id,
    title: '',
    content: '',
    screen: '',
    webPushUrl: '',
    smsText: '',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleNotification = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = `${BASE_URL}/admin/notifications`;

    console.log(sendPush, 'STATE VALUE');
    try {
      const response = await post(
        URL,
        {
          ...state,
          sendPush,
        },
        headers
      );

      if (response.data) {
        setLoading(false);

        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      console.log(error, 'ERRROR');

      toast({
        position: 'bottom-right',
        title: 'Error',
        description:
          Object.values(error?.error).length === 0 ? 'Error' : error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <ActionButton onClick={onOpen}>Send Notifications</ActionButton>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent
          borderRadius="16px"
          bg="#fff"
          maxWidth="468px"
          padding="20px 20px"
        >
          <ModalHeader
            padding="24px 30px"
            fontWeight="600"
            borderTopRadius="16px"
            textAlign="center"
          >
            Send Notifications
          </ModalHeader>

          <form onSubmit={handleNotification}>
            <Box mb="16px">
              <Input
                label="Title"
                name="title"
                placeholder="Title"
                onChange={handleChange}
                isRequired
              />
            </Box>

            <Box mb="16px">
              <Input
                label="Content"
                name="content"
                placeholder="Content"
                onChange={handleChange}
                isRequired
              />
            </Box>

            <Box mb="16px">
              <Input
                label="Screen"
                name="screen"
                placeholder="Screen"
                onChange={handleChange}
              />
            </Box>

            <Box mb="16px">
              <Input
                label="Web Push URL"
                name="webPushUrl"
                placeholder="Web Push URL"
                onChange={handleChange}
              />
            </Box>

            <Box mb="16px">
              <Input
                label="SMS Text"
                name="smsText"
                placeholder="SMS Text"
                onChange={handleChange}
              />
            </Box>

            <Flex mb="48px" gridGap="1rem">
              <Checkbox
                colorScheme="green"
                onChange={() => setSendPush(!sendPush)}
                name="force"
                value={sendPush}
                checked={sendPush}
                defaultChecked={sendPush}
              >
                Send Push Notification
              </Checkbox>
            </Flex>

            <Center>
              <Button
                minWidth="200px"
                type="submit"
                disabled={
                  loading || state.content.length < 2 || state.title.length < 2
                }
                isLoading={loading}
              >
                Submit
              </Button>
            </Center>
          </form>

          <CloseModal onClick={onClose} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SendNotificationsModal;
