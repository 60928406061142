import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Heading } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  FilterSelect,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  SearchInput,
  TableDataStatus,
} from 'components';
import { formatDateToUTC } from 'utils';

import { fetchAirtimeBills } from 'store/slices/bills.slice';
import { useDebounce } from 'hooks';

const AirtimeTable = () => {
  const dispatch = useDispatch();
  const { data, status, totalCount } = useSelector((state) => state.bills.airtime);
  const [size, setSize] = useState(10);

  const [searchString, setSearchString] = useState('');

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: size,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  useEffect(() => {
    dispatch(
      fetchAirtimeBills({
        limit: size,
        page: currentPage,
        search: searchString,
        operatorName: '',
      })
    );
  }, [dispatch, currentPage, size, searchString]);

  return (
    <Box>
      <Flex
        mb='45px'
        gridGap='1rem'
        alignItems='center'
        flexWrap='wrap'
        width='100%'
      >
        <Heading
          as='h2'
          color='#797A7A'
          fontSize='1rem'
          fontWeight='700'
        >
          Transactions
        </Heading>

        <Flex
          gridGap='1rem'
          alignItems='center'
        >
          <FilterSelect
            width='90px'
            onChange={handleSize}
          >
            <option value={10}>Size</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </FilterSelect>

          <Box
            maxWidth='319px'
            width='100%'
          >
            <SearchInput
              onChange={debouncedChangeHandler}
              placeholder='Search for user name or email'
            />
          </Box>
        </Flex>
      </Flex>

      {status === 'loading' && (
        <Box margin='8px 0'>
          <Loader />
        </Box>
      )}

      <ScrollRightContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Operator</Th>
              <Th>Phone number</Th>
              <Th>Country</Th>
              <Th>Reference ID</Th>
              <Th>Local Amount</Th>
              <Th>Currency</Th>
              <Th>Status</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.bills.map(({ _id, userDetails, metaData, currency, createdAt, txRef, localAmount }) => {
                return (
                  <Tr key={_id}>
                    <Td>{userDetails?.fullName ? userDetails.fullName : 'N/A'}</Td>

                    <Td>{metaData?.operatorName ? metaData.operatorName : 'N/A'}</Td>

                    <Td>{metaData?.phone ? metaData.phone : 'N/A'}</Td>

                    <Td>{metaData?.country ? metaData.country : 'N/A'}</Td>

                    <Td>{txRef ? txRef : 'N/A'} </Td>

                    <Td textTransform='capitalize'>
                      {localAmount ? Number(localAmount?.$numberDecimal).toLocaleString() : 'N/A'}
                    </Td>

                    <Td textTransform='uppercase'>{currency}</Td>

                    {metaData?.partnerStatus ? (
                      <Td textTransform='capitalize'>
                        <TableDataStatus
                          status={metaData.partnerStatus.toLowerCase()}
                          withIcon
                        />
                      </Td>
                    ) : (
                      <Td>N/A</Td>
                    )}

                    <Td>{formatDateToUTC(createdAt)}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={pageSize}
        offset={offset}
        pages={pages}
      />
    </Box>
  );
};

export default AirtimeTable;
