import { Box } from '@chakra-ui/react';
import { PageTitle, GreenboxStat, GreenboxTable } from 'components';

const Greenbox = () => {
  return (
    <Box>
      <PageTitle title="Greenbox" />

      <Box
        mt="27px"
        bg="#FCFDFD"
        boxShadow="0px 2px 8px rgba(229, 229, 229, 0.47)"
        borderRadius="10px"
        minHeight="700px"
        padding="15px 25px"
      >
        <Box padding="25px 0">
          <PageTitle fontSize="18px" title="Stats" fontWeight="600" />

          <GreenboxStat />

          <GreenboxTable />
        </Box>
      </Box>
    </Box>
  );
};

export default Greenbox;
