import { Box, Flex } from '@chakra-ui/react';
import { NavLink, useParams } from 'react-router-dom';
import { useLocationSearch } from 'hooks';
import styles from './tab.module.scss';

const LinkTab = ({ linkArray, gridGap }) => {
  const { id } = useParams();
  const search = useLocationSearch();

  return (
    <Flex
      backgroundColor='transparent'
      borderRadius='8px'
      fontFamily='PolySans'
      gridGap={gridGap ? gridGap : '0'}
    >
      {linkArray.map(({ _id, searchValue, title, defaultLink }) => {
        return (
          <Box
            key={_id}
            px='8px'
          >
            <NavLink
              className={styles.navLink}
              to={`${defaultLink}/${id}${searchValue}`}
              style={{
                backgroundColor: search === searchValue && 'rgba(224, 240, 216, 0.5)',
                borderBottom: search === searchValue && '0.5px solid #CACBCB',
              }}
            >
              {title}
            </NavLink>
          </Box>
        );
      })}
    </Flex>
  );
};

export default LinkTab;
