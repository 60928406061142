import { Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, Text, useToast, Box } from '@chakra-ui/react';
import { Button, CloseModal } from 'components';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDeleteNoteMutation } from 'store/services/notes.service';

const DeleteNoteModal = ({ userId, noteId }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [_deleteNote, { isLoading }] = useDeleteNoteMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await _deleteNote({
        userId,
        noteId,
      }).unwrap();

      toast({
        position: 'bottom-right',
        title: 'Success',
        description: 'Note deleted',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.log(error?.data?.error?.message, 'err');
      toast({
        position: 'bottom-right',
        title: 'Error',
        description: error?.data?.error?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <DeleteIcon
        fontSize='1rem'
        color='#ED1C00'
        cursor='pointer'
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='420px'
          padding='32px 0'
        >
          <Text
            fontWeight='600'
            textAlign='center'
            mb='16px'
            fontSize='18px'
          >
            Delete Note
          </Text>

          <ModalBody
            bg='#fff'
            display='flex'
            alignItems='center'
            flexDir='column'
            gap='24px'
          >
            <Text textAlign='center'>Are you sure you want to delete this note?</Text>

            <Box>
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                onClick={handleSubmit}
              >
                Yes
              </Button>

              <CloseModal onClick={onClose} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteNoteModal;
