import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Text, useToast } from '@chakra-ui/react';
import { Button, CloseModal } from 'components';
import { put } from 'utils/axiosLib';
import { fetchUsers } from 'store/slices/user.slice';
import { getHeaders } from 'utils';
import { BASE_URL } from 'constant';

const RemoveAdminModal = ({ isOpen, onClose, data }) => {
  const toast = useToast();
  const { _id, fullName } = data;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const removeAdmin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = `${BASE_URL}/admin/unassign/role/${_id}`;

    try {
      const response = await put(URL, {}, getHeaders());

      if (response.data) {
        dispatch(
          fetchUsers({
            limit: 10,
            page: 1,
            time: `isAdmin=${true}`,
            search: '',
          })
        );

        onClose();

        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response?.data?.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: typeof error.error === 'string' ? error.error : '',
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      key={_id}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='21px'
        maxWidth='399px'
        padding={'32px 39px'}
        textAlign='center'
      >
        <ModalHeader
          fontSize={'24px'}
          fontWeight={'600'}
          p='0'
        >
          Confirm
        </ModalHeader>

        <ModalBody p='0'>
          <Text
            color='#4E4B66'
            fontSize={'14px'}
            fontWeight={'400'}
            margin={'13px 0 28px'}
          >
            Are you sure you want to remove{' '}
            <Text
              as='span'
              fontWeight={'500'}
            >
              {fullName}
            </Text>{' '}
            as an admin?
          </Text>

          <Button
            isLoading={loading}
            disabled={loading}
            onClick={removeAdmin}
          >
            Yes
          </Button>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RemoveAdminModal;
