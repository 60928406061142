import { Box, Flex, Text } from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { mobileLink } from 'utils/sidebarLink';
import MoreButton from './MoreButton';
import styles from './Sidebar.module.scss';

const BottomNavbar = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Box
        overflow='hidden'
        bottom='0'
        pos='fixed'
        padding={{ base: '12px 8px 0', tb: '12px 24px 0' }}
        w='100%'
        zIndex='999'
        bg='brand.darkPrimary'
        borderTopRightRadius='2px'
        borderTopLeftRadius='2px'
      >
        <Flex
          gridGap='12px'
          justifyContent='space-between'
          as='ul'
          alignItems='center'
        >
          {mobileLink.slice(0, 4).map(({ icon, activeIcon, id, name, path, end, subPath }) => {
            return (
              <Box
                as='li'
                key={id}
                className={styles.mobileTab}
              >
                <NavLink
                  className={({ isActive }) => {
                    return isActive || (pathname === '/dashboard/' && name === 'Home')
                      ? styles.active
                      : styles.inactive;
                  }}
                  to={path}
                  end={end}
                >
                  <Flex
                    gridGap='8px'
                    alignItems='center'
                    flexDir='column'
                  >
                    <Box height='24px'>
                      {pathname === path ||
                      pathname === '/dashboard/' ||
                      (subPath && pathname.split('/')[2] === name.toLowerCase())
                        ? activeIcon
                        : icon}
                    </Box>

                    <Text
                      as='p'
                      fontWeight='300'
                      fontSize={{ sm: '12px', tb: '16px' }}
                      color='#F2F4F3'
                    >
                      {name}
                    </Text>
                  </Flex>
                </NavLink>
              </Box>
            );
          })}

          <Box>
            <MoreButton />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default BottomNavbar;
