import { Box } from '@chakra-ui/react';
import { PageTab, PartnersLeaderboard, PartnersData } from 'components';
import { useLocationSearch } from 'hooks';
import { PartnersTabArray } from 'LinkArray';

const PartnersTable = () => {
  const search = useLocationSearch();

  return (
    <Box mt="32px">
      <PageTab linkArray={PartnersTabArray} />

      <Box>
        {(search === '' || search === '?partners') && <PartnersData />}

        {search === '?leaderboard' && <PartnersLeaderboard />}
      </Box>
    </Box>
  );
};

export default PartnersTable;
