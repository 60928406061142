import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  Center,
  useToast,
  Checkbox,
  Text,
  Flex,
} from '@chakra-ui/react';
import { Button, Input } from 'components';
import { fetchSingleUser } from 'store/slices/user.slice';
import { put } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const EditUserModal = ({
  isOpen,
  onClose,
  firstName,
  lastName,
  country,
  phone,
  acceptPromotions,
  headers,
  id,
  dispatch,
}) => {
  const toast = useToast();
  const [promotions, setPromotions] = useState(acceptPromotions);

  const [state, setState] = useState({});

  // console.log(state, 'STATE');

  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e, 'event');
    setPromotions(!Boolean(promotions));
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const URL = `${BASE_URL}/admin/users/${id}/update`;

      const response = await put(
        URL,
        {
          acceptPromotions: Boolean(acceptPromotions),
          ...state,
        },
        headers
      );

      if (response.data) {
        setLoading(false);

        setState({});
        dispatch(fetchSingleUser(id));

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        onClose();
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Error',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent borderRadius="16px" maxWidth="420px" padding={'32px'}>
        <ModalHeader
          fontSize={'24px'}
          fontWeight={'600'}
          p="0"
          textAlign="center"
        >
          Edit User
        </ModalHeader>

        <ModalBody p="0">
          <form onSubmit={handleSubmit}>
            <VStack gridGap="20px" m="32px 0 40px">
              <Input
                label="First name"
                id="firstName"
                width="365px"
                minHeight="52px"
                name="firstName"
                placeholder="First name"
                defaultValue={firstName}
                onChange={handleChange}
              />

              <Input
                label="Last name"
                id="lastName"
                width="365px"
                minHeight="52px"
                name="lastName"
                placeholder="Last name"
                defaultValue={lastName}
                onChange={handleChange}
              />

              <Input
                label="Phone number"
                id="phone"
                width="365px"
                minHeight="52px"
                name="phone"
                placeholder="Phone number"
                defaultValue={phone}
                onChange={handleChange}
              />

              <Input
                label="Country"
                htmlFor="country"
                id="country"
                width="365px"
                minHeight="52px"
                name="country"
                placeholder="Country"
                defaultValue={country}
                onChange={handleChange}
              />

              <Flex width="100%" alignItems="center" gridGap="5px">
                <Checkbox
                  onChange={handleChange}
                  name="acceptPromotions"
                  value={Boolean(promotions)}
                  size="lg"
                  borderRadius="1xl"
                  defaultChecked={Boolean(acceptPromotions)}
                  colorScheme="green"
                >
                  <Text>Accept Promotions</Text>
                </Checkbox>
              </Flex>
            </VStack>

            <Center>
              <Button
                type="submit"
                minH="52px"
                minW="365px"
                disabled={loading}
                isLoading={loading}
                fontWeight="500"
              >
                Submit
              </Button>
            </Center>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditUserModal;
