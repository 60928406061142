import { Box } from '@chakra-ui/react';

const GreenboxDetails = () => {
  return (
    <Box>
      <Box>GreenboxDetails</Box>
    </Box>
  );
};

export default GreenboxDetails;
