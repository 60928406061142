import { usePagination } from '@ajna/pagination';
import { Box, Table, Tr, Td, Th, Thead, Tbody, Flex } from '@chakra-ui/react';
import { EndCampaignModal, FilterSelect, Loader, PaginationWrapper, ScrollRightContainer } from 'components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetAllWishlistQuery } from 'store/services/wishlist.service';
import { formatDateToUTC } from 'utils';

const WishlistCampaign = () => {
  const [page, setPage] = useState(1);
  const [criteria, setCriteria] = useState('');
  const { data, isLoading, isSuccess } = useGetAllWishlistQuery({
    page,
    criteria,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  return (
    <>
      <Box>
        {isLoading && <Loader />}

        <Flex
          gap='16px'
          mb='40px'
          alignItems='center'
        >
          <FilterSelect
            width='128px'
            onChange={(e) => setCriteria(e.target.value)}
          >
            <option value=''>All Users</option>
            <option value='oldUsers'>Old Users</option>
            <option value='newUsers'>New Users</option>
          </FilterSelect>

          {/* <Box
            maxW='355px'
            width='100%'
          >
            <SearchInput placeholder='Search by username or wishlist item' />
          </Box> */}

          {/* <Flex
            gridGap='5px'
            alignItems='center'
            cursor='pointer'
            _hover={{
              fontWeight: '600',
              color: '#797A7A',
            }}
          >
            <Text
              fontSize='14px'
              fontWeight='500'
            >
              Filter
            </Text>

            <FilterSVG />
          </Flex> */}
        </Flex>

        {isSuccess && (
          <>
            <ScrollRightContainer>
              <Table overflow='auto'>
                <Thead>
                  <Tr>
                    <Th>Tag</Th>
                    <Th>Frequency</Th>
                    <Th>Wishlist Item</Th>
                    <Th>Criteria</Th>
                    <Th>Date/Time</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {data?.data?.map(({ _id, tag, frequency, gifts, criteria, createdAt, cycle, active }) => {
                    const entry = String(tag.replace(/\s/g, '_')).toLowerCase();
                    return (
                      <Tr key={_id}>
                        <Td
                          color='#6FBE45'
                          textDecor='underline'
                        >
                          <Link
                            to={`/dashboard/wishlist/tag?entry=${entry}&cycle=${cycle}&id=${_id}&criteria=${criteria}`}
                          >
                            {tag}
                          </Link>
                        </Td>

                        <Td textTransform='capitalize'>{frequency}</Td>

                        <Td
                          display='flex'
                          gap='4px'
                        >
                          {gifts.map((r, _i) => (
                            <Box key={_i}>{r.value},</Box>
                          ))}
                        </Td>

                        <Td textTransform='capitalize'>{criteria}</Td>

                        <Td>{formatDateToUTC(createdAt)}</Td>

                        <Td>{active ? <EndCampaignModal id={_id} /> : 'Ended'}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </ScrollRightContainer>

            <PaginationWrapper
              setCurrentPage={setCurrentPage}
              totalDataCount={isLoading ? 0 : data?.totalCount}
              currentPage={currentPage}
              setPage={setPage}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              size={pageSize}
              offset={offset}
              pages={pages}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default WishlistCampaign;
