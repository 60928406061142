import { Box, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const BoxInput = ({ children, label, value, padding, textTransform, link, textColor, height }) => {
  return (
    <Box mb='24px'>
      <Text
        fontSize='16px'
        lineHeight='21px'
        fontWeight='400'
        mb='10px'
        textTransform='capitalize'
      >
        {label}
      </Text>

      <Box
        borderRadius='5px'
        bg='#FCFDFD'
        height={height ? height : '48px'}
        minWidth={{ base: '100%', md: '374px' }}
        padding={padding ? padding : '12px 14px 19px'}
        width='100%'
        borderBottom='0.5px solid #A1A3A2'
      >
        <Flex
          justifyContent='space-between'
          alignItems='center'
        >
          <Box
            fontWeight='500'
            fontSize='18px'
            lineHeight='24px'
            color={textColor ? textColor : 'initial'}
            textTransform={textTransform ? textTransform : 'initial'}
          >
            {link ? <Link to={link}>{value}</Link> : value}

            {/* {value} */}
          </Box>

          <Box>{children}</Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default BoxInput;
