import { Modal, ModalOverlay, ModalContent, ModalHeader, Text, useDisclosure, Box } from '@chakra-ui/react';
import { ActionButton, CloseModal, ScrollRightContainer, VerificationTable } from 'components';

const ReviewKYCModal = ({ reviewDocuments }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <ActionButton onClick={onOpen}>Review KYC</ActionButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          padding='24px'
          borderRadius='16px'
          maxWidth='1000px'
        >
          <ModalHeader
            padding='24px 30px'
            fontWeight='600'
            borderTopRadius='16px'
            textAlign='center'
          >
            KYC Review
          </ModalHeader>

          {reviewDocuments.data.documents.length === 0 && <Text>No KYC document</Text>}

          <ScrollRightContainer>
            {reviewDocuments.data.documents.length >= 1 && <VerificationTable data={reviewDocuments.data} />}
          </ScrollRightContainer>

          <CloseModal onClick={onClose} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ReviewKYCModal;
