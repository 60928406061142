import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal } from 'components';
import { BASE_URL } from 'constant';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSingleDocument } from 'store/slices/user.slice';
import { getHeaders } from 'utils';
import { put } from 'utils/axiosLib';

const FlagKYC = ({ id, document }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const headers = getHeaders();
  const dispatch = useDispatch();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const URL = `${BASE_URL}/admin/verification/documents/${id}/flag`;

    try {
      const response = await put(
        URL,
        {
          flag: !Boolean(document.flaggedForReview),
        },
        headers
      );

      if (response.data) {
        setLoading(false);
        onClose();
        dispatch(fetchSingleDocument({ id }));
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Box mt='3rem'>
      <Button
        minH='52px'
        onClick={onOpen}
        bg='brand.error'
        width={{ base: '100%', md: '180px' }}
        color='#fff'
        _hover={{
          border: '0px',
        }}
        _focus={{
          border: '0px',
        }}
      >
        {document.flaggedForReview ? 'UnFlag' : 'Flag'} KYC
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius='16px'
          maxWidth='420px'
        >
          <ModalHeader
            padding='24px'
            fontWeight='600'
            borderTopRadius='16px'
            textAlign='center'
          >
            {document.flaggedForReview ? 'UnFlag' : 'Flag'} KYC
          </ModalHeader>

          <ModalBody pb='1rem'>
            <Text
              mb='2rem'
              textAlign='center'
            >
              Flagging this kyc, will mark it as requiring further review
            </Text>

            <form onSubmit={handleSubmit}>
              <Center gridGap='0rem'>
                <Button
                  type='submit'
                  minH='52px'
                  minW='140px'
                  disabled={loading}
                  isLoading={loading}
                >
                  Proceed
                </Button>
              </Center>
            </form>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FlagKYC;
