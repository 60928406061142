import { useEffect, useRef, useState } from 'react';
import { Box, Center, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Recaptcha from 'react-google-recaptcha';
import { Button, AuthImage, PinInput } from 'components';
import { post } from 'utils/axiosLib';
import { constant, convertNumberMS } from 'utils';
import { BitmamaAdminLogoSVG, RightArrowSVG } from 'assets';
import { useAuth } from 'hooks';
import { BASE_URL } from 'constant';

const ResetCode = () => {
  const recaptchaInstanceRef = useRef();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { logout, token } = useAuth();
  const pinMax = 6;

  const [pin, setPin] = useState('');
  const [email, setEmail] = useState(searchParams.get('email'));
  const [initialTime, setInitialTime] = useState(600);
  const [startTimer, setStartTimer] = useState(true);
  const [disableRequest, setDisableRequest] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resetloading, setResetLoading] = useState(false);
  const [hasCompleteValues, setHasCompleteValues] = useState(false);

  const headers = {
    authorization: token,
    'Content-Type': 'application/json',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (navigator.onLine === true) {
      setLoading(true);
      try {
        const captcha = await recaptchaInstanceRef.current?.executeAsync();

        const response = await post(
          `${BASE_URL}/auth/forgotPassword?humanKey=${captcha}`,
          {
            email,
            code: pin,
            ...headers,
          }
        );

        if (response.status === 'success') {
          setLoading(false);

          console.log(response.data, 'DATA SUCCESS');
          toast({
            position: 'bottom-right',
            title: 'Success',
            description: response.data.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          navigate(constant.PAGES.LOGIN, {
            replace: true,
          });
        }

        if (response.status === 201) {
          setLoading(false);

          toast({
            position: 'bottom-right',
            title: 'Success',
            description: response.data.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          navigate(constant.PAGES.LOGIN, {
            replace: true,
          });
        }

        if (response.status === 202) {
          setLoading(false);

          toast({
            position: 'bottom-right',
            title: 'Success',
            description: response.data.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          navigate(constant.PAGES.LOGIN, {
            replace: true,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error, 'DATA error here');
        await recaptchaInstanceRef.current?.reset();
        toast({
          position: 'bottom-right',
          title: 'Oops!!',
          description: error.error,
          status: 'error',
          duration: 18000,
          isClosable: true,
        });
      }
    } else {
      toast({
        position: 'bottom-right',
        title: 'Network Error!!',
        description: 'Please check your network connection',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleResendPin = async (e) => {
    e.preventDefault();

    if (navigator.onLine === true) {
      setResetLoading(true);
      try {
        const captcha = await recaptchaInstanceRef.current?.executeAsync();

        const response = await post(
          `${BASE_URL}/auth/forgotPassword?humanKey=${captcha}`,
          { email, ...headers }
        );

        if (response) {
          setDisableRequest(true);
          setInitialTime(60);
          setStartTimer(true);
        }

        if (response.status === 'success') {
          setResetLoading(false);

          console.log(response.data, 'DATA HERE');
          toast({
            position: 'bottom-right',
            title: 'Success',
            description: response.data.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }

        if (response.status === 202) {
          setResetLoading(false);

          console.log(response.data, 'DATA HERE');

          toast({
            position: 'bottom-right',
            title: 'Success',
            description: response.data.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error, 'error here');
        await recaptchaInstanceRef.current?.reset();
        toast({
          position: 'bottom-right',
          title: 'Oops!!',
          description: error.error,
          status: 'error',
          duration: 18000,
          isClosable: true,
        });
      }
    } else {
      toast({
        position: 'bottom-right',
        title: 'Network Error!!',
        description: 'Please check your network connection',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (!email) {
      const _email = prompt('Enter your email address');
      _email && setEmail && setEmail(_email);
    }

    if (initialTime > 0) {
      setTimeout(() => {
        setInitialTime(initialTime - 1);
      }, 1000);
    }

    if (initialTime === 0 && startTimer) {
      setStartTimer(false);
      setDisableRequest(false);
    }
  }, [email, initialTime, startTimer]);

  return (
    <Flex mt={{ sm: '-3rem' }} gridGap="2rem" height="100vh">
      <AuthImage />

      <Box maxWidth="523px" margin="0 auto" width="100%" pt="185px">
        <Link
          onClick={() => {
            logout();
            navigate('/login', {
              replace: true,
            });
          }}
          to="/login"
        >
          <Box>
            <BitmamaAdminLogoSVG />
          </Box>
        </Link>

        <Flex flexDirection="column">
          <Flex gridGap="2px" margin="50px 0 9px" alignItems="center">
            <Heading fontWeight="600" fontSize="2rem">
              Reset Code
            </Heading>
          </Flex>

          <Text
            mb="66px"
            letterSpacing="-0.5px"
            color="#797A7A"
            fontSize="18px"
          >
            Enter the 6-digit reset code sent to your phone
          </Text>

          <form onSubmit={handleSubmit}>
            <Box mb="112px">
              <PinInput
                limit={pinMax}
                onChange={(val) => setPin(val)}
                onComplete={() => setHasCompleteValues(true)}
              />
            </Box>

            <Button
              isLoading={loading}
              disabled={!hasCompleteValues || loading}
              maxW="399px"
              width="100%"
              type="submit"
              fontWeight="700"
            >
              <Flex gridGap="10px">
                Submit <RightArrowSVG />
              </Flex>
            </Button>
          </form>

          <Center maxWidth="399px" width="100%" flexDir="column">
            <Text margin="48px 0 30px">{convertNumberMS(initialTime)}</Text>

            <Button
              isLoading={resetloading}
              disabled={disableRequest}
              borderRadius="12.6723px"
              fontSize="16px"
              bg="#E2F2DA"
              color="#0D4740"
              onClick={handleResendPin}
            >
              RESEND CODE
            </Button>
          </Center>
        </Flex>

        <Recaptcha
          ref={recaptchaInstanceRef}
          sitekey={process.env.REACT_APP_RECAPTCHA}
          size="invisible"
        />
      </Box>
    </Flex>
  );
};

export default ResetCode;
