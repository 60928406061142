import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ls, constant, wrapBaseApi } from 'utils';

const auth = ls.get(constant.AUTH_TOKEN_KEY);

export const testConnection = createApi({
  reducerPath: 'testConnection',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, _) => {
      headers.set('Authorization', auth.token);
      headers.set('Content-Type', 'application/json');
      headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    testWalletConnection: builder.mutation({
      query: ({ path }) => ({
        url: wrapBaseApi(`/wallet/admin/${path}`),
        method: 'POST',
        body: {
          throwError: false,
          length: 1000,
        },
      }),
      transformResponse: (response) => response,
    }),
  }),
});

export const { useTestWalletConnectionMutation } = testConnection;

export default testConnection;
