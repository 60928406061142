import { Box } from '@chakra-ui/react';
import { PageTab, PageTitle } from 'components';
import { useLocationSearch } from 'hooks';
import { SettingsTabLinkArray } from 'LinkArray';
import { Rate, AllRequest, AppConfig } from 'pages';
import CountryConfig from './CountryConfig';

const Settings = () => {
  const search = useLocationSearch();
  return (
    <Box>
      <PageTitle title='Settings' />

      <Box
        mt='27px'
        bg='#FCFDFD'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
        borderRadius='10px'
        minHeight='700px'
        padding={{ base: '15px', md: '15px 25px' }}
      >
        <PageTab linkArray={SettingsTabLinkArray} />

        {search === '' && <Rate />}

        {search === '?request' && <AllRequest />}

        {search === '?config' && <AppConfig />}

        {search === '?country-config' && <CountryConfig />}
      </Box>
    </Box>
  );
};

export default Settings;
