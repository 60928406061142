import { Select as ChakraSelect } from '@chakra-ui/react';

const Select = ({ onChange, children, ...props }) => {
  return (
    <ChakraSelect
      _hover={{
        bg: 'rgba(49, 183, 169, 0.2)',
      }}
      _focus={{
        bg: 'rgba(49, 183, 169, 0.2)',
      }}
      bg="rgba(49, 183, 169, 0.2)"
      borderRadius="5px"
      borderWidth="0"
      minW="79px"
      fontSize="12px"
      lineHeight="16px"
      height="38px"
      fontWeight="400"
      onChange={onChange}
      {...props}
    >
      {children}
    </ChakraSelect>
  );
};

export default Select;
