import { Box, Flex, Text } from '@chakra-ui/react';
import { ApprovedSVG, CancelledSVG, PendingSVG, ProccessingSVG, ClearedSVG } from 'assets';

const TableDataStatus = ({ status, withIcon = true, textTransform }) => {
  const colorValue = () => {
    switch (status) {
      case 'success':
        return 'brand.success';

      case 'approved':
        return 'brand.success';

      case 'completed':
        return 'brand.success';

      case 'fufilled':
        return 'brand.success';

      case 'confirmed':
        return 'brand.success';

      case 'made':
        return 'brand.success';

      case 'claimed':
        return 'brand.success';

      case 'processed':
        return 'brand.success';

      case 'closed':
        return 'brand.success';

      case 'credit':
        return 'brand.success';

      case 'issued':
        return 'brand.success';

      case 'TransferMade':
        return 'brand.success';

      case 'PaymentMade':
        return 'brand.success';

      case 'TransferComplete':
        return 'brand.success';

      case 'cleared':
        return '#2E2708';

      case 'paid':
        return 'brand.success';

      case 'pending':
        return 'brand.warning';

      case 'PaymentPending':
        return 'brand.warning';

      case 'PaymentIncomplete':
        return 'brand.error';

      case 'TransferStarted':
        return 'brand.warning';

      case 'TransferSendingCryptoFunds':
        return '#d79728';

      case 'TransferReceivedCryptoFunds':
        return '#d79728';

      case 'started':
        return 'brand.warning';

      case 'active':
        return 'brand.warning';

      case 'in-review':
        return 'brand.warning';

      case 'refund':
        return 'brand.success';

      case 'running':
        return 'brand.warning';

      case 'TransferPending':
        return 'brand.warning';

      case 'reported':
        return 'brand.warning';

      case 'error':
        return 'brand.error';

      case 'defaulted':
        return 'brand.error';

      case 'rejected':
        return 'brand.error';

      case 'timedout':
        return 'brand.error';

      case 'incomplete':
        return 'brand.error';

      case 'declined':
        return 'brand.error';

      case 'blocked':
        return 'brand.error';

      case 'debit':
        return 'brand.error';

      case 'expired':
        return 'brand.error';

      case 'cancelled':
        return 'brand.error';

      case 'failed':
        return 'brand.error';

      case 'canceled':
        return 'brand.error';

      case 'denied':
        return 'brand.error';

      case 'TransferFailed':
        return 'brand.error';

      case 'TransferAmlFailed':
        return 'brand.error';

      case 'TransferCancelled':
        return 'brand.error';

      case 'TransferFiatFundsDebited':
        return 'brand.error';

      case 'PaymentFailed':
        return 'brand.error';

      case 'PaymentCancelled':
        return 'brand.error';

      case 'processing':
        return '#31B7A9';

      case 'Auto Processing':
        return 'brand.success';

      default:
        break;
    }
  };

  const statusIcon = () => {
    switch (status) {
      case 'approved':
        return <ApprovedSVG />;

      case 'confirmed':
        return <ApprovedSVG />;

      case 'fufilled':
        return <ApprovedSVG />;

      case 'made':
        return <ApprovedSVG />;

      case 'claimed':
        return <ApprovedSVG />;

      case 'closed':
        return <ApprovedSVG />;

      case 'completed':
        return <ApprovedSVG />;

      case 'success':
        return <ApprovedSVG />;

      case 'processed':
        return <ApprovedSVG />;

      case 'issued':
        return <ApprovedSVG />;

      case 'cleared':
        return <ClearedSVG />;

      case 'paid':
        return <ApprovedSVG />;

      case 'pending':
        return <PendingSVG />;

      case 'active':
        return <PendingSVG />;

      case 'in-review':
        return <PendingSVG />;

      case 'running':
        return <PendingSVG />;

      case 'reported':
        return <PendingSVG />;

      case 'error':
        return <CancelledSVG />;

      case 'rejected':
        return <CancelledSVG />;

      case 'defaulted':
        return <CancelledSVG />;

      case 'blocked':
        return <CancelledSVG />;

      case 'failed':
        return <CancelledSVG />;

      case 'declined':
        return <CancelledSVG />;

      case 'expired':
        return <CancelledSVG />;

      case 'timedout':
        return <CancelledSVG />;

      case 'cancelled':
        return <CancelledSVG />;

      case 'canceled':
        return <CancelledSVG />;

      case 'denied':
        return <CancelledSVG />;

      case 'processing':
        return <ProccessingSVG />;

      default:
        break;
    }
  };

  return (
    <Flex
      alignItems='center'
      gridGap='6px'
    >
      {withIcon && <Box>{statusIcon()}</Box>}
      <Text
        textTransform={textTransform ? textTransform : 'capitalize'}
        color={colorValue()}
      >
        {status}
      </Text>
    </Flex>
  );
};

export default TableDataStatus;
