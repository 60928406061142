import { Center } from '@chakra-ui/react';

const RoundBox = ({ count }) => {
  return (
    <Center
      bg='#efefef'
      borderRadius='full'
      width={{ base: '24px', tb: '35px' }}
      height={{ base: '24px', tb: '35px' }}
      padding={{ base: '4px', tb: '8px' }}
      fontSize={{ base: '10px', tb: '14px' }}
    >
      {count}
    </Center>
  );
};

export default RoundBox;
