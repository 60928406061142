import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constant, ls } from 'utils';

export const appConfigApi = createApi({
  reducerPath: 'appConfig',
  refetchOnMountOrArgChange: true,
  tagTypes: ['allConfigs', 'countries'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, _) => {
      const auth = ls.get(constant.AUTH_TOKEN_KEY);
      if (auth && auth.token) {
        headers.set('Authorization', auth.token);
      }
      headers.set('Content-Type', 'application/json');
      headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getAllConfigs: builder.query({
      query: () => `/appConfig/admin-backend`,
      transformResponse: (res) => res.data,
      providesTags: ['allConfigs'],
    }),

    updateConfig: builder.mutation({
      query: ({ field, syncUpdate, data, changeLock }) => ({
        url: `/appConfig/backend`,
        method: 'PUT',
        body: {
          field,
          data,
          syncUpdate,
          changeLock,
        },
      }),

      transformResponse: (res) => res,
      invalidatesTags: ['allConfigs'],
    }),

    getCountryConfigs: builder.query({
      query: () => `/appConfig/features`,
      transformResponse: (res) => res.data,
      providesTags: ['countries'],
    }),

    updateCountryConfig: builder.mutation({
      query: ({ data, country }) => ({
        url: `appConfig/feature?country=${country}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),

      transformResponse: (res) => res,
      invalidatesTags: ['countries'],
    }),
  }),
});

export const {
  useGetAllConfigsQuery,
  useUpdateConfigMutation,
  useGetCountryConfigsQuery,
  useUpdateCountryConfigMutation,
} = appConfigApi;

export default appConfigApi;
