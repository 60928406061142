import { useMemo, useEffect } from 'react';
import { Box, Center, useToast, VStack } from '@chakra-ui/react';
import { Loader, Input, Button, FilterSelect } from 'components';
import { useRedebounce } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useResolveAccountMutation } from 'store/services/greenbox.service';
import { fetchBanks } from 'store/slices/bank.slice';

const StepOne = ({ handleChange, state, nextStep, country, setState, setBankCode, bankCode }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.allBanks.banks);

  const { bankAccountNumber } = state;

  const [_resolveBank, { isLoading: isResolvedLoading }] = useResolveAccountMutation();

  const debounce = useRedebounce();

  useEffect(() => {
    const _resolve = async () => {
      const response = await _resolveBank({
        bankAccountNumber,
        bankCode,
      });

      if (response.data) {
        setState({
          ...state,
          accountName: response.data.account_name,
        });
      }

      if (response.error) {
        toast({
          position: 'bottom-right',
          title: 'Oops',
          description: response.error.data.error,
          status: 'error',
          duration: 18000,
          isClosable: true,
        });
      }
    };

    if (bankCode?.length >= 2 && bankAccountNumber?.length >= 10) {
      debounce(() => _resolve(), 'resolveBank', 2000)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankCode, bankAccountNumber, _resolveBank, debounce, setState]);

  const userCountry = useMemo(() => {
    const countryList = {
      nigeria: 'ng',
      ghana: 'gh',
      kenya: 'ke',
    };
    return countryList[country];
  }, [country]);

  useEffect(() => {
    dispatch(fetchBanks(userCountry));
  }, [dispatch, userCountry]);

  return (
    <VStack
      minW='100%'
      width='100%'
      alignItems='initial'
      spacing='24px'
    >
      <Box>
        <FilterSelect
          label='Category'
          id='category'
          minH='58px'
          htmlFor='category'
          name='category'
          onChange={handleChange}
          defaultValue={state.category}
        >
          <option>Category</option>
          <option value='primary'>Primary</option>
          <option value='secondary'>Secondary</option>
        </FilterSelect>
      </Box>

      <Box>
        <FilterSelect
          label='Bank'
          id='bankCode'
          minH='60px'
          htmlFor='bankCode'
          name='bankCode'
          onChange={(e) => setBankCode(e.target.value)}
          defaultValue={bankCode}
        >
          <option>Select Bank</option>
          {data.bankPayloads
            ? data.bankPayloads.map(({ bankName, bankCode }, index) => {
                return (
                  <option
                    key={index}
                    value={bankCode}
                  >
                    {bankName}
                  </option>
                );
              })
            : data.ghanaBankPayloads.map(({ bankName, bankCode }, index) => {
                return (
                  <option
                    key={index}
                    value={bankCode}
                  >
                    {bankName}
                  </option>
                );
              })}
        </FilterSelect>
      </Box>

      <Box>
        <Input
          label='Account Number'
          name='bankAccountNumber'
          placeholder='Enter Account Number'
          minH='60px'
          onChange={handleChange}
          defaultValue={state.bankAccountNumber}
          max={10}
          inputMode='numeric'
        />
      </Box>

      <Box>
        {isResolvedLoading && <Loader />}

        <Input
          label='Account Name'
          name='accountName'
          disabled
          minH='60px'
          defaultValue={state?.accountName}
        />
      </Box>

      <Center
        width='100%'
        justify='flex-end'
        flexDir='column'
        mt='32px !important'
      >
        <Button
          minH='60px'
          width='320px'
          cursor='pointer'
          onClick={nextStep}
          disabled={!(String(state.accountName).length >= 4)}
        >
          Save and Continue
        </Button>
      </Center>
    </VStack>
  );
};

export default StepOne;
