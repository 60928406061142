import { useEffect, useState } from 'react';
import { Box, Table, Tr, Td, Th, Thead, Tbody, Flex, Select } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination } from '@ajna/pagination';
import {
  AddMerchantModal,
  CustomDate,
  DateFilter,
  ExportUsersFilterCSV,
  Loader,
  Merchants,
  MotionBox,
  PageTab,
  PaginationWrapper,
  ScrollRightContainer,
  SearchInput,
  UserTransactionModal,
} from 'components';
import { fetchUsers, getLoginActivityDate, getUsersCustomDate } from 'store/slices/user.slice';
import { useDebounce, useLocationSearch } from 'hooks';
import { formatDateToUTC, last30Days, lastWeek } from 'utils';
import { UsersTabArray } from 'LinkArray/tabArray';

const AllUsers = () => {
  const dispatch = useDispatch();

  const {
    user: { data, totalCount, status, beforeAndAfter, loginActivity: loginActivityValue },
  } = useSelector((state) => state.allUsers);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: { currentPage: 1, pageSize: 10 },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const [searchString, setSearchString] = useState('');
  const [customValue, setCustomValue] = useState('');
  const [loginActivityClicked, setLoginActivityClicked] = useState(false);
  const [checkClick, setCheckClick] = useState(false);

  const [filter, setFilter] = useState('');
  const [lastEvent, setLastEvent] = useState('');

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  const gotoUserdetails = (id) => {
    // navigate(`/dashboard/users/${id}`);
    window.location.href = `/dashboard/users/${id}`;
  };

  /**
   *  filters when users joined
   **/
  const handleDateCreated = (value) => {
    setLastEvent(value);

    setLoginActivityClicked(false);
    setCustomValue(value);
    setSearchString('');
    setCurrentPage(1);

    if (value === 'weekly=true') {
      dispatch(getUsersCustomDate(`afterDate=${lastWeek}`));
      setCheckClick(false);
    }

    if (value === 'monthly=true') {
      dispatch(getUsersCustomDate(`afterDate=${last30Days}`));
      setCheckClick(false);
    }

    if (value === 'custom') {
      setCheckClick(true);
      dispatch(getUsersCustomDate(``));
    }
  };

  /**
   *  filters when a user last login
   **/
  const handleLastLogin = (value) => {
    setLastEvent(value);
    setLoginActivityClicked(true);
    setCustomValue(value);
    setSearchString('');
    setCurrentPage(1);

    if (value === 'weekly=true') {
      dispatch(getLoginActivityDate(`weeklyLoggedInUsers=${true}`));
    }

    if (value === 'monthly=true') {
      dispatch(getLoginActivityDate(`monthlyLoggedInUsers=${true}`));
    }

    if (value === 'custom') {
      setCheckClick(!checkClick);
    }
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
    setCustomValue('');

    if (e.target.value === 'dateJoined') {
      dispatch(getLoginActivityDate(``));
      setCheckClick(false);
    }

    if (e.target.value === 'loginActivity') {
      dispatch(getUsersCustomDate(``));
      setCheckClick(false);
    }

    if (e.target.value === 'reset') {
      dispatch(getUsersCustomDate(``));
      dispatch(getLoginActivityDate(``));
    }
  };

  useEffect(() => {
    dispatch(
      fetchUsers({
        search: searchString,
        limit: 10,
        page: currentPage,
        time: loginActivityClicked ? loginActivityValue : beforeAndAfter,
      })
    );
  }, [beforeAndAfter, currentPage, dispatch, loginActivityClicked, loginActivityValue, searchString]);

  const search = useLocationSearch();
  return (
    <Box>
      <Flex
        justifyContent='space-between'
        mb='36px'
        alignItems='center'
      >
        <Box width='100%'>
          <PageTab
            linkArray={UsersTabArray}
            mb='0'
          />
        </Box>

        <Box>{search === '?merchants' && <AddMerchantModal />}</Box>
      </Flex>

      {search === '' && (
        <>
          <Flex
            justifyContent='space-between'
            flexDir={{ sm: 'column', md: 'row' }}
            gap={{ sm: '24px', md: '0' }}
          >
            <Flex
              gridGap='1rem'
              flexDir={{ sm: 'column', tb: 'row' }}
            >
              <Box width={{ sm: '100%', tb: '319px' }}>
                <SearchInput
                  onChange={debouncedChangeHandler}
                  placeholder='Search for user id, name or email'
                />
              </Box>

              <Box width='140px'>
                <Select
                  borderRadius='15px'
                  bg='#F2F4F3'
                  height='56px'
                  onChange={handleFilter}
                >
                  <option value='reset'>Filters</option>
                  <option value='dateJoined'>Date joined</option>
                  <option value='loginActivity'>Login Activity</option>
                </Select>
              </Box>
            </Flex>

            <MotionBox
              initial={{ opacity: 0 }}
              animate={{ y: 5, opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                delay: 1,
                y: { type: 'stiffness', stiffness: 100 },
                default: { duration: 2 },
              }}
            >
              <Box>
                {filter === 'dateJoined' && (
                  <Flex gridGap='1rem'>
                    <DateFilter
                      bg='#EFF0F7'
                      active={customValue}
                      handleClick={handleDateCreated}
                    />
                  </Flex>
                )}

                {filter === 'loginActivity' && (
                  <Flex gridGap='1rem'>
                    <DateFilter
                      bg='#EFF0F7'
                      active={customValue}
                      handleClick={handleLastLogin}
                    />
                  </Flex>
                )}
              </Box>
            </MotionBox>
          </Flex>

          <Box
            mt='16px'
            mr='0'
            ml='auto'
            mb='38px'
            maxWidth='520px'
            width='100%'
          >
            {filter === 'dateJoined' && (
              <Flex
                flexDir='column'
                justifyContent='right'
              >
                <Box>
                  {checkClick && (
                    <CustomDate
                      filterType='users'
                      loginActivity={false}
                    />
                  )}
                </Box>

                <Flex
                  mt={{ base: '16px', md: '0' }}
                  justifyContent={{ sm: 'flex-start', md: 'end' }}
                >
                  <ExportUsersFilterCSV
                    time={beforeAndAfter}
                    lastEvent={lastEvent}
                  />
                </Flex>
              </Flex>
            )}

            {filter === 'loginActivity' && (
              <Flex
                flexDir='column'
                justifyContent='right'
              >
                <Box>
                  {checkClick && (
                    <CustomDate
                      filterType='users'
                      loginActivity={true}
                    />
                  )}
                </Box>

                <Flex
                  mt={{ base: '16px', md: '0' }}
                  justifyContent={{ sm: 'flex-start', md: 'end' }}
                >
                  <ExportUsersFilterCSV
                    time={loginActivityValue}
                    lastEvent={lastEvent}
                  />
                </Flex>
              </Flex>
            )}
          </Box>

          <ScrollRightContainer minHeight='420px'>
            {status === 'loading' && <Loader />}
            <Table>
              <Thead>
                <Tr>
                  <Th>Full Name</Th>
                  <Th>Username</Th>
                  <Th>Email</Th>
                  <Th>Phone</Th>
                  <Th>Country</Th>
                  <Th>2FA Status</Th>
                  <Th>KYC Level</Th>
                  <Th>Last Login</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.map(
                    (
                      { _id, username, firstName, lastName, email, country, twoFactor, phone, kycLevel, lastLoginDate },
                      index
                    ) => {
                      const fullName = `${firstName} ${lastName}`;

                      return (
                        <Tr
                          key={_id}
                          cursor='pointer'
                        >
                          <Td textTransform='capitalize'>
                            {fullName ? (
                              <UserTransactionModal
                                userId={_id}
                                fullName={fullName}
                                index={index}
                              />
                            ) : (
                              'N/A'
                            )}
                          </Td>

                          <Td onClick={() => gotoUserdetails(_id)}>{username}</Td>

                          <Td
                            onClick={() => gotoUserdetails(_id)}
                            textTransform='lowercase'
                          >
                            {email}
                          </Td>

                          <Td onClick={() => gotoUserdetails(_id)}>{phone}</Td>

                          <Td
                            onClick={() => gotoUserdetails(_id)}
                            textTransform='capitalize'
                          >
                            {country}
                          </Td>

                          <Td
                            onClick={() => gotoUserdetails(_id)}
                            textTransform='capitalize'
                          >
                            {twoFactor ? 'On' : 'Off'}
                          </Td>

                          <Td
                            onClick={() => gotoUserdetails(_id)}
                            textTransform='capitalize'
                          >
                            {kycLevel}
                          </Td>

                          <Td
                            onClick={() => gotoUserdetails(_id)}
                            textTransform='capitalize'
                          >
                            {lastLoginDate ? formatDateToUTC(lastLoginDate) : 'N/A'}
                          </Td>
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table>
          </ScrollRightContainer>

          <PaginationWrapper
            setCurrentPage={setCurrentPage}
            totalDataCount={totalCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            pagesCount={pagesCount}
            offset={offset}
            pages={pages}
            size={10}
          />
        </>
      )}

      {search === '?merchants' && <Merchants />}
    </Box>
  );
};

export default AllUsers;
