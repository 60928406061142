import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Center,
  useToast,
  Flex,
  Box,
  Image,
} from '@chakra-ui/react';
import { Button, CloseModal, ConfigCheckbox, Input, Select, TableDataStatus } from 'components';
import { useUpdateIntegrationTransferMutation } from 'store/services/integrations.service';
import { formatDateToUTC, showFirstThreeChar, showLastThreeChar } from 'utils';
import { useCopy } from 'hooks';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { BoxDetails } from './UpdateTransferStatusModal';

const IntegrationUpdateStatusModal = ({ modalData, isOpen, onClose }) => {
  const toast = useToast();
  const {
    _id,
    fiatAmount,
    fiatType,
    fee,
    cryptoVolume,
    cryptoType,
    userAddress,
    paymentStatus: payStatus,
    processingFee,
    networkFee,
    reference,
    partnerId,
    createdAt,
    paymentUrl,
  } = modalData;

  const [_updatePaymentStatus, { isLoading }] = useUpdateIntegrationTransferMutation();
  const [sendWebhook, setSendwebhook] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState('');

  // console.log(modalData, 'modal data');
  const netCryptoVolume = cryptoVolume - fee - processingFee - networkFee;

  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      const response = await _updatePaymentStatus({
        _id,
        paymentStatus,
        sendWebhook,
        reason: paymentStatus === 'PaymentFailed' || paymentStatus === 'PaymentCancelled' ? inputValue : undefined,
        depositApprovalReference: paymentStatus === 'PaymentConfirmed' && inputValue ? inputValue : undefined,
      });

      if (response.data) {
        setSendwebhook(true);
        toast({
          title: 'Success',
          status: 'success',
          description: response?.data?.message,
          isClosable: true,
          duration: '4000',
          position: 'bottom-right',
        });
        onClose();

        // _getDeposits({
        //   page: 1,
        //   size: 10,
        //   status: 'PaymentMade',
        // });
      }

      if (response.error) {
        toast({
          title: 'Error',
          status: 'error',
          description: response.error.data.error.error,
          isClosable: true,
          duration: '4000',
          position: 'bottom-right',
        });
      }
    } catch (error) {}
  };

  const { hasCopied, onCopy, value } = useCopy();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        key={_id}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          bg='#fff'
          maxWidth='480px'
          padding='20px 20px 20px'
        >
          <Text
            fontSize='24px'
            textAlign='center'
            fontWeight='600'
            mb='32px'
          >
            Update Payment Status
          </Text>

          <ModalBody padding='0px'>
            <Box
              flexDir='column'
              width='100%'
              alignItems='initial'
            >
              <BoxDetails
                title='Partner ID'
                description={partnerId}
                mb='16px'
              />

              <BoxDetails
                mb='16px'
                title='Fiat Amount'
                description={`${Number(fiatAmount).toLocaleString()} ${fiatType}`}
              />

              <BoxDetails
                mb='16px'
                title='Crypto Volume'
                description={`${cryptoVolume} | (Net: ${netCryptoVolume}) ${cryptoType}`}
              />

              <BoxDetails
                mb='16px'
                description={`N.F ${networkFee} | P.F ${processingFee} ${cryptoType}`}
                title='Crypto Fee'
              />

              <BoxDetails
                title='Payment Status'
                description={<TableDataStatus status={payStatus} />}
              />

              <BoxDetails
                mb='16px'
                title='Reference'
                description={reference}
              />

              {paymentUrl && (
                <Flex
                  justifyContent='space-between'
                  mb='16px'
                >
                  <Text>Payment Receipt</Text>

                  <a
                    href={paymentUrl}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Image
                      src={paymentUrl}
                      alt='receipt'
                      width='120px'
                      height='120px'
                      borderRadius='8px'
                    />
                  </a>
                </Flex>
              )}

              <Flex
                justifyContent='space-between'
                mb='16px'
              >
                <Text>Address</Text>

                <Text
                  fontWeight='500'
                  maxWidth='280px'
                  onClick={() => onCopy(userAddress)}
                  cursor='pointer'
                >
                  {`${showFirstThreeChar(userAddress, 6)}...${showLastThreeChar(userAddress, -12)}`}
                  &nbsp;
                  {value === userAddress && hasCopied ? <CheckIcon /> : <CopyIcon />}
                </Text>
              </Flex>

              <BoxDetails
                description={formatDateToUTC(createdAt)}
                title='Date/Time'
              />

              <Flex
                gap='24px'
                m='24px 0'
              >
                <ConfigCheckbox
                  defaultChecked={sendWebhook}
                  onChange={() => setSendwebhook(!sendWebhook)}
                />

                <Text>Send Webhook</Text>
              </Flex>

              <Box
                mb='32px'
                width='100%'
              >
                <Select
                  onChange={(e) => setPaymentStatus(e.target.value)}
                  minH='52px'
                >
                  <option value='PaymentPending'>Update</option>
                  <option value='PaymentPending'>Payment Pending</option>
                  <option value='PaymentConfirmed'>Payment Confirmed</option>
                  <option value='PaymentFailed'>Payment Failed</option>
                  <option value='PaymentCancelled'>Payment Cancelled</option>
                  <option value='PaymentIncomplete'>Payment Incomplete</option>
                </Select>
              </Box>

              <Box mb='32px'>
                {paymentStatus === 'PaymentFailed' || paymentStatus === 'PaymentCancelled' ? (
                  <Input
                    minW='320px'
                    fontSize='16px'
                    label={'Reason'}
                    name={'reason'}
                    onChange={handleChange}
                  />
                ) : (
                  <></>
                )}

                {paymentStatus === 'PaymentConfirmed' && (
                  <Input
                    minW='320px'
                    fontSize='16px'
                    label='Approval Reference'
                    name='depositApprovalReference'
                    onChange={handleChange}
                  />
                )}
              </Box>

              <Center>
                <Button
                  onClick={handleUpdate}
                  isLoading={isLoading}
                  disabled={isLoading}
                  minW='220px'
                >
                  Submit
                </Button>
              </Center>
            </Box>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default IntegrationUpdateStatusModal;
