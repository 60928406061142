import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders, wrapBaseApi } from 'utils';
import { get } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const initialState = {
  greenbox: {
    status: 'idle',
    data: {
      data: [],
    },
    totalCount: 0,
  },
};

export const fetchGreenbox = createAsyncThunk(
  'greenbox/request',
  async ({ limit, page, actionType, search, status }) => {
    const response = await get(
      wrapBaseApi(
        `${BASE_URL}/wallet/admin/greenbox?actionType=${actionType}&page=${page}&limit=${limit}&search=${search}&status=${status}`
      ),

      getHeaders()
    );
    return response.data;
  }
);

const greenboxSlice = createSlice({
  name: 'greenbox',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchGreenbox.fulfilled, (state, { payload }) => {
      state.greenbox.status = 'fulfilled';
      state.greenbox.data = payload.data;
      state.greenbox.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchGreenbox.pending, (state) => {
      state.greenbox.status = 'loading';
    });

    builder.addCase(fetchGreenbox.rejected, (state) => {
      state.greenbox.status = 'error';
    });
  },
});

export const { reducer: greenboxReducer } = greenboxSlice;
