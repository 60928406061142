import { useState } from 'react';
import { usePagination } from '@ajna/pagination';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import {
  ActionButton,
  ExportBitwaya,
  FilterSelect,
  PaginationWrapper,
  ProcessAllPayout,
  ProcessPayout,
  Refresh,
  ScrollRightContainer,
  SearchInput,
  TableDataStatus,
  UpdateTransferStatusModal,
} from 'components';
import { useIntegrationWithdrawalQuery } from 'store/services/integrations.service';
import { constant, formatDateToUTC } from 'utils';
import { useCopy, useDebounce, useStorage } from 'hooks';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';

const IntegrationsWithdrawal = () => {
  const { setStorage, storageValue } = useStorage(constant.BITWAYA_WITHDRAWAL_STATUS);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [status, setStatus] = useState(storageValue ? storageValue : '');

  const [queryValue, setQueryValue] = useState('');
  // const [queryType, setQueryType] = useState('');
  const [transferType, setTransferType] = useState();
  const [hasFilters, setHasFilters] = useState(false);

  const { data, isLoading, refetch, isFetching } = useIntegrationWithdrawalQuery({
    page,
    status,
    size,
    queries: queryValue ? `search=${queryValue}` : '',
    // queries: queryValue ? `${queryType}=${queryValue}` : '',
    type: transferType,
    hasFilters,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: size,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSize = (e) => {
    setPage(1);
    setSize(e.target.value);
  };

  const handleTransferStatus = (e) => {
    const value = e.target.value;

    if (value.length > 1) {
      setHasFilters(true);
    } else {
      setHasFilters(false);
    }

    setStatus(value);
    setStorage(value);
    setPage(1);
  };

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [modalData, setModalData] = useState({});

  const handleUpdate = (data) => {
    onOpen();
    setModalData(data);
  };

  const handleTransferType = (e) => {
    const value = e.target.value;

    if (value || value.length > 1) {
      setTransferType(`currentTransactionType=${value}withdrawal`);
      setPage(1);
    } else {
      setTransferType('');
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setQueryValue(query);
      setPage(1);
    } else {
      setQueryValue('');
      setPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  const { hasCopied, onCopy, value } = useCopy();

  return (
    <Box>
      <Flex
        mb='16px'
        justifyContent='space-between'
        alignItems='center'
      >
        <Flex gridGap='12px'>
          <FilterSelect
            width='88px'
            onChange={handleSize}
          >
            <option value={10}>Size</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FilterSelect>

          <FilterSelect
            width='180px'
            onChange={handleTransferStatus}
            value={status}
          >
            <option value=''>All</option>
            <option value='TransferStarted'>TransferStarted</option>
            <option value='TransferPending'>TransferPending</option>
            <option value='TransferFiatFundsDebited'>TransferFiatFundsDebited</option>
            <option value='TransferSendingCryptoFunds'>TransferSendingCryptoFunds</option>
            <option value='TransferReadyForUserToSendCryptoFunds'>TransferReadyForUserToSendCryptoFunds</option>
            <option value='TransferReceivedCryptoFunds'>TransferReceivedCryptoFunds</option>
            <option value='TransferCancelled'>TransferCancelled</option>
            <option value='TransferComplete'>TransferComplete</option>
            <option value='TransferAmlFailed'>TransferAmlFailed</option>
            <option value='TransferFailed'>TransferFailed</option>
          </FilterSelect>

          <FilterSelect
            width='140px'
            onChange={handleTransferType}
          >
            <option value=''>Transfer Type</option>
            <option value='fiat'>Fiat</option>
            <option value='crypto'>Crypto</option>
          </FilterSelect>

          <Box
            alignSelf='center'
            width='100%'
          >
            <SearchInput
              placeholder='Search'
              minH='48px'
              width={{ base: '100%', tb: '319px' }}
              onChange={debouncedChangeHandler}
              // disabled={queryType.length <= 1}
            />
          </Box>
        </Flex>

        <ProcessAllPayout data={data?.data} />

        <Flex
          alignItems='center'
          gap='16px'
        >
          <Refresh
            isLoading={isFetching}
            onClick={refetch}
          />

          <ExportBitwaya
            queries={`search=${queryValue}`}
            transferStatus={status}
            transferType={transferType}
            exportType='withdrawal'
          />
        </Flex>
      </Flex>

      <>
        <ScrollRightContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Date/Time</Th>
                <Th>Transfer Status</Th>
                <Th>Email</Th>
                <Th>Fiat Amount</Th>
                <Th>Crypto Volume</Th>
                <Th>Action</Th>
                <Th>Account</Th>
                <Th>Partner ID</Th>
                <Th>Transfer ID</Th>
                <Th>Reference</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data &&
                data.data.map(
                  (
                    {
                      _id,
                      createdAt,
                      partnerId,
                      identification,
                      fiatType,
                      fiatAmount,
                      cryptoVolume,
                      cryptoType,
                      reference,
                      transferStatus,

                      processingFee,
                      networkFee,
                      fee,
                      feeUnit,
                      userAddress,
                      currentTransactionType,
                      fiatAccountData,
                      payoutStarted,
                      payoutId,
                      direction,
                      enterpriseWithdrawalId,
                      paymentStatus,
                      txHash,
                      txLink,
                      addressData,
                    },
                    i
                  ) => {
                    const response = {
                      _id,
                      createdAt,
                      partnerId,
                      identification,
                      fiatType,
                      fiatAmount,
                      cryptoVolume,
                      cryptoType,
                      reference,
                      transferStatus,
                      processingFee,
                      networkFee,
                      fee,
                      feeUnit,
                      userAddress,
                      currentTransactionType,
                      fiatAccountData,
                      addressData,
                      txHash,
                      txLink,
                    };

                    return (
                      <Tr
                        key={i}
                        onDoubleClick={() => handleUpdate(response)}
                      >
                        <Td
                          cursor='pointer'
                          onClick={() => handleUpdate(response)}
                        >
                          {formatDateToUTC(createdAt)}
                        </Td>

                        <Td
                          onClick={() => {
                            setPage(1);
                            setStatus(transferStatus);
                            setStorage(transferStatus);
                          }}
                          cursor='pointer'
                        >
                          <TableDataStatus status={transferStatus} />
                        </Td>

                        <Td>{identification?.id}</Td>

                        <Td textTransform='uppercase'>
                          {fiatType} {Number(fiatAmount).toLocaleString()}
                        </Td>

                        <Td textTransform='uppercase'>
                          {`N.F ${networkFee} | P.F ${processingFee}`} {cryptoType}
                        </Td>

                        <Td>
                          <Flex
                            gridGap='16px'
                            alignItems='center'
                          >
                            {direction === 'out' &&
                              transferStatus === 'TransferReceivedCryptoFunds' &&
                              !payoutStarted &&
                              !payoutId && (
                                <ProcessPayout
                                  _id={_id}
                                  modalData={response}
                                  widget
                                />
                              )}
                            <ActionButton onClick={() => handleUpdate(response)}>Update</ActionButton>

                            {/* {direction === 'in' && paymentStatus === 'PaymentConfirmed' && enterpriseWithdrawalId
                              ? 'Payout processing'
                              : 'Payout pending'}

                            {payoutId &&
                            payoutStarted === true &&
                            transferStatus !== 'TransferComplete' &&
                            'Auto Processing'
                              ? ''
                              : ''} */}

                            {payoutId && payoutStarted === true && transferStatus !== 'TransferComplete'
                              ? 'Auto Processing'
                              : direction === 'in' && paymentStatus === 'PaymentConfirmed' && enterpriseWithdrawalId
                              ? 'Payout processing'
                              : transferStatus === 'TransferComplete'
                              ? ''
                              : 'Payout pending'}
                          </Flex>
                        </Td>

                        <Td>
                          <FlexBox
                            title='Acct. Name'
                            value={fiatAccountData?.accountName}
                          />

                          <FlexBox
                            title='Acct. Number'
                            value={fiatAccountData?.accountNumber}
                          />

                          {fiatAccountData?.actualBankCode && (
                            <FlexBox
                              title='Bank Code'
                              value={fiatAccountData?.actualBankCode}
                            />
                          )}

                          <FlexBox
                            title='Account Type'
                            value={fiatAccountData?.accountType}
                          />

                          <FlexBox
                            title='Bank Network Code'
                            value={fiatAccountData?.networkOrBankCode}
                          />
                        </Td>

                        <Td>{partnerId}</Td>

                        <Td
                          display='flex'
                          alignItems='center'
                          gap='4px'
                          cursor='pointer'
                        >
                          <span onClick={() => handleUpdate(response)}>{_id}</span>
                          {value === _id && hasCopied ? <CheckIcon /> : <CopyIcon onClick={() => onCopy(_id)} />}{' '}
                        </Td>

                        <Td>{reference}</Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </ScrollRightContainer>

        {isOpen && (
          <UpdateTransferStatusModal
            isOpen={isOpen}
            modalData={modalData}
            onClose={onClose}
          />
        )}

        <PaginationWrapper
          setCurrentPage={setCurrentPage}
          totalDataCount={isLoading ? 0 : data?.totalCount}
          currentPage={currentPage}
          setPage={setPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          size={size}
          offset={offset}
          pages={pages}
        />
      </>
    </Box>
  );
};

export default IntegrationsWithdrawal;

function FlexBox({ title, value }) {
  return (
    <Flex
      gap='12px'
      alignItems='center'
    >
      <Text fontWeight='500'>{title}:</Text>

      <Text>{value}</Text>
    </Flex>
  );
}
