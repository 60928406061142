import { useState } from 'react';
import { CSVDownload } from 'react-csv';
import { Box } from '@chakra-ui/react';
import { Button } from 'components';
import { get } from 'utils/axiosLib';
import { getHeaders } from 'utils';
import { BASE_URL } from 'constant';

const ExportUsersFilterCSV = ({ time, lastEvent }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const headers = [
    { label: 'Firstname', key: 'firstName' },
    { label: 'Lastname', key: 'lastName' },
    { label: 'Username', key: 'username' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Country', key: 'country' },
  ];

  const handleExport = async () => {
    setLoading(true);

    try {
      const response = await get(
        `${BASE_URL}/admin/users?sortBy=createdAt:desc&${time}&${lastEvent}`,
        getHeaders()
      );

      setData(response.data.data.users);
      if (response) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box>
      {data.length > 0 && loading === false ? (
        <CSVDownload
          filename="bitmama-users.csv"
          data={data}
          headers={headers}
        />
      ) : (
        ''
      )}

      <Button
        minH="42px"
        onClick={handleExport}
        disabled={time !== 'custom' && time.length > 3 ? false : true}
        isLoading={loading}
      >
        Export
      </Button>
    </Box>
  );
};

export default ExportUsersFilterCSV;
