import { useState } from 'react';
import { Box, Center, Flex, Text, Textarea, useToast } from '@chakra-ui/react';
import { Button, ConfigCheckbox } from 'components';
import { BASE_URL } from 'constant';
import { put } from 'utils/axiosLib';

const DisableKycUpload = ({ data, headers }) => {
  const toast = useToast();
  const URL = `${BASE_URL}/admin/toggleKYC`;

  const [disabled, setDisabled] = useState(data?.kycConfig?.disabled);

  const [reason, setReason] = useState(data?.kycConfig?.reason);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await put(
        URL,
        {
          disabled: e.target.checked === false ? e.target.checked : disabled,
          reason,
          email: data.email,
        },
        headers
      );

      if (response.data) {
        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleChange = async (e) => {
    setDisabled(e.target.checked);

    if (e.target.checked === false) {
      await handleSubmit(e);
    }
  };

  return (
    <Box
      borderBottom='0.5px solid #A1A3A2'
      padding='12px 14px 16px'
    >
      <Flex
        alignItems='center'
        gridGap='12px'
        mb='16px'
      >
        <Text fontWeight='600'>Disable KYC Upload</Text>

        <ConfigCheckbox
          defaultChecked={disabled}
          onChange={handleChange}
        />
      </Flex>

      {disabled && (
        <Box>
          <form onSubmit={handleSubmit}>
            <Textarea
              defaultValue={data?.kycConfig?.reason}
              mb='16px'
              fontSize='15px'
              name='description'
              id='description'
              bg='#f2f2f2'
              isRequired
              width='100%'
              height='120px'
              padding='14px'
              borderWidth='1px'
              borderColor='#A1A3A2'
              _hover={{
                bg: '#f2f2f2',
                borderColor: '#0D4740',
              }}
              _focus={{
                borderColor: '#0D4740',
              }}
              onChange={(e) => setReason(e.target.value)}
            />

            <Center>
              <Button
                type='submit'
                minH='48px'
                isLoading={loading}
              >
                Update
              </Button>
            </Center>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default DisableKycUpload;
