import { usePagination } from '@ajna/pagination';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Flex, useDisclosure } from '@chakra-ui/react';
import {
  FilterSelect,
  IntegrationKYCModal,
  IntegrationsProcessKYCModal,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  SearchInput,
  Select,
  TableDataStatus,
} from 'components';
import { useDebounce } from 'hooks';
import { useState } from 'react';
import { useIntegrationKycQuery } from 'store/services/integrations.service';
import { formatDateToUTC } from 'utils';

const IntegrationsKYC = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [documentStatus, setDocumentStatus] = useState('pending');
  const [searchString, setSearchString] = useState('');

  const { data, isLoading, isFetching } = useIntegrationKycQuery({
    page,
    size,
    status: documentStatus,
    queries: searchString ? `search=${searchString}` : '',
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: size,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const handleStatus = (e) => {
    setPage(1);
    setDocumentStatus(e.target.value);
  };

  const [selectedStatus, setSelectedStatus] = useState('');
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [modalData, setModalData] = useState({});

  const handleProcess = (e, data) => {
    onOpen();
    setSelectedStatus(e.target.value);
    setModalData(data);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  return (
    <Box>
      <Flex
        mb='16px'
        gridGap='12px'
      >
        <FilterSelect
          width='88px'
          onChange={handleSize}
        >
          <option value={10}>Size</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </FilterSelect>

        <FilterSelect
          onChange={handleStatus}
          defaultValue={documentStatus}
          minW='120px'
        >
          <option value=''>Status</option>
          <option value='pending'>Pending</option>
          <option value='approved'>Approved</option>
          <option value='rejected'>Rejected</option>
          <option value='expired'>Expired</option>
        </FilterSelect>

        <SearchInput
          placeholder='Search'
          minH='48px'
          width={{ base: '100%', tb: '319px' }}
          onChange={debouncedChangeHandler}
        />
      </Flex>
      <>
        {isFetching && <Loader />}
        <ScrollRightContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Fullname</Th>
                <Th>Email</Th>
                <Th>Country</Th>
                <Th>Partner ID</Th>
                <Th>Category</Th>
                <Th>Status</Th>
                <Th>KYC Schema</Th>
                <Th>Date/Time</Th>
                <Th>Action</Th>
                <Th>Approval/Rejection (Date/Time)</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data &&
                data.data.map(
                  ({
                    _id,
                    firstName,
                    lastName,
                    country,
                    createdAt,
                    partnerId,
                    identification,
                    documentStatus,
                    documentCategory,
                    selfieDocument,
                    documentType,
                    documentUrl,
                    kycSchema,
                    expiresOn,
                    processedOn,
                    reason,
                  }) => {
                    const fullName = `${firstName} ${lastName}`;

                    const modalData = {
                      _id,
                      firstName,
                      lastName,
                      country,
                      createdAt,
                      partnerId,
                      identification,
                      documentStatus,
                      documentCategory,
                      kycSchema,
                      selfieDocument,
                      documentType,
                      documentUrl,
                      expiresOn,
                      reason,
                    };

                    return (
                      <Tr key={_id}>
                        <Td textTransform='capitalize'>{fullName}</Td>

                        <Td>{identification?.id}</Td>

                        <Td textTransform='uppercase'>{country}</Td>

                        <Td>{partnerId}</Td>

                        <Td>{documentCategory}</Td>

                        <Td>
                          <TableDataStatus status={documentStatus} />
                        </Td>

                        <Td>{kycSchema}</Td>

                        <Td>{formatDateToUTC(createdAt)}</Td>

                        <Td>
                          <Flex
                            alignItems='center'
                            gridGap='8px'
                          >
                            <IntegrationKYCModal modalData={modalData} />

                            <Select
                              width='100px'
                              onChange={(e) => handleProcess(e, modalData)}
                            >
                              <option value='approved'>Process</option>
                              <option value='approved'>Approve</option>
                              <option value='pending'>Pending</option>
                              <option value='expired'>Expire</option>
                              <option value='rejected'>Reject</option>
                            </Select>
                          </Flex>
                        </Td>

                        <Td>{processedOn ? formatDateToUTC(processedOn) : 'N/A'}</Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </ScrollRightContainer>

        <IntegrationsProcessKYCModal
          modalData={modalData}
          isOpen={isOpen}
          onClose={onClose}
          kycStatus={selectedStatus}
        />

        <PaginationWrapper
          setCurrentPage={setCurrentPage}
          totalDataCount={isLoading ? 0 : data?.totalCount}
          currentPage={currentPage}
          setPage={setPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          size={size}
          offset={offset}
          pages={pages}
        />
      </>
    </Box>
  );
};

export default IntegrationsKYC;
