import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Flex, Box, useToast, Center } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Button, CloseModal, Input, TableDataStatus } from 'components';
import { formatDateToUTC, getHeaders, wrapBaseApi } from 'utils';
import { put } from 'utils/axiosLib';
import { fetchPhysicalCardRequest } from 'store/slices/card.slice';
import { BASE_URL } from 'constant';

const PhysicalCardRequestModal = ({ isOpen, onClose, data, actionStatus, currentPage }) => {
  const {
    _id,
    addressData,
    cardDetails,
    chargeWallet,
    createdAt,
    fee,

    status,
    userDetails,
  } = data;
  const toast = useToast();

  const trimCharacter = (char) => {
    if (char.includes('lled')) {
      return char.slice(0, -3);
    }

    return char.slice(0, -1);
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const headers = getHeaders();

  const request = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/cards/p/${_id}/process-request`);

    try {
      const response = await put(
        URL,
        {
          reason: actionStatus === 'declined' || actionStatus === 'blocked' ? reason : undefined,
          action: actionStatus,
        },
        headers
      );

      if (response.data) {
        setLoading(false);
        dispatch(
          fetchPhysicalCardRequest({
            page: currentPage,
            status: '',
            search: '',
          })
        );
        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      key={_id}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        bg='#fff'
        maxWidth='580px'
        padding='32px 40px'
      >
        <ModalBody
          padding='0px'
          margin='0 0'
          fontSize='14px'
        >
          <Text
            fontSize='24px'
            fontWeight='semibold'
            textAlign='center'
          >
            Details
          </Text>

          <Text
            fontSize='16px'
            fontWeight='500'
            textAlign={'center'}
            mb='1rem'
          >
            Are you sure you want to {trimCharacter(actionStatus)} this request
          </Text>
          <Box>
            <Flex
              fontWeight='medium'
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Full name</Text>

              <Text fontWeight='medium'>{userDetails?.fullName}</Text>
            </Flex>

            <Flex
              mb='24px'
              justifyContent='space-between'
            >
              <Text>Email</Text>

              <Text
                fontWeight='medium'
                textTransform='lowercase'
              >
                {userDetails?.email}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Card Name</Text>

              <Text fontWeight='medium'>
                {cardDetails?.firstName} {cardDetails?.lastName} {cardDetails?.otherNames}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Address</Text>

              <Text fontWeight='medium'>
                {addressData?.streetNo}, {addressData?.streetAddress} {addressData?.state}, {addressData?.country}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Postal code</Text>

              <Text fontWeight='medium'>{addressData?.postalCode}</Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Zip code</Text>

              <Text fontWeight='medium'>{addressData?.zipCode}</Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Status</Text>

              <Box>
                <TableDataStatus status={status} />
              </Box>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Fee</Text>

              <Text
                fontWeight='medium'
                textTransform='uppercase'
              >
                {fee} {chargeWallet}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Time and Date</Text>

              <Text fontWeight='medium'>{formatDateToUTC(createdAt)}</Text>
            </Flex>

            {(actionStatus === 'declined' || actionStatus === 'blocked') && (
              <Center>
                <Input
                  width='450px'
                  onChange={(e) => setReason(e.target.value)}
                />
              </Center>
            )}

            <Flex
              justifyContent='center'
              mt='2rem'
              flexDir='column'
            >
              <Button
                // minW="120px"
                width='120px'
                margin='auto'
                onClick={request}
                isLoading={loading}
                disabled={actionStatus === 'declined' || actionStatus === 'blocked' ? reason.length < 3 : loading}
              >
                {actionStatus === 'blocked' ? 'Block' : trimCharacter(actionStatus)}
              </Button>

              <CloseModal onClick={onClose} />
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PhysicalCardRequestModal;
