import { Center, Text } from '@chakra-ui/react';

const PartnerStatsCard = ({ icon, count, color, title }) => {
  return (
    <Center
      flexDir="column"
      maxWidth="303px"
      width="100%"
      boxShadow="0px 2px 8px rgba(229, 229, 229, 0.47)"
      borderRadius="10px"
      padding="22px 0"
    >
      <Center
        boxShadow="0px 0.79px 3.15px rgba(229, 229, 229, 0.47)"
        borderRadius="10px"
        width="39px"
        height="35px"
      >
        {icon}
      </Center>

      <Text margin="3px 0 0" color="#797A7A" fontSize="14px">
        {title}
      </Text>

      <Text fontSize="28px" color={color} fontWeight="700">
        {count}
      </Text>
    </Center>
  );
};

export default PartnerStatsCard;
