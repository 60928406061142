import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ActionButton, Button } from 'components';
import { useProcessPayoutMutation } from 'store/services/cico.service';
import { useProcessWidgetPayoutMutation } from 'store/services/integrations.service';

const ProcessPayout = ({ _id, modalData, widget }) => {
  const { cryptoVolume, cryptoType, fiatAmount, fiatType } = modalData;
  const toast = useToast();

  const [processPayout, { isLoading }] = useProcessPayoutMutation();

  const [processWidgetPayout, { isLoading: isWidgetLoading }] = useProcessWidgetPayoutMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = async () => {
    try {
      const response = widget
        ? await processWidgetPayout({
            id: _id,
          })
        : await processPayout({
            id: _id,
          });

      console.log(response, 'RESPONSE');
      if (response?.data) {
        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: 'Process payout',
          status: 'success',
          duration: 18000,
          isClosable: true,
        });
      }

      if (response?.error) {
        toast({
          position: 'bottom-right',
          title: 'Error',
          status: 'error',
          duration: 18000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error, 'ERROR');
    }
  };

  return (
    <>
      <ActionButton
        color='#fff'
        bg='#6FBE45'
        onClick={onOpen}
      >
        Process Payout
      </ActionButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='450px'
          padding='8px 0 32px'
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
          >
            Process Payout
          </ModalHeader>

          <ModalBody bg='#fff'>
            <Box textAlign='center'>
              Are you sure you want to process this {widget ? 'transaction' : 'transfer'} with
              <Text>
                fiat amount{' '}
                <Text
                  as='span'
                  fontWeight='600'
                  textTransform='uppercase'
                >
                  {fiatType} {Number(fiatAmount).toLocaleString()}
                </Text>{' '}
                and crypto volume{' '}
                <Text
                  as='span'
                  fontWeight='600'
                >
                  {cryptoVolume} {cryptoType}
                </Text>{' '}
                ?
              </Text>
            </Box>

            <Center mt='42px'>
              <Button
                onClick={handleSubmit}
                minH='55px'
                isLoading={isLoading || isWidgetLoading}
                disabled={isLoading || isWidgetLoading}
              >
                Yes
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ProcessPayout;
