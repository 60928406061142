import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { KeyShortcutsContent, PageTitle } from 'components';
import { useLocationSearch } from 'hooks';
import { ToolsTabArray } from 'LinkArray/tabArray';
import ToolsFileUpload from './ToolsFileUpload';
import { Link } from 'react-router-dom';

const Tools = () => {
  const search = useLocationSearch();
  return (
    <Box>
      <PageTitle title='Tools' />

      <Box
        mt='24px'
        bg='#FCFDFD'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
        borderRadius='10px'
        minHeight='700px'
        padding={{ base: '15px', md: '15px 25px' }}
      >
        <Flex
          gap='48px'
          mb='48px'
        >
          {ToolsTabArray.map(({ title, id, search: _search, icon, isDisabled }) => {
            return (
              <ToolsCard
                key={id}
                title={title}
                search={_search}
                isActive={search === _search}
                icon={icon}
                isDisabled={isDisabled}
              />
            );
          })}
        </Flex>

        <Box
          maxWidth='550px'
          width='100%'
        >
          {search === '' && <ToolsFileUpload />}
          {search === '?shortcuts' && <KeyShortcutsContent />}
        </Box>
      </Box>
    </Box>
  );
};

export default Tools;

const ToolsCard = ({ title, search, isActive, icon, isDisabled }) => {
  return (
    <Box cursor={isDisabled ? 'not-allowed' : 'pointer'}>
      <Link
        to={`/dashboard/tools/${search}`}
        style={{ pointerEvents: isDisabled ? 'none' : 'initial' }}
      >
        <Center
          width='100px'
          height='90px'
          boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
          borderRadius='20px'
          border={isActive ? '1px solid #0D4740' : '1px solid #E0E0E0'}
          mb='4px'
          bg='#1a1b1b'
          opacity={isDisabled ? '0.7' : '1'}
          style={{ zoom: isActive ? '1.05' : '1', transition: 'all 0.3s ease-out' }}
        >
          {icon}
        </Center>

        <Text
          textAlign='center'
          fontWeight={isActive ? '500' : '400'}
        >
          {title}
        </Text>
      </Link>
    </Box>
  );
};
