import { BASE_URL } from 'constant';
import { post } from 'utils/axiosLib';
import { Box, Text, Center, useToast } from '@chakra-ui/react';
import { FileUpload, Button } from 'components';
import { useEffect, useState } from 'react';
import { uploadFile, getHeaders } from 'utils';

const StepThree = ({ setState, state, email, kycLevel, onClose, disableSubmit, nextStep, userId }) => {
  const toast = useToast();
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [docUrl, setDocUrl] = useState('');

  useEffect(() => {
    async function upload() {
      if (selectedFile) {
        setLoading(true);
        const response = await uploadFile(selectedFile, selectedFile?.name);

        if (response.location) {
          setLoading(false);

          setDocUrl(response.location);
        }
      }
    }
    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, setState]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      console.log(state, 'ALL STATE');
      const response = await post(
        `${BASE_URL}/admin/uploadKYCForUser`,
        {
          email,
          kycLevel,
          userId,
          formData: {
            ...state,
            docLink: state.category === 'primary' ? docUrl : undefined,
            docType: state.category === 'primary' ? state.docType : undefined,

            supportingDocLink: state.category === 'secondary' ? docUrl : undefined,
            supportingDocType: state.category === 'secondary' ? state.docType : undefined,

            ninNumber: state.ninNumber !== '' ? state.ninNumber : undefined,
            idNumber: state.idNumber !== '' ? state.idNumber : undefined,
          },
        },
        getHeaders()
      );

      if (response.data) {
        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 18000,
          isClosable: true,
        });

        onClose();
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.customError,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Text mb='16px'>ID Card Upload</Text>

      <FileUpload
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        defaultPreview={state.docLink}
      />

      <Center
        width='100%'
        justify='flex-end'
        flexDir='column'
        mt='32px'
      >
        <Button
          minH='58px'
          width='320px'
          cursor='pointer'
          type='submit'
          onClick={disableSubmit ? nextStep : handleSubmit}
          disabled={!docUrl}
          isLoading={loading}
        >
          {disableSubmit ? 'Save and Continue' : 'Submit'}
        </Button>
      </Center>
    </Box>
  );
};

export default StepThree;
