import { usePagination } from '@ajna/pagination';
import { Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import {
  ActionButton,
  CurrencyFormat,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
  UpdateMerchantNameModal,
} from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAllMerchantsQuery } from 'store/services/merchants.service';
import { formatDateToUTC } from 'utils';

const Merchants = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useAllMerchantsQuery({
    page,
    size: 10,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [modalData, setModalData] = useState();

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleOpen = (values) => {
    onOpen();
    setModalData(values);
  };
  const navigate = useNavigate();
  const handleRoute = (id) => {
    navigate(`/dashboard/users/merchants/${id}`);
  };

  return (
    <>
      {isLoading && <Loader />}

      <ScrollRightContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Full Name</Th>
              <Th>Email</Th>
              <Th>Account Name</Th>
              <Th>Account Number</Th>
              <Th>Bank Name</Th>
              <Th>Balance</Th>
              <Th>Last Deposit</Th>
              <Th>Last Withdrawal</Th>
              <Th>Status</Th>
              <Th>Phone</Th>
              <Th>Date</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.merchants.map(
                ({
                  _id,
                  email,
                  fullName,
                  firstName,
                  lastName,
                  status,
                  balance,
                  phone,
                  bankDetails,
                  lastDeposit,
                  lastWithdrawal,
                  createdAt,
                  assignable,
                }) => {
                  const modalValue = {
                    _id,
                    firstName,
                    lastName,
                    bankDetails,
                    phone,
                    email,
                    assignable,
                    merchantAccountId: String(bankDetails?._id),
                  };

                  return (
                    <Tr key={_id}>
                      <Td
                        textTransform='capitalize'
                        onClick={() => handleRoute(_id)}
                        cursor='pointer'
                      >
                        {fullName}
                      </Td>

                      <Td
                        cursor='pointer'
                        onClick={() => handleRoute(_id)}
                      >
                        {email}
                      </Td>

                      <Td
                        cursor='pointer'
                        onClick={() => handleRoute(_id)}
                      >
                        {bankDetails?.bankAccountName}
                      </Td>

                      <Td
                        cursor='pointer'
                        onClick={() => handleRoute(_id)}
                      >
                        {bankDetails?.bankAccountNumber}
                      </Td>

                      <Td
                        cursor='pointer'
                        onClick={() => handleRoute(_id)}
                      >
                        {bankDetails?.bankName}
                      </Td>

                      <Td>
                        <CurrencyFormat currency={bankDetails?.currency} />{' '}
                        {Number(balance?.$numberDecimal).toLocaleString()}
                      </Td>

                      <Td>
                        <CurrencyFormat currency={bankDetails?.currency} />{' '}
                        {Number(lastDeposit?.$numberDecimal).toLocaleString()}
                      </Td>

                      <Td>
                        <CurrencyFormat currency={bankDetails?.currency} />{' '}
                        {Number(lastWithdrawal?.$numberDecimal).toLocaleString()}
                      </Td>

                      <Td textTransform='capitalize'>{status}</Td>

                      <Td>{phone}</Td>

                      <Td>{formatDateToUTC(createdAt)}</Td>

                      <Td>
                        <ActionButton onClick={() => handleOpen(modalValue)}>Update</ActionButton>
                      </Td>
                    </Tr>
                  );
                }
              )}
          </Tbody>
        </Table>

        {modalData && (
          <UpdateMerchantNameModal
            data={modalData}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={isLoading ? 0 : data?.totalCount}
        currentPage={currentPage}
        setPage={setPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={10}
        offset={offset}
        pages={pages}
      />
    </>
  );
};

export default Merchants;
