import { nanoid } from '@reduxjs/toolkit';
import { constant } from 'utils';
import {
  Login,
  ForgotPassword,
  NotFound,
  Home,
  Account,
  UserWrapper,
  BCPL,
  BuySell,
  Cards,
  DataAirtime,
  Earn,
  P2P,
  Partners,
  QrCode,
  Verification,
  Wallet,
  ResetCode,
  UserDetails,
  VerificationDetails,
  BuyListing,
  SellListing,
  P2PAllTrades,
  P2PTradeDetails,
  P2PDisputeDetails,
  P2PAllDispute,
  EarnDetails,
  PartnersEarningsReferred,
  PartnersDetails,
  AddAdmin,
  EditAdmin,
  CICO,
  CicoKYCDetails,
  Disbursements,
  Settings,
  Greenbox,
  GreenboxDetails,
  MerchantTransaction,
  Wishlist,
  CreateWishlist,
  EntryTagDetails,
  Gaming,
  GamingDetails,
  CreateGame,
  CardDetails,
  Tools,
} from 'pages';

const routePath = [
  {
    protected: false,
    path: '/',
    component: <Login />,
    id: nanoid(),
  },
  {
    protected: false,
    path: '/login',
    component: <Login />,
    id: nanoid(),
  },
  {
    protected: false,
    path: constant.PAGES.FORGOT_PASSWORD,
    component: <ForgotPassword />,
    id: nanoid(),
  },
  {
    protected: false,
    path: constant.PAGES.RESET_CODE,
    component: <ResetCode />,
    id: nanoid(),
  },
  {
    protected: false,
    path: '*',
    component: <NotFound />,
    id: nanoid(),
  },
  {
    protected: true,
    path: constant.PAGES.HOME,
    component: <Home />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/account',
    component: <Account />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/account/edit-admin/:id',
    component: <EditAdmin />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/account/add-admin',
    component: <AddAdmin />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/bcpl',
    component: <BCPL />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/buy-sell',
    component: <BuySell />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/cards',
    component: <Cards />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/cards/:id',
    component: <CardDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/cico',
    component: <CICO />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/cico/kyc/:id',
    component: <CicoKYCDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/data-airtime',
    component: <DataAirtime />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/earn',
    component: <Earn />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/earn/:id',
    component: <EarnDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/p2p',
    component: <P2P />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/p2p/buy-listing',
    component: <BuyListing />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/p2p/sell-listing',
    component: <SellListing />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/p2p/trades',
    component: <P2PAllTrades />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/p2p/trades/:id',
    component: <P2PTradeDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/p2p/dispute/:id',
    component: <P2PDisputeDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/p2p/all-disputes',
    component: <P2PAllDispute />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/partners',
    component: <Partners />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/partners/earnings-referred',
    component: <PartnersEarningsReferred />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/partners/:id',
    component: <PartnersDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/qrcode',
    component: <QrCode />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/users',
    component: <UserWrapper />,
    // component: <AllUsers />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/users/:id',
    component: <UserDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/verification',
    component: <Verification />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/verification/:id',
    component: <VerificationDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/inflow-outflow',
    // path: '/dashboard/inflow-outflow',
    component: <Wallet />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/settings',
    component: <Settings />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/disbursements',
    component: <Disbursements />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/greenbox',
    component: <Greenbox />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/greenbox/:id',
    component: <GreenboxDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/users/merchants/:id',
    component: <MerchantTransaction />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/wishlist',
    component: <Wishlist />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/wishlist/create',
    component: <CreateWishlist />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/wishlist/tag',
    component: <EntryTagDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/gaming',
    component: <Gaming />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/gaming/:id',
    component: <GamingDetails />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/gaming/create-game',
    component: <CreateGame />,
    id: nanoid(),
  },
  {
    protected: true,
    path: '/dashboard/tools',
    component: <Tools />,
    id: nanoid(),
  },
];

export default routePath;
