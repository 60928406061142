import { useEffect, useState } from "react";

export function useCopy() {
  const [value, setValue] = useState('');
  const [hasCopied, setHasCopied] = useState(false);

  function onCopy(address) {
    setValue(address);
    setHasCopied(true);
    navigator.clipboard.writeText(address);
  }

  useEffect(() => {
    setTimeout(() => {
      setHasCopied(false);
    }, 1000);

    return () => {
      clearTimeout(1000);
    };
  }, [hasCopied]);

  return {
    value,
    hasCopied,
    onCopy,
  };
};
