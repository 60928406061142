import { Button as ChakraButton } from '@chakra-ui/react';

const ActionButton = ({ children, disabled, isLoading, type, ...rest }) => {
  return (
    <ChakraButton
      type={type}
      minW='102px'
      minH='32px'
      height='100%'
      bg='transparent'
      border='1px solid #6FBE45'
      color='#303131'
      fontWeight='500'
      borderRadius='20px'
      isLoading={isLoading}
      fontFamily='PolySans'
      textTransform='capitalize'
      transition='all 300ms ease-in-out'
      fontSize='10px'
      lineHeight='12px'
      disabled={disabled}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      _hover={{
        cursor: disabled ? 'not-allowed !important' : 'pointer',
        transition: 'all 300ms ease-in-out',
        bg: 'brand.primary',
        color: '#fff',
        border: '1px solid #0D4740',
      }}
      _focus={{
        transition: 'all 300ms ease-in-out',
        border: '1px solid #0D4740',
        bg: '#0D4740',
        color: '#fff',
      }}
      _active={{
        transition: 'all 300ms ease-in-out',
        border: '1px solid #0D4740',
        bg: '#0D4740',
        color: '#fff',
      }}
      _disabled={{
        opacity: 0.5,
        pointerEvents: 'none',
        cursor: 'not-allowed',
      }}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export default ActionButton;
