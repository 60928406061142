import { Modal, ModalOverlay, ModalContent, Text, Center, useDisclosure, useToast } from '@chakra-ui/react';
import { ActionButton, Button } from 'components';
import { useEndCampaignMutation } from 'store/services/wishlist.service';

const EndCampaignModal = ({ id }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [_endCampaign, { isLoading }] = useEndCampaignMutation();

  const handleSubmit = async () => {
    try {
      const response = await _endCampaign(id).unwrap();

      onClose();

      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response?.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      console.log(response, 'RESPONSE');
    } catch (error) {
      console.log(error, 'the error');
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.data?.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <ActionButton
        // fontSize='12px'
        onClick={onOpen}
      >
        End Campaign
      </ActionButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='21px'
          padding={{ base: '24px', md: '40px' }}
          maxWidth='420px'
          width='100%'
          // minH='120px'
        >
          <Center
            padding='0'
            flexDir='column'
            minH='120px'
          >
            <Text
              textAlign='center'
              mb='24px'
              fontSize='18px'
            >
              Are you sure you want to end this campaign?{' '}
            </Text>

            <Center>
              <Button
                width='100%'
                isLoading={isLoading}
                onClick={handleSubmit}
                disabled={isLoading}
                minW='180px'
                w='initial'
                minH='48px'
              >
                Yes
              </Button>
            </Center>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EndCampaignModal;
