import { useRef, useState } from 'react';
import { Box, Center, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import Recaptcha from 'react-google-recaptcha';
import { Button, Input, AuthImage } from 'components';
import { post } from 'utils/axiosLib';
import { constant, validateEmail } from 'utils';
import { BitcoinAuthSVG, BitmamaAdminLogoSVG, RightArrowSVG } from 'assets';
import { BASE_URL } from 'constant';

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: '',
  });

  const recaptchaInstanceRef = useRef();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email } = state;

    const headers = {
      authorization: null,
      'Content-Type': 'application/json',
    };

    if (navigator.onLine === true) {
      setLoading(true);
      try {
        const captcha = await recaptchaInstanceRef.current?.executeAsync();

        const URL = `${BASE_URL}/auth/forgotPassword?humanKey=${captcha}`;
        const response = await post(URL, { email, ...headers });

        setLoading(false);
        if (response.status === 'success') {
          toast({
            position: 'bottom-right',
            title: 'Success',
            description: response.data.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          navigate(`${constant.PAGES.RESET_CODE}?email=${email}`, {
            replace: true,
          });
        }

        if (response.status === 202) {
          setLoading(false);

          toast({
            position: 'bottom-right',
            title: 'Success',
            description: response.data.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          navigate(`${constant.PAGES.RESET_CODE}?email=${email}`, {
            replace: true,
          });
        }
      } catch (error) {
        console.log(error, 'THE ERROR');
        setLoading(false);

        await recaptchaInstanceRef.current?.reset();
        toast({
          position: 'bottom-right',
          title: 'Oops',
          description: error.error,
          status: 'error',
          duration: 18000,
          isClosable: true,
        });
      }
    } else {
      toast({
        position: 'bottom-right',
        title: 'Network Error!',
        description: 'Please check your network connection',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      mt={{ sm: '-2rem' }}
      gridGap='2rem'
      height='100vh'
    >
      <AuthImage />

      <Box
        maxWidth='523px'
        margin='0 auto'
        width='100%'
        pt={{ base: '120px', md: '185px' }}
        px={{ base: '16px', md: '0' }}
      >
        <Link to='/login'>
          <Box>
            <BitmamaAdminLogoSVG />
          </Box>
        </Link>

        <Flex flexDirection='column'>
          <Flex
            gridGap='2px'
            margin='50px 0 20px'
            alignItems='center'
          >
            <Heading
              fontWeight='600'
              fontSize={{ base: '1.5rem', md: '2rem' }}
            >
              Forgot Password
            </Heading>

            <Box>
              <BitcoinAuthSVG />
            </Box>
          </Flex>

          <Text
            mb='66px'
            letterSpacing='-0.5px'
            color='#797A7A'
            fontSize='18px'
          >
            Enter your registered email to reset password
          </Text>

          <form onSubmit={handleSubmit}>
            <Input
              label='Email Address'
              name='email'
              placeholder='Enter Email'
              onChange={handleChange}
            />

            <Center mt='54px'>
              <Button
                isLoading={loading}
                disabled={loading || !validateEmail(state.email)}
                maxW='399px'
                width='100%'
                type='submit'
                fontWeight='700'
              >
                <Flex gridGap='10px'>
                  Reset <RightArrowSVG />
                </Flex>
              </Button>
            </Center>

            <Text
              textAlign='center'
              mt='24px'
              fontWeight='500'
              onClick={() => navigate('/login')}
              cursor='pointer'
              display={{ sm: 'block', md: 'none' }}
            >
              Back
            </Text>
          </form>
        </Flex>

        <Recaptcha
          ref={recaptchaInstanceRef}
          sitekey={process.env.REACT_APP_RECAPTCHA}
          size='invisible'
        />
      </Box>
    </Flex>
  );
};

export default Login;
