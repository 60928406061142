import { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { BuyListingSVG, SellListingSVG } from 'assets';
import { Loader, P2PCardListing } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchP2PStat } from 'store/slices/p2p.slice';

const P2PListingWrapper = () => {
  const dispatch = useDispatch();

  const { data, status } = useSelector((state) => state.p2p.stat);

  const { buys, sells } = data;

  useEffect(() => {
    if (data.buys === 0) {
      dispatch(fetchP2PStat());
    }
  }, [dispatch, data]);
  return (
    <Flex
      gridGap='6px'
      flexDir={{ sm: 'row', md: 'column' }}
      flexWrap={{ sm: 'wrap', md: 'initial' }}
      justifyContent={{ sm: 'center', md: 'initial' }}
    >
      <P2PCardListing
        count={status === 'fulfilled' ? Number(sells).toLocaleString() : <Loader />}
        type='buy'
        icon={<BuyListingSVG />}
      />

      <P2PCardListing
        count={status === 'fulfilled' ? Number(buys).toLocaleString() : <Loader />}
        type='sell'
        icon={<SellListingSVG />}
      />
    </Flex>
  );
};

export default P2PListingWrapper;
