import { Flex, Text } from '@chakra-ui/react';

const TradeRefBox = ({ title, description, descTextTransform, color }) => {
  return (
    <Flex mb="30px">
      <Text maxWidth="335px" width="100%">
        {title}
      </Text>

      <Text
        fontWeight="500"
        color={color ? color : 'brand.black'}
        textTransform={descTextTransform}
      >
        {description}
      </Text>
    </Flex>
  );
};

export default TradeRefBox;
