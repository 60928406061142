import { CircularProgress } from '@chakra-ui/react';

const Loader = ({ ...props }) => {
  return (
    <CircularProgress
      size="16px"
      isIndeterminate
      color="green.800"
      {...props}
    />
  );
};

export default Loader;
