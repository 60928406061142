import { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PartnersWalletCard, Loader } from 'components';
import TetherPNG from 'assets/png/usdt.png';
import XrpPNG from 'assets/png/xrp.png';
import CeloPNG from 'assets/png/celo.png';
import BtcPNG from 'assets/png/btc.png';
import EthPNG from 'assets/png/eth.png';
import { fetchEarningSummary } from 'store/slices/partners.slice';

const ViewPartnerWalletModal = ({ isOpen, onClose, userId }) => {
  const { data, status, errorMessage } = useSelector(
    (state) => state.partners.earningSummary
  );

  console.log(userId, 'USER ID');

  const dispatch = useDispatch();
  useEffect(() => {
    userId && dispatch(fetchEarningSummary(userId));
  }, [dispatch, userId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="21px"
        padding="50px 50px 58px"
        maxWidth="568px"
        width="100%"
      >
        <ModalHeader fontWeight="600" fontSize="24px" textAlign="center">
          Wallet
        </ModalHeader>

        <Box padding="0">
          {status === 'error' && <p>{errorMessage}</p>}

          {status === 'loading' && <Loader />}

          {data?.combinedEarns?.tbtc && (
            <PartnersWalletCard
              coinSrc={BtcPNG}
              coin="btc"
              amount={Number(data?.combinedEarns?.tbtc).toFixed(8)}
            />
          )}

          {data?.combinedEarns?.btc && (
            <PartnersWalletCard
              coinSrc={BtcPNG}
              coin="btc"
              amount={Number(data?.combinedEarns?.btc).toFixed(8)}
            />
          )}

          {data?.combinedEarns?.celo && (
            <PartnersWalletCard
              coinSrc={CeloPNG}
              coin="celo"
              amount={Number(data?.combinedEarns?.celo).toFixed(4)}
            />
          )}

          {data?.combinedEarns?.teth && (
            <PartnersWalletCard
              coinSrc={EthPNG}
              coin="eth"
              amount={Number(data?.combinedEarns?.teth).toFixed(8)}
            />
          )}
          {data?.combinedEarns?.eth && (
            <PartnersWalletCard
              coinSrc={EthPNG}
              coin="eth"
              amount={Number(data?.combinedEarns?.eth).toFixed(8)}
            />
          )}

          {data?.combinedEarns?.xrp && (
            <PartnersWalletCard
              coinSrc={XrpPNG}
              coin="xrp"
              amount={Number(data?.combinedEarns?.xrp).toFixed(8)}
            />
          )}

          {data?.combinedEarns?.xlm && (
            <PartnersWalletCard
              coinSrc={TetherPNG}
              coin="xlm"
              amount={Number(data?.combinedEarns?.xlm).toFixed(8)}
            />
          )}

          {data?.combinedEarns?.cusd && (
            <PartnersWalletCard
              coinSrc={TetherPNG}
              coin="cusd"
              amount={Number(data?.combinedEarns?.cusd).toFixed(4)}
            />
          )}

          {data?.combinedEarns?.usdt && (
            <PartnersWalletCard
              coinSrc={TetherPNG}
              coin="usdt"
              amount={Number(data?.combinedEarns?.usdt).toFixed(4)}
            />
          )}

          <Box width="100%" margin={'73px auto 0'}>
            <Button width="100%" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default ViewPartnerWalletModal;
