import { Box, Input as ChakraInput } from '@chakra-ui/react';

const Input = ({ width, label, placeholder, onChange, type, name, labelSize, ...props }) => {
  return (
    <Box width={width}>
      {label && (
        <Box
          as='label'
          display='block'
          fontSize={labelSize ? labelSize : '12px'}
          fontWeight='400'
          mb='10px'
          color='#303131'
          htmlFor={name}
        >
          {label}
        </Box>
      )}
      <ChakraInput
        width={width}
        name={name}
        id={name}
        borderRadius='15px'
        errorBorderColor='brand.red'
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        minH='60px'
        bg='#F6F8F7'
        fontSize='18px'
        padding='27px 18px'
        variant='filled'
        color='#303131'
        autoComplete='new-password'
        border='1px solid #F6F8F7'
        _hover={{
          bg: 'rgba(226, 242, 218, 0.3)',
          border: '1px solid #6FBE45',
          transition: 'border 500ms ease-in-out',
        }}
        _active={{
          bg: '#FBFBFB',
          border: '1px solid #6FBE45',
        }}
        _focus={{
          bg: '#FBFBFB',
          border: '1px solid #6FBE45',
        }}
        _placeholder={{
          color: '#A1A3A2',
          fontWeight: '400',
        }}
        // _disabled={{
        //   border: '1px solid #6FBE45',
        // }}
        {...props}
      />
    </Box>
  );
};

export default Input;
