import React, { useState } from 'react';
import { Box, Text, useToast } from '@chakra-ui/react';
import { Button, CurrencyFormat, Input } from 'components';
import { useDispatch } from 'react-redux';
import { fetchWalletDeposit } from 'store/slices/wallet.slice';
import { getHeaders, wrapBaseApi } from 'utils';
import { put } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const UpdateDepositAmount = ({ amount, _id, currency, currentPage, status }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updatedAmount, setUpdatedAmount] = useState(0);
  const [newAmount, setNewAmount] = useState(amount?.$numberDecimal);
  const headers = getHeaders();

  const handleChange = (e) => {
    setNewAmount(e.target.value);
  };

  const [hide, setHide] = useState(true);

  const showInput = () => {
    setHide(!hide);
  };

  const updateAmount = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/deposit/${_id}/update-amount`);

    try {
      const response = await put(
        URL,
        {
          amount: Number(newAmount),
        },
        headers
      );

      if (response.data) {
        setLoading(false);
        setUpdatedAmount(response.data.data?.amount);

        dispatch(
          fetchWalletDeposit({
            page: currentPage,
            status,
            limit: 10,
            depositPaymentType: '',
          })
        );

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error, 'ERROR HERE');
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Box
        fontWeight='500'
        textAlign='right'
      >
        <CurrencyFormat currency={currency} />

        {updatedAmount !== 0 ? (
          updatedAmount
        ) : (
          <Text as='span'>{amount ? Number(amount.$numberDecimal).toLocaleString() : 'N/A'}</Text>
        )}
        {(status === 'paid' || status === 'cleared') && (
          <Text
            fontWeight='400'
            color='brand.primary'
            cursor='pointer'
            onClick={showInput}
          >
            Edit Amount
          </Text>
        )}
      </Box>

      {hide === false && (
        <Box
          as='form'
          marginTop='8px'
          onSubmit={updateAmount}
        >
          <Input
            width='200px'
            minHeight='52px'
            name='newAmount'
            isRequired
            type='number'
            placeholder='Edit amount'
            step='0.0001'
            onChange={handleChange}
            mb='16px'
          />

          <Button
            type='submit'
            disabled={Number(newAmount) < 10 || loading}
            isLoading={loading}
            minHeight='52px'
          >
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UpdateDepositAmount;
