import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
} from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  Loader,
  PageTitle,
  PaginationWrapper,
  BuySellModal,
  ScrollRightContainer,
  ViewMore,
  TableDataStatus,
  Select,
} from 'components';
import { formatDateToUTC } from 'utils';
import { fetchBuySell } from 'store/slices/buySell.slice';

const BuySell = () => {
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState({});
  const [filterStatus, setFilterStatus] = useState('');

  const handleOpen = (data) => {
    onOpen();
    setModalData(data);
  };

  const handleStatusChange = (e) => {
    setCurrentPage(1);
    setFilterStatus(e.target.value);
  };

  const {
    data: { data, totalCount },
    status,
    errorMessage,
  } = useSelector((state) => state.buySell.buySell);

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    isDisabled,
    pageSize,
    offset,
  } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  useEffect(() => {
    dispatch(
      fetchBuySell({
        status: filterStatus,
        page: currentPage,
        limit: 10,
      })
    );
  }, [dispatch, currentPage, filterStatus]);

  return (
    <Box>
      <PageTitle title="Buy/Sell" />

      <Box
        bg="#fff"
        borderRadius="8px"
        padding="25px 22px 2px"
        border="1px solid #EDEBF9"
        width="100%"
        maxWidth={{ sm: '1200px', '2xl': '1400px' }}
        overflowX="scroll"
        mt="1rem"
      >
        <Box mt="16px">
          <Select
            width="120px"
            onChange={handleStatusChange}
            placeholder="Status"
            mb="1rem"
            bg="#E2F2DA"
            minH="46px"
            borderRadius="15px"
            _active={{
              bg: '#E2F2DA',
            }}
            _hover={{
              bg: '#E2F2DA',
            }}
            _focus={{
              bg: '#E2F2DA',
            }}
          >
            <option value="cleared">Cleared</option>
            <option value="pending">Pending</option>
          </Select>
          <>
            {status === 'loading' && (
              <Box margin="8px 0">
                <Loader />
              </Box>
            )}

            {status === 'error' && <p>{errorMessage}</p>}

            {status === 'fulfilled' && (
              <ScrollRightContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Coin</Th>
                      <Th>Opening Rate</Th>
                      <Th>Closing Rate</Th>
                      <Th>Opening Amount</Th>
                      <Th>Closing Amount</Th>

                      <Th>Destination Unit</Th>

                      <Th>Pair </Th>
                      <Th>Status</Th>
                      <Th>Date</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {status === 'fulfilled' &&
                      data.map(
                        ({
                          buySellTxId,
                          closingAmount,
                          closingRate,
                          coin,
                          createdAt,
                          currency,
                          destinationAmount,
                          destinationUnit,
                          openingAmount,
                          openingRate,
                          pairTicker,
                          status,
                          _id,
                        }) => {
                          const buySellData = {
                            buySellTxId,
                            closingAmount,
                            closingRate,
                            coin,
                            createdAt,
                            currency,
                            destinationAmount,
                            destinationUnit,
                            openingAmount,
                            openingRate,

                            pairTicker,
                            status,
                            _id,
                          };

                          return (
                            <Tr key={_id}>
                              <Td textTransform={'uppercase'}>{coin}</Td>

                              <Td>{openingRate}</Td>
                              <Td>{closingRate}</Td>

                              <Td>{Number(openingAmount)?.toLocaleString()}</Td>
                              <Td>{closingAmount}</Td>

                              <Td textTransform={'uppercase'}>
                                {Number(destinationAmount)?.toLocaleString()}{' '}
                                {destinationUnit}
                              </Td>

                              <Td textTransform={'uppercase'}>{pairTicker}</Td>

                              <Td textTransform="capitalize">
                                <TableDataStatus status={status} />
                              </Td>

                              <Td>{formatDateToUTC(createdAt)}</Td>

                              <Td>
                                {status === 'cleared' ? (
                                  'N/A'
                                ) : (
                                  <ViewMore
                                    onClick={() => handleOpen(buySellData)}
                                  >
                                    Clear
                                  </ViewMore>
                                )}
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </Table>
              </ScrollRightContainer>
            )}

            <BuySellModal
              isOpen={isOpen}
              onClose={onClose}
              data={modalData}
              currentPage={currentPage}
              filterStatus={filterStatus}
            />

            <PaginationWrapper
              setCurrentPage={setCurrentPage}
              totalDataCount={totalCount}
              currentPage={currentPage}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              offset={offset}
              pages={pages}
              size={pageSize}
            />
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default BuySell;
