import { Center } from '@chakra-ui/react';
import { Loader } from 'components';
import { MdRefresh } from 'react-icons/md';

const Refresh = ({ onClick, isLoading, ...props }) => {
  return (
    <Center
      cursor={isLoading ? 'initial' : 'pointer'}
      width='40px'
      height='40px'
      borderRadius='full'
      bg='rgba(255, 255, 255, 0.99)'
      boxShadow='0px 8px 30px rgba(0, 0, 0, 0.06)'
      {...props}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <MdRefresh
          fontSize='24px'
          onClick={onClick}
        />
      )}
    </Center>
  );
};

export default Refresh;
