import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Text, Box, Flex, VStack } from '@chakra-ui/react';

import {
  Button,
  Loader,
  ReleaseRevertTrade,
  DisputeConversation,
  UserDisputeDetailsModal,
  HistoryTab,
} from 'components';
import { fetchP2PContractsDisputeByID } from 'store/slices/p2p.slice';
import { formatDateToUTC } from 'utils';

const P2PDisputeDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const {
    contractsDisputeById: { data, status },
  } = useSelector((state) => state.p2p);

  const [open, setOpen] = useState(false);
  const [detailsModalopen, setDetailsModalopen] = useState(false);
  const [operation, setOperation] = useState('');
  const [username, setUsername] = useState('');

  // console.log(data.counterParties, 'contract');

  const handleOpen = (value) => {
    setOperation(value);
    setOpen(true);
  };

  const handleDetailsOpen = (username) => {
    setUsername(username);
    setDetailsModalopen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDetailsModalopen(false);
  };

  useEffect(() => {
    dispatch(
      fetchP2PContractsDisputeByID({
        id: id,
      })
    );
  }, [dispatch, id]);

  console.log('I am here');

  return (
    <Box>
      {/* <PageTitle title="Dispute details" /> */}

      <HistoryTab
        firstText='Dispute details'
        secondText='P2P'
        url='/dashboard/p2p'
      />

      <Flex gridGap='2rem'>
        <Box
          width='100%'
          maxWidth='500px'
        >
          {status === 'loading' && <Loader />}

          {status === 'fulfilled' && (
            <>
              <Box
                bg='#fff'
                borderRadius='16px 16px 0 0'
                mb='4px'
              >
                <Flex
                  padding='1.5rem'
                  gridGap={'1.5rem'}
                >
                  <VStack alignItems={'left'}>
                    <Text>Date/Time</Text>
                    <Text>{formatDateToUTC(data.createdAt)}</Text>
                  </VStack>

                  <VStack alignItems={'left'}>
                    <Text>Conract ID</Text>
                    <Text>{data._id}</Text>
                  </VStack>
                </Flex>
              </Box>

              <Flex
                bg='#fff'
                borderRadius='16px 16px 0 0'
                mb='4px'
              >
                <VStack
                  fontWeight='500'
                  padding='1.5rem'
                  alignItems={'left'}
                >
                  <Text>Username: </Text>

                  <Text>Role: </Text>

                  <Text>Amount To Pay:</Text>

                  <Text>Amount To Receive:</Text>

                  <Text>Transaction Fee</Text>
                </VStack>

                <VStack
                  padding='1.5rem'
                  alignItems={'left'}
                >
                  <Text
                    color='brand.success'
                    cursor='pointer'
                    onClick={() => handleDetailsOpen(data.counterParties[0].user.username)}
                  >
                    {data.counterParties[0].user.username}
                  </Text>

                  <Text textTransform={'capitalize'}> {data.counterParties[0].role}</Text>

                  <Text textTransform={'uppercase'}>
                    {data.counterParties[0].unitToPay === 'btc'
                      ? Number(data.counterParties[0].amountToPay.toLocaleString()).toFixed(8)
                      : data.counterParties[0].amountToPay.toLocaleString()}{' '}
                    {data.counterParties[0].unitToPay}
                  </Text>

                  <Text textTransform={'uppercase'}>
                    {data.counterParties[0].amountToReceive.toLocaleString()} {data.counterParties[0].unitToReceive}
                  </Text>

                  <Text textTransform={'uppercase'}>
                    {data.coin === 'btc'
                      ? Number(data.counterParties[0].tradeFee).toFixed(8)
                      : data.counterParties[0].tradeFee.toLocaleString()}{' '}
                    {data.coin}
                  </Text>
                </VStack>

                <VStack
                  padding='1.5rem'
                  alignItems={'left'}
                >
                  <Text
                    color='brand.success'
                    cursor='pointer'
                    onClick={() => handleDetailsOpen(data.counterParties[1].user.username)}
                  >
                    {data.counterParties[1].user.username}
                  </Text>

                  <Text textTransform={'capitalize'}> {data.counterParties[1].role}</Text>

                  <Text textTransform={'uppercase'}>
                    {data.counterParties[1].unitToPay === 'btc'
                      ? Number(data.counterParties[1].amountToPay.toLocaleString()).toFixed(8)
                      : data.counterParties[1].amountToPay.toLocaleString()}{' '}
                    {data.counterParties[1].unitToPay}
                  </Text>

                  <Text textTransform={'uppercase'}>
                    {data.counterParties[1].amountToReceive.toLocaleString()} {data.counterParties[1].unitToReceive}
                  </Text>

                  <Text textTransform={'uppercase'}>
                    {data.coin === 'btc'
                      ? Number(data.counterParties[1].tradeFee).toFixed(8)
                      : data.counterParties[1].tradeFee.toLocaleString()}{' '}
                    {data.coin}
                  </Text>
                </VStack>
              </Flex>

              <Box
                bg='#fff'
                // color="#6E7191"
                padding='1rem 1.5rem'
                m='0.25rem 0'
              >
                <Flex justifyContent='space-between'>
                  <Box flexBasis={'40%'}>
                    <Text>Buyer</Text>
                    <Box>
                      {status === 'fulfilled' &&
                        data.counterParties
                          .filter((res) => {
                            return res.perspective === 'buyer';
                          })[0]
                          .payTo.map((result, i) => {
                            return (
                              <Box key={i}>
                                <Text textTransform='capitalize'>
                                  <Text
                                    as='span'
                                    fontWeight={'500'}
                                  >
                                    Account Name:
                                  </Text>{' '}
                                  {result?.accountName}
                                </Text>

                                <Text>
                                  <Text
                                    as='span'
                                    fontWeight={'500'}
                                  >
                                    Address:{' '}
                                  </Text>
                                  {result?.address}
                                </Text>

                                <Text textTransform='capitalize'>
                                  <Text
                                    as='span'
                                    fontWeight={'500'}
                                  >
                                    Method:{' '}
                                  </Text>
                                  {result?.method}
                                </Text>
                              </Box>
                            );
                          })}
                    </Box>
                  </Box>

                  <Box flexBasis={'40%'}>
                    <Text>Seller</Text>

                    <Box>
                      {status === 'fulfilled' &&
                        data.counterParties
                          .filter((res) => {
                            return res.perspective === 'seller';
                          })[0]
                          .payTo.map((result, i) => {
                            return (
                              <Box key={i}>
                                <Text>
                                  <Text
                                    as='span'
                                    fontWeight={'500'}
                                  >
                                    Address:{' '}
                                  </Text>

                                  <Text
                                    as='span'
                                    maxWidth='220px'
                                    display='block'
                                  >
                                    {result?.address}
                                  </Text>
                                </Text>
                              </Box>
                            );
                          })}
                    </Box>
                  </Box>
                </Flex>
              </Box>

              <Box
                bg='#fff'
                padding='1rem 1.5rem 0'
              >
                <Text fontWeight={'500'}>Timeline</Text>
                <Box>
                  <Text> CreatedAt: {formatDateToUTC(data.counterParties[0].timeline.createdAt)}</Text>

                  <Text> DueAt: {formatDateToUTC(data.counterParties[0].timeline.dueAt)}</Text>

                  <Text>
                    Duration: {data.counterParties[0].timeline.duration} {data.counterParties[0].timeline.durationUnit}
                  </Text>
                </Box>
              </Box>

              <Box
                bg='#fff'
                borderRadius='0 0 16px 16px'
                padding='1.5rem'
              >
                <Flex
                  mb='1rem'
                  gridGap='8px'
                >
                  <Text
                    fontWeight={'500'}
                    fontSize='14px'
                  >
                    Proof:{' '}
                  </Text>
                  <Box
                    as='a'
                    target='_blank'
                    fontSize='14px'
                    href={
                      data.counterParties[0].proofOfWaiver
                        ? data.counterParties[0].proofOfWaiver
                        : data.counterParties[1].proofOfWaiver
                    }
                    color='blue.500'
                  >
                    {data.counterParties[0].proofOfWaiver
                      ? data.counterParties[0].proofOfWaiver
                      : data.counterParties[1].proofOfWaiver}
                  </Box>
                </Flex>

                <Box
                  borderRadius={'8px'}
                  bg='#F6F5FA'
                  padding='1.5rem'
                >
                  <Text
                    fontWeight='700'
                    color='brand.black'
                  >
                    Remark
                  </Text>

                  <Text>{data.remark}</Text>
                </Box>

                {data.contractState === 'closed' || data.contractState === 'cancelled' ? (
                  <>
                    <Text></Text>
                  </>
                ) : (
                  <>
                    <Flex
                      gridGap='1rem'
                      mt='74px'
                    >
                      <Button
                        bg='#fff'
                        color='brand.primary'
                        border='2px solid #0D4740'
                        minW='200px'
                        onClick={() => handleOpen('RELEASETRADE')}
                      >
                        Release
                      </Button>

                      <Button
                        minW='200px'
                        onClick={() => handleOpen('REVERT')}
                      >
                        Revert
                      </Button>
                    </Flex>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>

        <DisputeConversation
          contractState={data?.contractState}
          tradeRef={id}
        />
      </Flex>

      {status === 'fulfilled' && (
        <ReleaseRevertTrade
          operation={operation}
          isOpen={open}
          onClose={handleClose}
          frozenRef={id.split('-')[1]}
          _id={id.split('-')[0]}
        />
      )}

      {
        <UserDisputeDetailsModal
          isOpen={detailsModalopen}
          onClose={handleClose}
          username={username}
        />
      }
    </Box>
  );
};

export default P2PDisputeDetails;
