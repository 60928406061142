import { useEffect, useRef, useState } from 'react';
import { Box, Center } from '@chakra-ui/react';

const ConfigCheckbox = ({ defaultChecked, onChange, ...props }) => {
  const ref = useRef();

  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleClick = () => {
    ref?.current.click();
    setIsChecked(ref.current.checked);
  };

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <Box onClick={handleClick}>
      <input
        ref={ref}
        hidden
        type='checkbox'
        onChange={onChange}
        defaultChecked={defaultChecked}
        {...props}
      />
      <Center
        padding='0 3px'
        width='42.41px'
        height='25.43px'
        borderRadius='1000px'
        bgColor={isChecked ? 'brand.success' : '#D9DBE9'}
        cursor='pointer'
        justifyContent={isChecked ? 'right' : 'left'}
        transition='all 500ms ease-in'
      >
        <Center
          width='20.35px'
          height='20.35px'
          borderRadius='full'
          bgColor='#fff'
          alignItems='center'
          transition='all 500ms ease-in'
        ></Center>
      </Center>
    </Box>
  );
};

export default ConfigCheckbox;
