import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from 'constant';
import { constant, ls, wrapBaseApi } from 'utils';
// DEPOSITS
// GET {{BASEURL}}/wallet/widget/admin/transfer/deposits

// WITHDRAWALS
// GET {{BASEURL}}/wallet/widget/admin/transfer/withdrawals

export const integrationsApi = createApi({
  reducerPath: 'integrations',
  refetchOnMountOrArgChange: true,
  tagTypes: ['transfer', 'kyc'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: (headers, _) => {
      const auth = ls.get(constant.AUTH_TOKEN_KEY);

      headers.set('Authorization', auth.token);
      headers.set('Content-Type', 'application/json');
      // headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    integrationTransfer: builder.query({
      query: ({ size, page }) => wrapBaseApi(`/wallet/widget/admin/transfers?page=${page}&limit=${size}`),
      transformResponse: (res) => res?.data,
      providesTags: ['transfer'],
    }),

    integrationDeposits: builder.query({
      query: ({ size, page, status, queries }) =>
        wrapBaseApi(
          `/wallet/widget/admin/transfer/deposits?page=${page}&limit=${size}&paymentStatus=${status}&${
            queries ? queries : ''
          }`
        ),
      transformResponse: (res) => res?.data,
      providesTags: ['transfer'],
    }),

    integrationWithdrawal: builder.query({
      query: ({ size, page, status, queries, type, hasFilters }) =>
        wrapBaseApi(
          `/wallet/widget/admin/${
            hasFilters ? 'transfer/withdrawals' : 'transfers'
          }?page=${page}&limit=${size}&transferStatus=${status}${type ? `&${type}` : ''}${queries ? `&${queries}` : ''}`
        ),
      transformResponse: (res) => res?.data,
      providesTags: ['transfer'],
    }),

    updateIntegrationTransfer: builder.mutation({
      query: ({ _id, paymentStatus, sendWebhook, reason, depositApprovalReference }) => ({
        url: wrapBaseApi(`/wallet/widget/admin/transfer/${_id}/paymentStatus`),
        method: 'PATCH',
        body: {
          paymentStatus,
          sendWebhook,
          reason,
          depositApprovalReference,
        },
      }),
      invalidatesTags: ['transfer'],
      transformResponse: (res) => res,
    }),

    updateTransferStatus: builder.mutation({
      query: ({ _id, transferStatus, sendWebhook, txHash, reason }) => ({
        url: wrapBaseApi(`/wallet/widget/admin/transfer/${_id}/transferStatus`),
        method: 'PATCH',
        body: {
          transferStatus,
          sendWebhook,
          txHash,
          reason,
        },
      }),
      invalidatesTags: ['transfer'],
      transformResponse: (res) => res,
    }),

    processWidgetPayout: builder.mutation({
      query: ({ id }) => ({
        url: wrapBaseApi(`/wallet/widget/admin/transfer/${id}/process-payout`),
        method: 'PATCH',
        body: {},
      }),
      invalidatesTags: ['transfer'],
      transformResponse: (res) => res,
    }),

    integrationKyc: builder.query({
      query: ({ page, size, status, queries }) =>
        `widget/admin/kyc/all?page=${page}&limit=${size}&documentStatus=${status || ''}&${queries}`,
      transformResponse: (res) => res?.data,
      providesTags: ['kyc'],
    }),

    updateIntegrationKyc: builder.mutation({
      query: ({ _id, kycStatus, kycSchema, reason, expiresOn }) => ({
        url: `/widget/admin/kyc/${_id}/updateKycStatus`,
        method: 'PATCH',
        body: {
          kycStatus,
          kycSchema,
          reason,
          expiresOn,
        },
      }),
      invalidatesTags: ['kyc'],
      transformResponse: (res) => res,
    }),

    integrationUser: builder.query({
      query: ({ page, size, search }) => `/widget/admin/users?page=${page}&limit=${size}&search=${search}`,
      transformResponse: (res) => res?.data,
    }),

    allTransfer: builder.query({
      query: ({ page, size, status }) =>
        wrapBaseApi(`/wallet/widget/admin/transfers?page=${page}&limit=${size}&paymentStatus=${status}`),
      transformResponse: (res) => res?.data,
    }),
  }),
});

export const {
  useIntegrationTransferQuery,
  useUpdateIntegrationTransferMutation,
  useUpdateTransferStatusMutation,

  useIntegrationKycQuery,
  useUpdateIntegrationKycMutation,

  useIntegrationUserQuery,

  useIntegrationWithdrawalQuery,
  useIntegrationDepositsQuery,
  useIntQuery,
  useAllTransferQuery,

  useProcessWidgetPayoutMutation,
} = integrationsApi;

export default integrationsApi;
