import { useState } from 'react';
import { VStack, useToast, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Button, Input } from 'components';
import { constant, ls } from 'utils';
import { useLoginMutation, useRequestAccessPairMutation } from 'store/services/cico.service';

const CICOAuth = () => {
  const toast = useToast();

  const { data: profileData, status } = useSelector((state) => state.auth.profile);
  const [requestAccessPair, { isLoading: reqLoading }] = useRequestAccessPairMutation();
  const [login, { isLoading }] = useLoginMutation();

  const [accessPair, setAccessPair] = useState(null);
  const [accessCode, setAccessCode] = useState('');

  const handleRequestLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await requestAccessPair({
        email: profileData.email,
      });

      if (response) {
        setAccessPair(response.data?.accessPair);

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: 'Request successful',
          status: 'success',
          duration: 18000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.data.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await login({
        email: profileData.email,
        accessPair,
        accessCode,
      });

      if (response.data) {
        ls.set(constant.AUTH_CICO_TOKEN_KEY, response.data.accessToken);

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: 'Login successful',
          status: 'success',
          duration: 18000,
          isClosable: true,
        });

        window.location.href = '/dashboard/cico?kyc';
      }
    } catch (error) {
      console.log(error, 'ERROR');
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.data?.error || error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Box width={{ base: '100%', tb: '399px' }}>
      {status === 'success' && (
        <>
          <VStack
            alignItems='start'
            gridGap='16px'
          >
            <Input
              width='100%'
              name='email'
              disabled
              placeholder='Reason'
              defaultValue={profileData?.email}
            />

            <Button
              width={{ base: '100%', md: '399px' }}
              isLoading={reqLoading}
              disabled={reqLoading || ls.get(constant.AUTH_CICO_TOKEN_KEY)}
              onClick={handleRequestLogin}
            >
              Request Login
            </Button>
          </VStack>

          {accessPair && (
            <VStack
              alignItems='start'
              gridGap='16px'
              mt='3rem'
            >
              <Input
                width='100%'
                name='accesCode'
                placeholder='Access Code'
                onChange={(e) => setAccessCode(e.target.value)}
              />

              <Button
                width={{ base: '100%', md: '399px' }}
                isLoading={isLoading}
                disabled={isLoading || accessCode.length <= 2}
                onClick={handleLogin}
              >
                Login
              </Button>
            </VStack>
          )}
        </>
      )}
    </Box>
  );
};

export default CICOAuth;
