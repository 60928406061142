export const transferStatusColor = (status) => {
  switch (status) {
    case 'TransferCancelled':
      return '#ED1C00';

    case 'TransferFailed':
      return '#ED1C00';

    case 'PaymentFailed':
      return '#ED1C00';

    case 'PaymentCancelled':
      return '#ED1C00';

    case 'cancelled':
      return '#ED1C00';

    case 'timedout':
      return '#ED1C00';

    case 'TransferMade':
      return '#6FBE45';

    case 'made':
      return '#6FBE45';

    case 'PaymentMade':
      return '#6FBE45';

    case 'confirmed':
      return '#6FBE45';

    case 'TransferComplete':
      return '#6FBE45';

    case 'TransferReceivedFiatFunds':
      return '#F4A51D';

    case 'TransferPending':
      return '#F4A51D';

    case 'TransferStarted':
      return '#F4A51D';

    case 'TransferReceivedCryptoFunds':
      return '#F4A51D';

    case 'TransferWaitingForUserAction':
      return '#F4A51D';

    case 'TransferSendingCryptoFunds':
      return '#F4A51D';

    case 'pending':
      return '#F4A51D';

    case 'incomplete':
      return '#F4A51D';

    default:
      break;
  }
};
