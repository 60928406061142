import { useEffect } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Flex } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSingleCard } from 'store/slices/card.slice';
import { formatDateToUTC } from 'utils';
import { CardTransactionModal, FreezeCardModal, Loader, PaginationWrapper, ScrollRightContainer } from 'components';
import { usePagination } from '@ajna/pagination';

const UserCard = ({ userId }) => {
  const dispatch = useDispatch();

  const { data, status, totalCount } = useSelector((state) => state.cards.singleCard);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  useEffect(() => {
    dispatch(
      fetchSingleCard({
        userId,
        page: currentPage,
      })
    );
  }, [currentPage, dispatch, userId]);

  return (
    <Box>
      {status === 'loading' && <Loader />}

      {status === 'fulfilled' && totalCount === 0 && (
        <Text
          fontSize='18px'
          fontWeight='500'
        >
          User has no card
        </Text>
      )}

      {(data && data.card).length >= 1 && (
        <>
          <ScrollRightContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Card ID</Th>
                  <Th>Card Number</Th>
                  <Th>Card type</Th>
                  <Th>Card balance</Th>
                  <Th>Last Withdrawal</Th>
                  <Th>Last Deposit</Th>
                  <Th>Card Version</Th>
                  <Th>Status</Th>
                  <Th>Label</Th>
                  <Th>Created At</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data.card.map(
                  ({
                    _id,
                    balance,
                    lastDeposit,
                    lastWithdrawal,
                    cardType,
                    cardVersion,
                    status,
                    statusTag,
                    cardId,
                    createdAt,
                    cardNumber,
                    label,
                  }) => {
                    return (
                      <Tr key={_id}>
                        <Td>{cardId}</Td>

                        <Td>{cardNumber}</Td>

                        <Td textTransform='capitalize'>
                          {cardType?.type ? cardType.type : 'N/A'} <br />(
                          {cardType?.txLimit ? Number(cardType?.txLimit).toLocaleString() : 'N/A'})
                        </Td>

                        <Td textTransform='capitalize'>{Number(balance?.$numberDecimal).toLocaleString()}</Td>

                        <Td>{Number(lastWithdrawal?.$numberDecimal).toLocaleString()}</Td>

                        <Td>{Number(lastDeposit?.$numberDecimal).toLocaleString()}</Td>

                        <Td textTransform='uppercase'>{cardVersion}</Td>

                        <Td textTransform='capitalize'>{status}</Td>

                        <Td>{label ? label : 'N/A'}</Td>

                        <Td>{formatDateToUTC(createdAt).split(',')[0]}</Td>

                        <Td>
                          <Flex gap='12px'>
                            <CardTransactionModal cardId={cardId} />
                            <FreezeCardModal
                              status={status}
                              statusTag={statusTag}
                              id={cardId}
                              btnWidth='100px'
                              btnHeight='32px'
                              userId={userId}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </ScrollRightContainer>

          <PaginationWrapper
            setCurrentPage={setCurrentPage}
            totalDataCount={totalCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            pagesCount={pagesCount}
            size={pageSize}
            offset={offset}
            pages={pages}
          />
        </>
      )}
    </Box>
  );
};

export default UserCard;
