import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'constant';
import { getHeaders } from 'utils';
import { get } from 'utils/axiosLib';

const initialState = {
  user: {
    status: 'idle',
    totalCount: 0,
    data: [],
    beforeAndAfter: '',
    loginActivity: '',
  },

  totalUserCount: {
    status: 'idle',
    totalCount: 0,
  },

  singleUser: {
    status: 'idle',
    data: {},
  },

  document: {
    status: 'idle',
    data: {
      documents: [],
    },
    totalCount: 0,
  },

  documentsID: {
    data: {
      documents: [],
      total: 0,
    },
  },

  singleDocument: {
    status: 'idle',
    data: {
      document: {},
      kycLevelOne: [],
      kycLevelTwo: [],
      kycLevelThree: [],
    },
  },

  similarityScore: {
    status: 'idle',
    dobMatches: 0,
    nameMatches: 0,
    ninMatches: 0,
    data: {
      ratings: [
        {
          target: '',
          rating: 0,
        },
      ],
      ninRatings: [
        {
          target: '',
          rating: 0,
        },
      ],
      dobRatings: [
        {
          target: '',
          rating: 0,
        },
      ],
      score: 0,
    },
  },

  documentByUserId: {
    status: 'idle',
    data: {
      documents: [],
    },
  },

  reviewDocuments: {
    status: 'idle',
    data: {
      documents: [],
    },
  },

  banHistory: {
    status: 'idle',
    data: {},
    errorMessage: '',
  },

  resolveBvn: {
    status: 'idle',
    errorMessage: '',
    data: {},
  },
};

export const fetchUsers = createAsyncThunk(
  'users/allUsers',
  async ({ page, limit, search, time }, { rejectWithValue }) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/users?limit=${limit}&page=${page}&search=${search}&sortBy=createdAt:desc&${time}`,
        getHeaders()
      );
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchTotalUserCount = createAsyncThunk('users/totalCount', async () => {
  const response = await get(`${BASE_URL}/admin/users?limit=${10}&page=${1}`, getHeaders());
  return response.data;
});

export const fetchSingleUser = createAsyncThunk('users/singleUser', async (id, { rejectWithValue }) => {
  try {
    const response = await get(`${BASE_URL}/admin/user/${id}`, getHeaders());
    return response.data;
  } catch (error) {
    rejectWithValue(error);
  }
});

export const fetchUserDocuments = createAsyncThunk(
  'users/document',
  async ({ page, limit, status, search, sortBy, otherQuery }) => {
    let query = `${BASE_URL}/admin/verification/documents?limit=${limit || 10}`;
    if (status) query += `&status=${status}`;
    if (page) query += `&page=${page}`;
    if (search) query += `&user=${search}`;
    if (sortBy) query += `&sortBy=${sortBy}`;
    if (otherQuery) query += `&${otherQuery}`;
    const response = await get(query, getHeaders());
    return response.data;
  }
);

export const fetchSingleDocument = createAsyncThunk('users/singleDocument', async ({ id }) => {
  const response = await get(`${BASE_URL}/admin/verification/documents/${id}`, getHeaders());
  return response.data;
});

export const fetchSimilarityScore = createAsyncThunk('users/similarityScore', async ({ id }) => {
  const response = await get(`${BASE_URL}/admin/check-similarity/${id}`, getHeaders());
  return response.data;
});

// KYC Level Two
export const fetchDocumentDetailsByUserId = createAsyncThunk('users/documentByUserIdWithKyc', async (id) => {
  const response = await get(`${BASE_URL}/admin/verification/documents?kycLevel=two&userId=${id}`, getHeaders());
  return response.data;
});

export const fetchDocumentByUserId = createAsyncThunk('users/documentByUserId', async (id) => {
  const response = await get(`${BASE_URL}/admin/verification/documents?userId=${id}`, getHeaders());
  return response.data;
});

export const fetchBanHistory = createAsyncThunk('users/banHistory', async (id) => {
  const response = await get(`${BASE_URL}/admin/users/${id}/ban-history`, getHeaders());
  return response.data;
});

export const resolveBvn = createAsyncThunk('users/resolveBvn', async (id, { rejectWithValue }) => {
  try {
    const response = await get(`${BASE_URL}/admin/bvn/resolve/${id}`, getHeaders());
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const userSlice = createSlice({
  name: 'users',

  initialState,

  reducers: {
    getUsersCustomDate(state, { payload }) {
      state.user.beforeAndAfter = payload;
    },

    getLoginActivityDate(state, { payload }) {
      state.user.loginActivity = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.user.status = 'fulfilled';
      state.user.data = payload.data.users;
      state.user.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchUsers.pending, (state) => {
      state.user.status = 'loading';
    });

    builder.addCase(fetchUsers.rejected, (state) => {
      state.user.status = 'error';
    });

    // Total User count
    builder.addCase(fetchTotalUserCount.fulfilled, (state, { payload }) => {
      state.totalUserCount.status = 'fulfilled';
      state.totalUserCount.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchTotalUserCount.pending, (state) => {
      state.totalUserCount.status = 'loading';
    });

    builder.addCase(fetchTotalUserCount.rejected, (state) => {
      state.totalUserCount.status = 'error';
    });

    // Single user
    builder.addCase(fetchSingleUser.fulfilled, (state, { payload }) => {
      state.singleUser.status = 'fulfilled';
      state.singleUser.data = payload.data.users;
    });

    builder.addCase(fetchSingleUser.pending, (state) => {
      state.singleUser.status = 'loading';
    });

    builder.addCase(fetchSingleUser.rejected, (state) => {
      state.singleUser.status = 'error';
    });

    // Documents
    builder.addCase(fetchUserDocuments.fulfilled, (state, { payload }) => {
      state.document.status = 'fulfilled';
      state.document.data = payload?.data;
      state.document.totalCount = payload?.data?.totalCount;
      state.documentsID.data = payload?.data;
    });

    builder.addCase(fetchUserDocuments.pending, (state) => {
      state.document.status = 'loading';
    });

    builder.addCase(fetchUserDocuments.rejected, (state) => {
      state.document.status = 'error';
    });

    // Documents
    builder.addCase(fetchSingleDocument.fulfilled, (state, { payload }) => {
      state.singleDocument.status = 'fulfilled';
      state.singleDocument.data = payload.data;
    });

    builder.addCase(fetchSingleDocument.pending, (state) => {
      state.singleDocument.status = 'loading';
    });

    builder.addCase(fetchSingleDocument.rejected, (state) => {
      state.singleDocument.status = 'error';
    });

    // Similarity score
    builder.addCase(fetchSimilarityScore.fulfilled, (state, { payload }) => {
      state.similarityScore.status = 'fulfilled';

      state.similarityScore.dobMatches = payload.data.dobMatches;
      state.similarityScore.ninMatches = payload.data.ninMatches;
      state.similarityScore.nameMatches = payload.data.nameMatches;

      state.similarityScore.data.ratings = payload.data.ratings.sort((a, b) => b.rating - a.rating);

      state.similarityScore.data.dobRatings = payload.data.dobRatings.sort((a, b) => b.rating - a.rating);

      state.similarityScore.data.ninRatings = payload.data.ninRatings.sort((a, b) => b.rating - a.rating);

      state.similarityScore.data.score = payload.data.score;
    });

    builder.addCase(fetchSimilarityScore.pending, (state) => {
      state.similarityScore.status = 'loading';
    });

    builder.addCase(fetchSimilarityScore.rejected, (state) => {
      state.similarityScore.status = 'error';
    });

    // Documents with KYC level two
    builder.addCase(fetchDocumentDetailsByUserId.fulfilled, (state, { payload }) => {
      state.documentByUserId.status = 'fulfilled';
      state.documentByUserId.data = payload.data;
    });

    builder.addCase(fetchDocumentDetailsByUserId.pending, (state) => {
      state.documentByUserId.status = 'loading';
    });

    builder.addCase(fetchDocumentDetailsByUserId.rejected, (state) => {
      state.documentByUserId.status = 'error';
    });

    builder.addCase(fetchDocumentByUserId.fulfilled, (state, { payload }) => {
      state.reviewDocuments.status = 'fulfilled';
      state.reviewDocuments.data = payload.data;
    });

    builder.addCase(fetchDocumentByUserId.pending, (state) => {
      state.reviewDocuments.status = 'loading';
    });

    builder.addCase(fetchDocumentByUserId.rejected, (state) => {
      state.reviewDocuments.status = 'error';
    });

    // Ban History
    builder.addCase(fetchBanHistory.fulfilled, (state, { payload }) => {
      state.banHistory.status = 'fulfilled';
      state.banHistory.data = payload;
    });

    builder.addCase(fetchBanHistory.pending, (state) => {
      state.banHistory.status = 'loading';
    });

    builder.addCase(fetchBanHistory.rejected, (state, { payload }) => {
      state.banHistory.status = 'error';
      state.banHistory.errorMessage = payload;
    });

    // Resolve BVN
    builder.addCase(resolveBvn.fulfilled, (state, { payload }) => {
      state.resolveBvn.status = 'fulfilled';
      state.resolveBvn.data = payload.data.bvnData;
    });

    builder.addCase(resolveBvn.pending, (state) => {
      state.resolveBvn.status = 'loading';
    });

    builder.addCase(resolveBvn.rejected, (state, { payload }) => {
      state.resolveBvn.status = 'error';
      state.resolveBvn.errorMessage = payload.error;
    });
  },
});

export const {
  actions: { getUsersCustomDate, getLoginActivityDate },
  reducer: UserReducer,
} = userSlice;
