import { useState } from 'react';
import { Box, Center, Text, useToast, VStack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'components';
import { put } from 'utils/axiosLib';
import { fetchWalletDeposit } from 'store/slices/wallet.slice';
import { constant, getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';
import { useStorage } from 'hooks';

const ApproveDenyFiatDeposit = ({ _id, onClose, actionValue }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [reason, setReason] = useState('');
  const { storageValue: statusStorage } = useStorage(constant.DE_STATUS);

  const headers = getHeaders();

  const handleChange = (e) => {
    setReason(e.target.value);

    if (e.target.value.length >= 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const approveTransaction = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/deposit/${_id}/${actionValue}`);

    try {
      const response = await put(
        URL,
        {
          reason: actionValue === 'deny' ? reason : undefined,
          depositApprovalReference: actionValue === 'approve' && reason ? reason : undefined,
        },
        headers
      );

      if (response.data) {
        dispatch(
          fetchWalletDeposit({
            page: 1,
            limit: 10,
            depositPaymentType: '',
            status: statusStorage ? statusStorage : '',
          })
        );

        onClose();

        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: typeof error.error === 'string' ? error.error : error.error.message,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  const isDisabled = actionValue === 'approve' ? false : disabled || reason.length <= 3;

  return (
    <VStack mt='2rem'>
      <Text>
        Are you sure you want to
        <Text
          as='span'
          fontWeight='600'
        >
          {' '}
          {actionValue}
        </Text>{' '}
        this transaction
      </Text>

      {/* {actionValue === 'deny' && ( */}
      <Box>
        <Box margin='1rem 0'>
          <Input
            width='380px'
            minHeight='48px'
            name={actionValue === 'deny' ? 'reason' : 'referenceId'}
            isRequired
            placeholder={actionValue === 'deny' ? 'Reason' : 'Reference ID'}
            onChange={handleChange}
          />
        </Box>
      </Box>
      {/* )} */}

      <Box>
        <Box mt={actionValue === 'approve' ? '1rem' : '0'}>
          <Center>
            <Button
              disabled={isDisabled}
              isLoading={loading}
              onClick={approveTransaction}
              minH='48px'
            >
              Yes {actionValue}
            </Button>
          </Center>
        </Box>
      </Box>
    </VStack>
  );
};

export default ApproveDenyFiatDeposit;
