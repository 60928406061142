import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
} from '@chakra-ui/react';
import { fetchDocumentDetailsByUserId } from 'store/slices/user.slice';
import { CloseModal } from 'components';

const SimilarityScoreModal = ({ isOpen, onClose, data: { userId } }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.allUsers.documentByUserId);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchDocumentDetailsByUserId(userId));
    }
  }, [dispatch, userId, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} key={userId}>
      <ModalOverlay />

      <ModalContent
        borderRadius="16px"
        bg="#fff"
        maxWidth="420px"
        padding="40px 40px 0"
      >
        <Text fontSize="24px" fontWeight="600" textAlign="center">
          User Details
        </Text>

        <ModalBody padding="0px" margin="20px 0" fontSize="14px">
          <Flex justifyContent="space-between" mb="1rem">
            <Text>Email:</Text>

            <Text fontWeight="medium">
              {data.documents[0]?.userDetail.email}
            </Text>
          </Flex>

          <Flex justifyContent="space-between" mb="1rem">
            <Text>NIN:</Text>

            <Text fontWeight="medium">{data.documents[0]?.ninNumber}</Text>
          </Flex>

          <Flex justifyContent="space-between" mb="1rem">
            <Text>Date of Birth:</Text>

            <Text fontWeight="medium">{data.documents[0]?.dateOfBirth}</Text>
          </Flex>

          <Flex justifyContent="space-between" mb="1rem">
            <Text>Document Link</Text>

            <Text
              color="brand.primary"
              fontWeight="medium"
              as="a"
              href={data.documents[0]?.docLink}
              target="_blank"
            >
              link
            </Text>
          </Flex>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SimilarityScoreModal;
