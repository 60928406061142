import { useEffect, useMemo, useState } from 'react';
import { CSVDownload } from 'react-csv';
import { Button, CloseModal, Input, InputSelect } from 'components';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  useDisclosure,
  Box,
  Center,
  useToast,
} from '@chakra-ui/react';
import { useGenerateTransferReportMutation } from 'store/services/cico.service';
import { convertToIso } from 'utils';

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
const GenerateTransferReport = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const currentYear = new Date().getFullYear();
  const [state, setState] = useState({
    year: currentYear,
    partnerId: '',
  });

  const [reportType, setReportType] = useState('monthly');

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const startYear = 2018;

  const listYear = useMemo(() => {
    const year = [];
    for (let i = currentYear; i >= startYear; i--) {
      year.push(i);
    }

    return year;
  }, [currentYear]);

  const [data, setData] = useState([]);

  const toast = useToast();
  const [_generateReport, { isLoading }] = useGenerateTransferReportMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await _generateReport({
      format: 'json',
      year: Number(state.year),
      month: reportType === 'monthly' && !state.month ? 'january' : state.month,

      quarter: state.quarter ? Number(state.quarter) : undefined,
      startDate: state.startDate ? convertToIso(state.startDate) : undefined,
      endDate: state.endDate ? convertToIso(state.endDate) : undefined,
      partnerId: '',
    });

    if (response.data) {
      setData(response.data.data);
      onClose();

      setState({
        year: currentYear,
      });
    }

    if (response.data?.data?.length === 0) {
      toast({
        position: 'bottom-right',
        description: 'No transfer record for the selected period',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    isOpen && setData([]);
  }, [isOpen]);

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Account Type', key: 'accountType' },
    { label: 'Bitmama Address', key: 'bitmamaAddress' },
    { label: 'Crypto Type', key: 'cryptoType' },
    { label: 'Crypto Volume', key: 'cryptoVolume' },
    { label: 'Date', key: 'date' },
    { label: 'fee', key: 'fee' },
    { label: 'Fee Unit', key: 'feeUnit' },
    { label: 'Fiat Account ID', key: 'fiatAccountId' },
    { label: 'fiatAmount', key: 'fiatAmount' },
    { label: 'fiatType', key: 'fiatType' },
    { label: 'Sender Address', key: 'senderAddress' },
    { label: 'Transfer Status', key: 'transferStatus' },
    { label: 'transferType', key: 'transferType' },
  ];

  return (
    <>
      {data?.length !== 0 && (
        <CSVDownload
          filename='cico_report'
          data={data}
          headers={headers}
        />
      )}

      <Button
        minH='45px'
        minW='170px'
        onClick={onOpen}
      >
        Generate Report
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        key={'_id'}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='480px'
          padding='32px 20px'
        >
          <Text
            fontSize='24px'
            textAlign='center'
            fontWeight='600'
            mb='24px'
          >
            Generate Report
          </Text>

          <ModalBody p='0'>
            <form onSubmit={handleSubmit}>
              <Flex
                flexDir='column'
                gridGap='24px'
                mb='42px'
              >
                <InputSelect
                  minH='56px'
                  borderRadius='15px'
                  onChange={(e) => setReportType(e.target.value)}
                >
                  <option value='monthly'>Monthly</option>
                  <option value='quarterly'>Quarterly</option>
                  <option value='custom'>Custom Date</option>
                </InputSelect>

                {reportType === 'monthly' && (
                  <>
                    <InputSelect
                      minH='56px'
                      name='month'
                      onChange={handleChange}
                      textTransform='capitalize'
                    >
                      {months.map((month) => (
                        <option
                          value={month}
                          key={month}
                        >
                          {month}
                        </option>
                      ))}
                    </InputSelect>
                  </>
                )}

                {reportType === 'quarterly' && (
                  <>
                    <InputSelect
                      minH='56px'
                      name='quarter'
                      onChange={handleChange}
                    >
                      <option value={1}>1st Quarter</option>
                      <option value={2}>Second Quarter</option>
                      <option value={3}>Third Quarter</option>
                      <option value={4}>Fourth Quarter</option>
                    </InputSelect>
                  </>
                )}

                {reportType === 'custom' && (
                  <>
                    <Input
                      label='Start Date'
                      name='startDate'
                      type='date'
                      minH='42px'
                      onChange={handleChange}
                    />

                    <Input
                      label='End Date'
                      name='endDate'
                      type='date'
                      minH='42px'
                      onChange={handleChange}
                    />
                  </>
                )}

                <Box>
                  <Box
                    as='label'
                    display='block'
                    fontSize='12px'
                    fontWeight='400'
                    mb='10px'
                    color='#303131'
                    htmlFor='year'
                    id='year'
                  >
                    Year
                  </Box>

                  <InputSelect
                    name='year'
                    id='year'
                    onChange={handleChange}
                  >
                    {listYear.map((yr) => (
                      <option
                        value={yr}
                        key={yr}
                      >
                        {yr}
                      </option>
                    ))}
                  </InputSelect>
                </Box>

                <Box>
                  <Input
                    label='Partner ID'
                    name='partnerId'
                    placeholder='Partner ID'
                    fontSize='14px'
                    onChange={handleChange}
                  />
                </Box>
              </Flex>

              <Center>
                <Button
                  minH='52px'
                  minW='180px'
                  isLoading={isLoading}
                  disabled={isLoading || state.partnerId?.length <= 2}
                  type='submit'
                >
                  Submit
                </Button>
              </Center>
            </form>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GenerateTransferReport;
