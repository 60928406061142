import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'constant';
import { getHeaders } from 'utils';
import { get } from 'utils/axiosLib';

const initialState = {
  roles: {
    roles: [],
    status: 'pending',
  },

  userByEmail: {
    users: [],
    userId: '',
    status: 'pending',
  },
};

export const fetchRoles = createAsyncThunk('auth/roles', async () => {
  const response = await get(`${BASE_URL}/admin/roles`, getHeaders());
  return response.data;
});

export const fetchUserByEmail = createAsyncThunk(
  'user/email',
  async ({ email }) => {
    const response = await get(
      `${BASE_URL}/admin/users?email=${email}`,
      getHeaders()
    );
    return response.data;
  }
);

const roleSlice = createSlice({
  name: 'roles',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchRoles.fulfilled, (state, { payload }) => {
      state.roles = payload.data;
      state.roles.status = 'fulfilled';
    });

    builder.addCase(fetchRoles.pending, (state) => {
      state.roles.status = 'loading';
    });

    builder.addCase(fetchRoles.rejected, (state) => {
      state.roles.status = 'error';
    });

    // User by Email
    builder.addCase(fetchUserByEmail.fulfilled, (state, { payload }) => {
      state.userByEmail = payload.data;
      state.userByEmail.userId = payload.data.users[0]?._id;
      state.userByEmail.status = 'fulfilled';
    });

    builder.addCase(fetchUserByEmail.pending, (state) => {
      state.userByEmail.status = 'loading';
    });

    builder.addCase(fetchUserByEmail.rejected, (state) => {
      state.userByEmail.status = 'error';
    });
  },
});

export const { actions, reducer: RoleReducer } = roleSlice;
