import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders, wrapBaseApi } from 'utils';
import { get } from 'utils/axiosLib';
import { BASE_URL } from 'constant';

const initialState = {
  bills: {
    status: 'idle',
    data: {
      bills: [],
    },
    totalCount: 0,
  },

  airtime: {
    status: 'idle',
    data: {
      bills: [],
    },
    totalCount: 0,
  },
};

export const fetchDataBills = createAsyncThunk('bills/billsData', async ({ limit, page, operatorName, search }) => {
  const response = await get(
    wrapBaseApi(
      `${BASE_URL}/wallet/admin/bills?limit=${limit}&page=${page}&billType=data&metaData.operatorName=${operatorName}&search=${search}`
    ),
    getHeaders()
  );
  return response.data;
});

export const fetchAirtimeBills = createAsyncThunk('bills/airtime', async ({ limit, page, operatorName, search }) => {
  const response = await get(
    wrapBaseApi(
      `${BASE_URL}/wallet/admin/bills?limit=${limit}&page=${page}&billType=airtime&metaData.operatorName=${operatorName}&search=${search}`
    ),
    getHeaders()
  );
  return response.data;
});

const billsSlice = createSlice({
  name: 'bills',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchDataBills.fulfilled, (state, { payload }) => {
      state.bills.status = 'fulfilled';
      state.bills.data = payload.data;
      state.bills.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchDataBills.pending, (state) => {
      state.bills.status = 'loading';
    });

    builder.addCase(fetchDataBills.rejected, (state) => {
      state.bills.status = 'error';
    });

    // Airtime
    builder.addCase(fetchAirtimeBills.fulfilled, (state, { payload }) => {
      state.airtime.status = 'fulfilled';
      state.airtime.data = payload.data;
      state.airtime.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchAirtimeBills.pending, (state) => {
      state.airtime.status = 'loading';
    });

    builder.addCase(fetchAirtimeBills.rejected, (state) => {
      state.airtime.status = 'error';
    });
  },
});

export const { actions, reducer: BillsReducer } = billsSlice;
