import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'utils';
import { get } from 'utils/axiosLib';

const initialState = {
  banks: {
    status: 'idle',
    data: {
      bankPayloads: [],
    },
  },
};

export const fetchBanks = createAsyncThunk('banks/all', async (country) => {
  const response = await get(
    `${process.env.REACT_APP_API_ENDPOINT}/banks?country=${country ? country : 'ng'}`,
    getHeaders()
  );
  return response.data;
});

const bankSlice = createSlice({
  name: 'banks',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchBanks.pending, (state) => {
      state.banks.status = 'loading';
    });

    builder.addCase(fetchBanks.fulfilled, (state, { payload }) => {
      state.banks.status = 'fulfilled';
      state.banks.data = payload.data;
    });

    builder.addCase(fetchBanks.rejected, (state) => {
      state.banks.status = 'error';
    });
  },
});

export const { actions, reducer: banksReducer } = bankSlice;
