import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text, Flex, VStack } from '@chakra-ui/react';
import { Loader } from 'components';
import { fetchP2PChatById } from 'store/slices/p2p.slice';
import { formatDateToUTC } from 'utils';
import { ChatSVG } from 'assets';

const TradeConversation = ({ tradeRefId }) => {
  const dispatch = useDispatch();
  console.log(tradeRefId, 'TRADE REF ID');

  const {
    status,
    data: { chats, allowedParties },
  } = useSelector((state) => state.p2p.chatById);

  useEffect(() => {
    dispatch(fetchP2PChatById(tradeRefId));
  }, [dispatch, tradeRefId]);

  return (
    <Box maxWidth="731px">
      {status === 'loading' && <Loader />}

      {status === 'error' && (
        <Flex mt="55px" gridGap="11px" pl="155px" alignItems="center">
          <Box>
            <ChatSVG />
          </Box>
          <Text fontWeight="500">Chat session not found</Text>
        </Flex>
      )}

      {status === 'fulfilled' && (
        <Box
          border="0.5px solid #CACBCB"
          borderRadius="10px"
          height="650px"
          overflowY={'scroll'}
        >
          <Flex bg="#F1F1F1" borderTopRadius="16px" padding="24px 40px">
            {allowedParties.map(({ _id, username }, i) => {
              return (
                <Text key={_id}>
                  {username}
                  {i === 1 ? '' : ','} &nbsp;
                </Text>
              );
            })}
          </Flex>

          <Box padding="40px 40px 20px">
            {chats.map(({ chatId, message, messageTime, format }) => {
              return (
                <VStack
                  key={chatId}
                  alignItems={format === 'inline' ? 'flex-end' : 'flex-start'}
                  mb="8px"
                >
                  <Box
                    bg={format === 'inline' ? '#F5FEFF' : '#F6FEDE'}
                    padding="16px"
                    borderRadius="16px 0 16px 16px"
                  >
                    <Text fontSize="14px" fontWeight="400" lineHeight="16px">
                      {message}
                    </Text>
                  </Box>

                  <Text as="span" fontSize="10px">
                    {formatDateToUTC(messageTime)}
                  </Text>
                </VStack>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TradeConversation;
