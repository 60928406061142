import { Text } from '@chakra-ui/react';
import SlateImage from './SlateImage';

const Element = (props) => {
  const { attributes, children, element } = props;
  // console.log(element.type, 'ELEMENT');
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote
          style={style}
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul
          style={{ listStyle: 'disc', padding: '0 8px' }}
          {...attributes}
        >
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <Text
          as='h1'
          fontSize='32px'
        >
          {children}
        </Text>
      );
    case 'heading-two':
      return (
        <Text
          as='h2'
          fontSize='24px'
        >
          {children}
        </Text>
      );
    case 'list-item':
      return (
        <li
          style={{ padding: '0 8px' }}
          {...attributes}
        >
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol
          style={{ padding: '0 8px' }}
          {...attributes}
        >
          {children}
        </ol>
      );
    case 'image':
      return <SlateImage {...props} />;

    default:
      return (
        <p
          style={style}
          {...attributes}
        >
          {children}
        </p>
      );
  }
};

export default Element;
