import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Text,
  Center,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { Button, CloseModal, ViewMore } from 'components';
import { useCancelGreenboxMutation } from 'store/services/greenbox.service';

const CancelGreenboxModal = ({ greenBoxId, username }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [_cancelGreenbox, { isLoading }] = useCancelGreenboxMutation();

  const handleCancel = async () => {
    const response = await _cancelGreenbox({
      greenBoxId,
    });

    console.log(response, greenBoxId, 'the response');

    if (response?.error) {
      toast({
        position: 'bottom-right',
        title: 'Error',
        // description: response?.error?.data?.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <ViewMore
        bg='brand.error'
        color='#fff'
        border='none'
        _hover={{
          bg: 'brand.errror',
        }}
        onClick={onOpen}
      >
        Cancel
      </ViewMore>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='480px'
          padding={{ base: '32px 16px', md: '32px 24px' }}
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
            fontFamily='PolySans'
          >
            Cancel Greenbox
          </ModalHeader>

          <ModalBody bg='#fff'>
            <Center flexDir='column'>
              <Text mb='24px'>
                Are you want to cancel{' '}
                <Text
                  as='span'
                  fontWeight='500'
                >
                  {username}
                </Text>
                's Greenbox?
              </Text>

              <Button
                isLoading={isLoading}
                disabled={isLoading}
                onClick={handleCancel}
              >
                Yes
              </Button>
            </Center>
            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelGreenboxModal;
