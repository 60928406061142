import { InputGroup, InputLeftElement } from '@chakra-ui/react';
import React from 'react';
import { SearchSVG } from 'assets';

import { Input as ChakraInput } from '@chakra-ui/react';

const SearchInput = ({
  placeholder,
  onChange,

  ...props
}) => {
  return (
    <InputGroup>
      <ChakraInput
        borderRadius="15px"
        errorBorderColor="brand.red"
        onChange={onChange}
        placeholder={placeholder}
        minH="56px"
        bg="#F9FAF9"
        fontSize="12px"
        paddingLeft="45px"
        variant="filled"
        color="#303131"
        border="1px solid #F9FAF9"
        boxShadow="0px 2px 8px rgba(229, 229, 229, 0.47)"
        _hover={{
          bg: 'rgba(226, 242, 218, 0.3)',
          border: '1px solid #6FBE45',
          transition: 'border 500ms ease-in-out',
        }}
        _active={{
          bg: '#F9FAF9',
          border: '1px solid #6FBE45',
        }}
        _focus={{
          bg: '#F9FAF9',
          border: '1px solid #6FBE45',
        }}
        _placeholder={{
          color: '#303131',
          fontWeight: '400',
          fontSize: '12px',
        }}
        {...props}
      />
      <InputLeftElement
        h="100%"
        mr="12px"
        pl="20px"
        children={<SearchSVG color="#A0A3BD" />}
      />
    </InputGroup>
  );
};

export default SearchInput;
