import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'constant';
import { getHeaders, wrapBaseApi } from 'utils';
import { get } from 'utils/axiosLib';

const initialState = {
  rate: {
    status: 'idle',
    data: {},
    errorMessage: '',
  },
};

export const fetchRates = createAsyncThunk('rate/fiat', async (_, thunkAPI) => {
  const URL = wrapBaseApi(`${BASE_URL}/wallet/rate/fiat-rates`);

  try {
    const response = await get(URL, getHeaders());
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const rateSlice = createSlice({
  name: 'rate',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchRates.fulfilled, (state, { payload }) => {
      const response = payload.data;

      const objArray = [];

      Object.keys(response).forEach((key) =>
        objArray.push({
          pair: key,
          rate: response[key],
          _id: Math.random() * 100000,
        })
      );

      // console.log(arrayOfObj, 'obj');
      state.rate.status = 'fulfilled';
      state.rate.data = objArray;
    });

    builder.addCase(fetchRates.pending, (state) => {
      state.rate.status = 'loading';
    });

    builder.addCase(fetchRates.rejected, (state, action) => {
      state.rate.status = 'error';
      state.rate.errorMessage = action.error.message;
    });
  },
});

export const { reducer: rateReducer } = rateSlice;
