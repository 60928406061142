import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td, useDisclosure, Flex, Text, Box } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import { Loader, ViewMore, PaginationWrapper, ScrollRightContainer, WalletCryptModal, FilterSelect } from 'components';
import { fetchCryptoWalletDepositByUserId } from 'store/slices/wallet.slice';
import { formatDateToUTC } from 'utils';

const CryptoDepositHistory = ({ userId }) => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.wallet.singleCryptoDeposit);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: { currentPage: 1, pageSize: 10 },
    total: data.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedData, setSelectedData] = useState({});
  const [size, setSize] = useState(10);
  const [actionValue, setActionValue] = useState('');
  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const [coinType, setCoinType] = useState('');

  const handleCoinType = (e) => {
    setCoinType(e.target.value);
  };

  const handleOpen = (data) => {
    onOpen();
    setSelectedData(data);
    setActionValue('');
  };

  useEffect(() => {
    dispatch(
      fetchCryptoWalletDepositByUserId({
        userId,
        page: currentPage,
        limit: size,
        unit: coinType,
      })
    );
  }, [coinType, currentPage, dispatch, size, userId]);

  return (
    <>
      {status === 'loading' && <Loader />}

      <Box>
        <Flex
          mb='24px'
          gridGap='10px'
        >
          <FilterSelect
            width='88px'
            onChange={handleSize}
          >
            <option value={10}>Size</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </FilterSelect>

          <FilterSelect
            width='90px'
            onChange={handleCoinType}
            placeholder='Unit'
          >
            <option value='btc'>BTC</option>
            <option value='teth'>ETH</option>
            <option value='cusd'>CUSD</option>
            <option value='usdt'>USDT</option>
            <option value='ceur'>CEUR</option>
            <option value='celo'>CELO</option>
            <option value='xlm'>XLM</option>
            <option value='xrp'>XRP</option>
          </FilterSelect>
        </Flex>
        {status === 'fulfilled' && data.totalCount === 0 && (
          <Text
            fontSize='18px'
            fontWeight='500'
          >
            User has no crypto deposit
          </Text>
        )}

        {(data && data.transactions).length >= 1 && (
          <>
            <ScrollRightContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Name</Th>
                    <Th>Amount</Th>
                    <Th>Date & Time</Th>
                    <Th>Description</Th>
                    <Th>Type</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {data &&
                    data.transactions.map(
                      (
                        {
                          _id,
                          userId,
                          createdAt,
                          addressRef,
                          description,
                          type,
                          unit,
                          value,
                          balanceAfter,
                          balanceBefore,
                        },
                        index
                      ) => {
                        const modalData = {
                          _id,
                          userId,
                          createdAt,
                          addressRef,
                          description,
                          type,
                          unit,
                          value,
                          balanceAfter,
                          balanceBefore,
                        };
                        return (
                          <Tr key={_id}>
                            <Td>{index + 1}</Td>

                            <Td>{userId}</Td>

                            <Td textTransform='uppercase'>
                              {value?.$numberDecimal.toLocaleString()} {unit}
                            </Td>

                            <Td>{formatDateToUTC(createdAt)}</Td>

                            <Td textTransform='capitalize'>{description}</Td>

                            <Td textTransform='capitalize'>{type}</Td>

                            <Td>
                              <ViewMore onClick={() => handleOpen(modalData)}>View more</ViewMore>
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                </Tbody>
              </Table>
            </ScrollRightContainer>

            <PaginationWrapper
              setCurrentPage={setCurrentPage}
              totalDataCount={data.totalCount}
              currentPage={currentPage}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              offset={offset}
              pages={pages}
              size={10}
            />

            <WalletCryptModal
              isOpen={isOpen}
              onClose={onClose}
              data={selectedData}
              actionValue={actionValue}
              currentPage={currentPage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default CryptoDepositHistory;
