import S3 from 'react-aws-s3-typescript';

const config = {
  bucketName: process.env.REACT_APP_DO_BUCKET,
  region: process.env.REACT_APP_DO_SPACES_ENDPOINT,
  accessKeyId: process.env.REACT_APP_DO_SPACES_KEY,
  secretAccessKey: process.env.REACT_APP_DO_SPACES_SECRET,
  s3Url: process.env.REACT_APP_DO_SPACES_URL,
};

const client = new S3(config);

export async function uploadFile(file, filename) {
  return await client.uploadFile(file, filename ?? file.name);
}
