import { useState } from 'react';
import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Button, CloseModal, Input } from 'components';
import { post } from 'utils/axiosLib';
import { getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';

const ProcessStaffWithdrawalModal = ({ data, onProcess, setup, setSetup }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { coin, amount, name, status, address, email, username, network } = data;

  const destinationFormat = {
    address,
    email,
    username,
  };

  const entries = Object.entries(destinationFormat).filter(([_, value]) => value?.toLowerCase() !== 'n/a');

  const toast = useToast();

  const [state, setState] = useState({
    amount,
    reason: setup.statement,
    destinationAddress: address,
    coin,
    destinationCoin: coin,
    tagNumber: '', // OPTIONAL, compulsory for xrp
    memo: '', // OPTIONAL, compulsory for xlm
    network, // OPTIONAL, compulsory for usdts
    destinationFormat: '', // OPTIONAL, compulsory for destinationAddress that is username or email
    pin: setup.pin,
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const URL = wrapBaseApi(`${BASE_URL}/wallet/withdrawal`);

    try {
      const response = await post(
        URL,
        {
          amount: state.amount,
          reason: state.reason,
          destinationAddress: state.destinationAddress?.toLowerCase() === 'n/a' ? 'n/a' : state.destinationAddress,
          coin: state.destinationCoin.toLowerCase(),
          destinationCoin: state.destinationCoin.toLowerCase(),
          tagNumber: state.tagNumber,
          memo: state.memo,
          username: username?.toLowerCase() === 'n/a' ? undefined : username,
          email: email?.toLowerCase() === 'n/a' ? undefined : email,
          network: network?.toLowerCase() === 'n/a' ? undefined : network,
          destinationFormat: entries.length === 0 ? '' : entries[0][0],
          pin: state.pin,
        },
        getHeaders()
      );

      if (response.data) {
        setSetup({
          pin: state.pin,
          statement: state.reason,
        });

        setLoading(false);

        onProcess();
        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  const handleOpen = (e) => {
    if (!setup.pin || !setup.statement) {
      onOpen();
    } else {
      return handleSubmit(e);
    }
  };

  return (
    <>
      {status !== 'processed' ? (
        <Button
          onClick={handleOpen}
          minH='42px'
          bg='rgba(49, 183, 169, 0.2)'
          minW='100px'
          color='brand.black'
          borderRadius='5px'
          fontWeight='400'
        >
          Process
        </Button>
      ) : (
        <Text color='brand.success'>Processed</Text>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          pt='24px'
          pb='24px'
          borderRadius='21px'
          maxWidth='430px'
        >
          <ModalHeader
            fontSize='24px'
            textAlign='center'
          >
            Process Withdrawal {username}
          </ModalHeader>

          <ModalBody>
            <form onSubmit={handleSubmit}>
              <VStack
                gridGap='8px'
                m='20px 0 40px'
              >
                <Box width='100%'>
                  {name && <Text>Name: {name}</Text>}
                  {email && <Text>Email: {email}</Text>}
                  {amount && (
                    <Text>
                      Amount: {amount} {coin}
                    </Text>
                  )}
                  {address && <Text>Address: {address}</Text>}
                </Box>

                <Box width='100%'>
                  <Text
                    display='block'
                    mb='0.5rem'
                    htmlFor='reason'
                    as='label'
                  >
                    Reason
                  </Text>

                  <Textarea
                    placeholder='Reason'
                    onChange={handleChange}
                    name='reason'
                    id='reason'
                    width='100%'
                    borderWidth='1px'
                    height='80px'
                    bg='#F6F8F7'
                    isRequired
                    padding='13px 22px'
                    _focus={{
                      bg: 'rgba(226, 242, 218, 0.3)',
                      border: '1px solid #6FBE45',
                      transition: 'border 500ms ease-in-out',
                    }}
                    _hover={{
                      bg: 'rgba(226, 242, 218, 0.3)',
                      border: '1px solid #6FBE45',
                      transition: 'border 500ms ease-in-out',
                    }}
                  />
                </Box>

                <Input
                  label='Pin'
                  name='pin'
                  width='100%'
                  htmlFor='pin'
                  type='password'
                  onChange={handleChange}
                  isRequired
                />
              </VStack>

              <Center>
                <Button
                  type='submit'
                  minH='52px'
                  minW='365px'
                  disabled={loading || !(state.pin.length === 6) || !(state.reason.length > 2)}
                  isLoading={loading}
                >
                  Submit
                </Button>
              </Center>
            </form>
          </ModalBody>

          <CloseModal onClick={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProcessStaffWithdrawalModal;
