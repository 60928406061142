import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { HistoryTab, Loader, PartnerRewardHistory, PartnersWalletCard } from 'components';
import TetherPNG from 'assets/png/usdt.png';
import XrpPNG from 'assets/png/xrp.png';
import CeloPNG from 'assets/png/celo.png';
import BtcPNG from 'assets/png/btc.png';
import EthPNG from 'assets/png/eth.png';
import { fetchEarningSummary } from 'store/slices/partners.slice';

const PartnersDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data, status, errorMessage } = useSelector((state) => state.partners.earningSummary);

  useEffect(() => {
    dispatch(fetchEarningSummary(id));
  }, [dispatch, id]);

  return (
    <Box>
      <HistoryTab
        firstText='Partners'
        secondText='Reward History'
        url='/dashboard/partners'
      />

      <Flex
        gridGap='40px'
        flexDir={{ base: 'column', tb: 'row' }}
      >
        <Box
          bg='#FCFDFD'
          boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
          borderRadius='10px'
          padding='38px 46px 38px 29px'
          maxW='543px'
          width='100%'
        >
          <Text
            fontSize='18px'
            lineHeight='22px'
            fontWeight='600'
            mb='32px'
          >
            Wallet
          </Text>

          {status === 'error' && <p>{errorMessage}</p>}

          {status === 'loading' && <Loader />}

          <>
            {data?.combinedEarns?.tbtc && (
              <PartnersWalletCard
                coinSrc={BtcPNG}
                align='left'
                coin='btc'
                amount={Number(data?.combinedEarns?.tbtc).toFixed(8)}
                padding='21px 44px'
              />
            )}

            {data?.combinedEarns?.btc && (
              <PartnersWalletCard
                coinSrc={BtcPNG}
                align='left'
                coin='btc'
                amount={Number(data?.combinedEarns?.btc).toFixed(8)}
                padding='21px 44px'
              />
            )}

            {data?.combinedEarns?.celo && (
              <PartnersWalletCard
                coinSrc={CeloPNG}
                align='left'
                coin='celo'
                amount={Number(data?.combinedEarns?.celo).toFixed(4)}
                padding='21px 44px'
              />
            )}

            {data?.combinedEarns?.teth && (
              <PartnersWalletCard
                coinSrc={EthPNG}
                align='left'
                coin='eth'
                amount={Number(data?.combinedEarns?.teth).toFixed(8)}
                padding='21px 44px'
              />
            )}

            {data?.combinedEarns?.eth && (
              <PartnersWalletCard
                coinSrc={EthPNG}
                align='left'
                coin='eth'
                amount={Number(data?.combinedEarns?.eth).toFixed(8)}
                padding='21px 44px'
              />
            )}

            {data?.combinedEarns?.xrp && (
              <PartnersWalletCard
                coinSrc={XrpPNG}
                align='left'
                coin='xrp'
                amount={Number(data?.combinedEarns?.xrp).toFixed(8)}
                padding='21px 44px'
              />
            )}

            {data?.combinedEarns?.xlm && (
              <PartnersWalletCard
                coinSrc={TetherPNG}
                align='left'
                coin='xlm'
                amount={Number(data?.combinedEarns?.xlm).toFixed(8)}
                padding='21px 44px'
              />
            )}

            {data?.combinedEarns?.cusd && (
              <PartnersWalletCard
                coinSrc={TetherPNG}
                align='left'
                coin='cusd'
                amount={Number(data?.combinedEarns?.cusd).toFixed(4)}
                padding='21px 44px'
              />
            )}

            {data?.combinedEarns?.usdt && (
              <PartnersWalletCard
                coinSrc={TetherPNG}
                align='left'
                coin='usdt'
                amount={Number(data?.combinedEarns?.usdt).toFixed(4)}
                padding='21px 44px'
              />
            )}
          </>
        </Box>

        <Box
          bg='#FCFDFD'
          boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
          borderRadius='10px'
          padding='38px 29px 38px 27px'
          maxW='487px'
          width='100%'
        >
          <Text
            fontSize='18px'
            lineHeight='22px'
            fontWeight='600'
            mb='28px'
          >
            Reward History
          </Text>

          <PartnerRewardHistory />
        </Box>
      </Flex>
    </Box>
  );
};

export default PartnersDetails;
