import { Modal, ModalOverlay, ModalContent, ModalHeader, Box, Text, Flex } from '@chakra-ui/react';
import { CloseModal } from 'components';
import { formatDateToUTC } from 'utils';

const PartnerRewardModal = ({ isOpen, onClose, data }) => {
  const { firstName, lastName, kycLevel, registeredOn, transactionsCount } = data;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius='21px'
        padding={{ base: '40px 16px', tb: '45px 52px 40px' }}
        maxWidth='568px'
        width='100%'
      >
        <ModalHeader
          fontWeight='600'
          fontSize='24px'
          textAlign='center'
          mb='28px'
        >
          Earning Details
        </ModalHeader>

        <Box>
          <Flex
            justifyContent='space-between'
            borderBottom='0.5px solid #CACBCB'
            padding={'8px 0 10px'}
            mb='29px'
          >
            <Text>Name</Text>

            <Text fontWeight='500'>
              {firstName} {lastName}
            </Text>
          </Flex>

          <Flex
            borderBottom='0.5px solid #CACBCB'
            padding={'8px 0 10px'}
            mb='29px'
            justifyContent='space-between'
          >
            <Text>Email</Text>

            <Text fontWeight={'500'}>{'email@gmail.com'}</Text>
          </Flex>

          <Flex
            borderBottom='0.5px solid #CACBCB'
            padding={'8px 0 10px'}
            mb='29px'
            justifyContent='space-between'
          >
            <Text>Transaction count</Text>

            <Text fontWeight={'500'}>{transactionsCount}</Text>
          </Flex>

          <Flex
            borderBottom='0.5px solid #CACBCB'
            padding={'8px 0 10px'}
            mb='29px'
            justifyContent='space-between'
          >
            <Text>Verification status</Text>

            <Text
              fontWeight={'500'}
              textTransform='capitalize'
            >
              {kycLevel}
            </Text>
          </Flex>

          <Flex
            borderBottom='0.5px solid #CACBCB'
            padding={'8px 0 10px'}
            mb='35px'
            justifyContent='space-between'
          >
            <Text>Date</Text>

            <Text fontWeight={'500'}>{formatDateToUTC(registeredOn)}</Text>
          </Flex>

          <CloseModal onClick={onClose} />
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default PartnerRewardModal;
