import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Text, FormLabel, FormControl, Select, VStack, useToast, Center } from '@chakra-ui/react';
import { Button, Input, Loader, HistoryTab, FilterSelect } from 'components';
import { fetchRoles, fetchUserByEmail } from 'store/slices/roles.slice';
import { getHeaders, validateEmail } from 'utils';
import { put } from 'utils/axiosLib';
import { BASE_URL } from 'constant';
import { useDebounce } from 'hooks';

const AddAdmin = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    roles,
    userByEmail: { users, status, userId },
  } = useSelector((state) => state.roles);

  const [validEmail, setValidEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [level, setLevel] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');

  const handleLevel = (event) => {
    setLevel(event.target.value);
  };

  const handleRole = (event) => {
    setRole(event.target.value);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setEmail(query);

    const validate = validateEmail(query);

    setValidEmail(validate);

    if (validate) {
      dispatch(
        fetchUserByEmail({
          email: query,
        })
      );
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await put(
        `${BASE_URL}/admin/assign/role/${userId}`,
        {
          group: role,
          level,
        },
        getHeaders()
      );

      if (response) {
        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        navigate('/dashboard/account');
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  return (
    <>
      <HistoryTab
        firstText='Administrators'
        secondText='Add Admin'
        url='/dashboard/account'
      />

      <Box
        bg='#FCFDFD'
        padding={{ base: '24px', md: '72px' }}
        borderRadius='10px'
        maxWidth='666px'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
        width='100%'
      >
        <VStack
          maxWidth='523px'
          width='100%'
          margin='0 auto'
          gridGap='31px'
          as='form'
          onSubmit={handleSubmit}
        >
          <FormControl>
            <FormLabel fontWeight={'400'}>Full name</FormLabel>
            <Input
              width='100%'
              name='fullName'
              value={users.length === 0 ? 'Full name' : `${users[0]?.firstName} ${users[0]?.lastName}`}
              onChange={() => {}}
              type='text'
              placeholder='Enter full name'
              padding='13px 22px'
              disabled
              _disabled={{
                color: '#A1A3A2',
                fontWeight: 500,
                cursor: 'not-allowed',
              }}
              _hover={{
                borderColor: 'none',
                border: 'none',
              }}
              _focus={{
                borderColor: 'none',
                border: 'none',
              }}
              _active={{
                borderColor: 'none',
                border: 'none',
              }}
            />
            <Text color='brand.error'>{userId === undefined && status === 'fulfilled' && 'User not found'}</Text>
            {status === 'loading' && <Loader />}
          </FormControl>

          <FormControl>
            <FormLabel fontWeight={'400'}>Email</FormLabel>
            <Input
              width='100%'
              name='email'
              type='email'
              required
              onChange={debouncedChangeHandler}
              placeholder='Enter email'
              padding='13px 22px'
              _focus={{
                borderColor: '#06C3448f',
              }}
            />

            <Text color='brand.error'>{validEmail === false && email.length >= 1 && 'Input a valid email'}</Text>
          </FormControl>

          <FormControl>
            <FormLabel fontWeight={'400'}>Role</FormLabel>

            <FilterSelect
              placeholder='Select role'
              onChange={handleRole}
              height='60px'
              required
              backgroundColor={'#F6F8F7'}
              width='100%'
              borderRadius={'15px'}
              _hover={{
                backgroundColor: '#F6F8F7',
              }}
            >
              <option value='super-admin'>Super Admin</option>
              <option value='finance'>Finance</option>
              <option value='customer-care'>Customer care</option>
              <option value='staff'>Staff</option>
            </FilterSelect>
          </FormControl>

          <FormControl>
            <FormLabel fontWeight={'400'}>Level</FormLabel>

            <Select
              placeholder='Select level'
              onChange={handleLevel}
              height='60px'
              backgroundColor={'#F6F8F7'}
              required
              width='100%'
              borderRadius={'15px'}
              _hover={{
                backgroundColor: '#F6F8F7',
              }}
            >
              {roles.roles.map(({ level, _id }) => {
                return (
                  <option
                    value={level}
                    key={_id}
                  >
                    {level}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          <Center width='100%'>
            <Button
              type='submit'
              mt='62px'
              minW={{ base: '100%', md: '399px' }}
              isLoading={loading}
              disabled={!(validEmail && level.length >= 3 && role.length >= 3) || loading}
            >
              Save
            </Button>
          </Center>
        </VStack>
      </Box>
    </>
  );
};

export default AddAdmin;
