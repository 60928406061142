import { Box } from '@chakra-ui/react';
import { PageTab, PageTitle, TableBoxWrapper } from 'components';
import { useLocationSearch } from 'hooks';
import { BcplTabLinkArray } from 'LinkArray/tabArray';
import {
  BcplAllTransactions,
  BcplRequest,
  BcplDefaultedTransactions,
} from 'pages';

const BCPL = () => {
  const search = useLocationSearch();
  return (
    <Box>
      <PageTitle title="Buy Crypto, Pay Later" mb="52px" />

      <TableBoxWrapper>
        <PageTab mb="36px" linkArray={BcplTabLinkArray} />

        <Box>
          {search === '' && <BcplRequest />}
          {search === '?all-transactions' && <BcplAllTransactions />}
          {search === '?defaulted-transactions' && (
            <BcplDefaultedTransactions />
          )}
        </Box>
      </TableBoxWrapper>
    </Box>
  );
};

export default BCPL;
