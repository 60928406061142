import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  useDisclosure,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal, Input, Select } from 'components';
import { patch } from 'utils/axiosLib';
import { fetchRates } from 'store/slices/rate.slice';
import { getHeaders, wrapBaseApi } from 'utils';

const RateModal = ({ modalData, dispatch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pair, rate } = modalData;
  const [selected, setSelected] = useState('');
  const [loading, setLoading] = useState('');
  const [state, setState] = useState({});

  const toast = useToast();

  const headers = getHeaders();

  const handleOpen = (e) => {
    setSelected(e.target.value);
    onOpen();
    setState({});
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const URL = wrapBaseApi(`${process.env.REACT_APP_API_ENDPOINT}/wallet/rate/fiat-update?ticker=${pair}`);

      const response = await patch(
        URL,
        {
          sellRate: state.sell ? state.sell : undefined,
          buyRate: state.buy ? state.buy : undefined,
        },
        headers
      );

      if (response.data) {
        setLoading(false);

        dispatch(fetchRates());

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        onClose();
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Error',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Select
        onChange={handleOpen}
        placeholder='Update'
        width='110px'
      >
        <option value='all'>Update All</option>
        <option value='buy'>Update Buy</option>
        <option value='sell'>Update Sell</option>
      </Select>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='420px'
          padding='32px 0'
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
          >
            Update{' '}
            <Text
              as='span'
              textTransform='uppercase'
            >
              {pair}
            </Text>{' '}
            pair
          </ModalHeader>

          <ModalBody bg='#fff'>
            <form onSubmit={handleSubmit}>
              <VStack gridGap='24px'>
                {(selected === 'all' || selected === 'buy') && (
                  <Input
                    type='number'
                    label='Buy rate'
                    id='buy'
                    width='100%'
                    minHeight='58px'
                    name='buy'
                    placeholder='Buy rate'
                    defaultValue={rate.buy}
                    onChange={handleChange}
                    step='any'
                  />
                )}

                {(selected === 'all' || selected === 'sell') && (
                  <Input
                    type='number'
                    label='Sell rate'
                    id='sell'
                    width='100%'
                    minHeight='58px'
                    name='sell'
                    placeholder='Sell rate'
                    defaultValue={rate.sell}
                    onChange={handleChange}
                    step='any'
                  />
                )}

                <Button
                  type='submit'
                  minH='50px'
                  disabled={loading}
                  isLoading={loading}
                >
                  Submit
                </Button>
              </VStack>
            </form>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RateModal;
