import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  useToast,
  Center,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { Button, CloseModal, Input, Select } from 'components';
import { useEffect } from 'react';
import { useState } from 'react';
import { useUpdateRequestMutation } from 'store/services/request.service';

const UpdateRequestModal = ({ requestId }) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [denyReason, setDenyReason] = useState('');
  const [status, setStatus] = useState('');

  const handleChange = (event) => {
    const value = event.target.value;

    setStatus(value);

    value && onOpen();
  };

  const [_updateRequest, { isLoading, error }] = useUpdateRequestMutation();

  const handleUpdate = async () => {
    const response = await _updateRequest({
      requestId,
      status,
      denyReason,
    });

    if (response.data) {
      onClose();

      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response.data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    error &&
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.data.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
  }, [toast, error]);

  return (
    <>
      <Select minW="100px" onChange={handleChange} placeholder="Update">
        <option value="approved">Approve</option>
        <option value="denied">Deny</option>
        <option value="cancelled">Cancell</option>
        <option value="in-review">In Review</option>
      </Select>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="16px" maxWidth="420px">
          <ModalHeader padding="32px 0" fontWeight="600" textAlign="center">
            Update Request
          </ModalHeader>

          <ModalBody>
            <Center>
              <Text>
                Are you sure you want to "
                <Text as="span" fontWeight="500">
                  {status}
                </Text>
                " this request?
              </Text>
            </Center>

            {status === 'denied' && (
              <Input
                placeholder="Deny reason"
                onChange={(e) => setDenyReason(e.target.value)}
                type="text"
                required
                mt="16px"
              />
            )}

            <Center mt="32px">
              <Button
                isLoading={isLoading}
                disabled={
                  isLoading || (status === 'denied' && denyReason.length < 5)
                }
                onClick={handleUpdate}
              >
                Submit
              </Button>
            </Center>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateRequestModal;
