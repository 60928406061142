import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { BoxInput, ChangeKycLevel, DisableKycUpload, EditUserModal, Loader } from 'components';
import { formatDateToUTC, getHeaders } from 'utils';
import { useParams } from 'react-router-dom';
import { useSetUserStorage } from 'hooks';

const UserInformation = ({ data, status }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const headers = getHeaders();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    email,
    fullName,
    phone,
    username,
    kycLevel,
    country,
    emailVerified,
    phoneVerified,
    twoFactor,
    createdAt,
    firstName,
    lastName,
    acceptPromotions,
  } = data;

  useSetUserStorage(id, fullName);

  return (
    <>
      <Box minH='515px'>
        {status === 'loading' && <Loader />}
        {status === 'pending' && <Loader />}
        {status === 'fulfilled' && (
          <Flex
            justifyContent='space-between'
            flexDir={{ base: 'column', tb: 'row' }}
          >
            <Box>
              <BoxInput
                label='Full Name'
                value={String(fullName).toLowerCase()}
                textTransform='capitalize'
              >
                <Text
                  color='#6FBE45'
                  fontSize='14px'
                  fontWeight='500'
                  lineHeight='19px'
                  cursor='pointer'
                  onClick={onOpen}
                >
                  Edit user
                </Text>
              </BoxInput>

              <BoxInput
                label='Username'
                value={username}
                textTransform='lowercase'
              />

              <BoxInput
                label='Email'
                value={email}
                textTransform='lowercase'
              />

              <BoxInput
                label='Phone'
                value={phone}
              />

              <BoxInput
                label='Country'
                value={country}
                textTransform='capitalize'
              />
            </Box>

            <Box>
              <BoxInput
                label='Email Verification Status'
                value={emailVerified ? 'Verified' : 'Not Verified'}
              />

              <BoxInput
                label='Phone Verification Status'
                value={phoneVerified ? 'Verified' : 'Not Verified'}
              />

              <BoxInput
                label='Two-Factor Auth Status'
                value={twoFactor ? 'Active' : 'Not Active'}
              />

              <BoxInput
                label='Joined'
                value={formatDateToUTC(createdAt)}
              />

              <BoxInput
                padding='0px 14px 19px'
                label='KYC Level'
                value={`Level ${kycLevel}`}
                textTransform='capitalize'
              >
                <ChangeKycLevel
                  headers={headers}
                  id={id}
                  kycLevel={kycLevel}
                  dispatch={dispatch}
                />
              </BoxInput>

              <DisableKycUpload
                data={data}
                headers={headers}
              />
            </Box>
          </Flex>
        )}
      </Box>

      <EditUserModal
        isOpen={isOpen}
        onClose={onClose}
        country={country}
        firstName={firstName}
        lastName={lastName}
        headers={headers}
        id={id}
        dispatch={dispatch}
        acceptPromotions={acceptPromotions}
        phone={phone}
      />
    </>
  );
};

export default UserInformation;
