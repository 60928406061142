import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { ActionButton } from 'components';
import { put } from 'utils/axiosLib';
import { fetchAllCards } from 'store/slices/card.slice';
import { getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';

const ReimburseCardTransaction = ({ _id, searchString }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const headers = getHeaders();

  const reimburseCardDeduction = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/cards/${_id}/reimburse`);

    try {
      const response = await put(URL, {}, headers);

      if (response.data) {
        dispatch(
          fetchAllCards({
            page: 1,
            limit: 10,
            search: searchString,
          })
        );

        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <ActionButton
        disabled={loading}
        isLoading={loading}
        onClick={reimburseCardDeduction}
      >
        Rebimburse
      </ActionButton>
    </>
  );
};

export default ReimburseCardTransaction;
