import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Text, Flex, Avatar, Heading } from '@chakra-ui/react';
import { Loader } from 'components';
import { fetchP2PContractsDispute } from 'store/slices/p2p.slice';
import { formatTimeToUTC } from 'utils';

const P2PDisputeCard = () => {
  const dispatch = useDispatch();
  const {
    contractsDispute: { data, status },
  } = useSelector((state) => state.p2p);

  useEffect(() => {
    dispatch(
      fetchP2PContractsDispute({
        limit: 8,
      })
    );
  }, [dispatch]);

  return (
    <Box
      mt='33px'
      width='272px'
      mx='auto'
      boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
      borderRadius='10px'
      border='0.5px solid #CACBCB'
      height='715px'
      bg='#fff'
      padding='17px 8px'
    >
      <Flex
        justifyContent='space-between'
        mb='20px'
      >
        <Heading
          color='#797A7A'
          as='h2'
          fontSize='1rem'
        >
          Disputes
        </Heading>

        <Link
          to='/dashboard/p2p/all-disputes'
          style={{ color: '#6FBE45', fontSize: '13px' }}
        >
          View All
        </Link>
      </Flex>

      {status === 'loading' && <Loader />}

      {data &&
        data.trades.slice(0, 7).map(({ counterParties, _id, tradeRef, createdAt }) => {
          const username = counterParties[0].user.username;
          const formattedCreatedAt = formatTimeToUTC(createdAt);

          return (
            <Link
              key={_id}
              to={`/dashboard/p2p/dispute/${tradeRef}`}
            >
              <Flex
                justifyContent='space-between'
                borderRadius='5px'
                bg='#FBFBFB'
                padding='9px 12px 9px 14px'
                width='100%'
                key={_id}
                mb='4px'
              >
                <Flex gridGap='8px'>
                  <Box height='26px'>
                    <Avatar
                      width='26px'
                      height='26px'
                    />
                  </Box>

                  <Flex flexDir='column'>
                    <Text
                      fontSize='12px'
                      fontWeight='500'
                      textTransform='capitalize'
                      mb='2px'
                    >
                      {username}
                    </Text>

                    <Text
                      alignSelf='baseline'
                      color='brand.black'
                      fontSize='12px'
                      maxWidth='158px'
                      width='100%'
                    >
                      {tradeRef}
                    </Text>
                  </Flex>
                </Flex>

                <Text
                  color='brand.darkGray'
                  fontSize='10px'
                  fontWeight='500'
                >
                  {formattedCreatedAt}
                </Text>
              </Flex>
            </Link>
          );
        })}
    </Box>
  );
};

export default P2PDisputeCard;
