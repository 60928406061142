import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ls, constant, wrapBaseApi } from 'utils';

const auth = ls.get(constant.AUTH_TOKEN_KEY);

export const payApi = createApi({
  reducerPath: 'pay',
  refetchOnMountOrArgChange: true,
  tagTypes: ['pay'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, _) => {
      headers.set('Content-Type', 'application/json');
      headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: ({ page, limit, paymentStatus }) => ({
        url: wrapBaseApi(
          `/wallet/widget/admin/payments/all?page=${page}&limit=${limit}&paymentStatus=${paymentStatus}`
        ),
        method: 'GET',
        headers: {
          authorization: auth.token,
        },
      }),
      transformResponse: (response) => response.data,
      providesTags: ['pay'],
    }),

    updatePayment: builder.mutation({
      query: ({ paymentStatus, sendWebhook, amountReceived, paymentId }) => ({
        url: wrapBaseApi(`/wallet/widget/admin/payments/${paymentId}`),
        method: 'POST',
        body: {
          paymentStatus,
          sendWebhook,
          amountReceived,
        },
        headers: {
          authorization: auth.token,
        },
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['pay'],
    }),
  }),
});

export const { useGetPaymentsQuery, useUpdatePaymentMutation } = payApi;

export default payApi;
