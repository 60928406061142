import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ls, constant } from 'utils';

const auth = ls.get(constant.AUTH_CICO_TOKEN_KEY);

export const cicoApi = createApi({
  reducerPath: 'cico',
  refetchOnMountOrArgChange: true,
  tagTypes: ['kyc', 'transfers'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_CICO_ENDPOINT}`,
    prepareHeaders: (headers, _) => {
      headers.set('Content-Type', 'application/json');
      headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Auth
    requestAccessPair: builder.mutation({
      query: ({ email }) => ({
        url: `/admin/request-login`,
        method: 'POST',
        body: {
          email,
          validity: '4h',
        },
      }),
      transformResponse: (response) => response,
    }),

    login: builder.mutation({
      query: ({ email, accessPair, accessCode }) => ({
        url: `/admin/login`,
        method: 'POST',
        body: {
          email,
          accessPair,
          accessCode,
        },
      }),
      transformResponse: (response) => response,
    }),

    // KYC
    getAllKyc: builder.query({
      query: ({ page, status, search, level }) => ({
        url: `/kyc/all?page=${page}&limit=10&documentStatus=${status}&search=${search}&level=${level}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${auth}`,
        },
      }),
      transformResponse: (response) => response,
    }),

    getKycByID: builder.query({
      query: (id) => ({
        url: `/kyc/doc/${id}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${auth}`,
        },
      }),
      transformResponse: (response) => response,
    }),

    updateKycStatus: builder.mutation({
      query: ({ kycStatus, id }) => ({
        url: `/kyc/${id}/updateKycStatus`,
        method: 'PATCH',
        body: {
          kycStatus,
          sendWebhook: true,
          kycSchema: 'PersonalDataAndDocuments',
        },
      }),
      transformResponse: (response) => response,
    }),

    // Transfer
    getTransfer: builder.query({
      query: ({ page, limit, transferStatus, queries }) => ({
        url: `/transfer/all?page=${page}&limit=${limit}${transferStatus ? `&transferStatus=${transferStatus}` : ''}${
          queries ? `&${queries}` : ''
        }`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${auth}`,
        },
      }),
      transformResponse: (response) => response,
      providesTags: ['transfers'],
    }),

    updateTransferStatus: builder.mutation({
      query: ({ transferStatus, id, direction, txHash }) => ({
        url: `/transfer/${id}/update-status`,
        method: 'PATCH',
        body: {
          id,
          transferStatus,
          direction,
          txHash,
          // sendWebhook,
        },
        headers: {
          authorization: `Bearer ${auth}`,
        },
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['transfers'],
    }),

    generateTransferReport: builder.mutation({
      query: (body) => ({
        url: `/transfer/reports?sortBy=createdAt:asc`,
        method: 'POST',
        body: {
          ...body,
        },
        headers: {
          authorization: `Bearer ${auth}`,
        },
      }),
      transformResponse: (response) => response,
    }),

    processPayout: builder.mutation({
      query: ({ transferStatus, id, direction, txHash }) => ({
        url: `/transfer/${id}/process-payout`,
        method: 'PATCH',
        body: {},
        headers: {
          authorization: `Bearer ${auth}`,
        },
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['transfers'],
    }),
  }),
});

export const {
  useRequestAccessPairMutation,
  useLoginMutation,
  useGetAllKycQuery,
  useGetKycByIDQuery,
  useUpdateKycStatusMutation,
  useGetTransferQuery,
  useUpdateTransferStatusMutation,
  useGenerateTransferReportMutation,
  useProcessPayoutMutation,
} = cicoApi;

export default cicoApi;
