import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Menu, Text, MenuButton, MenuItem, MenuList, useToast, Box, useDisclosure, Flex } from '@chakra-ui/react';
import { MdUpdate } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import { put } from 'utils/axiosLib';
import { fetchAllWithdrawal } from 'store/slices/wallet.slice';
import { getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';

const UpdateWithdrawalStatus = ({ _id, txStatus, withdrawalType }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const headers = getHeaders();

  const processTransaction = async () => {
    setLoading(true);
    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/withdrawal/${_id}/update-status`);

    try {
      const response = await put(
        URL,
        {
          newStatus: 'processing',
        },
        headers
      );

      if (response.data) {
        setLoading(false);
        dispatch(
          fetchAllWithdrawal({
            page: 1,
            limit: 10,
            withdrawalType,
            status: txStatus,
          })
        );

        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };
  return (
    <Box mt='8px'>
      <Menu
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
      >
        <MenuButton>
          <MdUpdate
            cursor='pointer'
            fontSize='1.2rem'
          />
        </MenuButton>

        <MenuList
          minWidth='100px'
          borderRadius='6px'
          padding='1rem'
          boxShadow='0px 20px 60px rgba(19, 10, 46, 0.25)'
        >
          <Flex
            mb='0.5rem'
            alignItems='center'
            gridGap='0.5rem'
          >
            <Text>Change Status to:</Text>

            <IoMdClose
              cursor='pointer'
              fontSize='1.2rem'
              onClick={onClose}
            />
          </Flex>

          <MenuItem
            color='#19181A'
            bg='#EFF0F7'
            padding='0.5rem 1rem'
            border='1px solid #19181A'
            borderRadius='8px'
            onClick={processTransaction}
            isDisabled={loading}
            textAlign='center'
            fontWeight='600'
            _hover={{
              border: '1px solid #19181A',
            }}
          >
            {loading ? 'Loading...' : 'Processing'}
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default UpdateWithdrawalStatus;
