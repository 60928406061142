import { Table, Thead, Tbody, Tr, Th, Td, Box, Center } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { formatDateToUTC } from 'utils';
import { CurrencyLogo, Loader, P2PTableStatus, ScrollRightContainer } from 'components';

const P2PCompletedTable = ({ tradeData }) => {
  const { data, status } = tradeData;

  return (
    <Box>
      {status === 'loading' && <Loader />}

      <ScrollRightContainer maxWidth={{ sm: '768px', md: '619px', lg: '1250px' }}>
        {data.length >= 1 && (
          <Table>
            <Thead>
              <Tr>
                <Th>User</Th>
                <Th>Amount</Th>
                <Th>Type</Th>
                <Th>Coin traded</Th>
                <Th>Status</Th>
                <Th>Time</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data
                .slice(0, 10)
                .map(({ _id, counterParty, coin, currencyValue, currency, createdAt, paymentStatus }) => {
                  const formattedCreatedAt = formatDateToUTC(createdAt);

                  return (
                    <Tr key={_id}>
                      <Td textTransform='capitalize'>{counterParty ? counterParty.username : 'N/A'} </Td>

                      <Td textTransform='uppercase'>
                        <CurrencyLogo currency={currency} />{' '}
                        {currencyValue ? `${Number(currencyValue).toLocaleString()}` : 'N/A'}
                      </Td>

                      <Td textTransform='capitalize'>{counterParty ? counterParty?.role : 'N/A'}</Td>

                      <Td textTransform='uppercase'>{coin ? coin : 'N/A'}</Td>

                      <Td textTransform='capitalize'>
                        <P2PTableStatus status={paymentStatus} />
                      </Td>

                      <Td whiteSpace='break-spaces'>{formattedCreatedAt}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        )}
      </ScrollRightContainer>

      <Center
        mt='55px'
        fontSize='14px'
        color='#0D4740'
        fontWeight='500'
        textDecor='underline'
        fontFamily='PolySans'
        lineHeight='17px'
      >
        <Link to='/dashboard/p2p/trades'>See More</Link>
      </Center>
    </Box>
  );
};

export default P2PCompletedTable;
