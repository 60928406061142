import { Heading, VStack } from '@chakra-ui/react';
import { Link, NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <VStack justifyContent="center" alignItems="center" height="80vh">
      <Heading>Not found</Heading>

      <NavLink to={-1}>Go back</NavLink>

      <Link to="/dashboard">Home</Link>
    </VStack>
  );
};

export default NotFound;
