import { Box } from '@chakra-ui/react';
import { PageTab, PageTitle, TableBoxWrapper } from 'components';
import { useLocationSearch } from 'hooks';
import { CardTabLinkArray } from 'LinkArray';
import { CardTransactions, CardTransactionsLimit, PhysicalCards } from 'pages';

const Cards = () => {
  const search = useLocationSearch();
  return (
    <Box>
      <PageTitle title="Cards" mb="52px" />

      <TableBoxWrapper>
        <PageTab mb="36px" linkArray={CardTabLinkArray} />

        <Box>
          {search === '' && <CardTransactions />}
          {search === '?transactions-limit' && <CardTransactionsLimit />}
          {search === '?physical-cards' && <PhysicalCards />}
        </Box>
      </TableBoxWrapper>
    </Box>
  );
};

export default Cards;
