import { Center, Box, Text, Heading } from '@chakra-ui/react';

const StatCard = ({ icon, count, title }) => {
  return (
    <Center
      maxWidth='256px'
      flexDirection='column'
      width='100%'
      padding='17px 14px'
      borderRadius='10px'
      bg='#fff'
      boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
    >
      <Box>{icon}</Box>

      <Text
        color='#797A7A'
        fontWeight='400'
        fontSize='14px'
        lineHeight='19px'
      >
        {title}
      </Text>

      <Heading
        margin='8px 0'
        fontSize='28px'
        fontWeight='700'
        lineHeight='34px'
      >
        {count}
      </Heading>
    </Center>
  );
};

export default StatCard;
