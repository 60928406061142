import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination } from '@ajna/pagination';
import { Table, Thead, Tr, Th, Tbody, Td, Text } from '@chakra-ui/react';
import {
  Loader,
  PaginationWrapper,
  PauseReverseLienModal,
  ScrollRightContainer,
  TableDataStatus,
} from 'components';
import { formatDateToUTC } from 'utils';
import { fetchLiens } from 'store/slices/liens.slice';

const UserLien = ({ userId }) => {
  const dispatch = useDispatch();

  const { data, status, totalCount } = useSelector(
    (state) => state.liens.liens
  );

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize: 10 },
      total: totalCount,
      limits: {
        inner: 2,
        outer: 1,
      },
    });

  useEffect(() => {
    dispatch(
      fetchLiens({
        id: userId,
        page: currentPage,
        limit: 10,
      })
    );
  }, [currentPage, dispatch, userId]);

  return (
    <>
      {status === 'loading' && <Loader />}

      {status === 'fulfilled' && totalCount === 0 && (
        <Text fontSize="18px" fontWeight="500">
          User has no lien
        </Text>
      )}

      {(data && data).length >= 1 && (
        <>
          <ScrollRightContainer>
            <Table fontSize="12px">
              <Thead>
                <Tr>
                  <Th>Lien Description</Th>
                  <Th>Amount</Th>
                  <Th>Amount Charged</Th>
                  <Th>Captured By</Th>
                  <Th>Status</Th>

                  <Th>Date</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.map(
                    ({
                      _id,
                      amount,
                      userDetails,
                      capturedBy,
                      status,
                      unit,
                      description,
                      createdAt,
                      tag,
                      amountCharged,
                    }) => {
                      const modalData = {
                        _id,
                        amount,
                        userDetails,
                        capturedBy,
                        status,
                        unit,
                        description,
                        createdAt,
                        tag,
                        amountCharged,
                      };
                      return (
                        <Tr key={_id}>
                          <Td textTransform="lowercase">{description}</Td>

                          <Td textTransform="uppercase">
                            {Number(amount?.$numberDecimal)} {unit}
                          </Td>
                          <Td textTransform="uppercase">
                            {Number(amountCharged?.$numberDecimal)} {unit}
                          </Td>

                          <Td textTransform="capitalize">
                            {capturedBy?.fullName}
                          </Td>

                          <Td>
                            <TableDataStatus status={status} />
                          </Td>

                          <Td>{formatDateToUTC(createdAt).split(',')[0]}</Td>

                          <Td>
                            <PauseReverseLienModal
                              id={userId}
                              data={modalData}
                            />
                          </Td>
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table>
          </ScrollRightContainer>

          <PaginationWrapper
            setCurrentPage={setCurrentPage}
            totalDataCount={totalCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            pagesCount={pagesCount}
            offset={offset}
            pages={pages}
            size={10}
          />
        </>
      )}
    </>
  );
};

export default UserLien;
