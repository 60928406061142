import { useCallback, useState } from 'react';
import { createEditor } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import { Box } from '@chakra-ui/react';
import { Element, Leaf } from 'components';
import { useInitialValue } from 'hooks/useInitialValue';

const SlateViewer = ({ value, id, ...props }) => {
  const [editor] = useState(() => withReact(createEditor()));
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  // const parsedNote = (note) => JSON.parse(note);

  // const initialValue = useMemo(() =>
  //     JSON.parse(localStorage.getItem('content')) || [
  //       {
  //         type: 'paragraph',
  //         children: [{ text: 'A line of text in a paragraph.' }],
  //       },
  //     ],[]);

  const initialValue = useInitialValue(value);

  console.log(initialValue, 'HELLO THERE');

  return (
    <Box
      mb='8px'
      {...props}
    >
      <Slate
        // initialValue={parsedNote(value)}
        initialValue={initialValue}
        editor={editor}
        key={id}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly
        />
      </Slate>
    </Box>
  );
};

export default SlateViewer;
