import { useEffect, useState } from 'react';
import { usePagination } from '@ajna/pagination';
import {
  Box,
  Table,
  Td,
  Tr,
  Thead,
  Th,
  Tbody,
  Text,
  Flex,
  useDisclosure,
  Center,
} from '@chakra-ui/react';
import {
  HistoryTab,
  ListingModal,
  Loader,
  PaginationWrapper,
  SearchInput,
  TableBoxWrapper,
  ViewMore,
} from 'components';
import { useDebounce } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { fetchP2PBuyListing } from 'store/slices/p2p.slice';
import { mathRound } from 'utils';

const BuyListing = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchSring, setSearchString] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();

  const { data, status, totalCount } = useSelector(
    (state) => state.p2p.buyListing
  );

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize: 10 },
      total: totalCount,
      limits: {
        inner: 2,
        outer: 1,
      },
    });

  useEffect(() => {
    dispatch(
      fetchP2PBuyListing({
        limit: 10,
        page: currentPage,
        search: searchSring,
      })
    );
  }, [dispatch, currentPage, searchSring]);

  const handleOpen = (data) => {
    onOpen();
    setSelectedData(data);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  return (
    <Box>
      <HistoryTab
        firstText="P2P"
        secondText="Buy Listing"
        url="/dashboard/p2p"
      />

      <TableBoxWrapper mt="-11px">
        <Flex mb="32px">
          <Box width="319px">
            <SearchInput
              onChange={debouncedChangeHandler}
              placeholder="Search for user id, name or email"
            />
          </Box>
        </Flex>

        {status === 'loading' && <Loader />}

        <Table>
          <Thead>
            <Tr>
              <Th textAlign="center">Buyer</Th>
              <Th>Price</Th>
              <Th>Coin Amount</Th>
              <Th textAlign="center">Payment</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data.trades &&
              data.trades.map(
                (
                  {
                    _id,
                    userData,
                    currency,
                    coin,
                    ratingsRef,
                    price,
                    paymentOption,
                    tradeOption,
                    terms,
                    instructions,
                    location,
                    timeout,
                    adsType,
                    currentVolume,
                  },
                  i
                ) => {
                  const customModalData = {
                    _id,
                    userData,
                    currency,
                    coin,
                    ratingsRef,
                    price,
                    paymentOption,
                    tradeOption,
                    terms,
                    instructions,
                    location,
                    timeout,
                    adsType,
                    currentVolume,
                  };
                  return (
                    <Tr
                      key={_id}
                      cursor="pointer"
                      onClick={() => handleOpen(customModalData)}
                    >
                      <Td textAlign="center">
                        <Center flexDir="column">
                          <Text color="brand.success" fontWeight="500">
                            {userData.username}
                          </Text>

                          <Flex gridGap="8px" mt="6px">
                            <Text>{ratingsRef.buyRating.buyTrades} Trades</Text>

                            <Text>
                              {mathRound(ratingsRef.buyRating.average)} rating
                            </Text>
                          </Flex>
                        </Center>
                      </Td>

                      <Td fontWeight="500">
                        <Flex gridGap="4px">
                          <Text>{price ? price.toLocaleString() : 'N/A'}</Text>

                          <Text
                            as="span"
                            fontSize="12px"
                            textTransform="uppercase"
                          >
                            {currency}
                          </Text>
                        </Flex>
                      </Td>

                      <Td>
                        <Box>
                          <Flex gridGap="8px">
                            <Text fontSize="11px">Available</Text>

                            <Text fontSize="14px">
                              <Text
                                fontWeight="500"
                                as="span"
                                textTransform="uppercase"
                              >
                                {currentVolume?.$numberDecimal} {coin}
                              </Text>
                            </Text>
                          </Flex>

                          <Flex gridGap="8px">
                            <Text fontSize="11px">Range</Text>

                            <Text fontWeight="500" fontSize="14px">
                              {tradeOption.minimumFilling} -{' '}
                              {tradeOption.maximumFilling}
                            </Text>
                          </Flex>
                        </Box>
                      </Td>

                      <Td textAlign="center">
                        <ViewMore width="192px" _hover={{ bg: 'transparent' }}>
                          {paymentOption[0].label}
                        </ViewMore>
                      </Td>
                    </Tr>
                  );
                }
              )}
          </Tbody>
        </Table>

        <ListingModal
          type="buyer"
          isOpen={isOpen}
          onClose={onClose}
          data={selectedData}
        />

        <PaginationWrapper
          setCurrentPage={setCurrentPage}
          totalDataCount={totalCount}
          currentPage={currentPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          offset={offset}
          pages={pages}
          size={10}
        />
      </TableBoxWrapper>
    </Box>
  );
};

export default BuyListing;
