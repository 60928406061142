import { Box, Flex, Text } from '@chakra-ui/react';
import { SlateViewer } from 'components';
import AccordionTab from 'components/Tabs/AccordionTab';
import { Link } from 'react-router-dom';
import { formatDateToUTC } from 'utils';

const NoteTooltipData = ({ notesData, userId }) => {
  return (
    <AccordionTab title='Notes'>
      <Box
        px='8px'
        pt='8px'
      >
        {notesData.data.slice(0, 3).map(({ _id, note, adminEmail, createdAt }) => {
          return (
            <Box
              as='ul'
              px='12px'
              key={_id}
              mb='6px'
            >
              <Box
                as='li'
                listStyleType='disc'
              >
                <SlateViewer
                  key={_id}
                  id={_id}
                  value={note}
                  mb='2px'
                />

                <Flex>
                  <Text
                    fontSize='12px'
                    textTransform='lowercase'
                  >
                    <Text as='span'>Added by </Text>
                    <Text
                      as='span'
                      fontWeight='500'
                      color='#6FBE45'
                    >
                      {adminEmail}{' '}
                    </Text>
                    on{' '}
                    <Text
                      as='span'
                      fontWeight='500'
                      color='brand.primary'
                    >
                      {formatDateToUTC(createdAt)}
                    </Text>
                  </Text>{' '}
                </Flex>
              </Box>
            </Box>
          );
        })}

        {notesData.data.length > 3 && (
          <Text
            textAlign='center'
            textDecor='underline'
            mt='8px'
            _hover={{ fontWeight: '500' }}
          >
            <Link to={`/dashboard/users/${userId}`}>See All</Link>
          </Text>
        )}
      </Box>
    </AccordionTab>
  );
};
export default NoteTooltipData;
