export const denyDocumentReason = [
  {
    title: 'ID number is incorrect',
    description: 'Make sure that the ID number that you enter matches the ID that you upload',
  },
  {
    title: 'ID is NOT accepted',
    description: 'ID is not part of the accepted IDs on Bitmama',
  },
  {
    title: 'Email address disparity',
    description: 'The  email address written on the sheet needs to be the same as the one attached to your account',
  },
  {
    title: 'Date on sheet of paper is incorrect',
    description:
      'The date on the sheet of paper should match the current date that you are submitting the verification',
  },
  {
    title: 'No selfie',
    description: 'Kindly hold your ID next to your face and upload a selfie',
  },
  {
    title: 'Selfie is without ID',
    description: 'Kindly hold your ID next to your face and upload a selfie',
  },
  {
    title: 'Passport picture on ID is NOT clear',
    description: 'Retake this photo as the image on your ID card is fuzzy',
  },
  {
    title: 'Expired level document',
    description: 'Kindly upload a new document that is valid for the next 6 months',
  },
  {
    title: 'Expired level 3 document',
    description: 'Kindly upload a new document that is not later than 3 months',
  },
  {
    title: 'Information required on the sheet of paper is NOT provided',
    description:
      'Kindly add the following to the sheet of paper on when you upload again (Bitmama verification (as the title), email address, date of submission',
  },
  {
    title: 'Information required on the sheet of paper is NOT complete or done properly',
    description:
      'Kindly add the following to the sheet of paper on when you upload again (Bitmama verification (as the title), email address, date of submission',
  },
  {
    title: 'No residential address on the document',
    description: 'Kindly provide us with a document that has your address on it',
  },
  {
    title: 'No account name',
    description: 'No account name',
  },
  {
    title: 'No bank name or logo',
    description: 'Kindly provide a bank statement that has the bank name and logo',
  },
  {
    title: 'Altered document is NOT accepted',
    description: 'Your bank statement looks altered. Kindly provide a government issued document with your address',
  },
  {
    title: 'Address disparity',
    description: 'The address on your bank statement does not match the address that you have provided',
  },
  {
    title: 'Document uploaded is NOT clear',
    description: 'Kindly retake this picture as the current uploaded document is fuzzy',
  },
  {
    title: 'Blank upload',
    description: 'Kindly add a file to this verification when you submit it the next time',
  },
  {
    title: 'Others',
    description: '',
  },
];
