import { Box } from '@chakra-ui/react';
import { BeforeCreation, HistoryTab } from 'components';

const EntryTagDetails = () => {
  return (
    <Box>
      <HistoryTab
        firstText='Wishlist'
        secondText='Item Details'
        url='/dashboard/wishlist'
      />

      <Box
        mt='27px'
        bg='#FCFDFD'
        boxShadow='0px 2px 8px 0px rgba(229, 229, 229, 0.47)'
        borderRadius='20px'
        minHeight='700px'
        padding={{ base: '24px', md: '24px' }}
      >
        <BeforeCreation />
      </Box>
    </Box>
  );
};

export default EntryTagDetails;
