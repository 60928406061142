import { Box } from '@chakra-ui/react';
import { Loader } from 'components';
import { useGetUserNotesQuery } from 'store/services/notes.service';
import NotesCard from './NotesCard';

const UserNotes = ({ userId, createThread, includeContext }) => {
  const { data, isLoading, isSuccess } = useGetUserNotesQuery(userId, {
    skip: userId ? false : true,
  });

  return (
    <Box
      h='65.5vh'
      borderRadius='8px'
      padding='12px'
      mb='16px'
      overflowY='auto'
    >
      {isLoading && <Loader />}

      {isSuccess && (
        <NotesCard
          createThread={createThread}
          data={data}
          userId={userId}
          includeContext={includeContext}
          isReply={false}
        />
      )}
    </Box>
  );
};

export default UserNotes;
