import { useState } from 'react';
import { Box, Center, Checkbox, Flex, Text, Textarea, VStack } from '@chakra-ui/react';
import { Button, FilterSelect, Input } from 'components';
import { post } from 'utils/axiosLib';
import { BASE_URL } from 'constant';
import { fetchLiens } from 'store/slices/liens.slice';
import { wrapBaseApi } from 'utils';

const PlaceNewLien = ({ data, headers, onClose, toast, dispatch, id }) => {
  const [state, setState] = useState({
    unit: '',
    amount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [autoProcess, setAutoProcess] = useState(true);
  const [acceptPartial, setAcceptPartial] = useState(true);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckbox = () => {
    setAutoProcess(!autoProcess);
  };

  const handlePartialCheckbox = () => {
    setAcceptPartial(!acceptPartial);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/liens/new`);

    try {
      const response = await post(
        URL,
        {
          email: data?.email,
          autoProcess,
          acceptPartial,
          amount: Number(state.amount),
          ...state,
        },
        headers
      );

      if (response.data) {
        setLoading(false);
        onClose();

        dispatch(
          fetchLiens({
            id,
            page: 1,
            limit: 10,
          })
        );
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Error',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <Box
      width='100%'
      pt='14px'
    >
      <form onSubmit={handleSubmit}>
        <VStack
          pl='2px'
          gridGap='8px'
          pr='8px'
        >
          <Input
            label='Amount'
            id='amount'
            width='100%'
            name='amount'
            type='number'
            htmlFor='amount'
            onChange={handleChange}
            step='any'
            placeholder='Amount to deduct or block from users wallet'
          />

          <Box width='100%'>
            <Text
              fontSize='14px'
              display='block'
              mb='5px'
              as='label'
              htmlFor='description'
            >
              Description
            </Text>

            <Textarea
              placeholder='Failed debit for VTU AIRTIME - ₦5000 on 28/01/2022'
              onChange={handleChange}
              fontSize='15px'
              name='description'
              id='description'
              isRequired
              width='100%'
              height='80px'
              padding='14px'
              _focus={{
                borderColor: '#6FBE45',
                borderWidth: '1px',
              }}
            />
          </Box>

          <Box width='100%'>
            <Text
              fontSize='14px'
              display='block'
              mb='5px'
              as='label'
              htmlFor='unit'
            >
              Unit
            </Text>

            <FilterSelect
              name='unit'
              minH='60px'
              id='unit'
              height='52px'
              width='100%'
              onChange={handleChange}
            >
              <option>Coin</option>
              <option value='ngn'>NGN</option>
              <option value='ghs'>GHS</option>
              <option value='kes'>KES</option>
              <option value='btc'>BTC</option>
              <option value='eth'>ETH</option>
              <option value='celo'>CELO</option>
              <option value='xlm'>XLM</option>
              <option value='xrp'>XRP</option>
              <option value='usdt'>USDT</option>
              <option value='usdc'>USDC</option>
              <option value='cusd'>CUSD</option>
              <option value='cdf'>CDF</option>
              <option value='xof'>XOF</option>
              <option value='xaf'>XAF</option>
              <option value='cdf'>CDF</option>
            </FilterSelect>
          </Box>

          <Flex
            pl='3px'
            width='100%'
          >
            <Box width='100%'>
              <Checkbox
                name='autoProcess'
                onChange={handleCheckbox}
                colorScheme='green'
                fontSize='14px'
                touchAction='none'
                defaultChecked
                value={autoProcess}
              >
                Auto-Process
              </Checkbox>
            </Box>

            <Box width='100%'>
              <Checkbox
                name='acceptPartial'
                onChange={handlePartialCheckbox}
                colorScheme='green'
                fontSize='14px'
                touchAction='none'
                defaultChecked
                value={acceptPartial}
              >
                Accept Partial
              </Checkbox>
            </Box>
          </Flex>

          <Box
            width='100%'
            pl='2px'
          >
            <Input
              label='Tag'
              id='tag'
              width='100%'
              name='tag'
              htmlFor='tag'
              onChange={handleChange}
              placeholder='vtu-airtime'
            />
          </Box>
        </VStack>

        <Center mt='2rem'>
          <Button
            isLoading={loading}
            disabled={loading || state.unit.length <= 2 || state.amount === undefined || Number(state.amount) === 0}
            minW='250px'
            type='submit'
            // minH="52px"
          >
            Submit
          </Button>
        </Center>
      </form>
    </Box>
  );
};

export default PlaceNewLien;
