import { useState } from 'react';
import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal, ConfigCheckbox } from 'components';
import { useUpdateConfigMutation } from 'store/services/app.service';
import isObject from 'lodash/isObject';

const SyncAppConfigModal = ({ isOpen, onClose, modalData = {}, data, changeLock, refectch }) => {
  const header = modalData && Object.keys(modalData);

  const previousData =
    data &&
    Object.fromEntries(
      Object.entries(data).filter(([key, _]) => key.toLocaleLowerCase() === header[0]?.toLocaleLowerCase())
    );
  const [syncUpdate, setSyncUpdate] = useState(false);

  const [_updateConfig, { isLoading }] = useUpdateConfigMutation();

  const toast = useToast();

  // const clone = (input) => {
  //   if (input === null || typeof input !== 'object') {
  //     return input;
  //   }

  //   const initialValue = Array.isArray(input) ? [] : {};

  //   return Object.keys(input).reduce((acc, key) => {
  //     acc[key] = clone(input[key]);

  //     return acc;
  //   }, initialValue);
  // };

  // const cloned = clone(previousData[header[0]]);

  // console.log(cloned, 'THE CLONED');
  // console.log(previousData[header[0]], 'THE KEY CLONED');

  // console.log(value, 'TO BE UPDATED');
  // const newObj = { ...previousData[header[0]], ...cloned };

  // console.log(newObj, 'CLONED DATA OBJECT');
  // cloned[key] = { ...previousData[header[0]], ...cloned };

  // console.log(value, 'VALUE TO BE UPDATED');
  // console.log(updatedData[key], 'UPDATED KEY');

  const handleUpdate = async () => {
    const updatedData = { ...previousData[header[0]] };

    Object.entries(modalData[header[0]]).forEach(([key, value]) => {
      if (Object.keys(updatedData).includes(key)) {
        if (isObject(value) && isObject(updatedData[key])) {
          updatedData[key] = { ...updatedData[key], ...value };
        } else {
          updatedData[key] = value;
        }
      }
    });

    const response = await _updateConfig({
      field: header[0] || '',
      data: updatedData,
      syncUpdate,
      changeLock,
    });

    // const response = {};
    if (response?.data) {
      onClose();

      toast({
        position: 'bottom-right',
        title: 'Success',
        description: response?.data?.message,
        status: 'success',
        duration: 18000,
        isClosable: true,
      });
    }

    if (response?.error) {
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: response?.error.data?.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });

      if (response.error?.data?.error === 'Outdated changeLock was supplied') {
        refectch();
      }
    }
  };

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius='16px'
          maxWidth='550px'
        >
          <ModalHeader
            bg='#F7F7FC'
            padding='24px'
            fontWeight='600'
            borderTopRadius='16px'
            textAlign='center'
          >
            Sync App Config
          </ModalHeader>

          <ModalBody>
            <Text
              textAlign='center'
              mb='24px'
            >
              Are you sure want to sync app update on{' '}
              <Text
                as='span'
                fontWeight='bold'
              >
                {header && header[0]?.toLocaleLowerCase()}
              </Text>
            </Text>

            {modalData &&
              Object.entries(modalData).map(([_, value]) => {
                return Object.entries(value).map(([key, value], index) => {
                  return (
                    <Flex
                      key={index}
                      width='100%'
                      justifyContent='space-between'
                      mb='16px'
                    >
                      <Box
                        textTransform='capitalize'
                        fontWeight='500'
                        maxWidth='250px'
                      >
                        {key}
                      </Box>

                      <Box>
                        {typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' ? (
                          <>{String(value)}</>
                        ) : (
                          Object.entries(value).map(([subHeader, subValue], i) => {
                            return (
                              <Flex
                                justifyContent='space-between'
                                minW='90px'
                                key={i}
                              >
                                <Box
                                  textTransform='uppercase'
                                  fontWeight='500'
                                  gap='24px'
                                >
                                  {subHeader}:
                                </Box>

                                <Flex gap='5px'>
                                  {(typeof subValue === 'string' ||
                                    typeof subValue === 'number' ||
                                    typeof subValue === 'boolean') &&
                                    String(subValue)}

                                  {typeof subValue === 'object' &&
                                    Object.entries(subValue).map(([a, b], _id) => {
                                      return (
                                        <Box key={_id}>
                                          {a}:{' '}
                                          <>
                                            {typeof b === 'object' ? (
                                              <>
                                                <br />
                                                {Object.entries(b).map(([newKey, newValue]) => {
                                                  return (
                                                    <Text key={_id}>
                                                      {newKey}:{newValue}
                                                    </Text>
                                                  );
                                                })}
                                              </>
                                            ) : (
                                              { b }
                                            )}
                                          </>
                                        </Box>
                                      );
                                    })}
                                </Flex>
                              </Flex>
                            );
                          })
                        )}
                      </Box>
                    </Flex>
                  );
                });
              })}

            <Flex
              gap='24px'
              alignItems='center'
              mt='24px'
            >
              <Text fontWeight='500'>Sync Update</Text>

              <ConfigCheckbox
                defaultChecked={syncUpdate}
                onChange={() => setSyncUpdate(!syncUpdate)}
              />
            </Flex>

            <Text fontSize='12px'>
              If you have a batch of updates that are dependent on one another, only sync update with the last batch.
              Otherwise, enable sunc update right now
            </Text>

            <Center mt='32px'>
              <Button
                onClick={handleUpdate}
                isLoading={isLoading}
                disabled={isLoading}
                minW='200px'
              >
                Apply Update
              </Button>
            </Center>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SyncAppConfigModal;
