import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'constant';
import { getHeaders } from 'utils';
import { get } from 'utils/axiosLib';

const initialState = {
  referral: {
    status: 'idle',
    data: {
      referrals: [],
    },
    totalCount: 0,
    beforeAndAfter: '',
  },
};

export const fetchReferrals = createAsyncThunk(
  'referrals/ref',
  async ({ limit, page, time }) => {
    const response = await get(
      `${BASE_URL}/admin/referrals?limit=${limit}&page=${page}&${time}`,

      getHeaders()
    );
    return response.data;
  }
);

const referralSlice = createSlice({
  name: 'referral',

  initialState,

  reducers: {
    getRefferralCustomDate(state, action) {
      state.referral.beforeAndAfter = action.payload;
    },
  },

  extraReducers: (builder) => {
    // Airtime
    builder.addCase(fetchReferrals.fulfilled, (state, { payload }) => {
      state.referral.status = 'fulfilled';
      state.referral.data = payload.data;
      state.referral.totalCount = payload.data.totalCount;
    });

    builder.addCase(fetchReferrals.pending, (state) => {
      state.referral.status = 'loading';
    });

    builder.addCase(fetchReferrals.rejected, (state) => {
      state.referral.status = 'error';
    });
  },
});

export const {
  actions: { getRefferralCustomDate },
  reducer: ReferralReducer,
} = referralSlice;
