const fiats = ['ngn', 'ghs', 'kes', 'xaf', 'xof', 'cdf'];

const isFiatCoin = (coin) => {
  return fiats.includes(coin);
};

export const roundNumber = (number) => {
  return number % 1 === 0
    ? Number(number).toLocaleString()
    : Number(number)
        .toFixed(6)
        .replace(/\.?0+$/, '');
};

export const toPrecision = (number, precision = 2) => {
  const num = Number(number);
  return Number(num.toFixed(precision));
};

// MathRound(3.256, 2) => 3.25 gives instead of 3.26
export const MathRound = (num, nrdecimals) => {
  let n = Number(Number(num).toFixed(nrdecimals));
  return n > num ? toPrecision(n - 1 / Math.pow(10, nrdecimals), nrdecimals) : n;
};

export const numberWithCommas = (x) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const getCryptoPrecision = (coin) => {
  if (coin.toLowerCase() === 'celo') return 3;
  if (coin.toLowerCase() === 'matic') return 3;
  if (coin.toLowerCase() === 'tbtc' || coin.toLowerCase() === 'btc') return 6;
  if (coin.toLowerCase() === 'teth' || coin.toLowerCase() === 'eth') return 5;
  return 2;
};

export const formatValuePerUnit = (value, unit) => {
  if (!unit) return value;
  if (isFiatCoin(unit)) {
    return numberWithCommas(MathRound(Number(value), 2).toFixed(2));
  }
  return numberWithCommas(MathRound(Number(value), getCryptoPrecision(unit)));
};
