import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'constant';
import { getHeaders } from 'utils';
import { post } from 'utils/axiosLib';

const initialState = {
  rewards: {
    status: 'idle',
    data: {
      stat: [],
    },
    claimedDates: '',
    totalCount: 0,
  },
};

export const fetchClaimedRewards = createAsyncThunk(
  'rewards/all',
  async ({ claimedDates, dates }) => {
    const response = await post(
      `${BASE_URL}/admin/claimStats`,
      {
        includeTransactions: true,
        exportAs: 'json',
        dates: dates,
        claimedDates: claimedDates,
      },
      getHeaders()
    );
    return response.data;
  }
);

const claimedRewaredSlice = createSlice({
  name: 'claimedRewards',

  initialState,

  reducers: {
    getClaimedDate(state, action) {
      state.rewards.claimedDates = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchClaimedRewards.fulfilled, (state, { payload }) => {
      state.rewards.status = 'fulfilled';
      state.rewards.data = payload.data;
      state.rewards.totalCount = payload.data.claimsSummary.count;
    });

    builder.addCase(fetchClaimedRewards.pending, (state) => {
      state.rewards.status = 'loading';
    });

    builder.addCase(fetchClaimedRewards.rejected, (state) => {
      state.rewards.status = 'error';
    });
  },
});

export const {
  actions: { getClaimedDate },
  reducer: rewardsReducer,
} = claimedRewaredSlice;
