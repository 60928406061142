import { useState } from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Button,
  ConfigCheckbox,
  Input,
  Loader,
  PageTitle,
  ScrollRightContainer,
  SyncAppConfigModal,
  TagInput,
} from 'components';

import { useGetAllConfigsQuery } from 'store/services/app.service';

const AppConfig = () => {
  const { data, isLoading, refetch } = useGetAllConfigsQuery();

  const changeLock =
    data && Object.fromEntries(Object.entries(data).filter(([key, _]) => key?.toLocaleLowerCase() === 'changelock'));

  const filteredObject =
    data && Object.fromEntries(Object.entries(data).filter(([key, _]) => !key?.toLocaleLowerCase().includes('lock')));

  // console.log(filteredObject?.widgetConfig, 'HELLO');

  const [properties, setProperties] = useState({});
  const [checkboxState, setCheckboxState] = useState({});

  const [selectedTags, setSelectedTags] = useState([]);

  const handleSelectedTags = (header, subHeader, key, newTags) => {
    setProperties({
      [header]: {
        ...properties[header],
        [key]: {
          ...(properties[header] ? { ...properties[header][key] } : null),
          [subHeader]: newTags,
        },
      },
    });
    // }
  };

  const removeTags = (header, subHeader, headerKey, filter) => {
    setProperties({
      [header]: {
        ...properties[header],
        [headerKey]: {
          ...(properties[header] ? { ...properties[header][headerKey] } : null),
          [subHeader]: filter,
        },
      },
    });
  };

  const handleCheckboxChange = (header, value, key, newValue) => {
    setCheckboxState({
      ...checkboxState,
      [key]: value,
    });

    if (typeof newValue === 'undefined') {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: value,
        },
      });
    }

    if (typeof newValue !== 'undefined') {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: {
            ...(properties[header] ? { ...properties[header][key] } : null),
            [newValue]: value,
          },
        },
      });
    }
  };

  const handleInputChange = (header, value, key, secondLevel, thirdLevelValue, fourthLevelValue) => {
    if (typeof secondLevel === 'undefined') {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: value,
        },
      });
    }

    if (typeof secondLevel !== 'undefined' && thirdLevelValue === undefined) {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: {
            ...(properties[header] ? { ...properties[header][key] } : null),
            [value]: secondLevel,
          },
        },
      });
    }

    if (typeof thirdLevelValue !== 'undefined' && fourthLevelValue === undefined) {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: {
            ...(properties[value] ? { ...properties[value][secondLevel] } : null),
            [value]: {
              [secondLevel]: thirdLevelValue,
            },
          },
        },
      });
    }

    if (typeof fourthLevelValue !== 'undefined') {
      setProperties({
        [header]: {
          ...properties[header],
          [key]: {
            ...(properties[header] ? { ...properties[header][key] } : null),
            [value]: {
              [secondLevel]: {
                ...(properties[secondLevel] ? { ...properties[secondLevel][thirdLevelValue] } : null),
                [thirdLevelValue]: fourthLevelValue,
              },
            },
          },
        },
      });
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState({});

  const handleUpdate = () => {
    onOpen();
    setModalData(properties);
  };

  return (
    <>
      <PageTitle
        title='App Config'
        fontSize={{ base: '18px', md: '24px' }}
      />

      <Box mt='29px'>
        <ScrollRightContainer>
          {isLoading && <Loader />}

          {!isLoading && (
            <>
              <Accordion
                allowMultiple={false}
                allowToggle
                border='0.5px solid #CACBCB'
                borderRadius='10px'
                filter='drop-shadow(0px 4px 24px rgba(67, 55, 55, 0.2))'
                mb='30px'
                padding='18px 0px'
                maxWidth='720px'
              >
                {Object.entries(filteredObject).map(([header, headerValue], index) => {
                  return (
                    <AccordionItem
                      key={index}
                      border='none'
                      padding={{ base: '0', md: '0 24px' }}
                      mb='2rem'
                    >
                      <Flex
                        alignItems='center'
                        gap='8px'
                      >
                        <AccordionButton
                          as='div'
                          p={{ base: '10px', md: '20px' }}
                          textTransform='capitalize'
                          _expanded={{ bg: 'rgba(0, 0, 0, 0.04)' }}
                          transition='all 300ms ease-in-out'
                        >
                          <Box
                            as='span'
                            flex='1'
                            fontSize='18px'
                            textAlign='left'
                            fontWeight='500'
                          >
                            {header}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>

                        <Box>
                          {Object.keys(properties).includes(header) && (
                            <Button
                              minW='100px'
                              minH='38px'
                              fontSize='11px'
                              onClick={handleUpdate}
                            >
                              Update
                            </Button>
                          )}
                        </Box>
                      </Flex>

                      <AccordionPanel
                        pb={4}
                        fontSize='14px'
                      >
                        {typeof headerValue === 'string'
                          ? headerValue
                          : Object.entries(headerValue).map(([key, value], i) => {
                              return (
                                <Flex
                                  key={i}
                                  width='100%'
                                  justifyContent='space-between'
                                  maxWidth='initial'
                                  alignItems='center'
                                  p='16px 0'
                                  borderBottom='1px solid #EDEBF9'
                                >
                                  <Text fontWeight='500'>{key}:</Text>

                                  <Box>
                                    {typeof value === 'string' ||
                                    typeof value === 'number' ||
                                    typeof value === 'boolean' ? (
                                      <>
                                        {(typeof value === 'number' || typeof value === 'string') && (
                                          <Input
                                            fontSize='14px'
                                            type={typeof value === 'number' ? 'number' : 'text'}
                                            width='100px'
                                            padding='18px'
                                            defaultValue={value}
                                            onChange={(e) =>
                                              handleInputChange(
                                                header,
                                                typeof value === 'number' ? Number(e.target.value) : e.target.value,
                                                key
                                              )
                                            }
                                          />
                                        )}

                                        {typeof value === 'boolean' && (
                                          <>
                                            <ConfigCheckbox
                                              onChange={(event) =>
                                                handleCheckboxChange(header, event.target.checked, key)
                                              }
                                              defaultChecked={value}
                                            />
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      Object.entries(value).map(([subHeader, subValue], idx) => {
                                        return (
                                          <Flex
                                            key={idx}
                                            mb='8px'
                                            width='100%'
                                            justifyContent='space-between'
                                            gap='18px'
                                          >
                                            <Box textTransform='uppercase'>{subHeader}:</Box>

                                            <Box>
                                              {typeof subValue === 'boolean' ||
                                              typeof subValue === 'number' ||
                                              typeof subValue === 'string' ? (
                                                <>
                                                  {(typeof subValue === 'number' || typeof subValue === 'string') && (
                                                    <>
                                                      <Input
                                                        fontSize='14px'
                                                        type={typeof subValue === 'number' ? 'number' : 'text'}
                                                        width='100px'
                                                        padding='18px'
                                                        defaultValue={subValue}
                                                        onChange={(e) =>
                                                          handleInputChange(
                                                            header,
                                                            subHeader,
                                                            key,
                                                            typeof subValue === 'number'
                                                              ? Number(e.target.value)
                                                              : e.target.value
                                                          )
                                                        }
                                                      />
                                                    </>
                                                  )}
                                                  {typeof subValue === 'boolean' && (
                                                    <ConfigCheckbox
                                                      onChange={(event) =>
                                                        handleCheckboxChange(
                                                          header,
                                                          event.target.checked,
                                                          key,
                                                          subHeader
                                                        )
                                                      }
                                                      defaultChecked={subValue}
                                                    />
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {Array.isArray(subValue) ? (
                                                    <>
                                                      <TagInput
                                                        selectedTags={selectedTags}
                                                        defaultTags={subValue}
                                                        setSelectedTags={setSelectedTags}
                                                        header={header}
                                                        headerKey={key}
                                                        subHeader={subHeader}
                                                        handlePropChange={() => {
                                                          handleSelectedTags(header, subHeader, key, selectedTags);
                                                        }}
                                                        onRemoveTags={removeTags}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      {Object.entries(subValue).map(([a, b], idxx) => {
                                                        return (
                                                          <Flex
                                                            key={idxx}
                                                            gap='1rem'
                                                            mb='8px'
                                                          >
                                                            <Text>
                                                              {typeof b === 'string' || typeof b === 'number' ? a : ''}{' '}
                                                            </Text>

                                                            <Box>
                                                              {(typeof b === 'string' || typeof b === 'number') && (
                                                                <Input
                                                                  fontSize='14px'
                                                                  type={typeof b === 'number' ? 'number' : 'text'}
                                                                  width='100px'
                                                                  padding='18px'
                                                                  defaultValue={b}
                                                                  onChange={(e) =>
                                                                    handleInputChange(
                                                                      header,
                                                                      subHeader,
                                                                      key,
                                                                      a,
                                                                      typeof b === 'number'
                                                                        ? Number(e.target.value)
                                                                        : e.target.value
                                                                    )
                                                                  }
                                                                />
                                                              )}

                                                              {typeof b === 'object' &&
                                                                Object.entries(b).length >= 1 && (
                                                                  <>
                                                                    {Object.entries(b).map(([firstKey, secondKey]) => {
                                                                      return (
                                                                        <Flex
                                                                          key={firstKey}
                                                                          gap='8px'
                                                                          mb='8px'
                                                                        >
                                                                          <Box textTransform='capitalize'>
                                                                            {firstKey}
                                                                          </Box>
                                                                          <Input
                                                                            fontSize='14px'
                                                                            type={
                                                                              typeof secondKey === 'number'
                                                                                ? 'number'
                                                                                : 'text'
                                                                            }
                                                                            width='100px'
                                                                            padding='18px'
                                                                            defaultValue={secondKey}
                                                                            onChange={(e) =>
                                                                              handleInputChange(
                                                                                header,
                                                                                subHeader,
                                                                                key,
                                                                                a,
                                                                                firstKey,
                                                                                typeof secondKey === 'number'
                                                                                  ? Number(e.target.value)
                                                                                  : e.target.value
                                                                              )
                                                                            }
                                                                          />
                                                                        </Flex>
                                                                      );
                                                                    })}
                                                                  </>
                                                                )}
                                                            </Box>
                                                          </Flex>
                                                        );
                                                      })}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </Box>
                                          </Flex>
                                        );
                                      })
                                    )}
                                  </Box>
                                </Flex>
                              );
                            })}
                      </AccordionPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </>
          )}
        </ScrollRightContainer>

        <SyncAppConfigModal
          isOpen={isOpen}
          onClose={onClose}
          modalData={modalData}
          data={filteredObject}
          changeLock={changeLock?.changeLock}
          refetch={refetch}
        />
      </Box>
    </>
  );
};

export default AppConfig;
