import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  Box,
  useToast,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { formatDateToUTC, getHeaders, wrapBaseApi } from 'utils';
import { post } from 'utils/axiosLib';
import { Button, CloseModal, FilterSelect, Input, TableDataStatus } from 'components';
import { fetchLiens } from 'store/slices/liens.slice';
import { BASE_URL } from 'constant';

const PauseReverseLienModal = ({ data, id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { _id, amount, userDetails, capturedBy, status, unit, description, tag, amountCharged, createdAt } = data;

  const toast = useToast();
  const [selected, setSelected] = useState('');
  const [reverseAmount, setReverseAmount] = useState(0);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleOpen = (e) => {
    setSelected(e.target.value);
    if (e.target.value.length > 3) {
      onOpen();
    }
  };

  const handleChange = (e) => {
    setReverseAmount(e.target.value);
  };

  const handleLienAction = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/liens/${selected}/${_id}`);

    try {
      const response = await post(
        URL,
        {
          amount: selected === 'reverse' ? Number(reverseAmount) : undefined,
        },
        getHeaders()
      );

      if (response.data) {
        setLoading(false);
        dispatch(
          fetchLiens({
            id,
            page: 1,
            limit: 10,
          })
        );

        setReverseAmount(0);

        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <FilterSelect
        width='100px'
        onChange={handleOpen}
      >
        <option value=''>Action</option>
        <option value='pause'>Pause</option>
        <option value='reverse'>Reverse</option>
      </FilterSelect>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        key={_id}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='21px'
          bg='#fff'
          maxWidth='480px'
          padding='40px 40px 0'
        >
          <Text
            fontSize='24px'
            fontWeight='600'
            textAlign='center'
            textTransform='capitalize'
          >
            {selected} Lien
          </Text>

          <ModalBody
            padding='0px'
            margin='40px 0'
            fontSize='14px'
          >
            <Box>
              <Flex
                fontWeight='medium'
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Full name</Text>

                <Text
                  fontWeight='medium'
                  textTransform='capitalize'
                >
                  {userDetails?.fullName}
                </Text>
              </Flex>

              <Flex
                fontWeight='medium'
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Email</Text>

                <Text
                  fontWeight='medium'
                  textTransform='lowercase'
                >
                  {userDetails?.email}
                </Text>
              </Flex>

              <Flex
                fontWeight='medium'
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Status</Text>

                <TableDataStatus status={status} />
              </Flex>

              <Flex
                fontWeight='medium'
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Amount</Text>

                <Text
                  fontWeight='medium'
                  textTransform='uppercase'
                >
                  {amount?.$numberDecimal} {unit}
                </Text>
              </Flex>

              <Flex
                fontWeight='medium'
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Amount Charged</Text>

                <Text
                  fontWeight='medium'
                  textTransform='uppercase'
                >
                  {amountCharged?.$numberDecimal} {unit}
                </Text>
              </Flex>

              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Box>
                  <Text>Placed By</Text>
                </Box>

                <Box>
                  <Text
                    fontWeight='medium'
                    textTransform='capitalize'
                    textAlign='right'
                  >
                    {capturedBy?.fullName}
                  </Text>
                  <Text
                    fontWeight='medium'
                    textTransform='lowercase'
                  >
                    {capturedBy?.email}
                  </Text>
                </Box>
              </Flex>

              <Flex
                fontWeight='medium'
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Description</Text>

                <Text
                  fontWeight='medium'
                  maxWidth='280px'
                  textAlign='right'
                >
                  {description}
                </Text>
              </Flex>

              <Flex
                fontWeight='medium'
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Tag</Text>

                <Text fontWeight='medium'>{tag}</Text>
              </Flex>

              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Time and Date</Text>

                <Text fontWeight='medium'>{formatDateToUTC(createdAt)}</Text>
              </Flex>
            </Box>

            <VStack mt='4rem'>
              <Text>
                Are you sure you want to{' '}
                <Text
                  as='span'
                  fontWeight='semibold'
                >
                  {selected}
                </Text>{' '}
                this lien
              </Text>

              {selected === 'reverse' && (
                <Box
                  width='100%'
                  m='1rem 0'
                >
                  <Input
                    id='amount'
                    width='100%'
                    minHeight='52px'
                    name='amount'
                    type='number'
                    htmlFor='amount'
                    placeholder='Amount'
                    onChange={handleChange}
                    step='any'
                  />
                </Box>
              )}

              <Button
                disabled={
                  loading ||
                  (selected === 'reverse' && reverseAmount <= 0) ||
                  (selected === 'reverse' && Number(amount?.$numberDecimal) < Number(reverseAmount))
                }
                isLoading={loading}
                onClick={handleLienAction}
                _focus={{
                  border: 'none',
                }}
              >
                Yes
              </Button>
            </VStack>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PauseReverseLienModal;
