import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text, Flex, CircularProgress, CircularProgressLabel, useDisclosure } from '@chakra-ui/react';
import { fetchSimilarityScore } from 'store/slices/user.slice';
import { SimilarityScoreModal } from 'components';

const SimilarityScore = ({ userDetail }) => {
  const dispatch = useDispatch();
  const { data, nameMatches, dobMatches, ninMatches } = useSelector((state) => state.allUsers.similarityScore);

  const [selectedData, setSelectedData] = useState({});

  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleOpen = (data) => {
    onOpen();
    setSelectedData(data);
  };

  useEffect(() => {
    dispatch(
      fetchSimilarityScore({
        id: userDetail.userId,
      })
    );
  }, [dispatch, userDetail.userId]);

  return (
    <Box
      border='1px solid #EDEBF9'
      width='350px'
      borderRadius='8px'
      mt='30px'
    >
      <Flex
        padding='20px 16px 0'
        justifyContent='space-between'
        alignItems='center'
      >
        <Text
          color='#027343'
          fontSize='18px'
          fontWeight='medium'
        >
          Similarity Score
        </Text>

        <CircularProgress
          value={(data?.score / 3) * 100}
          color='brand.primary'
          thickness='4px'
        >
          <CircularProgressLabel fontWeight='medium'>{data?.score}/3</CircularProgressLabel>
        </CircularProgress>
      </Flex>

      <Box padding='0 16px 16px'>
        <Text
          fontSize='16px'
          fontWeight='medium'
        >
          Name Matches: {nameMatches}
        </Text>

        <Text
          fontSize='16px'
          fontWeight='medium'
        >
          DOB Matches: {dobMatches}
        </Text>

        <Text
          fontSize='16px'
          fontWeight='medium'
        >
          NIN Matches: {ninMatches}
        </Text>
      </Box>

      <Box
        height='360px'
        overflowY='scroll'
      >
        <Box padding='0 16px'>
          <Text fontWeight='bold'>Name match</Text>

          {data?.ratings?.slice(0, 5)?.map(({ target, userId }, i) => {
            const customModalData = {
              userId,
              target,
            };
            return (
              <Box
                key={i}
                padding='16px 0'
                borderTop='1px solid #EAEAEA'
              >
                <Flex justifyContent='space-between'>
                  <Text textTransform='capitalize'>{target}</Text>

                  <Box
                    color='#fff'
                    borderRadius='8px'
                    background='brand.primary'
                    padding='4px 8px'
                    fontSize='12px'
                    as='button'
                    onClick={() => handleOpen(customModalData)}
                  >
                    View details
                  </Box>
                </Flex>
              </Box>
            );
          })}

          <Text fontWeight='bold'>D.O.B match</Text>

          {data?.dobRatings?.slice(0, 5)?.map(({ target, user, userId }, i) => {
            const customModalData = {
              userId,
              target,
            };
            return (
              <Box
                key={i}
                padding='16px 0'
                borderTop='1px solid #EAEAEA'
              >
                <Flex justifyContent='space-between'>
                  <Box>
                    <Text textTransform='capitalize'>{user}</Text>
                    <Text>{target}</Text>
                  </Box>

                  <Box
                    color='#fff'
                    borderRadius='8px'
                    background='brand.primary'
                    padding='4px 8px'
                    fontSize='12px'
                    as='button'
                    height='26px'
                    onClick={() => handleOpen(customModalData)}
                  >
                    View details
                  </Box>
                </Flex>
              </Box>
            );
          })}

          <Text fontWeight='bold'>NIN match</Text>
          {data?.ninRatings?.slice(0, 5)?.map(({ target, user, userId }, i) => {
            const customModalData = {
              userId,
              target,
            };
            return (
              <Box
                key={i}
                padding='16px 0'
                borderTop='1px solid #EAEAEA'
              >
                <Flex justifyContent='space-between'>
                  <Box>
                    <Text textTransform='capitalize'>{user}</Text>
                    <Text>{target}</Text>
                  </Box>

                  <Box
                    color='#fff'
                    borderRadius='8px'
                    background='brand.primary'
                    padding='4px 8px'
                    fontSize='12px'
                    as='button'
                    height='26px'
                    onClick={() => handleOpen(customModalData)}
                  >
                    View details
                  </Box>
                </Flex>
              </Box>
            );
          })}
        </Box>
      </Box>

      {isOpen && (
        <SimilarityScoreModal
          data={selectedData}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Box>
  );
};

export default SimilarityScore;
