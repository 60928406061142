import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, HStack, Input } from '@chakra-ui/react';

import { Button } from 'components';
import {
  fetchReferrals,
  getRefferralCustomDate,
} from 'store/slices/referral.slice';
import { convertToIso } from 'utils';
import {
  fetchUsers,
  getLoginActivityDate,
  getUsersCustomDate,
} from 'store/slices/user.slice';
import { fetchAllLoans, getLoanCustomDate } from 'store/slices/bcpl.slice';

const CustomDate = ({ filterType, loginActivity, btnTitle }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    startDate: '',
    endDate: '',
  });

  const handleDateChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const applyReferralFilters = () => {
    dispatch(
      getRefferralCustomDate(
        `beforeDate=${convertToIso(state.endDate)}&afterDate=${convertToIso(
          state.startDate
        )}`
      )
    );

    dispatch(
      fetchReferrals({
        limit: 10,
        page: 1,
        time: `beforeDate=${convertToIso(
          state.endDate
        )}&afterDate=${convertToIso(state.startDate)}`,
      })
    );
  };

  const applyAllUsersFilter = () => {
    !loginActivity &&
      dispatch(
        getUsersCustomDate(
          `beforeDate=${convertToIso(state.endDate)}&afterDate=${convertToIso(
            state.startDate
          )}`
        )
      );

    loginActivity &&
      dispatch(
        getLoginActivityDate(
          `endLoggedInDate=${convertToIso(
            state.endDate
          )}&startLoggedInDate=${convertToIso(state.startDate)}`
        )
      );

    dispatch(
      fetchUsers({
        page: 1,

        limit: 10,

        search: '',

        time:
          loginActivity === false
            ? `beforeDate=${convertToIso(
                state.endDate
              )}&afterDate=${convertToIso(state.startDate)}`
            : `startLoggedInDate=${convertToIso(
                state.endDate
              )}&endLoggedInDate=${convertToIso(state.startDate)}`,
      })
    );
  };

  const applyBcplFilter = () => {
    dispatch(
      getLoanCustomDate(
        `beforeDate=${convertToIso(state.endDate)}&afterDate=${convertToIso(
          state.startDate
        )}`
      )
    );

    dispatch(
      fetchAllLoans({
        page: 1,
        limit: 10,
        time: `beforeDate=${convertToIso(
          state.endDate
        )}&afterDate=${convertToIso(state.startDate)}`,
      })
    );
  };

  return (
    <Box>
      <HStack mb="20px" alignItems="flex-end">
        <Input
          label="Start Date"
          name="startDate"
          type="date"
          minH="42px"
          value={state.startDate}
          onChange={handleDateChange}
        />

        <Input
          label="End Date"
          name="endDate"
          type="date"
          minH="42px"
          value={state.endDate}
          onChange={handleDateChange}
        />

        {filterType === 'referral' && (
          <Button
            minH="42px"
            onClick={applyReferralFilters}
            disabled={!state.startDate || !state.endDate}
          >
            Filter
          </Button>
        )}

        {filterType === 'users' && (
          <Button
            minH="42px"
            onClick={applyAllUsersFilter}
            disabled={!state.startDate || !state.endDate}
          >
            {btnTitle ? btnTitle : 'Filter'}
          </Button>
        )}

        {filterType === 'bcpl' && (
          <Button
            minH="42px"
            onClick={applyBcplFilter}
            disabled={!state.startDate || !state.endDate}
          >
            Filter
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default CustomDate;
