import { useState } from 'react';
import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ActionButton, Button, FilterSelect, Input } from 'components';
import { useUpdateTransferStatusMutation } from 'store/services/cico.service';

const CICOTransferUpdate = ({ _id, direction }) => {
  const toast = useToast();

  const [_updateStatus, { isLoading }] = useUpdateTransferStatusMutation();

  const [transferStatus, setTransferStatus] = useState('');
  const [txHash, setTxHash] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = async () => {
    try {
      const response = await _updateStatus({
        id: _id,
        transferStatus,
        txHash: txHash ? txHash : undefined,
        direction,
      });

      if (response?.data) {
        onClose && onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: 'Status updated',
          status: 'success',
          duration: 18000,
          isClosable: true,
        });
      }

      if (response?.error) {
        toast({
          position: 'bottom-right',
          title: 'Error',
          description: response?.error?.data?.error,
          status: 'error',
          duration: 18000,
          isClosable: true,
        });
      }
    } catch (error) {}
  };

  // console.log(transferStatus, 'TRANSFER STATUS');
  // const isDisabled = useMemo(() => {
  //   return direction === 'in' && txHash.length > 1;
  // }, [direction, txHash]);

  // console.log(isDisabled, direction, 'ISDISABLED');

  return (
    <>
      <ActionButton onClick={onOpen}>Update</ActionButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='420px'
          padding='8px 0 32px'
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
          >
            Update{' '}
          </ModalHeader>

          <ModalBody bg='#fff'>
            <FilterSelect
              minH='55px'
              width='100%'
              onChange={(e) => setTransferStatus(e.target.value)}
              label='Transfer Status'
              htmlFor='txStatus'
              // onChange={(e) => handleStatusUpdate(e.target.value, _id)}
            >
              <option>Update Status</option>

              <option value='TransferStarted'>TransferStarted</option>

              <option value='TransferFiatFundsDebited'>TransferFiatFundsDebited</option>

              <option value='TransferSendingCryptoFunds'>TransferSendingCryptoFunds</option>

              <option value='TransferAmlFailed'>TransferAmlFailed</option>

              <option value='TransferReadyForUserToSendCryptoFunds'>TransferReadyForUserToSendCryptoFunds</option>

              <option value='TransferReceivedCryptoFunds'>TransferReceivedCryptoFunds</option>

              <option value='TransferComplete'>TransferComplete</option>

              <option value='TransferFailed'>TransferFailed</option>
            </FilterSelect>

            {transferStatus === 'TransferComplete' && direction === 'in' && (
              <Box mt='24px'>
                <Input
                  label='Transaction Hash'
                  name='txHash'
                  placeholder='TxHash'
                  onChange={(e) => setTxHash(e.target.value)}
                />
              </Box>
            )}

            <Center mt='24px'>
              <Button
                onClick={handleSubmit}
                minH='55px'
                isLoading={isLoading}
                disabled={(transferStatus === 'TransferComplete' && !txHash && direction === 'in') || isLoading}
              >
                Submit
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default CICOTransferUpdate;
