import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Flex, Box, useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { formatDateToUTC, getHeaders, wrapBaseApi } from 'utils';
import { Button, CloseModal, TableDataStatus } from 'components';
import { put } from 'utils/axiosLib';
import { fetchBuySell } from 'store/slices/buySell.slice';
import { BASE_URL } from 'constant';

const BuySellModal = ({ isOpen, onClose, data, currentPage, filterStatus }) => {
  const {
    buySellTxId,
    closingAmount,
    closingRate,
    coin,
    createdAt,
    currency,
    destinationAmount,
    destinationUnit,
    openingAmount,
    openingRate,
    pairTicker,
    status,
    _id,
  } = data;
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const headers = getHeaders();

  const request = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/updateBuySellLedger/${_id}`);

    try {
      const response = await put(
        URL,
        {
          action: 'cleared',
          closingAmount,
          closingRate,
        },
        headers
      );

      if (response.data) {
        console.log(response, 'response');
        setLoading(false);

        dispatch(
          fetchBuySell({
            page: currentPage,
            limit: 10,
            status: filterStatus,
          })
        );
        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      key={_id}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        bg='#fff'
        maxWidth='568px'
        padding='40px 40px 0'
      >
        <Text
          fontSize='24px'
          textAlign='center'
          fontWeight='600'
        >
          Clear Transaction
        </Text>

        <ModalBody
          padding='0px'
          margin='40px 0'
          fontSize='14px'
        >
          <Box>
            <Flex
              fontWeight='medium'
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Transaction ID</Text>

              <Text fontWeight='medium'>{buySellTxId}</Text>
            </Flex>

            <Flex
              mb='24px'
              justifyContent='space-between'
            >
              <Text>Coin</Text>

              <Text
                fontWeight='medium'
                textTransform='uppercase'
              >
                {coin}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Currency</Text>

              <Text
                textTransform='uppercase'
                fontWeight='medium'
              >
                {currency}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Opening Amount</Text>

              <Text fontWeight='medium'>{openingAmount}</Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Closing Amount</Text>

              <Text fontWeight='medium'>{closingAmount}</Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Opening Rate</Text>

              <Text fontWeight='medium'>{Number(openingRate).toLocaleString()}</Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Closing Rate</Text>

              <Text fontWeight='medium'>{closingRate}</Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Status</Text>

              <Box>
                <TableDataStatus status={status} />
              </Box>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Pair</Text>

              <Text
                fontWeight='medium'
                textTransform='uppercase'
              >
                {pairTicker}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Destination Amount</Text>

              <Text fontWeight='medium'>{Number(destinationAmount)?.toLocaleString()}</Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Destination Unit</Text>

              <Text
                fontWeight='medium'
                textTransform='uppercase'
              >
                {destinationUnit}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Time and Date</Text>

              <Text fontWeight='medium'>{formatDateToUTC(createdAt)}</Text>
            </Flex>

            <Flex
              justifyContent='center'
              mt='3rem'
            >
              <Button
                minW='120px'
                onClick={request}
                isLoading={loading}
                disabled={loading}
                minH='48px'
              >
                Confirm
              </Button>
            </Flex>
          </Box>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BuySellModal;
