import { Box } from '@chakra-ui/react';

const ViewMore = ({ onClick, children, ...props }) => {
  return (
    <Box
      onClick={onClick}
      as="button"
      textAlign="center"
      borderRadius="20.4px"
      bg="transparent"
      color="brand.black"
      fontSize="10px"
      fontWeight="500"
      width="96px"
      height="32px"
      border="1px solid #6FBE45"
      fontFamily="PolySans"
      _hover={{
        transition: 'all 300ms ease-in-out',
        bg: 'brand.primary',
        color: '#fff',
        border: '1px solid #0D4740',
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ViewMore;
