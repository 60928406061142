const constant = {
  AUTH_TOKEN_KEY: 'authToken',
  AUTH_CICO_TOKEN_KEY: 'cicoAuth',
  USER_DATA_KEY: 'userData',

  DEPOSIT_TYPE: 'deposit_type',
  WITHDRAWAL_TYPE: 'withdrawal_type',
  DE_SIZE: 'size',
  DE_STATUS: 'status',
  DE_SOURCE: 'source',
  DE_SEARCH: 'search',
  DE_COIN: 'coim',
  CARD_TX_TYPE: 'card_tx_type',
  CARD_FAILED: 'card_failed',

  WI_SIZE: 'w_size',
  WI_STATUS: 'w_status',
  WI_SEARCH: 'w_search',

  WIDGET_KYC: 'widget_kyc',
  WIDGET_TRANSFER: 'widget_transfer',
  WIDGET_USER: 'widget_user',
  WIDGET_URL: 'widget_url',
  LAST_PATH: 'last_path',
  MAIN_URL: 'main_url',

  BITWAYA_WITHDRAWAL_STATUS: 'b_w_s',
  BITWAYA_DEPOSIT_STATUS: 'b_d_s',
  CICO_TRANSFER: 'cico_transfer',

  SELECTED_USER_ID: 'selected_user_id',

  PAGES: {
    LOGIN: '/login',
    HOME: '/dashboard',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    RESET_CODE: '/resetcode',
  },
};

export default constant;
