import { usePagination } from '@ajna/pagination';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { CurrencyFormat, HistoryTab, Loader, PaginationWrapper, ScrollRightContainer } from 'components';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMerchantTransactionsQuery } from 'store/services/merchants.service';
import { formatDateToUTC } from 'utils';

const MerchantTransaction = () => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const { data, isLoading } = useMerchantTransactionsQuery(
    {
      id,
      page,
      size: 20,
    },
    {
      skip: id ? false : true,
      page,
      size: 10,
    }
  );

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });
  console.log(data, 'THE DATA');

  return (
    <Box>
      <HistoryTab
        firstText=''
        secondText='Merchants Transactions'
        url='/dashboard/users?merchants'
      />

      {isLoading && <Loader />}
      <ScrollRightContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Merchant ID</Th>
              <Th>Signed Value</Th>
              <Th>Description</Th>
              <Th>Balance Before</Th>
              <Th>Balance After</Th>
              <Th>Transaction Ref.</Th>
              <Th>Type</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.merchantbankTx.map(
                ({
                  _id,
                  merchantId,
                  signedValue,
                  currency,
                  createdAt,
                  description,
                  transactionRef,
                  balanceAfter,
                  balanceBefore,
                  type,
                }) => {
                  return (
                    <Tr key={_id}>
                      <Td textTransform='capitalize'>{merchantId}</Td>

                      <Td>
                        <CurrencyFormat currency={currency} /> {Number(signedValue?.$numberDecimal).toLocaleString()}
                      </Td>

                      <Td>{description}</Td>

                      <Td>{Number(balanceBefore).toLocaleString()}</Td>

                      <Td>{Number(balanceAfter).toLocaleString()}</Td>

                      <Td>{transactionRef}</Td>

                      <Td textTransform='capitalize'>{type}</Td>

                      <Td>{formatDateToUTC(createdAt)}</Td>
                    </Tr>
                  );
                }
              )}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={isLoading ? 0 : data?.totalCount}
        currentPage={currentPage}
        setPage={setPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={20}
        offset={offset}
        pages={pages}
      />
    </Box>
  );
};

export default MerchantTransaction;
