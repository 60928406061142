import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Text, Flex, useToast } from '@chakra-ui/react';
import { post } from 'utils/axiosLib';
import { Button, CloseModal } from 'components';
import { fetchP2PContractsDisputeByID } from 'store/slices/p2p.slice';
import { getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';

const ReleaseRevertTrade = ({ isOpen, onClose, frozenRef, operation, _id }) => {
  console.log(frozenRef, _id);

  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const approveReleaseRevert = async (e) => {
    e.preventDefault();
    const id = `${_id}-${frozenRef}`;

    setLoading(true);

    const URL = wrapBaseApi(`${BASE_URL}/p2p/admin/flexiListing/flexiTrades/${_id}/update`);

    try {
      const response = await post(
        URL,
        {
          frozenRef,
          operation,
        },
        getHeaders()
      );

      if (response.data) {
        onClose();

        dispatch(
          fetchP2PContractsDisputeByID({
            id,
          })
        );

        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: typeof error.error === 'object' ? error.error.msg : error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      key={frozenRef}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        maxWidth='399px'
        padding={'32px 39px'}
      >
        <ModalHeader
          fontSize={'24px'}
          fontWeight={'600'}
          p='0'
          textAlign='center'
        >
          {operation === 'RELEASETRADE' ? 'Release Trade' : 'Revert Trade'}
        </ModalHeader>

        <ModalBody p='0'>
          <Text
            color='#4E4B66'
            fontSize={'14px'}
            fontWeight={'400'}
            margin={'13px 0 28px'}
            textAlign='center'
          >
            Are you sure you want to {operation === 'RELEASETRADE' ? 'release trade' : 'revert trade'}
          </Text>

          <Flex justifyContent={'center'}>
            <Button
              minW='158px'
              height='48px'
              type='submit'
              _hover={{
                boxShadow: 'none',
              }}
              disabled={loading}
              isLoading={loading}
              onClick={approveReleaseRevert}
            >
              {operation === 'RELEASETRADE' ? 'Release' : 'Revert'}
            </Button>
          </Flex>

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReleaseRevertTrade;
