import { Box } from '@chakra-ui/react';
import { PageTab, PageTitle, TableBoxWrapper } from 'components';
import { useLocationSearch } from 'hooks';
import { QrCodeTabLinkArray } from 'LinkArray/tabArray';
import { GenerateQrCode, ClaimedRewards } from 'pages';

const QrCode = () => {
  const search = useLocationSearch();
  return (
    <Box>
      <PageTitle title="QR Code" mb="52px" />

      <TableBoxWrapper bg="#FCFDFD">
        <PageTab mb="36px" linkArray={QrCodeTabLinkArray} />

        <Box>
          {search === '' && <GenerateQrCode />}

          {search === '?claimed-rewards' && <ClaimedRewards />}
        </Box>
      </TableBoxWrapper>
    </Box>
  );
};

export default QrCode;
