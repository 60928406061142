import { Table, Thead, Tbody, Tr, Th, Td, Box, Center } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateToUTC } from 'utils';
import { CurrencyLogo, Loader, P2PTableStatus, ScrollRightContainer } from 'components';
import { useLocationSearch } from 'hooks';

const P2PCancelledPendingTable = ({ contracts }) => {
  const { data, status } = contracts;
  const search = useLocationSearch();

  const navigate = useNavigate();

  const handleNavigate = (id) => {
    return navigate(`/dashboard/p2p/trades/${id}`);
  };

  return (
    <Box>
      {status === 'loading' && <Loader />}

      <ScrollRightContainer maxWidth={{ sm: '768px', md: '619px', lg: '1250px' }}>
        {data.trades.length >= 1 && (
          <Table>
            <Thead>
              <Tr>
                <Th>User</Th>
                <Th>Amount</Th>
                <Th>Type</Th>
                <Th>Coin traded</Th>
                <Th>Status</Th>
                <Th>Time</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data.trades
                .slice(0, 9)
                .map(({ _id, counterParties, amount, coin, adsType, createdAt, contractState, currency }) => {
                  const formattedCreatedAt = formatDateToUTC(createdAt);
                  const username = counterParties[0].user.username;

                  return (
                    <Tr
                      key={_id}
                      cursor='pointer'
                      onClick={() => handleNavigate(_id)}
                    >
                      <Td textTransform='capitalize'>{username ? username : 'N/A'}</Td>

                      <Td textTransform='uppercase'>
                        <CurrencyLogo currency={currency} /> {amount ? `${Number(amount).toLocaleString()}` : 'N/A'}
                      </Td>

                      <Td textTransform='capitalize'>{adsType ? adsType : 'N/A'}</Td>

                      <Td textTransform='uppercase'>{coin ? coin : 'N/A'}</Td>

                      <Td textTransform='capitalize'>
                        <P2PTableStatus status={contractState} />
                      </Td>

                      <Td whiteSpace='break-spaces'>{formattedCreatedAt}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        )}
      </ScrollRightContainer>

      <Center
        mt='55px'
        fontSize='14px'
        color='#0D4740'
        fontWeight='500'
        textDecor='underline'
        fontFamily='PolySans'
        lineHeight='17px'
      >
        <Link to={`/dashboard/p2p/trades${search}`}>See More</Link>
      </Center>
    </Box>
  );
};

export default P2PCancelledPendingTable;
