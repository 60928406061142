import { DeleteIcon } from '@chakra-ui/icons';

const DeleteBatchPaymentRecord = ({ handleDelete }) => {
  return (
    <DeleteIcon
      fontSize="0.85rem"
      color="#303131"
      cursor="pointer"
      onClick={handleDelete}
    />
  );
};

export default DeleteBatchPaymentRecord;
