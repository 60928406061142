import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Button,
  ExportRewardCSV,
  Loader,
  PageTitle,
  ScrollRightContainer,
  RewardsModal,
  RewardsDateFilter,
} from 'components';
import { fetchClaimedRewards } from 'store/slices/claimedRewards.slice';
import { formatDateToUTC } from 'utils';

const ClaimedRewards = () => {
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [showDateFilter, setShowDateFilter] = useState(false);
  const [modalData, setModalData] = useState();

  const { data, claimedDates, status } = useSelector(
    (state) => state.claimedRewards.rewards
  );

  useEffect(() => {
    dispatch(
      fetchClaimedRewards({
        claimedDates: claimedDates,
      })
    );
  }, [dispatch, claimedDates]);

  const handleModalData = (res) => {
    setModalData(res);
    onOpen();
  };

  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <PageTitle header={false} title="Claimed Rewards" />

        <Flex gridGap={'16px'}>
          <ExportRewardCSV claimedDates={claimedDates} />

          <Button
            minH="42px"
            bg="#fff"
            color="brand.primary"
            border="1px solid #03a438"
            onClick={() => setShowDateFilter(!showDateFilter)}
          >
            Filter
          </Button>
        </Flex>
      </Flex>

      <Flex justifyContent={'flex-end'} m="24px 0 12px">
        {showDateFilter && <RewardsDateFilter />}
      </Flex>

      <ScrollRightContainer>
        <Box>
          <Box mt="16px">
            <>
              {status === 'loading' ? (
                <Box margin="8px 0">
                  <Loader />
                </Box>
              ) : (
                <Box>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>S/N</Th>
                        <Th>Full name</Th>
                        <Th>Description</Th>
                        <Th>Amount</Th>
                        <Th>Claimed</Th>
                        <Th>Transaction Counts</Th>
                        <Th>Date</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {data &&
                        data.stat.map((res, i) => {
                          const { name, rewards, transactionCounts } = res;

                          return (
                            <Tr
                              key={i}
                              cursor="pointer"
                              _hover={{
                                borderLeft: '2px solid #0D4740',
                                borderRight: '2px solid #0D4740',
                              }}
                              onClick={() => handleModalData(res)}
                            >
                              <Td>{i + 1}</Td>
                              <Td textTransform="capitalize">{name}</Td>
                              <Td>{rewards[0].description}</Td>
                              <Td textTransform={'uppercase'}>
                                {rewards[0].reward.value}{' '}
                                {rewards[0].reward?.unit}
                              </Td>
                              <Td textTransform={'capitalize'}>
                                {String(rewards[0].claimed)}
                              </Td>
                              <Td>{transactionCounts}</Td>
                              <Td>{formatDateToUTC(rewards[0].claimedAt)}</Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Box>
              )}

              {modalData && (
                <RewardsModal
                  isOpen={isOpen}
                  onClose={onClose}
                  data={modalData}
                />
              )}
            </>
          </Box>
        </Box>
      </ScrollRightContainer>
    </>
  );
};

export default ClaimedRewards;
