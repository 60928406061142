import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';
import { PartnersLeaderboardTotalEarnings, PartnersLeaderboardUserCount } from 'components';
import { fetchLeaderboardRanking, fetchLeaderboardRanking2 } from 'store/slices/partners.slice';

const PartnersLeaderboard = () => {
  const dispatch = useDispatch();

  const { data, status, errorMessage } = useSelector((state) => state.partners.leaderBoardRanking);

  const {
    data: dataUSD,
    status: statusUSD,
    errorMessage: errMsgUSD,
  } = useSelector((state) => state.partners.leaderBoardRanking2);

  useEffect(() => {
    dispatch(
      fetchLeaderboardRanking({
        limit: 10,
        sortBy: 'referredCount:desc',
      })
    );
    dispatch(fetchLeaderboardRanking2({}));
  }, [dispatch]);

  return (
    <Box>
      <Flex
        gridGap='33px'
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Box
          maxWidth={'437px'}
          width='100%'
        >
          <PartnersLeaderboardUserCount
            data={data}
            status={status}
            errorMessage={errorMessage}
          />
        </Box>

        <Box
          maxWidth={'571px'}
          width='100%'
        >
          <PartnersLeaderboardTotalEarnings
            data={dataUSD}
            status={statusUSD}
            errorMessage={errMsgUSD}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default PartnersLeaderboard;
