import { useState } from 'react';
import { CSVDownload } from 'react-csv';
import { Box } from '@chakra-ui/react';
import { Button } from 'components';
import { get } from 'utils/axiosLib';
import { getHeaders, wrapBaseApi } from 'utils';

const ExportBitwaya = ({ transferStatus, transferType, queries, paymentStatus, exportType }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  console.log(loading, 'LOADING...');

  console.log(data.length, 'DATA LENGHT');

  const headers = [
    { label: 'Email', key: 'identification.id' },
    { label: 'Transfer Status', key: 'transferStatus' },
    { label: 'Payment Status', key: 'paymentStatus' },
    { label: 'Fiat Type', key: 'fiatType' },
    { label: 'Fiat Amount', key: 'fiatAmount' },
    { label: 'Crypto Type', key: 'cryptoType' },
    { label: 'Processing Fee', key: 'processingFee' },
    { label: 'Network Fee', key: 'networkFee' },
    { label: 'Crypto Volume', key: 'cryptoVolume' },
    { label: 'Account Name', key: 'fiatAccountData.accountName' },
    { label: 'Account Number', key: 'fiatAccountData.accountNumber' },
    { label: 'Network/BankCode', key: 'fiatAccountData.networkOrBankCode' },
    { label: 'Direction', key: 'direction' },
    { label: 'Date/Time', key: 'createdAt' },
  ];

  const handleExport = async () => {
    setLoading(true);

    try {
      const response = await get(
        wrapBaseApi(
          exportType === 'deposit'
            ? `/wallet/widget/admin/transfer/deposits?page=${1}&paymentStatus=${paymentStatus}&${
                queries ? queries : ''
              }`
            : `/wallet/widget/admin/transfer/withdrawals?page=${1}&transferStatus=${transferStatus}${
                transferType ? `&${transferType}` : ''
              }${queries ? `&${queries}` : ''}`
        ),
        getHeaders()
      );

      if (response.data) {
        setData(response.data.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box>
      {data.length > 0 && loading === false ? (
        <CSVDownload
          filename='bitwaya.csv'
          data={data}
          headers={headers}
        />
      ) : (
        ''
      )}

      <Button
        minH='42px'
        onClick={handleExport}
        disabled={loading}
        isLoading={loading}
      >
        Export
      </Button>
    </Box>
  );
};

export default ExportBitwaya;
