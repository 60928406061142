import { Box, Flex, Text } from '@chakra-ui/react';
import { HomeWalletCard } from 'components';
import NairaWalletSVG from '../../assets/svg/naira-bg.svg';
import KenyaShillingsWalletSVG from '../../assets/svg/kenya-shillings-bg.svg';
import GhanaCedisWalletSVG from '../../assets/svg/cedis.svg';
import NairaPNG from '../../assets/png/naira.png';
import KShillingsPNG from '../../assets/png/kenya-shillings.png';
import CedisPNG from '../../assets/png/cedis.png';

const HomeWallet = () => {
  return (
    <Box mt='46px'>
      <Text
        mb='15px'
        fontFamily='PolySans'
        fontSize='18px'
        fontWeight='600'
        textAlign={{ sm: 'center', md: 'initial' }}
      >
        Wallets
      </Text>

      <Flex
        gridGap='19px'
        flexWrap={{ sm: 'wrap', md: 'initial' }}
        justifyContent={{ sm: 'center', md: 'initial' }}
      >
        <HomeWalletCard
          amount='₦ 12,235,871,101.90'
          bgImage={NairaWalletSVG}
          currencyIcon={NairaPNG}
          currencyName='Naira'
        />

        <HomeWalletCard
          amount='Ksh 12,235,871,101.90'
          bgImage={KenyaShillingsWalletSVG}
          currencyIcon={KShillingsPNG}
          currencyName='Kenya Shillings'
          pl='27px'
        />

        <HomeWalletCard
          amount='C 12,235,871,101.90'
          bgImage={GhanaCedisWalletSVG}
          currencyIcon={CedisPNG}
          currencyName='Naira'
        />
      </Flex>
    </Box>
  );
};

export default HomeWallet;
