import { Flex } from '@chakra-ui/react';
import { FilterTab } from 'components';

const DateFilter = ({ handleClick, active, bg }) => {
  return (
    <Flex
      gridGap='16px'
      justifyContent='space-between'
      alignItems={'center'}
    >
      <FilterTab
        active={active === `weekly=${true}`}
        text='Last 7 days'
        onClick={() => handleClick(`weekly=${true}`)}
        bg={bg}
      />

      <FilterTab
        active={active === `monthly=${true}`}
        text='Last month'
        onClick={() => handleClick(`monthly=${true}`)}
        bg={bg}
      />

      <FilterTab
        active={active === 'custom'}
        text='Custom'
        onClick={() => handleClick('custom')}
        bg={bg}
      />
    </Flex>
  );
};

export default DateFilter;
