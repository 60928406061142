import { Button as ChakraButton } from '@chakra-ui/react';

const Button = ({ children, disabled, isLoading, type, ...rest }) => {
  return (
    <ChakraButton
      minW="150px"
      minH="60px"
      type={type}
      borderRadius="42.5px"
      color="#fff"
      bg="brand.primary"
      border="none"
      isLoading={isLoading}
      fontFamily="PolySans"
      textTransform="capitalize"
      transition="all 300ms ease-in-out"
      p="10px 19.5px"
      disabled={disabled}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      _hover={{
        cursor: disabled ? 'not-allowed !important' : 'pointer',
        transition: 'all 300ms ease-in-out',
      }}
      _focus={{
        transition: 'all 300ms ease-in-out',
      }}
      _active={{
        transition: 'all 300ms ease-in-out',
      }}
      _disabled={{
        opacity: 0.5,
        pointerEvents: 'none',
      }}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
