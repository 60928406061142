import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  ModalHeader,
  useDisclosure,
  Flex,
  Box,
  Center,
  useToast,
} from '@chakra-ui/react';
import { Button, CloseModal, ConfigCheckbox, FilterSelect, TableDataStatus, ViewMore } from 'components';
import { useCopy } from 'hooks';
import { useState } from 'react';
import { useUpdatePaymentMutation } from 'store/services/pay.service';

const WidgetPayModal = ({ modalData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    _id,
    amount,
    unit,
    fee,
    direction,
    paymentStatus,
    amountReceived,
    paymentAccountData,
    sender,
    txId,
    paymentUrl,
  } = modalData;

  const { hasCopied, onCopy, value } = useCopy();
  const [_paymentStatus, setPaymentStatus] = useState(paymentStatus);

  const [_updatePayment, { isLoading }] = useUpdatePaymentMutation();
  const toast = useToast();
  const [sendWebhook, setSendwebhook] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await _updatePayment({
        paymentId: _id,
        paymentStatus: _paymentStatus,
        sendWebhook,
        amountReceived: paymentStatus === 'confirmed' ? undefined : amountReceived,
      }).unwrap();

      if (response) {
        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: 'bottom-right',
        title: 'Error',
        description: typeof error?.data?.error === 'string' ? error?.data?.error : error?.data?.error?.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <ViewMore onClick={onOpen}>View More</ViewMore>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='520px'
          padding='24px 24px 8px'
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
            p='0'
          >
            Pay Details
          </ModalHeader>

          <ModalBody bg='#fff'>
            <BoxWrapper
              title='Direction'
              description={direction}
              textTransform='capitalize'
            />

            <BoxWrapper
              title='Amount'
              description={`${Number(amount).toLocaleString()} ${String(unit).toUpperCase()}`}
            />

            <BoxWrapper
              title='Fee'
              description={fee}
            />

            <BoxWrapper
              title='Amount Received'
              description={`${Number(amountReceived).toLocaleString()} ${String(unit).toUpperCase()}`}
            />

            <BoxWrapper
              title='Status'
              description=''
            >
              <TableDataStatus status={paymentStatus} />
            </BoxWrapper>

            <BoxWrapper
              title='Transaction ID'
              description=''
            >
              <Flex
                onClick={() => onCopy(txId)}
                cursor='pointer'
                alignItems='center'
                gridGap='8px'
              >
                <Box fontWeight='600'>{txId}</Box>

                {hasCopied && value === txId ? <CheckIcon /> : <CopyIcon />}
              </Flex>
            </BoxWrapper>

            {paymentAccountData && (
              <>
                <Text
                  textAlign='center'
                  mb='12px'
                  fontWeight='600'
                >
                  Payment Account Data
                </Text>

                <BoxWrapper
                  title='Account Name'
                  description={paymentAccountData.accountName}
                />

                <BoxWrapper
                  title='Account Number'
                  description=''
                >
                  <Flex
                    onClick={() => onCopy(paymentAccountData.accountNumber)}
                    cursor='pointer'
                    alignItems='center'
                    gridGap='8px'
                  >
                    <Box fontWeight='600'>{paymentAccountData.accountNumber}</Box>

                    {hasCopied && value === paymentAccountData.accountNumber ? <CheckIcon /> : <CopyIcon />}
                  </Flex>
                </BoxWrapper>

                <BoxWrapper
                  title='Bank Code'
                  description={paymentAccountData.networkOrBankCode}
                />

                {paymentUrl && (
                  <BoxWrapper
                    title='Payment URL'
                    description=''
                    mt='12px'
                  >
                    <a
                      href={paymentUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img
                        src={paymentUrl}
                        alt='Payment Upload'
                        width='100px'
                        height='100px'
                      />
                    </a>
                  </BoxWrapper>
                )}
              </>
            )}

            <Text
              textAlign='center'
              mb='12px'
              fontWeight='600'
            >
              Sender Account Data
            </Text>

            <BoxWrapper
              title='Sender Label'
              description={sender.senderLabel}
            />
            <BoxWrapper
              title='Sender ID'
              description={sender.senderId}
            />
            <BoxWrapper
              title='Sender ID Type'
              description={sender.idType}
            />

            <Box mt='24px'>
              <Flex
                gap='12px'
                mb='24px'
              >
                <ConfigCheckbox
                  defaultChecked={sendWebhook}
                  onChange={() => setSendwebhook(!sendWebhook)}
                />

                <Text>Send Webhook</Text>
              </Flex>

              <FilterSelect
                minH='60px'
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <option>Update Status</option>
                <option value='pending'>Payment Pending</option>
                <option value='started'>Payment Started</option>
                <option value='made'>Payment Made</option>
                <option value='confirmed'>Payment Confirmed</option>
                <option value='failed'>Payment Failed</option>
                <option value='cancelled'>Payment Cancelled</option>
                <option value='incomplete'>Payment Incomplete</option>
                <option value='timedout'>Payment Timedout</option>
              </FilterSelect>

              <Center>
                <Button
                  type='submit'
                  onClick={handleSubmit}
                  mt='24px'
                  minW='320px'
                  minH='60px'
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Update
                </Button>
              </Center>
            </Box>
          </ModalBody>

          <CloseModal onClick={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default WidgetPayModal;

const BoxWrapper = ({ children, title, description, ...props }) => {
  return (
    <Flex
      mb='8px'
      justifyContent='space-between'
      width='100%'
      {...props}
    >
      <Text>{title}</Text>

      <Box>
        <Text
          fontWeight='600'
          cursor='pointer'
        >
          {description}
        </Text>

        <Box>{children}</Box>
      </Box>
    </Flex>
  );
};
