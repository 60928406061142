import { usePagination } from '@ajna/pagination';
import { Box, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react';
import {
  FilterSelect,
  Loader,
  PageTab,
  PageTitle,
  PaginationWrapper,
  RoundBox,
  ScrollRightContainer,
  SearchInput,
  TableBoxWrapper,
  VerificationTable,
} from 'components';
import { useDebounce, useLocationSearch } from 'hooks';
import useLocalStorageState from 'hooks/useLocalStorageState';
import IntegrationsKYC from 'pages/Integrations/IntegrationsKYC';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetPendingDocumentQuery } from 'store/services/request.service';
import { fetchUserDocuments } from 'store/slices/user.slice';
import { constant } from 'utils';

const Verification = () => {
  const search = useLocationSearch();
  const dispatch = useDispatch();
  const { data, status, totalCount } = useSelector((state) => state.allUsers.document);
  const [size, setSize] = useState(10);

  const [sortBy, setSortBy] = useLocalStorageState('VERIF_DOC_LIST_SORT_BY', 'createdAt:asc');
  const [otherQuery, setOtherQuery] = useLocalStorageState(`VERIF_DOC_LIST_OTHER_QUERY`, '');
  const [searchString, setSearchString] = useLocalStorageState(`VERIF_DOC_LIST_QUERY`, '');

  const [tab, setTab] = useLocalStorageState(`VERIF_DOC_LIST_TAB`, search.split('?')[1] || 'pending');

  useEffect(() => {
    let theTab = search.split('?')[1] || 'pending';
    if (!['pending', 'approved', 'denied'].includes(theTab)) {
      theTab = 'pending';
    }
    setTab(theTab);
  }, [search, setTab]);

  const [refresher, setRefresher] = useState(0);

  const refetch = useCallback(() => {
    setRefresher(refresher + 1);
  }, [refresher]);

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  const { data: pendingData } = useGetPendingDocumentQuery();

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: size,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const handleFilter = (key, value) => {
    if (key === 'sortBy') {
      setSortBy(value);
    } else {
      setOtherQuery(`${key}=${value}`);
    }
    setCurrentPage(1);
  };
  useEffect(() => {
    dispatch(
      fetchUserDocuments({
        page: currentPage,
        limit: size,
        status: tab,
        search: searchString,
        sortBy,
        otherQuery,
      })
    );
  }, [dispatch, refresher, tab, pageSize, size, currentPage, sortBy, otherQuery, search, searchString]);

  const VerificationTabLinkArray = [
    {
      id: 1,
      search: '?pending',
      otherSearchValue: '',
      title: (
        <Flex
          justifyContent='center'
          alignItems='center'
          gridGap='8px'
        >
          Pending
          <RoundBox count={pendingData?.totalCount} />
        </Flex>
      ),
      to: '/dashboard/verification?pending',
    },
    {
      id: 2,
      search: '?approved',
      title: 'Approved',
      to: '/dashboard/verification?approved',
    },
    {
      id: 3,
      search: '?denied',
      title: 'Rejected',
      to: '/dashboard/verification?denied',
    },
  ];

  const navigate = useNavigate();
  const [storageValue, setStorage] = useLocalStorageState(constant.WIDGET_KYC, 'bitwaya');

  const handleWidget = (e) => {
    setStorage(e);

    navigate('/dashboard/verification');
  };

  const isMainPage = storageValue === 'main';

  return (
    <Box>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        mb='52px'
      >
        <PageTitle title={isMainPage ? 'Account Verification' : 'Bitwaya Integration'} />

        <Flex
          alignItems='center'
          gridGap='8px'
        >
          <RadioGroup
            defaultValue={isMainPage ? 'main' : 'bitwaya'}
            onChange={handleWidget}
            colorScheme='green'
          >
            <Flex gap='24px'>
              <Radio value='main'>Main</Radio>
              <Radio value='bitwaya'>Bitwaya</Radio>
            </Flex>
          </RadioGroup>
        </Flex>
      </Flex>

      <>
        {isMainPage ? (
          <TableBoxWrapper>
            <PageTab
              count=''
              linkArray={VerificationTabLinkArray}
            />

            <Flex
              gridGap='1rem'
              alignItems='center'
              flexDir={{ base: 'column', tb: 'row' }}
            >
              <Box width={{ base: '100%', md: 'initial' }}>
                <SearchInput
                  width={{ base: '100%', tb: '320px' }}
                  onChange={debouncedChangeHandler}
                  defaultValue={searchString || ''}
                  placeholder='Search for user id, name, or email'
                />
              </Box>

              <Flex gridGap='1rem'>
                <FilterSelect
                  width='120px'
                  onChange={(e) => handleFilter('sortBy', `createdAt:${e.target.value}`)}
                >
                  <option value={'asc'}>Sort</option>
                  <option value={'asc'}>Ascending</option>
                  <option value={'desc'}>Descending</option>
                </FilterSelect>

                <FilterSelect
                  width='110px'
                  onChange={(e) => handleFilter('kycLevel', e.target.value)}
                >
                  <option value={''}>KYC Level</option>
                  <option value={'two'}>two</option>
                  <option value={'three'}>three</option>
                </FilterSelect>

                <FilterSelect
                  width='90px'
                  onChange={handleSize}
                >
                  <option value={10}>Size</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </FilterSelect>
              </Flex>
            </Flex>

            {status === 'loading' && <Loader />}

            <Box mt='32px'>
              <ScrollRightContainer>
                <VerificationTable
                  data={data}
                  refetch={refetch}
                />
              </ScrollRightContainer>
            </Box>

            <PaginationWrapper
              setCurrentPage={setCurrentPage}
              totalDataCount={totalCount}
              currentPage={currentPage}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              offset={offset}
              pages={pages}
              size={10}
            />
          </TableBoxWrapper>
        ) : (
          <TableBoxWrapper padding='24px'>
            <Text
              mb='24px'
              fontWeight='700'
            >
              KYC
            </Text>
            <IntegrationsKYC />
          </TableBoxWrapper>
        )}
      </>
    </Box>
  );
};

export default Verification;
