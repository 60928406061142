import { useState } from 'react';
import { Center, Text } from '@chakra-ui/react';
import { Button } from 'components';
import { BASE_URL } from 'constant';
import { fetchLiens } from 'store/slices/liens.slice';
import { post } from 'utils/axiosLib';
import { wrapBaseApi } from 'utils';

const RunPendingLien = ({ data, dispatch, headers, toast, onClose, id }) => {
  const [loading, setLoading] = useState(false);

  const handleLienAction = async (e) => {
    e.preventDefault();
    setLoading(true);

    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/liens/run`);

    try {
      const response = await post(URL, {}, headers);

      if (response.data) {
        setLoading(false);
        dispatch(
          fetchLiens({
            id,
            page: 1,
            limit: 10,
          })
        );

        onClose();

        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };
  return (
    <Center flexDir='column'>
      <Text
        textAlign='center'
        mb='1.5rem'
      >
        Are you sure you want to{' '}
        <Text
          fontWeight='500'
          as='span'
          textTransform='capitalize'
        >
          {data?.fullName}
        </Text>{' '}
        pending liens?
      </Text>

      <Button
        mt='2rem'
        disabled={loading}
        isLoading={loading}
        onClick={handleLienAction}
        _focus={{
          border: 'none',
        }}
      >
        Yes
      </Button>
    </Center>
  );
};

export default RunPendingLien;
