import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Flex, Box } from '@chakra-ui/react';
import { CopyIcon, CheckIcon } from '@chakra-ui/icons';
import { formatDateToUTC } from 'utils';
import { ApproveDenyWithdrawal, CloseModal, CurrencyFormat, TableDataStatus } from 'components';
import { useCopy, useSetUserStorage } from 'hooks';

const WalletWithdrawalModal = ({
  isOpen,
  onClose,
  data,
  actionValue,
  pageSize,
  currentPage,
  type,
  status: txStatus,
}) => {
  const {
    _id,
    user,
    createdAt,
    coin,
    withdrawalType,
    status,
    amount,
    bankDetails,
    addressRef,
    txRef,
    destination,
    withdrawalFee,
    approvedBy,
    txLink,
    txHash,
    canceledBy,
    deniedBy,
    payoutId,
    enterpriseWithdrawalId,
    reason,
  } = data;

  const { hasCopied, onCopy, value } = useCopy();

  useSetUserStorage(user?.userId, user?.fullName);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      key={_id}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        bg='#fff'
        maxWidth='568px'
        padding='40px 40px 0'
      >
        <Text
          fontSize='24px'
          textAlign='center'
          fontWeight='600'
        >
          Transaction Details
        </Text>

        <ModalBody
          padding='0px'
          margin='40px 0'
          fontSize='14px'
        >
          <Box>
            <Flex
              mb='24px'
              justifyContent='space-between'
            >
              <Text>ID</Text>

              <Text
                fontWeight='500'
                textTransform='lowercase'
              >
                {_id}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Full name</Text>

              <Text fontWeight='500'>{user?.fullName}</Text>
            </Flex>

            <Flex
              mb='24px'
              justifyContent='space-between'
            >
              <Text>Email</Text>

              <Text
                fontWeight='500'
                textTransform='lowercase'
              >
                <a
                  href={`/dashboard/users/${user?.userId}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {user?.email}
                </a>
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Price</Text>

              <Text
                fontWeight='500'
                textTransform='uppercase'
              >
                <CurrencyFormat currency={coin} />
                &nbsp; {withdrawalType === 'crypto' ? amount : Number(amount).toLocaleString(8)}{' '}
                {withdrawalType === 'crypto' && destination?.destinationCoin}
              </Text>
            </Flex>

            {withdrawalType === 'crypto' && (
              <>
                <Flex
                  justifyContent='space-between'
                  mb='24px'
                >
                  <Text>Withdrawal Fee</Text>

                  <Text
                    fontWeight='500'
                    textTransform='uppercase'
                  >
                    {withdrawalFee} {coin}
                  </Text>
                </Flex>
              </>
            )}

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Status</Text>

              <Box fontWeight='500'>
                <TableDataStatus
                  status={status}
                  withIcon={false}
                />
              </Box>
            </Flex>

            {reason && (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Reason</Text>

                <Text fontWeight='500'>{reason}</Text>
              </Flex>
            )}
            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Reference</Text>

              <Text
                fontWeight='500'
                textTransform='uppercase'
              >
                {txRef}{' '}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Address Reference</Text>

              <Text fontWeight='500'>{addressRef} </Text>
            </Flex>

            {withdrawalType === 'fiat' && payoutId && (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Payout ID</Text>

                <Text
                  fontWeight='500'
                  textTransform='uppercase'
                >
                  {payoutId}
                </Text>
              </Flex>
            )}

            {withdrawalType === 'crypto' && enterpriseWithdrawalId && (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Enterprise Withdrawal ID</Text>

                <Text
                  fontWeight='500'
                  textTransform='uppercase'
                >
                  {enterpriseWithdrawalId}
                </Text>
              </Flex>
            )}

            {bankDetails ? (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Bank Details</Text>

                <Box
                  textAlign='right'
                  fontWeight='500'
                  textTransform='capitalize'
                >
                  <Text>{bankDetails?.bankAccountName}</Text>
                  <Text>{bankDetails?.bankAccountNumber}</Text>
                  <Text>{bankDetails?.bankName}</Text>
                </Box>
              </Flex>
            ) : (
              ''
            )}

            {destination ? (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Destination</Text>

                <Box
                  textAlign='right'
                  fontWeight='500'
                  maxWidth='350px'
                  onClick={() => onCopy(destination?.destinationAddress)}
                  cursor='pointer'
                >
                  <Text>
                    {destination?.destinationAddress}{' '}
                    {hasCopied && value === destination?.destinationAddress ? <CheckIcon /> : <CopyIcon />}
                  </Text>
                </Box>
              </Flex>
            ) : (
              ''
            )}

            {destination?.destinationCoin === 'xlm' && (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Destination Memo</Text>

                <Box
                  textAlign='right'
                  fontWeight='500'
                >
                  <Text>{destination?.destinationMemo} </Text>
                </Box>
              </Flex>
            )}

            {destination?.destinationCoin === 'xrp' && (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Destination Tag Number</Text>

                <Box
                  textAlign='right'
                  fontWeight='500'
                >
                  <Text>{destination?.destinationTagNumber} </Text>
                </Box>
              </Flex>
            )}

            {withdrawalType === 'crypto' && (
              <>
                <Flex
                  justifyContent='space-between'
                  mb='24px'
                >
                  <Text>Transaction Hash</Text>

                  <Flex
                    textAlign='right'
                    fontWeight='500'
                    cursor='pointer'
                    onClick={() => onCopy(txHash)}
                    alignItems='center'
                    gridGap='3px'
                  >
                    <Text maxWidth='300px'>{txHash ? txHash : 'N/A'}</Text>

                    {txHash && <>{txHash === value && hasCopied ? <CheckIcon /> : <CopyIcon />}</>}
                  </Flex>
                </Flex>

                <Flex
                  justifyContent='space-between'
                  mb='24px'
                >
                  <Text>Transaction Link</Text>

                  <Box
                    textAlign='right'
                    fontWeight='500'
                    maxWidth='350px'
                    cursor='pointer'
                  >
                    <Text
                      as='a'
                      href={txLink}
                      target='_blank'
                      color='brand.primary'
                      textDecoration={txLink ? 'underline' : 'none'}
                    >
                      {txLink ? txLink : 'N/A'}
                    </Text>
                  </Box>
                </Flex>
              </>
            )}

            {status === 'approved' && (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Approved By</Text>

                <Box
                  textAlign='right'
                  fontWeight='500'
                  maxWidth='350px'
                >
                  <Text textTransform='capitalize'>{approvedBy?.fullName}</Text>

                  <Text textTransform='lowercase'>{approvedBy?.email}</Text>
                </Box>
              </Flex>
            )}

            {status === 'cancelled' && (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Cancelled By</Text>

                <Box
                  textAlign='right'
                  fontWeight='500'
                  maxWidth='350px'
                >
                  <Text textTransform='capitalize'>{canceledBy?.fullName}</Text>

                  <Text textTransform='lowercase'>{canceledBy?.email}</Text>
                </Box>
              </Flex>
            )}

            {status === 'denied' && (
              <Flex
                justifyContent='space-between'
                mb='24px'
              >
                <Text>Denied By</Text>

                <Box
                  textAlign='right'
                  fontWeight='500'
                  maxWidth='350px'
                >
                  <Text textTransform='capitalize'>{deniedBy?.fullName}</Text>

                  <Text textTransform='lowercase'>{deniedBy?.email}</Text>
                </Box>
              </Flex>
            )}

            <Flex
              justifyContent='space-between'
              mb='24px'
            >
              <Text>Time and Date</Text>

              <Text fontWeight='500'>{formatDateToUTC(createdAt)}</Text>
            </Flex>
          </Box>

          <ApproveDenyWithdrawal
            _id={_id}
            actionValue={actionValue}
            onClose={onClose}
            withdrawalType={type}
            currentPage={currentPage}
            pageSize={pageSize}
            status={txStatus}
          />

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WalletWithdrawalModal;
