import { Box, Flex, Heading } from '@chakra-ui/react';
import { PeopleSVG, ProfileStatsSVG } from 'assets';
import { PartnerStatsCard } from 'components';

const PartnerStats = () => {
  return (
    <Box>
      <Heading
        fontSize='18px'
        fontWeight='600'
        m='18px 0'
      >
        Stats
      </Heading>

      <Flex
        gridGap='12px'
        flexDir={{ base: 'column', tb: 'row' }}
      >
        <PartnerStatsCard
          title='Total Number of Partners'
          icon={<PeopleSVG />}
          color='#F4A51D'
          count='2098'
        />

        <PartnerStatsCard
          title='Total Number of users referred'
          icon={<ProfileStatsSVG />}
          color='#6FBE45'
          count='100'
        />
      </Flex>
    </Box>
  );
};

export default PartnerStats;
