import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Text, VStack, useToast, Box } from '@chakra-ui/react';
import { Button, Input } from 'components';
import { put } from 'utils/axiosLib';
import { fetchAllWithdrawal } from 'store/slices/wallet.slice';
import { getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';

const ApproveDenyWithdrawal = ({ _id, onClose, actionValue, withdrawalType, currentPage, pageSize, status }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [reason, setReason] = useState('');
  const [txHash, setTxHash] = useState('');
  const toast = useToast();

  const headers = getHeaders();

  const handleChange = (e) => {
    setReason(e.target.value);

    if (e.target.value.length >= 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleTxHash = (e) => {
    setTxHash(e.target.value);

    if (e.target.value.length >= 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const approveDenyTransaction = async (e) => {
    e.preventDefault();
    setLoading(true);
    const URL = wrapBaseApi(`${BASE_URL}/wallet/admin/withdrawal/${_id}/${actionValue}`);

    try {
      const response = await put(
        URL,
        {
          reason: actionValue === 'deny' ? reason : undefined,
          txHash: withdrawalType === 'crypto' ? txHash : undefined,
        },
        headers
      );

      if (response.data) {
        dispatch(
          fetchAllWithdrawal({
            page: currentPage,
            limit: pageSize,
            withdrawalType: withdrawalType,
            status,
          })
        );

        onClose();

        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {actionValue && (
        <VStack mt='2rem'>
          <Text>
            Are you sure you want to{' '}
            <Text
              as='span'
              fontWeight='600'
            >
              {actionValue}
            </Text>{' '}
            this transaction
          </Text>

          {actionValue === 'deny' && (
            <Box>
              <Box margin='1rem 0'>
                <Input
                  width='380px'
                  minHeight='48px'
                  name='reason'
                  isRequired
                  placeholder='Reason'
                  onChange={handleChange}
                />
              </Box>
            </Box>
          )}

          {withdrawalType === 'crypto' && actionValue === 'approve' && (
            <Box>
              <Box margin='1rem 0'>
                <Input
                  width='380px'
                  minHeight='48px'
                  name='reason'
                  isRequired
                  placeholder='Transaction hash'
                  onChange={handleTxHash}
                />
              </Box>
            </Box>
          )}

          <Box>
            <Box mt={actionValue === 'approve' ? '1rem' : '0'}>
              <Button
                disabled={loading || (actionValue === 'deny' && disabled) || (withdrawalType === 'crypto' && disabled)}
                isLoading={loading}
                onClick={approveDenyTransaction}
                minH='48px'
              >
                Yes
              </Button>
            </Box>
          </Box>
        </VStack>
      )}
    </>
  );
};

export default ApproveDenyWithdrawal;
