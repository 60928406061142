import { useState } from 'react';
import { CSVDownload } from 'react-csv';
import { Box } from '@chakra-ui/react';
import { Button } from 'components';
import { useSelector } from 'react-redux';
import { get } from 'utils/axiosLib';
import { BASE_URL } from 'constant';
import { getHeaders, wrapBaseApi } from 'utils';

const ExportCardTransactions = ({ txType, failedTx, search, dateFilter }) => {
  const [data, setData] = useState([]);
  const { beforeAndAfter } = useSelector((state) => state.cards.cardTransactions);

  const [loading, setLoading] = useState(false);

  const headers = [
    { label: 'FullName', key: 'userDetails.fullName' },
    { label: 'Email', key: 'userDetails.email' },
    { label: 'Currency', key: 'currency' },
    { label: 'Amount', key: 'value.$numberDecimal' },
    { label: 'Description', key: 'description' },
    { label: 'Transaction Type', key: 'type' },
    { label: 'Card ID', key: 'cardId' },
    { label: 'Card Service ID', key: 'cardServiceId' },
    { label: 'Date/Time', key: 'createdAt' },
    { label: 'IsFailedTransaction', key: 'isFailedTx' },
    { label: 'Balance Before', key: 'balanceBefore' },
    { label: 'Balance After', key: 'balanceAfter' },
  ];

  const handleExport = async () => {
    setLoading(true);

    let ROUTE = wrapBaseApi(`${BASE_URL}/wallet/admin/cards-transactions?page=1`);

    if (search) ROUTE += `&search=${search}`;
    if (txType) ROUTE += `&type=${txType}`;
    if (typeof failedTx === 'boolean') ROUTE += `&failed=${failedTx}`;
    if (beforeAndAfter) ROUTE += `&${beforeAndAfter}`;

    try {
      const response = await get(ROUTE, getHeaders());

      if (response) {
        setLoading(false);
        setData(response.data.data.transactions);
      }
    } catch (error) {
      setLoading(false);
      console.log(error, 'ERROR');
    }
  };

  return (
    <Box>
      {data.length > 0 && loading === false ? (
        <CSVDownload
          filename='bitmama-card.csv'
          data={data}
          headers={headers}
        />
      ) : (
        ''
      )}

      <Button
        minH='48px'
        onClick={handleExport}
        disabled={loading}
        isLoading={loading}
      >
        Download
      </Button>
    </Box>
  );
};

export default ExportCardTransactions;
