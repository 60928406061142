import { Box, Flex } from '@chakra-ui/react';
import { P2PDisputeCard, P2PListingWrapper, P2PTradeTable, PageTitle } from 'components';

const P2P = () => {
  return (
    <Box>
      <PageTitle title='P2P' />

      <Flex
        gridGap='30px'
        mt='27px'
        flexDir={{ base: 'column', md: 'row' }}
      >
        <P2PTradeTable />

        <Box width={{ base: '100%', md: '272px' }}>
          <P2PListingWrapper />

          <P2PDisputeCard />
        </Box>
      </Flex>
    </Box>
  );
};

export default P2P;
