import { useParams } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
  ApproveDeclineCicoKYC,
  BoxInput,
  HistoryTab,
  Loader,
  TableBoxWrapper,
} from 'components';
import { useGetKycByIDQuery } from 'store/services/cico.service';
import { formatDateToUTC } from 'utils';

const CicoKYCDetails = () => {
  const { id } = useParams();

  const { data, isLoading, isSuccess, refetch } = useGetKycByIDQuery(id);

  return (
    <>
      <HistoryTab
        firstText="Cash In Cash Out"
        secondText="KYC Details"
        url="/dashboard/cico?kyc"
      />

      <TableBoxWrapper>
        {isLoading && <Loader />}

        {isSuccess && (
          <Flex gridGap="164px">
            <Box>
              <BoxInput
                label="Name"
                textTransform="capitalize"
                value={`${data.doc.firstName} ${
                  data.doc.middleName ? data.doc.middleName : ''
                } ${data.doc.lastName}`}
              />

              <BoxInput
                label="Level"
                textTransform="capitalize"
                value={data.doc.coverage[0].level}
              />

              <BoxInput label="Phone number" value={data.doc.phoneNumber} />

              <BoxInput
                label="Address"
                textTransform="capitalize"
                value={
                  data.doc.addressData
                    ? `${data.doc.addressData.address1}, ${data.doc.addressData.city}, ${data.doc.country}`
                    : 'N/A'
                }
              />

              <BoxInput
                label="Status"
                textTransform="capitalize"
                value={data.doc.documentStatus}
              />

              <BoxInput
                label="Date/time of verification"
                value={formatDateToUTC(data.doc.createdAt)}
              />
            </Box>

            <Box>
              <Flex gridGap="1rem" mb="1rem">
                <Text>Document URL</Text>

                <Text
                  color="brand.primary"
                  as="a"
                  href={data.doc.documentUrl}
                  target="_blank"
                >
                  Click to view
                </Text>
              </Flex>

              <Flex gridGap="1rem" mb="1rem">
                <Text>Selfie Document</Text>

                <Text
                  color="brand.primary"
                  as="a"
                  href={data.doc.selfieDocument}
                  target="_blank"
                >
                  Click to view
                </Text>
              </Flex>
            </Box>
          </Flex>
        )}

        <ApproveDeclineCicoKYC id={id} refetch={refetch} />
      </TableBoxWrapper>
    </>
  );
};

export default CicoKYCDetails;
