import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, useDisclosure, Text } from '@chakra-ui/react';
import { CloseModal, UserProfileTooltip } from 'components';
import useShortcutKeys from 'hooks/useShortCut';
import { UserTransactions } from 'pages';
import { constant } from 'utils';
import { useStorage } from 'hooks';

const UserTransactionModal = ({ fullName, userId, index }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);

  const [shortcut, setShortcut] = useState(false);
  const { storageValue, setStorage } = useStorage(constant.SELECTED_USER_ID);

  const handleOpen = () => {
    setStorage(`${userId},${fullName}`);
    onOpen();
  };

  const handleClose = () => {
    onClose();
    setShortcut(false);
  };

  const { data } = useSelector((state) => state.wallet.userTranasction);

  const handleShortcut = () => {
    setShortcut(true);
    onOpen();
  };

  const userDetails = useMemo(() => {
    return shortcut && storageValue && storageValue.split(',');
  }, [storageValue, shortcut]);

  useShortcutKeys(['/', 'u', 't'], handleShortcut);

  useEffect(() => {
    if (data && initialRef.current !== null) {
      initialRef.current.scrollTop = 10;
    }
  }, [data]);

  return (
    <>
      {/* <Box
        cursor='pointer'
        onClick={handleOpen}
      >
        {fullName}
      </Box> */}

      <UserProfileTooltip
        onClick={handleOpen}
        fullName={fullName}
        userId={userId}
        index={index}
      />

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        scrollBehavior='inside'
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='1024px'
          padding={{ base: '32px 4px', md: '32px 0' }}
          height='90vh'
          overflowY='scroll'
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
            p='0'
            mb='24px'
            textTransform='capitalize'
          >
            <Text as='span'>{shortcut && userDetails ? userDetails[1] : fullName}</Text>'s Transactions
          </ModalHeader>

          <ModalBody
            ref={initialRef}
            scrollBehavior='smooth'
          >
            <UserTransactions userId={shortcut && userDetails ? userDetails[0] : userId} />

            <CloseModal onClick={handleClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserTransactionModal;
