import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text } from '@chakra-ui/react';

const AccordionTab = ({ title, children }) => {
  return (
    <AccordionItem
      border='none'
      padding={{ base: '0', md: '0 12px' }}
      mb='10px'
    >
      <AccordionButton
        as='div'
        p={{ base: '12px 8px' }}
        textTransform='capitalize'
        _expanded={{ bg: 'rgba(0, 0, 0, 0.04)' }}
        transition='all 300ms ease-in-out'
        borderRadius='8px'
      >
        <Text
          as='span'
          flex='1'
          textAlign='left'
          fontWeight='500'
          fontSize='16px'
        >
          {title}
        </Text>

        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel
        p='0'
        px='0'
        fontSize='14px'
      >
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordionTab;
