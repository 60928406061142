import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Button, CloseModal, Input } from 'components';
import { BASE_URL } from 'constant';
import useShortcutKeys from 'hooks/useShortCut';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSingleDocument } from 'store/slices/user.slice';
import { getHeaders } from 'utils';
import { put } from 'utils/axiosLib';

const ReviewKYC = ({ id }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  useShortcutKeys(['/', 'r'], onOpen);

  const dispatch = useDispatch();
  const toast = useToast();
  const [state, setState] = useState({
    validUntil: '',
    reason: '',
    status: '',
    level: '',
    notifyUser: true,
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const URL = `${BASE_URL}/admin/verification/documents/${id}/review`;

    const headers = getHeaders();

    try {
      const response = await put(URL, { ...state }, headers);

      if (response.data) {
        setLoading(false);
        onClose();
        dispatch(fetchSingleDocument({ id }));
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Box mt='3rem'>
      <Button
        minH='52px'
        onClick={onOpen}
        width={{ base: '100%', md: '180px' }}
      >
        Review KYC
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius='16px'
          maxWidth='420px'
        >
          <ModalHeader
            textAlign='center'
            // padding="24px"
            fontWeight='600'
            fontSize='24px'
            borderTopRadius='16px'
          >
            Review
          </ModalHeader>

          <ModalBody pb='1rem'>
            <form onSubmit={handleSubmit}>
              <VStack
                gridGap='20px'
                mb='40px'
              >
                <Box width='100%'>
                  <Text
                    display='block'
                    mb='0.5rem'
                    htmlFor='validUntil'
                    as='label'
                  >
                    Valid Until
                  </Text>

                  <Input
                    htmlFor='validUntil'
                    id='validUntil'
                    width='100%'
                    minHeight='52px'
                    name='validUntil'
                    type='date'
                    defaultValue={state.validUntil}
                    onChange={handleChange}
                  />
                </Box>

                <Box width='100%'>
                  <Text
                    display='block'
                    mb='0.5rem'
                    htmlFor='status'
                    as='label'
                  >
                    Status
                  </Text>

                  <Select
                    width='100%'
                    name='status'
                    id='status'
                    fontSize='1rem'
                    minH='52px'
                    borderRadius='16px'
                    onChange={handleChange}
                    defaultValue={state.status}
                  >
                    <option>Status</option>
                    <option value='expired'>Expire</option>
                    <option value='withdrawn'>Withdraw Approval</option>
                  </Select>
                </Box>

                <Box width='100%'>
                  <Text
                    display='block'
                    mb='0.5rem'
                    htmlFor='level'
                    as='label'
                  >
                    KYC Level
                  </Text>

                  <Select
                    width='100%'
                    name='level'
                    id='level'
                    fontSize='1rem'
                    minH='52px'
                    borderRadius='16px'
                    onChange={handleChange}
                    defaultValue={state.level}
                  >
                    <option>Level</option>
                    <option value='one'>One</option>
                    <option value='two'>Two</option>
                    <option value='three'>Three</option>
                  </Select>
                </Box>

                <Box width='100%'>
                  <Text
                    display='block'
                    mb='0.5rem'
                    htmlFor='notifyUser'
                    as='label'
                    onChange={handleChange}
                  >
                    Notify User
                  </Text>

                  <Select
                    width='100%'
                    name='notifyUser'
                    id='notifyUser'
                    fontSize='1rem'
                    minH='52px'
                    borderRadius='16px'
                    onChange={handleChange}
                    defaultValue={state.notifyUser}
                  >
                    <option disabled>Notify User</option>
                    <option value='true'>True</option>
                    <option value='false'>False</option>
                  </Select>
                </Box>

                <Box width='100%'>
                  <Text
                    display='block'
                    mb='0.5rem'
                    htmlFor='reason'
                    as='label'
                  >
                    Reason
                  </Text>

                  <Textarea
                    placeholder='Reason'
                    onChange={handleChange}
                    name='reason'
                    id='reason'
                    width='100%'
                    height='120px'
                    defaultValue={state.reason}
                    padding='13px 22px'
                    _focus={{
                      borderColor: '#06C3448f',
                    }}
                  />
                </Box>
              </VStack>

              <Center>
                <Button
                  type='submit'
                  minH='52px'
                  minW='365px'
                  disabled={loading}
                  isLoading={loading}
                >
                  Submit
                </Button>
              </Center>
            </form>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ReviewKYC;
