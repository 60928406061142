import { Box } from '@chakra-ui/react';
import { Button, RichEditor } from 'components';
import { IoIosArrowRoundUp } from 'react-icons/io';

const RichEditorWithButton = ({ setContent, editor, isLoading, handleSubmit }) => {
  return (
    <Box pos='relative'>
      <RichEditor
        setContent={setContent}
        editor={editor}
      >
        <Box
          pos='absolute'
          top='5%'
          right='1%'
        >
          <Button
            bg='brand.primary'
            disabled={isLoading}
            isLoading={isLoading}
            minH='40px'
            minW='40px'
            h='40px'
            w='40px'
            borderRadius='1rem'
            cursor='pointer'
            boxShadow='0px 20px 60px rgba(19, 10, 46, 0.25)'
            onClick={handleSubmit}
            padding='0'
          >
            <IoIosArrowRoundUp
              color='#fff'
              fontSize='28px'
              fontWeight='500'
            />
          </Button>
        </Box>
      </RichEditor>
    </Box>
  );
};

export default RichEditorWithButton;
