import { Modal, ModalOverlay, ModalContent, Text, Center } from '@chakra-ui/react';
import { RocketSVG } from 'assets';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';

const WishlistSuccessModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/dashboard/wishlist');
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='21px'
        padding={{ base: '24px', md: '40px' }}
        maxWidth='488px'
        width='100%'
      >
        <Center
          padding='0'
          flexDir='column'
        >
          <RocketSVG />

          <Text
            margin='19px 0 24px'
            fontWeight='600'
            fontSize='32px'
            fontFamily='PolySans'
          >
            Superb!
          </Text>

          <Text
            textAlign='center'
            mb='40px'
          >
            Your wishlist has been created and sent. You can track its performance on the main screen.
          </Text>

          <Button
            width='100%'
            onClick={handleClose}
          >
            Done
          </Button>
        </Center>
      </ModalContent>
    </Modal>
  );
};

export default WishlistSuccessModal;
