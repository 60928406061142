import { useEffect, useState } from 'react';
import { Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { Input } from 'components';

const TagInput = ({
  defaultTags,
  selectedTags,
  setSelectedTags,
  handlePropChange,
  header,
  subHeader,
  headerKey,
  onRemoveTags,
  ...props
}) => {
  const [value, setValue] = useState('');
  const [tags, setTags] = useState(defaultTags);

  const removeTags = (indexToRemove, selectedTags) => {
    const filter = selectedTags.filter((_, index) => index !== indexToRemove);

    setTags(filter);
    setSelectedTags(filter);

    onRemoveTags(header, subHeader, headerKey, filter);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const addTags = (event) => {
    if (value !== '') {
      setTags((previousTags) => [...previousTags, event.target.value]);
    }

    handlePropChange();

    setValue('');
  };

  useEffect(() => {
    if (value !== '') {
      tags && setSelectedTags([...tags, value]);
    }
  }, [tags, setSelectedTags, value]);

  return (
    <Flex
      gridGap='5px'
      alignItems='center'
    >
      <Flex
        gridGap='4px'
        minW='120px'
        bg={tags?.length === 0 ? '' : '#F9FAF9'}
        height='100%'
        minH='56px'
        alignItems='center'
        borderRadius='15px'
        padding='4px 6px'
        flexWrap='wrap'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
      >
        {tags.map((tag, index) => {
          return (
            <Tag
              cursor='pointer'
              fontSize='14px'
              height='24px'
              minW='40px'
              key={index}
              alignItems='center'
              variant='subtle'
              colorScheme='green'
              size='sm'
              onClick={() => removeTags(index, tags)}
              // on
            >
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton />
            </Tag>
          );
        })}
      </Flex>

      <Input
        type='text'
        onKeyUp={(event) => (event.key === 'Enter' ? addTags(event) : null)}
        onChange={handleChange}
        value={value}
        fontSize='12px'
        {...props}
      />
    </Flex>
  );
};

export default TagInput;
