import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import FileSVG from 'assets/svg/file.svg';

const ChallengeIconUpload = ({ setSelectedFile, selectedFile, defaultPreview, title }) => {
  const [preview, setPreview] = useState(defaultPreview);
  const imgRef = useRef(null);

  const handleFile = (event) => {
    const fileList = event.target.files;
    if (!fileList) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(fileList[0]);
  };

  const handleClick = () => {
    imgRef?.current.click();
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(defaultPreview);
      return;
    }

    if (preview) {
      setPreview(defaultPreview);
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, defaultPreview]);
  return (
    <>
      <Text
        display='block'
        htmlFor='description'
        as='label'
      >
        Icon
      </Text>

      <Text
        fontWeight='300'
        mb='4px'
        fontSize='16px'
      >
        {title ? title : 'Choose your badge icon. It will be displayed to Bitmama users'}
      </Text>

      <Box
        onClick={handleClick}
        cursor='pointer'
      >
        <>
          <input
            type='file'
            hidden
            onChange={handleFile}
            ref={imgRef}
            accept='image/jpg, image/jpeg, image/png, image/webp, image/svg+xml'
          />

          <Box
            border='1px solid #E1ECE7'
            borderRadius='8px'
            padding='20px'
          >
            <Flex gap='24px'>
              <Box
                maxW='104px'
                width='100%'
              >
                <Center
                  bg='#F4F6F5'
                  border='1px solid #E6E7E6'
                  borderRadius='5px'
                  width='104px'
                  height='104px'
                >
                  {/* {prevDefaultPreview !== preview ? <>Hey</> : <>something</>} */}
                  {preview ? (
                    <Image
                      src={preview}
                      width='100px'
                      height='100px'
                      alt='file'
                      objectFit='cover'
                    />
                  ) : (
                    <Image
                      src={FileSVG}
                      width='67px'
                      height='67px'
                      alt='file'
                      objectFit='cover'
                    />
                  )}
                </Center>

                <Text
                  color='#797A7A'
                  fontSize='12px'
                  fontStyle='italic'
                  mt='4px'
                  textAlign='center'
                >
                  Preview
                </Text>
              </Box>

              <Box
                maxW='257px'
                width='100%'
              >
                <Text
                  fontWeight='300'
                  fontSize='16px'
                  lineHeight='21.28px'
                  mb='18px'
                >
                  You can upload JPG, PNG, SVG files Maximum file size in 5.00MB Recommended file size is 400x400px
                </Text>

                <Text
                  textDecor='underline'
                  fontWeight='500'
                  color='#0D4740'
                >
                  Upload File
                </Text>
              </Box>
            </Flex>
          </Box>
        </>
      </Box>
    </>
  );
};

export default ChallengeIconUpload;
