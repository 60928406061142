import { useState } from 'react';
import {
  Box,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ActionButton, Loader } from 'components';
import { put } from 'utils/axiosLib';
import { BASE_URL } from 'constant';
import { wrapBaseApi } from 'utils';

const P2PHealModal = ({ id, headers }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});

  const handleHeal = async () => {
    setLoading(true);
    onOpen();

    const URL = wrapBaseApi(`${BASE_URL}/p2p/admin/profile/${id}/heal`);

    try {
      const response = await put(URL, {}, headers);

      console.log(response.data, 'RESPONSE DATA');

      if (response.data) {
        setResult(response.data.data.checks);
        setLoading(false);
        toast({
          position: 'bottom-right',
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <ActionButton onClick={handleHeal}>P2P Heal</ActionButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius='16px'
          maxWidth='420px'
        >
          <ModalHeader
            bg='#F7F7FC'
            padding='24px'
            fontWeight='600'
            borderTopRadius='16px'
            textAlign='center'
          >
            P2P Heal
          </ModalHeader>

          <ModalBody>
            {loading && <Loader />}

            <Grid
              gridTemplateColumns='1fr 1fr'
              pb='1rem'
            >
              <Box>
                <Text
                  as='ul'
                  textTransform='capitalize'
                >
                  {Object.keys(result).map((res, index) => (
                    <Text
                      key={index}
                      as='li'
                    >
                      {res}:
                    </Text>
                  ))}
                </Text>
              </Box>

              <Box>
                <Text as='ul'>
                  {Object.values(result).map((res, index) => (
                    <Text
                      key={index}
                      as='li'
                    >
                      {res}
                    </Text>
                  ))}
                </Text>
              </Box>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default P2PHealModal;
