import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Heading, Text } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  FilterSelect,
  Loader,
  PaginationWrapper,
  ReimburseCardTransaction,
  ScrollRightContainer,
  SearchInput,
} from 'components';
import { formatDateToUTC } from 'utils';
import { useDebounce } from 'hooks';
import { fetchAllCards } from 'store/slices/card.slice';

const CardsTransactionsLimit = () => {
  const dispatch = useDispatch();
  const { data, status, beforeAndAfter, totalCount } = useSelector((state) => state.cards.allCards);

  const [size, setSize] = useState(10);

  const [searchString, setSearchString] = useState('');

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: size,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  useEffect(() => {
    dispatch(
      fetchAllCards({
        limit: size,
        page: currentPage,
        search: searchString,
        filter: beforeAndAfter,
      })
    );
  }, [dispatch, currentPage, size, searchString, beforeAndAfter]);

  return (
    <Box>
      <Flex
        mb='45px'
        gridGap='1rem'
        alignItems={{ base: 'initial', tb: 'center' }}
        flexDir={{ base: 'column', tb: 'row' }}
      >
        <Heading
          as='h2'
          color='#797A7A'
          fontSize='1rem'
          fontWeight='700'
        >
          Transactions
        </Heading>

        <Flex
          gridGap='1rem'
          alignItems='center'
        >
          <FilterSelect
            width='90px'
            onChange={handleSize}
          >
            <option value={10}>Size</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </FilterSelect>

          <Box
            maxWidth='319px'
            width='100%'
          >
            <SearchInput
              onChange={debouncedChangeHandler}
              placeholder='Search for user name or email'
            />
          </Box>
        </Flex>
      </Flex>

      {status === 'loading' && (
        <Box margin='8px 0'>
          <Loader />
        </Box>
      )}

      <ScrollRightContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Card balance</Th>
              <Th>Card ID</Th>
              <Th>Card Number</Th>
              <Th>Card type</Th>
              <Th>Card transaction limit</Th>
              <Th>Label</Th>
              <Th>Date</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.cards.map(
                ({ _id, userDetails, balance, cardType, reversedFee, createdAt, cardId, cardNumber, label }) => {
                  return (
                    <Tr key={_id}>
                      <Td>{userDetails.fullName}</Td>

                      <Td>{userDetails.email}</Td>

                      <Td textTransform='capitalize'>{Number(balance?.$numberDecimal).toLocaleString()}</Td>

                      <Td>{cardId}</Td>

                      <Td>{cardNumber}</Td>

                      <Td textTransform='capitalize'>{cardType?.type}</Td>

                      <Td>{cardType?.txLimit ? Number(cardType?.txLimit).toLocaleString() : 'N/A'}</Td>

                      <Td>{label ? label : 'N/A'}</Td>

                      <Td>{formatDateToUTC(createdAt)}</Td>

                      <Td>
                        <Flex
                          alignItems='center'
                          gap='8px'
                        >
                          {reversedFee ? (
                            <Text
                              color='brand.primary'
                              fontWeight='500'
                            >
                              Refunded
                            </Text>
                          ) : (
                            <ReimburseCardTransaction
                              _id={_id}
                              searchString={searchString}
                            />
                          )}
                        </Flex>
                      </Td>
                    </Tr>
                  );
                }
              )}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        totalDataCount={totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={pageSize}
        offset={offset}
        pages={pages}
      />
    </Box>
  );
};

export default CardsTransactionsLimit;
