import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, Text, Flex, Center } from '@chakra-ui/react';
import { CICOTransferUpdate, CloseModal } from 'components';

const CicoTransferModal = ({ isOpen, onClose, modalData }) => {
  const {
    _id,
    fiatAccountData,
    transferStatus,
    direction,
    cryptoVolume,
    cryptoType,
    fiatAmount,
    fiatType,
    enterpriseWithdrawalId,
    payoutId,
  } = modalData;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='480px'
          padding='24px'
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
            fontFamily='PolySans'
          >
            Transfer Details
          </ModalHeader>

          <ModalBody
            bg='#fff'
            pt='1rem'
          >
            <Card
              title='Transfer ID'
              value={_id}
            />

            <Card
              title='Transfer Status'
              value={transferStatus}
            />

            {enterpriseWithdrawalId && (
              <Card
                title='Enterprise Withdrawal ID'
                value={enterpriseWithdrawalId}
              />
            )}

            {payoutId && (
              <Card
                title='Payout ID'
                value={payoutId}
              />
            )}

            <Card
              title='Direction'
              value={direction}
              valueTextTransform='uppercase'
            />

            <Card
              title='Crypto Volume and Type'
              value={`${Number(cryptoVolume).toFixed(2)} ${cryptoType}`}
              valueTextTransform='uppercase'
            />

            <Card
              title='Fiat Amount'
              value={`${Number(fiatAmount).toLocaleString()} ${fiatType}`}
              valueTextTransform='uppercase'
            />

            {fiatAccountData && (
              <>
                <Card
                  title='Account Name'
                  value={fiatAccountData.accountName}
                />

                <Card
                  title='Account Number'
                  value={fiatAccountData.accountNumber}
                />

                <Card
                  title='Account Type'
                  value={fiatAccountData.accountType}
                  valueTextTransform='capitalize'
                />

                <Card
                  title='Network Bank Code'
                  value={fiatAccountData.networkOrBankCode}
                  valueTextTransform='capitalize'
                />
              </>
            )}

            <Center>
              <CICOTransferUpdate
                _id={_id}
                onClose={onClose}
                direction={direction}
              />
            </Center>

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CicoTransferModal;

const Card = ({ title, value, valueTextTransform, ...props }) => {
  return (
    <Flex
      {...props}
      justifyContent='space-between'
      mb='16px'
    >
      <Text>{title}</Text>

      <Text
        fontWeight='500'
        textTransform={valueTextTransform ? valueTextTransform : 'initial'}
      >
        {value}
      </Text>
    </Flex>
  );
};
