import { Box, Flex, Table, Td, Tr, Th, Thead, Tbody, useDisclosure } from '@chakra-ui/react';
import {
  SearchInput,
  ScrollRightContainer,
  TableDataStatus,
  PaginationWrapper,
  Loader,
  FilterSelect,
  GreenboxDetailsModal,
  ViewMore,
  CancelGreenboxModal,
} from 'components';
import { usePagination } from '@ajna/pagination';
import { useState } from 'react';
import { useDebounce } from 'hooks';
import { useGreenboxQuery } from 'store/services/greenbox.service';

const GreenboxTable = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [actionType, setActionType] = useState('');
  const [searchString, setSearchString] = useState('');
  const [greenBoxStatus, setStatus] = useState('');
  const [modalData, setModalData] = useState({});

  const handleActionType = (e) => {
    setCurrentPage(1);
    setActionType(e.target.value);
  };

  const handleStatus = (e) => {
    setCurrentPage(1);
    setStatus(e.target.value);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      console.log('a search');
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString('');
      setCurrentPage(1);
    }
  };

  const handleModalData = (data) => {
    setModalData(data);
    onOpen();
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  const [page, setPage] = useState(1);

  const { data, isLoading } = useGreenboxQuery({
    page: page,
    limit: 10,
    actionType,
    search: searchString,
    status: greenBoxStatus,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: { currentPage: 1, pageSize: 10 },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  return (
    <Box mt='32px'>
      <Flex
        mb='45px'
        alignItems='center'
        gridGap='16px'
        flexDir={{ base: 'column', tb: 'row' }}
      >
        <Box width='100%'>
          <SearchInput
            placeholder='Search by username or paste greenbox code'
            minH='56px'
            width={{ base: '100%', tb: '352px' }}
            bg='#F9FAF9'
            onChange={debouncedChangeHandler}
          />
        </Box>

        <Flex gridGap='16px'>
          <FilterSelect
            width='120px'
            onChange={handleActionType}
            placeholder='Action Type'
          >
            <option value='box_in'>Box In</option>
            <option value='box_out'>Box Out</option>
            <option value='funding'>Funding</option>
            <option value='withdrawal'>Withdrawal</option>
            <option value='box_cancel'>Box Cancel</option>
          </FilterSelect>

          <FilterSelect
            width='120px'
            onChange={handleStatus}
            placeholder='Status'
          >
            <option value='pending'>Pending</option>
            <option value='expired'>Expired</option>
            <option value='claimed'>Claimed</option>
            <option value='active'>Active</option>
          </FilterSelect>
        </Flex>
      </Flex>

      <ScrollRightContainer minHeight='20vh'>
        {isLoading && <Loader />}

        <Table>
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Greenbox Title</Th>
              <Th>Greenbox Code</Th>
              <Th>No. Of Recipients</Th>
              <Th>No. Of Claimed</Th>
              <Th>Amount. Per User</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.data.map(({ _id, userDetails, unit, product, status }) => {
                const modalData = {
                  _id,
                  userDetails,
                  unit,
                  product,
                  status,
                };
                return (
                  <Tr key={_id}>
                    <Td
                      color='#6FBE45'
                      fontWeight='500'
                    >
                      <a
                        href={`/dashboard/users/${userDetails?.userId}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {userDetails?.username}
                      </a>
                    </Td>

                    <Td
                      cursor='pointer'
                      onClick={() => handleModalData(modalData)}
                    >
                      {product?.title}
                    </Td>

                    <Td fontWeight='500'>{product?.code}</Td>

                    <Td
                      cursor='pointer'
                      onClick={() => handleModalData(modalData)}
                    >
                      {product?.totalRecipients}
                    </Td>

                    <Td
                      cursor='pointer'
                      onClick={() => handleModalData(modalData)}
                    >
                      {product?.totalClaimed}
                    </Td>

                    <Td
                      cursor='pointer'
                      onClick={() => handleModalData(modalData)}
                      textTransform='uppercase'
                    >
                      {product?.amtPerUser} {unit}
                    </Td>

                    <Td onClick={() => handleModalData(modalData)}>
                      <TableDataStatus status={status} />
                    </Td>

                    <Td>
                      <Flex gridGap='16px'>
                        {/* <Link to={`/dashboard/greenbox/${_id}`}> */}
                        <ViewMore onClick={() => handleModalData(modalData)}>View More</ViewMore>
                        {/* </Link> */}

                        {status === 'pending' && (
                          <CancelGreenboxModal
                            username={userDetails?.username}
                            greenBoxId={_id}
                          />
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </ScrollRightContainer>

      <GreenboxDetailsModal
        isOpen={isOpen}
        modalData={modalData}
        onClose={onClose}
      />

      <PaginationWrapper
        setCurrentPage={setCurrentPage}
        setPage={setPage}
        totalDataCount={data?.totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={10}
      />
    </Box>
  );
};

export default GreenboxTable;
