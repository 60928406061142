import { Box, Text } from '@chakra-ui/react';

const P2PTableStatus = ({ status }) => {
  const colorValue = () => {
    switch (status) {
      case 'paid':
        return 'brand.success';

      case 'open':
        return '#F4A51D';

      case 'cancelled':
        return '#ED1C00';

      default:
        break;
    }
  };

  const paddingValue = () => {
    switch (status) {
      case 'paid':
        return '8px 47px';

      case 'open':
        return '8px 34px';

      case 'cancelled':
        return '8px 26px';

      default:
        break;
    }
  };

  const bgValue = () => {
    switch (status) {
      case 'paid':
        return 'rgba(226, 242, 218, 0.3)';

      case 'open':
        return 'rgba(244, 183, 64, 0.2)';

      case 'cancelled':
        return 'rgba(237, 28, 0, 0.1)';

      default:
        break;
    }
  };

  return (
    <Box
      maxWidth="122px"
      width="100%"
      borderRadius="10px"
      bg={bgValue()}
      padding={paddingValue()}
    >
      <Text
        textTransform="uppercase"
        color={colorValue()}
        textAlign="center"
        fontWeight="700"
      >
        {status}
      </Text>
    </Box>
  );
};

export default P2PTableStatus;
