import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react';
import { BitmamaWhiteSVG } from 'assets';
import { NavLink, useLocation } from 'react-router-dom';
import { constant, sidebarLink } from 'utils';
import Logout from './Logout';
import styles from './Sidebar.module.scss';
import { useStorage } from 'hooks';

const Sidebar = () => {
  const { pathname } = useLocation();
  const { storageValue: inflowLastPath } = useStorage(constant.LAST_PATH);

  return (
    <Box
      className={styles.sidebar}
      padding='32px 0 114px 6px'
      bg='brand.darkPrimary'
      width='265px'
      height='100%'
    >
      <Center
        flexDir='column'
        mb='40px'
      >
        <BitmamaWhiteSVG />

        <Box
          borderRadius='10px'
          ml='30px'
          padding='8px'
          width='74px'
          bg='brand.lemonShade'
        >
          <Heading
            textAlign='center'
            fontWeight='600'
            fontSize='12px'
            lineHeight='14px'
          >
            Admin
          </Heading>
        </Box>
      </Center>

      <Box
        as='ul'
        pl='6px'
      >
        {sidebarLink.map(({ icon, activeIcon, id, name, path, end, subPath }) => {
          return (
            <Box
              as='li'
              key={id}
              mb='10.5px'
            >
              <NavLink
                className={({ isActive }) => {
                  return isActive || (pathname === '/dashboard/' && name === 'Home') ? styles.active : styles.inactive;
                }}
                to={name === 'Inflow/Outflow' && inflowLastPath ? `/dashboard/inflow-outflow${inflowLastPath}` : path}
                end={end}
              >
                <Flex
                  gridGap='13px'
                  alignItems='center'
                >
                  <Box>
                    {pathname === path ||
                    pathname === '/dashboard/' ||
                    (subPath && pathname.split('/')[2] === name.toLowerCase())
                      ? activeIcon
                      : icon}
                  </Box>

                  <Text
                    lineHeight='19px'
                    as='p'
                    fontWeight='300'
                    fontSize='1rem'
                    color='#F2F4F3'
                  >
                    {name}
                  </Text>
                </Flex>
              </NavLink>
            </Box>
          );
        })}

        <Logout />
      </Box>
    </Box>
  );
};

export default Sidebar;
