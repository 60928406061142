import { Box, Image } from '@chakra-ui/react';

const AuthImage = () => {
  return (
    <Box
      width='100%'
      maxWidth='608px'
      display={{ sm: 'none', md: 'block' }}
    >
      <Image
        src='https://res.cloudinary.com/bitmama/image/upload/v1656674092/bitmama_admin/authLanding_jtajy4.webp'
        alt='Bitmama App'
        objectFit='cover'
        height='1082px'
      />
    </Box>
  );
};

export default AuthImage;
