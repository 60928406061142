import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import {
  ApproveDeclineBcpl,
  Loader,
  PaginationWrapper,
  ScrollRightContainer,
} from 'components';
import { fetchAllLoans } from 'store/slices/bcpl.slice';
import { Link } from 'react-router-dom';
import { formatDateToUTC } from 'utils';

const BcplRequest = () => {
  const dispatch = useDispatch();

  const {
    data,
    status,
    totalCount: tCount,
  } = useSelector((state) => state.bcpl.loans);

  const [totalCount, setTotalCount] = useState(0);

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    isDisabled,
    pageSize,
    offset,
  } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);

    setTimeout(() => {
      window.scrollTo({ top: 100, behavior: 'smooth' });
    }, 500);
  };

  useEffect(() => {
    dispatch(
      fetchAllLoans({
        limit: 10,
        page: currentPage,
        loanStatus: 'pending',
      })
    );
  }, [dispatch, currentPage]);

  useEffect(() => {
    setTotalCount(tCount);
  }, [tCount]);

  return (
    <>
      <ScrollRightContainer>
        {status === 'loading' && (
          <Box margin="8px 0">
            <Loader />
          </Box>
        )}

        {status !== 'loading' && (
          <>
            <Table>
              <Thead>
                <Tr>
                  <Th>Username</Th>
                  <Th>Email address</Th>
                  <Th>Coin Type</Th>
                  <Th>Amount</Th>
                  <Th>Fiat Value</Th>
                  <Th>Date</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.map(
                    ({
                      _id,
                      userDetails,
                      coin,
                      coinValue,
                      currencyValue,
                      currency,
                      createdAt,
                    }) => {
                      const data = {
                        _id,
                        userDetails,
                        coin,
                        coinValue,
                        currencyValue,
                        currency,
                      };
                      return (
                        <Tr key={_id}>
                          <Td color="brand.primary">
                            <Link to={`/dashboard/users/${userDetails.userId}`}>
                              {userDetails.username}
                            </Link>
                          </Td>

                          <Td>{userDetails.email}</Td>

                          <Td textTransform={'uppercase'}>{coin}</Td>

                          <Td>{coinValue}</Td>

                          <Td textTransform={'uppercase'}>
                            {currencyValue} {currency}
                          </Td>

                          <Td>{formatDateToUTC(createdAt)}</Td>

                          <Td>
                            <ApproveDeclineBcpl data={data} />
                          </Td>
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table>
          </>
        )}
      </ScrollRightContainer>

      <PaginationWrapper
        handlePageChange={handlePageChange}
        totalDataCount={totalCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        size={pageSize}
        offset={offset}
        pages={pages}
      />
    </>
  );
};

export default BcplRequest;
