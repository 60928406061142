import { Box, Center, Image, Text } from '@chakra-ui/react';

const PartnersCommissionCard = ({ count, icon, coin }) => {
  return (
    <Box
      maxWidth="192px"
      width="100%"
      boxShadow="0px 2px 8px rgba(229, 229, 229, 0.47)"
      borderRadius="10px"
      padding="17px 0 20px"
    >
      <Center>
        <Box>
          <Center
            mb="6px"
            boxShadow="0px 0.79px 3.15px rgba(229, 229, 229, 0.47)"
            borderRadius="10px"
            width="39px"
            height="35px"
          >
            <Image src={icon} width="24px" height="24px" alt="coin" />
          </Center>

          <Text
            fontSize="16px"
            fontWeight="700"
            textTransform="uppercase"
            fontFamily="PolySans"
          >
            {count} {coin}
          </Text>
        </Box>
      </Center>
    </Box>
  );
};

export default PartnersCommissionCard;
