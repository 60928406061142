import { useState } from 'react';
import { Box, Center, Flex, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { Button, FilterSelect, HistoryTab, Input, PageTitle, WishlistSuccessModal } from 'components';
import { useCreateWishlistMutation } from 'store/services/wishlist.service';

const CreateWishlist = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [state, setState] = useState({
    criteria: '',
    feature: 'card',
    tag: '',
    frequency: '',
  });

  const [gifts, setGifts] = useState({});

  const handleGift = (e) => {
    const { name, value } = e.target;

    if (value === '') {
      const newGifts = { ...gifts };
      delete newGifts[name];

      setGifts(newGifts);
    } else {
      setGifts({
        ...gifts,
        [name]: value,
      });
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const [countMap, setCountMap] = useState(4);
  const addMoreInput = () => {
    setCountMap(countMap + 1);
  };

  const clearInput = () => {
    setCountMap(countMap - 1);
    const objectKeys = Object.keys(gifts);

    // Remove the last key
    objectKeys.pop();

    // Create a new object without the last key
    const newObject = Object.fromEntries(objectKeys.map((key) => [key, gifts[key]]));

    setGifts({
      ...newObject,
    });
  };

  const [_createWishlist, { isLoading }] = useCreateWishlistMutation();

  const toast = useToast();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { criteria, feature, frequency, tag } = state;

    const giftValue = Object.values(gifts);

    const tagUpdated = String(tag.replace(/\s/g, '_')).toLowerCase();

    try {
      const response = await _createWishlist({
        gifts: giftValue,
        tag: tagUpdated,
        criteria,
        feature,
        frequency,
      }).unwrap();
      if (response.data) {
        onOpen();
      }
    } catch (error) {
      toast({
        position: 'bottom-right',
        title: 'Oops',
        description: error?.data?.error,
        status: 'error',
        duration: 18000,
        isClosable: true,
      });
      onClose();
    }
  };

  return (
    <Box>
      <HistoryTab
        firstText='Wishlist Campaign'
        secondText=''
        url='/dashboard/wishlist'
      />

      <Box
        mt='27px'
        bg='#FCFDFD'
        boxShadow='0px 2px 8px 0px rgba(229, 229, 229, 0.47)'
        borderRadius='20px'
        minHeight='700px'
        padding={{ base: '24px', md: '64px' }}
      >
        <PageTitle title='Populate Wishlist' />

        <Text
          m={{ base: '12px 0 16px', md: '12px 0 30px' }}
          color='#303131'
        >
          Enter the prize items on your wish list
        </Text>

        <Box maxW='640px'>
          <form onSubmit={handleSubmit}>
            <Box width='100%'>
              <InputField
                handleChange={handleGift}
                countMap={countMap}
              />
            </Box>

            <Flex gap='24px'>
              <Text
                my='23px'
                textDecor='underline'
                color='#0D4740'
                cursor='pointer'
                onClick={addMoreInput}
              >
                Add more items
              </Text>

              {countMap >= 5 && (
                <Text
                  my='23px'
                  textDecor='underline'
                  color='#ED1C00'
                  cursor='pointer'
                  onClick={clearInput}
                >
                  Clear Items
                </Text>
              )}
            </Flex>

            <Flex
              width='100%'
              gap='16px'
              mb='23px'
              alignItems='flex-end'
            >
              {/* <Box
                maxW='305px'
                width='100%'
              >
                <FilterSelect
                  minH='60px'
                  label='Feature'
                  name='feature'
                  onChange={handleChange}
                  width='100%'
                >
                  <option value=''>Select Feature</option>
                  <option value='card'>Cards</option>
                  <option value='buy'>Buy</option>
                </FilterSelect>
              </Box> */}

              <Box
                // maxW='305px'
                width='100%'
              >
                <Input
                  placeholder='Tag (e.g. Holiday season)'
                  name='tag'
                  onChange={handleChange}
                  isRequired
                />
              </Box>
            </Flex>

            <Flex gap='16px'>
              <Box
                maxW='305px'
                width='100%'
              >
                <FilterSelect
                  minH='60px'
                  label='Who can See this List?'
                  name='criteria'
                  onChange={handleChange}
                  isRequired
                >
                  <option value=''>Select Users (old or new)</option>

                  <option value='oldUsers'>Old</option>
                  <option value='newUsers'>New</option>
                </FilterSelect>
              </Box>

              <Box
                maxW='305px'
                width='100%'
              >
                <FilterSelect
                  minH='60px'
                  label='Frequency'
                  name='frequency'
                  onChange={handleChange}
                  isRequired
                >
                  <option value=''>Frequency</option>

                  <option value='daily'>Daily</option>
                  <option value='weekly'>Weekly</option>
                  <option value='monthly'>Monthly</option>
                </FilterSelect>
              </Box>
            </Flex>

            <Center
              justifyContent='flex-end'
              mt={{ base: '48px', md: '93px' }}
            >
              <Button
                minW='305px'
                type='submit'
                isLoading={isLoading}
                disabled={isLoading}
              >
                Send Wishlist
              </Button>
            </Center>
          </form>
        </Box>
      </Box>

      {isOpen && (
        <WishlistSuccessModal
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Box>
  );
};

export default CreateWishlist;

const InputField = ({ handleChange, countMap }) => {
  return (
    <Box>
      <Flex
        width='initial'
        rowGap='23px'
        columnGap='16px'
        flexWrap='wrap'
      >
        {Array(countMap)
          .fill('')
          .map((_, i) => (
            <Box
              key={i}
              // mb='23px'
              flexBasis='50%'
              maxWidth='305px'
            >
              <Input
                placeholder={`Item ${i + 1}`}
                onChange={handleChange}
                name={`gift-${i + 1}`}
              />
            </Box>
          ))}
      </Flex>
    </Box>
  );
};
