import { Center, VStack, Box } from '@chakra-ui/react';
import { Button, Input } from 'components';

const StepTwo = ({ state, nextStep, handleChange }) => {
  const isDisabled = false;

  return (
    <VStack
      minW='100%'
      width='100%'
      alignItems='initial'
      spacing='12px'
    >
      <Box>
        <Input
          label='Street Name and Number'
          name='street'
          placeholder='Enter Details'
          minH='60px'
          onChange={handleChange}
          defaultValue={state.street}
        />
      </Box>

      <Box>
        <Input
          label='Flat, Suite, Unit, Building or Floor Number'
          placeholder='Enter Details'
          name='houseFlat'
          minH='60px'
          onChange={handleChange}
          defaultValue={state.houseFlat}
        />
      </Box>

      <Box>
        <Input
          label='Town/City'
          placeholder='Enter Details'
          name='city'
          minH='60px'
          onChange={handleChange}
          defaultValue={state.city}
        />
      </Box>

      <Box>
        <Input
          label='State/District'
          placeholder='Enter Details'
          name='state'
          minH='60px'
          onChange={handleChange}
          defaultValue={state.state}
        />
      </Box>

      <Box>
        <Input
          label='Branch'
          placeholder='Enter Details'
          name='branchName'
          minH='60px'
          onChange={handleChange}
          defaultValue={state.branchName}
        />
      </Box>

      <Box>
        <Input
          label='Zip Code'
          placeholder='Enter Details'
          name='zipCode'
          minH='60px'
          onChange={handleChange}
          defaultValue={state.zipCode}
        />
      </Box>

      <Center
        width='100%'
        justify='flex-end'
        flexDir='column'
        mt='32px !important'
      >
        <Button
          minH='60px'
          width='320px'
          cursor='pointer'
          onClick={nextStep}
          disabled={isDisabled}
        >
          Save and Continue
        </Button>
      </Center>
    </VStack>
  );
};

export default StepTwo;
