import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineChevronRight } from 'react-icons/md';
import { Box, Text, Flex, useDisclosure } from '@chakra-ui/react';
import { PartnerRewardModal, Loader } from 'components';
import { fetchParntersByUserId } from 'store/slices/partners.slice';

const PartnerRewardHistory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: { data },
    status,
    errorMessage,
  } = useSelector((state) => state.partners.partnerByUserId);

  const [modalData, setModalData] = useState({});

  const handleOpen = (data) => {
    onOpen(true);
    setModalData(data);
  };

  useEffect(() => {
    dispatch(fetchParntersByUserId({ userId: id }));
  }, [dispatch, id]);

  return (
    <>
      {status === 'loading' && <Loader />}
      {status === 'error' && <Text>{errorMessage}</Text>}
      {data.map(
        ({
          firstName,
          lastName,
          kycLevel,
          referredUserId,
          registeredOn,
          transactionsCount,
        }) => {
          const data = {
            firstName,
            lastName,
            transactionsCount,
            kycLevel,
            referredUserId,
            registeredOn,
          };
          return (
            <Flex
              key={referredUserId}
              borderRadius="20px"
              marginBottom="18px"
              padding="13px 18px"
              justifyContent={'space-between'}
              alignItems="center"
              onClick={() => handleOpen(data)}
              bg="#F2F4F3"
              cursor="pointer"
              _hover={{
                zoom: 1.005,
              }}
            >
              <Box>
                <Text
                  fontSize="16px"
                  lineHeight="19px"
                  fontWeight="600"
                  mb="16px"
                >
                  {firstName} {lastName}
                </Text>

                <Text fontSize={'14px'}>
                  {transactionsCount > 1
                    ? `${transactionsCount} transactions count`
                    : `${transactionsCount} transaction count`}
                </Text>

                <Text fontSize={'14px'}>{'email@gmail.om'}</Text>
              </Box>

              <Box>
                <MdOutlineChevronRight fontSize="1.5rem" />
              </Box>
            </Flex>
          );
        }
      )}
      <PartnerRewardModal data={modalData} isOpen={isOpen} onClose={onClose} />{' '}
    </>
  );
};

export default PartnerRewardHistory;
